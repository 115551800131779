/* eslint-disable react/no-danger */
import { ThemeProvider } from '@mui/material/styles';
import theme from '../../themev5';

import SourceValidationLayout from './components/SourceValidationLayout';

const SourceValidation = () => {
  return (
    <ThemeProvider theme={theme}>
      <SourceValidationLayout />
    </ThemeProvider>
  );
};

export default SourceValidation;
