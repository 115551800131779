import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Styles from './styles/Synonyms.styles';

import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';

import { createsynonyms } from '../../api/AdminPage';

const CreateSynonyms = ({ handleClose }) => {
  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(GlobalStore);

  const [key_term, setKeyTerm] = useState('');
  const [synonyms, setSynonyms] = useState('');
  const [data, setData] = useState([]);

  async function create_synonyms(data) {
    try {
      const response = await createsynonyms(data);
      setData(response.data);
      if (response.status === 200) {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: 'Synonyms Created Successfully!', color: 'success' }
        });
        history.go('/synonyms');
      } else if (response.status === 500) {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: 'Key_term Already exists!! ' }
        });
      }
    } catch (e) {
      alert(e);
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    const data = {
      key_term,
      synonyms: synonyms.split(',')
    };
    create_synonyms(data);
  };

  return (
    <Container maxWidth='xs'>
      <div className={classes.paper}>
        <Typography component='h1' variant='h5' className={classes.Heading}>
          Create Synonyms
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='key_term'
                label='Key Term'
                onChange={e => setKeyTerm(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                required
                fullWidth
                id='synonyms'
                label='Synonyms'
                multiline
                maxRows={Infinity}
                onChange={e => setSynonyms(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button type='submit' fullWidth variant='contained' className={classes.submit}>
            Create
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default CreateSynonyms;
