const styles = {
  root: {
    paddingTop: '70px',
    margin: '20px'
  },
  paper: {
    borderRadius: 1.5
  },
  countText: {
    fontWeight: 'bold !important'
  },
  tabContainer: {
    display: 'flex',
    paddingRight: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottom: '1px solid',
    borderBottomColor: 'gray.lightBorder',
    alignItems: 'flex-end'
  },
  activeTab: {
    border: '0.5px solid',
    borderColor: 'gray.light',
    borderRadius: '10px 10px 0px 0px',
    fontSize: '16px',
    boxShadow: 'none',
    color: 'gray.main',
    textTransform: 'capitalize',
    background: 'white'
  },
  inactiveTab: {
    borderRadius: '10px 10px 0px 0px',
    textTransform: 'capitalize',
    color: 'white.main',
    fontFamily: 'Nunito Sans',
    fontWeight: '600',
    fontSize: '16px',
    boxShadow: 'none',
    background: 'gradient.main'
  },
  tabs: {
    paddingLeft: 1,
    marginBottom: '-1px'
  }
};

export default styles;
