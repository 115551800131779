export const daysList: { key: string; value: string }[] = [
  { key: '7', value: 'Last 7 days' },
  { key: '30', value: 'Last month' },
  { key: '60', value: 'Last 2 months' },
  { key: '90', value: 'Last 3 months' },
  { key: '180', value: 'Last 6 months' },
  { key: '365', value: 'Last year' },
  { key: '0', value: 'All time' }
];
export const groupList: { key: string; value: string; allowed: string[] }[] = [
  { key: 'day', value: 'Days', allowed: ['7', '30', '60'] },
  { key: 'week', value: 'Weeks', allowed: ['30', '60', '90', '180', '365'] },
  { key: 'month', value: 'Months', allowed: ['60', '90', '180', '365', '0'] },
  { key: 'year', value: 'Years', allowed: ['0', '180', '365'] }
];
