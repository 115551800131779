import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import AuthContext from './store/Auth/AuthProvider';
import Login from './pages/Login';

/**
 * function to create authenticated routes for the application
 * @param {string} path url path
 * @param {node} component component that should be rendered
 * @param {boolean} exact to check exact match for url path is required
 * @returns route from react-router-dom which renders the given component for url path
 */
const PrivateRoute = ({ path, component, exact }) => {
  const { currentUser } = useContext(AuthContext);
  if (currentUser) {
    return <Route exact={exact} path={path} component={component} />;
  }
  return <Login />;
};

PrivateRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.node.isRequired,
  exact: PropTypes.bool.isRequired
};

export default PrivateRoute;
