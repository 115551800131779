export const ROUTE_ADMIN_EMA_PRODUCTS = '/ema/products';
export const ROUTE_ADMIN_EMA_PRODUCTS_REVIEW = '/ema/products/:applNo';
export const ROUTE_ARIA_MAIN = '/aria/:module/:source';
export const ROUTE_QUICK_SEARCH = '/search/:searchType/:module/:category/:search/:source';
export const ROUTE_ADVANCE_SEARCH = '/search/:searchType/:module/:source';
export const ROUTE_CLINICAL_TRIAL = '/clinicalTrials/:module/:category/:search';
export const ROUTE_REGULATORY_360 = '/regulatory360/:module/:number/:source';
export const ROUTE_CLINICAL_TRIAL_ADVANCED_SEARCH = '/clinicalTrials/:module/:searchType';
export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_SELECT = 'select';
export const DEFAULT_DATE = '01/01/1900';
