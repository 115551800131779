import React, { useState, useEffect, ChangeEvent, useContext } from 'react';
import { useLocation } from 'react-router-dom';

// muiv5
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete } from '@mui/material';
import PLRPage from './PLRPage';
import NonPLRPage from './NonPLRPage';
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { getLabelDetails, getProcessedLabelInfo } from '../../api/Cber'

// styles
import styles from './styles/PageNumberEdit.styles';

const table_format = [
    'PLR',
    'NON-PLR',
];

const LabelPageEditDialog = ({ closeEdit, docsId, application_number, searchText, status }: any) => {
    const location = useLocation();
    const { dispatch } = useContext<any>(Store);
    const [pdfurl, setpdfurl] = useState('')
    const [format, setFormat] = useState(location.pathname.includes('non-plr') ? 'NON-PLR' : 'PLR')
    const [disable, setdisable] = useState(false)
    
    useEffect(() => {
        const getLabelData = async () => {
            try {
                let labelData = null;
                if (location.pathname.includes('label_section')) {
                    setdisable(true)
                    labelData = await getProcessedLabelInfo(format.toLowerCase(), docsId);
                }
                else {
                    labelData = await getLabelDetails(docsId)
                }
                if (!labelData?.data || labelData.status !== 200) {
                    throw Error("Result Not Available")
                }
                else if (labelData.data.body) {
                    if (!location.pathname.includes('label_section')) {
                        labelData.data.body.result.is_plr ? setFormat('PLR') : setFormat('NON-PLR')
                        setpdfurl(labelData.data.body.result.document_link)
                    }
                    else {
                        setpdfurl(labelData.data.body.results.document_link)
                    }
                }
            } catch (e: any) {
                await dispatch({
                    type: Actions.SET_ALERT,
                    value: { status: true, message: e.message }
                });
            }
        }
        getLabelData();
    }, []);

    return (
        <Container sx={styles.root}>
            <div>
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <Box display='flex' justifyContent='space-between' alignItems='center'>
                        <Typography component='h2' variant='h5' sx={styles.Heading}>
                            Edit Label Page Number
                        </Typography>
                        <Autocomplete
                            id="table_format"
                            disabled={disable}
                            value={format}
                            options={table_format}
                            onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                                if (newValue !== null) {
                                    setFormat(newValue);
                                }
                            }}
                            sx={{
                                "& .MuiInputBase-input": {
                                    marginRight: '50px',
                                    fontSize: '1.5vh',
                                    minWidth: '250px'
                                },
                                "& .MuiFormLabel-root": {
                                    fontSize: '1.5vh'
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Document Format" variant="outlined"
                                style={{ fontSize: '2.5vh', minWidth: '250px' }}
                                InputLabelProps={{
                                    style: { fontSize: '1.8vh', fontWeight: 'bold' }
                                }}
                            />}
                        />
                    </Box>
                    <IconButton
                        aria-label='close'
                        onClick={() => {
                            closeEdit();
                        }}
                        sx={{
                            position: 'absolute',
                            right: 5,
                            top: 2,
                            color: 'gray.light'
                        }}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <Grid container spacing={2} display='flex'>
                    <Grid item xs={12} lg={6}>
                        {format && format === "PLR" ? (
                            <PLRPage format={format} docs_id={docsId} application_number={application_number} closeEdit={closeEdit} searchText={searchText} status={status} />
                        ) : null}
                        {format && format === "NON-PLR" ? (
                            <NonPLRPage format={format} docs_id={docsId} application_number={application_number} closeEdit={closeEdit} searchText={searchText} status={status} />
                        ) : null}
                    </Grid>
                    <Grid item xs={12} lg={6} sx={{ overflow: 'hidden', height: '75vh' }}>
                        {pdfurl ? null : (
                            <Box
                                display='flex'
                                justifyContent='center'
                                alignItems='center'
                                style={{ height: '100%', width: '100%', }}
                            >
                                <Typography
                                    style={{ fontSize: '20px', fontWeight: 'bold', color: '#33B187' }}
                                >Can't Open This PDF </Typography>
                            </Box>
                        )}
                        {pdfurl ? (
                            <Box display='flex' justifyContent='center'
                            >
                                <iframe
                                    title='pdf'
                                    src={pdfurl}
                                    style={{ height: '100vh', width: '50vw', marginTop: '20px', position: 'relative' }}
                                />
                            </Box>
                        ) : null}
                    </Grid>
                </Grid>
            </div>
        </Container>
    );
};

export default LabelPageEditDialog;
