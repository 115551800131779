// Import of React.
import React from 'react';

// pdf
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Base exported for PDF viewer
const PDFViewer = ({ fileUrl, initialPage = 0, defaultScale = 1 }) => {
  /**
   * Initialize the default layout for the basic plugin
   * instances. Like search, background color, zoom-in, etc.
   */
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  return (
    <Worker workerUrl='https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.js'>
      <Viewer
        fileUrl={fileUrl}
        initialPage={initialPage}
        defaultScale={defaultScale}
        plugins={[defaultLayoutPluginInstance]}
      />
    </Worker>
  );
};

export default PDFViewer;
