import themev5 from "../../../themev5";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import React, { memo, useContext } from "react";
import { ThemeProvider } from "@mui/material";
import { getCurationLogs, markCurationAsDone } from "../../../api/pmda";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import Button from "@mui/material/Button";
import Actions from "../../../store/actions";
import Store from "../../../store";

const DAGCuration = () => {
  const { dispatch } = useContext<any>(Store);

  const [logs, setLogs] = React.useState<any>([]);
  const handleMarkAsDone = (time:any) => {
   markCurationAsDone(time).then((response) => {
      if (response.data) {
        dispatch({
          type: Actions.SET_ALERT, value: { status: true, message: "Marked as done" }
        });
        // remove the log from the list
        const newLogs = logs.filter((log:any) => log.start_date !== time);
        setLogs(newLogs);
      }
   }).catch((error) => {
      console.error(error);
       dispatch({
         type: Actions.SET_ALERT, value: { status: true, message: error.message }
       });
    }
    );
  };
  React.useEffect(() => {
    getCurationLogs().then((response) => {
      if (response.data) {
        const finalLogs:any = [];
        response.data.body.forEach((log:any) => {
          finalLogs.push({
            id: log.id,
            start_date: log.start_date,
            status: log.status
          })
        })
        setLogs(finalLogs);
      }
    }).catch((error) => {
      console.error(error);
      dispatch({
        type: Actions.SET_ALERT, value: { status: true, message: error.message }
      });
    });
  }, []);
  return (<ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display="flex" ml={2} mt={10} alignItems="center">
        <h1>Curation logs</h1>
      </Box>
      <DataGrid style={{ height: "60vh", width: "100%" }}
                rows={logs || []}
                columns={[{
                  field: "start_date", headerName: "Date", width: 500,
                }, {
                  field: "translateButton", headerName: "Actions", width: 200, renderCell: (params) => (
                    <Button
                      onClick={() => handleMarkAsDone(params.row.start_date)}>Mark as complete</Button>)
                }]}
                pageSize={100}
      />

    </ThemeProvider>);
};
export default memo(DAGCuration);