import React, { useState, useMemo, useCallback } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { makeStyles } from '@material-ui/core';

import SelectField from './SelectField';
import TextForm from './TextField';

interface StatsRecordType {
  region: string;
  source: string;
  active_data_source: string;
  metric: string;
  description: string;
  id?: number;
}

interface AddStatsProps {
  handlePopUpClose: () => void;
  handleAddStatsRecord: (statsRecord: any) => void;
  recordToUpdate?: StatsRecordType;
  dataOptions: any;
}

const initialStatsRecord: StatsRecordType = {
  region: '',
  source: '',
  active_data_source: '',
  metric: '',
  description: ''
};

const AddStats: React.FC<AddStatsProps> = ({
  handlePopUpClose,
  handleAddStatsRecord,
  dataOptions,
  recordToUpdate = initialStatsRecord
}) => {
  const useStyles = makeStyles(() => ({
    root: {
      '&  .Mui-active .MuiStepIcon-root': { color: '#3ab09e' },
      '&  .Mui-completed .MuiStepIcon-root': { color: 'green' },
      '& .Mui-disabled .MuiStepIcon-root': { color: 'gray' }
    }
  }));

  const classes = useStyles();

  const [activeStep, setActiveStep] = useState(0);
  const [statsRecord, setStatsRecord] = useState(recordToUpdate);

  const handleStatsRecordChange = useCallback(
    (fieldName: string, value: string) => {
      const newFieldValue: any = {};
      newFieldValue[fieldName] = value;
      setStatsRecord({
        ...statsRecord,
        ...newFieldValue
      });
    },
    [statsRecord]
  );

  const handleNext = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  }, []);

  const handleReset = () => {
    setActiveStep(0);
    setStatsRecord(initialStatsRecord);
  };

  const handleSave = () => {
    handleAddStatsRecord(statsRecord);
    handlePopUpClose();
  };

  const steps = useMemo(
    () => [
      {
        label: statsRecord.region ? `Selected Region: ${statsRecord.region}` : 'Select Region'
      },
      {
        label: statsRecord.source ? `Selected Source: ${statsRecord.source}` : 'Select Source'
      },

      {
        label: statsRecord.active_data_source
          ? `Selected Active Data source: ${statsRecord.active_data_source}`
          : 'Select Active Data source'
      },
      {
        label: statsRecord.metric ? `Selected Metric: ${statsRecord.metric}` : 'Select Metric'
      },
      {
        label: 'Description'
      }
    ],
    [statsRecord.region, statsRecord.source, statsRecord.active_data_source, statsRecord.metric]
  );

  const renderStepContent = (step: number, index: number) => {
    switch (step) {
      case 0:
        return (
          <SelectField
            values={dataOptions.regions}
            selectType='Region'
            index={index}
            handleBack={handleBack}
            handleNext={handleNext}
            handleStatsRecordChange={handleStatsRecordChange}
            statsRecordState={statsRecord}></SelectField>
        );
      case 1:
        return (
          <SelectField
            values={dataOptions.sources[statsRecord.region]}
            selectType='Source'
            index={index}
            handleBack={handleBack}
            handleNext={handleNext}
            handleStatsRecordChange={handleStatsRecordChange}
            statsRecordState={statsRecord}></SelectField>
        );
      case 2:
        return (
          <SelectField
            values={dataOptions.active_data_sources}
            selectType='Active Data Source'
            index={index}
            handleBack={handleBack}
            handleNext={handleNext}
            handleStatsRecordChange={handleStatsRecordChange}
            statsRecordState={statsRecord}></SelectField>
        );
      case 3:
        return (
          <SelectField
            values={dataOptions.metrics}
            selectType='Metric'
            index={index}
            handleBack={handleBack}
            handleNext={handleNext}
            handleStatsRecordChange={handleStatsRecordChange}
            statsRecordState={statsRecord}></SelectField>
        );
      case 4:
        return (
          <TextForm
            selectType='Description'
            index={index}
            handleBack={handleBack}
            handleNext={handleNext}
            handleStatsRecordChange={handleStatsRecordChange}></TextForm>
        );
      default:
        return <div>Not Found</div>;
    }
  };

  return (
    <Box>
      <Stepper activeStep={activeStep} className={classes.root} orientation='vertical'>
        {steps.map((step, index) => (
          <Step key={step.label} sx={{ color: 'black' }}>
            <StepLabel
              optional={
                index === steps.length - 1 ? (
                  <Typography variant='caption'>Last step</Typography>
                ) : null
              }>
              {step.label}
            </StepLabel>
            <StepContent>
              <Box sx={{ mb: 2 }}>{renderStepContent(activeStep, index)}</Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} sx={{ p: 3 }}>
          <Typography>Description: {statsRecord.description}</Typography>
          <Button onClick={handleSave} sx={{ mt: 1, mr: 1 }}>
            {recordToUpdate.id ? 'Update' : 'Save'}
          </Button>
          <Button onClick={handleReset} sx={{ mt: 1, mr: 1 }}>
            Reset
          </Button>
        </Paper>
      )}
      <Typography variant='caption'>
        If you don't find a Region, Source, Active data source or Metric looking for; Please contact
        admin.
      </Typography>
    </Box>
  );
};

export default AddStats;
