import { useState, useEffect, useContext } from 'react';

import { Button, Grid, Box, TextField, MenuItem } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@mui/material/CssBaseline';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { debounce } from 'lodash';
import { format } from 'date-fns';

// context
import Store from '../../store';
import Actions from '../../store/actions';

import themev5 from '../../themev5';
import Styles from './styles/CategorizationTable.styles';
import styles from '../Datagrid/Datagrid.styles';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';

// api
import { getCderLabelsList } from '../../api/Cder';
import IndicationCurationModal from './IndicationCurationModal';
import LabelPageCurationModal from './LabelPageCurationDialog';
import LabelSectionEditDialog from './LabelSectionEditDialog';
import LabelCurationLegend from './legends/LabelCurationLegend';
import LimitationCurationModal from './LimitationCurationModal';
import CurationButton from './CurationButton';

const qcStatusMap = (key: string) => {
  const map: any = {
    to_qc: 'To QC',
    qced: 'QCed',
    escalate: 'Escalate',
    'missing details': 'Missing Details'
  };
  return map[key];
};

const validPageLabelTypes = ['USPI'];

const LabelCuration = () => {
  const { dispatch } = useContext<any>(Store);
  const classes = Styles();
  const [pageSize, setPageSize] = useState(10);
  const [labelData, setLabelData] = useState<Array<any>>([]);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState();
  const [loading, setloading] = useState(true);
  const [indicationEditVisible, setIndicationEditDialogVisible] = useState(false);
  const [limitationEditVisible, setLimitationEditDialogVisible] = useState(false);
  const [pageEditVisible, setPageEditDialogVisible] = useState(false);
  const [sectionEditVisible, setSectionEditDialogVisible] = useState(false);
  const [docsId, setDocsId] = useState<Number>();
  const [searchText, setSearchText] = useState<string>('');
  const [status, setStatus] = useState<string>('all documents');
  const [curationTask, setCurationTask] = useState<string>('0');
  const [createAt, setCreatedAt] = useState<string>('');
  const [submissionType, setSubmissionType] = useState<string>('');
  const rowsPerPageOptions = [10, 20, 50, 100];
  const [reload, setReload] = useState(false);

  const debouncedChangeHandler = () => {
    setReload(prevState => !prevState);
  };
  const handleChange = debounce(debouncedChangeHandler, 300);

  useEffect(() => {
    const fetchCderLabelData = async () => {
      try {
        const result = await getCderLabelsList(
          pageNumber,
          pageSize,
          status,
          searchText.trim().toLowerCase(),
          curationTask,
          createAt,
          submissionType
        );

        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.results) {
          setloading(false);
          const resultData = result.data.body.results;
          setTotalRowCount(result.data.body.total_rows);
          setLabelData(resultData?.map((item: any, index: any) => ({ ...item, id: index })));
        } else {
          setloading(false);
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    fetchCderLabelData();
  }, [pageNumber, status, pageSize, reload, curationTask, createAt, submissionType]);

  const openEdit = (data: any) => {
    setDocsId(data.document_id);
  };
  const closeDialogues = () => {
    setIndicationEditDialogVisible(false);
    setPageEditDialogVisible(false);
    setSectionEditDialogVisible(false);
    setLimitationEditDialogVisible(false);
  };
  const closeEdit = () => {
    closeDialogues();
    setReload(prevState => !prevState);
    setDocsId(undefined);
  };

  const verifyLabelOpenPageDialog = (labelType: string) => {
    if (!validPageLabelTypes.includes(labelType)) {
      dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `Label Type ${labelType} is not Allowed for Page Curation` }
      });
      return;
    }
    setPageEditDialogVisible(true);
  };

  const verifyLabelOpenSectionDialog = (labelType: string) => {
    if (!validPageLabelTypes.includes(labelType)) {
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: `Label Type ${labelType} is not Allowed for Section Curation`
        }
      });
      return;
    }
    setSectionEditDialogVisible(true);
  };

  const indicurationCurationNext = () => {
    closeDialogues();
    setReload(prevState => !prevState);
    setLimitationEditDialogVisible(true);
  };

  const limitationCurationNext = (labelType: string) => {
    closeDialogues();
    setReload(prevState => !prevState);
    verifyLabelOpenPageDialog(labelType);
  };

  const pageCurationNext = () => {
    closeDialogues();
    setReload(prevState => !prevState);
    setSectionEditDialogVisible(true);
  };

  const getQCButtonClass = (row: any, key: string): any => {
    if (
      key !== 'label_curation' &&
      row.label_type &&
      !validPageLabelTypes.includes(row.label_type)
    ) {
      return classes.RedButton;
    }
    const qcStatus: string = row.qc_statuses[key].toLowerCase();
    const qcStatusColorMap: any = {
      qced: classes.Button,
      to_qc: classes.BlueButton,
      default: classes.YellowButton
    };
    return qcStatusColorMap[qcStatus] || qcStatusColorMap.default;
  };
  const isPageCurationDisabled = (rowData: any) => {
    return rowData.qc_statuses?.page_numbers === 'qced'
      ? false
      : rowData.qc_statuses.label_curation !== 'qced';
  };
  const isSectionSeparationDisabled = (rowData: any) => {
    return rowData.qc_statuses?.page_numbers !== 'qced';
  };
  const columns = [
    {
      field: 'application_number',
      headerName: 'Application Number',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'pdf_title',
      headerName: 'PDF Title',
      width: 500,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Grid item xs={8} lg={8}>
            {params.row.s3_path.split('/').slice(-1)}
          </Grid>
        );
      }
    },
    {
      field: 'overall_qc_status',
      headerName: 'Overall QC Status',
      width: 170,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Grid item xs={8} lg={8}>
            {qcStatusMap(params.row.overall_qc_status as string)}
          </Grid>
        );
      }
    },
    {
      field: 'submission_no',
      headerName: 'Submission Number',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'submission_type',
      headerName: 'Submission Type',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'created_at',
      headerName: 'Created Date',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Actions',
      width: 700,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Box
            style={{
              margin: '3px',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Button
              variant='contained'
              className={getQCButtonClass(params.row, 'label_curation')}
              onClick={() => {
                openEdit(params.row);
                setIndicationEditDialogVisible(true);
              }}>
              <Typography variant='body1'>Label Curation</Typography>
            </Button>
            <Button
              variant='contained'
              className={getQCButtonClass(params.row, 'limitation_curation')}
              onClick={() => {
                openEdit(params.row);
                setLimitationEditDialogVisible(true);
              }}>
              <Typography variant='body1'>Limitation Curation</Typography>
            </Button>
            <Button
              variant='contained'
              className={getQCButtonClass(params.row, 'page_numbers')}
              disabled={isPageCurationDisabled(params.row)}
              onClick={() => {
                openEdit(params.row);
                verifyLabelOpenPageDialog(params.row.label_type);
              }}>
              <Typography variant='body1'>Page Curation</Typography>
            </Button>
            <Button
              variant='contained'
              className={getQCButtonClass(params.row, 'section_separation')}
              disabled={isSectionSeparationDisabled(params.row)}
              onClick={() => {
                openEdit(params.row);
                verifyLabelOpenSectionDialog(params.row.label_type);
              }}>
              <Typography variant='body1'>Section Separation</Typography>
            </Button>
          </Box>
        );
      }
    }
  ];
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Grid container>
        <Grid item xl={10} lg={10}>
          <Box display='flex' ml={2} mt={10} alignItems='center'>
            <TextField
              variant='outlined'
              type='search'
              id='search_application'
              label='Search Application Number'
              value={searchText}
              InputProps={{
                style: { fontSize: '1.5vh', minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: '1.5vh' }
              }}
              onChange={e => {
                setSearchText(e.target.value);
                setPageNumber(0);
                handleChange();
              }}
            />
            <TextField
              variant='outlined'
              id='status'
              label='QC Status'
              select
              value={status}
              onChange={e => setStatus(e.target.value)}
              InputProps={{
                style: { fontSize: '1.5vh', minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: '1.5vh' }
              }}>
              <MenuItem value='all documents'>All Documents</MenuItem>
              <MenuItem value='qced'>Qced</MenuItem>
              <MenuItem value='to_qc'>To QC</MenuItem>
              <MenuItem value='escalate'>Escalate</MenuItem>
              <MenuItem value='missing details'>Missing Details</MenuItem>
            </TextField>
            <TextField
              variant='outlined'
              id='curation'
              label='Curation Task'
              select
              value={curationTask}
              onChange={e => setCurationTask(e.target.value)}
              InputProps={{
                style: { fontSize: '1.5vh', minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: '1.5vh' }
              }}>
              <MenuItem value='0'>All</MenuItem>
              <MenuItem value='1'>Indication</MenuItem>
              <MenuItem value='4'>Limitation Curation</MenuItem>
              <MenuItem value='2'>Page Number</MenuItem>
              <MenuItem value='3'>Section Separation</MenuItem>
            </TextField>
            <TextField
              variant='outlined'
              id='submissiontype'
              label='Submission Type'
              select
              value={submissionType}
              onChange={e => setSubmissionType(e.target.value)}
              InputProps={{
                style: { fontSize: '1.5vh', minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: '1.5vh' }
              }}>
              <MenuItem value='All'>All</MenuItem>
              <MenuItem value='ORIG'>ORIG</MenuItem>
              <MenuItem value='SUPPL'>SUPPL</MenuItem>
            </TextField>
            <Box sx={{ width: '300px' }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label='Create At Date'
                  inputFormat='yyyy-MM-dd'
                  openTo='year'
                  views={['year', 'month', 'day']}
                  value={createAt || null}
                  onChange={newValue => {
                    if (newValue !== null && typeof newValue !== 'undefined') {
                      // eslint-disable-next-line no-restricted-globals
                      if (!isNaN(new Date(newValue).getTime())) {
                        const date = format(new Date(newValue), 'yyyy-MM-dd');
                        setCreatedAt(date);
                      }
                    }
                  }}
                  InputProps={{
                    style: { fontSize: '1.5vh', minWidth: '250px', width: '300px' }
                  }}
                  renderInput={(params: any) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      variant='outlined'
                      InputLabelProps={{
                        style: { fontSize: '1.5vh', fontWeight: 'bold', Width: '300px' }
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Box>
          </Box>
        </Grid>
        <Grid item lg={2} xl={2}>
          <Box mt={14}>
            <LabelCurationLegend />
          </Box>
        </Grid>
      </Grid>
      <Box mt={2}>
        <CurationButton />
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={labelData}
          columns={columns as any}
          rowCount={totalRowCount}
          pageSize={pageSize}
          getRowId={row => row.id}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPageNumber(0);
          }}
          getRowHeight={() => 'auto'}
          onPageChange={setPageNumber}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={pageNumber}
          paginationMode='server'
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        <Dialog
          open={
            indicationEditVisible || sectionEditVisible || pageEditVisible || limitationEditVisible
          }
          onClose={closeEdit}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          {indicationEditVisible ? (
            <IndicationCurationModal
              closeEdit={closeEdit}
              docsId={docsId}
              handleNext={indicurationCurationNext}
            />
          ) : null}
          {limitationEditVisible ? (
            <LimitationCurationModal
              closeEdit={closeEdit}
              docsId={docsId}
              handleNext={limitationCurationNext}
            />
          ) : null}
          {pageEditVisible ? (
            <LabelPageCurationModal
              closeEdit={closeEdit}
              docsId={docsId}
              handleNext={pageCurationNext}
            />
          ) : null}
          {sectionEditVisible ? (
            <LabelSectionEditDialog closeEdit={closeEdit} docsId={docsId} />
          ) : null}
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default LabelCuration;
