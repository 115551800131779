import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

// mui
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';

// api
import { getAllApplications, getMeetingIdSuggestion } from '../../api/AdcommModule';

// styles
import themev5 from '../../themev5';
import Styles, { styles } from './styles/meetingDashbaord';

// constant
import { qcStatus, rowsPerPageOptions, CENTER_OPTIONS_APPLICATON_DASHBOARD } from './constant';
import ApplicationCurationForm from './ApplicationCurationForm';
import DeviceCurationForm from './DeviceCurationForm';

const AdCommApplicationDashbaord = () => {
  const classes = Styles();
  const location = useLocation();
  const history = useHistory();
  const updatedSearchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNo, setPageNo] = useState<number>(0);
  const [curatedStatus, setCuratedStatus] = useState('All');
  const [center, setCenter] = useState('CDRH');
  const [rowCount, setRowCount] = useState<number>(0);
  const [meetingData, setMeetingData] = useState<Array<any>>([]);
  const [meetingList, setMeetingList] = useState<Array<any>>([]);
  const [meetingId, setMeetingId] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [reload, setReload] = useState<boolean>(true);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

  const debouncedChangeHandler = () => {
    setReload(prevState => !prevState);
  };

  useEffect(() => {
    const curPage = updatedSearchParams.get('page') || '0';
    if (curPage !== pageNo.toString()) {
      setPageNo(parseInt(curPage, 10));
    }
    const curPageSize = updatedSearchParams.get('pageSize') || '10';
    if (curPageSize !== pageSize.toString()) {
      setPageSize(parseInt(curPageSize, 10));
    }
    history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
  }, []);

  const handleChange = debounce(debouncedChangeHandler, 300);

  const fetchData = async () => {
    try {
      setLoading(true);
      const filters: any = {};
      if (curatedStatus !== 'All') {
        filters.qc_status = curatedStatus === 'Non Qced' ? 'to_qc' : curatedStatus.toLowerCase();
      }
      if (meetingId) {
        filters.meeting_id = meetingId;
      }
      if (center) {
        filters.center = center;
      }
      const application = await getAllApplications(pageSize, pageNo, { filters });
      if (application.data.body.data) {
        setRowCount(application.data.body.count);
        setMeetingData(application.data.body.data);
        setMeetingList(application.data.body?.filter?.meeting_ids || []);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const closeEditDialog = () => {
    setShowEditDialog(false);
    fetchData();
  };

  const openEditDialog = (data: string) => {
    setId(data);
    setShowEditDialog(true);
  };

  const debouncedMeetingIdChangeHandler = async (newValue: string) => {
    try {
      if (newValue) {
        const response = await getMeetingIdSuggestion('meeting', 'meeting_id', newValue);
        if (response.data.status === 200) {
          setMeetingList(response.data.body.suggestions);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleMeetingIdFilters = debounce(debouncedMeetingIdChangeHandler, 300);

  useEffect(() => {
    fetchData();
  }, [curatedStatus, pageSize, pageNo, reload, meetingId]);

  const DATAGRID_COLUMN = [
    {
      field: 'Edit',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              openEditDialog(params.row.meeting_id);
            }}>
            <Typography sx={{ color: 'white.main' }}>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'meeting_id',
      headerName: 'Meeting Id',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'qc_status',
      headerName: 'Application Qc Status',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    }
  ];

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        <Autocomplete
          id='meeting_ids'
          value={meetingId}
          onInputChange={(event, newValue) => {
            setMeetingId(newValue || '');
            handleMeetingIdFilters(newValue || '');
          }}
          onChange={(event: React.ChangeEvent<{}>, newValue: string | null): void => {
            setMeetingId(newValue || '');
            setPageNo(0);
            handleChange();
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
          options={meetingList}
          renderInput={(params: any) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} placeholder='Meeting Id' label='Meeting Id' variant='outlined' />
          )}
        />
        <Autocomplete
          id='qc_status'
          value={curatedStatus}
          onChange={(event: React.ChangeEvent<{}>, newValue: string | null): void => {
            setCuratedStatus(newValue || '');
            setPageNo(0);
            handleChange();
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
          options={qcStatus}
          renderInput={(params: any) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} placeholder='QC Status' label='QC Status' variant='outlined' />
          )}
        />
        <Autocomplete
          id='center'
          value={center}
          onChange={(event: React.ChangeEvent<{}>, newValue: string | null): void => {
            setCenter(newValue || '');
            setPageNo(0);
            handleChange();
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
          options={CENTER_OPTIONS_APPLICATON_DASHBOARD}
          renderInput={(params: any) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} placeholder='center' label='center' variant='outlined' />
          )}
        />
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          style={{ height: '100%' }}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={meetingData}
          columns={DATAGRID_COLUMN as any}
          rowCount={rowCount}
          pageSize={pageSize}
          getRowId={row => row.meeting_id}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPageNo(0);
            updatedSearchParams.set('page', '0');
            updatedSearchParams.set('pageSize', newPageSize.toString());
            history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
          }}
          onPageChange={(page: number) => {
            setPageNo(page);
            updatedSearchParams.set('page', page.toString());
            history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={pageNo}
          paginationMode='server'
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        <Dialog
          disableEscapeKeyDown
          open={showEditDialog}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          {center === 'CDRH' ? (
            <DeviceCurationForm closeEdit={closeEditDialog} meetingId={id} />
          ) : (
            <ApplicationCurationForm closeEdit={closeEditDialog} meetingId={id} center={center} />
          )}
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(AdCommApplicationDashbaord);
