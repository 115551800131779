import React, { useContext, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import { DialogContent, Skeleton } from '@mui/material';
import CurationForm from '../../CustomDashboard/CurationForm';

// api
import { getNonQcdEuaDoc, insertQcdEuaAppliation, insertQcdEuaIndication } from '../../../api/eua';
import Actions from '../../../store/actions';
import Store from '../../../store';

// utils
import getDataFields, { getFieldsFromApiData } from '../../CustomDashboard/utils/getDataFields';
import { EUA_APPLICATION_FORM } from './constant';

const EditApplicationField = ({ closeEdit, selectedData }: any) => {
  const { dispatch } = useContext(Store) as any;
  const [applicationData, setApplicationData] = useState<any>([]);
  const [pdfUrl, setpdfUrl] = useState<Array<any>>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const payload: any = {
          vivpro_appl_no: selectedData.vivpro_appl_no
        };
        const pdfLink: Array<any> = [
          {
            document_title: 'confirmation_link',
            label: 'Confirmation Link',
            s3_url: selectedData.confirmation_link
          },
          {
            document_title: 'source_link',
            label: 'Source Link',
            s3_url: selectedData.source_link
          }
        ];
        const res = await getNonQcdEuaDoc(payload);
        if (res.data.status === 200) {
          if (res.data.body) {
            setApplicationData(getFieldsFromApiData(res.data.body, EUA_APPLICATION_FORM));
          } else {
            setApplicationData(getFieldsFromApiData([], EUA_APPLICATION_FORM));
          }
          setpdfUrl(pdfLink);
        } else {
          setLoading(false);
          throw new Error(res.data.Error);
        }
      } catch (error) {
        setLoading(false);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Something went wrong - ${error}` }
        });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [selectedData]);

  const handleSubmit = async (e: React.SyntheticEvent, data: any) => {
    try {
      if (Object.keys(data).length !== 0) {
        const fields = getDataFields(data);
        const applicationPayload = {
          ...fields,
          type: selectedData.type
        };
        const indicationPayload = {
          appl_no: fields.appl_no,
          appl_type: fields.appl_type.toUpperCase(),
          indication_usage: fields.indication,
          is_deleted: fields.is_deleted
        };
        const applRes = await insertQcdEuaAppliation(applicationPayload);
        const indicationRes = await insertQcdEuaIndication(indicationPayload);

        if (applRes.data.status !== 200 && indicationRes.data.status !== 200) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'something went wrong' }
          });
        } else {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Successfully Added the application', color: 'success' }
          });
        }
      }
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `${error}` }
      });
    }
  };

  return (
    <Container sx={{ overflow: 'hidden' }}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Application Curation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <CurationForm fields={applicationData} handleSubmit={handleSubmit} fileUrls={pdfUrl} />
          ) : (
            <Box display='flex' maxHeight='100vh' sx={{ overflow: 'hidden' }}>
              <Skeleton variant='rectangular' height='100vh' width='100%' />
            </Box>
          )}
        </DialogContent>
      </div>
    </Container>
  );
};

export default React.memo(EditApplicationField);
