import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import Typography from '@material-ui/core/Typography';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { deleteSynonyms, getSynonyms } from '../../api/AdminPage';
import Styles from './styles/Synonyms.styles';
import { basicDataGridCreator } from '../../helpers/dataGridCreator';

import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';

import CreateSynonyms from './CreateSynonyms';
import UpdateSynonyms from './UpdateSynonyms';

const ConfirmationDialog = ({ dialogOpen, onClose, onYesClick, title, message }) => {
  const classes = Styles();

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <Box className={classes.dialog}>
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} className={classes.confirm}>
            No
          </Button>
          <Button onClick={onYesClick} className={classes.confirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const Synonyms = () => {
  const classes = Styles();

  const [selectedkey_term, setkey_term] = useState('');
  const [open, setOpen] = useState(false);
  const [showedit, setEdit] = useState(false);
  const [data, setData] = useState([]);
  const [dialog, setDialog] = useState({});
  const [formData, setFormData] = useState();
  const [selectedData, setselectedData] = useState({});

  const { dispatch } = useContext(GlobalStore);
  const history = useHistory();

  const handleOpen = () => {
    setOpen(true);
  };

  const closeEdit = () => {
    setEdit(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getSynonyms();
        if (response.data.length >= 1) {
          setData(response.data);
        } else {
          setData([]);
        }
      } catch (e) {
        console.error('No record Found');
      }
    }

    getData();
  }, []);

  const columns = [
    { field: 'key_term', headerName: 'Key Terms', width: 150 },
    { field: 'synonyms', headerName: 'Synonyms', width: 1100 },
    {
      field: 'Edit',
      width: 100,
      renderCell: data => {
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={event => {
              handleUpdate(data);
            }}
            className={classes.Button}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'Delete',
      width: 140,
      renderCell: data => {
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={event => {
              confirmSubmission(data);
            }}
            className={classes.Button}>
            <Typography>Delete</Typography>
          </Button>
        );
      }
    }
  ];

  const confirmSubmission = data => {
    setDialog({
      message: 'Are you sure you want to delete the record?',
      title: 'Confirm Submission'
    });
    setselectedData(data);
    setFormData(formData);
  };

  async function handleDelete(data) {
    try {
      const response = await deleteSynonyms(data.row.key_term);
      if (response.status === 200) {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: 'Synonyms Deleted Successfully!', color: 'success' }
        });
        history.go('/synonyms');
      } else if (response.status === 500) {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: 'Failed to Delete!! ' }
        });
      }
    } catch (e) {
      console.error(e);
    }
  }

  async function handleUpdate(data) {
    try {
      setEdit(true);
      setkey_term(data.row.key_term);
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Box className={classes.root} height='10vh'>
      <Box height='50vh' width='100%' paddingTop='10vh' paddingLeft='3vh'>
        <Box>
          <Typography component='h2' variant='h6' color='#3AB09E' className={classes.Heading}>
            Synonyms
          </Typography>
        </Box>
        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={handleOpen}
            className={classes.Button}>
            CREATE
          </Button>
          <Dialog open={open} onClose={handleClose}>
            <CreateSynonyms handleClose={handleClose} />
          </Dialog>
        </Box>
        <Box justifyContent='center' height='60vh' className={classes.root}>
          {basicDataGridCreator(data, columns)}
        </Box>
        <Dialog open={showedit} onClose={closeEdit}>
          <UpdateSynonyms key_term={selectedkey_term} handleClose={closeEdit} />
        </Dialog>
      </Box>
      <ConfirmationDialog
        dialogOpen={!!(dialog.message && dialog.title)}
        onClose={() => setDialog({})}
        onYesClick={() => {
          handleDelete(selectedData);
        }}
        title={dialog.title}
        message={dialog.message}
      />
    </Box>
  );
};

export default Synonyms;
