import makeRequest from './client';

/**
 * Function to get stats-details.
 */
export const getStatsDetails = () =>
    makeRequest(`/db-stats/all`, 'GET');

export const createStatsRecord = (payload: any) =>
    makeRequest(`/db-stats/create`, 'POST', payload);

export const updateStatsRecord = (id: number, payload: any) =>
    makeRequest(`/db-stats/stats/${id}`, 'PUT', payload);

export const deleteStatsRecord = (id: number) =>
    makeRequest(`/db-stats/stats/${id}`, 'DELETE');   