import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import themev5 from "../../themev5";
import CssBaseline from "@mui/material/CssBaseline";
import { Autocomplete, LinearProgress, TextField, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import styles from "../Datagrid/Datagrid.styles";
import DatagridFooter from "../Datagrid/DatagridFooter";
import DatagridNoRowsOverlay from "../Datagrid/DatagridNoRowsOverlay";
import Dialog from "@mui/material/Dialog";
import { debounce } from "lodash";
import Styles from "../Canada/styles/curationdashboard.styles";
import Store from "../../store";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import renderCellExpand from "../Datagrid/DatagridPopover";
import Actions from "../../store/actions";
import { Field } from "../Canada/monographCuration";
import { getProductList } from "../../api/pmda";
import PMDAEditDialog from "./components/PMDAEditDialog";
import { TransitionProps } from "@mui/material/transitions";
import Slide from "@mui/material/Slide";

const qcStatus = [{
  label: "All Documents", value: ""
}, {
  label: "Non Qced", value: "admin_check_required"
}, {
  label: "Qced", value: "Qced"
}];

const Transition = React.forwardRef(function Transition(props: TransitionProps & {
  children: React.ReactElement;
}, ref: React.Ref<unknown>) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const PMDA = () => {
  const classes = Styles();
  const [pageSize, setPageSize] = useState(10);
  const [productData, setProductData] = useState<Array<any>>([]);
  const [pageNo, setPageNo] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setloading] = useState(true);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [primaryNumber, setPrimaryNumber] = useState("");
  const [tradeName, setTradeName] = useState("");
  const [activeIngredientId, setActiveIngredientId] = useState("");
  const rowsPerPageOptions = [10, 20, 50, 100];
  const [reload, setReload] = useState<boolean>(true);
  const [primaryNumberData, setPrimaryNumberData] = useState<object>({});
  const [status, setStatus] = useState({ label: "All Documents", value: "" });
  const { dispatch } = useContext<any>(Store);

  const debouncedChangeHandler = () => {
    setReload(prevState => !prevState);
  };

  const handleChange = debounce(debouncedChangeHandler, 300);

  const handlePrimaryNumber = (value: string) => {
    setPrimaryNumber(value);
    handleChange();
  };

  const handleTradeName = (value: string) => {
    setTradeName(value);
    handleChange();
  };

  const handleActiveIngredientId = (value: string) => {
    setActiveIngredientId(value);
    handleChange();
  };

  const fields: Field[] = [{
    id: "yj_code", label: "YJ Code", value: primaryNumber, onChange: handlePrimaryNumber
  }, {
    id: "sales_name", label: "Trade Name", value: tradeName, onChange: handleTradeName
  }, {
    id: "active_ingredient_id",
    label: "Active ingredient id",
    value: activeIngredientId,
    onChange: handleActiveIngredientId
  }];

  const openEditDialog = (data: any) => {
    setPrimaryNumberData(data);
    setShowEditDialog(true);
  };
  const closeEditDialog = () => {
    setShowEditDialog(false);
    setReload(prevState => !prevState);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setloading(true);
        const result = await getProductList(pageSize, pageNo, status.value, primaryNumber, tradeName, activeIngredientId);

        if (result === undefined) {
          throw new Error("Result is undefined");
        }
        if (result.data && result.data.body.results) {
          setloading(false);
          const resultData = result.data.body.results;
          setRowCount(result.data.body.total);
          setProductData(resultData);
        } else {
          setloading(false);
          throw Error("No data");
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT, value: { status: true, message: e.message }
        });
      }
    };
    getData();
  }, [pageNo, pageSize, reload]);

  const DATAGRID_COLUMN = [{
    field: "Edit", width: 100, headerAlign: "center", align: "center", renderCell: (params: any) => {
      return (<Button
        variant="contained"
        className={classes.Button}
        onClick={() => {
          openEditDialog(params.row);
        }}>
        <Typography>Edit</Typography>
      </Button>);
    }
  }, {
    field: "yj_code", headerName: "YJ Code", width: 200, headerAlign: "center", align: "center"
  }, {
    field: "trade_name", headerName: "Trade Name", width: 350, headerAlign: "center", align: "left"
  }, {
    field: "qc_status", headerName: "Qced Status", width: 350, headerAlign: "center", align: "center"
  }, {
    field: "indications_and_usage",
    headerName: "Indication",
    width: 350,
    headerAlign: "center",
    align: "left",
    renderCell: renderCellExpand
  }, {
    field: "sponsor_name",
    headerName: "Sponsor",
    width: 350,
    headerAlign: "center",
    align: "left",
    renderCell: renderCellExpand
  }, {
    field: "is_sub_product", headerName: "Is sub-product", width: 350, headerAlign: "center", align: "left"
  }];
  return (<ThemeProvider theme={themev5}>
    <CssBaseline />
    <Box display="flex" ml={2} mt={10} alignItems="center">
      <Autocomplete
        id="qc_status"
        value={status}
        onChange={(event: ChangeEvent<{}>, newValue: { label: string, value: string } | null): void => {
          if (newValue !== null) {
            setStatus(newValue);
            setPageNo(0);
            handleChange();
          }
        }}
        style={{ fontSize: "1.5vh", minWidth: "250px", paddingRight: "20px" }}
        options={qcStatus}
        getOptionLabel={(option: any) => option.label}
        renderInput={(params: any) => (// eslint-disable-next-line react/jsx-props-no-spreading
          <TextField {...params} placeholder="QC Status" label="QC Status" variant="outlined" />)}
      />
      {fields.map(row => (<TextField
        key={row.id}
        variant="outlined"
        type="search"
        id={row.id}
        label={row.label}
        value={row.value}
        sx={{ paddingRight: "30px" }}
        InputProps={{
          style: { fontSize: "1.5vh", minWidth: "250px", paddingRight: "20px" }
        }}
        InputLabelProps={{
          style: { fontSize: "1.5vh" }
        }}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          setPageNo(0);
          row.onChange(e.target.value);
        }}
      />))}
    </Box>
    <Box
      height="80vh"
      p={2}
      mt={1}
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.root}>
      <DataGrid
        sx={styles.datagrid}
        style={{ height: "100%" }}
        rowSpacingType="margin"
        sortingOrder={["desc", "asc"]}
        checkboxSelection={false}
        density="standard"
        rows={productData}
        columns={DATAGRID_COLUMN as any}
        rowCount={rowCount}
        pageSize={pageSize}
        getRowId={row => row.yj_code}
        onPageSizeChange={newPageSize => {
          setPageSize(newPageSize);
          setPageNo(0);
        }}
        onPageChange={setPageNo}
        rowsPerPageOptions={rowsPerPageOptions}
        pagination
        loading={loading}
        getRowClassName={() => `table-rows`}
        page={pageNo}
        paginationMode="server"
        components={{
          LoadingOverlay: LinearProgress, Footer: DatagridFooter, NoRowsOverlay: DatagridNoRowsOverlay
        }}
      />
      <Dialog
        open={showEditDialog}
        onClose={closeEditDialog}
        TransitionComponent={Transition}
        fullScreen>
        <PMDAEditDialog closeEdit={closeEditDialog} productData={primaryNumberData} />
      </Dialog>
    </Box>
  </ThemeProvider>);
};
export default React.memo(PMDA);