import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  SelectChangeEvent,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import { Add, Remove, Close } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';

import { WHATS_NEW_TYPES, LIMITS } from './constants';
import { getWhatsNewById, updateWhatsNewContent } from '../../api/WhatsNew';

interface Update {
  id: string;
  title: string;
  content: string;
  visual_type: string;
  visual_url: string;
  link_text: string;
  link_url: string;
}

const initialUpdate = {
  title: '',
  content: '',
  visual_type: '',
  visual_url: '',
  link_text: '',
  link_url: ''
};

interface EditProps {
  showEditDialog: boolean;
  closeEditDialog: () => void;
  id: number | null;
  handleSubmitRefetch: () => void;
}

const Edit: React.FC<EditProps> = ({
  showEditDialog,
  closeEditDialog,
  id,
  handleSubmitRefetch
}) => {
  const defaultFormValues = {
    id: '',
    title: '',
    description: '',
    type: '',
    updates: [] as Update[],
    publish_on: null as Date | null
  };

  const [formValues, setFormValues] = useState(defaultFormValues);

  const [errors, setErrors] = useState({
    title: '',
    description: '',
    type: '',
    updates: [] as {
      title: string;
      content: string;
      visual_type: string;
      visual_url: string;
      link_text: string;
      link_url: string;
    }[],
    publish_on: ''
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setFormValues(defaultFormValues);

        if (!id || !showEditDialog) {
          return;
        }

        const result = await getWhatsNewById(id);

        const whatNewEntry = result?.data?.body;

        setFormValues({
          id: whatNewEntry.id,
          title: whatNewEntry.title,
          description: whatNewEntry.description,
          type: whatNewEntry.type,
          updates: whatNewEntry.updates,
          publish_on: whatNewEntry.published_on ? new Date(whatNewEntry.published_on) : null
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [showEditDialog, id]);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    setFormValues(prevState => ({
      ...prevState,
      [name as string]: value
    }));
  };

  const handleDateChange = (name: string, newValue: Date | null) => {
    setFormValues(prevState => ({
      ...prevState,
      [name]: newValue
    }));
  };

  const handleUpdateChange = (
    index: number,
    e: React.ChangeEvent<HTMLInputElement | { name?: string; value: unknown }> | SelectChangeEvent
  ) => {
    const { name, value } = e.target;
    const updates = [...formValues.updates];
    updates[index] = { ...updates[index], [name as string]: value };
    setFormValues(prevState => ({
      ...prevState,
      updates
    }));
  };

  const handleAddUpdate = () => {
    setFormValues(prevState => ({
      ...prevState,
      updates: [...prevState.updates, { ...initialUpdate, id: uuidv4() }]
    }));
    setErrors(prevState => ({
      ...prevState,
      updates: [
        ...prevState.updates,
        { title: '', content: '', visual_type: '', visual_url: '', link_text: '', link_url: '' }
      ]
    }));
  };

  const handleRemoveUpdate = (index: number) => {
    const updates = formValues.updates.filter((_, i) => i !== index);
    setFormValues(prevState => ({
      ...prevState,
      updates
    }));
    const updatesErrors = errors.updates.filter((_, i) => i !== index);
    setErrors(prevState => ({
      ...prevState,
      updates: updatesErrors
    }));
  };

  const validateForm = () => {
    const newErrors = {
      title: '',
      description: '',
      type: '',
      updates: formValues.updates.map(() => ({
        title: '',
        content: '',
        visual_type: '',
        visual_url: '',
        link_text: '',
        link_url: ''
      })),
      publish_on: ''
    };
    let isValid = true;

    if (!formValues.title) {
      newErrors.title = 'Title is required';
      isValid = false;
    } else if (formValues.title.length > LIMITS.mainTitle) {
      newErrors.title = `Title must be ${LIMITS.mainTitle} characters or less`;
      isValid = false;
    }
    if (formValues.description.length > LIMITS.description) {
      newErrors.description = `Description must be ${LIMITS.description} characters or less`;
      isValid = false;
    }
    if (!formValues.type) {
      newErrors.type = 'Type is required';
      isValid = false;
    }
    if (!formValues.publish_on) {
      newErrors.publish_on = 'Publish date is required';
      isValid = false;
    }
    formValues.updates.forEach((update, index) => {
      if (update.title.length > LIMITS.title) {
        newErrors.updates[index].title = `Title must be ${LIMITS.title} characters or less`;
        isValid = false;
      }
      if (!update.content) {
        newErrors.updates[index].content = 'Update content is required';
        isValid = false;
      } else if (update.content.split(' ').length > LIMITS.content) {
        newErrors.updates[index].content = `Content must be around ${LIMITS.content} words`;
        isValid = false;
      }
      if (update.visual_type || update.visual_url) {
        if (!update.visual_type) {
          newErrors.updates[index].visual_type = 'Visual type is required';
          isValid = false;
        }
        if (!update.visual_url) {
          newErrors.updates[index].visual_url = 'Visual URL is required';
          isValid = false;
        }
      }
      if (update.link_text || update.link_url) {
        if (!update.link_text) {
          newErrors.updates[index].link_text = 'Link text is required';
          isValid = false;
        }
        if (!update.link_url) {
          newErrors.updates[index].link_url = 'Link URL is required';
          isValid = false;
        }
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  const handleUpdateWhatsNew = async (payload: any) => {
    try {
      const result = await updateWhatsNewContent(payload);

      if (result?.status !== 200) {
        return;
      }

      handleSubmitRefetch();
      closeEditDialog();
    } catch (error) {
      console.error('Error updating:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!validateForm()) {
      return;
    }

    const payload: any = {
      ...formValues,
      publish_on: formValues.publish_on?.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      })
    };

    payload.updates = payload.updates.map((update: any) => {
      return {
        ...update,
        id: uuidv4()
      };
    });

    await handleUpdateWhatsNew(payload);
  };

  return (
    <Dialog open={showEditDialog} onClose={closeEditDialog} maxWidth='lg' fullWidth>
      <DialogTitle>Edit</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={closeEditDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
        <Close />
      </IconButton>
      <DialogContent>
        <Box m={1}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <TextField
                name='title'
                label='Title'
                variant='outlined'
                value={formValues.title}
                onChange={handleInputChange}
                error={Boolean(errors.title)}
                helperText={errors.title}
              />
              <TextField
                name='description'
                label='Description'
                variant='outlined'
                value={formValues.description}
                onChange={handleInputChange}
                error={Boolean(errors.description)}
                helperText={errors.description}
                multiline
                rows={4}
              />
              <FormControl variant='outlined'>
                <InputLabel>Type</InputLabel>
                <Select
                  name='type'
                  label='Type'
                  value={formValues.type}
                  onChange={handleInputChange}
                  error={Boolean(errors.type)}>
                  <MenuItem value={WHATS_NEW_TYPES['Enhancement Updates']}>
                    {WHATS_NEW_TYPES['Enhancement Updates']}
                  </MenuItem>
                  <MenuItem value={WHATS_NEW_TYPES['Major Updates']}>
                    {WHATS_NEW_TYPES['Major Updates']}
                  </MenuItem>
                </Select>
                {errors.type && (
                  <Typography variant='body2' color='error'>
                    {errors.type}
                  </Typography>
                )}
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label='Publish Date'
                  value={formValues.publish_on}
                  onChange={newValue => handleDateChange('publish_on', newValue)}
                  renderInput={params => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      fullWidth
                      error={!!errors.publish_on}
                      helperText={errors.publish_on}
                    />
                  )}
                />
              </LocalizationProvider>
              {formValues.updates.map((update, index) => (
                <Box key={update.id} sx={{ border: '1px solid #ccc', p: 2, borderRadius: 1 }}>
                  <Typography variant='h6' mb={1}>
                    Update {index + 1}
                  </Typography>
                  <TextField
                    name='title'
                    label='Title'
                    variant='outlined'
                    value={update.title}
                    onChange={e => handleUpdateChange(index, e)}
                    error={Boolean(errors.updates[index]?.title)}
                    helperText={errors.updates[index]?.title}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    name='content'
                    label='Content'
                    variant='outlined'
                    value={update.content}
                    onChange={e => handleUpdateChange(index, e)}
                    error={Boolean(errors.updates[index]?.content)}
                    helperText={errors.updates[index]?.content}
                    fullWidth
                    multiline
                    rows={6}
                    sx={{ mb: 2 }}
                  />
                  <FormControl fullWidth variant='outlined' sx={{ mb: 2 }}>
                    <InputLabel>Visual Type</InputLabel>
                    <Select
                      name='visual_type'
                      label='Visual Type'
                      value={update.visual_type}
                      onChange={e => handleUpdateChange(index, e)}
                      error={Boolean(errors.updates[index]?.visual_type)}>
                      <MenuItem value='image'>Image</MenuItem>
                      <MenuItem value='video'>Video</MenuItem>
                    </Select>
                    {errors.updates[index]?.visual_type && (
                      <Typography variant='body2' color='error'>
                        {errors.updates[index]?.visual_type}
                      </Typography>
                    )}
                  </FormControl>
                  <TextField
                    name='visual_url'
                    label='Visual URL'
                    variant='outlined'
                    value={update.visual_url}
                    onChange={e => handleUpdateChange(index, e)}
                    error={Boolean(errors.updates[index]?.visual_url)}
                    helperText={errors.updates[index]?.visual_url}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    name='link_text'
                    label='Link Text'
                    variant='outlined'
                    value={update.link_text}
                    onChange={e => handleUpdateChange(index, e)}
                    error={Boolean(errors.updates[index]?.link_text)}
                    helperText={errors.updates[index]?.link_text}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <TextField
                    name='link_url'
                    label='Link URL'
                    variant='outlined'
                    value={update.link_url}
                    onChange={e => handleUpdateChange(index, e)}
                    error={Boolean(errors.updates[index]?.link_url)}
                    helperText={errors.updates[index]?.link_url}
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button
                      variant='contained'
                      color='secondary'
                      startIcon={<Remove />}
                      onClick={() => handleRemoveUpdate(index)}
                      disabled={formValues.updates.length === 1}>
                      Remove Update
                    </Button>
                    {index === formValues.updates.length - 1 && (
                      <Button
                        variant='contained'
                        color='primary'
                        startIcon={<Add />}
                        onClick={handleAddUpdate}>
                        Add Update
                      </Button>
                    )}
                  </Box>
                </Box>
              ))}
              <Button type='submit' variant='contained' color='primary'>
                Update
              </Button>
            </Box>
          </form>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default Edit;
