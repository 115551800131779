/* eslint-disable import/prefer-default-export */
import capitalize from 'lodash/capitalize';
import startCase from 'lodash/startCase';
import toLower from 'lodash/toLower';

const customTitleCase = (value) => {
  // https://en.wikipedia.org/wiki/Title_case
  // https://en.wikipedia.org/wiki/Letter_case#Stylistic_or_specialised_usage
  const exceptions = ["and", "the", "a", "an", "for", "to", "but", "at", "by", "of", "in", "with"]
  const splitStr = value.toLowerCase().split(" ");
  for (let i = 0; i < splitStr.length; i++) {
    if (i != 0 && exceptions.includes(splitStr[i])) {
      continue;
    }
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  const array = splitStr.join(" ");
  return array;
}

const fieldType = {
  active_ingredient: 'titlecase',
  formulations: 'customtitlecase',
  indications_and_usage: 'customtitlecase',
  routes: 'customtitlecase',
  therapeutic_area: 'titlecase',
  trade_name: 'uppercase'
};

const customTransform = (value, field) => {
  // check if value is string before making string operations
  // sometime value can be a react element
  if (typeof value !== 'string') {
    return value;
  }
  if (fieldType[field] === 'capitalize' || value?.toLowerCase() === 'missing at source') {
    return capitalize(value);
  }
  if (fieldType[field] === 'uppercase') {
    return value.toUpperCase();
  }
  if (fieldType[field] === 'titlecase') {
    return startCase(toLower(value));
  }
  if (fieldType[field] === 'customtitlecase') {
    return customTitleCase(toLower(value))
  }
  return value;
};
export default customTransform
