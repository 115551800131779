import React from 'react';
import { useParams } from 'react-router-dom';
// other components
import PNReviewLabel from './PNReviewLabel';
import ReviewPageLabel from './ReviewPageLabel';

const QCDocumentCheck = () => {
  const { applNo, subType, subNo, docType } = useParams();
  return (
    <>
      {docType === 'Letter' || docType === 'letter' ? (
        <ReviewPageLabel
          applNo={applNo && applNo}
          subType={subType}
          subNo={subNo}
          docType={docType}
        />
      ) : null}

      <PNReviewLabel applNo={applNo && applNo} subType={subType} subNo={subNo} docType={docType} />
    </>
  );
};

export default QCDocumentCheck;
