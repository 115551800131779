import theme from '../../../theme';

const styles = {
  root: {
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    padding: '30px'
  },
  typography: {
    padding: 8,
    fontSize: '1.8vh'
  },

  Heading: {
    color: '#3AB09E',
    fontSize: '5vh',
    textAlign: 'left',
    fontWeight: '600'
  },

  Button: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: 'white'
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: 'white'
  },
  dialog: {
    background: 'white'
  },
  select: {
    width: '85%'
    // marginTop: theme.spacing(0.5)
  },
  text: {
    width: '85%',
    marginTop: theme.spacing(0.35)
  },
  confirm: {
    background: '#3AB09E',
    color: theme.palette.primary,
    fontWeight: 'bold',
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8
    }
  }
};

export default styles;
