import Cber from '../components/CBER/Cber';
import DocCategorization from '../components/CBER/DocumentCategorization';
import PdfForm from '../components/CBER/PdfForm';
import LabelSection from '../components/CBER/LabelSection';
import PageNumberCuration from '../components/CBER/PageNumberCuration';
import CberPmrTable from '../components/CBER/CberPmrTable';
import CberATC from '../components/CBER/CberATC';

const CBER_ROUTES = [
  {
    id: 'cber_pmrc_search',
    path: '/pmr/cber',
    component: CberPmrTable,
    exact: true
  },
  {
    id: 'cber',
    path: '/cber',
    component: Cber,
    exact: true
  },
  {
    id: 'document_categorization',
    path: '/document_categorization',
    component: DocCategorization,
    exact: true
  },
  {
    id: 'document_categorization_form',
    path: '/cber/document_categorization/:application_num/:docs_id',
    component: PdfForm,
    exact: true
  },
  {
    id: 'label_section_separation',
    path: '/cber/label_section/:label_format',
    component: LabelSection
  },
  {
    id: 'label_page_number',
    path: '/cber/reviews/:format/:table_status?/:search_query?',
    component: PageNumberCuration,
    exact: true
  },
  {
    id: 'cber_atc_curation',
    path: '/cber/atc',
    component: CberATC,
    exact: true
  }
];
export default CBER_ROUTES;
