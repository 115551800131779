import React from 'react';

const css = `

.indicat{margin-left:5px;margin-right:5px}.indicat_a{fill:#3AB09E;}.indicat_b{fill:#6868fa;}.indicat_c{fill:#ffa500;}.indicat_e{fill:#DC143C;}.indicat_d{fill:#5b5a5c;font-size:19px;font-family:SFUIDisplay-Regular, SF UI Display;}`;

const LabelCurationLegend = () => {
  return (
    <svg
      className='indicat'
      xmlns='http://www.w3.org/2000/svg'
      width='170'
      height='16'
      viewBox='0 0 315 16'>
      <defs>
        <style>{css}</style>
      </defs>
      <g transform='translate(-550 -308)'>
        <rect className='indicat_a' width='20' height='20' rx='5' transform='translate(550 308)' />
        <rect className='indicat_b' width='20' height='20' rx='5' transform='translate(632 308)' />
        <rect className='indicat_e' width='20' height='20' rx='5' transform='translate(717 308)' />
        <rect className='indicat_c' width='20' height='20' rx='5' transform='translate(780 308)' />
        <text className='indicat_d' transform='translate(574 324)'>
          <tspan x='0' y='0'>
            QCed
          </tspan>
        </text>
        <text className='indicat_d' transform='translate(655 324)'>
          <tspan x='0' y='0'>
            To QC
          </tspan>
        </text>
        <text className='indicat_d' transform='translate(740 324)'>
          <tspan x='0' y='0'>
            N/A
          </tspan>
        </text>
        <text className='indicat_d' transform='translate(803 324)'>
          <tspan x='0' y='0'>
            Others
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default LabelCurationLegend;
