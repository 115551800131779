import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Auth } from 'aws-amplify';

// mui
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, Skeleton, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// api
import { getSelectedDevice, submitDevice, submitOverallQcStatus } from '../../api/AdcommModule';

// Store
import Actions from '../../store/actions';
import Store from '../../store';
import TopicOrDeviceForm from './TopicOrDeviceForm';
import { DEVICE_FORM_MAPPING, qcStatus } from './constant';

// utils
import getDataFields, { getFieldsFromApiData } from '../CustomDashboard/utils/getDataFields';
import PDFViewer from '../../utils/PDFViewer';

// styles
import Styles, { styles } from './styles/applicationCuration';
import titleCaseString from '../../utils/titleCaseString';

const DeviceCurationForm = ({ closeEdit, meetingId }: any) => {
  const classes = Styles();
  const [pdfUrl, setpdfUrl] = useState<Array<any>>([]);
  const { dispatch } = useContext<any>(Store);
  const [applicationData, setApplicationData] = useState<any>([]);
  const [selectedApplicationData, setSelectedApplicationData] = useState<any>([]);
  const [selectedAppl, setSelectedAppl] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedS3Link, setSelectedS3Link] = useState<any>({});
  const [curatedStatus, setCuratedStatus] = useState<string>('All');

  useEffect(() => {
    const getMeetingData = async () => {
      try {
        setLoading(true);
        const application = await getSelectedDevice(meetingId);
        if (application.data.status === 200) {
          setApplicationData(application.data.body);
          let pdfLink: Array<any> = [
            {
              document_title: 'agenda',
              label: 'Agenda',
              value: application.data.body.agenda ?? 'NA'
            }
          ];
          if (application.data.body?.backgrounder_files?.length !== 0) {
            pdfLink = [...pdfLink, ...(application.data.body?.backgrounder_files || [])];
          }
          setpdfUrl(pdfLink);
          setCuratedStatus(
            application.data.body.qc_status === 'to_qc'
              ? 'Non Qced'
              : titleCaseString(application.data.body.qc_status)
          );
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
        closeEdit();
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getMeetingData();
  }, [meetingId]);

  const handleSubmit = async (e: React.SyntheticEvent, data: any) => {
    try {
      if (Object.keys(data).length !== 0) {
        const user = await Auth.currentUserInfo();
        const payload = {
          ...selectedApplicationData,
          ...getDataFields(data),
          curator_email: user.attributes.email,
          curator_name: user.attributes['custom:user']
        };
        const res = await submitDevice(meetingId, payload);
        if (res.data.status === 200) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: `Submitted Successfully`, color: 'success' }
          });
        } else {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: `Failed to submit` }
          });
        }
      }
    } catch (error: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    } finally {
      closeEdit();
    }
  };

  const handleEdit = useCallback((applData: any) => {
    setOpen(true);
    setSelectedApplicationData(applData);
    setSelectedAppl(getFieldsFromApiData(applData, DEVICE_FORM_MAPPING));
  }, []);

  const handleOpenPopup = useCallback((url: string) => {
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow: any = window.open(url, '_blank', option);

    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
      }
    }, 1000);
  }, []);

  const handleQcStatus = useCallback(async () => {
    try {
      const user = await Auth.currentUserInfo();
      const payload = {
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user'],
        qc_status: curatedStatus === 'Non Qced' ? 'to_qc' : curatedStatus.toLowerCase()
      };
      const res = await submitOverallQcStatus(meetingId, 'device', payload);
      if (res.data.status === 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Submitted Successfully`, color: 'success' }
        });
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Failed to submit` }
        });
      }
    } catch (error: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    }
  }, [curatedStatus]);

  return (
    <div style={{ maxHeight: '100vh', overflow: 'hidden' }}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography
          component='h2'
          variant='h5'
          style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
          Device Curation for meeting {meetingId}
        </Typography>

        {open ? (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              setOpen(false);
            }}>
            <Typography sx={{ color: 'white.main' }}>Back</Typography>
          </Button>
        ) : (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              handleEdit({ meeting_id: applicationData.meeting_id });
            }}>
            <Typography sx={{ color: 'white.main' }}>Add Application</Typography>
          </Button>
        )}

        <IconButton
          aria-label='close'
          onClick={() => {
            closeEdit();
            setOpen(true);
          }}
          sx={{
            position: 'absolute',
            right: 5,
            top: 2,
            color: 'gray.light'
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!loading ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {open ? (
              <TopicOrDeviceForm
                fields={selectedAppl}
                handleSubmit={handleSubmit}
                fileUrls={pdfUrl}
                center='CDRH'
              />
            ) : (
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-around'
                style={{ maxHeight: '75vh', padding: '10px 10px' }}>
                <Box
                  style={{
                    width: '50%',
                    overflowY: 'auto',
                    maxHeight: '75vh',
                    padding: '4px'
                  }}>
                  {applicationData?.device_data?.length ? (
                    applicationData?.device_data?.map((ele: any) => (
                      <Card
                        sx={{ display: 'flex' }}
                        style={{ margin: '10px 0' }}
                        key={`${ele.application_number}`}>
                        <Box
                          className={classes.reviewBox}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative'
                          }}>
                          <Button
                            size='small'
                            type='button'
                            variant='contained'
                            onClick={() => {
                              handleEdit(ele);
                            }}
                            color='primary'
                            sx={styles.Button}
                            style={{
                              position: 'absolute',
                              right: 15,
                              top: 0,
                              color: 'gray.light'
                            }}>
                            Edit
                          </Button>
                          {ele.term && (
                            <Box padding={1}>
                              <Typography align='left' sx={styles.label}>
                                Topic
                              </Typography>
                              <Typography align='left' sx={styles.details}>
                                {ele.term}
                              </Typography>
                            </Box>
                          )}
                          {ele.application_number && (
                            <Box padding={1}>
                              <Typography align='left' sx={styles.label}>
                                Application Number
                              </Typography>
                              <Typography align='left' sx={styles.details}>
                                {ele.application_number}
                              </Typography>
                            </Box>
                          )}
                          {ele.brand_name && (
                            <Box padding={1}>
                              <Typography align='left' sx={styles.label}>
                                Brand Name
                              </Typography>
                              <Typography align='left' sx={styles.details}>
                                {ele.brand_name}
                              </Typography>
                            </Box>
                          )}
                          {ele.sponsor && (
                            <Box padding={1}>
                              <Typography align='left' sx={styles.label}>
                                sponsor
                              </Typography>
                              <Typography align='left' sx={styles.details}>
                                {ele.sponsor}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Box sx={{ border: '4px solid #d6d6d6', borderRadius: '4px' }}>
                      <Card style={{ margin: '10px 0', padding: '10px' }}>
                        <Box>
                          <Typography>No Application found</Typography>
                        </Box>
                      </Card>
                    </Box>
                  )}
                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'
                    sx={{ mt: '10px' }}>
                    <Autocomplete
                      id='qc_status'
                      value={curatedStatus}
                      onChange={(event: React.ChangeEvent<{}>, newValue: string | null): void => {
                        if (newValue !== null) {
                          setCuratedStatus(newValue);
                        }
                      }}
                      style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
                      options={qcStatus}
                      renderInput={(params: any) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          placeholder='QC Status'
                          label='QC Status'
                          variant='outlined'
                        />
                      )}
                    />
                    <Button
                      size='small'
                      type='button'
                      variant='contained'
                      color='primary'
                      sx={styles.Button}
                      onClick={() => {
                        handleQcStatus();
                      }}
                      style={{
                        right: 15,
                        top: 0,
                        color: 'gray.light'
                      }}>
                      Submit
                    </Button>
                  </Box>
                </Box>
                <Box
                  style={{
                    width: '50%',
                    overflowY: 'auto',
                    maxHeight: '75vh',
                    padding: '4px'
                  }}>
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Button
                      variant='contained'
                      sx={styles.Button}
                      disabled={!(selectedS3Link.s3_url || selectedS3Link.meeting_link)}
                      onClick={() => {
                        handleOpenPopup(selectedS3Link.s3_url || selectedS3Link.meeting_link);
                      }}>
                      <Typography>Open As a Pop-Up window</Typography>
                    </Button>
                  </Box>
                  <PDFViewer pdf={pdfUrl} setSelectedS3Link={setSelectedS3Link} />
                </Box>
              </Box>
            )}
          </>
        ) : (
          <Box display='flex' maxHeight='100vh' sx={{ overflow: 'hidden' }}>
            <Skeleton variant='rectangular' height='100vh' width='100%' />
          </Box>
        )}
      </DialogContent>
    </div>
  );
};

export default React.memo(DeviceCurationForm);
