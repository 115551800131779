import { memo, useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridColumnGroupingModel
} from '@mui/x-data-grid';
import {
  getAllSourcesStatistics,
  getEsIndexValidationProcessDetails
} from '../../api/DataQualityStatistics';
import styles from './styles/DataQualityStatistics.styles';
import {
  DB_SCHEMA_VALIDATION_SOURCE_MAPPING,
  ES_INDEX_VALIDATION_PROCESS_MAPPING
} from './contants';

const DataQualityStatistics = () => {
  const [statistics, setStatistics] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const getStatistics = async () => {
      setIsLoading(true);
      try {
        const response = await getAllSourcesStatistics();

        if (response?.status !== 200 || !response?.data?.Success) {
          setStatistics([]);
          return;
        }

        setStatistics(response.data.Success);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    getStatistics();
  }, []);

  const getErrorElement = (error: any) => {
    return (
      <Typography display='inline' sx={{ ...(error && error > 0 && styles.error) }}>
        {error}
      </Typography>
    );
  };

  const esIndexValidationProcessDetails = async (source: string, validationType: string) => {
    try {
      const response = await getEsIndexValidationProcessDetails(source, validationType);

      if (response?.status !== 200 || !response?.data?.Success) {
        return;
      }

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  const handleEsIndexValidationClick = (source: string, validationType: string) => {
    console.log(source, validationType);
  };

  const DATAGRID_COLUMNS: GridColDef[] = [
    {
      field: '#',
      headerName: '#',
      width: 25,
      renderCell: params => params.api.getAllRowIds().indexOf(params.id) + 1
    },
    { field: 'source', headerName: 'Source', width: 150 },
    {
      field: 'bastion',
      headerName: 'Invalid Files on Bastion',
      width: 200,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <TableContainer component={Paper} sx={{ backgroundColor: '#f7f7f7' }}>
            <Table size='small'>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    PDF
                  </TableCell>
                  <TableCell scope='row'>{getErrorElement(params?.row?.pdf_files_count)}</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    JSON
                  </TableCell>
                  <TableCell scope='row'>
                    {getErrorElement(params?.row?.json_files_count)}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    Others
                  </TableCell>
                  <TableCell scope='row'>
                    {getErrorElement(params?.row?.other_files_count)}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    Total
                  </TableCell>
                  <TableCell scope='row'>{params?.row?.total_files_count ?? 'NA'}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    },
    {
      field: 's3_validation',
      headerName: 'S3 Validation',
      width: 130,
      renderCell: () => {
        return <Typography>NA</Typography>;
      }
    },
    {
      field: 'kendra',
      headerName: 'Kendra Sync',
      width: 300,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <TableContainer component={Paper} sx={{ backgroundColor: '#f7f7f7', my: '20px' }}>
            <Table size='small'>
              <TableBody>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    Added
                  </TableCell>
                  <TableCell scope='row'>{params?.row?.kendra_documents_added}</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    Modified
                  </TableCell>
                  <TableCell scope='row'>{params?.row?.kendra_documents_modified}</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    Deleted
                  </TableCell>
                  <TableCell scope='row'>{params?.row?.kendra_documents_deleted}</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    Failed
                  </TableCell>
                  <TableCell scope='row'>
                    {getErrorElement(params?.row?.kendra_documents_failed)}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    Scanned
                  </TableCell>
                  <TableCell scope='row'>{params?.row?.kendra_documents_scanned}</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component='th' scope='row'>
                    Status
                  </TableCell>
                  <TableCell scope='row'>{params?.row?.kendra_status}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    },
    {
      field: 'refresh_date',
      headerName: 'Refresh Date',
      width: 120,
      renderCell: (params: GridRenderCellParams<any>) => {
        return <Typography>{params?.row?.refresh_date ?? 'NA'}</Typography>;
      }
    },
    {
      field: 'schema_validation',
      headerName: 'DB Schema Validation',
      width: 200,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <Link
            href={`/data_quality?schema=${
              DB_SCHEMA_VALIDATION_SOURCE_MAPPING[params?.row?.source?.toLowerCase()]
            }`}
            target='_blank'>
            {params?.row?.source?.toUpperCase() ?? 'Source'}
          </Link>
        );
      }
    },
    {
      field: 'es_index_validation',
      headerName: 'ES Index Validation',
      width: 250,
      renderCell: (params: GridRenderCellParams<any>) => {
        if (!params?.row?.es_validations) {
          return <Typography>Not integrated</Typography>;
        }
        return (
          <TableContainer component={Paper} sx={{ backgroundColor: '#f7f7f7' }}>
            <Table size='small'>
              <TableBody>
                {params?.row?.es_validations?.map((esValidation: any) => (
                  <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, cursor: 'pointer' }}
                    onClick={() =>
                      handleEsIndexValidationClick(
                        esValidation.source,
                        esValidation.validation_type
                      )
                    }>
                    <TableCell component='th' scope='row'>
                      {ES_INDEX_VALIDATION_PROCESS_MAPPING[esValidation.validation_type]}
                    </TableCell>
                    <TableCell scope='row'>
                      {esValidation.total_failed_entries}/{esValidation.total_entries} Failed
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
    }
  ];

  const columnGroupingModel: GridColumnGroupingModel = [
    {
      groupId: 'source_validation',
      headerName: 'Source Validation',
      children: [{ field: 'source_validation_status' }, { field: 'source_validation_latest_date' }]
    }
  ];

  return (
    <Box mt={10}>
      <Typography textAlign='center' pb={2}>
        Data Quality Statistics
      </Typography>
      <Box height='80vh' px={3} sx={styles.root}>
        <DataGrid
          experimentalFeatures={{ columnGrouping: true }}
          rows={statistics}
          columns={DATAGRID_COLUMNS}
          getRowId={row => row?.source}
          style={{ height: '100%' }}
          rowSpacingType='margin'
          density='standard'
          loading={isLoading}
          columnGroupingModel={columnGroupingModel}
          getRowHeight={() => 'auto'}
        />
      </Box>
    </Box>
  );
};

export default memo(DataQualityStatistics);
