import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme) => ({
  root: {
    //margin: theme.spacing(15),
    paddingTop: '10px',
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    '& .MuiDataGrid-root': {
      fontSize: '1.8vh',
      fontWeight: 'normal',
      height: 'inherit',
      color: theme.palette.datagrid.dataText,
      backgroundColor: theme.palette.white,
      border: 'unset',
      '& .MuiDataGrid-columnsContainer': {
        color: theme.palette.primary.lighter,
        backgroundColor: theme.palette.regulatory.lighterGreen,
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: '2.2vh',
        padding: '0 0px',
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold',
      },
      '& .MuiDataGrid-cell': {
        padding: '0 20px',
      },
      '& .MuiDataGrid-renderingZone': {
        backgroundColor: theme.palette.white,
      },
      '& .MuiDataGrid-footer': {
        backgroundColor: theme.palette.regulatory.lighterGreen,
      },
    },
  },
  typography: {
    padding: 8,
    fontSize: '1.8vh',
  },
  
  Heading: {
    color: '#3AB09E',
    fontSize: '2.5vh',
    textAlign: 'left',
    fontWeight: '600',
    marginTop: theme.spacing(2)
  },

  Button: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%', 
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white

  },
  dialog: {
    background: theme.palette.white
  },
  confirm: {
    background: '#3AB09E',
    color: theme.palette.primary,
    fontWeight: 'bold',
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
}));