import { useState } from 'react';
import { getSnapshotApplications } from '../../../api/submission_details';

const useSnapshotAPI = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [errMessage, setErrorMessage] = useState<string>('');
  const [applications, setApplications] = useState<Array<any>>([]);
  const [applicationsCount, setTotalApplications] = useState<number>(0);

  const getApplications = async (
    pageNo: number,
    pageSize: number,
    qcStatus: string,
    applicationNumber: string
  ) => {
    setLoading(true);
    setErrorMessage('');
    try {
      const response = await getSnapshotApplications(pageNo, pageSize, qcStatus, applicationNumber);
      if (response?.data?.Status === 200) {
        setApplications([...response.data.Success.applications]);
        setTotalApplications(response.data.Success.total);
      } else {
        setErrorMessage('Could not find Label for the given submission');
      }
    } catch (err) {
      console.error(err);
      setErrorMessage('Could not find Label for the given submission');
    } finally {
      setLoading(false);
    }
  };

  return {
    applications,
    loading,
    errMessage,
    applicationsCount,
    getApplications
  };
};

export default useSnapshotAPI;
