import DbStats from '../components/DbStats/DbStats';

const DBSTATS_ROUTES = [
  {
    id: 'db_stats',
    path: '/db_stats',
    component: DbStats,
    exact: true
  }
];
export default DBSTATS_ROUTES;