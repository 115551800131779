import makeRequest from './client';

// API for Indication and Control Number curations

export const getCanadaProductList = (
  pageSize: number,
  pageNo: number,
  status: string,
  vinNumber: string,
  tradeName: string,
  submissionNumber: string
) => {
  const baseURL = `/canada/product_monograph`;
  const queryParams = [`page=${pageNo}`, `page_size=${pageSize}`];
  if (status !== 'All Documents') {
    queryParams.push(`qc_status=${status}`);
  }
  if (vinNumber) {
    queryParams.push(`vin=${vinNumber}`);
  }
  if (tradeName) {
    queryParams.push(`brand_name=${tradeName}`);
  }
  if (submissionNumber) {
    queryParams.push(`control_number=${submissionNumber}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const updateMonographData = (id: string, payload: any) =>
  makeRequest(`/canada/product_monograph/${id}`, 'PUT', payload);

export const getLabelDetails = (id: string) =>
  makeRequest(`/canada/product_monograph/${id}`, 'GET');

// API for Page Number curations

export const getMonographDetails = (
  pageSize: number,
  pageNo: number,
  status: string,
  vinNumber: string,
  tradeName: string,
  submissionNumber: string,
  original: boolean
) => {
  const baseURL = `/canada/status/product_monograph`;
  const queryParams = [`page=${pageNo}`, `page_size=${pageSize}`];
  if (status !== 'All Documents') {
    queryParams.push(`qc_status=${status}`);
  }
  queryParams.push(`original=${original}`);
  if (vinNumber) {
    queryParams.push(`vin=${vinNumber}`);
  }
  if (tradeName) {
    queryParams.push(`brand_name=${tradeName}`);
  }
  if (submissionNumber) {
    queryParams.push(`control_number=${submissionNumber}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const getMonographPageNoDetails = (id: string, original: boolean) =>
  makeRequest(`/canada/page_numbers/product_monographs/${id}?original=${original}`, 'GET');

export const updateMonographPageNoDetails = (id: string, payload: any, original: boolean) =>
  makeRequest(`/canada/page_numbers/product_monographs/${id}?original=${original}`, 'PUT', payload);

// API for label separation

export const getMonograph = (id: string) =>
  makeRequest(`/canada/page_separation/product_monograph/${id}`, 'GET');

export const submitMonographInfo = (id: string, payload: any) =>
  makeRequest(`/canada/page_separation/product_monograph/${id}`, 'PUT', payload);
