import { useState, SyntheticEvent, memo } from 'react';

// material ui
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { LinearProgress } from '@material-ui/core';

// api
import { DataGrid, GridSelectionModel } from '@mui/x-data-grid';

import { SourceColumnMapping } from './constants';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';
import styles from '../Datagrid/Datagrid.styles';

const MultiApplAtcCode = ({
  closeEdit,
  activeIngredient,
  source,
  applicationList,
  handleSubmit,
  applicationSelected
}: any) => {
  const [selectedApplications, setSelectedApplications] = useState<GridSelectionModel>(
    applicationSelected || []
  );
  const columns = SourceColumnMapping[source];

  const handleKeyFormSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleSubmit(selectedApplications);
  };
  return (
    <Container style={{ maxWidth: '100%', height: '80vh' }}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h3'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '22px' }}>
            Select Applications - {activeIngredient}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      </div>

      <DataGrid
        sx={styles.datagrid}
        style={{ height: '80%' }}
        rowSpacingType='margin'
        checkboxSelection
        density='standard'
        rows={applicationList}
        columns={columns as any}
        onSelectionModelChange={(newRowSelection: GridSelectionModel) =>
          setSelectedApplications(newRowSelection)
        }
        selectionModel={selectedApplications}
        getRowId={row => row.id}
        getRowClassName={() => `table-rows`}
        hideFooterPagination
        components={{
          LoadingOverlay: LinearProgress,
          NoRowsOverlay: DatagridNoRowsOverlay
        }}
      />
      <Button
        type='button'
        fullWidth
        onClick={(e: any) => handleKeyFormSubmit(e)}
        variant='contained'
        color='primary'
        style={{ color: 'white' }}>
        SUBMIT
      </Button>
    </Container>
  );
};

export default memo(MultiApplAtcCode);
