import React from 'react';
import { useParams } from 'react-router-dom';
// other components
import PNReviewFda from './PNReviewFda';
import ReviewPageFda from './ReviewPageFda';

const QCDocumentCheck = () => {
  const { applNo, subType, subNo, docType } = useParams();

  return (
    <>
      {docType === 'Letter' || docType === 'letter' ? (
        <ReviewPageFda
          applNo={applNo && applNo}
          subType={subType}
          subNo={subNo}
          docType={docType}
        />
      ) : null}

      <PNReviewFda
        applNo={applNo && applNo}
        subType={subType}
        subNo={subNo}
        docType={docType}
      />
    </>
  );
};

export default QCDocumentCheck;
