import React, { useState, useCallback, useContext } from 'react';
import { Auth } from 'aws-amplify';

// mui
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  TextField,
  Box,
  Button,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// api
import { saveOrSubmitMeetingDetails } from '../../api/AdcommModule';

// constant
import { MEETING_FORM_MAPPING, urlRegex } from './constant';

// styles
import { styles } from './styles/meetingDashbaord';
import CurationForm from '../CustomDashboard/CurationForm';

// utils
import getDataFields, { getFieldsFromApiData } from '../CustomDashboard/utils/getDataFields';
import Actions from '../../store/actions';
import Store from '../../store';

const AdcommNewMeeting = ({ openUrlPopUp, setOpenUrlPopup }: any) => {
  const { dispatch } = useContext<any>(Store);
  const [webLink, setWebLink] = useState<string>('');
  const [openForm, setOpenForm] = useState<boolean>(false);
  const [meetingData, setMeetingData] = useState<any>([]);

  const handleOpenPopup = useCallback(() => {
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow = window.open(webLink, '_blank', option) as any;

    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
        setOpenUrlPopup(true);
      }
    }, 1000);
  }, []);

  const handleSave = useCallback(() => {
    setOpenForm(true);
    setMeetingData(getFieldsFromApiData([], MEETING_FORM_MAPPING));
  }, []);

  const handleSubmit = async (e: React.SyntheticEvent, data: any) => {
    try {
      if (Object.keys(data).length !== 0) {
        const user = await Auth.currentUserInfo();
        const payload = {
          ...getDataFields(data),
          curator_email: user.attributes.email,
          curator_name: user.attributes['custom:user']
        };
        const res = await saveOrSubmitMeetingDetails(payload, 'add');
        if (res.data.status === 200) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Meeting added successfully', color: 'success' }
          });
        }
      }
    } catch (error: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    } finally {
      setOpenForm(false);
      setOpenUrlPopup(false);
    }
  };

  return (
    <Dialog maxWidth='lg' fullWidth open={openUrlPopUp} disableEscapeKeyDown>
      <DialogTitle id='alert-dialog-title'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '20px' }}>
            {openForm ? `Add New Meeting` : `Adcomm URL`}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              setOpenUrlPopup(false);
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {openForm ? (
          <CurationForm
            fields={meetingData}
            handleSubmit={handleSubmit}
            fileUrls={[{ s3_url: webLink }]}
          />
        ) : (
          <Box display='flex' flexDirection='column'>
            <DialogContentText>Are you sure you have the correct url?</DialogContentText>
            <TextField
              required
              variant='outlined'
              fullWidth
              value={webLink}
              error={!urlRegex.test(webLink)}
              helperText={
                !urlRegex.test(webLink)
                  ? 'Invalid url'
                  : 'Kindly ensure you have copied the entire url'
              }
              onChange={e => setWebLink(e.target.value)}
              placeholder='Meeting url'
            />
          </Box>
        )}
      </DialogContent>
      {!openForm && (
        <DialogActions>
          <Button
            variant='contained'
            color='secondary'
            sx={styles.Button2}
            disabled={!urlRegex.test(webLink)}
            onClick={() => handleOpenPopup()}>
            Open link
          </Button>
          <Button
            variant='contained'
            onClick={handleSave}
            color='secondary'
            disabled={!urlRegex.test(webLink)}
            sx={styles.Button2}>
            Save link
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};
export default React.memo(AdcommNewMeeting);
