import * as React from 'react';
import { useContext, useEffect, useState, useCallback } from 'react';
import { AppBar, Button, DialogContent, Toolbar, Typography } from '@material-ui/core';
import IconButton from '@mui/material/IconButton';
import { ArrowBack } from '@mui/icons-material';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { Box, Grid } from '@mui/material';
import TextField from '@material-ui/core/TextField';
import Styles from './styles/GuidanceList.styles';
import ReviewStyles from '../DataEntry/styles/Review.styles';
import AdminStore from '../../store/AdminPage';
import GuidanceAcceptance from './GuidanceAcceptance';
import Actions from '../../store/actions';
import Loading from './GuidanceLoading';
import Store from '../../store';
import { getLabelComparison, s3toPdf, updateGuidanceQcd } from '../../api/AdminPage';
import AdminActions from '../../store/AdminPage/actions';

// eslint-disable-next-line react/prop-types
const CustomTextField = ({ documentType, name, value, disabled, handleChange }) => {
  const { adminState } = useContext(AdminStore);
  const [disable, setDisable] = useState('');
  const [valueDifferent, setValueDifferent] = useState(false);
  const classes = ReviewStyles();
  const guidanceClasses = Styles();
  // eslint-disable-next-line react/prop-types
  const label = name.split('_').join(' ');

  useEffect(() => {
    if (
      adminState.qcedGuidanceCompare[name] &&
      adminState.nonQcedGuidanceCompare[name] &&
      !disabled
    ) {
      setValueDifferent(
        adminState.qcedGuidanceCompare[name].trim().toLowerCase() !==
          adminState.nonQcedGuidanceCompare[name].trim().toLowerCase()
      );
    }
  }, [adminState, disabled, value]);

  const handleClick = (e, name, value, label) => {
    e.preventDefault();
    setDisable(label);
    handleChange(name, value);
  };

  return (
    <Box
      display='flex'
      width='90%'
      flex={1}
      justifyContent='space-evenly'
      margin='1em auto'
      alignContent='center'>
      <Box width='45%' marginRight='2em' height='min-content' margin='auto'>
        <Typography variant='body1' component='div' className={classes.labelName}>
          {label}
        </Typography>
      </Box>
      <TextField
        multiline
        required
        className={`${classes.textfield} ${classes.textFieldSpacing} `}
        onChange={e => handleChange(name, e.target.value)}
        value={value}
        fullWidth
        disabled={disable !== label}
        id={label}
        inputRef={input => input?.focus()}
        InputProps={{
          classes: {
            input: `${classes.input} ${valueDifferent ? guidanceClasses.textFieldDifferent : ''}`
          },
          disableUnderline: true
        }}
      />
      {documentType === 'nonqced' && (
        <IconButton aria-label='delete' size='large'>
          <ModeEditOutlineOutlinedIcon
            onClick={e => {
              handleClick(e, name, value, label);
            }}
            sx={{ color: 'white' }}
          />
        </IconButton>
      )}
    </Box>
  );
};
// eslint-disable-next-line react/prop-types
const GuidanceCompare = ({ onClose }) => {
  const { adminState, adminDispatch } = useContext(AdminStore);
  const { dispatch } = useContext(Store);
  const classes = Styles();

  const [localNonQcedGuidance, setLocalNonQcedGuidance] = useState({});
  const [localQcedGuidance, setLocalQcedGuidance] = useState({});
  const [comparePdf, setComparePdf] = useState('');
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);

  const handleChange = (key, val) => {
    const localNonQced = { ...localNonQcedGuidance };
    localNonQced[key] = val;
    setLocalNonQcedGuidance(localNonQced);

    const localQced = { ...localQcedGuidance };
    localQced[key] = val;
    setEditMode(true);
    setLocalQcedGuidance(localQced);
  };
  const handleComparePdf = async () => {
    if (comparePdf) {
      setComparePdf('');
    } else {
      try {
        setLoading(true);
        const pdfResponse = await s3toPdf({
          qced: localQcedGuidance.s3_file_path,
          nonQced: localNonQcedGuidance.s3_file_path
        });
        if (pdfResponse.data && pdfResponse.data.Success) {
          const response = await getLabelComparison({
            parent_object: pdfResponse.data.Success.qced_url,
            child_object: pdfResponse.data.Success.non_qced_url
          });
          if (response.data && response.data.success) {
            setComparePdf(response.data.success);
          } else {
            throw new Error(response.data.Error);
          }
        } else {
          throw new Error(pdfResponse.data.Error);
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Something went wrong' }
        });
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const payload = localQcedGuidance;
      payload.is_updated = true;
      const res = await updateGuidanceQcd(payload);
      if (res.data.Status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'something went wrong' }
        });
        setLocalQcedGuidance({ ...adminState.qcedGuidanceCompare });
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Successfully Added the application', color: 'success' }
        });
        await adminDispatch({
          type: AdminActions.SET_QCED_GUIDANCE_COMPARE,
          value: localQcedGuidance
        });
        await adminDispatch({
          type: AdminActions.SET_NON_QCED_GUIDANCE_COMPARE,
          value: localNonQcedGuidance
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Something went wrong' }
      });
      setLocalQcedGuidance({ ...adminState.qcedGuidanceCompare });
    }
    setEditMode(false);
  };

  const handleClose = async () => {
    if (!editMode) {
      onClose();
    } else {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'submit the changes' }
      });
    }
  };

  const handleCancel = async () => {
    setLocalQcedGuidance({ ...adminState.qcedGuidanceCompare });
  };

  const sortLocalQcedGuidance = useCallback(
    qecdGuidanceData => {
      const sortedGuidanceData = Object.entries(qecdGuidanceData).sort(([key1], [key2]) => {
        if (key1 === 'is_updated') {
          return 1;
        }
        if (key2 === 'is_updated') {
          return -1;
        }
        return 0;
      });

      return sortedGuidanceData;
    },
    [localQcedGuidance]
  );

  useEffect(() => {
    if (adminState.nonQcedGuidanceCompare) {
      setLocalNonQcedGuidance({ ...adminState.nonQcedGuidanceCompare });
    }
    if (adminState.qcedGuidanceCompare) {
      setLocalQcedGuidance({ ...adminState.qcedGuidanceCompare });
    }
  }, [adminState.nonQcedGuidanceCompare, adminState.qcedGuidanceCompare]);
  if (loading) return <Loading dataLoading />;
  return (
    <>
      <AppBar sx={{ position: 'relative' }} className={classes.appBar}>
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
            <ArrowBack />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <Button
            variant='outlined'
            color='secondary'
            className={classes.button}
            disabled={!editMode}
            onClick={handleSubmit}>
            Submit
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.button}
            disabled={!editMode}
            onClick={handleCancel}>
            Reset
          </Button>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.button}
            onClick={handleComparePdf}>
            {comparePdf ? 'See original view' : 'Compare PDF'}
          </Button>
          <GuidanceAcceptance
            isReject
            onClose={onClose}
            documents={adminState.nonQcedGuidanceCompare}
          />
          <GuidanceAcceptance onClose={onClose} documents={localNonQcedGuidance} />
        </Toolbar>
      </AppBar>
      <DialogContent style={{ marginTop: '90px' }}>
        {comparePdf ? (
          <iframe className={classes.iframe} src={comparePdf} title='wr docs' />
        ) : (
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <Box className={`${classes.pdfContainer} ${classes.formSection}`}>
                <Typography variant='h6'>Qced document</Typography>
                <Box display='flex' flexDirection='column'>
                  {localQcedGuidance &&
                    sortLocalQcedGuidance(localQcedGuidance).map(([key, value]) => {
                      if (key.trim().toLowerCase() === 's3_file_path') {
                        return null;
                      }
                      return (
                        <Box key={`${key}${value}`} sx={{ marginTop: '1em' }} width='100%'>
                          <CustomTextField documentType='qced' name={key} value={value} disabled />
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className={`${classes.pdfContainer} ${classes.formSection}`}>
                <Typography variant='h6'>Non Qced document</Typography>
                <Box display='flex' flexDirection='column'>
                  {localNonQcedGuidance &&
                    Object.entries(localNonQcedGuidance).map(([key, value]) => {
                      if (
                        key.trim().toLowerCase() === 'status' ||
                        key.trim().toLowerCase() === 'comment' ||
                        key.trim().toLowerCase() === 's3_file_path'
                      ) {
                        return null;
                      }
                      return (
                        <Box key={`${key}nonqced`} sx={{ marginTop: '1em' }} width='100%'>
                          <CustomTextField
                            documentType='nonqced'
                            name={key}
                            value={value}
                            handleChange={handleChange}
                          />
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </>
  );
};
export default GuidanceCompare;
