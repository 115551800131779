import React, { useContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { useHistory, useLocation } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { DataGrid } from '@mui/x-data-grid/DataGrid';

import Dialog from '@mui/material/Dialog';

import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Store from '../../store';
import Actions from '../../store/actions';

import styles from '../Datagrid/Datagrid.styles';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';

import themev5 from '../../themev5';

import Styles from './styles/CategorizationTable.styles';
import renderCellExpand from '../Datagrid/DatagridPopover';
import { getInvalidPdfData } from '../../api/Cder';
import InvalidPdfUrlDialog from './InvalidPdfUrlDialog';

const InvalidFileUrlCuration = () => {
  const { dispatch } = useContext<any>(Store);
  const location = useLocation();
  const history = useHistory();

  const updatedSearchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const classes = Styles();

  const [applicationNumber, setApplicationNumber] = useState('');
  const [status, setStatus] = useState('all');

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState();
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(false);

  const [openEdit, setOpenEidt] = useState(null);
  const [invalidPdfUrlData, setInvalidPdfUrlData] = useState<Array<any>>([]);

  const rowsPerPageOptions = [10, 20, 50, 100];

  const closeEdit = () => {
    setOpenEidt(null);
    setReload(true);
  };

  const columns = [
    {
      field: 'Edit',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              setOpenEidt(params.row);
            }}>
            <Typography sx={{ color: 'white.main' }}>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'document_id',
      headerName: 'Document Id',
      width: 350,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'application_number',
      headerName: 'Application',
      width: 100,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'current_url',
      headerName: 'Current Url',
      width: 500,
      headerAlign: 'center',
      align: 'center',
      renderCell: renderCellExpand
    },
    {
      field: 'qc_status',
      headerName: 'Status',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'updated_url',
      headerName: 'Updated Url',
      width: 500,
      headerAlign: 'center',
      align: 'center',
      renderCell: renderCellExpand
    },
    {
      field: 'reason',
      headerName: 'Reason',
      width: 500,
      headerAlign: 'center',
      align: 'center',
      renderCell: renderCellExpand
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 150,
      headerAlign: 'center',
      align: 'center'
    }
  ];

  useEffect(() => {
    const curPage = updatedSearchParams.get('page') || '0';
    if (curPage !== pageNumber.toString()) {
      setPageNumber(parseInt(curPage, 10));
    }
    const curPageSize = updatedSearchParams.get('pageSize') || '10';
    if (curPageSize !== pageSize.toString()) {
      setPageSize(parseInt(curPageSize, 10));
    }
    history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
  }, []);

  useEffect(() => {
    const fetchPediatricIndications = async () => {
      try {
        setLoading(true);
        const result = await getInvalidPdfData(
          pageNumber,
          pageSize,
          status,
          applicationNumber.trim().toLowerCase()
        );
        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.results) {
          const resultData = result.data.body.results;
          setInvalidPdfUrlData(resultData);
          setTotalRowCount(result.data.body.total_rows);
        } else {
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      } finally {
        setLoading(false);
        setReload(false);
      }
    };
    fetchPediatricIndications();
  }, [pageNumber, status, pageSize, applicationNumber, reload]);

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Grid container>
        <Grid item xl={10} lg={10}>
          <Box display='flex' ml={2} mt={10} alignItems='center'>
            <TextField
              variant='outlined'
              type='search'
              id='application_number'
              label='Search Application Number'
              value={applicationNumber}
              InputProps={{
                style: { fontSize: 16, minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: 16 }
              }}
              onChange={(e: any) => {
                setApplicationNumber(e.target.value);
              }}
            />
            <TextField
              variant='outlined'
              id='status'
              label='QC Status'
              select
              value={status}
              onChange={e => setStatus(e.target.value)}
              InputProps={{
                style: { fontSize: 16, minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: 16 }
              }}>
              <MenuItem value='all'>All</MenuItem>
              <MenuItem value='ToQced'>To Qced</MenuItem>
              <MenuItem value='Escalate'>Escalate</MenuItem>
              <MenuItem value='Qced'>Qced</MenuItem>
              <MenuItem value='Curated'>Curated</MenuItem>
              <MenuItem value='Confirmed Missing'>Confirmed Missing</MenuItem>
            </TextField>
          </Box>
        </Grid>
      </Grid>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={invalidPdfUrlData}
          columns={columns as any}
          rowCount={totalRowCount}
          pageSize={pageSize}
          getRowId={row => row.document_id}
          onPageSizeChange={(newPageSize: any) => {
            setPageSize(newPageSize);
            setPageNumber(0);
            updatedSearchParams.set('page', '0');
            updatedSearchParams.set('pageSize', newPageSize.toString());
            history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
          }}
          getRowHeight={() => 'auto'}
          onPageChange={(page: any) => {
            setPageNumber(page);
            updatedSearchParams.set('page', page.toString());
            history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={pageNumber}
          paginationMode='server'
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        <Dialog
          open={!!openEdit}
          onClose={closeEdit}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='sm'
          fullWidth>
          {openEdit ? <InvalidPdfUrlDialog closeEdit={closeEdit} fileData={openEdit} /> : null}
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(InvalidFileUrlCuration);
