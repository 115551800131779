import React, { useState, useEffect, useMemo } from 'react';

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  IconButton
} from '@mui/material';

import { Close } from '@mui/icons-material';
import { getWhatsNewById } from '../../api/WhatsNew';
import { WHATS_NEW_VISUAL_TYPES } from './constants';

interface Update {
  id: string;
  title: string;
  content: string;
  visual_type: string;
  visual_url: string;
  link_text: string;
  link_url: string;
}

interface WhatsNewEntry {
  id: number;
  type: string;
  description: string;
  status: string;
  title: string;
  added_by: string;
  approved_by: string;
  published_by: string;
  published_on: string;
  created_at: string;
  updated_at: string;
  updates: Update[];
}

interface ViewProps {
  showViewDialog: boolean;
  closeViewDialog: () => void;
  id: number | null;
}

const View: React.FC<ViewProps> = ({ showViewDialog, closeViewDialog, id }) => {
  const [whatNewEntry, setWhatNewEntry] = useState<WhatsNewEntry | null>();
  const [currentUpdateIndex, setCurrentUpdateIndex] = useState<number>(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!id || !showViewDialog) {
          return;
        }

        const result = await getWhatsNewById(id);

        const entry = result?.data?.body;

        setWhatNewEntry(entry);
        setCurrentUpdateIndex(0);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [showViewDialog, id]);

  const handlePrev = () => {
    setCurrentUpdateIndex(prevIndex => Math.max(prevIndex - 1, 0));
  };

  const handleNext = () => {
    setCurrentUpdateIndex(prevIndex =>
      whatNewEntry?.updates ? Math.min(prevIndex + 1, whatNewEntry.updates.length - 1) : prevIndex
    );
  };

  const currentUpdate = useMemo(
    () => whatNewEntry?.updates[currentUpdateIndex],
    [whatNewEntry, currentUpdateIndex]
  );

  return (
    <Dialog open={showViewDialog} onClose={closeViewDialog} maxWidth='lg' fullWidth>
      <DialogTitle>View</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={closeViewDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
        <Close />
      </IconButton>
      <DialogContent>
        <Box m={1}>
          <Typography variant='body1'>
            <strong>Main Title:</strong> {whatNewEntry?.title}
          </Typography>
          <Typography variant='body1'>
            <strong>Description:</strong> {whatNewEntry?.description}
          </Typography>
          <Typography variant='body1'>
            <strong>Update Type:</strong> {whatNewEntry?.type}
          </Typography>
          <br />

          {currentUpdate && (
            <Box>
              <Typography variant='body1'>
                <strong>Title:</strong> {currentUpdate.title}
              </Typography>
              <Typography variant='body1'>
                <strong>Content:</strong>{' '}
                <Box dangerouslySetInnerHTML={{ __html: currentUpdate.content }} />
              </Typography>
              <Typography variant='body1'>
                <strong>Visual Type:</strong> {currentUpdate.visual_type}
              </Typography>
              <Typography variant='body1'>
                <strong>Visual URL:</strong> {currentUpdate.visual_url}
              </Typography>
              <Typography variant='body1'>
                <strong>Link Text:</strong> {currentUpdate.link_text}
              </Typography>
              <Typography variant='body1'>
                <strong>Link URL:</strong> {currentUpdate.link_url}
              </Typography>

              {currentUpdate.visual_type === WHATS_NEW_VISUAL_TYPES.image && (
                <Box>
                  <img src={currentUpdate.visual_url} alt={currentUpdate.title} width='476px' />
                </Box>
              )}

              {currentUpdate.visual_type === WHATS_NEW_VISUAL_TYPES.video && (
                <Box>
                  {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                  <video controls src={currentUpdate.visual_url} controlsList='nodownload' />
                </Box>
              )}
            </Box>
          )}

          {whatNewEntry && whatNewEntry?.updates?.length > 1 && (
            <Box display='flex' justifyContent='space-between' mt={2}>
              <Button variant='contained' onClick={handlePrev} disabled={currentUpdateIndex === 0}>
                Prev
              </Button>
              <Button
                variant='contained'
                onClick={handleNext}
                disabled={
                  whatNewEntry?.updates && currentUpdateIndex === whatNewEntry.updates.length - 1
                }>
                Next
              </Button>
            </Box>
          )}

          <br />
          <Typography variant='body1'>
            <strong>Status:</strong> {whatNewEntry?.status}
          </Typography>
          <Typography variant='body1'>
            <strong>Published On:</strong> {whatNewEntry?.published_on}
          </Typography>
          <Typography variant='body1'>
            <strong>Added By:</strong> {whatNewEntry?.added_by}
          </Typography>
          <Typography variant='body1'>
            <strong>Created On:</strong> {whatNewEntry?.created_at}
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default View;
