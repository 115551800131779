import { useState, useEffect, useContext, SyntheticEvent, memo } from 'react';
import { Auth } from 'aws-amplify';

// material ui
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { MenuItem } from '@material-ui/core';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { fetchLimitationCurationDetails, submitLimitationCurationDetails } from '../../api/Cder';

const LimitationCurationModal = ({ closeEdit, docsId, handleNext }: any) => {
  const { dispatch } = useContext<any>(Store);
  const [mainIndication, setmainIndication] = useState('');
  const [limitationText, setLimitationTextText] = useState('');
  const [status, setstatus] = useState('');
  const [labelType, setLabelType] = useState('');
  const [url, seturl] = useState('');
  useEffect(() => {
    const getURL = async () => {
      try {
        const result = await fetchLimitationCurationDetails(docsId);
        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.result) {
          const resultData = result.data.body.result;
          seturl(resultData.document_link);
          setLimitationTextText(resultData.limitations_of_use_text);
          setmainIndication(resultData.main_indications_and_usage_text);
          setstatus(resultData.qc_status);
          setLabelType(resultData.label_type);
        } else {
          throw Error('Cannot Show PDF');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getURL();
  }, []);

  const handleKeyFormSubmit = async (
    event: SyntheticEvent<HTMLFormElement>,
    isNextSubmit = false
  ) => {
    event.preventDefault();
    const user = await Auth.currentUserInfo();

    const payload = {
      limitations_of_use_text: limitationText,
      main_indications_and_usage_text: mainIndication,
      qc_status: status.toLowerCase(),
      curator_email: user.attributes.email,
      curator_name: user.attributes['custom:user'],
      document_id: docsId
    };
    const res = await submitLimitationCurationDetails(docsId, payload);
    const resData = res.data;
    if (resData.error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: resData.message }
      });
    } else {
      if (isNextSubmit) {
        handleNext(labelType);
      } else {
        closeEdit();
      }
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: resData.body, color: 'success' }
      });
    }
  };

  const handleNextSubmit = (event: any) => {
    event.preventDefault();
    handleKeyFormSubmit(event, true);
  };

  return (
    <Container>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Limitation Curation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Grid container spacing={2} display='flex'>
          <Grid item xs={12} lg={6}>
            <form onSubmit={handleKeyFormSubmit} style={{ marginTop: '20px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='limitation_and_use'
                    label='Limitation and Use'
                    value={limitationText}
                    minRows={3}
                    maxRows={Infinity}
                    multiline
                    onChange={e => setLimitationTextText(e.target.value)}
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '2vh' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='main_indication_text'
                    label='Main Indication Text'
                    value={mainIndication}
                    minRows={2}
                    maxRows={Infinity}
                    multiline
                    onChange={e => setmainIndication(e.target.value)}
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '2vh' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    id='status'
                    label='QC Status'
                    select
                    fullWidth
                    required
                    value={status}
                    onChange={e => setstatus(e.target.value)}
                    InputProps={{
                      style: { fontSize: '1.5vh', minWidth: '250px' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '1.5vh' }
                    }}>
                    <MenuItem value='qced'>Qced</MenuItem>
                    <MenuItem value='to_qc'>To QC</MenuItem>
                    <MenuItem value='escalate'>Escalate</MenuItem>
                    <MenuItem value='missing details'>Missing Details</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} display='flex' direction='row' justifyContent='space-around'>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{ color: 'white', width: '45%' }}>
                    SUBMIT
                  </Button>
                  <Button
                    type='button'
                    variant='contained'
                    color='primary'
                    onClick={e => handleNextSubmit(e)}
                    style={{ color: 'white', width: '45%' }}>
                    NEXT
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} lg={6}>
            {url ? null : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{ height: '100%', width: '100%' }}>
                <Typography style={{ fontSize: '20px', fontWeight: 'bold', color: '#33B187' }}>
                  Can&apos;t Open This PDF{' '}
                </Typography>
              </Box>
            )}
            {url ? (
              <Box display='flex' justifyContent='center'>
                <iframe
                  title='pdf'
                  src={url}
                  style={{ height: '75vh', width: '50vw', marginTop: '20px', position: 'relative' }}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default memo(LimitationCurationModal);
