import { cloneDeep } from 'lodash';

export interface DataField {
  id: string;
  label: string;
  type: string;
  options?: Array<any>; // date_range, text_area_field, text_field, dropdown, radio_type
  value?: any;
}

export const getFieldsFromApiData = (apiData: any, dataFieldMapping: Array<DataField>) => {
  const filters = cloneDeep(
    dataFieldMapping.map((fltr: { [key: string]: any }) => ({
      ...fltr,
      options: [...(fltr?.options || [])]
    }))
  );

  filters.forEach((filter: { [key: string]: any }) => {
    if (apiData[filter.id]) {
      // eslint-disable-next-line no-param-reassign
      filter.value = apiData[filter.id];
    }
  });

  return filters;
};

const getDataFields = (payload: Array<DataField>) => {
  const data: { [key: string]: any } = {};
  payload.forEach((fields: DataField) => {
    data[fields.id] = fields.value;
  });
  return data;
};

export default getDataFields;
