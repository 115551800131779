import monographCuration from '../components/Canada/monographCuration';
import MonographPageNoCuration from '../components/Canada/MonographPageNoCuration';
import LabelSection from '../components/Canada/LabelSection';

const CANADA_ROUTES = [
  {
    id: 'monographCuration',
    path: '/canada',
    component: monographCuration,
    exact: true
  },
  {
    id: 'monographPageNoCuration',
    path: '/canada/monograph',
    component: MonographPageNoCuration,
    exact: true
  },
  {
    id: 'labelSeparationCuration',
    path: '/canada/label_separation',
    component: LabelSection,
    exact: true
  }
];
export default CANADA_ROUTES;
