/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect, useState } from 'react';

// muiv5
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import TextField from '@material-ui/core/TextField';

// Custom Components
import VivproDatagrid from '../Datagrid';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// styles
import styles from './styles/ApprovalTable.styles';

// api
import { getAllFdaData } from '../../api/AdminPage';
import ApprovalEditDialog from './ApprovalEditDialog';
import ApprovalCreateDialog from './ApprovalCreateDialog';
import { DEFAULT_DATE } from '../../const';

export interface ApprovalBaseRecordProps {
  appl_num: string;
  appl_title: string;
  submission_type: string;
  submission_num: string;
  id: string;
  status?: string;
  aa_date?: string | null | undefined;
  aa_indication?: string;
  conversion_withdrawal_status?: string | null | undefined;
  full_approval_conversion_withdrawal_date?: string | null | undefined;
  breakthrough_indication?: string;
  designation_date?: string | null | undefined;
  rescinded_date?: string | null | undefined;
  date_source?: string;
  ft_indication?: string;
  pepfar_flag?: string;
  email?: string;
  name?: string;
  docType: string;
  qc_status?: string;
  rtorsubmissiondate?: string | null | undefined;
  rtorfullsubmissiondate?: string | null | undefined;
  // eslint-disable-next-line no-unused-vars
  closeEdit?: (res: any, reason: any, prevId: string) => void;
}

const ApprovalTable = ({ docType }: { docType: string }) => {
  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  const { state, dispatch } = useContext(Store);
  const [approvalData, setApprovalData] = useState<ApprovalBaseRecordProps[]>([]);
  const [searchApprovalData, setSearchApprovalData] = useState<ApprovalBaseRecordProps[]>([]);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showCreateDialog, setShowCreateDialog] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [editRow, setEditRow] = useState<ApprovalBaseRecordProps>({
    appl_num: '',
    appl_title: '',
    submission_type: '',
    submission_num: '',
    id: '',
    status: '',
    aa_date: '',
    aa_indication: '',
    conversion_withdrawal_status: '',
    full_approval_conversion_withdrawal_date: '',
    breakthrough_indication: '',
    docType: '',
    designation_date: '',
    rescinded_date: '',
    date_source: ''
  });

  const removeDefaultDate = (data: ApprovalBaseRecordProps) => {
    const newObj = { ...data };
    if (newObj.aa_date && newObj.aa_date === DEFAULT_DATE) {
      newObj.aa_date = null;
    }
    if (
      newObj.full_approval_conversion_withdrawal_date &&
      newObj.full_approval_conversion_withdrawal_date === DEFAULT_DATE
    ) {
      newObj.full_approval_conversion_withdrawal_date = null;
    }
    if (newObj.designation_date && newObj.designation_date === DEFAULT_DATE) {
      newObj.designation_date = null;
    }
    if (newObj.rescinded_date && newObj.rescinded_date === DEFAULT_DATE) {
      newObj.rescinded_date = null;
    }
    if (newObj.rtorfullsubmissiondate && newObj.rtorfullsubmissiondate === DEFAULT_DATE) {
      newObj.rtorfullsubmissiondate = null;
    }
    if (newObj.rtorsubmissiondate && newObj.rtorsubmissiondate === DEFAULT_DATE) {
      newObj.rtorsubmissiondate = null;
    }
    return newObj;
  };

  const filterSearch = (text: string, data: ApprovalBaseRecordProps[]) => {
    if (text) {
      setSearchApprovalData(
        data.filter(dt => dt.appl_title.toLowerCase().includes(text.trim().toLowerCase()))
      );
    } else {
      setSearchApprovalData(data);
    }
  };

  const openEdit = (data: ApprovalBaseRecordProps) => {
    try {
      setShowEditDialog(true);
      setEditRow(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const closeEdit = (
    editedData: ApprovalBaseRecordProps | null,
    reason: string,
    prevId: string
  ) => {
    try {
      if (reason && reason === 'backdropClick') {
        return;
      }
      if (editedData) {
        const index = approvalData.findIndex(row => row.id === prevId);
        const newApprovalData = [...approvalData];
        const newEditedData = removeDefaultDate(editedData);
        newApprovalData.splice(index, 1, newEditedData);
        switch (docType) {
          case 'breakthrough':
            newApprovalData.sort(
              (a, b) =>
                a.appl_num.localeCompare(b.appl_num) ||
                a.submission_type.localeCompare(b.submission_type) ||
                a.submission_num.localeCompare(b.submission_num) ||
                (a.breakthrough_indication as string).localeCompare(
                  b.breakthrough_indication as string
                )
            );
            break;
          case 'fasttrack':
            newApprovalData.sort(
              (a, b) =>
                a.appl_num.localeCompare(b.appl_num) ||
                a.submission_type.localeCompare(b.submission_type) ||
                a.submission_num.localeCompare(b.submission_num) ||
                (a.ft_indication as string).localeCompare(b.ft_indication as string)
            );
            break;
          default:
            newApprovalData.sort(
              (a, b) =>
                a.appl_num.localeCompare(b.appl_num) ||
                a.submission_type.localeCompare(b.submission_type) ||
                a.submission_num.localeCompare(b.submission_num)
            );
        }

        setApprovalData(newApprovalData);
        filterSearch(searchText, newApprovalData);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setShowEditDialog(false);
  };

  const closeCreate = (createdData: ApprovalBaseRecordProps | null, reason: string) => {
    try {
      if (reason && reason === 'backdropClick') {
        return;
      }
      if (createdData) {
        const newCreatedData = removeDefaultDate(createdData);
        const newApprovalData = [...approvalData, newCreatedData];

        switch (docType) {
          case 'breakthrough':
            newApprovalData.sort(
              (a, b) =>
                a.appl_num.localeCompare(b.appl_num) ||
                a.submission_type.localeCompare(b.submission_type) ||
                a.submission_num.localeCompare(b.submission_num) ||
                (a.breakthrough_indication as string).localeCompare(
                  b.breakthrough_indication as string
                )
            );
            break;
          case 'fasttrack':
            newApprovalData.sort(
              (a, b) =>
                a.appl_num.localeCompare(b.appl_num) ||
                a.submission_type.localeCompare(b.submission_type) ||
                a.submission_num.localeCompare(b.submission_num) ||
                (a.ft_indication as string).localeCompare(b.ft_indication as string)
            );
            break;
          default:
            newApprovalData.sort(
              (a, b) =>
                a.appl_num.localeCompare(b.appl_num) ||
                a.submission_type.localeCompare(b.submission_type) ||
                a.submission_num.localeCompare(b.submission_num)
            );
        }

        setApprovalData(newApprovalData);
        filterSearch(searchText, newApprovalData);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    setShowCreateDialog(false);
  };

  useEffect(() => {
    async function fetchData() {
      let res;
      try {
        res = await getAllFdaData(docType);
        if (res.data.Success.fda_data_qc.length > 0) {
          const newApprovalData = res.data.Success.fda_data_qc.map((x: ApprovalBaseRecordProps) =>
            removeDefaultDate(x)
          );
          setApprovalData(newApprovalData);
          filterSearch(searchText, newApprovalData);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: error }
        });
      }
    }

    fetchData();
  }, [docType]);

  // Column Mappings
  const ACCELERATED_COLUMNS_MAPPINGS = [
    {
      field: 'Edit',
      width: 80,
      // eslint-disable-next-line no-unused-vars
      renderCell: (cellData: any) => {
        return (
          <Button
            variant='contained'
            color='primary'
            sx={styles.Button}
            onClick={() => {
              openEdit(cellData.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'appl_title',
      headerName: 'Application Number',
      width: 200
    },
    {
      field: 'aa_indication',
      headerName: 'Accelerated Indicators',
      width: 780
    },
    {
      field: 'aa_date',
      headerName: 'Accelerated Date',
      width: 200
    },
    {
      field: 'conversion_withdrawal_status',
      headerName: 'Conversion Withdrawal Status',
      width: 180
    },
    {
      field: 'full_approval_conversion_withdrawal_date',
      headerName: 'Full Approval Conversion Withdrawal Date',
      width: 200
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150
    }
  ];

  const BREAKTHROUGH_COLUMNS_MAPPINGS = [
    {
      field: 'Edit',
      width: 80,
      // eslint-disable-next-line no-unused-vars
      renderCell: (cellData: any) => {
        return (
          <Button
            variant='contained'
            color='primary'
            sx={styles.Button}
            onClick={() => {
              openEdit(cellData.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'appl_title',
      headerName: 'Application Number',
      width: 200
    },
    {
      field: 'breakthrough_indication',
      headerName: 'Breakthrough Indication',
      width: 700
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150
    },
    {
      field: 'designation_date',
      headerName: 'Designation Date',
      width: 150
    },
    {
      field: 'rescinded_date',
      headerName: 'Rescinded Date',
      width: 150
    },
    {
      field: 'date_source',
      headerName: 'Date Source',
      width: 200
    }
  ];

  const FASTTRACK_COLUMNS_MAPPINGS = [
    {
      field: 'Edit',
      width: 80,
      // eslint-disable-next-line no-unused-vars
      renderCell: (cellData: any) => {
        return (
          <Button
            variant='contained'
            color='primary'
            sx={styles.Button}
            onClick={() => {
              openEdit(cellData.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'appl_title',
      headerName: 'Application Number',
      width: 200
    },
    {
      field: 'ft_indication',
      headerName: 'Fast Track Indication',
      width: 800
    },
    {
      field: 'pepfar_flag',
      headerName: 'Pepfar Flag',
      width: 150
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 150
    },
    {
      field: 'designation_date',
      headerName: 'Designation Date',
      width: 200
    },
    {
      field: 'date_source',
      headerName: 'Date Source',
      width: 200
    }
  ];

  const RTOR_COLUMNS_MAPPINGS = [
    {
      field: 'Edit',
      width: 100,
      // eslint-disable-next-line no-unused-vars
      renderCell: (cellData: any) => {
        return (
          <Button
            variant='contained'
            color='primary'
            sx={styles.Button}
            onClick={() => {
              openEdit(cellData.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'appl_num',
      headerName: 'Application Number',
      width: 250
    },
    {
      field: 'submission_num',
      headerName: 'Submission Number',
      width: 250
    },
    {
      field: 'submission_type',
      headerName: 'Submission Type',
      width: 250
    },
    {
      field: 'rtorsubmissiondate',
      headerName: 'Submission Date',
      width: 300
    },
    {
      field: 'rtorfullsubmissiondate',
      headerName: 'Full Submission Date',
      width: 300
    },
    {
      field: 'qc_status',
      headerName: 'Status',
      width: 200
    }
  ];

  return (
    <Box sx={styles.root} pr={2}>
      <Stack direction='column' alignItems='flex-start' spacing={2}>
        <Stack direction='row' spacing={2} pt={3} width='100%'>
          <Button
            variant='contained'
            color='primary'
            sx={styles.Button}
            onClick={() => setShowCreateDialog(true)}>
            CREATE NEW
          </Button>
          <TextField
            variant='outlined'
            type='search'
            id='search_application'
            label='Search Application Number'
            value={searchText}
            InputProps={{
              style: { fontSize: '1.5vh', minWidth: '250px' }
            }}
            InputLabelProps={{
              style: { fontSize: '1.5vh' }
            }}
            onChange={e => {
              setSearchText(e.target.value);
              filterSearch(e.target.value, approvalData);
            }}
          />
        </Stack>
        <Box height='70vh' width='100%'>
          <VivproDatagrid
            rows={searchApprovalData}
            key={`${docType}-datagrid`}
            columnsMapping={
              docType === 'accelerated'
                ? ACCELERATED_COLUMNS_MAPPINGS
                : docType === 'breakthrough'
                ? BREAKTHROUGH_COLUMNS_MAPPINGS
                : docType === 'fasttrack'
                ? FASTTRACK_COLUMNS_MAPPINGS
                : docType === 'rtor'
                ? RTOR_COLUMNS_MAPPINGS
                : RTOR_COLUMNS_MAPPINGS
            }
            rowId='id'
          />
          <Dialog
            open={showEditDialog}
            onClose={() => closeEdit}
            sx={{ backdropFilter: 'blur(5px)' }}>
            {docType === 'accelerated' ? (
              <ApprovalEditDialog
                id={editRow.id}
                appl_title={editRow.appl_title}
                appl_num={editRow.appl_num}
                submission_type={editRow.submission_type}
                submission_num={editRow.submission_num}
                aa_date={editRow.aa_date}
                aa_indication={editRow.aa_indication}
                conversion_withdrawal_status={editRow.conversion_withdrawal_status}
                full_approval_conversion_withdrawal_date={
                  editRow.full_approval_conversion_withdrawal_date
                }
                status={editRow.status}
                closeEdit={closeEdit}
                docType={docType}
              />
            ) : docType === 'breakthrough' ? (
              <ApprovalEditDialog
                id={editRow.id}
                appl_title={editRow.appl_title}
                appl_num={editRow.appl_num}
                submission_type={editRow.submission_type}
                submission_num={editRow.submission_num}
                breakthrough_indication={editRow.breakthrough_indication}
                status={editRow.status}
                designation_date={editRow.designation_date}
                rescinded_date={editRow.rescinded_date}
                date_source={editRow.date_source}
                closeEdit={closeEdit}
                docType={docType}
              />
            ) : docType === 'fasttrack' ? (
              <ApprovalEditDialog
                id={editRow.id}
                appl_title={editRow.appl_title}
                appl_num={editRow.appl_num}
                submission_type={editRow.submission_type}
                submission_num={editRow.submission_num}
                ft_indication={editRow.ft_indication}
                pepfar_flag={editRow.pepfar_flag}
                status={editRow.status}
                designation_date={editRow.designation_date}
                date_source={editRow.date_source}
                closeEdit={closeEdit}
                docType={docType}
              />
            ) : docType === 'rtor' ? (
              <ApprovalEditDialog
                id={editRow.id}
                appl_title={editRow.appl_title}
                submission_num={editRow.submission_num}
                submission_type={editRow.submission_type}
                status={editRow.qc_status}
                docType={docType}
                closeEdit={closeEdit}
                appl_num={editRow.appl_num}
                rtorfullsubmissiondate={editRow.rtorfullsubmissiondate}
                rtorsubmissiondate={editRow.rtorsubmissiondate}
              />
            ) : null}
          </Dialog>
          <Dialog
            open={showCreateDialog}
            onClose={() => closeCreate}
            sx={{ backdropFilter: 'blur(5px)' }}>
            <ApprovalCreateDialog closeCreate={closeCreate} docType={docType} />
          </Dialog>
        </Box>
      </Stack>
    </Box>
  );
};

export default ApprovalTable;
