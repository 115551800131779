import { USERS_ALLOWED_TO_UPDATE_STATUS, USERS_ALLOWED_TO_EDIT } from './constants';

export const isEditDisabled = (status: string, userEmail: string) => {
  if (status === 'Published') {
    return true;
  }

  const allowedUsers = USERS_ALLOWED_TO_EDIT[status];

  if (!allowedUsers) {
    return false;
  }

  return !allowedUsers.includes(userEmail);
};

export const isUpdateStatusDisabled = (status: string, userEmail: string) => {
  if (status === 'Published') {
    return true;
  }

  const allowedUsers = USERS_ALLOWED_TO_UPDATE_STATUS[status];

  if (!allowedUsers) {
    return false;
  }

  return !allowedUsers.includes(userEmail);
};
