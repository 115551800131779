import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    background: theme.palette.backgrounds.dark,
    boxShadow: `5px 0px 5px ${theme.palette.adminHome.grayText}`,
    height: theme.spacing(7.5),
    [theme.breakpoints.down('sm')]: {
      height: theme.spacing(6.5)
    }
  },
  vivLogo: {
    width: theme.spacing(20),
    height: '100%',
    marginTop: theme.spacing(-1)
  },
  linkBox: {
    width: theme.spacing(40)
  },
  navIcon: {
    color: theme.palette.white,
    cursor: 'pointer'
  },
  menuIcon: {
    color: theme.palette.adminHome.primaryBlack,
    fontWeight: 'bold',
    fontSize: theme.spacing(2.5)
  },
  menuText: {
    color: theme.palette.adminHome.primaryBlack
  },
  headerIcon: {
    color: theme.palette.common.grayBorder
  },
  cursorPointer: {
    cursor: 'pointer'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(0)
  },
  button: {
    background: theme.palette.buttons.purple,
    color: theme.palette.white,
    width: theme.spacing(10),
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
  links: {
    marginRight: theme.spacing(9),
    color: theme.palette.common.primaryGray
  },
  navLinks: {
    color: theme.palette.adminHome.grayText,
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.buttons.purple
    }
  },
  responsiveLogo: {
    cursor: 'pointer'
  },
  name: {
    marginLeft: theme.spacing(1),
    color: theme.palette.white,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    width: '100px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  tabs: {
    width: '70%',
    marginRight: theme.spacing(5)
  }
}));
