import React, { useContext, useEffect, useState } from 'react';

// material ui
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Auth } from 'aws-amplify';

// styles
import Styles from './ReleaseUpdate.style';

import { getReleaseVersion, updateReleaseVersion } from '../../api/release';
import Store from '../../store';
import Actions from '../../store/actions';

const ReleaseUpdate = () => {
  const classes = Styles();
  const [version, setVersion] = useState();
  const { dispatch } = useContext(Store);

  const handleKeyChange = event => {
    const text = event.target.value;
    setVersion(text);
  };

  useEffect(() => {
    const getLatestVersion = async () => {
      try {
        const versionRespone = await getReleaseVersion();
        if (versionRespone?.data?.Success?.version) {
          setVersion(versionRespone.data.Success.version);
        }
      } catch (err) {
        console.error(err);
      }
    };
    getLatestVersion();
  }, []);

  const handleKeyFormSubmit = async event => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        version,
        username: user.attributes['custom:user'],
        email: user.attributes.email
      };
      const response = await updateReleaseVersion(payload);
      if (response?.data?.Status !== 200) {
        return dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: response.data?.Error }
        });
      }
      dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Version Updated Successfully', color: 'success' }
      });
    } catch (e) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `${e}` }
      });
    }
  };

  return (
    <Box className={classes.root} display='flex' alignItems='center'>
      <Container maxWidth='lg' className={classes.container}>
        <Paper elevation={1} className={classes.banner}>
          <Typography className={classes.formHeadings}>Enter latest Release Version</Typography>
          <form onSubmit={handleKeyFormSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={12}>
                <Box display='flex' flexDirection='row' justifyContent='center'>
                  <Box margin={1} display='flex' flexDirection='column'>
                    <Typography align='left' className={classes.label}>
                      Version
                    </Typography>
                    <TextField
                      required
                      className={classes.textfield}
                      onChange={handleKeyChange}
                      fullWidth
                      id='standard-basic'
                      placeholder='Enter the latest version, Ex: v20.0.3'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                      value={version}
                    />
                  </Box>
                  <Box mt={4} display='flex' alignItems='center' className={classes.btn}>
                    <Button type='submit' variant='contained' className={classes.button}>
                      Update
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default ReleaseUpdate;
