import { useState, useEffect, useContext, memo } from 'react';

import { Button, Box, TextField, MenuItem, FormControlLabel, Switch } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';
import Styles from './styles/CategorizationTable.styles';
import styles from '../Datagrid/Datagrid.styles';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';

// api
import { getCberATCList } from '../../api/Cber';

// context
import Store from '../../store';
import Actions from '../../store/actions';

import CberAtcModal from './CberAtcModal';

const qcStatusMap = (key: string) => {
  const map: any = {
    qced: 'QCed',
    to_qc: 'To QC',
    escalate: 'Escalate',
    missing_atc: 'Missing ATC'
  };
  return map[key];
};

const CberATC = () => {
  const classes = Styles();
  const [pageSize, setPageSize] = useState(10);
  const [atcData, setAtcData] = useState<Array<any>>([]);
  const [pageNo, setPageNo] = useState(0);
  const [rowCount, setRowCount] = useState();
  const [loading, setloading] = useState(true);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [applicationNumber, setApplicationNumber] = useState('');
  const [activeIngredient, setActiveIngredient] = useState('');
  const [qcStatus, setQcStatus] = useState<string>('');
  const [atcCode, setAtcCode] = useState('');
  const rowsPerPageOptions = [10, 20, 50, 100];
  const [reload, setReload] = useState<boolean>(true);
  const [emptyATC, setEmptyATC] = useState<boolean>(false);
  const [applicationAtcData, setApplicationAtcData] = useState<object>({});
  const { dispatch } = useContext<any>(Store);

  useEffect(() => {
    const getCberATCData = async () => {
      try {
        const isEmptyATC = emptyATC === true ? 'yes' : '';
        const result = await getCberATCList(
          pageSize,
          pageNo,
          applicationNumber,
          activeIngredient,
          atcCode,
          qcStatus,
          isEmptyATC,
          ''
        );

        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.results) {
          setloading(false);
          const resultData = result.data.body.results;
          setRowCount(result.data.body.total);
          setAtcData(resultData?.map((item: any, index: any) => ({ ...item, id: index })));
        } else {
          setloading(false);
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getCberATCData();
  }, [pageNo, applicationNumber, atcCode, activeIngredient, pageSize, reload, qcStatus, emptyATC]);

  const openEdit = (data: any) => {
    setApplicationAtcData(data);
    setShowEditDialog(true);
  };
  const closeEdit = () => {
    setShowEditDialog(false);
    setReload(prevState => !prevState);
  };
  const columns = [
    {
      field: 'Edit',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            className={classes.Button}
            onClick={() => {
              openEdit(params.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'application_number',
      headerName: 'Application Number',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'application_type',
      headerName: 'Application Type',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'active_ingredient',
      headerName: 'Active Ingredient',
      width: 400,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'drug_name',
      headerName: 'Trade Name',
      width: 400,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'atc_codes',
      headerName: 'ATC Codes',
      width: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.atc_codes.map((elem: string) => (
              <span>
                <a
                  key={elem}
                  href={`https://www.whocc.no/atc_ddd_index/?code=${elem}`}
                  target='_blank'
                  rel='noreferrer'>
                  {elem}
                </a>
                &nbsp;
              </span>
            ))}
          </>
        );
      }
    },
    {
      field: 'qc_status',
      headerName: 'QC Status',
      width: 180,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return <Typography> {qcStatusMap(params.row.qc_status)} </Typography>;
      }
    }
  ];
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        <TextField
          variant='outlined'
          type='search'
          id='search_application'
          label='Search Application Number'
          value={applicationNumber}
          InputProps={{
            style: { fontSize: '1.5vh', minWidth: '250px' }
          }}
          InputLabelProps={{
            style: { fontSize: '1.5vh' }
          }}
          onChange={e => {
            setApplicationNumber(e.target.value);
            setPageNo(0);
          }}
        />
        <TextField
          variant='outlined'
          type='search'
          id='search_ingredient'
          label='Search Active Ingredient'
          value={activeIngredient}
          InputProps={{
            style: { fontSize: '1.5vh', minWidth: '250px' }
          }}
          InputLabelProps={{
            style: { fontSize: '1.5vh' }
          }}
          onChange={e => {
            setPageNo(0);
            setActiveIngredient(e.target.value);
          }}
        />
        <TextField
          variant='outlined'
          type='search'
          id='search_atc'
          label='Search ATC Code'
          value={atcCode}
          InputProps={{
            style: { fontSize: '1.5vh', minWidth: '250px' }
          }}
          InputLabelProps={{
            style: { fontSize: '1.5vh' }
          }}
          onChange={e => {
            setAtcCode(e.target.value);
            setPageNo(0);
          }}
        />
        <TextField
          variant='outlined'
          id='status'
          label='QC Status'
          select
          value={qcStatus}
          onChange={e => setQcStatus(e.target.value)}
          InputProps={{
            style: { fontSize: '1.5vh', minWidth: '250px' }
          }}
          InputLabelProps={{
            style: { fontSize: '1.5vh' }
          }}>
          <MenuItem value=''>All Documents</MenuItem>
          <MenuItem value='qced'>Qced</MenuItem>
          <MenuItem value='to_qc'>To QC</MenuItem>
          <MenuItem value='escalate'>Escalate</MenuItem>
          <MenuItem value='missing_atc'>Missing ATC</MenuItem>
        </TextField>
        <FormControlLabel
          value='start'
          control={<Switch checked={emptyATC} onChange={e => setEmptyATC(e.target.checked)} />}
          label='Empty ATC Code'
          labelPlacement='start'
        />
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          style={{ height: '100%' }}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={atcData}
          columns={columns as any}
          rowCount={rowCount}
          pageSize={pageSize}
          getRowId={row => row.id}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPageNo(0);
          }}
          onPageChange={setPageNo}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={pageNo}
          paginationMode='server'
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        <Dialog
          open={showEditDialog}
          onClose={closeEdit}
          sx={{ backdropFilter: 'blur(5px)', p: 10 }}
          maxWidth='xl'
          fullWidth>
          <CberAtcModal closeEdit={closeEdit} applicationAtcData={applicationAtcData} />
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default memo(CberATC);
