export const qcStatus = ['Escalate', 'Non Qced', 'Qced', 'Automated'];

export const qcStatusMapping: any = {
  'Non Qced': 'to_qc',
  Qced: 'qced',
  Escalate: 'escalate',
  Automated: 'automated'
};

export const Fields = [
  {
    id: 'name_of_the_medicine',
    labelName: 'Name of the Medicine'
  },
  {
    id: 'qualitative_and_quantitative_composition',
    labelName: 'Qualitative and Quantitative Composition'
  },
  {
    id: 'pharmaceutical_form',
    labelName: 'Pharmaceutical Form'
  },
  {
    id: 'therapeutic_indications',
    labelName: 'Therapeutic Indications'
  },
  {
    id: 'dosage_and_method_of_adminstration',
    labelName: 'Dosage and Method of Administration'
  },
  {
    id: 'contraindications',
    labelName: 'Contraindications'
  },
  {
    id: 'special_warning_and_precautions_for_use',
    labelName: 'Special Warnings and Precautions for Use'
  },
  {
    id: 'interactions_other_medicines',
    labelName: 'Interactions with Other Medicines'
  },
  {
    id: 'fertility_pregnancy_lactation',
    labelName: 'Fertility, Pregnancy, and Lactation'
  },
  {
    id: 'effects_ability_drive_use_machines',
    labelName: 'Effects on Ability to Drive and Use Machines'
  },
  {
    id: 'adverse_effects',
    labelName: 'Adverse Effects'
  },
  {
    id: 'overdose',
    labelName: 'Overdose'
  },
  {
    id: 'pharmacodynamic_properties',
    labelName: 'Pharmacodynamic Properties'
  },
  {
    id: 'pharmacokinetic_properties',
    labelName: 'Pharmacokinetic Properties'
  },
  {
    id: 'preclinical_safety_data',
    labelName: 'Preclinical Safety Data'
  },
  {
    id: 'pharmaceutical_particulars',
    labelName: 'Pharmaceutical Particulars'
  },
  {
    id: 'medicine_schedule',
    labelName: 'Medicine Schedule'
  },
  {
    id: 'sponsor',
    labelName: 'Sponsor'
  },
  {
    id: 'date_first_approval',
    labelName: 'Date of First Approval'
  },
  {
    id: 'date_revision',
    labelName: 'Date of Revision'
  }
];
