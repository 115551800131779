const styles = {
  closeBtn: {
    cursor: 'pointer',
    position: 'absolute',
    right: '25px',
    top: '25px'
  },
  button: {
    fontWeight: 'bold'
  },
  button__disabled: {
    fontWeight: 'bold',
    color: 'gray.light'
  }
};

export default styles;
