import React, { useState, useEffect, useMemo } from 'react';
import enUS from 'date-fns/locale/en-US';
import {
  Box,
  Dialog,
  DialogTitle,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Stack,
  TextField,
  DialogActions,
  Button,
  DialogContent,
  Paper
} from '@mui/material';
import { BarChart, Gauge, gaugeClasses } from '@mui/x-charts';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DateRangePicker, DateRange } from 'mui-daterange-picker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import getSummary from '../../api/user_notifications';

const UserNotificationStats: React.FC = () => {
  const [timePeriod, setTimePeriod] = useState<string>('week');
  const [timeZone, setTimeZone] = useState<string>('US/Eastern');
  const [summaryData, setSummaryData] = useState<any[]>([]);
  const [dataSourceList, setDataSourceList] = useState<string[]>(['all']);
  const [currentDataSource, setCurrentDataSource] = useState<string>('all');
  const [averageValue, setAverageValue] = useState<number>(7);
  const [maxValue, setMaxValue] = useState<number>(7);
  const [loading, setLoading] = useState<boolean>(false);
  const [openDatePicker, setOpenDatePicker] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: new Date()
  });

  const getDays = (timePeriodValue: string) => {
    switch (timePeriodValue) {
      case 'week':
        return 7;
      case 'month':
        return 30;
      case 'year':
        return 365;
      default:
        return 7;
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getSummary(
        timePeriod,
        timeZone,
        dateRange.startDate?.toISOString().split('T')[0] ?? '',
        dateRange.endDate?.toISOString().split('T')[0] ?? ''
      );
      if (response.status !== 200 || response.data.Status !== 200)
        throw new Error('No data returned');
      setSummaryData(response?.data?.Success?.data ?? []);
      setDataSourceList(['all', ...(response?.data?.Success?.sources ?? [])]);
    } catch (error) {
      setSummaryData([]);
    } finally {
      setLoading(false);
    }
  };

  const valueFormatter = (value: number | null) => (value === 1 ? `${value} Day` : `${value} Days`);

  const guageColor = ({ value, maxVal }: { value: number; maxVal: number }) =>
    useMemo(() => {
      if (value > maxVal * 0.9) return '#52b202';
      if (value > maxVal * 0.7) return '#f7b500';
      return '#ff4d4f';
    }, [value, maxVal]);

  const finalSummarySeries = useMemo(() => {
    if (currentDataSource === 'all')
      return dataSourceList.map(dataSource => ({
        dataKey: dataSource,
        label: dataSource,
        valueFormatter
      }));
    return [{ dataKey: currentDataSource, label: currentDataSource, valueFormatter }];
  }, [currentDataSource, dataSourceList]);

  const handleTimePeriodChange = (event: SelectChangeEvent<string>) => {
    setTimePeriod(event.target.value as string);
  };

  const handleTimeZoneChange = (event: SelectChangeEvent<string>) => {
    setTimeZone(event.target.value as string);
  };

  const handleDataSourceChange = (event: SelectChangeEvent<string>) => {
    setCurrentDataSource(event.target.value as string);
  };

  const getAverageValue = (data: any[]) => {
    if (!data.length) return;
    let total = 0;
    data.forEach(d => {
      let count = 0;
      Object.keys(d).forEach((key: string) => {
        // if (d[key] === 0) return;
        if (currentDataSource !== 'all' && key !== currentDataSource) return;
        if (currentDataSource === 'all' && dataSourceList.indexOf(key) === -1) return;
        count += d[key];
      });
      if (currentDataSource === 'all') {
        const totalDataSources = dataSourceList.filter(ds => Object.keys(d).includes(ds)).length;
        total += count / totalDataSources;
      } else total += count;
    });
    const maxVal = getDays(timePeriod);
    setAverageValue(Math.round(total / data.length));
    setMaxValue(maxVal);
  };

  const getMondayOfCurrentWeek = () => {
    const currentDate = new Date();
    const day = currentDate.getDay();
    const difference = day === 0 ? -6 : 1 - day; // Adjust when day is Sunday
    return new Date(currentDate.setDate(currentDate.getDate() + difference));
  };

  const getSundayOfAnyWeek = (date: Date) => {
    const currentDate = new Date(date);
    const day = currentDate.getDay();
    const difference = day === 0 ? 0 : 7 - day; // If it's already Sunday, no change; otherwise, calculate the difference to the next Sunday
    return new Date(currentDate.setDate(currentDate.getDate() + difference));
  };

  const getDefinedRange = useMemo(() => {
    const today = new Date();
    const mondayThisWeek = getMondayOfCurrentWeek();
    const previousMonday = new Date(mondayThisWeek);
    previousMonday.setDate(previousMonday.getDate() - 7);

    const weekRange = [
      {
        label: 'Last 7 Days',
        startDate: new Date(today.setDate(today.getDate() - 7)),
        endDate: new Date()
      },
      {
        label: 'This Week',
        startDate: mondayThisWeek,
        endDate: new Date()
      },
      {
        label: 'Last Week',
        startDate: previousMonday,
        endDate: getSundayOfAnyWeek(previousMonday)
      },

      {
        label: 'This Month',
        startDate: new Date(new Date().setDate(1)),
        endDate: new Date()
      },
      {
        label: 'Last Month',
        startDate: new Date(new Date().setMonth(new Date().getMonth() - 1, 1)),
        endDate: new Date(new Date().setDate(0))
      },
      {
        label: 'Last 30 Days',
        startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
        endDate: new Date()
      },
      {
        label: 'Last 90 Days',
        startDate: new Date(new Date().setDate(new Date().getDate() - 90)),
        endDate: new Date()
      },
      {
        label: 'Last 1 Year',
        startDate: new Date(new Date().setDate(new Date().getDate() - 365)),
        endDate: new Date()
      }
    ];

    switch (timePeriod) {
      case 'day':
      case 'week':
        return weekRange;
      case 'month':
        return [
          {
            label: 'Last 30 Days',
            startDate: new Date(new Date().setDate(new Date().getDate() - 30)),
            endDate: new Date()
          },
          {
            label: 'Last Month',
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 1, 1)),
            endDate: new Date(new Date().setDate(0))
          },
          {
            label: 'Last 3 Months',
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 3)),
            endDate: new Date()
          },
          {
            label: 'Last 6 Months',
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 6)),
            endDate: new Date()
          },
          {
            label: 'Last 1 Year',
            startDate: new Date(new Date().setMonth(new Date().getMonth() - 12)),
            endDate: new Date()
          }
        ];
      default:
        return weekRange;
    }
  }, [timePeriod]);

  const changeDateRange = (range: DateRange) => {
    setDateRange(range);
  };

  const maxChartValue = useMemo(() => {
    switch (timePeriod) {
      case 'day':
        return 1;
      case 'week':
        return 7;
      case 'month':
        return 31;
      case 'year':
        return 365;
      default:
        return 7;
    }
  }, [timePeriod]);

  useEffect(() => {
    fetchData();
  }, [timePeriod, timeZone, dateRange]);

  useEffect(() => {
    if (summaryData.length) getAverageValue(summaryData);
  }, [summaryData, currentDataSource, dataSourceList, timePeriod, dateRange, timeZone]);

  return (
    <Box margin={3}>
      <Box sx={{ textAlign: 'center', mt: 10 }}>
        <Typography variant='h4'>Notification Consistency Dashboard</Typography>
      </Box>
      <Stack direction='row' spacing={2} sx={{ mb: 4, mt: 2, ml: 5 }}>
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel>Data Source</InputLabel>
          <Select value={currentDataSource} onChange={handleDataSourceChange} label='Data Source'>
            {dataSourceList.map(dataSource => (
              <MenuItem key={dataSource} value={dataSource}>
                {dataSource}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel>Time Period</InputLabel>
          <Select value={timePeriod} onChange={handleTimePeriodChange} label='Time Period'>
            <MenuItem value='day'>Daily</MenuItem>
            <MenuItem value='week'>Weekly</MenuItem>
            <MenuItem value='month'>Monthly</MenuItem>
            {/* <MenuItem value='year'>Annual</MenuItem> */}
          </Select>
        </FormControl>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enUS}>
          <Box sx={{ width: 160 }} onClick={() => setOpenDatePicker(true)}>
            <DatePicker
              label='From'
              inputFormat='dd MMM yyyy'
              value={dateRange.startDate}
              readOnly
              onOpen={() => setOpenDatePicker(true)}
              onChange={() => {}}
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={params => <TextField {...params} />}
            />
          </Box>
          <Box sx={{ width: 160 }} onClick={() => setOpenDatePicker(true)}>
            <DatePicker
              label='To'
              inputFormat='dd MMM yyyy'
              value={dateRange.endDate}
              readOnly
              onChange={() => {}}
              // eslint-disable-next-line react/jsx-props-no-spreading
              renderInput={params => <TextField {...params} />}
            />
          </Box>
        </LocalizationProvider>
        <Dialog onClose={() => setOpenDatePicker(false)} open={openDatePicker} maxWidth='lg'>
          <DialogTitle>Select Dates</DialogTitle>
          <DialogContent>
            <Paper variant='outlined' elevation={1}>
              <DateRangePicker
                open={openDatePicker}
                toggle={() => setOpenDatePicker(!openDatePicker)}
                initialDateRange={dateRange}
                definedRanges={getDefinedRange}
                onChange={changeDateRange}
                minDate={new Date('2024-01-01')}
                maxDate={new Date()}
              />
            </Paper>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpenDatePicker(false)} autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
        <FormControl sx={{ minWidth: 100 }}>
          <InputLabel>Time Zone</InputLabel>
          <Select value={timeZone} onChange={handleTimeZoneChange} label='Time Zone'>
            <MenuItem value='US/Eastern'>EST</MenuItem>
            <MenuItem value='Asia/Calcutta'>IST</MenuItem>
            <MenuItem value='utc'>UTC</MenuItem>
          </Select>
        </FormControl>
      </Stack>
      <Typography variant='h6' sx={{ ml: 5 }}>
        Note: This chart represents the number of days with notifications for the selected time
        period. (not the quantity of notifications)
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <BarChart
          margin={{ bottom: 100 }}
          borderRadius={5}
          barLabel='value'
          width={1400}
          height={600}
          dataset={summaryData}
          series={finalSummarySeries}
          loading={loading}
          yAxis={[
            {
              min: 0,
              max: maxChartValue,
              label: 'Number of Days with Notifications'
            }
          ]}
          xAxis={[
            {
              scaleType: 'band',
              dataKey: 'period',
              label: 'Time Period'
            }
          ]}
          slotProps={{
            legend: {
              direction: 'row',
              position: { vertical: 'bottom', horizontal: 'middle' },
              padding: 5
            }
          }}
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Gauge
            width={400}
            height={200}
            value={(averageValue / maxValue) * 100}
            startAngle={-110}
            endAngle={110}
            cornerRadius='50%'
            sx={{
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40
              },
              [`& .${gaugeClasses.valueArc}`]: {
                fill: guageColor({ value: averageValue, maxVal: maxValue })
              },
              [`& .${gaugeClasses.valueText}`]: {
                fontSize: 40,
                transform: 'translate(0px, 0px)'
              }
            }}
            text={() => `${averageValue} / ${maxValue}`}
          />
          <Typography variant='h4'>{currentDataSource} Average</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default UserNotificationStats;
