import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// material ui
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Skeleton from '@material-ui/lab/Skeleton';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

import Styles from '../DataEntry/styles/Review.styles';
import { getLabelData, submitEMAProceduralStepsData } from '../../api/AdminPage';
import AuthContext from '../../store/Auth/AuthProvider';

// helpers
import { handleChange } from '../../helpers/changeHandler';

// store
import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';

const ProceduralStepsEMAReview = () => {
  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(GlobalStore);
  const classes = Styles();
  const history = useHistory();
  const prodNo = useLocation().pathname.split('/')[3];

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [addMode, setAddMode] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [query, setQuery] = useState(null);
  const [allDetails, setAllDetails] = useState([]);
  const [applData, setApplData] = useState(null);
  const [applNumber, setApplNumber] = useState(null);
  const [id, setId] = useState(null);
  const [bookmark0, setBookmark0] = useState(null);
  const [bookmark2, setBookmark2] = useState(null);
  const [commissionDecision, setCommissionDecision] = useState(null);
  const [scope, setScope] = useState(null);
  const [summary, setSummary] = useState(null);
  const [initialIndicationStatus, setInitialIndicationStatus] = useState('');
  const [indicationStatus, setIndicationStatus] = useState('');
  const [productUrl, setProductUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [numberOfApps, setNumberOfApps] = useState(0);
  const [numberOfNewApps, setNumberOfNewApps] = useState(0);
  const [newDocFlag, setNewDocFlag] = useState(false);
  const [queryOptions, setQueryOptions] = useState([]);
  const [dropdown, setDropdown] = useState(false);
  const [duplicateAlertDialog, setDuplicateAlertDialog] = useState(false);

  useEffect(() => {
    async function getData() {
      setLoading(true);
      setEmail(currentUser.email);
      setName(currentUser['custom:user']);
      const response = await getLabelData(prodNo, null, null, 'procedural_steps');
      await setNumberOfApps(response.data.Success.procedural_steps_qc.length);
      await setAllDetails(response.data.Success.procedural_steps_qc);
      await setProductUrl(response.data.Success.pdf_url.split('?')[0].split('.com/')[1]);
      await setPdfUrl(response.data.Success.pdf_url);
      await setQueryOptions(response.data.Success.procedural_steps_qc);
      setLoading(false);
    }

    getData();
  }, [currentUser, prodNo]);

  const handleSubmit = async () => {
    if (initialIndicationStatus) {
      setLoading(true);
      setDialogOpen(false);
      setIndicationStatus(initialIndicationStatus);
      const payload = {
        doctype: 'procedural_steps',
        product_number: prodNo,
        appln_no: applNumber,
        bookmark_0: bookmark0,
        commission_decision: commissionDecision,
        bookmark_2: bookmark2,
        scope,
        summary,
        indication_status: initialIndicationStatus,
        sourced_from: productUrl,
        id,
        curator_email: email,
        curator_name: name
      };
      await submitEMAProceduralStepsData(payload);
      const response = await getLabelData(prodNo, null, null, 'procedural_steps');
      await setAllDetails(response.data.Success.procedural_steps_qc);
      setApplData(null);
      setDropdown(false);

      setEditMode(false);
      setAddMode(false);
      setLoading(false);
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Saved Successfully!', color: 'success' }
      });
      if (newDocFlag) {
        setNumberOfNewApps(numberOfNewApps + 1);
      }
      setNewDocFlag(false);
      const responseData = await getLabelData(prodNo, null, null, 'procedural_steps');
      await setAllDetails(responseData.data.Success.procedural_steps_qc);
      await setProductUrl(responseData.data.Success.pdf_url.split('?')[0].split('.com/')[1]);
      await setQueryOptions(responseData.data.Success.procedural_steps_qc);
      setId(null);
      setQuery('');
      setApplNumber('');
      setBookmark0('');
      setBookmark2('');
      setCommissionDecision('');
      setScope('');
      setSummary('');
      setInitialIndicationStatus('');
      setIndicationStatus('');
    } else {
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Please update Status before you save.' }
      });
      setDialogOpen(false);
    }
  };

  const checkAddDuplicate = async () => {
    let tempCheck = false;

    for (const item in allDetails) {
      if (allDetails[item]['appln_no'] === applNumber) {
        setDuplicateAlertDialog(true);
        tempCheck = true;
        break;
      }
    }
    if (!tempCheck) {
      handleSubmit();
    }
  };

  const cancelAddSubmit = () => {
    setId(null);
    setQuery('');
    setApplNumber('');
    setBookmark0('');
    setBookmark2('');
    setCommissionDecision('');
    setScope('');
    setSummary('');
    setInitialIndicationStatus('');
    setIndicationStatus('');
    setAddMode(false);
  };

  const cancelChanges = async () => {
    if (editMode) {
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          status: true,
          message: 'Your changes are stored temporarily. Please submit to save changes.'
        }
      });
    }
    setEditMode(false);
    setAddMode(false);
    setNewDocFlag(false);
  };

  const handleAdd = () => {
    setId(null);
    setQuery('');
    setApplData(null);
    setApplNumber('');
    setBookmark0('');
    setBookmark2('');
    setCommissionDecision('');
    setScope('');
    setSummary('');
    setInitialIndicationStatus('');
    setIndicationStatus('');
    setNewDocFlag(true);
    setAddMode(true);
  };

  const handleQuery = async () => {
    let flag = false;
    setAddMode(false);
    setDropdown(false);
    allDetails.map(item => {
      if (item.appln_no === query) {
        flag = true;
        return (
          setApplData(item),
          setApplNumber(item.appln_no),
          setId(item.id),
          setBookmark0(item.bookmark_0.split(' ')[0]),
          setBookmark2(item.bookmark_2),
          setCommissionDecision(item.commission_decision.split(' ')[0]),
          setScope(item.scope),
          setSummary(item.summary),
          setIndicationStatus(item.indication_status)
        );
      }
      return null;
    });
    if (!flag) {
      setApplData('');
      setApplNumber('');
      setId('');
      setBookmark0('');
      setBookmark2('');
      setCommissionDecision('');
      setScope('');
      setSummary('');
      setIndicationStatus('');
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: {
          status: true,
          message: 'Not found! Please re-check the Application Number or Add a New Application.'
        }
      });
    }
  };

  const handleSearchChange = async event => {
    event.preventDefault();
    event.persist();
    setDropdown(true);
    await setQuery(event.target.value);
    handleChangeOptions(event.target.value);
  };

  const handleChangeOptions = async val => {
    if (val && val.length > 0) {
      let options = allDetails.filter(item => item.appln_no.match(val));
      await setQueryOptions(options);
    } else {
      setDropdown(false);
      await setQueryOptions(allDetails);
    }
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        {editMode || addMode ? null : (
          <Box display='flex' padding={1} flexDirection='row' justifyContent='space-between'>
            <Box>
              <Button
                className={classes.button}
                onClick={() => history.push('/ema/proceduralStepsEma')}
                variant='contained'>
                Go back
              </Button>

              <Button
                className={classes.button}
                onClick={() => setEditMode(true)}
                variant='contained'>
                Edit
              </Button>
              <Button className={classes.button} onClick={handleAdd} variant='contained'>
                Add
              </Button>
            </Box>
            <Box>
              <Typography className={classes.heading2}>
                Number of Applications in database: {numberOfApps}
              </Typography>
              <Typography className={classes.heading2}>
                Number of New Applications: {numberOfNewApps}
              </Typography>
              <Typography className={classes.heading2}>
                Total Applications: {numberOfApps + numberOfNewApps}
              </Typography>
            </Box>
          </Box>
        )}

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} className={classes.gridLabel}>
            <Box
              className={classes.reviewBox}
              justifyContent='center'
              alignItems='space-evenly'
              style={{ minHeight: '75vh' }}>
              <Box padding={1}>
                <Typography className={classes.heading}>
                  Select an Application for {prodNo}.
                </Typography>
              </Box>
              {loading ? (
                <Box padding={1}>
                  <Skeleton variant='rect' width={300} height={50} />
                </Box>
              ) : null}
              {loading || addMode || editMode ? null : (
                <>
                  <Box
                    padding={1}
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'>
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      onClick={() => setDropdown(true)}
                      value={query}
                      onChange={event => handleSearchChange(event)}
                      id='standard-basic'
                      placeholder='Enter Application Number'
                      autoComplete='off'
                    />
                    <Button className={classes.button} onClick={handleQuery} variant='contained'>
                      Search
                    </Button>
                  </Box>
                  <Box className={dropdown ? classes.showDropdown : classes.hideDropdown}>
                    <ul className={classes.dropdownList}>
                      {queryOptions.map(item => {
                        return (
                          <MenuItem
                            className={classes.dropdownItems}
                            onClick={() => {
                              setDropdown(false);
                              setQuery(item.appln_no);
                            }}
                            value={item.appln_no}>
                            <Typography>{item.appln_no}</Typography>
                            <Typography>
                              {item.indication_status ? item.indication_status : 'To be Qced'}
                            </Typography>
                          </MenuItem>
                        );
                      })}
                    </ul>
                  </Box>
                </>
              )}
              {addMode && addMode ? (
                <Box
                  padding={1}
                  display='flex'
                  flexDirection='column'
                  justifyContent='space-between'>
                  <Typography align='left' className={classes.label}>
                    Application Number:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={applNumber}
                      onChange={event => setApplNumber(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Application Number'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Scope:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={scope}
                      onChange={event => setScope(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Scope'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Opinion/Notification:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={bookmark0}
                      onChange={event => setBookmark0(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Opinion/Notification'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Commission Decision:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={commissionDecision}
                      onChange={event => setCommissionDecision(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Commission Decision'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Product Information Affected:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={bookmark2}
                      onChange={event => setBookmark2(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Product Information Affected'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Summary:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={summary}
                      onChange={event => setSummary(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Summary'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Status:
                    {loading ? (
                      <Skeleton variant='rect' width={100} height={20} />
                    ) : (
                      <Select
                        id='blaStats'
                        variant='standard'
                        disableUnderline
                        required
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                          },
                          getContentAnchorEl: null
                        }}
                        value={initialIndicationStatus}
                        onChange={event => setInitialIndicationStatus(handleChange(event))}
                        className={classes.dropdownContainer}
                        inputProps={{
                          classes: {
                            icon: classes.icon
                          }
                        }}>
                        <MenuItem value='Qced' className={classes.dropdownText}>
                          Qced
                        </MenuItem>
                        <MenuItem value='To Escalate' className={classes.dropdownText}>
                          To Escalate
                        </MenuItem>
                        <MenuItem value='New Document' className={classes.dropdownText}>
                          New Document
                        </MenuItem>
                      </Select>
                    )}
                  </Typography>
                </Box>
              ) : null}
              {applData && editMode ? (
                <Box
                  padding={1}
                  display='flex'
                  flexDirection='column'
                  justifyContent='space-between'>
                  <Typography align='left' className={classes.label}>
                    Application Number:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={applNumber}
                      onChange={event => setApplNumber(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Application Number'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Scope:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={scope}
                      onChange={event => setScope(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Scope'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Opinion/Notification:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={bookmark0}
                      onChange={event => setBookmark0(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Opinion/Notification'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Commission Decision:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={commissionDecision}
                      onChange={event => setCommissionDecision(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Commission Decision'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Product Information Affected:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={bookmark2}
                      onChange={event => setBookmark2(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Product Information Affected'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Summary:
                  </Typography>
                  {loading ? (
                    <Skeleton variant='rect' width={500} height={20} />
                  ) : (
                    <TextareaAutosize
                      className={classes.textAreaField}
                      minRows={2}
                      fullWidth
                      value={summary}
                      onChange={event => setSummary(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter Summary'
                    />
                  )}

                  <Typography align='left' className={classes.label}>
                    Status:
                    {loading ? (
                      <Skeleton variant='rect' width={100} height={20} />
                    ) : (
                      <Select
                        id='blaStats'
                        variant='standard'
                        disableUnderline
                        required
                        MenuProps={{
                          anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'left'
                          },
                          transformOrigin: {
                            vertical: 'top',
                            horizontal: 'left'
                          },
                          getContentAnchorEl: null
                        }}
                        value={initialIndicationStatus}
                        onChange={event => setInitialIndicationStatus(handleChange(event))}
                        className={classes.dropdownContainer}
                        inputProps={{
                          classes: {
                            icon: classes.icon
                          }
                        }}>
                        <MenuItem value='Qced' className={classes.dropdownText}>
                          Qced
                        </MenuItem>
                        <MenuItem value='To Escalate' className={classes.dropdownText}>
                          To Escalate
                        </MenuItem>
                        <MenuItem value='New Document' className={classes.dropdownText}>
                          New Document
                        </MenuItem>
                      </Select>
                    )}
                  </Typography>
                </Box>
              ) : null}
              {applData && !editMode ? (
                <Box
                  padding={1}
                  display='flex'
                  flexDirection='column'
                  justifyContent='space-between'
                  style={{ minHeight: '65vh' }}>
                  <Typography align='left' className={classes.label}>
                    Application Number:
                    <Typography align='left' className={classes.details}>
                      {applNumber}
                    </Typography>
                  </Typography>

                  <Typography align='left' className={classes.label}>
                    Scope:
                    <Typography align='left' className={classes.details}>
                      {scope}
                    </Typography>
                  </Typography>

                  <Typography align='left' className={classes.label}>
                    Opinion/Notification:
                    <Typography align='left' className={classes.details}>
                      {bookmark0}
                    </Typography>
                  </Typography>

                  <Typography align='left' className={classes.label}>
                    Commission Decision:
                    <Typography align='left' className={classes.details}>
                      {commissionDecision}
                    </Typography>
                  </Typography>

                  <Typography align='left' className={classes.label}>
                    Product Information Affected:
                    <Typography align='left' className={classes.details}>
                      {bookmark2}
                    </Typography>
                  </Typography>

                  <Typography align='left' className={classes.label}>
                    Summary:
                    <Typography align='left' className={classes.details}>
                      {summary}
                    </Typography>
                  </Typography>

                  <Typography align='left' className={classes.label}>
                    Status: {indicationStatus}
                  </Typography>
                </Box>
              ) : null}

              {editMode || addMode ? (
                <Box padding={1} display='flex' flexDirection='row'>
                  <Button
                    className={classes.button}
                    onClick={() => cancelChanges()}
                    variant='contained'>
                    Cancel
                  </Button>
                  <Button
                    className={classes.button}
                    onClick={() => {
                      if (addMode) {
                        checkAddDuplicate();
                      } else {
                        setDialogOpen(true);
                      }
                    }}
                    variant='contained'>
                    Submit
                  </Button>
                  <Dialog
                    open={dialogOpen}
                    onClose={() => setDialogOpen(false)}
                    aria-labelledby='alert-dialog-title'
                    aria-describedby='alert-dialog-description'>
                    <Box className={classes.dialog}>
                      <DialogTitle id='alert-dialog-title'>Confirm Submission</DialogTitle>
                      <DialogContent>
                        <DialogContentText id='alert-dialog-description'>
                          Are you sure you want to submit the changes?
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={() => setDialogOpen(false)} className={classes.confirm}>
                          No
                        </Button>
                        <Button onClick={handleSubmit} className={classes.confirm} autoFocus>
                          Yes
                        </Button>
                      </DialogActions>
                    </Box>
                  </Dialog>
                </Box>
              ) : null}
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            {pdfUrl ? (
              <>
                <Box className={classes.pdfContainer}>
                  <iframe title='pdf' src={pdfUrl} className={classes.pdfContainer} />
                </Box>
              </>
            ) : null}
            {pdfUrl ? null : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.pdfContainer}>
                <Typography className={classes.notAvailable}>No pdf is available</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
        {duplicateAlertDialog ? (
          <Dialog
            open={duplicateAlertDialog}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'>
            <DialogTitle>Alert</DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                An application with the Product Number: {prodNo} and Application Number:
                {applNumber} already exists. Do you want to continue?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setDuplicateAlertDialog(false);
                  cancelAddSubmit();
                }}
                className={classes.confirm}>
                Cancel
              </Button>
              <Button
                onClick={() => {
                  setDuplicateAlertDialog(false);
                  handleSubmit();
                }}
                className={classes.confirm}
                autoFocus>
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        ) : null}
      </Container>
    </Box>
  );
};

export default ProceduralStepsEMAReview;
