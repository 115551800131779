import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  dataGrid: {
    background: 'white',
    marginTop: '50px'
  },
  root: {
    background: theme.palette.backgrounds.dark,
    height: '100%',
    minHeight: '100vh',
    color: 'white',
    paddingTop: theme.spacing(10)
  },
  dataGridRoot: {
    // margin: theme.spacing(15),
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    position: 'relative',
    display: 'flex',
    '& .cellValue': {
      // whiteSpace: 'nowrap',
      // overflow: 'hidden',
      // textOverflow: 'ellipsis',
    },
    '& .MuiDataGrid-root': {
      fontSize: '1.8vh',
      fontWeight: 'normal',
      height: 'inherit',
      color: theme.palette.datagrid.dataText,
      backgroundColor: theme.palette.white,
      border: 'unset',
      '& .MuiDataGrid-columnsContainer': {
        color: theme.palette.primary.lighter,
        backgroundColor: theme.palette.regulatory.lighterGreen
        // alignItems: 'center',
        // justifyContent: 'center',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: '2.2vh',
        padding: '0 30px'
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold'
      },
      '& .MuiDataGrid-cell': {
        padding: '0 30px',
        // alignItems: 'center',
        justifyContent: 'center'
      },
      '& .MuiDataGrid-renderingZone': {
        backgroundColor: theme.palette.white
      },
      '& .MuiDataGrid-footer': {
        backgroundColor: theme.palette.regulatory.lighterGreen
      }
    }
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  flexRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  splitPage: {
    width: '50vw'
  },
  button: {
    background: theme.palette.buttons.purple,
    color: theme.palette.white,
    width: theme.spacing(25),
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
  button1: {
    background: theme.palette.buttons.purple,
    color: theme.palette.white,
    width: theme.spacing(15),
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    },
    textTransform: 'none'
  },
  buttonOverflow: {
    background: theme.palette.buttons.purple,
    color: theme.palette.white,
    width: theme.spacing(45),
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    },
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    textTransform: 'none',
    textAlign: 'left !important',
    alignItems: 'flex-start',
    justifyContent: 'flex-start'
  },
  buttonCancel: {
    background: theme.palette.adcomm.warningRed,
    color: theme.palette.white,
    width: theme.spacing(25),
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.adcomm.warningRed
    }
  },
  buttonIncomplete: {
    background: theme.palette.adcomm.warningRed,
    fontFamily: 'Roboto',
    color: theme.palette.white,
    width: theme.spacing(25),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    margin: theme.spacing(1),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.adcomm.warningRed
    }
  },
  buttonComplete: {
    background: theme.palette.adcomm.completedGreen,
    fontFamily: 'Roboto',
    color: theme.palette.white,
    width: theme.spacing(25),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    margin: theme.spacing(1),
    opacity: 0.3
    // pointerEvents: 'none'
  },
  link: {
    background: theme.palette.adcomm.completedGreen,
    color: theme.palette.white,
    width: theme.spacing(25),
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    marginLeft: 'auto',
    padding: '11px',
    float: 'right',
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.adcomm.completedGreen
    }
  },
  label: {
    fontSize: theme.spacing(2),
    padding: '10px 0 3px 0',
    color: theme.palette.review.label,
    fontWeight: 'bold',
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2.5)
    }
  },
  reviewBox: {
    margin: theme.spacing(1),
    padding: theme.spacing(1.5),
    background: theme.palette.backgrounds.light,
    width: '95%',
    borderRadius: theme.spacing(1.5)
  },
  textfield: {
    marginTop: theme.spacing(0.5),
    width: '100%',
    color: 'white',
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5),
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    },
    '& .MuiOutlinedInput-input' : {
      color: 'white'
    }
  },
  input: {
    color: theme.palette.white,
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    '&::placeholder': {
      color: theme.palette.white,
      fontSize: theme.spacing(1)
    },
    '& .MuiOutlinedInput-input' : {
      color: 'white'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  details: {
    color: '#6f7594',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  icons: {
    width: '3vh',
    height: '3vh'
  },
  sticky: {
    position: 'sticky',
    top: '60px'
  },
  icon: {
    color: theme.palette.white
  },
  dropdownText: {
    color: theme.palette.adminHome.primaryBlack,
    fontWeight: 'bold'
  },
  dropdownContainer: {
    marginTop: theme.spacing(0.5),
    height: theme.spacing(6),
    background: theme.palette.backgrounds.dark,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.white,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    '& 	.MuiSelect-select': {
      color: 'white'
    }
  },
  warning: {
    color: theme.palette.adcomm.warningRed
  },
  date_picker: {
    background: theme.palette.white,
    borderRadius: theme.spacing(0.5)
  },
  dialogRoot: {
    background: `${theme.palette.backgrounds.dark} !important`,
    color: 'white'
  }
}));
