import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { chatRiaQuestionType } from './ChatRIAStatsInterface';
import VivproDatagrid from '../../Datagrid';
import styles from './styles/DetailView.styles';

interface propTypes {
  isOpen: boolean;
  handleClose: () => void;
  loading: Boolean;
  organization: string;
  data: chatRiaQuestionType;
}

const QUESTIONS_COLUMN_MAPPING = [
  {
    field: 'date',
    headerName: 'Date',
    width: 190
  },
  {
    field: 'section',
    headerName: 'Section',
    width: 189
  },
  {
    field: 'application',
    headerName: 'Application',
    width: 100
  },
  {
    field: 'module',
    headerName: 'Module',
    width: 70
  },
  {
    field: 'source',
    headerName: 'Source',
    width: 70
  },
  {
    field: 'question',
    headerName: 'Question',
    flex: 1
  }
];

const DetailView = ({ isOpen, handleClose, loading, organization, data }: propTypes) => {
  const orgQData = data.orgData.find(x => x.organization === organization);
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth='lg' fullWidth>
      <DialogTitle>
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Stack>
            <Typography variant='h4'>{organization}</Typography>
            <Typography variant='subtitle1' color='grey' ml={1}>
              {`${data.startDate} - ${data.endDate}`}
            </Typography>
          </Stack>
          <IconButton onClick={handleClose} size='large'>
            <Close />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent sx={styles.root}>
        <Typography variant='h5'>Activity</Typography>
        <Box height='50vh' mt={2}>
          {loading ? (
            <Typography variant='h5'>Loading</Typography>
          ) : (
            <VivproDatagrid
              rows={orgQData?.data ?? []}
              key={`${organization}-questions-datagrid`}
              columnsMapping={QUESTIONS_COLUMN_MAPPING}
              rowId='id'
            />
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ padding: 2 }}>
        <Button color='error' onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default DetailView;
