import React from 'react';

// muiv5
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';

// components
import DatagridFooter from './DatagridFooter';
import DatagridNoRowsOverlay from './DatagridNoRowsOverlay';

// utils
import { HIDDEN_COLUMNS } from './constants';

import styles from './Datagrid.styles';

interface DatagridProps {
  rows: Array<object>; // data that will be displayed in the datagrid
  columnsMapping: GridColDef[]; // column definitions for the data
  rowId: string; // unique identifier for the rows in datagrid
  // eslint-disable-next-line react/require-default-props
  csvFileName?: string;
  // eslint-disable-next-line react/require-default-props
  noRowMessage?: string;
  // eslint-disable-next-line react/require-default-props
  loading?: boolean;
}

const VivproDatagrid = ({
  rows,
  columnsMapping,
  rowId,
  csvFileName,
  noRowMessage = 'No Rows',
  loading
}: DatagridProps) => {
  const [pageSize, setPageSize] = React.useState<number>(20);

  return (
    <DataGrid
      sx={styles.datagrid}
      initialState={{
        columns: {
          // Hide columns status and traderName, the other columns will remain visible
          columnVisibilityModel: HIDDEN_COLUMNS
        }
      }}
      rowSpacingType='margin'
      sortingOrder={['desc', 'asc']}
      checkboxSelection={false}
      density='standard'
      rows={rows}
      columns={columnsMapping}
      pageSize={pageSize}
      getRowId={row => row[rowId]}
      onPageSizeChange={newPageSize => setPageSize(newPageSize)}
      rowsPerPageOptions={[20, 40, 60, 80]}
      pagination
      loading={loading}
      getRowClassName={() => `table-rows`}
      componentsProps={{
        toolbar: {
          fileName: csvFileName
        },
        noRowsOverlay: { message: noRowMessage }
      }}
      components={{
        // Toolbar: DatagridToolbar,
        LoadingOverlay: LinearProgress,
        Footer: DatagridFooter,
        NoRowsOverlay: DatagridNoRowsOverlay
      }}
    />
  );
};

export default VivproDatagrid;
