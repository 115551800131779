import makeRequest from './client';

/**
 * Function to get All Meetings details
 * @param {string} table
 * @param {string} limit
 * @param {string} page
 * @param {string} qcComplete
 */
export const getAllMeetings = (table, limit, page, qcComplete) =>
  makeRequest(
    `/adcomm/meetings?table=${table}&limit=${limit}&page=${page}&qcComplete=${qcComplete}`,
    'GET'
  );

/**
 * Function to get a single Meeting details
 * @param {string} table
 * @param {string} meetingId
 */
export const getOneMeeting = (meetingId, table) =>
  makeRequest(`/adcomm/singleMeeting?table=${table}&meetingId=${meetingId}`, 'GET');

/**
 * Function to get Applications of a Meeting.
 * @param {string} meetingId
 * @param {string} table
 */
export const getAppsOneMeeting = (meetingId, table) =>
  makeRequest(`/adcomm/singleMeetingApps?table=${table}&meetingId=${meetingId}`, 'GET');

/**
 * Function to Save or Submit details about a meeting
 * @param {object} payload
 * @param {string} action
 */
export const saveOrSubmitMeetingDetails = (payload, action) =>
  makeRequest(`/adcomm?action=${action}`, 'POST', payload);

/**
 * Function to get details of One Application.
 * @param {string} table
 * @param {string} applPrimaryKey
 */
export const getOneApp = (table, applPrimaryKey) =>
  makeRequest(`/adcomm?table=${table}&key=${applPrimaryKey}`, 'GET');

/**
 * Function to Submit Application Details.
 * @param {object} payload
 */
export const submitApplicationDetails = payload =>
  makeRequest(`/adcomm/application/submit`, 'POST', payload);

/**
 * It makes a GET request to the `/adcomm/:meetingId/minutes` endpoint
 * @param meetingId - The ID of the meeting you want to get the minutes for.
 */
export const getAdcommMinutes = meetingId => makeRequest(`/adcomm/${meetingId}/minutes`, 'GET');

/**
 * It makes a GET request to the `/adcomm/minutes/:minutesId` endpoint
 */
export const getAddcommMinutesById = minutesId =>
  makeRequest(`/adcomm/minutes/${minutesId}`, 'GET');

/**
 * It makes a POST request to the `/adcomm/:meetingId/minutes` endpoint with the payload
 * @param meetingId - The ID of the meeting you want to update
 * @param payload - the data to be sent to the server
 */
export const upsertAdcommMinutes = (meetingId, payload) =>
  makeRequest(`/adcomm/${meetingId}/minutes`, 'POST', payload);

/**
 * It makes a GET request to the `/adcomm/:minutesId/roster` endpoint
 */
export const getAdcommRoster = minutesId => makeRequest(`/adcomm/${minutesId}/roster`, 'GET');

/**
 * It makes a GET request to the `/adcomm/rosterId/:rosterId` endpoint and returns the response
 */
export const getAddcommRosterById = rosterId => makeRequest(`/adcomm/roster/${rosterId}`, 'GET');

/**
 * This function makes a POST request to the `/adcomm/:meetingId/roster` endpoint with the given payload.
 * @param meetingId - The ID of the meeting you want to update
 * @param payload - {
 */
export const upsertAdcommRoster = (meetingId, payload) =>
  makeRequest(`/adcomm/${meetingId}/roster`, 'POST', payload);

/**
 * It makes a GET request to the /adcomm/members endpoint and returns the response
 */
export const getMemberList = () => makeRequest('/adcomm/members/list', 'GET');

export const getCommittee = () => makeRequest('/adcomm/committee', 'GET');

export const getMaterial = meetingId => makeRequest(`/adcomm/material/${meetingId}`, 'GET');

export const upsertAdcommMaterial = (meetingId, payload) =>
  makeRequest(`/adcomm/material/${meetingId}`, 'POST', payload);

export const getNonQcedLetters = (page, per_page) =>
  makeRequest(`/cder/non-qcdletters?page=${page}&per_page=${per_page}`, 'GET');

export const getNonQcedLabels = (page, per_page) =>
  makeRequest(`/cder/non-qcdlabel?page=${page}&per_page=${per_page}`, 'GET');

export const deleteMaterial = (meetingId, materialId) =>
  makeRequest(`/adcomm/material/${meetingId}/${materialId}`, 'DELETE');


export const deleteMinute = minutesId => makeRequest(`/adcomm/minutes/${minutesId}`, 'DELETE');

export const deleteRoster = rosterId => makeRequest(`/adcomm/roster/${rosterId}`, 'DELETE');