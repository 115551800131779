import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  paper: {
    width: '80%',
    height: theme.spacing(15),
    borderRadius: theme.spacing(1.5),
    background: 'linear-gradient(to right, #96deda, #50c9c3)',
    '&:hover': {
      opacity: '.8'
    }
  },
  paperBox: {
    height: '100%'
  },
  cardText: {
    color: theme.palette.primaryBlack,
    fontWeight: 'bold',
    textDecoration: 'none',
    textTransform: 'initial !important',
  },
  disabled: {
    opacity: 0.5
  }
}));
