import LabelPageNoCuration from '../components/HPRA/LabelPageNoCuration';
import LabelSection from '../components/HPRA/LabelSection';

const HPRA_ROUTES = [
  {
    id: 'hpraSectionSeperation',
    path: '/hpra/section',
    component: LabelSection,
    exact: true
  },
  {
    id: 'hpraPagenumber',
    path: '/hpra/page',
    component: LabelPageNoCuration,
    exact: true
  }
];
export default HPRA_ROUTES;
