import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { STUDY_KEY_TITLE } from './constant';

const isDataModified = (parentIndication: any, value: any, key = '', attributeName = null) => {
  let flag = true;
  if (attributeName) {
    parentIndication.studies.forEach((study: any) => {
      // eslint-disable-next-line consistent-return
      study.attributes.forEach((element: any) => {
        if (element.attribute_name === attributeName && element.attribute_value === value) {
          flag = false;
        }
      });
    });
    return flag;
  }
  // eslint-disable-next-line consistent-return
  parentIndication.studies.forEach((study: any) => {
    if (study[key] === value) {
      flag = false;
    }
  });
  return flag;
};

// eslint-disable-next-line no-unused-vars
const isDataMissingInChild = (childIndication: any, value: any, key = '', attributeName = null) => {
  let flag = true;
  if (attributeName) {
    childIndication.studies.forEach((study: any) => {
      // eslint-disable-next-line consistent-return
      study.attributes.forEach((element: any) => {
        if (element.attribute_name === attributeName && element.attribute_value === value) {
          flag = false;
        }
      });
    });
  }
  return flag;
};

const IndicationComparison = ({
  title,
  parentIndication,
  currentIndication,
  onClose,
  onCopy,
  onAcceptCurrent
}: {
  title: string;
  onClose: any;
  onCopy: any;
  onAcceptCurrent: any;
  parentIndication: any;
  currentIndication: any;
}) => {
  return (
    <Box height='100%'>
      <Typography id='alert-dialog-title' textAlign='center' p={2}>
        {title}
      </Typography>
      <Box sx={{ overflow: 'hidden', p: 2 }}>
        <Stack direction='row' justifyContent='center'>
          <Box flex={1} overflow='hidden'>
            <Typography textAlign='center' fontWeight='bold'>
              Previous Submission Values
            </Typography>
            <Box
              display='inline'
              position='absolute'
              right='10px'
              top='5px'
              sx={{ cursor: 'pointer' }}
              onClick={onClose}>
              <CloseIcon fontSize='large' />
            </Box>
            <Typography textAlign='center' fontWeight='bold'>
              {parentIndication.text}
            </Typography>
            <Box sx={{ overflowY: 'auto', height: '60vh' }}>
              {parentIndication.studies?.map((stud: any, index: number) => {
                return (
                  <>
                    <hr />
                    <Typography flex='1' fontWeight='bold' textAlign='center'>{`Study ${
                      index + 1
                    }`}</Typography>
                    <hr />
                    {Object.keys(stud).map(key => {
                      if (!STUDY_KEY_TITLE[key]) return null;
                      return (
                        <Box display='flex' key={`parent${key}`}>
                          <Typography flex='1' fontWeight='bold'>
                            {STUDY_KEY_TITLE[key].title}:
                          </Typography>
                          <Typography flex='2'>{stud[key] || '--'}</Typography>
                        </Box>
                      );
                    })}
                    {stud?.attributes.map((attr: any) => {
                      return (
                        <Box display='flex' key={`parent${attr.id}`}>
                          <Typography flex='2' fontWeight='bold'>
                            {attr.attribute_name}:
                          </Typography>
                          <Typography
                            flex='3'
                            sx={{
                              backgroundColor: isDataMissingInChild(
                                currentIndication,
                                attr.attribute_value,
                                '',
                                attr.attribute_name
                              )
                                ? 'green'
                                : 'inherit'
                            }}>
                            {attr.attribute_value || '--'}
                          </Typography>
                        </Box>
                      );
                    })}
                  </>
                );
              })}
            </Box>
          </Box>
          <Box flex={1} overflow='hidden'>
            <Typography textAlign='center' fontWeight='bold'>
              Current Submission Values
            </Typography>
            <Typography
              textAlign='center'
              fontWeight='bold'
              sx={{
                backgroundColor:
                  currentIndication.text !== parentIndication.text ? 'yellow' : 'inherit'
              }}>
              {currentIndication.text}
            </Typography>
            <Box sx={{ overflowY: 'auto', height: '60vh' }}>
              {currentIndication.studies?.map((stud: any, index: number) => {
                return (
                  <>
                    <hr />
                    <Typography flex='1' fontWeight='bold' textAlign='center'>{`Study ${
                      index + 1
                    }`}</Typography>
                    <hr />
                    {Object.keys(stud).map(key => {
                      if (!STUDY_KEY_TITLE[key]) return null;
                      return (
                        <Box display='flex' key={`child${key}`}>
                          <Typography flex='1' fontWeight='bold'>
                            {STUDY_KEY_TITLE[key].title}:
                          </Typography>
                          <Typography
                            flex='2'
                            sx={{
                              backgroundColor: isDataModified(
                                parentIndication,
                                stud[key],
                                key,
                                null
                              )
                                ? 'yellow'
                                : 'inherit'
                            }}>
                            {stud[key] || '--'}
                          </Typography>
                        </Box>
                      );
                    })}
                    {stud?.attributes.map((attr: any) => {
                      return (
                        <Box display='flex' key={`child${attr.id}`}>
                          <Typography flex='2' fontWeight='bold'>
                            {attr.attribute_name}:
                          </Typography>
                          <Typography
                            flex='3'
                            sx={{
                              backgroundColor: isDataModified(
                                parentIndication,
                                attr.attribute_value,
                                'attributes',
                                attr.attribute_name
                              )
                                ? 'yellow'
                                : 'inherit'
                            }}>
                            {attr.attribute_value || '--'}
                          </Typography>
                        </Box>
                      );
                    })}
                  </>
                );
              })}
            </Box>
          </Box>
        </Stack>
      </Box>
      <Box display='flex' justifyContent='center' p={2}>
        <Button onClick={onCopy}>Copy Indication from Previous Submission</Button>
        <Button onClick={onAcceptCurrent} autoFocus>
          Current Indication Data is Correct
        </Button>
      </Box>
    </Box>
  );
};

export default React.memo(IndicationComparison);
