import makeRequest from './client';

const baseURL = `/user_excel_upload`;

const createUsers = (payload: object) => {
  const finalURL = `${baseURL}/createUsers`;
  return makeRequest(finalURL, 'POST', payload);
};

export default createUsers;
