import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// mui
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import { DialogContent, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';

// api
import { getAddcommRosterById, getMemberList, upsertAdcommRoster } from '../../api/AdcommModule';

// Store
import Actions from '../../store/actions';
import Store from '../../store';
import CurationForm from '../CustomDashboard/CurationForm';
import { ROSTER_FORM_MAPPING, DEGREE_OPTIONS } from './constant';

// utils
import getDataFields, { getFieldsFromApiData } from '../CustomDashboard/utils/getDataFields';

const RosterCurationForm = ({ closeEdit, id, pdf }: any) => {
  const { meetingId } = useParams<any>();
  const [pdfUrl, setpdfUrl] = useState<any>({});
  const { dispatch } = useContext<any>(Store);
  const [rosterData, setRosterData] = useState<any>([]);
  const [documentId, setdoucmentId] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (pdfUrl) {
      const roster: Array<any> = [...rosterData];
      roster.forEach((ele: any) => {
        if (ele.id === 'material_category') {
          // eslint-disable-next-line no-param-reassign
          ele.value = pdfUrl?.material_category || '';
          // eslint-disable-next-line no-param-reassign
          ele.validate = Boolean(documentId);
        }
      });
      setRosterData(roster);
    }
  }, [pdfUrl]);

  useEffect(() => {
    const getRosterData = async () => {
      try {
        setLoading(true);
        let roster: any = {};
        if (id) {
          const result = await getAddcommRosterById(id);
          if (result.data.status === 200) {
            roster = result.data.body.roster;
          }
        }
        setdoucmentId(roster?.document_id || '');
        const rosterList: Array<any> = getFieldsFromApiData(roster, ROSTER_FORM_MAPPING);

        const data = await getMemberList();
        try {
          if (data.data.status === 200) {
            const memberList: Array<any> = [];
            data.data.body.forEach((element: string) => {
              memberList.push({ label: element, value: element });
            });
            if (rosterList) {
              rosterList.forEach((ele: any) => {
                if (ele.id === 'member') {
                  // eslint-disable-next-line no-param-reassign
                  ele.options = memberList;
                }
                if (ele.id === 'degree') {
                  // eslint-disable-next-line no-param-reassign
                  ele.options = DEGREE_OPTIONS;
                }
              });
            }
          } else {
            await dispatch({
              type: Actions.SET_ALERT,
              value: { status: true, message: `${data.data.Error}` }
            });
          }
        } catch (e: any) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: e.message }
          });
        }

        setRosterData(rosterList);
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
        closeEdit();
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getRosterData();
  }, [id]);

  const generateMemberId = (name: string) => {
    const nameArray = name.trim().split(' ');
    let memberId = '';
    const lastIndex = nameArray.length - 1;
    nameArray.forEach((nameLocal, index) => {
      let localName: any = nameLocal.trim();
      if (localName && localName !== '.' && localName !== '-') {
        // eslint-disable-next-line array-callback-return,consistent-return
        localName = localName.split('').map((nam: string) => {
          if (nam && nam !== '.' && nam !== '-') {
            return nam;
          }
        });
        localName = localName.join('');
        if (index === lastIndex) {
          memberId = `${memberId}${localName.charAt(0).toUpperCase()}${localName.slice(1)}`;
        } else {
          memberId = `${memberId}${localName.charAt(0).toUpperCase()}`;
        }
      }
    });

    return memberId;
  };

  const handleSubmit = async (e: React.SyntheticEvent, data: any) => {
    try {
      if (Object.keys(data).length !== 0) {
        const user = await Auth.currentUserInfo();
        const memberData: any = getDataFields(data);
        const payload = {
          ...memberData,
          curator_email: user.attributes.email,
          curator_name: user.attributes['custom:user'],
          qc_date: new Date(),
          qc_complete: true,
          status: 'Qced',
          document_id: pdfUrl?.id || '',
          s3_link: pdfUrl.s3_link || pdfUrl.meeting_link,
          meeting_id: meetingId,
          member_id: generateMemberId(memberData.member),
          id
        };
        const result = await upsertAdcommRoster(meetingId, payload);
        if (result && result.data.status === 200) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: {
              status: true,
              message: `Roster ${id === 'add' ? 'added' : 'edited'} successfully`,
              color: 'success'
            }
          });
        } else {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: result.data.error }
          });
        }
      }
    } catch (error: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    } finally {
      closeEdit();
    }
  };
  return (
    <Container sx={{ overflow: 'hidden' }}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Roster Curation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <CurationForm
              fields={rosterData}
              handleSubmit={handleSubmit}
              fileUrls={pdf}
              setSelectedPdfUrl={setpdfUrl}
            />
          ) : (
            <Box display='flex' maxHeight='100vh' sx={{ overflow: 'hidden' }}>
              <Skeleton variant='rectangular' height='100vh' width='100%' />
            </Box>
          )}
        </DialogContent>
      </div>
    </Container>
  );
};

export default React.memo(RosterCurationForm);
