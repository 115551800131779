import makeRequest from './client';

export const getSchemaList = () => makeRequest(`/data_quality/schemas`, 'GET');

export const getJSONResultData = (schema: string) =>
  makeRequest(`/data_quality/results/${schema}`, 'GET');

export const updateRules = (payload: any) => makeRequest(`/data_quality/rules`, 'POST', payload);

export const getSuggestedRules = (schema: string, table: string) =>
  makeRequest(`/data_quality/suggestions/${schema}/${table}`, 'GET');

export const getTableData = (schema: string, table: string) =>
  makeRequest(`/data_quality/data/${schema}/${table}`, 'GET');
