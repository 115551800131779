import * as React from 'react';
import { useContext, useState } from 'react';
import { alpha, styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import List from '@mui/material/List';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import SearchIcon from '@mui/icons-material/Search';
import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Toolbar,
  Typography
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import Styles from './styles/GuidanceList.styles';
import GuidanceListDisplay from './GuidanceListDisplay';
import Loading from './GuidanceLoading';
import { searchQcdGuidance } from '../../api/AdminPage';
import Actions from '../../store/actions';
import Store from '../../store';
import AdminStore from '../../store/AdminPage';
import GuidanceCompare from './GuidanceCompare';
import AdminActions from '../../store/AdminPage/actions';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%'
  }
}));
const StyledSelect = styled(Select)(({ theme }) => ({
  height: '41px',
  marginRight: theme.spacing(2),
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25)
  },
  color: 'white',
  '& fieldset.Mui-focused': {
    border: '1px solid red !important'
  },
  '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: '1px solid red !important',
    borderRadius: '5px 5px 0 0'
  }
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Slide direction='up' ref={ref} {...props} />;
});

const columns = [
  'guidance_pk',
  'changed',
  'field_associated_media_2',
  'field_center',
  'field_comment_close_date',
  'field_communication_type',
  'field_docket_number',
  'field_docket_number_link',
  'field_final_guidance_1',
  'field_issue_datetime',
  'field_issuing_office_taxonomy',
  'field_regulated_product_field',
  'field_topics',
  'open_comment',
  'term_node_tid',
  'title',
  'title_link',
  'topics_product',
  'doc_hash',
  's3_file_path'
];
// eslint-disable-next-line react/prop-types
const GuidanceSearch = ({ open, onClose }) => {
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const { adminState, adminDispatch } = useContext(AdminStore);

  const classes = Styles();
  const [loading, setLoading] = useState(false);
  const [qcedGuidance, setQcedGuidance] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [searchField, setSearchField] = useState('title');
  const [searched, setSearched] = useState(false);
  const [compare, setCompare] = useState(false);
  const handleSearch = async () => {
    try {
      setSearched(true);
      setLoading(true);
      const res = await searchQcdGuidance(searchValue, searchField);
      if (res === undefined) {
        throw new Error('No response');
      }
      if (res.data && res.data.Success) {
        setQcedGuidance([...res.data.Success]);
      } else if (res.data.Status && res.data.Status === 404) {
        setQcedGuidance([]);
      } else {
        throw new Error(res.data.Error);
      }
      setLoading(false);
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Something went wrong' }
      });
      history.push('/');
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={() => onClose()}
      TransitionComponent={Transition}
      classes={{
        root: classes.dialog
      }}>
      {compare ? (
        <GuidanceCompare
          onClose={async () => {
            await adminDispatch({
              type: AdminActions.SET_QCED_GUIDANCE_COMPARE,
              value: {}
            });
            setCompare(false);
          }}
        />
      ) : (
        <>
          <AppBar sx={{ position: 'relative' }} className={classes.appBar}>
            <Toolbar>
              <IconButton edge='start' color='inherit' onClick={() => onClose()} aria-label='close'>
                <CloseIcon />
              </IconButton>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase
                  placeholder='Search…'
                  inputProps={{ 'aria-label': 'search' }}
                  onChange={e => setSearchValue(e.target.value)}
                  value={searchValue}
                />
              </Search>
              <FormControl variant='outlined' style={{ minWidth: '200px' }}>
                <InputLabel id='search-field-label' className={classes.selectInputLabel}>
                  Field
                </InputLabel>
                <StyledSelect
                  labelId='search-field-label'
                  id='search-field'
                  value={searchField}
                  label='Field'
                  onChange={e => setSearchField(e.target.value)}>
                  {columns.map(column => (
                    <MenuItem key={column} value={column}>
                      {column.split('_').join(' ')}
                    </MenuItem>
                  ))}
                </StyledSelect>
              </FormControl>

              <Button
                className={`${classes.buttonText} ${classes.searchButton}`}
                color='secondary'
                variant='contained'
                disabled={!searchValue || !searchField}
                onClick={handleSearch}>
                Search
              </Button>
            </Toolbar>
          </AppBar>
          <DialogContent style={{ marginTop: '90px' }}>
            {adminState && adminState.nonQcedGuidanceCompare && (
              <List>
                <Typography variant='h6'>Non Qced selected for compare</Typography>
                <GuidanceListDisplay
                  documents={adminState.nonQcedGuidanceCompare}
                  disableCompare
                  insertButton
                />
              </List>
            )}
            <Divider />
            <Box className={classes.searchRoot}>
              <Typography variant='h6'>Qced search results</Typography>
              {/* eslint-disable-next-line no-nested-ternary */}
              {loading ? (
                <Loading dataLoading />
              ) : searched ? (
                <List sx={{ width: '100%' }}>
                  {qcedGuidance && qcedGuidance.length > 0 ? (
                    qcedGuidance.map(item => (
                      <GuidanceListDisplay
                        key={item.guidance_pk}
                        documents={item}
                        type='qced'
                        onClose={() => setCompare(true)}
                        disableCompare={
                          adminState &&
                          adminState.qcedGuidanceCompare &&
                          adminState.qcedGuidanceCompare.guidance_pk === item.guidance_pk
                        }
                      />
                    ))
                  ) : (
                    <Typography variant='h6'>No results found</Typography>
                  )}
                </List>
              ) : (
                <Typography variant='h6'>Please enter a search term</Typography>
              )}
            </Box>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
export default GuidanceSearch;
