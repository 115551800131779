import React, { useState, useEffect } from 'react';

import {
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  MenuItem
} from '@mui/material';

import { Close } from '@mui/icons-material';
import { Auth } from 'aws-amplify';

import { WHATS_NEW_STATUSES } from './constants';
import { updateWhatsNewStatus } from '../../api/WhatsNew';

interface WhatsNewEntry {
  id: number;
  type: string;
  status: string;
  title: string;
  added_by: string;
  approved_by: string;
  published_by: string;
  published_on: string;
  created_at: string;
  updated_at: string;
}

interface UpdateStatusProps {
  showUpdateStatusDialog: boolean;
  closeUpdateStatusDialog: () => void;
  entry: WhatsNewEntry | null;
  handleSubmitRefetch: () => void;
}

const UpdateStatus: React.FC<UpdateStatusProps> = ({
  showUpdateStatusDialog,
  closeUpdateStatusDialog,
  entry,
  handleSubmitRefetch
}) => {
  const [selectedStatus, setSelectedStatus] = useState<string>('');

  useEffect(() => {
    setSelectedStatus('');
  }, [showUpdateStatusDialog]);

  const updateStatus = async (payload: any) => {
    try {
      const result = await updateWhatsNewStatus(payload);

      if (result?.status !== 200) {
        return;
      }
      handleSubmitRefetch();
      closeUpdateStatusDialog();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const handleStatusChange = (event: SelectChangeEvent) => {
    setSelectedStatus(event.target.value);
  };

  const handleStatusUpdateConfirm = async () => {
    if (!entry) {
      return;
    }

    let newStatus = '';
    if (entry.status === WHATS_NEW_STATUSES.Draft) {
      newStatus = WHATS_NEW_STATUSES['Design Review'];
    } else if (entry.status === WHATS_NEW_STATUSES['Design Review']) {
      newStatus = WHATS_NEW_STATUSES['Final Review'];
    } else if (
      entry.status === WHATS_NEW_STATUSES['Final Review'] ||
      entry.status === WHATS_NEW_STATUSES.Approved
    ) {
      if (selectedStatus.length < 1) {
        return;
      }

      newStatus = selectedStatus;
    }

    if (newStatus.length < 1) {
      return;
    }

    const user = await Auth.currentUserInfo();

    const payload = {
      id: entry.id,
      status: newStatus,
      user: user.attributes.email
    };

    updateStatus(payload);
  };

  return (
    <Dialog open={showUpdateStatusDialog} onClose={closeUpdateStatusDialog} maxWidth='md' fullWidth>
      <DialogTitle>Update Status</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={closeUpdateStatusDialog}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500]
        }}>
        <Close />
      </IconButton>
      <DialogContent>
        <Box m={1}>
          <Typography variant='body1'>
            <strong>Current Status:</strong> {entry?.status}
          </Typography>
          <Typography variant='body1'>
            <strong>Added By:</strong> {entry?.added_by}
          </Typography>
          <Typography variant='body1'>
            <strong>Created On:</strong> {entry?.created_at}
          </Typography>

          <br />

          {entry?.status === WHATS_NEW_STATUSES.Draft && (
            <Typography variant='body1' textAlign='center'>
              Change Status to <strong>{WHATS_NEW_STATUSES['Design Review']}</strong> ?
            </Typography>
          )}

          {entry?.status === WHATS_NEW_STATUSES['Design Review'] && (
            <Typography variant='body1' textAlign='center'>
              Change Status to <strong>{WHATS_NEW_STATUSES['Final Review']}</strong> ?
            </Typography>
          )}

          {entry?.status === WHATS_NEW_STATUSES['Final Review'] && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                gap: '16px',
                justifyContent: 'center'
              }}>
              Change Status to
              <FormControl variant='outlined' sx={{ mb: 2, width: '25%' }}>
                <InputLabel id='news_status_1'>New Status</InputLabel>
                <Select
                  name='news_status_1'
                  label='New Status'
                  value={selectedStatus}
                  onChange={handleStatusChange}>
                  <MenuItem value={WHATS_NEW_STATUSES['Design Review']}>
                    Back to {WHATS_NEW_STATUSES['Design Review']}
                  </MenuItem>
                  <MenuItem value={WHATS_NEW_STATUSES.Approved}>
                    {WHATS_NEW_STATUSES.Approved}
                  </MenuItem>
                </Select>
              </FormControl>
              ?
            </Box>
          )}

          {entry?.status === WHATS_NEW_STATUSES.Approved && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'baseline',
                gap: '16px',
                justifyContent: 'center'
              }}>
              Change Status to
              <FormControl variant='outlined' sx={{ mb: 2, width: '25%' }}>
                <InputLabel id='news_status_2'>New Status</InputLabel>
                <Select
                  name='news_status_2'
                  label='New Status'
                  value={selectedStatus}
                  onChange={handleStatusChange}>
                  <MenuItem value={WHATS_NEW_STATUSES['Design Review']}>
                    Back to {WHATS_NEW_STATUSES['Design Review']}
                  </MenuItem>
                  <MenuItem value={WHATS_NEW_STATUSES.Published}>
                    {WHATS_NEW_STATUSES.Published}
                  </MenuItem>
                </Select>
              </FormControl>
              ?
            </Box>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' onClick={closeUpdateStatusDialog}>
          Cancel
        </Button>
        <Button variant='contained' onClick={handleStatusUpdateConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateStatus;
