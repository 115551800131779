import theme from '../../../../theme';

const styles = {
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white
  }
};

export default styles;
