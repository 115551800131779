import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    height: '100vh',
    background: theme.palette.backgrounds.dark,
  },
  container: {
    paddingTop: theme.spacing(10),
  },
  heading: {
    fontSize: theme.spacing(2.5),
    fontWeight: 'bold',
    color: theme.palette.review.label,
  },
  paper: {
    marginTop: theme.spacing(1),
    width: theme.spacing(25),
    height: theme.spacing(25)
  },
  paperBox: {
    width: '100%',
    height: '100%'
  },
  cnt: {
    color: theme.palette.black,
    fontSize: theme.spacing(3),
    fontWeight: 'bold'
  }
}))