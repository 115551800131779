const styles = {
  root: {
    padding: '40px',
    '& .MuiDataGrid-root': {
      width: '100%',
      '& .MuiDataGrid-columnHeader': {
        color: 'primary.main',
        fontWeight: 'bold',
        bgcolor: 'primary.backgroundDark'
      },
      '& .MuiDataGrid-columnsContainer': {
        color: 'primary.lightBluish',
        background: '#F7F7F7'
      }
    }
  }
};

export default styles;
