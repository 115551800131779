import Box from '@material-ui/core/Box';
import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
// styles
import TextField from '@material-ui/core/TextField';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { Save } from '@mui/icons-material';
import { Button } from '@material-ui/core';
import Styles from './styles/AdcommiFrameStyles';

// eslint-disable-next-line react/prop-types
const AdCommWebPageiFrame = ({ webLink, isPre2009, setWebLink, showOpenButton }) => {
  const urlRegex =
    /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;

  const classes = Styles();

  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const handleOpenPopup = () => {
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow = window.open(webLink, '_blank', option);

    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
        setOpenSubmitConfirmation(true);
      }
    }, 1000);
  };
  useEffect(() => {
    if (!webLink && isPre2009) {
      setOpenSubmitConfirmation(true);
    }
  }, [webLink, isPre2009]);

  if (isPre2009) {
    return (
      <>
        <Box
          className={classes.webPageContainer}
          display='flex'
          flexDirection='column'
          alignItems='flex-start'
          justifyContent='flex-start'>
          <Button onClick={handleOpenPopup} fullWidth className={classes.button}>
            Open as a popup window
          </Button>
          {webLink && webLink ? (
            <iframe
              id='iframe_id'
              title='webpage'
              src={webLink && webLink}
              allowFullScreen
              className={classes.webPage}
            />
          ) : (
            <Typography className={classes.notAvailable}>
              No webpage is available. Click button above to access webpage directly.
            </Typography>
          )}
        </Box>
        <Dialog
          maxWidth='lg'
          fullWidth
          open={openSubmitConfirmation}
          aria-labelledby='alert-dialog-title'
          disableEscapeKeyDown
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <div>Adcomm URL</div>
              {/* <IconButton aria-label='close' onClick={() => setOpenSubmitConfirmation(false)}> */}
              {/*  <Close /> */}
              {/* </IconButton> */}
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Are you sure you have the correct url?
            </DialogContentText>
            <Box display='flex'>
              <TextField
                required
                variant='outlined'
                fullWidth
                value={webLink}
                error={!urlRegex.test(webLink)}
                helperText={
                  !urlRegex.test(webLink)
                    ? 'Invalid url'
                    : 'Kindly ensure you have copied the entire url'
                }
                onChange={e => setWebLink(e.target.value)}
                placeholder='Meeting url'
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              variant='outlined'
              color='secondary'
              className={classes.button}
              disabled={!urlRegex.test(webLink)}
              onClick={() => handleOpenPopup()}>
              Open link
            </Button>
            <Button
              startIcon={<Save />}
              variant='contained'
              onClick={() => setOpenSubmitConfirmation(false)}
              color='secondary'
              disabled={!urlRegex.test(webLink)}
              className={classes.button}>
              Save link
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  return (
    <Box
      className={classes.webPageContainer}
      display='flex'
      flexDirection='column'
      alignItems='flex-start'
      justifyContent='flex-start'>
      {showOpenButton && (
        <Button onClick={() => window.open(webLink, '_blank')} fullWidth className={classes.button}>
          Open as a popup window
        </Button>
      )}
      {webLink && webLink ? (
        <iframe title='webpage' src={webLink && webLink} className={classes.webPage} />
      ) : (
        <Typography className={classes.notAvailable}>
          No webpage is available. Click button above to access webpage directly.
        </Typography>
      )}
    </Box>
  );
};
export default AdCommWebPageiFrame;
