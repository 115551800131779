export const qcStatus = ['Escalate', 'Non Qced', 'Qced', 'Automated'];

export const Fields = [
  {
    id: 'indications',
    labelName: 'Indications'
  },
  {
    id: 'contraindications',
    labelName: 'Contraindications'
  },
  {
    id: 'serious_warnings_and_precautions_box',
    labelName: 'Serious Warnings AND Precautions Box'
  },
  {
    id: 'dosage_and_administration',
    labelName: 'Dosage And Administration'
  },
  {
    id: 'overdosage',
    labelName: 'Over Dosage'
  },
  {
    id: 'dosage_forms_strengths_composition_and_packaging',
    labelName: 'Dosage Forms Strengths Composition and Packaging'
  },
  {
    id: 'warnings_and_precautions',
    labelName: 'Warnings and Precautions'
  },
  {
    id: 'adverse_reactions',
    labelName: 'Adverse Reactions'
  },
  {
    id: 'drug_interactions',
    labelName: 'Drug Interactions'
  },
  {
    id: 'clinical_pharmacology',
    labelName: 'Clinical Pharmacology'
  },
  {
    id: 'storage_stability_and_disposal',
    labelName: 'Storage Stability and Disposal'
  },
  {
    id: 'special_handling_instructions',
    labelName: 'Special Handling Instructions'
  },
  {
    id: 'pharmaceutical_information',
    labelName: 'Pharmaceutical Information'
  },
  {
    id: 'clinical_trials',
    labelName: 'Clinical Trials'
  },
  {
    id: 'microbiology',
    labelName: 'Microbiology'
  },
  {
    id: 'nonclinical_toxicology',
    labelName: 'Non Clinical Toxicology'
  },
  {
    id: 'supporting_product_monographs',
    labelName: 'Supporting Product Monographs'
  },
  {
    id: 'detailed_pharmacology',
    labelName: 'Detailed Pharmaology'
  },
  {
    id: 'references',
    labelName: 'References'
  },
  {
    id: 'patient_medication_information',
    labelName: 'Patient Medication Information'
  }
];
