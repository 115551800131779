import React, { useContext, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress
} from '@mui/material';
import Stack from '@mui/material/Stack';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { getPriceList } from '../../../api/pmda';
import Actions from '../../../store/actions';
import styles from '../../Datagrid/Datagrid.styles';
import DatagridFooter from '../../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../../Datagrid/DatagridNoRowsOverlay';
import Store from '../../../store';
import { GetField } from './PMDAEditDialog';

const PriceListData = ({ productData, setProductData, handleNext }: any) => {
  const { dispatch } = useContext<any>(Store);

  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [priceList, setPriceList] = useState<any>([]);
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [localProductData, setLocalProductData] = useState<any>(productData);

  const rowsPerPageOptions = [10, 25, 50, 100];
  const DATAGRID_COLUMN = [
    {
      field: 'product_name_jp',
      headerName: 'Name (JP)',
      width: 500,
      headerAlign: 'center',
      align: 'left'
    },
    {
      field: 'formulation',
      headerName: 'Formulation',
      width: 100,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'route',
      headerName: 'Route',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'product_type',
      headerName: 'Product Type',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params: any) => {
        if (
          !params.row.actual_product_type ||
          params.row.actual_product_type === 'null' ||
          params.row.actual_product_type === 'undefined' ||
          params.row.actual_product_type === 'NULL' ||
          params.row.actual_product_type === 'NaN'
        ) {
          return 'Undefined';
        }
        return params.row.product_type;
      }
    },

    {
      field: 'nhi_code',
      headerName: 'NHI Code',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'strength_jp',
      headerName: 'Strength (JP)',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    }
  ];

  const fetchPriceList = async (activeIngredientId: string) => {
    setLoading(true);

    const result = await getPriceList(activeIngredientId);
    setLoading(false);

    if (result.data && result.data.body.results) {
      const resultData = result.data.body.results;
      setRowCount(result.data.body.total);
      setPriceList(resultData);
    } else {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'No data' }
      });
    }
  };
  useEffect(() => {
    if (productData) {
      if (
        !productData?.nhi_code ||
        !productData?.formulation ||
        !productData?.route ||
        !productData?.product_type
      ) {
        fetchPriceList(productData?.active_ingredient_id);
      } else {
        handleNext();
      }
    }
  }, [productData]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} mt={3}>
          <Stack px={2} direction='row'>
            <GetField
              label='nhi_code'
              value={localProductData?.nhi_code}
              setLocalProductData={setLocalProductData}
            />
            <GetField
              label='formulation'
              value={localProductData?.formulation}
              setLocalProductData={setLocalProductData}
            />
            <GetField
              label='route'
              value={localProductData?.route}
              setLocalProductData={setLocalProductData}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id='product_type'>Product type</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={localProductData?.product_type}
                label='Product Type'
                onChange={(e: SelectChangeEvent) => {
                  setLocalProductData((prev: any) => ({
                    ...prev,
                    product_type: e.target.value
                  }));
                }}>
                <MenuItem value='Generic'>Generic</MenuItem>
                <MenuItem value='Original'>Original</MenuItem>
                <MenuItem value='Undefined'>Undefined</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Stack justifyContent='flex-end' alignItems='flex-end' px={2} mb={2}>
            <Button
              variant='contained'
              sx={{
                color: 'white.main'
              }}
              onClick={() => {
                setOpenSubmitConfirmation(true);
              }}>
              Next
            </Button>
          </Stack>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            height: '72vh',
            border: '1px solid #000',
            '& #HeaderPopArea,#SearchHd,#FooterArea01,#FooterArea02,#FooterArea03': {
              display: 'none'
            },
            '& #ResultSet > div:first-child': {
              borderRight: '1px solid #000'
            },
            '& #ResultSet > div:nth-child(2)': {
              px: 2
            }
          }}>
          <DataGrid
            sx={styles.datagrid}
            style={{ height: '100%' }}
            rowSpacingType='margin'
            sortingOrder={['desc', 'asc']}
            checkboxSelection={false}
            density='standard'
            rows={priceList}
            columns={DATAGRID_COLUMN as any}
            rowCount={rowCount}
            pageSize={pageSize}
            getRowId={row => row.price_list_id}
            onPageSizeChange={newPageSize => {
              setPageSize(newPageSize);
              setPageNo(0);
            }}
            onPageChange={setPageNo}
            rowsPerPageOptions={rowsPerPageOptions}
            pagination
            loading={loading}
            getRowClassName={() => `table-rows`}
            page={pageNo}
            paginationMode='server'
            components={{
              LoadingOverlay: LinearProgress,
              Footer: DatagridFooter,
              NoRowsOverlay: DatagridNoRowsOverlay
            }}
          />
        </Grid>
      </Grid>
      <Dialog
        maxWidth='lg'
        fullWidth
        open={openSubmitConfirmation}
        aria-labelledby='alert-dialog-title'
        disableEscapeKeyDown
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <div>PMDA URL</div>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to submit this data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              setOpenSubmitConfirmation(false);
            }}>
            No
          </Button>

          <Button
            variant='contained'
            onClick={async () => {
              await setProductData(localProductData);
              await handleNext();
            }}
            color='secondary'>
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default React.memo(PriceListData);
