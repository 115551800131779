import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// mui
import { Stack, Typography, Container, Divider } from '@mui/material';

// hooks
import useSourceValidation from '../hooks/useSourceValidation';

// other components
import DateForm from './DateForm';
import LatestUpdatesList from './LatestUpdatesList';
import LatestUpdatesListLoader from './LatestUpdatesListLoader';

const SourceValidationLayout = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const {
    getLatestUpdatesList,
    latestUpdates,
    setSelectedIdentifier,
    handleStartChange,
    handleEndChange,
    fromDate,
    toDate,
    selectedIdentifier,
    loading
  } = useSourceValidation();

  useEffect(() => {
    // Get current date
    const currentDate = new Date();
    const formattedCurrentDate =
      queryParams.get('toDate') ?? currentDate.toISOString().split('T')[0];
    // Subtract two days
    currentDate.setDate(currentDate.getDate() - 7);
    // Format the date as YYYY-MM-DD
    const defaultDate = queryParams.get('fromDate') ?? currentDate.toISOString().split('T')[0];
    getLatestUpdatesList(defaultDate, formattedCurrentDate);
  }, []);

  return (
    <Container maxWidth='lg'>
      <Stack mt={8} spacing={2}>
        <h1>Data source: EMA EPAR</h1>
        <DateForm
          handleStartChange={handleStartChange}
          handleEndChange={handleEndChange}
          fromDate={fromDate}
          toDate={toDate}
          getLatestUpdatesList={getLatestUpdatesList}
        />
        <Divider />

        {loading && <LatestUpdatesListLoader />}
        {!loading && (
          <>
            <Typography variant='subtitle1'>
              Showing latest updates from <b>{fromDate}</b> to <b>{toDate}</b>
            </Typography>
            <LatestUpdatesList
              latestUpdates={latestUpdates}
              setSelectedIdentifier={setSelectedIdentifier}
              selectedIdentifier={selectedIdentifier}
              fromDate={fromDate}
              toDate={toDate}
            />
          </>
        )}
      </Stack>
    </Container>
  );
};

export default SourceValidationLayout;
