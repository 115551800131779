import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

const initialState = {
  loading: false,
  cartItems: 0,
  advanceSearch: null,
  report: [],
  pdfMessage: 'Nothing has been added to the report',
  graphType: [],
  searchTerm: null,
  cardDetails: [],
  applicationContent: [],
  patentExpansion: false,
  userStub: null,
  module: '/',
  category: 'indications_and_usage',
  alert: {},
  db: 'us',
  timelineData: [],
  extendedCard: {},
  bot: false,
  adcommCommittee: 'ALL',
  treeData: {},
  searchDetails: JSON.parse(sessionStorage.getItem('searchDetails')) || null,
  procedural_steps_prod_no: null,
  selectedCard: null, // selected application (card) for a project.
  projectDialog: false, // open/close projects list dialog box.,
  itemType: null, // type of the item to be store in a project(application/query/report)
  submitProjectName: null, // project name
  advanceSearchPayload: {},
  advanceSearchCnfQuery: '',
  advanceSearchConditions: {
    date_filter: {},
    useSynonyms: true
  },
  adcommMinutesAddDetails: {},
  homeTabs: 0
};

const Store = createContext(initialState);
const { Provider } = Store;

// eslint-disable-next-line react/prop-types
export const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState);
  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export default Store;
