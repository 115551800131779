import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    height : '100vh',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  outline:{
    height: '100vh',
    width : '100%',
    backgroundColor : theme.palette.white,
  },
  button: {
    borderRadius: theme.spacing(5),
    minWidth: 400,
    backgroundColor: theme.palette.black,
    color: theme.palette.white,
    textTransform: 'none',
    margin: theme.spacing(2),
    '&:hover': {
      background: theme.palette.login.darkgray,
   },
   boxShadow : 'none'
  },
  paper : {
    alignItems: 'center',
    display: "flex",
    height: theme.spacing(6),
    backgroundColor: theme.palette.login.gray,
    borderRadius: theme.spacing(5),
    width: 'auto',
    margin: theme.spacing(1),
    minWidth:400,
  },
  link:{
    color: theme.palette.black,
    margin: theme.spacing(2),
    fontSize : theme.spacing(1.5),
    textDecoration : 'underline'
  },
  title : {
    fontSize : theme.spacing(3),
  },
  description : {
    color: theme.palette.black,
    margin: theme.spacing(2),
    fontSize : theme.spacing(1.5),
    opacity : 0.6
  },
  textfieldIcon:{
    marginLeft : theme.spacing(1.25),
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
}))