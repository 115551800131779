/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState, FC } from 'react';
import { Button, CircularProgress, Box, Tooltip } from '@mui/material';
import { read, utils, writeFile } from 'xlsx';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';

import createUsers from '../../api/user_excel_upload';
import Styles from './UserUpload.style';
import { User } from './type';
import validateUser_, {
  validateFirstName,
  validateLastName,
  validateEmail,
  validateOrganization,
  validateStatus,
  validateLicense,
  validateRole
} from './validation';
import { EXPECTED_COLUMNS, VALID_NUMBER_OF_COLUMNS } from './constants';

const ExcelUploadComponent: FC = () => {
  const classes: any = Styles();

  const [jsonData, setJsonData] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [hasError, setHasError] = useState(false);

  const validateUser = (refinedUser: User) => {
    if (validateUser_(refinedUser)) markError();
  };

  const getRefinedData = (data: unknown[]): User[] => {
    return data.map((currentData: any, index) => {
      const refinedUser = {
        id: index,
        first_name: (currentData['First Name'] ?? '').trim(),
        last_name: (currentData['Last Name'] ?? '').trim(),
        email: (currentData.Email ?? '').trim(),
        organization: (currentData.Organization ?? '').trim(),
        status: (currentData.Status ?? '').trim(),
        license: (currentData.License ?? '').trim(),
        role: (currentData.Role ?? '').trim()
      };

      if (!hasError) {
        validateUser(refinedUser);
      }

      return refinedUser;
    });
  };

  const markError = (errorMessage = 'Uploaded file has error.') => {
    setError(errorMessage);
    setHasError(true);
  };

  const validateExcelColumns = (columns: string[]) => {
    const validNumberOfColumns = VALID_NUMBER_OF_COLUMNS;

    if (columns.length < validNumberOfColumns) {
      return 'Missing column(s)';
    }

    const expectedColumns = EXPECTED_COLUMNS;

    if (expectedColumns.every(columnName => columns.includes(columnName))) {
      return null;
    }

    return 'Missing expected column(s)';
  };

  const handleFileUpload = (uploadedFile: any) => {
    setLoading(true);
    setHasError(false);
    setError('');
    // Reset jsonData state
    setJsonData([]);
    setSuccess(false);
    try {
      const file = uploadedFile;

      if (!file) {
        return;
      }

      const reader = new FileReader();
      reader.onload = e => {
        const data = e.target?.result;

        if (!data) {
          return;
        }

        const workbook = read(data, { type: 'binary' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const parsedData = utils.sheet_to_json(worksheet);
        const columnNames = (utils.sheet_to_json(worksheet, { header: 1 })?.[0] as string[]) || [];

        const columnsError = validateExcelColumns(columnNames);

        if (columnsError) {
          setJsonData([]);
          markError(columnsError);
        } else {
          const refinedData = getRefinedData(parsedData);

          if (refinedData.length < 1) {
            markError('Empty rows');
          }

          setJsonData([...refinedData]);
        }
      };
      reader.readAsBinaryString(file);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadSample = () => {
    const json = [
      {
        'First Name': '',
        'Last Name': '',
        Email: '',
        Organization: '',
        Status: '',
        License: '',
        Role: ''
      }
    ];

    const worksheet = utils.json_to_sheet(json);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet);
    writeFile(workbook, 'User Excel Upload Sample.xlsx', { compression: true });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const response = await createUsers(jsonData);
    if (response.status === 200) {
      setSuccess(true);
    } else if (response.status === 400) {
      setHasError(true);
      setError(
        `${response?.data?.errors}
          \n Users uploaded 
          ${JSON.stringify(response?.data?.users_uploaded)}`
      );
    } else {
      setHasError(true);
      setError('Something went wrong');
    }
    setLoading(false);
  };

  const getErrorCell = (errorTooltipMessage: string, value: string) => {
    return (
      <Tooltip title={errorTooltipMessage}>
        <Box className={classes.errorCell}>{value}</Box>
      </Tooltip>
    );
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Excel Row No.',
      headerAlign: 'center',
      align: 'center',
      width: 125,
      valueGetter: params => {
        return params.value + 2;
      },
      sortable: false
    },
    {
      field: 'first_name',
      headerName: 'First Name',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => {
        const firstNameError = validateFirstName(params.value);
        return firstNameError ? getErrorCell(firstNameError, params.value) : params.value;
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'last_name',
      headerName: 'Last Name',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => {
        const lastNameError = validateLastName(params.value);
        return lastNameError ? getErrorCell(lastNameError, params.value) : params.value;
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'email',
      headerName: 'Email',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => {
        const emailError = validateEmail(params.value);
        return emailError ? getErrorCell(emailError, params.value) : params.value;
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'organization',
      headerName: 'Organization',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => {
        const organizationError = validateOrganization(params.value);
        return organizationError ? getErrorCell(organizationError, params.value) : params.value;
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => {
        const statusError = validateStatus(params.value);
        return statusError ? getErrorCell(statusError, params.value) : params.value;
      },
      flex: 1,
      sortable: false
    },
    {
      field: 'license',
      headerName: 'License',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => {
        const licenseError = validateLicense(params.value);
        return licenseError ? getErrorCell(licenseError, params.value) : params.value;
      },
      sortable: false
    },
    {
      field: 'role',
      headerName: 'Role',
      headerAlign: 'center',
      align: 'center',
      width: 100,
      renderCell: (params: GridRenderCellParams<any>) => {
        const roleError = validateRole(params.value);
        return roleError ? getErrorCell(roleError, params.value) : params.value;
      },
      sortable: false
    }
  ];

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'right', gap: '16px', paddingRight: '1rem' }}>
        <Button
          variant='contained'
          color='secondary'
          component='span'
          onClick={handleDownloadSample}
          sx={{ width: '15%' }}>
          Download Sample
        </Button>
        <label htmlFor='file-upload'>
          <Button variant='contained' color='primary' component='span'>
            Upload File
          </Button>
          <input
            accept='.xlsx,.csv'
            style={{ display: 'none' }}
            id='file-upload'
            type='file'
            onChange={e => {
              handleFileUpload(e?.target?.files?.[0]);
              e.target.value = '';
            }}
          />
        </label>
      </Box>

      {loading && (
        <Box sx={{ textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      )}
      {hasError && (
        <Box sx={{ color: 'red', display: 'flex', justifyContent: 'center' }}>
          <p>Error: {error}</p>
        </Box>
      )}
      {success && (
        <Box sx={{ color: 'green', display: 'flex', justifyContent: 'center' }}>
          <p>Success: All Users uploaded Successfully</p>
        </Box>
      )}
      {!loading && jsonData.length > 0 && (
        <>
          <Box
            height='60vh'
            p={2}
            mt={1}
            display='flex'
            alignItems='center'
            justifyContent='center'>
            <DataGrid
              rows={jsonData}
              columns={columns}
              rowSpacingType='margin'
              density='standard'
              disableColumnMenu
            />
          </Box>
          {!hasError && (
            <Button
              variant='contained'
              color='secondary'
              component='span'
              onClick={handleSubmit}
              disabled={loading}
              sx={{ width: '15%', marginLeft: '1rem' }}>
              Submit
            </Button>
          )}
        </>
      )}
    </>
  );
};

export default ExcelUploadComponent;
