import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Container, List, Typography } from '@material-ui/core';

import { Box } from '@mui/material';
import Actions from '../../store/actions';
import AdminActions from '../../store/AdminPage/actions';
import Store from '../../store';
import Styles from './styles/GuidanceList.styles';
import GuidanceListDisplay from './GuidanceListDisplay';
import Loading from './GuidanceLoading';
import GuidanceSearch from './GuidanceSearch';
import AdminStore, { AdminStateProvider } from '../../store/AdminPage';
import { getNonQcedGuidance } from '../../api/AdminPage';

const GuidanceList = () => {
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const { adminState, adminDispatch } = useContext(AdminStore);

  const classes = Styles();

  const [loading, setLoading] = useState(true);
  const [nonQcedGuidance, setNonQcedGuidance] = useState([]);
  const [openSearch, setOpenSearch] = useState(false);

  const setQcedStoreFunction = async () => {
    await adminDispatch({
      type: AdminActions.SET_QCED_GUIDANCE_COMPARE,
      value: {}
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getNonQcedGuidance();
        if (res === undefined) {
          throw new Error('No response');
        }
        if (res.data && res.data.Success) {
          setNonQcedGuidance([...res.data.Success]);
        } else if (res.data && res.data.Status === 404) {
          setNonQcedGuidance([]);
        } else {
          throw new Error(res.data.Error);
        }
        setLoading(false);
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Something went wrong' }
        });
        history.push('/');
      }
    };
    fetchData();
  }, []);
  useEffect(() => {
    return () => {
      const unMount = async () => {
        await adminDispatch({
          type: AdminActions.SET_NON_QCED_GUIDANCE_COMPARE,
          value: {}
        });
      };
      unMount();
    };
  }, []);

  if (loading) {
    return (
      <Box className={classes.root}>
        <Container maxWidth='lg' className={classes.container}>
          <Loading dataLoading />
        </Container>
      </Box>
    );
  }
  if (adminState && adminState.isSubmitting)
    return (
      <Box className={classes.root}>
        <Container maxWidth='lg' className={classes.container}>
          <Loading dataSubmitting />
        </Container>
      </Box>
    );

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <GuidanceSearch
          open={openSearch}
          onClose={async () => {
            await adminDispatch({
              type: AdminActions.SET_NON_QCED_GUIDANCE_COMPARE,
              value: {}
            });
            setOpenSearch(false);
          }}
        />
        <Typography variant='h6' className={classes.whiteText}>
          Non Qced guidance document
          {adminState &&
            adminState.nonQcedGuidanceCompare &&
            adminState.nonQcedGuidanceCompare.guidance_pk}
        </Typography>
        <List sx={{ width: '100%' }}>
          {nonQcedGuidance && nonQcedGuidance.length <= 0 && (
            <Typography variant='h6' className={classes.whiteText}>
              No documents available for qced
            </Typography>
          )}
          {nonQcedGuidance?.map(item => (
            <GuidanceListDisplay
              key={item.guidance_pk}
              documents={item}
              disableCompare={
                adminState &&
                adminState.nonQcedGuidanceCompare &&
                adminState.nonQcedGuidanceCompare.guidance_pk === item.guidance_pk
              }
              onClose={() => {
                setQcedStoreFunction();
                setOpenSearch(true);
              }}
            />
          ))}
        </List>
      </Container>
    </Box>
  );
};

const GuidanceListWrapper = () => {
  return (
    <AdminStateProvider>
      <GuidanceList />
    </AdminStateProvider>
  );
};
export default GuidanceListWrapper;
