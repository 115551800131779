import Dashboard from '../components/Dashboard/Dashboard';

const DASHBOARD_ROUTES = [
  {
    id: 'dashboard',
    path: '/dashboard',
    component: Dashboard,
    exact: true
  }
];
export default DASHBOARD_ROUTES;
