import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Tooltip } from '@mui/material';
import StyledTabs, { StyledTab } from '../components/StyledComponents/VivproTabs';
import Styles, { styles } from './pdfViewer.styles';

const TabPanel = props => {
  const { children, value, index, pdfUrl, row, ...other } = props;
  const classes = Styles();

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ height: '70vh' }}
      {...other}>
      {value === index && pdfUrl ? (
        <iframe width='100%' height='100%' title='pdf' src={pdfUrl} className={classes.pdfEma} />
      ) : (
        <Box
          style={{
            overflowY: 'auto',
            maxHeight: '60vh',
            padding: '4px'
          }}>
          <Box padding={1}>
            <Typography align='left' sx={{ fontSize: '18px', fontWeight: 'bold' }}>
              {row.label}
            </Typography>
            <Typography align='left' sx={{ fontSize: '16px' }}>
              {row.value}
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pdfUrl: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  row: PropTypes.any.isRequired
};

const PDFViewer = ({ pdf, setSelectedS3Link }) => {
  const classes = Styles();

  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
    if (setSelectedS3Link) {
      setSelectedS3Link(pdf[newValue] || '');
    }
  };
  useEffect(() => {
    if (pdf.length !== 0 && setSelectedS3Link) {
      setSelectedS3Link(pdf[0]);
    }
  }, [pdf]);
  if (pdf.length !== 0) {
    // eslint-disable-next-line react/prop-types
    if (pdf.length > 1) {
      return (
        <Box>
          <StyledTabs
            value={tab}
            onChange={handleChange}
            aria-label='admin tabs'
            variant='scrollable'
            scrollButtons
            allowScrollButtonsMobile>
            {pdf.map(({ document_title: title }, index) => {
              return (
                <Tooltip title={title}>
                  <StyledTab
                    key={title}
                    label={title.length > 50 ? `${title.substring(0, 50)}...` : title}
                    id={index}
                    sx={tab === index ? styles.activeTab : styles.inactiveTab}
                    aria-controls={`tabpanel-${title}`}
                    wrapped
                  />
                </Tooltip>
              );
            })}
          </StyledTabs>

          {pdf.map((row, index) => (
            // eslint-disable-next-line react/jsx-no-useless-fragment
            <>
              {tab === index ? (
                <TabPanel
                  key={row.id}
                  value={tab}
                  index={index}
                  pdfUrl={row.s3_url || row.meeting_link}
                  row={row}
                />
              ) : null}
            </>
          ))}
        </Box>
      );
    }
    return (
      <TabPanel value={0} index={0} pdfUrl={pdf[0].s3_url || pdf[0].meeting_link} row={pdf[0]} />
    );
  }
  return <Typography className={classes.notAvailable}>No pdf is available</Typography>;
};
PDFViewer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pdf: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  setSelectedS3Link: PropTypes.func
};
export default PDFViewer;
