import React, { useCallback } from "react";
import { initialiseChat, sendQuery } from "../../../api/ChatRIA";
import { toNumber } from "lodash";

const useChatQuery = () => {
  const [openWidget, setOpenWidget] = React.useState<boolean>(false);
  const [applicationNumber, setApplicationNumber] = React.useState<string>("");
  const [queryText, setQueryText] = React.useState<string>("");
  const [chatFlow, setChatFlow] = React.useState<any>([]);
  const [queryError, setQueryError] = React.useState<any>(null);
  const [queryLoading, setQueryLoading] = React.useState<boolean>(false);
  const [initialised, setInitialised] = React.useState<boolean>(false);
  const [s3URL, setS3URL] = React.useState<string>("");
  const [sections, setSections] = React.useState<string[]>([]);
  const [currentSection, setCurrentSection] = React.useState<string>("");
  const [temperature, setTemperature] = React.useState<number>(0.3);
  const [topP, setTopP] = React.useState<number>(1);
  const [n, setN] = React.useState<number>(3);
  const handleChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryText(e.target.value);
  };
  const handleSectionChange = (event: React.ChangeEvent<{}>, value: any | null): void => {
    setCurrentSection(value || "");
  };
  const handleChangeTemperature = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTemperature(toNumber(e.target.value) || 0.3);
  };
  const handleChangeTopP = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTopP(toNumber(e.target.value) || 1);
  };
  const handleChangeN = (e: React.ChangeEvent<HTMLInputElement>) => {
    setN(toNumber(e.target.value) || 3);
  };

  const handleSubmitQuery = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setQueryLoading(true);
    // @ts-ignore
    setChatFlow(prev => prev ? [...prev, { text: queryText, type: "user" }] : [{ text: queryText, type: "user" }]);
    const payload = { question: queryText, temperature, top_p: topP, n };
    const response = await sendQuery({
      applicationNumber,
      section: currentSection,
      payload
    });
    if (response) {
      setQueryText("");

      const { data } = response;
      // @ts-ignore
      setChatFlow(prev => prev ? [...prev, {
        text: data.body,
        type: "bot",
        error: data.error
      }] : [{ text: data.body, type: "bot", error: data.error }]);

      setQueryLoading(false);
    }

  }, [queryText, currentSection, applicationNumber, temperature, topP, n]);
  const openSession = useCallback(async (number: string | null) => {
    if (number) {
      setOpenWidget(true);
      setApplicationNumber(number);
      const response = await initialiseChat(number);
      if (response) {
        setS3URL(response.data?.body?.s3_url);
        setSections(response.data?.body?.sections);
        setCurrentSection(response.data?.body?.sections[0]);
        setInitialised(true);
      }
    }

  }, []);
  const closeSession = useCallback(async () => {
    setOpenWidget(false);
    setApplicationNumber("");
    setQueryText("");
    setChatFlow(null);
    setQueryError(null);
    setQueryLoading(false);
    setInitialised(false);
    setS3URL("");
    setSections([]);
    setCurrentSection("");
  }, []);


  return {
    handleChangeQuery,
    handleSubmitQuery,
    queryText,
    openSession,
    openWidget,
    initialised,
    closeSession,
    chatFlow,
    s3URL,
    sections,
    queryLoading,
    currentSection,
    handleSectionChange,
    temperature, topP, n, handleChangeTemperature, handleChangeTopP, handleChangeN
  };
};
export default useChatQuery;