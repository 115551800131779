import { withStyles } from '@material-ui/core/styles';
import InputBase from '@material-ui/core/InputBase';

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `TextField` component.

const LoginTextField = withStyles(theme => ({
  root: {
    height: theme.spacing(6),
    width: '100%',
    margin: theme.spacing(1),
    '& .MuiInputBase-input': {
      fontSize: theme.spacing(1.5),
      color: theme.palette.black,
      backgroundColor: 'transparent !important'
    },
    '& .MuiInputLabel-root': {
      fontSize: theme.spacing(1.5),
      color: theme.palette.black,
      backgroundColor: 'transparent !important'
    }
  }
}))(InputBase);

export default LoginTextField;
