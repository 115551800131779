import {
  Box,
  Button,
  Container,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@mui/material';
import { useContext } from 'react';
import styles from './styles/Form.styles';
import { deleteRld } from '../../api/AdminPage';

// store
import Store from '../../store';
import Actions from '../../store/actions';
import { RldDataProps } from './RldView';

const DeleteRld = ({ id, closeDelete }: RldDataProps) => {
  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  const { state, dispatch } = useContext(Store);

  const handleDelete = async (event: any) => {
    event.preventDefault(event);
    try {
      const res = await deleteRld(id);
      if (closeDelete) closeDelete(res, null);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Operation successful', color: 'success' }
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('=>(DeleteRLD.tsx:13) error', e);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Enter valid information' }
      });
    }
  };

  return (
    <Container aria-labelledby='alert-dialog-title' aria-describedby='alert-dialog-description'>
      <Box sx={styles.dialog}>
        <DialogTitle id='alert-dialog-title'>Confirm Submission</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to delete the record?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              if (closeDelete) closeDelete(null, null);
            }}
            sx={styles.submit}
            variant='contained'
            autoFocus>
            No
          </Button>
          <Button onClick={handleDelete} variant='contained' sx={styles.submit}>
            Yes
          </Button>
        </DialogActions>
      </Box>
    </Container>
  );
};
export default DeleteRld;
