import { makeStyles } from '@material-ui/core';
import palette from '../../../themev5/palette';

export default makeStyles(() => ({
  root: {
    '& .MuiDataGrid-columnHeaderTitle': {
      color: palette.primary.main,
      fontSize: '16px',
      fontWeight: 'bold'
    },
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& .fda_org_cell': {
        height: '26px',
        '& .fda_org_cell__more-text': {
          display: 'inline !important'
        }
      }
    },
    '& .MuiTablePagination-toolbar': {
      color: palette.primary.main,
      fontWeight: 'bold'
    },
    '& .MuiDataGrid-root': {
      width: '100%',
      '& .MuiDataGrid-columnHeader': {
        color: palette.primary.main,
        fontWeight: 'bold'
      },
      '& .MuiDataGrid-columnsContainer': {
        color: palette.primary.lightBluish,
        background: '#F7F7F7'
      }
    }
  }
}));

export const styles = {
  Button: {
    margin: '10px',
    background: palette.primary.main,
    color: palette.white.main,
    padding: 1,
    '&:hover': {
      opacity: 0.8,
      background: palette.primary.main
    }
  },
  Button2: {
    margin: '10px',
    background: '#a593f2',
    color: palette.white.main,
    padding: 1,
    '&:hover': {
      opacity: 0.8,
      background: '#a593f2'
    }
  },
  title: { width: '100%', color: palette.primary.main, marginTop: '2em' },
  datagrid: {
    border: 'none',
    borderRadius: '10px',
    fontSize: '16px',

    '& .table-header': {
      width: '100%',
      color: palette.primary.main,
      fontWeight: 'bold',
      backgroundColor: palette.primary.backgroundDark,
      borderRadius: '10px'
    },
    '& .MuiDataGrid-columnHeader': { backgroundColor: palette.primary.backgroundDark },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '16px'
    },
    '& 	.MuiDataGrid-row': {
      bgcolor: 'red'
    },
    '& .MuiDataGrid-cell:hover': {
      color: 'black.main'
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: '16px'
    },
    '& .MuiTablePagination-selectLabel': {
      fontSize: '16px'
    }
  }
};
