import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// material ui
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

// styles
import Styles from './styles/PmrDataEntry.styles';

const PmrDataEntry = () => {
  const classes = Styles();
  const history = useHistory();
  const [key, setKey] = useState();

  const handleKeyChange = event => {
    const text = event.target.value;
    setKey(text);
  };

  const handleKeyFormSubmit = async event => {
    event.preventDefault();
    const docKey = key.toUpperCase();
    history.push(`/pmr/review/${docKey}`);
  };

  return (
    <Box className={classes.root} display='flex' alignItems='center'>
      <Container maxWidth='lg' className={classes.container}>
        <Paper elevation={1} className={classes.banner}>
          <Typography className={classes.formHeadings}>Enter details to qc PMR data</Typography>
          <form onSubmit={handleKeyFormSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={12}>
                <Box display='flex' flexDirection='row' justifyContent='center'>
                  <Box margin={1} display='flex' flexDirection='column'>
                    <Typography align='left' className={classes.label}>
                      Application Number
                    </Typography>
                    <TextField
                      required
                      className={classes.textfield}
                      onChange={handleKeyChange}
                      fullWidth
                      id='standard-basic'
                      placeholder='Enter a key. Eg: NDA-004782-ORIG-1'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                  </Box>
                  <Box mt={4} display='flex' alignItems='center' className={classes.btn}>
                    <Button type='submit' variant='contained' className={classes.button}>
                      Submit
                    </Button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default PmrDataEntry;
