import React, { useContext, useEffect, useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import {
  Button,
  Grid,
  Box,
  TextField,
  MenuItem,
  CssBaseline,
  LinearProgress,
  debounce,
  Typography,
  Dialog
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import themev5 from '../../themev5';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';
import Store from '../../store';
import Actions from '../../store/actions';
import { getApprovalStatusApplicationDetails, getApprovalStatusList } from '../../api/Cder';
import styles from './styles/ApprovalTable.styles';
import Styles from '../FDA/styles/CategorizationTable.styles';
import ApprovalStatusList from './ApprovalStatusList';

const qcStatusMap = (key: string) => {
  const map: any = {
    to_qc: 'To QC',
    qced: 'QCed',
    escalate: 'Escalate',
    'missing details': 'Missing Details'
  };
  return map[key];
};

const AllApprovalStatus = () => {
  const { dispatch } = useContext<any>(Store);
  const classes = Styles();
  const [pageSize, setPageSize] = useState(10);
  const [approvalStatusData, setApprovalStatusData] = useState<Array<any>>([]);
  const [center, setCenter] = useState<string>('cber');
  const [approvalStatusType, SetApprovalStatusType] = useState<string>('accelerated');
  const [searchText, setSearchText] = useState<string>('');
  const [status, setStatus] = useState<string>('all documents');
  const [pageNumber, setPageNumber] = useState(0);
  const [totalRowCount, setTotalRowCount] = useState();
  const [loading, setloading] = useState(true);
  const rowsPerPageOptions = [10, 20, 50, 100];
  const [reload, setReload] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editRow, setEditRow] = useState<any>({});

  const debouncedChangeHandler = () => {
    setReload(prevState => !prevState);
  };
  const handleChange = debounce(debouncedChangeHandler, 300);

  useEffect(() => {
    const fetchCderLabelData = async () => {
      try {
        const result = await getApprovalStatusList(
          pageNumber,
          pageSize,
          status,
          searchText.trim().toLowerCase(),
          center,
          approvalStatusType
        );

        if (!result) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.results) {
          setloading(false);
          const resultData = result.data.body.results;
          setTotalRowCount(result.data.body.total_rows);
          setApprovalStatusData(
            resultData?.map((item: any, index: any) => ({ ...item, id: index }))
          );
        } else {
          setloading(false);
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    fetchCderLabelData();
  }, [pageNumber, pageSize, center, approvalStatusType, searchText, status, reload]);

  const closeEdit = () => {
    setReload(prevState => !prevState);
    setShowEditDialog(false);
  };
  const openEdit = async (data: any) => {
    try {
      setloading(true);
      const result = await getApprovalStatusApplicationDetails(
        center,
        approvalStatusType,
        data?.application_docs_id ?? data?.document_id
      );
      if (result?.status === 200) {
        if (result?.data && result?.data?.body) {
          setloading(false);
          setEditRow({ ...data, ...result?.data?.body, center });
        } else {
          setloading(false);
          throw Error('No data');
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e }
      });
      setEditRow(data);
    } finally {
      setShowEditDialog(true);
      setloading(false);
    }
  };

  const columns = [
    {
      field: 'Actions',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Box
            style={{
              margin: '3px',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}>
            <Button
              variant='contained'
              className={classes.Button}
              onClick={() => {
                openEdit(params.row);
              }}>
              <Typography
                variant='body1'
                sx={{
                  color: 'white.main'
                }}>
                EDIT
              </Typography>
            </Button>
          </Box>
        );
      }
    },
    {
      field: 'application_number',
      headerName: 'Application Number',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'title',
      headerName: 'PDF Title',
      width: 500,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Grid item xs={8} lg={8}>
            {params.row.s3_path.split('/').slice(-1)}
          </Grid>
        );
      }
    },
    {
      field: 'qc_status',
      headerName: 'QC Status',
      width: 170,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Grid item xs={8} lg={8}>
            {qcStatusMap(params?.row?.qc_status as string)}
          </Grid>
        );
      }
    },
    {
      field: 'submission_number',
      headerName: 'Submission Number',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Grid item xs={8} lg={8}>
            {params?.row?.submission_number ?? params?.row?.submission_no}
          </Grid>
        );
      }
    },
    {
      field: 'submission_type',
      headerName: 'Submission Type',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    }
  ];
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Grid container>
        <Grid item xl={10} lg={10}>
          <Box display='flex' ml={2} mt={10} alignItems='center'>
            <TextField
              variant='outlined'
              type='search'
              id='search_application'
              label='Search Application Number'
              value={searchText}
              InputProps={{
                style: { fontSize: '1.5vh', minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: '1.5vh' }
              }}
              onChange={e => {
                setSearchText(e.target.value);
                setPageNumber(0);
                handleChange();
              }}
            />
            <TextField
              variant='outlined'
              id='status'
              label='QC Status'
              select
              value={status}
              onChange={e => setStatus(e.target.value)}
              InputProps={{
                style: { fontSize: '1.5vh', minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: '1.5vh' }
              }}>
              <MenuItem value='all documents'>All Documents</MenuItem>
              <MenuItem value='qced'>Qced</MenuItem>
              <MenuItem value='to_qc'>To QC</MenuItem>
            </TextField>
            <TextField
              variant='outlined'
              id='center'
              label='Center'
              select
              value={center}
              onChange={e => setCenter(e.target.value)}
              InputProps={{
                style: { fontSize: '1.5vh', minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: '1.5vh' }
              }}>
              <MenuItem value='cber'>CBER</MenuItem>
              <MenuItem value='cder'>CDER</MenuItem>
            </TextField>
            <TextField
              variant='outlined'
              id='approvalStatusType'
              label='Approval Status Type'
              select
              value={approvalStatusType}
              onChange={e => SetApprovalStatusType(e.target.value)}
              InputProps={{
                style: { fontSize: '1.5vh', minWidth: '250px' }
              }}
              InputLabelProps={{
                style: { fontSize: '1.5vh' }
              }}>
              <MenuItem value='accelerated'>Accelerated</MenuItem>
              {/* <MenuItem value='breakthrough'>Breakthrough</MenuItem>
              <MenuItem value='fasttrack'>FastTrack</MenuItem>
              <MenuItem value='rtor'>RTOR</MenuItem> */}
            </TextField>
          </Box>
        </Grid>
      </Grid>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={approvalStatusData}
          columns={columns as any}
          rowCount={totalRowCount}
          pageSize={pageSize}
          getRowId={row => row.id}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPageNumber(0);
          }}
          getRowHeight={() => 'auto'}
          onPageChange={setPageNumber}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={pageNumber}
          paginationMode='server'
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
      </Box>
      <Dialog
        open={showEditDialog}
        maxWidth='lg'
        onClose={() => closeEdit}
        sx={{ backdropFilter: 'blur(5px)' }}>
        <ApprovalStatusList
          applData={editRow}
          approvalStatusType={approvalStatusType}
          closeEdit={closeEdit}
        />
      </Dialog>
    </ThemeProvider>
  );
};

export default React.memo(AllApprovalStatus);
