import theme from '../../theme';

const styles = {
  Button: {
    background: '#3AB09E',
    mb: 1,
    color: theme.palette.white,
    '&:hover': {
      background: theme.palette.primary.darkBluish,
      color: theme.palette.white
    }
  },
  autocomplete: {
    color: `${theme.palette.primary.darkBluish} !important`,
    marginTop: theme.spacing(0.5),
    background: theme.palette.white,
    borderRadius: `${theme.spacing(1.5)} !important`,
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    },
    '& .Mui-disabled': {
      color: '#ffffff7d !important',
      '-webkit-text-fill-color': '#ffffff7d !important'
    }
  },
  autocompleteChip: {
    border: '1px solid black !important',
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.darkBluish
  }
};

export default styles;
