import makeRequest, { makeRequestWithFormData } from "./client";

const baseURL = `/pmda`;
export const getProductList = (pageSize: number, pageNo: number, status: string, yjCode: string, tradeName: string, activeIngredientId: string) => {
  const queryParams = [`page=${pageNo}`, `page_size=${pageSize}`];
  if (status !== "All Documents") {
    queryParams.push(`qc_status=${status}`);
  }
  if (yjCode) {
    queryParams.push(`yj_code=${yjCode}`);
  }
  if (tradeName) {
    queryParams.push(`sales_name=${tradeName}`);
  }
  if (activeIngredientId) {
    queryParams.push(`active_ingredient_id=${activeIngredientId}`);
  }
  const finalURL = `${baseURL}/pmda_product_curation?${queryParams.join("&")}`;
  return makeRequest(finalURL, "GET");
};

export const updatePMDAProductData = (payload: any, id: string) => {
  const finalURL = `${baseURL}/pmda_product_curation/${id}`;
  return makeRequest(finalURL, "POST", payload);
};

export const getPMDAProductHTMLData = (link: string, packageInsertNumber: any) => {
  const finalURL = `${baseURL}/pmda_product/html?package_insert_number=${packageInsertNumber}`;
  return makeRequest(finalURL, "GET");
};
export const getPriceList = (activeIngredientId: string) => {
  const finalURL = `${baseURL}/price_list/${activeIngredientId}`;
  return makeRequest(finalURL, "GET");
};

export const sendConverterData = (formData: any) => {
  const finalURL = `${baseURL}/pmda_product_curation/convert`;
  return makeRequestWithFormData(finalURL, "POST", formData);
};

export const getPmdaProductResources = (
  pageSize: number,
  pageNo: number,
  qcStatus: string,
  yjCode: string,
  packageNumber: string
) => {
  const queryParams = [`page=${pageNo}`, `page_size=${pageSize}`];

  if (qcStatus !== 'All Documents') {
    queryParams.push(`qc_status=${qcStatus.toLowerCase()}`);
  }

  if (yjCode.trim().length > 0) {
    queryParams.push(`yj_code=${yjCode}`);
  }

  if (packageNumber.trim().length > 0) {
    queryParams.push(`package_number=${packageNumber}`);
  }

  const finalURL = `${baseURL}/product_resources?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const getPmdaProductResource = (
  resourceId: number | undefined,
  packageNumber: string | undefined
) => {
  if (!resourceId || !packageNumber) {
    return null;
  }

  const finalURL = `${baseURL}/product_resource/${resourceId}?package_number=${packageNumber}`;
  return makeRequest(finalURL, 'GET');
};

export const updatePmdaProductResource = (
  resourceId: number | undefined,
  packageNumber: string | undefined,
  payload: object
) => {
  if (!resourceId) {
    return null;
  }
  const finalURL = `${baseURL}/product_resource/${resourceId}?package_number=${packageNumber}`;
  return makeRequest(finalURL, 'PUT', payload);
};

export const getPmdaSectionSeparationProductResource = (
  resourceId: number | undefined,
  packageNumber: string | undefined
) => {
  if (!resourceId || !packageNumber) {
    return null;
  }

  const finalURL = `${baseURL}/product_resource/section_separation/${resourceId}?package_number=${packageNumber}`;
  return makeRequest(finalURL, 'GET');
};

export const submitPmdaSectionSeparationProductResource = (
  resourceId: number | undefined,
  packageNumber: string | undefined,
  payload: any
) => {
  if (!resourceId || !packageNumber || !payload) {
    return null;
  }
  return makeRequest(
    `${baseURL}/product_resource/section_separation/${resourceId}?package_number=${packageNumber}`,
    'PUT',
    payload
  );
};

export const getPMDAHTMLData = ( packageInsertNumber: string,activeIngredientId:string,yjCode:string) => {
  const finalURL = `${baseURL}/pmda_product/s3_link`;
  return makeRequest(finalURL, "POST", {
    "package_insert_number": packageInsertNumber,
    active_ingredient_id:activeIngredientId,yj_code:yjCode
  });
};


export const getTranslationList=()=>{
  const finalURL = `${baseURL}/translation_list`;
  return makeRequest(finalURL, "GET");
}

export const getCurationLogs = () => {
  const finalURL = `${baseURL}/curation`;
  return makeRequest(finalURL, "GET");
}

export const markCurationAsDone = (time:any) => {
  const finalURL = `${baseURL}/curation`;
  return makeRequest(finalURL, "POST",{
    'task_to_run':'after_curation',
    'run_date':time
  });
}