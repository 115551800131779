import React, { useContext, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Stack
} from "@mui/material";
import { Close, Save } from "@mui/icons-material";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import { submitNonPLRData, submitProcessedNonPLRData } from "../../api/AdminPage";
import Styles from "./styles/Review.styles";
import Actions from "../../store/actions";
import Store from "../../store";
import { Loading } from "../EMA/EMAPageSeparation";
import { indexSorting, labelSorting } from "../EMA/EMALabelCreation";
import Switch from "@mui/material/Switch";

const nonEditableLabel = [
  "appl_num",
  "curator_email",
  "curator_name",
  "isPLR",
  "key",
  "label_path",
  "pdf_url",
  "status",
  "submission_num",
  "submission_type"
];
const labelOrder = [
  "description",
  "clinical_pharmacology",
  "clinical_studies",
  "indications_and_usage",
  "contraindications",
  "warnings",
  "precautions",
  "adverse_reactions",
  "drug_abuse_and_dependence",
  "overdosage",
  "dosage_and_administration",
  "how_supplied"
];

export const LabelMapper = ({
                              disabled,
                              label,
                              index,
                              setLabelData,
                              labelData,
                              setError,
                              error,
                              disableDuplicateCheck
                            }) => {
  const classes = Styles();
  const handleChange = e => {
    let val = e.target.value;
    const localError = { ...error };
    if (localError[label]) {
      delete localError[label];
    }

    // eslint-disable-next-line no-restricted-globals
    if (isNaN(val) || Object.values(labelData).includes(`${val}`)) {
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(val)) {
        localError[label] = "Enter valid page number";
      } else if (val && !disableDuplicateCheck) {
        localError[label] = "Page number already linked";
      }
    }
    setError({ ...localError });
    if (val === "") {
      val = null;
    }
    // eslint-disable-next-line no-param-reassign
    labelData[label] = val;
    setLabelData({ ...labelData });
  };

  if (nonEditableLabel.includes(label)) {
    return null;
  }
  return (
    <Box
      display="flex"
      width="90%"
      flex={1}
      justifyContent="space-evenly"
      margin="1em auto"
      alignContent="center">
      <Box width="45%" marginRight="2em" height="min-content" margin="auto">
        <Typography variant="body1" component="div" className={classes.labelName}>
          {/* eslint-disable-next-line react/prop-types */}
          {label.split("_").map(key => `${key} `)}
        </Typography>
      </Box>
      <TextField
        error={error[label] && error[label] !== ""}
        required
        disabled={disabled}
        className={`${classes.textfield} ${classes.textFieldSpacing}`}
        onChange={handleChange}
        value={index}
        fullWidth
        id={label}
        helperText={error[label] && error[label]}
        InputProps={{
          classes: { input: classes.input },
          disableUnderline: true
        }}
      />
    </Box>
  );
};

// eslint-disable-next-line react/prop-types
const NonPLRPage = ({ edit, handleSwitch, res, page, autoSort, setAutoSort }) => {
  const history = useHistory();
  const classes = Styles();

  const { dispatch } = useContext(Store);
  const [loading, setLoading] = useState(true);
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState({});
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);

  const [labelData, setLabelData] = useState(null);
  const [labelInitialData, setLabelInitialData] = useState(null);

  const handleSubmit = async () => {
    setSubmitting(true);
    setOpenSubmitConfirmation(false);
    const user = await Auth.currentUserInfo();
    const payload = {
      key: labelData.key,
      appl_num: labelData.key.split("-")[0],
      // eslint-disable-next-line react/prop-types
      submission_num: res.submission_num ?? "",
      submission_type: labelData.submission_type ?? "",
      description: labelData.description ?? "",
      clinical_pharmacology: labelData.clinical_pharmacology ?? "",
      clinical_studies: labelData.clinical_studies ?? "",
      indications_and_usage: labelData.indications_and_usage ?? "",
      contraindications: labelData.contraindications ?? "",
      warnings: labelData.warnings ?? "",
      precautions: labelData.precautions ?? "",
      adverse_reactions: labelData.adverse_reactions ?? "",
      drug_abuse_and_dependence: labelData.drug_abuse_and_dependence ?? "",
      overdosage: labelData.overdosage ?? "",
      dosage_and_administration: labelData.dosage_and_administration ?? "",
      how_supplied: labelData.how_supplied ?? "",
      label_path: labelData.label_path ?? "",
      status: labelData.status ?? "",
      curator_email: user.attributes.email,
      curator_name: user.attributes["custom:user"]
    };
    if (page === "processed") {
      await submitProcessedNonPLRData(payload);
      history.push("/fda/label/section");
    } else {
      await submitNonPLRData(payload);
      history.push("/reviews");
    }
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: "Document label mapped successfully" }
    });
  };
  const handleSubmitChanges = () => {
    setOpenSubmitConfirmation(true);
  };

  const handleSubmitCheck = () => {
    let valueCount = 0;
    let emptyValueCount = 0;
    Object.entries(labelData ?? {}).forEach(([key, value]) => {
      valueCount += 1;
      if (!nonEditableLabel.includes(key)) {
        if (!value) {
          emptyValueCount += 1;
        }
      }
    });
    return (
      valueCount === emptyValueCount ||
      JSON.stringify(labelInitialData) === JSON.stringify(labelData) ||
      Object.keys(error).length !== 0
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (res === undefined) {
          throw Error("No response");
        }
        // eslint-disable-next-line react/prop-types
        if (res) {
          setLoading(false);
          const localRes = { ...res };
          delete localRes.submission_num;
          const payload = {
            key: localRes.key,
            appl_num: localRes.key.split("-")[0],
            submission_type: localRes.submission_type ?? "",
            description: localRes.description ?? "",
            clinical_pharmacology: localRes.clinical_pharmacology ?? "",
            clinical_studies: localRes.clinical_studies ?? "",
            indications_and_usage: localRes.indications_and_usage ?? "",
            contraindications: localRes.contraindications ?? "",
            warnings: localRes.warnings ?? "",
            precautions: localRes.precautions ?? "",
            adverse_reactions: localRes.adverse_reactions ?? "",
            drug_abuse_and_dependence: localRes.drug_abuse_and_dependence ?? "",
            overdosage: localRes.overdosage ?? "",
            dosage_and_administration: localRes.dosage_and_administration ?? "",
            how_supplied: localRes.how_supplied ?? "",
            label_path: localRes.label_path ?? "",
            status: localRes.status ?? ""
          };
          // eslint-disable-next-line react/prop-types
          setLabelData({ ...payload });
          // eslint-disable-next-line react/prop-types
          setLabelInitialData({ ...payload });
        } else {
          setLoading(false);
          throw Error("No data");
        }
      } catch (err) {
        console.log(err);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: "Enter valid information" }
        });
        // history.push('/admin/reviews');
      }
    };
    fetchData();
  }, [res]);

  return (
    <Grid item xs={12} lg={6} className={classes.grid}>
      {edit && (
        <>
          <Box display="flex" padding={1} flexDirection="row" justifyContent="space-between">
            <div>
              <Button
                className={classes.button}
                disabled={loading}
                onClick={() => history.goBack()}
                variant="contained">
                Go back
              </Button>
            </div>
            <div>
              <Button className={classes.button} onClick={handleSwitch} variant="contained">
                Cancel
              </Button>
            </div>
            <div>
              <Button
                className={classes.button}
                disabled={loading || handleSubmitCheck()}
                onClick={handleSubmitChanges}
                variant="contained">
                Submit
              </Button>
            </div>
          </Box>
          <Stack direction={"row"} width={"100%"} justifyContent={"center"}>
            <FormControlLabel
              sx={{
                "& .MuiFormControlLabel-label": {
                  color: "white"
                },
                "& .MuiSwitch-track": {
                  backgroundColor: "white"
                }
              }}
              control={<Switch />} checked={autoSort} onChange={(event) => {
              setAutoSort(event.target.checked);
            }} label="Auto sorting" />

          </Stack>
        </>

      )}

      {loading || isSubmitting ? (
        <Loading dataLoading={loading} dataSubmitting={isSubmitting} />
      ) : (
        labelData && (
          <Box
            minHeight="50vh"
            maxHeight="75vh"
            className={`${classes.pdfContainer} ${classes.formSection}`}>
            {autoSort ? Object.entries(labelData)
              .sort(labelSorting(labelOrder, true))
              .sort(indexSorting(true))
              .map(([key, value]) => (
                <LabelMapper
                  disableDuplicateCheck
                  key={key}
                  disabled={!edit}
                  label={key}
                  index={value}
                  setLabelData={setLabelData}
                  labelData={labelData}
                  setError={setError}
                  error={error}
                />
              )) : Object.entries(labelData).map(([key, value]) => (
              <LabelMapper
                disableDuplicateCheck
                key={key}
                disabled={!edit}
                label={key}
                index={value}
                setLabelData={setLabelData}
                labelData={labelData}
                setError={setError}
                error={error}
              />
            ))}
            {edit && (
              <Box
                display="flex"
                width="90%"
                flex={1}
                justifyContent="space-evenly"
                margin="1em auto"
                alignContent="center">
                <Select
                  id="blaStats"
                  variant="standard"
                  disableUnderline
                  required
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                  value={labelData.status}
                  onChange={event =>
                    setLabelData(prev => ({
                      ...prev,
                      status: event.target.value
                    }))
                  }
                  className={classes.dropdownContainer}
                  inputProps={{
                    classes: {
                      icon: classes.icon
                    }
                  }}>
                  <MenuItem value="Qced" className={classes.dropdownText}>
                    Qced
                  </MenuItem>
                  <MenuItem value="No Document" className={classes.dropdownText}>
                    No Document
                  </MenuItem>
                  <MenuItem value="Escalate" className={classes.dropdownText}>
                    Escalate
                  </MenuItem>
                  <MenuItem value="" className={classes.dropdownText} />
                </Select>
              </Box>
            )}
          </Box>
        )
      )}

      <Dialog
        open={openSubmitConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <div>Confirm submission</div>
            <IconButton aria-label="close" onClick={() => setOpenSubmitConfirmation(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you have mapped the index accurately?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="secondary"
            className={classes.button}
            disabled={loading}
            onClick={() => {
              setOpenSubmitConfirmation(false);
              handleSwitch();
            }}>
            Cancel
          </Button>

          <Button
            startIcon={<Save />}
            variant="contained"
            onClick={handleSubmit}
            disabled={loading}
            color="secondary"
            className={classes.button}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};
export default NonPLRPage;
