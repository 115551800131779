/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useContext, SyntheticEvent } from 'react';

// muiv5
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Button } from '@mui/material';
import { Auth } from 'aws-amplify';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import DeleteIcon from '@material-ui/icons/Delete';
import { MenuItem } from '@material-ui/core';
import Actions from '../../store/actions';
import Store from '../../store';
// api
import { fetchLetterCurationDetails, submitLetterCurationDetails } from '../../api/Cder';

// styles
import styles from './styles/PageNumberEdit.styles';

const LetterPageEditDialog = ({ closeEdit, docsId, handleNext }: any) => {
  const { dispatch } = useContext<any>(Store);
  const [pdfurl, setpdfurl] = useState('');
  const [tempIssueDate, setTempIssueDate] = useState('');
  const [tempSubmissionDate, setTempSubmissionDate] = useState('');
  const [crDates, setCrDates] = useState('');
  const [alDates, setAlDates] = useState('');
  const [alIssueDate, setAlIssueDate] = useState('');
  const [alSubmissionDate, setAlSubmissionDate] = useState('');
  const [indication, setIndication] = useState<Array<string>>([]);
  const [indicationValue, setIndicationValue] = useState('');
  const [filingDate, setFilingDate] = useState('');
  const [letterData, setLetterData] = useState({
    sponsor_name: '',
    approval_pathway: '',
    signatory_division: '',
    signatory_office: '',
    qc_status: ''
  });
  useEffect(() => {
    const getLetterData = async () => {
      try {
        const result = await fetchLetterCurationDetails(docsId);
        if (!result?.data?.body || result.status !== 200) {
          throw Error('Result Not Available');
        } else if (result.data.body.result) {
          const resultData = result.data.body.result;
          setpdfurl(resultData.document_link);
          setLetterData({
            sponsor_name: resultData.sponsor_name || '',
            approval_pathway: resultData.approval_pathway || '',
            signatory_division: resultData.signatory_division || '',
            signatory_office: resultData.signatory_office || '',
            qc_status: resultData.qc_status || 'qced'
          });
          setFilingDate(resultData.filing_date || '');
          setIndication(resultData.indication || []);
          setCrDates(
            resultData.complete_response ? JSON.stringify(resultData.complete_response) : ''
          );
          setAlDates(
            resultData.approvable_letter ? JSON.stringify(resultData.approvable_letter) : ''
          );
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getLetterData();
  }, []);
  const handleCrSubmission = async () => {
    const obj: { [key: string]: any } = {};
    if (crDates === '') {
      const add = [];
      if (tempIssueDate !== '') {
        const date = new Date(tempIssueDate);
        const formattedDate = format(date, 'yyyy-MM-dd');
        obj['FDA Issue date'] = formattedDate;
      }
      if (tempSubmissionDate !== '') {
        const date = new Date(tempSubmissionDate);
        const formattedDate = format(date, 'yyyy-MM-dd');
        obj['Submission date'] = formattedDate;
      }
      add.push(obj);
      setCrDates(JSON.stringify(add));
    } else if (crDates.length > 0) {
      let res = [];
      res = JSON.parse(crDates);
      if (tempIssueDate !== '') {
        const date = new Date(tempIssueDate);
        const formattedDate = format(date, 'yyyy-MM-dd');
        obj['FDA Issue date'] = formattedDate;
      }
      if (tempSubmissionDate !== '') {
        const date = new Date(tempSubmissionDate);
        const formattedDate = format(date, 'yyyy-MM-dd');
        obj['Submission date'] = formattedDate;
      }
      res.push(obj);
      setCrDates(JSON.stringify(res));
    }
    setTempIssueDate('');
    setTempSubmissionDate('');
  };

  const handleAlSubmission = async (event: any) => {
    event.preventDefault();
    const obj: { [key: string]: any } = {};
    if (alDates === '') {
      const add = [];

      if (alIssueDate !== '') {
        const date = new Date(alIssueDate);
        const formattedDate = format(date, 'yyyy-MM-dd');
        obj['FDA Issue date'] = formattedDate;
      }
      if (alSubmissionDate !== '') {
        const date = new Date(alSubmissionDate);
        const formattedDate = format(date, 'yyyy-MM-dd');
        obj['Submission date'] = formattedDate;
      }
      add.push(obj);
      setAlDates(JSON.stringify(add));
    } else {
      let res = [];
      res = JSON.parse(alDates);
      if (alIssueDate !== '') {
        const date = new Date(alIssueDate);
        const formattedDate = format(date, 'yyyy-MM-dd');
        obj['FDA Issue date'] = formattedDate;
      }
      if (alSubmissionDate !== '') {
        const date = new Date(alSubmissionDate);
        const formattedDate = format(date, 'yyyy-MM-dd');
        obj['Submission date'] = formattedDate;
      }
      res.push(obj);
      setAlDates(JSON.stringify(res));
    }
    setAlIssueDate('');
    setAlSubmissionDate('');
  };
  const handleIndication = async (event: any) => {
    event.preventDefault();
    const add = [...indication];
    if (indicationValue !== '') {
      add.push(indicationValue);
    }
    setIndication(add);
    setIndicationValue('');
  };
  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>, isNextSubmit = false) => {
    try {
      event.preventDefault();
      let formattedDate = null;
      if (filingDate !== '') {
        const date = new Date(filingDate);
        formattedDate = format(date, 'yyyy-MM-dd');
      }
      const user = await Auth.currentUserInfo();
      const payload = {
        ...letterData,
        complete_response: crDates !== '' ? JSON.parse(crDates) : [],
        approvable_letter: alDates !== '' ? JSON.parse(alDates) : [],
        indication,
        qc_status: letterData.qc_status.toLowerCase(),
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user'],
        filing_date: formattedDate
      };
      const res = await submitLetterCurationDetails(docsId, payload);
      if (!res?.data || res?.data?.status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Error while Submitting ${docsId}` }
        });
      } else if (res.data.body) {
        if (isNextSubmit) {
          handleNext();
        } else {
          closeEdit();
        }
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: res.data.body, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  const handleNextSubmit = (event: any) => {
    event.preventDefault();
    handleSubmit(event, true);
  };

  return (
    <Container sx={styles.root}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography component='h2' variant='h5' sx={styles.Heading}>
            Letter Curation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid container spacing={2} display='flex' sx={{ maxHeight: '100vh' }}>
          <Grid item xs={12} lg={6}>
            <form
              onSubmit={handleSubmit}
              style={{ paddingTop: '17px', maxHeight: '83vh', overflow: 'auto' }}>
              <Grid container>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='sponsor_name'
                    label='Sponsor Name'
                    value={letterData.sponsor_name}
                    onChange={e => {
                      setLetterData(prevData => {
                        return {
                          ...prevData,
                          sponsor_name: e.target.value
                        };
                      });
                    }}
                    InputProps={{
                      style: { fontSize: '2vh', marginBottom: '15px' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '2vh', fontWeight: 'bold' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='approval_pathway'
                    label='Approval Pathway'
                    value={letterData.approval_pathway}
                    onChange={e => {
                      setLetterData(prevData => {
                        return {
                          ...prevData,
                          approval_pathway: e.target.value
                        };
                      });
                    }}
                    InputProps={{
                      style: { fontSize: '2vh', marginBottom: '15px' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '2vh', fontWeight: 'bold' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Filing Date'
                      inputFormat='yyyy/MM/dd'
                      openTo='year'
                      views={['year', 'month', 'day']}
                      value={filingDate || null}
                      onChange={newValue => {
                        if (newValue !== null) {
                          setFilingDate(newValue);
                        } else {
                          setFilingDate('');
                        }
                      }}
                      InputProps={{
                        style: { fontSize: '2vh', marginBottom: '15px' }
                      }}
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          fullWidth
                          variant='outlined'
                          InputLabelProps={{
                            style: { fontSize: '2vh', fontWeight: 'bold' }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Box display='flex' flexDirection='column'>
                  <Typography style={{ marginBottom: '5px', fontSize: '15px', fontWeight: 'bold' }}>
                    Complete Response
                  </Typography>
                  <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
                    {crDates}
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label='FDA Issue Date'
                        inputFormat='yyyy/MM/dd'
                        openTo='year'
                        views={['year', 'month', 'day']}
                        value={tempIssueDate || null}
                        onChange={newValue => {
                          if (newValue !== null) {
                            setTempIssueDate(newValue);
                          }
                        }}
                        InputProps={{
                          style: { fontSize: '2vh', marginBottom: '15px' }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant='outlined'
                            InputLabelProps={{
                              style: { fontSize: '2vh', fontWeight: 'bold' }
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label='Submission Date'
                        inputFormat='yyyy/MM/dd'
                        openTo='year'
                        views={['year', 'month', 'day']}
                        value={tempSubmissionDate || null}
                        onChange={newValue => {
                          if (newValue !== null) {
                            setTempSubmissionDate(newValue);
                          }
                        }}
                        InputProps={{
                          style: { fontSize: '2vh', marginBottom: '15px' }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant='outlined'
                            InputLabelProps={{
                              style: { fontSize: '2vh', fontWeight: 'bold' }
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      onClick={handleCrSubmission}
                      variant='contained'
                      color='primary'
                      style={{ color: 'white', marginBottom: '15px', background: '#3AB09E' }}>
                      ADD
                    </Button>
                  </Grid>
                </Box>
                <Box display='flex' flexDirection='column'>
                  <Typography style={{ marginBottom: '5px', fontSize: '15px', fontWeight: 'bold' }}>
                    Approvable Letter
                  </Typography>
                  <Typography style={{ marginBottom: '5px', fontSize: '12px' }}>
                    {alDates}
                  </Typography>
                </Box>
                <Box display='flex' alignItems='center' justifyContent='space-between'>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label='FDA Issue Date'
                        inputFormat='yyyy/MM/dd'
                        openTo='year'
                        views={['year', 'month', 'day']}
                        value={alIssueDate || null}
                        onChange={newValue => {
                          if (newValue !== null) {
                            setAlIssueDate(newValue);
                          }
                        }}
                        InputProps={{
                          style: { fontSize: '2vh', marginBottom: '15px' }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant='outlined'
                            InputLabelProps={{
                              style: { fontSize: '2vh', fontWeight: 'bold' }
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={5}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        label='Submission Date'
                        inputFormat='yyyy/MM/dd'
                        openTo='year'
                        views={['year', 'month', 'day']}
                        value={alSubmissionDate || null}
                        onChange={newValue => {
                          if (newValue !== null) {
                            setAlSubmissionDate(newValue);
                          }
                        }}
                        InputProps={{
                          style: { fontSize: '2vh', marginBottom: '15px' }
                        }}
                        renderInput={(params: any) => (
                          <TextField
                            {...params}
                            fullWidth
                            variant='outlined'
                            InputLabelProps={{
                              style: { fontSize: '2vh', fontWeight: 'bold' }
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item xs={1}>
                    <Button
                      onClick={handleAlSubmission}
                      variant='contained'
                      color='primary'
                      style={{ color: 'white', marginBottom: '15px', background: '#3AB09E' }}>
                      ADD
                    </Button>
                  </Grid>
                </Box>
                <Grid item xs={11}>
                  <Box display='flex' flexDirection='column'>
                    <Typography
                      style={{ marginBottom: '5px', fontSize: '15px', fontWeight: 'bold' }}>
                      Indication (Label changes)
                    </Typography>
                    {indication?.map((arg: String, index: number) => (
                      <Box display='flex' alignItems='center' key={`${arg}`}>
                        <IconButton
                          onClick={() => {
                            const newIndications = [...indication];
                            newIndications.splice(index, 1);
                            setIndication(newIndications);
                          }}>
                          <DeleteIcon />
                        </IconButton>
                        <Typography style={{ fontSize: '12px' }}>{arg}</Typography>
                      </Box>
                    ))}
                    <Box display='flex' alignItems='center' justifyContent='space-between'>
                      <Grid item xs={10.5}>
                        <TextField
                          variant='outlined'
                          fullWidth
                          id='indication'
                          label='Indications'
                          value={indicationValue}
                          maxRows={Infinity}
                          multiline
                          onChange={e => {
                            setIndicationValue(e.target.value);
                          }}
                          InputProps={{
                            style: { fontSize: '2vh', marginBottom: '15px' }
                          }}
                          InputLabelProps={{
                            style: { fontSize: '2vh', fontWeight: 'bold' }
                          }}
                        />
                      </Grid>
                      <Grid item xs={1}>
                        <Button
                          onClick={handleIndication}
                          variant='contained'
                          color='primary'
                          style={{ color: 'white', marginBottom: '15px', background: '#3AB09E' }}>
                          ADD
                        </Button>
                      </Grid>
                    </Box>
                  </Box>
                </Grid>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='signatory_division'
                  label='Signatory Division'
                  value={letterData.signatory_division}
                  onChange={e => {
                    setLetterData(prevData => {
                      return {
                        ...prevData,
                        signatory_division: e.target.value
                      };
                    });
                  }}
                  InputProps={{
                    style: { fontSize: '2vh', marginBottom: '15px' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh', fontWeight: 'bold' }
                  }}
                />
                <TextField
                  variant='outlined'
                  fullWidth
                  id='signatory_office'
                  label='Signatory Office'
                  value={letterData.signatory_office}
                  onChange={e => {
                    setLetterData(prevData => {
                      return {
                        ...prevData,
                        signatory_office: e.target.value
                      };
                    });
                  }}
                  InputProps={{
                    style: { fontSize: '2vh', marginBottom: '15px' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh', fontWeight: 'bold' }
                  }}
                />
                <TextField
                  variant='outlined'
                  id='status'
                  label='QC Status'
                  fullWidth
                  select
                  required
                  value={letterData.qc_status}
                  onChange={(e: any): void => {
                    setLetterData(prevData => {
                      return { ...prevData, qc_status: e.target.value };
                    });
                  }}
                  InputProps={{
                    style: { fontSize: '1.5vh', minWidth: '250px' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '1.5vh' }
                  }}>
                  <MenuItem value='qced'>Qced</MenuItem>
                  <MenuItem value='to_qc'>To QC</MenuItem>
                  <MenuItem value='escalate'>Escalate</MenuItem>
                  <MenuItem value='missing details'>Missing Details</MenuItem>
                </TextField>
                <Grid item xs={12} display='flex' direction='row' justifyContent='space-around'>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    sx={styles.submit}
                    style={{ color: 'white', width: '45%' }}>
                    SUBMIT
                  </Button>
                  <Button
                    type='button'
                    variant='contained'
                    color='primary'
                    sx={styles.submit}
                    onClick={e => handleNextSubmit(e)}
                    style={{ color: 'white', width: '45%' }}>
                    NEXT
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} lg={6}>
            {pdfurl ? null : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{ height: '100%', width: '100%' }}>
                <Typography style={{ fontSize: '20px', fontWeight: 'bold', color: '#33B187' }}>
                  Can&apos;t Open This PDF{' '}
                </Typography>
              </Box>
            )}
            {pdfurl ? (
              <Box
                display='flex'
                justifyContent='center'
                sx={{ maxHeight: '86vh', overflow: 'auto' }}>
                <iframe
                  title='pdf'
                  src={pdfurl}
                  style={{
                    height: '77vh',
                    width: '50vw',
                    marginTop: '20px',
                    position: 'relative'
                  }}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default LetterPageEditDialog;
