/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// muiv5
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@mui/icons-material/Search';

// Custom Components
import InputAdornment from '@mui/material/InputAdornment';
import { Button } from '@mui/material';
import VivproDatagrid from '../Datagrid';

// styles
import styles from './styles/GuidanceTableView.styles';

// api
import { getGuidanceDoc } from '../../api/update_guidance';

import useSearchedTableData from './hooks/useSearchedTableData';

export interface UpdateGuidanceProps {
  changed: string;
  doc_hash: string;
  field_associated_media_2: string;
  field_center: string;
  field_comment_close_date: string | null | undefined;
  field_communication_type: string;
  field_docket_number: string;
  field_docket_number_link: string;
  field_final_guidance_1: string;
  field_issue_datetime: string | null | undefined;
  field_issuing_office_taxonomy: string;
  field_regulated_product_field: string;
  field_topics: string;
  guidance_pk: string;
  open_comment: string;
  page_count: number;
  s3_file_path: string;
  term_node_tid: string;
  title: string;
  title_link: string;
  topics_product: string;
  updated_at: string | null | undefined;
  updated_status: string;
  s3_pdf_link: string;
}

const SEARCH_IGNORE_FIELDS = ['doc_hash', 'original_pdf_url', 'guidance_pk'];

const GuidanceTableView = ({ docType }: { docType: string }) => {
  const history = useHistory();
  const [guidanceData, setGuidanceData] = useState<UpdateGuidanceProps[]>([]);
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);

  const { searchedData } = useSearchedTableData({
    tableData: guidanceData,
    search: searchText,
    ignoreFields: SEARCH_IGNORE_FIELDS
  });

  useEffect(() => {
    async function getData() {
      setLoading(true);
      try {
        setLoading(true);
        const response = await getGuidanceDoc();
        if (response.data.Success.length >= 1) {
          setGuidanceData(response.data.Success);
        } else {
          setGuidanceData([]);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('No record Found');
      }
      setLoading(false);
    }
    getData();
  }, []);

  const formatDate = (date: Date) => {
    if (date) {
      // eslint-disable-next-line no-param-reassign
      date = new Date(date);
      const day = date.getDate() < 10 ? `0${date.getDate()}` : String(date.getDate());
      const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : String(date.getMonth() + 1);
      const year = String(date.getFullYear());
      return `${year}-${month}-${day}`;
    }
    return '';
  };

  // Column Mappings
  const COLUMNS_MAPPINGS = [
    {
      field: 'Edit',
      width: 100,
      flex: 0.1,
      headerAlign: 'center',
      // eslint-disable-next-line no-unused-vars
      renderCell: ({ row }: any) => {
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history.push(`/guidancedocument/${row.guidance_pk}`);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'title',
      headerName: 'Summary',
      headerAlign: 'center',
      width: 200,
      flex: 0.3,
      align: 'center'
    },
    {
      field: 'field_issue_datetime',
      headerName: 'Issue Date',
      headerAlign: 'center',
      width: 100,
      flex: 0.1,
      align: 'center',
      renderCell: (row: any) => formatDate(row.value)
    },
    {
      field: 'field_final_guidance_1',
      headerName: 'Guidance Status',
      headerAlign: 'center',
      width: 100,
      flex: 0.1,
      align: 'center'
    },
    {
      field: 'open_comment',
      headerName: 'Open Comment',
      headerAlign: 'center',
      width: 100,
      flex: 0.1,
      align: 'center'
    },
    {
      field: 'field_comment_close_date',
      headerName: 'Comment Closing Date',
      headerAlign: 'center',
      width: 100,
      flex: 0.1,
      align: 'center',
      renderCell: (row: any) => formatDate(row.value)
    },
    {
      field: 'field_docket_number',
      headerName: 'Field Docket Number',
      headerAlign: 'center',
      width: 100,
      flex: 0.1,
      align: 'center'
    },
    {
      field: 'updated_by',
      headerName: 'Updated By',
      headerAlign: 'center',
      width: 100,
      flex: 0.1,
      align: 'center'
    },
    {
      field: 'updated_at',
      headerName: 'Updated Date',
      headerAlign: 'center',
      width: 100,
      flex: 0.1,
      align: 'center',
      renderCell: (row: any) => formatDate(row.value)
    }
  ];

  return (
    <Box height='10vh'>
      <Box style={{ height: '50vh', width: '95%', paddingTop: '10vh', paddingLeft: '10vh' }}>
        <Box>
          <Typography sx={styles.Typography}>Guidance Update Data</Typography>
        </Box>
        <Box sx={styles.root} pr={2}>
          <Stack direction='column' alignItems='flex-start' spacing={2}>
            <Stack direction='row' spacing={2} pt={3} width='100%'>
              <TextField
                variant='outlined'
                type='search'
                id='search_title'
                label='Search Title'
                value={searchText}
                InputProps={{
                  style: { fontSize: '1.5vh', minWidth: '250px' },
                  startAdornment: (
                    <InputAdornment position='start'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
                InputLabelProps={{
                  style: { fontSize: '1.5vh' }
                }}
                onChange={e => {
                  setSearchText(e.target.value);
                }}
              />
            </Stack>
            <Box height='70vh' width='100%'>
              <VivproDatagrid
                rows={searchedData}
                loading={loading}
                key={`${docType}-datagrid`}
                columnsMapping={COLUMNS_MAPPINGS as any}
                rowId='guidance_pk'
              />
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default GuidanceTableView;
