import Box from '@material-ui/core/Box';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import Styles from '../DataEntry/styles/Review.styles';

// eslint-disable-next-line react/prop-types
const Loading = ({ dataLoading, dataSubmitting, fullScreen }) => {
  const classes = Styles();

  return (
    <Box
      sx={{
        width: fullScreen ? '100vw' : '100%',
        height: fullScreen ? '100vh' : '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: fullScreen ? 'fixed' : 'relative',
        bottom: fullScreen ? '0' : '',
        right: fullScreen ? '0' : '',
        zIndex: fullScreen ? '9999' : '',
        background: fullScreen ? 'rgba(5,5,5,0.45)' : 'initial'
      }}>
      <LinearProgress
        sx={{ width: '100%', maxWidth: '400px', marginBottom: '1em' }}
        color='secondary'
      />
      <Typography className={classes.notAvailable}>
        {dataLoading ? 'Loading data ....' : 'Submitting changes ....'}
      </Typography>

      <Typography className={classes.notAvailable}>
        {dataSubmitting && '(this might take couple of minutes to complete)'}
      </Typography>
    </Box>
  );
};
export default Loading;
