import RLD from '../components/RLD/RldView';

const RLD_ROUTES = [
  {
    id: 'adminRld',
    path: '/rld',
    component: RLD,
    exact: true
  }
];
export default RLD_ROUTES;
