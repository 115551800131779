import { useState } from 'react';
import { useHistory } from 'react-router-dom';

// api
import { getLatestUpdates } from '../../../api/source_validation';

interface Application {
  product_name: string;
  source_url: string;
  product_number: string;
  documents: any[];
}

const useSourceValidation = () => {
  const history = useHistory();
  const [latestUpdates, setLatestUpdates] = useState<any[]>([]);
  const [error, setError] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedIdentifier, setSelectedIdentifier] = useState<Application | null>(null);
  const [fromDate, setFromDate] = useState<string>('');
  const [toDate, setToDate] = useState<string>('');

  const getLatestUpdatesList = async (from: string, to: string) => {
    setLoading(true);
    const response = await getLatestUpdates({
      date: {
        from,
        to
      }
    });
    setFromDate(from);
    setToDate(to);
    if (response.status === 200) {
      setLatestUpdates(response.data.body.results.applications);
    }
    if (response.status !== 200) {
      setError('Error fetching data from the source');
    }
    setLoading(false);
    history.push({
      pathname: '/sourceValidation',
      search: `?fromDate=${from}&toDate=${to}` // Update the search part of the URL
    });
  };

  /**
   * function to update start date category
   * @param {string} newValue is the start value that the user clicks inside the calendar
   */
  const handleStartChange = async (newValue: any) => {
    if (newValue) {
      newValue.setDate(newValue.getDate() - 1);
      const defaultDate = newValue.toISOString().split('T')[0];
      setFromDate(defaultDate);
    }
  };

  /**
   * function to update end date category
   * @param {string} newValue is the end value that the user clicks inside the calendar
   */
  const handleEndChange = async (newValue: any) => {
    if (newValue) {
      newValue.setDate(newValue.getDate() - 1);
      const defaultDate = newValue.toISOString().split('T')[0];
      setToDate(defaultDate);
    }
  };

  return {
    getLatestUpdatesList,
    handleStartChange,
    handleEndChange,
    latestUpdates,
    fromDate,
    toDate,
    setSelectedIdentifier,
    selectedIdentifier,
    loading
  };
};

export default useSourceValidation;
