import { useEffect, useState, memo, useCallback } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { Auth } from 'aws-amplify';
import { getAllWhatsNew } from '../../api/WhatsNew';
import Add from './Add';
import Edit from './Edit';
import View from './View';
import UpdateStatus from './UpdateStatus';
import { isEditDisabled, isUpdateStatusDisabled } from './utils';

interface WhatsNewData {
  id: number;
  type: string;
  status: string;
  title: string;
  added_by: string;
  approved_by: string;
  published_by: string;
  published_on: string;
  created_at: string;
  updated_at: string;
}

const WhatsNew = () => {
  const [whatsNewUpdates, setWhatsNewUpdates] = useState<WhatsNewData[]>([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showViewDialog, setShowViewDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showUpdateStatusDialog, setShowUpdateStatusDialog] = useState(false);
  const [id, setId] = useState<number | null>(null);
  const [whatsNewEntry, setWhatsNewEntry] = useState<WhatsNewData | null>(null);
  const [user, setUser] = useState<any>(null);

  const fetchAllWhatsNew = useCallback(async () => {
    const response = await getAllWhatsNew();

    if (response?.status !== 200 || !response?.data?.body) {
      return;
    }

    setWhatsNewUpdates(response?.data?.body || []);
  }, []);

  const fetchUser = useCallback(async () => {
    const userInfo = await Auth.currentUserInfo();
    setUser(userInfo);
  }, []);

  useEffect(() => {
    fetchAllWhatsNew();
    fetchUser();
  }, []);

  const openEditDialog = useCallback((entryId: number) => {
    setId(entryId);
    setShowEditDialog(true);
  }, []);

  const openViewDialog = useCallback((entryId: number) => {
    setId(entryId);
    setShowViewDialog(true);
  }, []);

  const openUpdateStatusDialog = useCallback((entry: WhatsNewData) => {
    setWhatsNewEntry(entry);
    setShowUpdateStatusDialog(true);
  }, []);

  const handleSubmitRefetch = () => {
    fetchAllWhatsNew();
  };

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Id',
      width: 25
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 125
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 110
    },
    {
      field: 'title',
      headerName: 'Title',
      width: 175
    },
    {
      field: 'added_by',
      headerName: 'Added By',
      width: 175
    },
    {
      field: 'approved_by',
      headerName: 'Approved By',
      width: 175
    },
    {
      field: 'published_by',
      headerName: 'Published By',
      width: 175
    },
    {
      field: 'published_on',
      headerName: 'Published On',
      width: 100
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      width: 100
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 100
    },
    {
      field: 'actions',
      headerName: 'Actions',
      width: 300,
      renderCell: (params: GridRenderCellParams<any>) => {
        return (
          <>
            <Button
              variant='contained'
              size='small'
              onClick={() => {
                openViewDialog(params.row.id);
              }}>
              <Typography>View</Typography>
            </Button>
            <Button
              variant='contained'
              size='small'
              sx={{ ml: '2px' }}
              onClick={() => {
                openEditDialog(params.row.id);
              }}
              disabled={isEditDisabled(params.row.status, user?.attributes?.email)}>
              <Typography>Edit</Typography>
            </Button>
            <Button
              variant='contained'
              size='small'
              sx={{ ml: '2px' }}
              disabled={isUpdateStatusDisabled(params.row.status, user?.attributes?.email)}
              onClick={() => {
                openUpdateStatusDialog(params.row);
              }}>
              <Typography>Update Status</Typography>
            </Button>
          </>
        );
      }
    }
  ];

  return (
    <Box mt={10}>
      <Typography textAlign='center' my={3}>
        Whats New
      </Typography>
      <Box textAlign='right' mr='16px'>
        <Button variant='contained' onClick={() => setShowAddDialog(true)}>
          Add
        </Button>
      </Box>
      <Box height='80vh' px={2}>
        <DataGrid
          rows={whatsNewUpdates}
          columns={columns}
          getRowId={row => row.id}
          rowSpacingType='margin'
          getRowHeight={() => 'auto'}
        />
      </Box>

      <Add
        showAddDialog={showAddDialog}
        closeAddDialog={() => setShowAddDialog(false)}
        handleSubmitRefetch={handleSubmitRefetch}
      />

      <Edit
        showEditDialog={showEditDialog}
        closeEditDialog={() => setShowEditDialog(false)}
        id={id}
        handleSubmitRefetch={handleSubmitRefetch}
      />

      <View
        showViewDialog={showViewDialog}
        closeViewDialog={() => setShowViewDialog(false)}
        id={id}
      />

      <UpdateStatus
        showUpdateStatusDialog={showUpdateStatusDialog}
        closeUpdateStatusDialog={() => setShowUpdateStatusDialog(false)}
        entry={whatsNewEntry}
        handleSubmitRefetch={handleSubmitRefetch}
      />
    </Box>
  );
};

export default memo(WhatsNew);
