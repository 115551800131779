import SourceValidation from '../pages/SourceValidation';

const SourceValidationRoutes = [
  {
    id: 'sourceValidation',
    path: '/sourceValidation',
    component: SourceValidation,
    exact: true
  }
];
export default SourceValidationRoutes;
