import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Auth } from 'aws-amplify';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import TextField from '@material-ui/core/TextField';
import { Close, Save } from '@mui/icons-material';
import Styles from '../DataEntry/styles/Review.styles';
import {
  EMA_DOCUMENT_RESPONSE,
  EMA_LABEL_DATA,
  EMA_LABEL_DATA_LOADING_PROPS,
  PAGE_SEPARATION_PATH_PARAM
} from './EMATypes';
import Store from '../../store';
import Actions from '../../store/actions';
import { getLabeledEMADocument, submitLabeledEMADocument } from '../../api/AdminPage';
import { Loading } from './EMAPageSeparation';

const nonEditableLabel = [
  'document_id',
  'decision_number',
  'ema_number',
  'eu_number',
  'product_number',
  's3_path',
  's3_url',
  'status'
];
const labelOrder = [
  'name_of_the_medicinal_product',
  'qualitative_and_quantitative_composition',
  'pharmaceutical_form',
  'therapeutic_indications',
  'posology_and_method_of_administration',
  'contraindications',
  'special_warnings_and_precautions_for_use',
  'interaction_with_other_medicinal_products_and_other_forms_of_in',
  'fertility_pregnancy_and_lactation',
  'effects_on_ability_to_drive_and_use_machines',
  'undesirable_effects',
  'overdose',
  'pharmacodynamic_properties',
  'pharmacokinetic_properties',
  'preclinical_safety_data',
  'pharmaceutical_particulars',
  'marketing_authorisation_holder',
  'marketing_authorisation_number',
  'date_of_first_authorisation_renewal_of_the_authorisation',
  'date_of_revision_of_the_text',
  'annex_ii',
  'annex_iii'
];
/**
 * It sorts an array of arrays of strings, where the second item in each array is a number, and the first item is a string
 * @param {boolean} ascending - boolean - whether to sort ascending or descending
 * @returns A function that takes two arrays and returns a number.
 */
export const indexSorting = (ascending: boolean) => {
  return (a: string[], b: string[]) => {
    // @ts-ignore
    const A: number | null = a[1] ? parseInt(a[1], 10) : null;
    const B: number | null = b[1] ? parseInt(b[1], 10) : null;

    // equal items sort equally
    if (A === B) {
      return 0;
    }
    // nulls sort after anything else
    if (!A) {
      return 1;
    }
    if (!B) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    if (ascending) {
      return A < B ? -1 : 1;
    }
    // if descending, highest sorts first

    return A < B ? 1 : -1;
  };
};
/**
 * It sorts an array of arrays of strings, where the first element of each array is a label, and the rest of the elements
 * are strings
 * @param {boolean} ascending - boolean - whether or not the sort should be ascending or descending
 * @returns A function that takes two arrays and returns a number.
 */
export const labelSorting = (label: any, ascending: boolean) => {
  return (a: string[], b: string[]) => {
    // @ts-ignore
    const A: number | null =
      // @ts-ignore
      a[0] && label.includes(a[0]) ? label.findIndex(val => val === a[0]) : null;
    const B: number | null =
      // @ts-ignore
      b[0] && label.includes(b[0]) ? label.findIndex(val => val === b[0]) : null;

    // equal items sort equally
    if (A === B) {
      return 0;
    }
    // nulls sort after anything else
    if (!A) {
      return 1;
    }
    if (!B) {
      return -1;
    }
    // otherwise, if we're ascending, lowest sorts first
    if (ascending) {
      return A < B ? -1 : 1;
    }
    // if descending, highest sorts first

    return A < B ? 1 : -1;
  };
};
const LabelMapper = ({
  label,
  index,
  setEmaLabelData,
  emaLabelData,
  setError,
  error
}: EMA_LABEL_DATA_LOADING_PROPS) => {
  const classes = Styles();
  /**
   * The function takes in an event, and then sets the value of the input to a variable called val.
   *
   * It then checks if the value of val is a number, and if it is, it checks if the value of val is already in the
   * emaLabelData object.
   *
   * If it is, it sets the error state to the value of the label.
   *
   * If it isn't, it sets the error state to an empty object.
   *
   * It then sets the emaLabelData state to the value of the label.
   *
   * It then logs the value of the emaLabelData state.
   * @param e - ChangeEvent<HTMLInputElement>
   */
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let val: string | null = e.target.value;
    const localError: any = { ...error };
    if (localError[label]) {
      delete localError[label];
    }

    // @ts-ignore
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(val) || Object.values(emaLabelData).includes(`${val}`)) {
      // @ts-ignore
      // eslint-disable-next-line no-restricted-globals
      if (isNaN(val)) {
        localError[label] = 'Enter valid page number';
      } else if (val) {
        localError[label] = 'Page number already linked';
      }
    }
    setError({ ...localError });
    if (val === '') {
      val = null;
    }
    // @ts-ignore
    // eslint-disable-next-line no-param-reassign
    emaLabelData[label] = val;
    setEmaLabelData({ ...emaLabelData });
  };

  if (nonEditableLabel.includes(label)) {
    return null;
  }
  return (
    <Box
      display='flex'
      width='90%'
      flex={1}
      justifyContent='space-evenly'
      margin='1em auto'
      alignContent='center'>
      <Box width='45%' marginRight='2em' height='min-content' margin='auto'>
        <Typography variant='body1' component='div' className={classes.labelName}>
          {label.split('_').map(key => `${key} `)}
        </Typography>
      </Box>
      <TextField
        error={error[label] && error[label] !== ''}
        required
        className={`${classes.textfield} ${classes.textFieldSpacing}`}
        onChange={handleChange}
        value={index}
        fullWidth
        id={label}
        helperText={error[label] && error[label]}
        InputProps={{
          classes: { input: classes.input },
          disableUnderline: true
        }}
      />
    </Box>
  );
};
const EMALabelCreation = () => {
  const { applicationNumber, closeDate, documentNumber } = useParams<PAGE_SEPARATION_PATH_PARAM>();
  const history = useHistory();
  const classes = Styles();

  const { dispatch } = useContext<any>(Store);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);
  const [error, setError] = useState<any>({});
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);

  const [emaLabelData, setEmaLabelData] = useState<EMA_LABEL_DATA | null>(null);
  const [emaLabelInitialData, setEmaLabelInitialData] = useState<EMA_LABEL_DATA | null>(null);

  const handleSubmit: () => Promise<void> = async () => {
    setSubmitting(true);
    setOpenSubmitConfirmation(false);
    const user = await Auth.currentUserInfo();
    await submitLabeledEMADocument(
      closeDate,
      applicationNumber,
      {
        ...emaLabelData,
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      },
      documentNumber
    );
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Document label mapped successfully' }
    });
    history.push(`/ema/label/section`);
  };
  const handleSubmitChanges = () => {
    setOpenSubmitConfirmation(true);
  };
  /**
   * It checks if the form is empty or if the form is the same as the initial form data or if there are errors in the form
   * @returns - If the valueCount is equal to the emptyValueCount, then the form is empty and the submit button should be
   * disabled.
   *   - If the initial data is equal to the current data, then the form has not been changed and the submit button should
   * be disabled.
   *   - If the error object has a length of 0, then there are no errors and the submit button
   */
  const handleSubmitCheck = (): boolean => {
    let valueCount: number = 0;
    let emptyValueCount: number = 0;
    Object.entries(emaLabelData ?? {}).forEach(([key, value]) => {
      valueCount += 1;
      if (!nonEditableLabel.includes(key)) {
        if (!value) {
          emptyValueCount += 1;
        }
      }
    });
    return (
      valueCount === emptyValueCount ||
      JSON.stringify(emaLabelInitialData) === JSON.stringify(emaLabelData) ||
      Object.keys(error).length !== 0
    );
  };
  useEffect(() => {
    const fetchData: () => Promise<void> = async () => {
      try {
        const res: EMA_DOCUMENT_RESPONSE<EMA_LABEL_DATA> = await getLabeledEMADocument(
          closeDate,
          applicationNumber,
          documentNumber
        );
        if (res === undefined) {
          throw Error('No response');
        }
        if (res.data && res.data.Success) {
          setLoading(false);
          setEmaLabelData({ ...res.data.Success });
          setEmaLabelInitialData({ ...res.data.Success });
        } else {
          setLoading(false);
          throw Error('No data');
        }
      } catch (err) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        history.push('/ema/label/section');
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    return () => {
      window.onbeforeunload = () => {};
    };
  }, []);

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <Box display='flex' padding={1} flexDirection='row' justifyContent='space-between'>
          <div>
            <Button
              className={classes.button}
              disabled={loading}
              onClick={() => history.goBack()}
              variant='contained'>
              Go back
            </Button>
          </div>
          <div>
            <Button
              className={classes.button}
              disabled={loading || handleSubmitCheck()}
              onClick={handleSubmitChanges}
              variant='contained'>
              Submit
            </Button>
          </div>
        </Box>
        <Grid container spacing={2}>
          {loading || isSubmitting ? (
            <Loading dataLoading={loading} dataSubmitting={isSubmitting} />
          ) : (
            <>
              <Grid item xs={12} lg={6}>
                {emaLabelData && (
                  <Box
                    minHeight='50vh'
                    maxHeight='75vh'
                    className={`${classes.pdfContainer} ${classes.formSection}`}>
                    {Object.entries(emaLabelData)
                      .sort(labelSorting(labelOrder, true))
                      .sort(indexSorting(true))
                      .map(([key, value]) => (
                        <LabelMapper
                          key={key}
                          label={key}
                          index={value}
                          setEmaLabelData={setEmaLabelData}
                          emaLabelData={emaLabelData}
                          setError={setError}
                          error={error}
                        />
                      ))}
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                {emaLabelData && emaLabelData.s3_url ? (
                  <Box display='flex' className={classes.pdfContainer}>
                    <iframe
                      width='100%'
                      height='100%'
                      title='pdf'
                      src={emaLabelData && emaLabelData.s3_url}
                      className={classes.pdfEma}
                    />
                  </Box>
                ) : (
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    className={classes.pdfContainer}>
                    <Typography className={classes.notAvailable}>No pdf is available</Typography>
                  </Box>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      <Dialog
        open={openSubmitConfirmation}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <div>Confirm submission</div>
            <IconButton aria-label='close' onClick={() => setOpenSubmitConfirmation(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you have mapped the index accurately?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.button}
            disabled={loading}
            onClick={() => setOpenSubmitConfirmation(false)}>
            Cancel
          </Button>

          <Button
            startIcon={<Save />}
            variant='contained'
            onClick={handleSubmit}
            disabled={loading}
            color='secondary'
            className={classes.button}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EMALabelCreation;
