import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import React from 'react';

const WarningPopup = ({
  errors,
  warnings,
  onClose,
  onContinue
}: {
  errors: Array<string>;
  warnings: Array<string>;
  onClose: any;
  onContinue: any;
}) => {
  return (
    <Dialog
      open
      onClose={onClose}
      fullWidth
      maxWidth='sm'
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <DialogContent>
        {errors.length > 0 && <Typography fontWeight='bold'>Validation Errors: </Typography>}
        {errors.map((message: string) => (
          <DialogContentText id='alert-dialog-description' key={message} pl={2}>
            {message}
          </DialogContentText>
        ))}
        {warnings.length > 0 && (
          <Typography fontWeight='bold' pt={1}>
            Warnings:{' '}
          </Typography>
        )}
        {warnings.map((message: string) => (
          <DialogContentText id='alert-dialog-description' key={message} pl={2}>
            {message}
          </DialogContentText>
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onClose}>Edit Data</Button>
        {errors.length === 0 && (
          <Button onClick={onContinue} autoFocus>
            Continue to Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default React.memo(WarningPopup);
