import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Base64 } from 'js-base64';

import { Auth } from 'aws-amplify';

const AuthContext = React.createContext();

/**
 * function to create an authentication provider to make current user details accessible to all components
 * @param {node} children child components
 * @returns authentication provider which contains current user details and update method
 */
export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);

  const value = React.useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      currentUserId
    }),
    [currentUser]
  );

  useEffect(() => {
    const getUser = async () => {
      try {
        const user = await Auth.currentUserInfo();
        if (user) {
          setCurrentUser(user.attributes);
          setCurrentUserId(Base64.encode(user.attributes.email));
          // used for pendo user analytics
          localStorage.setItem('visitor_id', user.attributes.sub);
          localStorage.setItem('organization', user.attributes['custom:organization']);
        }
        setLoadingUser(false);
      } catch (e) {
        setLoadingUser(false);
      }
    };
    getUser();
  }, []);

  if (loadingUser) {
    return <>Loading...</>;
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
