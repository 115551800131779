import BotQueriesStats from '../components/BotQueries/BotQueriesStats';

const BOT_QUERIES = [
  {
    id: 'botQueries',
    path: '/queries/:days/:group',
    component: BotQueriesStats,
    exact: true
  }
];
export default BOT_QUERIES;
