import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@material-ui/core/Typography';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import { Close, Save } from '@mui/icons-material';
import { Auth } from 'aws-amplify';
import Styles from '../DataEntry/styles/Review.styles';
import {
  EMA_DOCUMENT_DATA,
  EMA_DOCUMENT_RESPONSE,
  EMA_PAGE_SEPARATION_LOADING_PROPS,
  PAGE_SEPARATION_PATH_PARAM
} from './EMATypes';
import Store from '../../store';
import Actions from '../../store/actions';
import { getEMALabelInfo, submitPageSeparatedEMADocument } from '../../api/AdminPage';

export const Loading = ({ dataLoading, dataSubmitting }: EMA_PAGE_SEPARATION_LOADING_PROPS) => {
  const classes = Styles();

  return (
    <Box
      sx={{
        width: '100%',
        height: '80vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <LinearProgress
        sx={{ width: '100%', maxWidth: '400px', marginBottom: '1em' }}
        color='secondary'
      />
      <Typography className={classes.notAvailable}>
        {dataLoading ? 'Loading data ....' : 'Submitting changes ....'}
      </Typography>

      <Typography className={classes.notAvailable}>
        {dataSubmitting && '(this might take couple of minutes to complete)'}
      </Typography>
    </Box>
  );
};
const EMAPageSeparation = () => {
  const { applicationNumber, closeDate, documentNumber } = useParams<PAGE_SEPARATION_PATH_PARAM>();
  const history = useHistory();
  const classes = Styles();

  const { dispatch } = useContext<any>(Store);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSubmitting, setSubmitting] = useState<boolean>(false);

  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);

  const [emaDocumentData, setEmaDocumentData] = useState<EMA_DOCUMENT_DATA>({
    close_date: '',
    document_id: '',
    onedrive_doc_id: '',
    onedrive_path: '',
    product_number: '',
    s3_path: '',
    s3_url: ''
  });

  const handleOpenPopup = (url: string) => {
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow: any = window.open(url, '_blank', option);

    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
        setOpenSubmitConfirmation(true);
      }
    }, 1000);
  };

  /**
   * It's a function that takes in a close date, application number, user email, and user name, and then submits a page
   * separated EMA document
   */
  const handleSubmitChanges = async (downloadFile: boolean): Promise<void> => {
    const handleSubmit: () => Promise<void> = async () => {
      setOpenSubmitConfirmation(false);
      const user = await Auth.currentUserInfo();
      await submitPageSeparatedEMADocument(
        closeDate,
        applicationNumber,
        user.attributes.email,
        user.attributes['custom:user'],
        documentNumber,
        downloadFile
      );

      history.push(`/ema/label/creation/${closeDate}/${applicationNumber}/${documentNumber}`);
    };
    setSubmitting(true);
    await handleSubmit();
  };

  useEffect(() => {
    /**
     * It fetches data from the server and sets the state of the component.
     */
    const fetchData: () => Promise<void> = async () => {
      try {
        const res: EMA_DOCUMENT_RESPONSE<EMA_DOCUMENT_DATA> = await getEMALabelInfo(
          closeDate,
          applicationNumber,
          documentNumber
        );
        if (res === undefined) {
          throw Error('No response');
        }
        if (res.data && res.data.Success) {
          setLoading(false);
          setEmaDocumentData({ ...res.data.Success });
          handleOpenPopup(res.data.Success.onedrive_path);
        } else {
          setLoading(false);
          throw Error('No data');
        }
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        history.push('/ema/label/section');
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    return () => {
      window.onbeforeunload = () => {};
    };
  }, []);

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <Box display='flex' padding={1} flexDirection='row' justifyContent='space-between'>
          <div>
            <Button
              className={classes.button}
              disabled={loading}
              onClick={() => history.goBack()}
              variant='contained'>
              Go back
            </Button>
          </div>
        </Box>
        <Grid container spacing={2}>
          {loading || isSubmitting ? (
            <Loading dataLoading={loading} dataSubmitting={isSubmitting} />
          ) : (
            <>
              <Grid item xs={12} lg={6}>
                {emaDocumentData && emaDocumentData.onedrive_path && (
                  <Box
                    display='flex'
                    flexDirection='column'
                    minHeight='50vh'
                    justifyContent='center'
                    alignItems='center'
                    className={classes.pdfContainer}>
                    <Typography variant='h5' component='div' className={classes.notAvailable}>
                      Editable file is open in a new popup window
                    </Typography>
                    <Typography variant='body1' component='div' className={classes.note}>
                      <b>Note:</b>
                      <ul>
                        <li>You can press CTRL + ENTR key to add page breaks.</li>
                        <li>
                          Once you close the document window, you will receive a popup if you are
                          ready to submit the chnages.
                        </li>
                      </ul>
                    </Typography>
                    <Button
                      disabled={loading}
                      className={classes.button}
                      onClick={() => handleOpenPopup(emaDocumentData.onedrive_path)}
                      variant='contained'>
                      Open file again.
                    </Button>
                  </Box>
                )}
              </Grid>

              <Grid item xs={12} lg={6}>
                {emaDocumentData && emaDocumentData.s3_url ? (
                  <Box display='flex' className={classes.pdfContainer}>
                    <iframe
                      width='100%'
                      height='100%'
                      title='pdf'
                      src={emaDocumentData && emaDocumentData.s3_url}
                      className={classes.pdfEma}
                    />
                  </Box>
                ) : (
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    className={classes.pdfContainer}>
                    <Typography className={classes.notAvailable}>No pdf is available</Typography>
                  </Box>
                )}
              </Grid>
            </>
          )}
        </Grid>
      </Container>
      <Dialog
        open={openSubmitConfirmation}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <div>Confirm submission</div>
            <IconButton aria-label='close' onClick={() => setOpenSubmitConfirmation(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you have edited the document accurately?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.button}
            disabled={loading}
            onClick={() => handleOpenPopup(emaDocumentData.onedrive_path)}>
            Open file again
          </Button>
          <Button
            startIcon={<Save />}
            variant='contained'
            onClick={() => handleSubmitChanges(true)}
            disabled={loading}
            color='secondary'
            className={classes.button}>
            Submit
          </Button>

          <Button
            startIcon={<Save />}
            variant='contained'
            onClick={() => handleSubmitChanges(false)}
            disabled={loading}
            color='secondary'
            className={classes.button}>
            Skip
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EMAPageSeparation;
