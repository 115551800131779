import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  input: {
    padding: '5px !important'
  },
  indicationText: {
    padding: '0px !important'
  }
}));
