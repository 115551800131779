import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// material ui
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { getReviewData, submitQcData } from '../../api/AdminPage';

// helpers
import { handleChange } from '../../helpers/changeHandler';

// styles
import Styles from '../DataEntry/styles/Review.styles';

const ReviewPage = props => {
  const classes = Styles();
  const history = useHistory();
  const { state, dispatch } = useContext(Store);
  const [allDetails, setAllDetails] = useState();
  const [approvalPathway, setApprovalPathway] = useState();
  const [sponsorName, setSponsorName] = useState();
  const [signatoryDivision, setSignatoryDivision] = useState();
  const [signatoryOffice, setSignatoryOffice] = useState();
  const [filingDate, setFilingDate] = useState();
  const [indication, setIndication] = useState();
  const [formStatus, setFormStatus] = useState('Qced');
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [crDates, setCrDates] = useState('');
  const [crEdit, setCrEdit] = useState(false);
  const [alEdit, setAlEdit] = useState(false);
  const [tempIssueDate, setTempIssueDate] = useState('');
  const [tempSubmissionDate, setTempSubmissionDate] = useState('');
  const [alDates, setAlDates] = useState();
  const [alIssueDate, setAlIssueDate] = useState('');
  const [alSubmissionDate, setAlSubmissionDate] = useState('');
  const [email, setEmail] = useState();
  const [name, setName] = useState();

  const handleClickClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentUserInfo();
        setEmail(user.attributes.email);
        setName(user.attributes['custom:user']);
        const res = await getReviewData(props.applNo, props.subType, props.subNo, props.docType);
        if (res === undefined) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Enter valid information' }
          });
          history.push('/reviews');
        }
        setAllDetails(res.data.Success);
        setApprovalPathway(res.data.Success.approval_pathway);
        setSponsorName(res.data.Success.sponsor_name);
        setSignatoryDivision(res.data.Success.signatory_division);
        setSignatoryOffice(res.data.Success.signatory_office);
        setFilingDate(res.data.Success.filing_date);
        let cr =
          res.data.Success.complete_response.length > 0
            ? JSON.stringify(
                JSON.parse(
                  res.data.Success.complete_response.replace(/''/g, '"').replace(/'/g, '"')
                )
              )
            : '';
        setCrDates(cr);
        let al =
          res.data.Success.approvable_letter.length > 0
            ? JSON.stringify(JSON.parse(res.data.Success.approvable_letter.replace(/'/g, '"')))
            : '';
        setAlDates(al);
        setIndication(res.data.Success.indication);
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        history.push('/reviews');
      }
    }

    fetchData();
  }, [props.applNo, props.subType, props.subNo, props.docType]);

  const checkForQc = async () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    const payload = {
      appl_no: allDetails.key.split('-')[0],
      approvable_letter: alDates,
      approval_pathway: approvalPathway,
      complete_response: crDates,
      filing_date: filingDate,
      indication: indication,
      key: allDetails.key,
      label_path: allDetails.label_path,
      letter_path: allDetails.letter_path,
      review_path: allDetails.review_path,
      signatory_division: signatoryDivision,
      signatory_office: signatoryOffice,
      sponsor_name: sponsorName,
      status: formStatus,
      submission_no: allDetails.submission_no,
      submission_type: allDetails.submission_type,
      curator_email: email,
      curator_name: name
    };
    await submitQcData(payload);
    history.push('/admin/reviews');
  };

  const handleCrSubmission = async event => {
    event.preventDefault();
    let obj = {};
    if (crDates === '') {
      let add = [];

      if (tempIssueDate !== '') {
        obj['FDA Issue date'] = tempIssueDate;
      }
      if (tempSubmissionDate !== '') {
        obj['Submission date'] = tempSubmissionDate;
      }
      add.push(obj);
      setCrDates(JSON.stringify(add));
      setCrEdit(true);
    } else if (crDates.length > 0) {
      let res = [];
      if (crEdit) {
        res = JSON.parse(crDates);
      } else {
        res = [];
      }
      if (tempIssueDate !== '') {
        obj['FDA Issue date'] = tempIssueDate;
      }
      if (tempSubmissionDate !== '') {
        obj['Submission date'] = tempSubmissionDate;
      }

      res.push(obj);
      setCrDates(JSON.stringify(res));
      setCrEdit(true);
    }
    setTempIssueDate('');
    setTempSubmissionDate('');
  };

  const handleAlSubmission = async event => {
    event.preventDefault();
    let obj = {};
    if (alDates === '') {
      let add = [];

      if (alIssueDate !== '') {
        obj['FDA Issue date'] = alIssueDate;
      }
      if (alSubmissionDate !== '') {
        obj['Submission date'] = alSubmissionDate;
      }
      add.push(obj);
      setAlDates(JSON.stringify(add));
      setAlEdit(true);
    } else {
      let res = [];
      if (alEdit) {
        res = JSON.parse(alDates);
      } else {
        res = [];
      }
      if (alIssueDate !== '') {
        obj['FDA Issue date'] = alIssueDate;
      }
      if (alSubmissionDate !== '') {
        obj['Submission date'] = alSubmissionDate;
      }
      res.push(obj);
      setAlDates(JSON.stringify(res));
      setAlEdit(true);
    }
    setAlIssueDate('');
    setAlSubmissionDate('');
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        {editMode ? null : (
          <Box display='flex' padding={1} flexDirection='row' justifyContent='flex-start'>
            <Button
              className={classes.button}
              onClick={() => history.push('/reviews')}
              variant='contained'>
              Go back
            </Button>
            <Button
              className={classes.button}
              onClick={() => setEditMode(true)}
              variant='contained'>
              Edit
            </Button>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6} className={classes.grid}>
            <Box className={classes.reviewBox} justifyContent='center' alignItems='space-evenly'>
              <Typography align='center' className={classes.heading}>
                {allDetails && allDetails.appl_no} {allDetails && allDetails.submission_type}-
                {allDetails && allDetails.submission_no}
              </Typography>
              <Box padding={1}>
                <Typography align='left' className={classes.label}>
                  Sponsor Name
                </Typography>
                {editMode ? (
                  <TextField
                    className={classes.textfield}
                    fullWidth
                    value={sponsorName}
                    onChange={event => setSponsorName(handleChange(event))}
                    id='standard-basic'
                    placeholder='Enter sponsor name'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}
                {editMode ? null : (
                  <Typography align='left' className={classes.details}>
                    {allDetails && allDetails.sponsor_name}
                  </Typography>
                )}
              </Box>
              <Box padding={1} display='flex' flexDirection='row' justifyContent='space-between'>
                <Box pr={1} display='flex' flexDirection='column' className={classes.jointBox}>
                  <Typography align='left' className={classes.label}>
                    Approval Pathway
                  </Typography>
                  {editMode ? (
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      value={approvalPathway}
                      onChange={event => setApprovalPathway(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter the approval pathaway'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                  ) : null}
                  {editMode ? null : (
                    <Typography align='left' className={classes.details}>
                      {allDetails && allDetails.approval_pathway}
                    </Typography>
                  )}
                </Box>
                <Box display='flex' flexDirection='column' className={classes.jointBox}>
                  <Typography align='left' className={classes.label}>
                    Filing Date
                  </Typography>
                  {editMode ? (
                    <TextField
                      id='date'
                      type='date'
                      onChange={event => setFilingDate(handleChange(event))}
                      defaultValue={filingDate && filingDate}
                      className={classes.textfield}
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                      InputLabelProps={{
                        shrink: true,
                        classes: { label: classes.input }
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                        classes: { icon: classes.input }
                      }}
                    />
                  ) : null}
                  {editMode ? null : (
                    <Typography align='left' className={classes.details}>
                      {allDetails && allDetails.filing_date}
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box padding={1}>
                <Typography align='left' className={classes.label}>
                  Complete Response
                </Typography>
                {editMode ? (
                  <>
                    <Typography className={classes.crdates}>{crDates}</Typography>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-evenly'
                      className={classes.credit}>
                      <Box display='flex' flexDirection='column'>
                        <Typography className={classes.crLabel}>FDA Issue Date</Typography>
                        <TextField
                          id='date'
                          type='date'
                          onChange={event => setTempIssueDate(handleChange(event))}
                          value={tempIssueDate && tempIssueDate}
                          className={classes.textfield}
                          InputProps={{
                            classes: { input: classes.input },
                            disableUnderline: true
                          }}
                          InputLabelProps={{
                            shrink: true,
                            classes: { label: classes.input }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                            classes: { icon: classes.input }
                          }}
                        />
                      </Box>
                      <Box display='flex' flexDirection='column'>
                        <Typography className={classes.crLabel}>Submission Date</Typography>
                        <TextField
                          id='date'
                          type='date'
                          onChange={event => setTempSubmissionDate(handleChange(event))}
                          value={tempSubmissionDate && tempSubmissionDate}
                          className={classes.textfield}
                          InputProps={{
                            classes: { input: classes.input },
                            disableUnderline: true
                          }}
                          InputLabelProps={{
                            shrink: true,
                            classes: { label: classes.input }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                            classes: { icon: classes.input }
                          }}
                        />
                      </Box>
                      <Button
                        variant='contained'
                        className={classes.addBtn}
                        onClick={handleCrSubmission}>
                        Add
                      </Button>
                    </Box>
                  </>
                ) : null}
                {editMode ? null : (
                  <Typography align='left' className={classes.details}>
                    {allDetails &&
                      allDetails.complete_response.replace(/''/g, '"').replace(/'/g, '"')}
                  </Typography>
                )}
              </Box>
              <Box padding={1}>
                <Typography align='left' className={classes.label}>
                  Approvable Letter
                </Typography>
                {editMode ? (
                  <>
                    <Typography className={classes.crdates}>{alDates}</Typography>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='space-evenly'
                      className={classes.credit}>
                      <Box display='flex' flexDirection='column'>
                        <Typography className={classes.crLabel}>FDA Issue Date</Typography>
                        <TextField
                          id='date'
                          type='date'
                          onChange={event => setAlIssueDate(handleChange(event))}
                          value={alIssueDate && alIssueDate}
                          className={classes.textfield}
                          InputProps={{
                            classes: { input: classes.input },
                            disableUnderline: true
                          }}
                          InputLabelProps={{
                            shrink: true,
                            classes: { label: classes.input }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                            classes: { icon: classes.input }
                          }}
                        />
                      </Box>
                      <Box display='flex' flexDirection='column'>
                        <Typography className={classes.crLabel}>Submission Date</Typography>
                        <TextField
                          id='date'
                          type='date'
                          onChange={event => setAlSubmissionDate(handleChange(event))}
                          value={alSubmissionDate && alSubmissionDate}
                          className={classes.textfield}
                          InputProps={{
                            classes: { input: classes.input },
                            disableUnderline: true
                          }}
                          InputLabelProps={{
                            shrink: true,
                            classes: { label: classes.input }
                          }}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                            classes: { icon: classes.input }
                          }}
                        />
                      </Box>
                      <Button
                        variant='contained'
                        className={classes.addBtn}
                        onClick={handleAlSubmission}>
                        Add
                      </Button>
                    </Box>
                  </>
                ) : null}
                {editMode ? null : (
                  <Typography align='left' className={classes.details}>
                    {allDetails &&
                      allDetails.approvable_letter.replace(/''/g, '"').replace(/'/g, '"')}
                  </Typography>
                )}
              </Box>
              <Box padding={1}>
                <Typography align='left' className={classes.label}>
                  Indication (Label changes)
                </Typography>
                {editMode ? (
                  <TextField
                    className={classes.textarea}
                    fullWidth
                    multiline
                    rowsMin={3}
                    rowsMax={4}
                    value={indication}
                    onChange={event => setIndication(handleChange(event))}
                    id='standard-basic'
                    placeholder='Enter indication'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}
                {editMode ? null : (
                  <Typography align='left' className={classes.details}>
                    {allDetails && allDetails.indication}
                  </Typography>
                )}
              </Box>
              <Box padding={1}>
                <Typography align='left' className={classes.label}>
                  Signatory Division
                </Typography>
                {editMode ? (
                  <TextField
                    className={classes.textfield}
                    fullWidth
                    value={signatoryDivision}
                    onChange={event => setSignatoryDivision(handleChange(event))}
                    id='standard-basic'
                    placeholder='Enter signatory Division'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}
                {editMode ? null : (
                  <Typography align='left' className={classes.details}>
                    {allDetails && allDetails.signatory_division}
                  </Typography>
                )}
              </Box>
              <Box padding={1}>
                <Typography align='left' className={classes.label}>
                  Signature Office
                </Typography>
                {editMode ? (
                  <TextField
                    className={classes.textfield}
                    fullWidth
                    value={signatoryOffice}
                    onChange={event => setSignatoryOffice(handleChange(event))}
                    id='standard-basic'
                    placeholder='Enter signatory office'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}
                {editMode ? null : (
                  <Typography align='left' className={classes.details}>
                    {allDetails && allDetails.signatory_office}
                  </Typography>
                )}
              </Box>
              <Box padding={1} display='flex' flexDirection='column'>
                <Typography align='left' className={classes.label}>
                  Status
                </Typography>
                {editMode ? (
                  <Select
                    id='blaStats'
                    variant='standard'
                    disableUnderline
                    required
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null
                    }}
                    open={open}
                    onClose={handleClose}
                    onOpen={handleOpen}
                    value={formStatus}
                    onChange={event => setFormStatus(handleChange(event))}
                    className={classes.dropdownContainer}
                    inputProps={{
                      classes: {
                        icon: classes.icon
                      }
                    }}>
                    <MenuItem value='Qced' className={classes.dropdownText}>
                      Qced
                    </MenuItem>
                    <MenuItem value='No Document' className={classes.dropdownText}>
                      No Document
                    </MenuItem>
                    <MenuItem value='Escalate' className={classes.dropdownText}>
                      Escalate
                    </MenuItem>
                    <MenuItem value='' className={classes.dropdownText}></MenuItem>
                  </Select>
                ) : null}
                {editMode ? null : (
                  <Typography align='left' className={classes.details}>
                    {allDetails && allDetails.indications_status}
                  </Typography>
                )}
              </Box>
              <Box padding={1} display='flex' justifyContent='center'>
                {editMode ? (
                  <Box display='flex' flexDirection='row'>
                    <Button
                      className={classes.button}
                      onClick={() => setEditMode(false)}
                      variant='contained'>
                      Cancel
                    </Button>
                    <Button
                      className={classes.button}
                      disabled={
                        state.userStub &&
                        state.userStub['custom:role'] !== 'Admin' &&
                        allDetails &&
                        allDetails.status === 'Qced'
                          ? true
                          : false
                      }
                      onClick={checkForQc}
                      variant='contained'>
                      Submit
                    </Button>
                    <Dialog
                      open={dialogOpen}
                      onClose={handleClickClose}
                      aria-labelledby='alert-dialog-title'
                      aria-describedby='alert-dialog-description'>
                      <Box className={classes.dialog}>
                        <DialogTitle id='alert-dialog-title'>Confirm Submission</DialogTitle>
                        <DialogContent>
                          <DialogContentText id='alert-dialog-description'>
                            Are you sure you want to submit the changes?
                          </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleClickClose} className={classes.confirm}>
                            No
                          </Button>
                          <Button onClick={handleSubmit} className={classes.confirm} autoFocus>
                            Yes
                          </Button>
                        </DialogActions>
                      </Box>
                    </Dialog>
                  </Box>
                ) : null}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            {allDetails && allDetails.pdf_url ? (
              <Box display='flex' className={classes.pdfContainer}>
                <iframe
                  title='pdf'
                  src={allDetails && allDetails.pdf_url}
                  className={classes.pdf}
                />
              </Box>
            ) : null}
            {allDetails && allDetails.pdf_url ? null : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.pdfContainer}>
                <Typography className={classes.notAvailable}>No pdf is available</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ReviewPage;
