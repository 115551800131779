/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';

// mui
import {
  Stack,
  Typography,
  ListItem,
  List,
  ListItemButton,
  ListItemText,
  Button,
  Slide,
  IconButton,
  Dialog,
  Grid,
  Paper
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import CloseIcon from '@mui/icons-material/Close';

// api
import { getHtmlContent } from '../../../api/source_validation';

// other conmponents
import DataListContent from './DataListContent';
import HtmlContent from './HtmlContent';
import ErrorForm from './ErrorForm';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const LatestUpdatesList = ({
  latestUpdates,
  setSelectedIdentifier,
  selectedIdentifier,
  fromDate,
  toDate
}: {
  latestUpdates: any;
  setSelectedIdentifier: any;
  selectedIdentifier: any;
  fromDate: any;
  toDate: any;
}) => {
  const [open, setOpen] = useState(false);
  const [htmlContent, setHtmlContent] = useState('' as any);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const validateFromSource = async (app: any) => {
    setSelectedIdentifier(app);

    const response = await getHtmlContent({
      url: app.source_url,
      source: 'eu'
    });
    if (response.status === 200) {
      setHtmlContent(response.data.body.html);
    }
    handleClickOpen();
  };

  return (
    <Stack>
      <List>
        {latestUpdates &&
          latestUpdates?.map((app: any) => (
            <ListItem
              key={app.product_number}
              sx={{ border: '1px solid gray', borderRadius: 0, p: 0, mt: 0.5 }}
              disablePadding
              secondaryAction={
                <Button
                  onClick={() => validateFromSource(app)}
                  variant='contained'
                  size='small'
                  sx={{ textTransform: 'capitalize', color: 'white.main' }}>
                  Validate from Source
                </Button>
              }>
              <ListItemButton sx={{ width: '20%' }}>
                <ListItemText primary={`${app.product_name} - ${app.product_number}`} />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <Stack spacing={2} p={2}>
          <Stack direction='row' justifyContent='space-between'>
            <Typography variant='subtitle1' fontWeight='bold'>
              {selectedIdentifier?.product_number} (Last verified on 2024-05-03)
            </Typography>
            <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
          </Stack>
          <ErrorForm />
          <Grid container>
            <Grid item xs={5} p={1}>
              <Stack spacing={2}>
                <Typography variant='subtitle1'>
                  Data type: <b>Documents</b>
                </Typography>
                <DataListContent data={selectedIdentifier} />
              </Stack>
            </Grid>
            <Grid item xs={7} p={1}>
              <HtmlContent
                content={htmlContent}
                fromDate={fromDate}
                toDate={toDate}
                selectedIdentifier={selectedIdentifier}
              />
            </Grid>
          </Grid>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default LatestUpdatesList;
