import React, { ChangeEvent, SyntheticEvent, useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// material ui
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
// context
import Store from '../../store';
import Actions from '../../store/actions';

// styles
import Styles from '../DataEntry/styles/DataEntry.styles';
import CurationButton from './CurationButton';

const EMALabelFetch = () => {
  const classes = Styles();
  const history = useHistory();
  const location = useLocation();
  const { dispatch } = useContext<any>(Store);
  const [closeDate, setCloseDate] = useState<string>('');
  const [applicationNumber, setApplicationNumber] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [documentNumber, setDocumentNumber] = useState<string>('');
  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const appNumber = e.target.value;
      let [
        year,
        month,
        date,
        applicationNumber1,
        applicationNumber2,
        applicationNumber3,
        applicationNumber4,
        documentNumberStr
      ] = appNumber.split('-', 8);
      year = year.trim();
      month = month.trim();
      date = date.trim();
      applicationNumber1 = applicationNumber1.trim();
      applicationNumber2 = applicationNumber2.trim();
      applicationNumber3 = applicationNumber3.trim();
      applicationNumber4 = applicationNumber4.trim();
      documentNumberStr = documentNumberStr.trim();
      if (
        year &&
        month &&
        date &&
        applicationNumber1 &&
        applicationNumber2 &&
        applicationNumber3 &&
        applicationNumber4 &&
        documentNumberStr
      ) {
        setError('');
        setCloseDate(`${year}-${month}-${date}`);
        setApplicationNumber(
          `${applicationNumber1}-${applicationNumber2}-${applicationNumber3}-${applicationNumber4}`
        );
        setDocumentNumber(documentNumberStr);
      } else {
        setError('Invalid label');
      }
    } catch (err) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Enter valid information' }
      });
      if (location.pathname.match('labelEma')) {
        history.push('/ema/labelEma');
      } else {
        history.push('/ema/label/section');
      }
    }
  };

  const handleSubmit = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (location.pathname.match('labelEma')) {
      history.push(`/ema/labelEma/${closeDate}/${applicationNumber}/${documentNumber}`);
    } else {
      history.push(`/ema/label/section/${closeDate}/${applicationNumber}/${documentNumber}`);
    }
  };

  return (
    <Box className={classes.root} display='flex' alignItems='center' mt='3.75rem'>
      <Container maxWidth='lg'>
        <Paper elevation={1} className={classes.banner}>
          <form onSubmit={handleSubmit}>
            <Box margin={1} flexDirection='column'>
              <Typography align='left' className={classes.label}>
                Enter Key for EMA Label
              </Typography>
              <TextField
                required
                className={classes.textfield}
                onChange={handleChange}
                fullWidth
                id='ema-label'
                placeholder='Eg: 2021-12-21-EMEA-H-C-001213-153994'
                InputProps={{
                  classes: { input: classes.input },
                  disableUnderline: true
                }}
              />
              {error && (
                <Typography variant='subtitle1' component='div' color='error'>
                  {error}
                </Typography>
              )}
            </Box>
            <Box padding={2} display='flex' justifyContent='center'>
              <Button
                type='submit'
                variant='contained'
                className={classes.button}
                disabled={!applicationNumber || !closeDate || error !== ''}>
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
      <CurationButton />
    </Box>
  );
};

export default EMALabelFetch;
