import React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import SettingsIcon from "@mui/icons-material/Settings";
import { TextField } from "@mui/material";
import useChatQuery from "./hooks/useChatQuery";

interface SettingMenuProps {

}

const SettingMenu: React.FC<SettingMenuProps> = () => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { temperature, topP, n, handleChangeTemperature, handleChangeTopP, handleChangeN } = useChatQuery();
  const onClose = () => {
    setOpen(false);
    setAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  return (
    <div>
      <IconButton
        onClick={handleClick}
        color="inherit"
        aria-controls="settings-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        aria-label="settings"
      >
        <SettingsIcon />
      </IconButton>
      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          "aria-labelledby": "settings-button"
        }}
      >
        <MenuItem>
          <TextField
            label={"temperature"}
            variant="outlined"
            type={"number"}
            value={temperature}
            onChange={handleChangeTemperature}
          />
        </MenuItem>
        <MenuItem>
          <TextField
            label={"Top P"}
            variant="outlined"
            type={"number"}
            value={topP}
            onChange={handleChangeTopP}
          />
        </MenuItem>
        <MenuItem>
          <TextField
            label={"N"}
            variant="outlined"
            value={n}
            type={"number"}
            onChange={handleChangeN}
          />
        </MenuItem>
      </Menu>
    </div>
  );
};

export default SettingMenu;
