import React, { useEffect, useState } from 'react';

// mui components
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Typography from '@mui/material/Typography';

import styles from './styles/EMAData.styles';
import Loading from '../../GuidanceCuration/GuidanceLoading';

import { getEMAData } from '../../../api/ema';
import CreateNewRecord from './CreateNewRecord';
import { EMA_DATA_COLUMNS_MAPPINGS } from './constants';
import VivproDatagrid from '../../Datagrid';

const EMAData = () => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    async function getData() {
      try {
        setLoading(true);
        let response = await getEMAData();
        response.data.body.results.forEach((item: any, index: any) => {
          item.id = index;
        });
        setData(response.data.body.results);
      } catch (e) {
        console.error('No record Found');
      } finally {
        setLoading(false);
      }
    }

    getData();
  }, []);

  const emaDataColumnMapping = EMA_DATA_COLUMNS_MAPPINGS;

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box sx={styles.root} height='10vh'>
      {loading && <Loading dataLoading dataSubmitting={undefined} fullScreen={undefined} />}
      {!loading && (
        <Box height='70vh' width='100%' paddingTop='10vh' paddingLeft='3vh'>
          {!open ? (
            <Box>
              <Box id='emaData' display='flex'>
                <Button
                  variant='contained'
                  color='primary'
                  onClick={handleOpen}
                  disabled={loading}
                  sx={styles.Button}
                  >
                  CREATE NEW RECORD
                </Button>
                <Typography component='h2' variant='h6' color='primary' sx={styles.Typography}>
                  EMA Data
                </Typography>
              </Box>
              <Box height='70vh' width='100%'>
                <VivproDatagrid
                  rows={data}
                  loading={loading}
                  key={`ema-datagrid`}
                  columnsMapping={emaDataColumnMapping as any}
                  rowId='id'
                />
              </Box>
            </Box>
          ) : (
            <CreateNewRecord setOpen={setOpen} loading={loading} setLoading={setLoading} />
          )}
        </Box>
      )}
    </Box>
  );
};

export default EMAData;
