import React, { useCallback } from 'react';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

// icons
import CloseIcon from '@mui/icons-material/Close';

const Chip: React.FC<any> = ({ title, value, onClose }) => {
  const handleClose = useCallback(() => onClose(value), []);
  return (
    <Stack
      spacing={2}
      direction='row'
      justifyContent='flex-start'
      sx={{
        marginTop: 1,
        p: 1,
        borderRadius: 18,
        alignItems: 'center',
        backgroundColor: 'primary.backgroundDark'
      }}>
      <CloseIcon cursor='pointer' fontSize='small' onClick={handleClose} />
      <Typography noWrap variant='subtitle2'>
        {title.length > 15 ? `${title.substring(0, 15)}...` : title}
      </Typography>
    </Stack>
  );
};

export default React.memo(Chip);
