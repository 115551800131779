import axios from 'axios';
import { Auth } from 'aws-amplify';

// basic authorization token
const Base = require('js-base64').Base64;

const tok = 'developers:>;JQwa[zL!EX+f{?3{fjnK';
const hash = Base.encode(tok);
const Basic = `Basic ${hash}`;

const API_URL = process.env.REACT_APP_API_URL;

// eslint-disable-next-line no-undef
const getAuthToken = async path => {
  let res = null;
  try {
    // eslint-disable-next-line camelcase
    const api_list = ['/clignosis/health'];
    if (api_list.includes(path)) {
      res = Basic;
    } else {
      res = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
    }
  } catch (err) {
    res = err.response;
  }
  // eslint-disable-next-line no-undef
  return res;
};

/**
 * Function to make requests to rest API
 * @param {string} path api endpoint path
 * @param {string} method type of request
 * @param {object} payload request body
 * @returns api response from backend
 */
const makeRequest = async (path, method, payload = {}, customHeaders = {}) => {
  let res = null;
  try {
    res = await axios({
      method: method?.toLowerCase(),
      url: `${API_URL}${path}`,
      data: payload,
      headers: {
        'Content-type': 'application/json',
        Authorization: await getAuthToken(path),
        ...customHeaders
      }
    });
  } catch (err) {
    res = err.response;
  }

  return res;
};

export const makeArrayBufferRequest = async (path, method, payload = {}, customHeaders = {}) => {
  let res = null;
  try {
    res = await axios({
      method,
      url: `${API_URL}${path}`,
      data: payload,
      responseType: 'arraybuffer',
      headers: {
        'Content-type': 'application/json',
        Authorization: await getAuthToken(path),
        ...customHeaders
      }
    });
  } catch (err) {
    if (err?.response?.status === 429) {
      window.dispatchEvent(new Event('apiError'));
    }
    res = err.response;
  }

  return res;
};

export const makeRequestWithFormData = async (path, method, payload = {}, customHeaders = {}) => {
  let res = null;
  try {
    res = await axios({
      method: method?.toLowerCase(),
      url: `${API_URL}${path}`,
      data: payload,
      headers: {
        'Content-type': 'multipart/form-data',
        Authorization: await getAuthToken(path),
        ...customHeaders
      }
    });
  } catch (err) {
    res = err.response;
  }

  return res;
}

export default makeRequest;
