import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// muiv5
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { getGuidanceDocById, updateGuidanceDocById } from '../../api/update_guidance';

// styles
import styles from './styles/GuidanceTableView.styles';

const updatedStatus = ['To_QC', 'QCed', 'Escalate'];

const TabPanel = (props: any) => {
  const { children, value, index } = props;
  return (
    <Box role='tabpanel' hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

// eslint-disable-next-line react/prop-types
const CustomTextField = ({ name, value, disabled, onChange }: any) => {
  // eslint-disable-next-line react/prop-types
  const label = name.split('_').join(' ');
  return (
    <TextField
      fullWidth
      id={name}
      label={label}
      value={value}
      disabled={disabled}
      variant='outlined'
      onChange={onChange}
      InputProps={{
        style: { fontSize: '2vh', marginBottom: '15px' }
      }}
      InputLabelProps={{
        style: { fontSize: '2vh', fontWeight: 'bold' }
      }}
    />
  );
};

const GuidanceData = ({ match }: any) => {
  const { dispatch } = useContext<any>(Store);
  const history = useHistory();
  const guidanceId = match?.params?.key;
  const [pdfurl, setpdfurl] = useState('');
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [status, setStatus] = useState('To_QC');
  const [guidanceData, setGuidanceData] = useState<any>({
    changed: '',
    doc_hash: '',
    field_associated_media_2: '',
    field_center: '',
    field_comment_close_date: '',
    field_communication_type: '',
    field_docket_number: '',
    field_docket_number_link: '',
    field_final_guidance_1: '',
    field_issue_datetime: '',
    field_issuing_office_taxonomy: '',
    field_regulated_product_field: '',
    field_topics: '',
    guidance_pk: '',
    open_comment: '',
    page_count: '',
    s3_file_path: '',
    term_node_tid: '',
    title: '',
    title_link: '',
    topics_product: '',
    updated_at: '',
    updated_status: '',
    s3_pdf_link: ''
  });
  const textFields: any = {
    changed: true,
    doc_hash: true,
    field_associated_media_2: '',
    field_center: '',
    field_communication_type: '',
    field_docket_number: '',
    field_docket_number_link: '',
    field_final_guidance_1: '',
    field_issuing_office_taxonomy: '',
    field_regulated_product_field: '',
    field_topics: '',
    guidance_pk: true,
    open_comment: '',
    term_node_tid: '',
    title: '',
    title_link: '',
    topics_product: ''
  };
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  const openTitleLink = () => {
    window.open(guidanceData.title_link);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        const guidanceDataList = await getGuidanceDocById(guidanceId);
        if (guidanceDataList.status !== 200) {
          throw Error('Result Not Available');
        } else if (guidanceDataList.data.Success) {
          const resultData = guidanceDataList.data.Success;
          setStatus(resultData.updated_status ? resultData.updated_status : 'To_QC');
          setpdfurl(resultData.s3_pdf_link);
          setGuidanceData(resultData);
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getData();
  }, []);

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        ...guidanceData,
        updated_by: user.attributes.email,
        changed: guidanceData.changed,
        doc_hash: guidanceData.doc_hash,
        field_associated_media_2: guidanceData.field_associated_media_2,
        field_center: guidanceData.field_center,
        field_comment_close_date: guidanceData.field_comment_close_date
          ? new Date(guidanceData.field_comment_close_date).toLocaleDateString()
          : '',
        field_communication_type: guidanceData.field_communication_type,
        field_docket_number: guidanceData.field_docket_number,
        field_docket_number_link: guidanceData.field_docket_number_link,
        field_final_guidance_1: guidanceData.field_final_guidance_1,
        field_issue_datetime: guidanceData.field_issue_datetime
          ? new Date(guidanceData.field_issue_datetime).toLocaleDateString()
          : '',
        field_issuing_office_taxonomy: guidanceData.field_issuing_office_taxonomy,
        field_regulated_product_field: guidanceData.field_regulated_product_field,
        field_topics: guidanceData.field_topics,
        guidance_pk: guidanceData.guidance_pk,
        open_comment: guidanceData.open_comment,
        page_count: guidanceData.page_count,
        s3_file_path: guidanceData.s3_file_path,
        term_node_tid: guidanceData.term_node_tid,
        title: guidanceData.title,
        title_link: guidanceData.title_link,
        topics_product: guidanceData.topics_product,
        updated_at: new Date().toLocaleDateString(),
        updated_status: status === 'To_QC' ? '' : status
      };
      const res = await updateGuidanceDocById(guidanceId, payload);
      if (!res?.data || res?.status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Error while Updating Guidance Data` }
        });
      } else if (res.data.Success) {
        history.push(`/guidancedocument`);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Guidance Updated Successfully`, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  return (
    <Box mt={10} pl={2}>
      <Box pb={2}>
        <Stack direction='row'>
          <KeyboardBackspaceIcon sx={{ fontSize: 32 }} />
          <Typography
            onClick={() => history.goBack()}
            variant='h5'
            display='inline-block'
            sx={{ cursor: 'pointer' }}>
            Go Back
          </Typography>
        </Stack>
      </Box>
      <Stack direction='row' sx={{ overflow: 'hidden', height: '85vh' }}>
        <Box flex={1} overflow='auto'>
          <Typography variant='h6'>Guidance Update Data</Typography>
          <Box>
            <Box alignItems='center' display='flex' justifyContent='start' position='relative'>
              <form
                onSubmit={handleSubmit}
                style={{ paddingTop: '17px', width: '95%', alignItems: 'center' }}>
                <Grid container>
                  <Box display='flex' flexDirection='column' width='100%'>
                    {Object.entries(guidanceData).map(([key, value]) =>
                      key in textFields ? (
                        <CustomTextField
                          name={key}
                          value={value}
                          disabled={textFields[key]}
                          onChange={(e: { target: { value: any } }) => {
                            guidanceData[key] = e.target.value;
                            setGuidanceData({
                              ...guidanceData
                            });
                          }}
                        />
                      ) : (
                        // eslint-disable-next-line react/jsx-no-useless-fragment
                        <></>
                      )
                    )}
                  </Box>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Field Comment Close Date'
                      inputFormat='MM/dd/yyyy'
                      openTo='year'
                      views={['year', 'month', 'day']}
                      value={guidanceData.field_comment_close_date}
                      onChange={newValue => {
                        setGuidanceData((prevData: any) => {
                          return {
                            ...prevData,
                            field_comment_close_date: newValue
                          };
                        });
                      }}
                      InputProps={{
                        style: { fontSize: '2vh', marginBottom: '15px' }
                      }}
                      renderInput={params => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          fullWidth
                          error={false}
                          InputLabelProps={{
                            style: { fontSize: '2vh', fontWeight: 'bold' }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Field Issue Datetime'
                      inputFormat='MM/dd/yyyy'
                      openTo='year'
                      views={['year', 'month', 'day']}
                      value={guidanceData.field_issue_datetime}
                      onChange={newValue => {
                        setGuidanceData((prevData: any) => {
                          return {
                            ...prevData,
                            field_issue_datetime: newValue
                          };
                        });
                      }}
                      InputProps={{
                        style: { fontSize: '2vh', marginBottom: '15px' }
                      }}
                      renderInput={params => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          error={false}
                          fullWidth
                          InputLabelProps={{
                            style: { fontSize: '2vh', fontWeight: 'bold' }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <FormControl fullWidth>
                    <InputLabel id='status' sx={{ fontSize: '2vh', fontWeight: 'bold' }}>
                      Updated Status
                    </InputLabel>
                    <Select
                      labelId='qc_status'
                      name='qc_status'
                      id='qc_status'
                      value={status}
                      label='Status'
                      onChange={e => {
                        setStatus(e.target.value);
                      }}
                      inputProps={{
                        style: { fontSize: '2vh', marginBottom: '15px' }
                      }}>
                      {updatedStatus?.map(option => {
                        return <MenuItem value={option}>{option}</MenuItem>;
                      })}
                    </Select>
                  </FormControl>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    sx={styles.submit}>
                    Update
                  </Button>
                </Grid>
              </form>
            </Box>
          </Box>
        </Box>
        <Box flex={1}>
          <Tabs value={selectedTab} onChange={handleChange} variant='fullWidth'>
            <Tab label='Document' value={0} />
            <Tab label='Title Document' value={1} />
          </Tabs>
          <TabPanel value={selectedTab} index={0} display='flex' justifyContent='center'>
            <Box
              display='flex'
              justifyContent='center'
              sx={{
                height: '100vh',
                width: '100%'
              }}>
              <iframe
                title='pdf'
                src={pdfurl}
                style={{
                  height: '100%',
                  width: '100%'
                }}
              />
            </Box>
          </TabPanel>
          <TabPanel value={selectedTab} index={1} display='flex' justifyContent='center'>
            <Box
              display='flex'
              justifyContent='center'
              sx={{
                height: '100vh',
                width: '100%'
              }}>
              <IconButton onClick={openTitleLink} sx={styles.Button}>
                <ArrowForwardIcon />
              </IconButton>
              <iframe
                title='pdf'
                src={guidanceData.title_link}
                style={{
                  height: '100%',
                  width: '100%'
                }}
              />
            </Box>
          </TabPanel>
        </Box>
      </Stack>
    </Box>
  );
};

export default GuidanceData;
