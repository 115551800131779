import React, { useState, useEffect, useContext, SyntheticEvent, ChangeEvent } from 'react';
import { Auth } from 'aws-amplify';

// material
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControlLabel, Switch } from '@mui/material';
// Store
import { DialogContent } from '@material-ui/core';
import Actions from '../../../store/actions';
import Store from '../../../store';

// api
import { getOrUpdateProductInfoByID, insertProductInfoPageNumbers } from '../../../api/australia';

// constant
import { qcStatus, Fields, qcStatusMapping } from '../constants';

// utils
import getsortLabels from '../../../components/Canada/getSortLabels';

export const TextFieldCard = ({ id, labelName, data, setData }: any) => {
  return (
    <TextField
      variant='outlined'
      fullWidth
      id={id}
      label={labelName}
      placeholder={labelName}
      value={data[id]}
      onChange={e =>
        setData((prevData: object) => {
          const value = e.target.value.trim();
          if (value === '') {
            return { ...prevData, [id]: null };
          }
          const intValue = parseInt(value, 10);
          if (Number.isNaN(intValue)) {
            return prevData;
          }
          return { ...prevData, [id]: intValue };
        })
      }
      InputProps={{
        style: { fontSize: '2vh', marginBottom: '15px' }
      }}
      InputLabelProps={{
        style: { fontSize: '2vh', fontWeight: 'bold' }
      }}
    />
  );
};

const ProductInfoPageCurationEditDialog = ({
  closeEdit,
  productData,
  processed,
  processedDocumentLink = ''
}: any) => {
  type ProductInfoPageNumbers = {
    name_of_the_medicine: string;
    qualitative_and_quantitative_composition: string;
    pharmaceutical_form: string;
    therapeutic_indications: string;
    dosage_and_method_of_adminstration: string;
    contraindications: string;
    special_warning_and_precautions_for_use: string;
    interactions_other_medicines: string;
    fertility_pregnancy_lactation: string;
    effects_ability_drive_use_machines: string;
    adverse_effects: string;
    overdose: string;
    pharmacodynamic_properties: string;
    pharmacokinetic_properties: string;
    preclinical_safety_data: string;
    pharmaceutical_particulars: string;
    medicine_schedule: string;
    sponsor: string;
    date_first_approval: string;
    date_revision: string;
    status: string;
    [key: string]: number | string;
  };
  const { dispatch } = useContext<any>(Store);
  const [pdfUrl, setpdfUrl] = useState('');
  const [autoStart, setAutoStart] = useState(true);
  const [productInfoExists, setProductInfoExists] = useState(false);
  const [productInfoPageNumbers, setProductInfoPageNumbers] = useState<ProductInfoPageNumbers>({
    name_of_the_medicine: '0',
    qualitative_and_quantitative_composition: '0',
    pharmaceutical_form: '0',
    therapeutic_indications: '0',
    dosage_and_method_of_adminstration: '0',
    contraindications: '0',
    special_warning_and_precautions_for_use: '0',
    interactions_other_medicines: '0',
    fertility_pregnancy_lactation: '0',
    effects_ability_drive_use_machines: '0',
    adverse_effects: '0',
    overdose: '0',
    pharmacodynamic_properties: '0',
    pharmacokinetic_properties: '0',
    preclinical_safety_data: '0',
    pharmaceutical_particulars: '0',
    medicine_schedule: '0',
    sponsor: '0',
    date_first_approval: '0',
    date_revision: '0',
    status: ''
  });

  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        name_of_the_medicine: productInfoPageNumbers.name_of_the_medicine ?? '0',
        qualitative_and_quantitative_composition:
          productInfoPageNumbers.qualitative_and_quantitative_composition ?? '0',
        pharmaceutical_form: productInfoPageNumbers.pharmaceutical_form ?? '0',
        therapeutic_indications: productInfoPageNumbers.therapeutic_indications ?? '0',
        dosage_and_method_of_adminstration:
          productInfoPageNumbers.dosage_and_method_of_adminstration ?? '0',
        contraindications: productInfoPageNumbers.contraindications ?? '0',
        special_warning_and_precautions_for_use:
          productInfoPageNumbers.special_warning_and_precautions_for_use ?? '0',
        interactions_other_medicines: productInfoPageNumbers.interactions_other_medicines ?? '0',
        fertility_pregnancy_lactation: productInfoPageNumbers.fertility_pregnancy_lactation ?? '0',
        effects_ability_drive_use_machines:
          productInfoPageNumbers.effects_ability_drive_use_machines ?? '0',
        adverse_effects: productInfoPageNumbers.adverse_effects ?? '0',
        overdose: productInfoPageNumbers.overdose ?? '0',
        pharmacodynamic_properties: productInfoPageNumbers.pharmacodynamic_properties ?? '0',
        pharmacokinetic_properties: productInfoPageNumbers.pharmacokinetic_properties ?? '0',
        preclinical_safety_data: productInfoPageNumbers.preclinical_safety_data ?? '0',
        pharmaceutical_particulars: productInfoPageNumbers.pharmaceutical_particulars ?? '0',
        medicine_schedule: productInfoPageNumbers.medicine_schedule ?? '0',
        sponsor: productInfoPageNumbers.sponsor ?? '0',
        date_first_approval: productInfoPageNumbers.date_first_approval ?? '0',
        date_revision: productInfoPageNumbers.date_revision ?? '0',
        status: qcStatusMapping[productInfoPageNumbers.status] ?? 'qced',
        document_id: productData.document_id,
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      };

      let res = null;
      if (productInfoExists) {
        res = await getOrUpdateProductInfoByID(productData.document_id, 'PUT', payload, processed);
      } else {
        res = await insertProductInfoPageNumbers(payload, processed);
      }
      const resData = res.data;
      if (resData.error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: resData.message }
        });
      } else {
        closeEdit();
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: resData.body, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  useEffect(() => {
    const getProductData = async () => {
      try {
        // if document is not present in processed table then use pdf url from pdf metadata
        setpdfUrl(productData.document_link);
        const result = await getOrUpdateProductInfoByID(
          productData.document_id,
          'GET',
          {},
          processed
        );
        if (!result?.data?.body) {
          if (processed === 'true') {
            setpdfUrl(processedDocumentLink);
          }
          setProductInfoExists(false);
        }
        if (result?.data?.body) {
          setProductInfoExists(true);
          const resultData = result.data.body;
          setProductInfoPageNumbers({
            name_of_the_medicine: resultData.name_of_the_medicine ?? '0',
            qualitative_and_quantitative_composition:
              resultData.qualitative_and_quantitative_composition ?? '0',
            pharmaceutical_form: resultData.pharmaceutical_form ?? '0',
            therapeutic_indications: resultData.therapeutic_indications ?? '0',
            dosage_and_method_of_adminstration:
              resultData.dosage_and_method_of_adminstration ?? '0',
            contraindications: resultData.contraindications ?? '0',
            special_warning_and_precautions_for_use:
              resultData.special_warning_and_precautions_for_use ?? '0',
            interactions_other_medicines: resultData.interactions_other_medicines ?? '0',
            fertility_pregnancy_lactation: resultData.fertility_pregnancy_lactation ?? '0',
            effects_ability_drive_use_machines:
              resultData.effects_ability_drive_use_machines ?? '0',
            adverse_effects: resultData.adverse_effects ?? '0',
            overdose: resultData.overdose ?? '0',
            pharmacodynamic_properties: resultData.pharmacodynamic_properties ?? '0',
            pharmacokinetic_properties: resultData.pharmacokinetic_properties ?? '0',
            preclinical_safety_data: resultData.preclinical_safety_data ?? '0',
            pharmaceutical_particulars: resultData.pharmaceutical_particulars ?? '0',
            medicine_schedule: resultData.medicine_schedule ?? '0',
            sponsor: resultData.sponsor ?? '0',
            date_first_approval: resultData.date_first_approval ?? '0',
            date_revision: resultData.date_revision ?? '0',
            status: resultData.status ?? '0'
          });
          setpdfUrl(resultData.document_link);
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getProductData();
  }, []);

  return (
    <Container sx={{ overflow: 'hidden' }}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Product Info Page Number Curation
          </Typography>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={autoStart}
                  onChange={() => {
                    setAutoStart(!autoStart);
                  }}
                />
              }
              label='Auto sorting'
            />
          </Grid>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} display='flex'>
            <Grid item xs={12} lg={6} mb={3} sx={{ overflow: 'auto', height: '70vh', padding: 2 }}>
              <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                <Grid container spacing={3}>
                  {autoStart ? (
                    <Grid item xs={12}>
                      {getsortLabels(Fields, productInfoPageNumbers).map((arg: any) => {
                        return (
                          <TextFieldCard
                            key={arg.id}
                            id={arg.id}
                            labelName={arg.labelName}
                            data={productInfoPageNumbers}
                            setData={setProductInfoPageNumbers}
                          />
                        );
                      })}
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      {Fields.map((arg: any) => {
                        return (
                          <TextFieldCard
                            key={arg.id}
                            id={arg.id}
                            labelName={arg.labelName}
                            data={productInfoPageNumbers}
                            setData={setProductInfoPageNumbers}
                          />
                        );
                      })}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Autocomplete
                      id='qc_status'
                      fullWidth
                      value={productInfoPageNumbers.status}
                      options={qcStatus}
                      onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                        if (newValue !== null) {
                          setProductInfoPageNumbers(prevData => {
                            return { ...prevData, status: newValue };
                          });
                        }
                      }}
                      sx={{ fontSize: '2vh' }}
                      renderInput={(params: any) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <TextField {...params} label='QC Status' variant='outlined' required />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} display='flex' direction='column'>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      style={{ color: 'white' }}>
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ height: '70vh' }}>
              <iframe src={pdfUrl} title='Product info' style={{ height: '100%', width: '100%' }} />
            </Grid>
          </Grid>
        </DialogContent>
      </div>
    </Container>
  );
};

export default React.memo(ProductInfoPageCurationEditDialog);
