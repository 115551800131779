import { useState, useContext, useEffect } from 'react';

// mui5
import {
  Button,
  Container,
  Select,
  Typography,
  MenuItem,
  InputLabel,
  Grid,
  TextField
} from '@mui/material';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { Auth } from 'aws-amplify';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { submitRLDForm } from '../../api/AdminPage';

// styles
import styles from './styles/Form.styles';

import { RldDataProps } from './RldView';

const AddRld = ({ closeAdd }: RldDataProps) => {
  const [values, setValue] = useState({
    appl_type: 'NDA',
    appl_num: '',
    rld_appl_type: 'NDA',
    rld_appl_num: '',
    submission_type: 'ORIG',
    submission_num: '',
    status: 'ToQced',
    closeAdd
  });
  const [email, setEmail] = useState('');
  const [uName, setName] = useState('');
  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  const { state, dispatch } = useContext(Store);

  const getUserDetails = async () => {
    const user = await Auth.currentUserInfo();
    setEmail(user.attributes.email);
    setName(user.attributes['custom:user']);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const handleInputChange = (e: { target: { name: any; value: any } }) => {
    const { name, value } = e.target;
    setValue({
      ...values,
      [name]: value
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault(event);
    try {
      const payload = {
        appl_type: values.appl_type,
        appl_num: values.appl_num,
        rld_appl_type: values.rld_appl_type,
        rld_appl_num: values.rld_appl_num,
        submission_type: values.submission_type,
        submission_num: values.submission_num,
        status: values.status,
        curator_name: uName,
        curator_email: email
      };
      const res = await submitRLDForm(payload);
      if (closeAdd) closeAdd(res, null);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Operation successful', color: 'success' }
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('=>(AddRLD.tsx:39) error', e);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Enter valid information' }
      });
    }
  };

  return (
    <Container sx={styles.root}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography component='h1' variant='h5' sx={styles.Heading}>
            ADD RLD data
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              if (closeAdd) closeAdd(null, null);
            }}
            sx={{
              position: 'absolute',
              right: 15,
              top: 15,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit} style={styles.form}>
          <Grid container>
            <Grid item xs={6}>
              <InputLabel id='app_t'>Application Type</InputLabel>
              <Select
                labelId='app_tt'
                name='appl_type'
                id='app_t_select'
                value={values.appl_type}
                label='Application Type'
                sx={styles.select}
                onChange={handleInputChange}>
                <MenuItem value='NDA'>NDA</MenuItem>
                <MenuItem value='BLA'>BLA</MenuItem>
                <MenuItem value='EUA'>EUA</MenuItem>
              </Select>
              <InputLabel id='rld_t'>RLD Application Type</InputLabel>
              <Select
                labelId='rld_tt'
                name='rld_appl_type'
                id='rld_t_select'
                value={values.rld_appl_type}
                label='RLD Application Type'
                sx={styles.select}
                onChange={handleInputChange}>
                <MenuItem value='NDA'>NDA</MenuItem>
                <MenuItem value='BLA'>BLA</MenuItem>
                <MenuItem value='EUA'>EUA</MenuItem>
                <MenuItem value='NA'>NA</MenuItem>
              </Select>
              <InputLabel id='sub_t'>Submission Type</InputLabel>
              <Select
                labelId='sub_tt'
                name='submission_type'
                id='sub_t_select'
                value={values.submission_type}
                label='Submission Type'
                sx={styles.select}
                onChange={handleInputChange}>
                <MenuItem value='ORIG'>ORIG</MenuItem>
                <MenuItem value='SUPPL'>SUPPL</MenuItem>
              </Select>
              <InputLabel id='rld_status'>Status</InputLabel>
              <Select
                labelId='rld_status'
                name='status'
                id='status'
                value={values.status}
                label='Status'
                sx={styles.select}
                onChange={handleInputChange}>
                <MenuItem value='ToQced'>ToQced</MenuItem>
                <MenuItem value='Qced'>Qced</MenuItem>
                <MenuItem value='Escalate'>Escalate</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name='appl_num'
                label='Application Number'
                variant='outlined'
                value={values.appl_num}
                sx={styles.text}
                inputProps={{ minLength: 6 }}
                required
                onChange={handleInputChange}
              />
              <TextField
                name='rld_appl_num'
                label='RLD Application Number'
                variant='outlined'
                inputProps={{ minLength: 6 }}
                value={values.rld_appl_num}
                sx={styles.text}
                required
                onChange={handleInputChange}
              />
              <TextField
                name='submission_num'
                label='Submission Number'
                variant='outlined'
                sx={styles.text}
                required
                value={values.submission_num}
                onChange={handleInputChange}
              />
            </Grid>
            <Button type='submit' variant='contained' sx={styles.submit}>
              Add RLD
            </Button>
          </Grid>
        </form>
      </div>
    </Container>
  );
};
export default AddRld;
