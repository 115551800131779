import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Store from '../../store';
import Styles from '../DataEntry/styles/DataEntry.styles';
import Actions from '../../store/actions';
import { getEMAProducts } from '../../api/AdminPage';
import Loading from '../GuidanceCuration/GuidanceLoading';
import EMAProductsInfo from './EMAProductsInfo';

const EMACuration = () => {
  const { closeDate, applicationNumber } = useParams();

  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const [response, setResponse] = useState();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleSwitch = () => {
    setEditMode(prev => !prev);
  };
  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      let res;
      try {
        // eslint-disable-next-line react/prop-types
        res = await getEMAProducts(closeDate, applicationNumber);

        if (res === undefined) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Enter valid information' }
          });
          history.push('/ema/labelEma');
        } else {
          setResponse(res.data.Success.productinfo_data_qc[0]);
          setLoading(false);
        }
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        history.push('/ema/labelEma');
      }
    }

    fetchData();
  }, [dispatch, history, closeDate, applicationNumber]);

  if (loading) {
    return (
      <Box className={classes.root}>
        <Container maxWidth='lg' className={classes.container}>
          <Loading dataLoading />
        </Container>
      </Box>
    );
  }
  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <Box display='flex' padding={1} flexDirection='row' justifyContent='flex-start'>
          <Button
            className={classes.button}
            onClick={() => {
              history.push('/ema/labelEma');
            }}
            variant='contained'>
            Go back
          </Button>
          <Button className={classes.button} onClick={handleSwitch} variant='contained'>
            Edit
          </Button>
        </Box>

        <Grid container spacing={2}>
          {response ? (
            <EMAProductsInfo editMode={editMode} setEditMode={setEditMode} res={response} />
          ) : null}

          <Grid item xs={12} lg={6}>
            {response && response.pdf_url ? (
              <Box display='flex' className={classes.pdfContainer}>
                <iframe title='pdf' src={response && response.pdf_url} className={classes.pdf} />
              </Box>
            ) : null}
            {response && response.pdf_url ? null : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.pdfContainer}>
                <Typography className={classes.notAvailable}>No pdf is available</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default EMACuration;
