import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// material ui
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { submitNonPLRFdaData } from '../../api/AdminPage';

// helpers
import { handleChange } from '../../helpers/changeHandler';

// styles
import Styles from '../DataEntry/styles/PageNumberReview.styles';

// eslint-disable-next-line react/prop-types
const FdaCurationDisplay = ({ editMode, switchEdit, res, docType }) => {
  const classes = Styles();
  const history = useHistory();
  const { state, dispatch } = useContext(Store);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formStatus, setFormStatus] = useState('Qced');
  const [open, setOpen] = useState(false);
  const [allDetails, setAllDetails] = useState();
  const [indicationsAndUsage, setIndicationsAndUsage] = useState('');
  const [pepfarFlag, setPepfarFlag] = useState('');
  const [applType, setApplType] = useState('');

  const [aaDate, setAaDate] = useState('');
  const [cwStatus, setCwStatus] = useState('');
  const [facwStatus, setFacwStatus] = useState('');

  const [approvalDate, setApprovalDate] = useState('');

  const [tradeName, setTradeName] = useState('');
  const [activeIngredient, setActiveIngredient] = useState('');

  const [email, setEmail] = useState();
  const [name, setName] = useState();

  const handleClickClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentUserInfo();
        setEmail(user.attributes.email);
        setName(user.attributes['custom:user']);
        setAllDetails(res);
        if (docType === 'fasttrack') {
          setIndicationsAndUsage(res.ft_indication || '');
          setPepfarFlag(res.pepfar_flag || '');
          setApplType(res.appl_type || '');
        } else if (docType === 'accelerated') {
          setIndicationsAndUsage(res.aa_indication || '');
          setApplType(res.appl_type || '');
          setAaDate(res.aa_date || '');
          setCwStatus(res.conversion_withdrawal_status || '');
          setFacwStatus(res.full_approval_conversion_withdrawal_date || '');
        } else if (docType === 'breakthrough') {
          setIndicationsAndUsage(res.breakthrough_indication || '');
          setApplType(res.appl_type || '');
          setApprovalDate(res.approval_date || '');
          setTradeName(res.trade_name || '');
          setActiveIngredient(res.active_ingredient || '');
        }
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });

        history.push('/approval');
      }
    }

    fetchData();
  }, [dispatch, history]);

  const checkForQc = async () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    if (docType === 'fasttrack') {
      const payload = {
        appl_type: applType,
        appl_num: allDetails.appl_num,
        submission_type: allDetails.submission_type,
        submission_num: allDetails.submission_num,
        ft_indication: indicationsAndUsage,
        indication_status: formStatus,
        pepfar_flag: pepfarFlag,
        key: allDetails.key,
        curator_email: email,
        curator_name: name,
        doctype: docType
      };
      await submitNonPLRFdaData(payload);
    } else if (docType === 'breakthrough') {
      const payload = {
        appl_type: applType,
        appl_num: allDetails.appl_num,
        submission_type: allDetails.submission_type,
        submission_num: allDetails.submission_num,
        approval_date: approvalDate,
        breakthrough_indication: indicationsAndUsage,
        trade_name: tradeName,
        active_ingredient: activeIngredient,
        indication_status: formStatus,
        key: allDetails.key,
        curator_email: email,
        curator_name: name,
        doctype: docType
      };
      await submitNonPLRFdaData(payload);
    } else if (docType === 'accelerated') {
      const payload = {
        appl_type: applType,
        appl_num: allDetails.appl_num,
        submission_type: allDetails.submission_type,
        submission_num: allDetails.submission_num,
        aa_date: aaDate,
        aa_indication: indicationsAndUsage,
        indication_status: formStatus,
        conversion_withdrawal_status: cwStatus,
        full_approval_conversion_withdrawal_date: facwStatus,
        key: allDetails.key,
        curator_email: email,
        curator_name: name,
        doctype: docType
      };
      await submitNonPLRFdaData(payload);
    }
    await dispatch({
      type: Actions.SET_ALERT,
      value: { status: true, message: 'Operation successful' }
    });

    history.push('/approval');
  };
  const handleSwitch = () => {
    switchEdit();
  };

  return (
    <Grid item xs={12} lg={12} className={classes.gridLabel}>
      <Box className={classes.reviewBox} justifyContent='center' alignItems='space-between'>
        <Typography align='center' className={classes.heading}>
          {allDetails && allDetails.appl_num} {allDetails && allDetails.submission_type}-
          {allDetails && allDetails.submission_num}
          {allDetails && allDetails.docType}
        </Typography>

        <Box pr={1} display='flex' flexDirection='column' className={classes.jointBox}>
          <Box
            padding={1}
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            style={{ minHeight: '35vh' }}>
            {docType === 'fasttrack' ? (
              <Typography align='left' className={classes.label}>
                Fasttrack Indications
              </Typography>
            ) : docType === 'breakthrough' ? (
              <Typography align='left' className={classes.label}>
                Breakthrough Indications
              </Typography>
            ) : (
              <Typography align='left' className={classes.label}>
                Accelerated Indications
              </Typography>
            )}
            {editMode ? (
              <TextField
                multiline
                className={classes.textfield}
                value={indicationsAndUsage}
                onChange={event => setIndicationsAndUsage(handleChange(event))}
                id='standard-basic'
                placeholder='Indications'
                InputProps={{
                  classes: { input: classes.input },
                  disableUnderline: true
                }}
              />
            ) : null}

            {/* eslint-disable-next-line no-nested-ternary */}
            {editMode ? null : docType === 'fasttrack' ? (
              <Typography align='left' className={classes.detailsLabel}>
                {allDetails && allDetails.ft_indication}
              </Typography>
            ) : docType === 'breakthrough' ? (
              <Typography align='left' className={classes.detailsLabel}>
                {allDetails && allDetails.breakthrough_indication}
              </Typography>
            ) : docType === 'accelerated' ? (
              <Typography align='left' className={classes.detailsLabel}>
                {allDetails && allDetails.aa_indication}
              </Typography>
            ) : null}
          </Box>

          {docType === 'fasttrack' ? (
            <Box>
              <Box
                padding={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                style={{ minHeight: '10vh' }}>
                <Typography align='left' className={classes.label}>
                  Pepfar Flag
                </Typography>
                {editMode ? (
                  <TextField
                    rows='20'
                    cols='60'
                    className={classes.textfield}
                    fullWidth
                    value={pepfarFlag}
                    onChange={event => setPepfarFlag(handleChange(event))}
                    id='standard-basic'
                    placeholder='Pepfar Flag'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}

                {editMode ? null : docType === 'fasttrack' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.pepfar_flag}
                  </Typography>
                ) : docType === 'breakthrough' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.breakthrough_indication}
                  </Typography>
                ) : docType === 'accelerated' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.aa_indication}
                  </Typography>
                ) : null}
              </Box>

              <Box
                padding={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                style={{ minHeight: '10vh' }}>
                <Typography align='left' className={classes.label}>
                  Application Type
                </Typography>
                {editMode ? (
                  <TextField
                    rows='20'
                    cols='60'
                    className={classes.textfield}
                    fullWidth
                    value={applType}
                    onChange={event => setApplType(handleChange(event))}
                    id='standard-basic'
                    placeholder='Indications'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}

                {editMode ? null : docType === 'fasttrack' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.appl_type}
                  </Typography>
                ) : docType === 'breakthrough' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.breakthrough_indication}
                  </Typography>
                ) : docType === 'accelerated' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.aa_indication}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          ) : docType === 'accelerated' ? (
            <Box>
              <Box
                padding={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                style={{ minHeight: '5vh' }}>
                <Typography align='left' className={classes.label}>
                  Application Type
                </Typography>
                {editMode ? (
                  <TextField
                    rows='20'
                    cols='60'
                    className={classes.textfield}
                    fullWidth
                    value={applType}
                    onChange={event => setApplType(handleChange(event))}
                    id='standard-basic'
                    placeholder='Application Type'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}

                {editMode ? null : docType === 'fasttrack' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.pepfar_flag}
                  </Typography>
                ) : docType === 'breakthrough' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.breakthrough_indication}
                  </Typography>
                ) : docType === 'accelerated' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.appl_type}
                  </Typography>
                ) : null}
              </Box>

              <Box
                padding={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                style={{ minHeight: '5vh' }}>
                <Typography align='left' className={classes.label}>
                  Accelerated Date
                </Typography>
                {editMode ? (
                  <TextField
                    rows='10'
                    cols='30'
                    className={classes.textfield}
                    fullWidth
                    value={aaDate}
                    onChange={event => setAaDate(handleChange(event))}
                    id='standard-basic'
                    placeholder='Accelerated Date'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}

                {editMode ? null : docType === 'fasttrack' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.pepfar_flag}
                  </Typography>
                ) : docType === 'breakthrough' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.breakthrough_indication}
                  </Typography>
                ) : docType === 'accelerated' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.aa_date}
                  </Typography>
                ) : null}
              </Box>

              <Box
                padding={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                style={{ minHeight: '5vh' }}>
                <Typography align='left' className={classes.label}>
                  Conversion Withdrawal Status
                </Typography>
                {editMode ? (
                  <TextField
                    rows='20'
                    cols='60'
                    className={classes.textfield}
                    fullWidth
                    value={cwStatus}
                    onChange={event => setCwStatus(handleChange(event))}
                    id='standard-basic'
                    placeholder='Conversion Withdrawal Status'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}

                {editMode ? null : docType === 'fasttrack' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.pepfar_flag}
                  </Typography>
                ) : docType === 'breakthrough' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.breakthrough_indication}
                  </Typography>
                ) : docType === 'accelerated' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.conversion_withdrawal_status}
                  </Typography>
                ) : null}
              </Box>

              <Box
                padding={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                style={{ minHeight: '5vh' }}>
                <Typography align='left' className={classes.label}>
                  Full Approval Conversion Withdrawal Date
                </Typography>
                {editMode ? (
                  <TextField
                    rows='20'
                    cols='60'
                    className={classes.textfield}
                    fullWidth
                    value={facwStatus}
                    onChange={event => setFacwStatus(handleChange(event))}
                    id='standard-basic'
                    placeholder='Full Approval Conversion Withdrawal Date'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}

                {editMode ? null : docType === 'fasttrack' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.pepfar_flag}
                  </Typography>
                ) : docType === 'breakthrough' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.breakthrough_indication}
                  </Typography>
                ) : docType === 'accelerated' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.full_approval_conversion_withdrawal_date}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          ) : (
            <Box>
              <Box
                padding={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                style={{ minHeight: '5vh' }}>
                <Typography align='left' className={classes.label}>
                  Application Type
                </Typography>
                {editMode ? (
                  <TextField
                    rows='20'
                    cols='60'
                    className={classes.textfield}
                    fullWidth
                    value={applType}
                    onChange={event => setApplType(handleChange(event))}
                    id='standard-basic'
                    placeholder='Enter Application Type'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}

                {editMode ? null : docType === 'fasttrack' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.pepfar_flag}
                  </Typography>
                ) : docType === 'breakthrough' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.appl_type}
                  </Typography>
                ) : docType === 'accelerated' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.aa_indication}
                  </Typography>
                ) : null}
              </Box>

              <Box
                padding={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                style={{ minHeight: '5vh' }}>
                <Typography align='left' className={classes.label}>
                  Approval Date
                </Typography>
                {editMode ? (
                  <TextField
                    rows='20'
                    cols='60'
                    className={classes.textfield}
                    fullWidth
                    value={approvalDate}
                    onChange={event => setApprovalDate(handleChange(event))}
                    id='standard-basic'
                    placeholder='Enter Approval Date'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}

                {editMode ? null : docType === 'fasttrack' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.appl_type}
                  </Typography>
                ) : docType === 'breakthrough' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.approval_date}
                  </Typography>
                ) : docType === 'accelerated' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.aa_indication}
                  </Typography>
                ) : null}
              </Box>

              <Box
                padding={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                style={{ minHeight: '5vh' }}>
                <Typography align='left' className={classes.label}>
                  Trade Name
                </Typography>
                {editMode ? (
                  <TextField
                    rows='20'
                    cols='60'
                    className={classes.textfield}
                    fullWidth
                    value={tradeName}
                    onChange={event => setTradeName(handleChange(event))}
                    id='standard-basic'
                    placeholder='Enter Trade Name'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}

                {editMode ? null : docType === 'fasttrack' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.appl_type}
                  </Typography>
                ) : docType === 'breakthrough' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.trade_name}
                  </Typography>
                ) : docType === 'accelerated' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.aa_indication}
                  </Typography>
                ) : null}
              </Box>

              <Box
                padding={1}
                display='flex'
                flexDirection='row'
                justifyContent='space-between'
                style={{ minHeight: '5vh' }}>
                <Typography align='left' className={classes.label}>
                  Active Ingredient
                </Typography>
                {editMode ? (
                  <TextField
                    rows='20'
                    cols='60'
                    className={classes.textfield}
                    fullWidth
                    value={activeIngredient}
                    onChange={event => setActiveIngredient(handleChange(event))}
                    id='standard-basic'
                    placeholder='Enter Active Ingredient'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                ) : null}

                {editMode ? null : docType === 'fasttrack' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.appl_type}
                  </Typography>
                ) : docType === 'breakthrough' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.active_ingredient}
                  </Typography>
                ) : docType === 'accelerated' ? (
                  <Typography align='left' className={classes.detailsLabel}>
                    {allDetails && allDetails.aa_indication}
                  </Typography>
                ) : null}
              </Box>
            </Box>
          )}

          <Box padding={1} display='flex' flexDirection='column'>
            <Box padding={1} display='flex' flexDirection='row' justifyContent='flex-start'>
              <Typography align='left' className={classes.label}>
                Status:
              </Typography>

              {editMode ? (
                <Select
                  id='blaStats'
                  variant='standard'
                  disableUnderline
                  required
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }}
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                  value={formStatus}
                  onChange={event => setFormStatus(handleChange(event))}
                  className={classes.dropdownContainer}
                  inputProps={{
                    classes: {
                      icon: classes.icon
                    }
                  }}>
                  <MenuItem value='Qced' className={classes.dropdownText}>
                    Qced
                  </MenuItem>
                  <MenuItem value='No Document' className={classes.dropdownText}>
                    No Document
                  </MenuItem>
                  <MenuItem value='Escalate' className={classes.dropdownText}>
                    Escalate
                  </MenuItem>
                  <MenuItem value='' className={classes.dropdownText}></MenuItem>
                </Select>
              ) : null}
              {editMode ? null : (
                <Typography align='left' className={classes.details}>
                  {allDetails && allDetails.status}
                </Typography>
              )}
            </Box>
          </Box>

          <Box padding={1} display='flex' justifyContent='center'>
            {editMode ? (
              <Box display='flex' flexDirection='row'>
                <Button className={classes.button} onClick={handleSwitch} variant='contained'>
                  Cancel
                </Button>

                {state.userStub &&
                state.userStub['custom:role'] !== 'Admin' &&
                allDetails &&
                allDetails.status === 'Qced' ? (
                  <Button className={classes.button} onClick={checkForQc} variant='contained'>
                    Submit
                  </Button>
                ) : (
                  <Button className={classes.button} onClick={checkForQc} variant='contained'>
                    Submit
                  </Button>
                )}
                <Dialog
                  open={dialogOpen}
                  onClose={handleClickClose}
                  aria-labelledby='alert-dialog-title'
                  aria-describedby='alert-dialog-description'>
                  <Box className={classes.dialog}>
                    <DialogTitle id='alert-dialog-title'>Confirm Submission</DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to submit the changes?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClickClose} className={classes.confirm}>
                        No
                      </Button>
                      <Button onClick={handleSubmit} className={classes.confirm} autoFocus>
                        Yes
                      </Button>
                    </DialogActions>
                  </Box>
                </Dialog>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default FdaCurationDisplay;
