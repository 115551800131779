import { useEffect, useState, memo } from 'react';
import { Box, Typography } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import getSurveyAnswers from '../../api/user_survey';
import SURVEYS from './constants';

const UserSurvey = () => {
  const [surveyAnswers, setSurveyAnswers] = useState<any>([]);

  const { title, questions } = SURVEYS.riaCustomerExperienceFeedback;

  useEffect(() => {
    const fetchUserSurveyAnswers = async () => {
      const response = await getSurveyAnswers(encodeURI(title));

      if (response?.status !== 200 || !response?.data?.body) {
        return;
      }

      setSurveyAnswers(response?.data?.body || []);
    };

    fetchUserSurveyAnswers();
  }, []);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: '#',
      width: 25,
      renderCell: params => params.api.getAllRowIds().indexOf(params.id) + 1
    },
    { field: 'organization', headerName: 'Organization', width: 200 },
    {
      field: 'question1',
      headerName: questions.question_1,
      width: 200,
      valueGetter: params => {
        return params.row?.answer?.[questions.question_1] ?? 'N/A';
      }
    },
    {
      field: 'question2',
      headerName: questions.question_2,
      flex: 0.75,
      valueGetter: params => {
        return params.row?.answer?.[questions.question_2] ?? 'N/A';
      }
    },
    {
      field: 'question3',
      headerName: questions.question_3,
      flex: 1,
      valueGetter: params => {
        return params.row?.answer?.[questions.question_3] ?? 'N/A';
      }
    },
    {
      field: 'question4',
      headerName: questions.question_4,
      flex: 0.65,
      valueGetter: params => {
        return params.row?.answer?.[questions.question_4] ?? 'N/A';
      }
    }
  ];

  return (
    <Box mt={10}>
      <Typography textAlign='center' my={3}>
        User Survey Answers
      </Typography>
      <Box height='80vh' px={2}>
        <DataGrid
          rows={surveyAnswers}
          columns={columns}
          getRowId={row => row.uuid}
          rowSpacingType='margin'
          getRowHeight={() => 'auto'}
        />
      </Box>
    </Box>
  );
};

export default memo(UserSurvey);
