import EUACuration from '../components/EUA/EUACuration';
import EditApplicationField from '../components/EUA/EUACuration/EditApplicationField';
import EuaATC from '../components/EUA/EUACuration/EuaATC';

const EUA_ROUTES = [
  {
    id: 'adminEuaHome',
    path: '/eua',
    component: EUACuration,
    exact: true
  },
  {
    id: 'editEuaApplication',
    path: '/eua/:vivproApplNo',
    component: EditApplicationField,
    exact: true
  },
  {
    id: 'eua_atc_curation',
    path: '/eua/atc/curation',
    component: EuaATC,
    exact: true
  }
];

export default EUA_ROUTES;
