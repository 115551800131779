import React, { useState, useEffect, useContext, SyntheticEvent, ChangeEvent } from 'react';
import { Auth } from 'aws-amplify';

// material
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';

// Store
import Actions from '../../store/actions';
import Store from '../../store';

// api
import { updateMonographData, getLabelDetails } from '../../api/canada';

interface formField {
  id: string;
  label: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  maxRows: number;
}

const qcStatus = ['All Documents', 'Escalate', 'Non Qced', 'Qced', 'Automated'];

const MonographEditDialog = ({ closeEdit, productData }: any) => {
  const { dispatch } = useContext<any>(Store);
  const [submissionNumber, setSubmissionNumber] = useState(productData.control_number) || '';
  const [indication, setIndication] = useState(productData.indication || '');
  const [pdfUrl, setpdfUrl] = useState('');
  const [isUpdated, setIsUpdated] = useState(productData.is_updated || false);
  const [status, setStatus] = useState(productData.qc_status || 'All Documents');

  const handleIndication = (value: string) => {
    setIndication(value);
  };

  const handleSubmissionNumber = (value: string) => {
    setSubmissionNumber(value);
  };

  const fields: formField[] = [
    {
      id: 'indication',
      label: 'Indication',
      value: indication,
      onChange: handleIndication,
      maxRows: 15
    },
    {
      id: 'submission_number',
      label: 'Submission Number',
      value: submissionNumber,
      onChange: handleSubmissionNumber,
      maxRows: 1
    }
  ];

  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      if (productData.control_number !== submissionNumber) {
        setIsUpdated(true);
      }
      const user = await Auth.currentUserInfo();
      const payload = {
        id: productData.id,
        indication,
        control_number: submissionNumber,
        curator_email: user.attributes.email,
        qc_status: status,
        is_control_number_updated: isUpdated,
        curator_name: user.attributes['custom:user']
      };
      const res = await updateMonographData(productData.id, payload);
      const resData = res.data;
      if (resData.error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: resData.message }
        });
      } else {
        closeEdit();
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: resData.body, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  useEffect(() => {
    const getLabelData = async () => {
      try {
        const result = await getLabelDetails(productData.id);
        if (!result?.data?.body || result.status !== 200) {
          throw Error('Result Not Available');
        } else if (result.data.body) {
          const resultData = result.data.body;
          setpdfUrl(resultData.document_link);
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getLabelData();
  }, []);
  return (
    <Container>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Product Monograph Curation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Grid container spacing={2} display='flex'>
          <Grid item xs={12} lg={6} mb={3}>
            <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
              <Grid container spacing={3}>
                {fields.map(row => (
                  <Grid item xs={12} key={row.id}>
                    <TextField
                      variant='outlined'
                      fullWidth
                      id={row.id}
                      label={row.label}
                      value={row.value}
                      multiline
                      rows={row.maxRows}
                      maxRows={row.maxRows}
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        row.onChange(e.target.value);
                      }}
                      InputProps={{
                        style: { fontSize: '2vh' }
                      }}
                      InputLabelProps={{
                        style: { fontSize: '2vh' }
                      }}
                    />
                  </Grid>
                ))}
                <Grid item xs={12}>
                  <Autocomplete
                    id='qc_status'
                    fullWidth
                    value={status}
                    options={qcStatus}
                    onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                      if (newValue !== null) {
                        setStatus(newValue);
                      }
                    }}
                    sx={{ fontSize: '2vh' }}
                    renderInput={(params: any) => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <TextField {...params} label='QC Status' variant='outlined' required />
                    )}
                  />
                </Grid>

                <Grid item xs={12} display='flex' direction='column'>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    style={{ color: 'white' }}>
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} lg={6}>
            <iframe
              src={pdfUrl}
              title='Product Monograph'
              style={{ height: '80%', width: '100%' }}
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default React.memo(MonographEditDialog);
