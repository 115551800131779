import React, { useCallback, useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// mui components
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import HelpIcon from '@mui/icons-material/Help';

import Styles from './styles/DbStats.styles';
import { basicDataGridCreator } from '../../helpers/dataGridCreator';

// store
import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';

// custom components
import AddStats from './AddStats';
import {
  getStatsDetails,
  createStatsRecord,
  updateStatsRecord,
  deleteStatsRecord
} from '../../api/Dbstats';

interface StatsRecordProps {
  source: string;
  region: string;
  active_data_source: string;
  metric: string;
  description: string;
  id?: number;
}

interface DataOptionProps {
  regions: string[];
  sources: Object;
  active_data_sources: string[];
  metrics: string[];
}

const DbStats = () => {
  const classes: any = Styles();
  const [open, setOpen] = useState(false);
  const [updateRecord, setUpdateRecord] = useState<StatsRecordProps | undefined>(undefined);
  const [isDataChanged, setIsDataChanged] = useState(false);
  const [data, setData] = useState<StatsRecordProps[] | []>([]);
  const [dataOptions, setDataOptions] = useState<DataOptionProps | {}>({});
  const [openDeletConfirmation, setOpenDeletConfirmation] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);

  const { dispatch } = useContext(GlobalStore) as any;
  const history = useHistory();
  useEffect(() => {
    async function getData() {
      try {
        const response = await getStatsDetails();
        setData(response.data.data);
        setDataOptions(response.data.data_options);
      } catch (e) {
        console.error('No record Found');
      }
    }

    getData();
  }, [isDataChanged]);

  const handleAddStatsRecord = useCallback(
    async (statsRecord: any) => {
      if (updateRecord?.id) {
        try {
          const response = await updateStatsRecord(updateRecord.id, { ...statsRecord });
          if (response.status === 200) {
            await dispatch({
              type: GlobalActions.SET_ALERT,
              value: { status: true, message: 'Stats Updated Successfully!', color: 'success' }
            });
          } else {
            await dispatch({
              type: GlobalActions.SET_ALERT,
              value: { status: true, message: 'Error occured while updating Stats' }
            });
          }
        } catch (e) {
          console.error('Issues while updating Stats');
        }
        setUpdateRecord(undefined);
        setIsDataChanged(prev => !prev);
      } else {
        try {
          const response = await createStatsRecord({ ...statsRecord });
          if (response.status === 200) {
            await dispatch({
              type: GlobalActions.SET_ALERT,
              value: {
                status: true,
                message: 'Stats Record Created Successfully!',
                color: 'success'
              }
            });
          } else {
            await dispatch({
              type: GlobalActions.SET_ALERT,
              value: { status: true, message: 'Error occured while creating Stats' }
            });
          }
        } catch (e) {
          console.error('Issues while adding');
        }
        setIsDataChanged(prev => !prev);
      }
    },
    [data, updateRecord]
  );

  const handleUpdate = (data: any) => {
    setUpdateRecord(data);
    handleOpen();
  };
  const handleDelete = (data: any) => {
    setUpdateRecord(data);
    setOpenDeletConfirmation(true);
  };

  const handleYesClick = async () => {
    try {
      try {
        const response = await deleteStatsRecord(updateRecord?.id as number);
        if (response.status === 200) {
          await dispatch({
            type: GlobalActions.SET_ALERT,
            value: { status: true, message: 'Stats Record deleted Successfully!', color: 'success' }
          });
        } else {
          await dispatch({
            type: GlobalActions.SET_ALERT,
            value: { status: true, message: 'Error occured while deleting Stats Record' }
          });
        }
      } catch (e) {
        console.error('No record Found');
      }
      setOpenDeletConfirmation(false);
      setUpdateRecord(undefined);
      setIsDataChanged(prev => !prev);
    } catch (e) {
      console.error(e);
    }
  };

  const DATASTATS_COLUMNS_MAPPINGS = [
    {
      field: 'id',
      headerName: 'ID',
      width: 80
    },
    {
      field: 'region',
      headerName: 'Region',
      width: 120
    },
    {
      field: 'source',
      headerName: 'Source',
      width: 140
    },
    {
      field: 'active_data_source',
      headerName: 'Data Source',
      width: 200
    },
    {
      field: 'metric',
      headerName: 'Metric',
      width: 120
    },
    {
      field: 'description',
      headerName: 'Description',
      width: 450
    },
    {
      field: 'value',
      headerName: 'value',
      width: 110
    },
    {
      field: 'Edit',
      width: 120,
      renderCell: (data: any) => {
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={event => {
              handleUpdate(JSON.parse(JSON.stringify(data.row)));
            }}
            className={classes.Button}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'Delete',
      width: 120,
      renderCell: (data: any) => {
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={event => {
              handleDelete(data);
            }}
            className={classes.Button}>
            <Typography>Delete</Typography>
          </Button>
        );
      }
    }
  ];

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = useCallback(() => {
    setOpen(false);
    setUpdateRecord(undefined);
  }, [open]);

  const handleDeletConfirmationClose = useCallback(() => {
    setOpenDeletConfirmation(false);
    setUpdateRecord(undefined);
  }, [openDeletConfirmation]);

  const handleInfoClose = useCallback(() => {
    setOpenInfo(false);
  }, [openInfo]);
  const handleInfoOpen = useCallback(() => {
    setOpenInfo(true);
  }, [openInfo]);

  return (
    <Box className={classes.root} height='10vh'>
      <Box height='50vh' width='100%' paddingTop='10vh' paddingLeft='3vh'>
        <Box id='dbStats'>
          <Typography component='h2' variant='h6' color='primary' className={classes.Heading}>
            DBStats
          </Typography>
        </Box>
        <Box>
          <Button
            variant='contained'
            color='primary'
            onClick={handleOpen}
            className={classes.Button}>
            CREATE
          </Button>
          <Dialog
            open={open}
            onClose={handleClose}
            disableEnforceFocus
            PaperProps={{ style: { width: '70vw' } }}>
            <Box id='box' sx={{ padding: '20px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography component='h2' variant='h6'>
                  {`${updateRecord ? 'Updating' : 'Adding'} a record to metadata`}
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Box>
              <AddStats
                handlePopUpClose={handleClose}
                handleAddStatsRecord={handleAddStatsRecord}
                recordToUpdate={
                  updateRecord
                    ? updateRecord
                    : {
                        region: '',
                        source: '',
                        active_data_source: '',
                        metric: '',
                        description: ''
                      }
                }
                dataOptions={dataOptions}></AddStats>
            </Box>
          </Dialog>
          <Dialog
            open={openDeletConfirmation}
            onClose={handleDeletConfirmationClose}
            disableEnforceFocus
            PaperProps={{ style: { width: '70vw' } }}>
            <Box className={classes.dialog}>
              <Typography component='h2' variant='h6'>
                {`Deleting a record from stats table with ID: ${updateRecord?.id}`}
              </Typography>
              <Button onClick={handleDeletConfirmationClose} className={classes.confirm}>
                No
              </Button>
              <Button onClick={handleYesClick} className={classes.confirm} autoFocus>
                Yes
              </Button>
            </Box>
          </Dialog>
          <Dialog
            open={openInfo}
            onClose={handleInfoClose}
            disableEnforceFocus
            PaperProps={{ style: { width: '100%', height: '70vh', padding: '5px' } }}>
            <Box sx={{ padding: '5px 10px 5px 10px' }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography component='h3' variant='h2'>
                  Steps to follow:
                </Typography>
                <IconButton onClick={handleInfoClose}>
                  <CloseIcon fontSize='small' />
                </IconButton>
              </Box>
              <Box>
                <Typography variant='subtitle1' style={{ paddingBottom: '10px' }}>
                  1. Use create button for adding a new record to stats table by selecting
                  appropriate region, source, metric and active data store. Note the id assigned.
                </Typography>
              </Box>
              <Box>
                <Typography variant='subtitle1' style={{ paddingBottom: '10px' }}>
                  2. Modify data-aggregation/db_stats/src/query_json/ &lt;region&gt;
                  _es_query_mapping.json (on bastion) to add a ES query.
                </Typography>
                <Typography variant='subtitle1' style={{ paddingBottom: '10px' }}>
                  3. Update your data-refresh script by importing and creating an obj for GetStats
                  and SetStats class and call get_stats() and set_stats() method with id as a
                  parameter.
                </Typography>
                <Typography style={{ paddingBottom: '10px' }}>
                  Note: In step 1 if you can't find your region/source/metric/data store of interest
                  please contact Tushar or Kunal.
                </Typography>
              </Box>
            </Box>
          </Dialog>
        </Box>
        <Box justifyContent='center' height='50vh' className={classes.root}>
          {basicDataGridCreator(data, DATASTATS_COLUMNS_MAPPINGS)}
        </Box>

        <Box
          sx={{
            display: 'inline',
            justifyContent: 'space-between',
            position: 'absolute',
            top: '85vh',
            right: '1vw'
          }}>
          <IconButton onClick={handleInfoOpen}>
            <HelpIcon fontSize='large' />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default DbStats;
