import { useState, useEffect, useContext, SyntheticEvent, memo } from 'react';
import { Auth } from 'aws-amplify';

// material ui
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import { Checkbox, Link, MenuItem } from '@material-ui/core';

// api
import { upsertEmaAtcData, getEmaATCList } from '../../api/AdminPage';

// context
import Actions from '../../store/actions';
import Store from '../../store';
import MultiApplAtcModal from '../FDA/MultiApplAtcModal';

const rowsInPage = 500;

const EmaAtcModal = ({ closeEdit, applicationAtcData }: any) => {
  const { dispatch } = useContext<any>(Store);
  const [applicationNumber, setApplicationNumber] = useState(applicationAtcData.product_number);
  const [activeIngredient, setActiveIngredient] = useState(applicationAtcData.active_ingredient);
  const [tradeName, setTradeName] = useState(applicationAtcData.trade_name);
  const [qcStatus, setQcStatus] = useState(applicationAtcData.qc_status);
  const [atcCodes, setAtcCodes] = useState<string>(applicationAtcData.atc_code || '');
  const [openMultiDialog, setOpenMultiDialog] = useState<boolean>(false);
  const [similarApplicationList, setSimilarApplicationList] = useState<Array<any>>([]);
  const [allowMultiUpdate, setAllowMultiUpdate] = useState<boolean>(false);
  const [multiSelectedAppl, setMultiSelectedAppl] = useState<Array<any>>([]);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');

  useEffect(() => {
    const getUserInfo = async () => {
      try {
        const user = await Auth.currentUserInfo();
        setEmail(user.attributes.email);
        setName(user.attributes['custom:user']);
        const result = await getEmaATCList(
          rowsInPage,
          0,
          '',
          activeIngredient,
          '',
          '',
          'yes',
          'yes'
        );
        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.results) {
          const resultData = result.data.body.results;
          setSimilarApplicationList(
            resultData
              ?.filter((item: any) => item.product_number !== applicationNumber)
              ?.map((item: any) => ({ ...item, id: item.product_number }))
          );
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getUserInfo();
  }, []);

  const sendUpdateRequest = async (payload: any) => {
    const res = await upsertEmaAtcData(payload);
    const resData = res.data;
    if (resData.error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: resData.message }
      });
    } else {
      closeEdit();
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: resData.body, color: 'success' }
      });
    }
  };

  const handleMultiSelectToggle = () => {
    if (allowMultiUpdate) {
      setMultiSelectedAppl([]);
    } else {
      setMultiSelectedAppl(
        similarApplicationList.map((item: any) => ({
          product_number: item.product_number
        }))
      );
    }
    setAllowMultiUpdate(prev => !prev);
  };

  const handleMultiApplSubmit = async (selectedApplicationNumbers: Array<any>) => {
    setOpenMultiDialog(false);
    const selectedApplicationList = similarApplicationList
      ?.filter((item: any) => selectedApplicationNumbers.includes(item.product_number))
      ?.map((item: any) => ({
        product_number: item.product_number
      }));
    setMultiSelectedAppl(selectedApplicationList);
    if (selectedApplicationList.length > 0) {
      setAllowMultiUpdate(true);
    }
  };

  const handleKeyFormSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = {
      application_list: [
        {
          product_number: applicationNumber
        }
      ],
      atc_codes: atcCodes
        .split(',')
        .map(elem => elem.trim())
        .join(','),
      qc_status: qcStatus,
      curator_email: email,
      curator_name: name
    };
    if (allowMultiUpdate) {
      payload.application_list = [...payload.application_list, ...multiSelectedAppl];
    }
    await sendUpdateRequest(payload);
  };

  return (
    <Container style={{ maxWidth: '100%' }}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            EMA ATC Curation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Grid container spacing={2} display='flex'>
          <Grid item xs={12} lg={5} mb={3} xl={4}>
            <form onSubmit={handleKeyFormSubmit} style={{ marginTop: '20px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='application_number'
                    label='Application Number'
                    value={applicationNumber}
                    disabled
                    onChange={e => setApplicationNumber(e.target.value)}
                    InputProps={{
                      style: { fontSize: '1.5vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '1.5vh' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='active_ingredient'
                    label='Active Ingredient'
                    value={activeIngredient}
                    disabled
                    onChange={e => setActiveIngredient(e.target.value)}
                    InputProps={{
                      style: { fontSize: '1.5vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '1.5vh' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='trade_name'
                    label='Trade Name'
                    value={tradeName}
                    disabled
                    onChange={e => setTradeName(e.target.value)}
                    InputProps={{
                      style: { fontSize: '1.5vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '1.5vh' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='atc_codes'
                    label='ATC Codes(comma separated)'
                    value={atcCodes}
                    onChange={e => setAtcCodes(e.target.value)}
                    InputProps={{
                      style: { fontSize: '1.5vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '1.5vh' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    id='status'
                    label='QC Status'
                    select
                    value={qcStatus}
                    onChange={e => setQcStatus(e.target.value)}
                    InputProps={{
                      style: { fontSize: '1.5vh', minWidth: '250px' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '1.5vh' }
                    }}>
                    <MenuItem value='qced'>Qced</MenuItem>
                    <MenuItem value='to_qc'>To QC</MenuItem>
                    <MenuItem value='escalate'>Escalate</MenuItem>
                    <MenuItem value='missing_atc'>Missing ATC</MenuItem>
                  </TextField>
                </Grid>
                {similarApplicationList.length > 0 ? (
                  <Box style={{ color: '#3AB09E', cursor: 'pointer' }} sx={{ mt: 2, pl: 5 }}>
                    <Checkbox
                      checked={allowMultiUpdate}
                      onChange={() => handleMultiSelectToggle()}
                    />
                    <Typography
                      variant='body2'
                      onClick={() => setOpenMultiDialog(true)}
                      style={{ display: 'initial' }}>
                      ({`${multiSelectedAppl.length}/${similarApplicationList.length}`}) selected
                      from similar applications found with missing ATC Code, Click to Select
                    </Typography>
                  </Box>
                ) : null}
                <Grid item xs={12} display='flex' direction='column'>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    style={{ color: 'white' }}>
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} lg={7} xl={8}>
            <iframe
              src='https://www.whocc.no/atc_ddd_index/'
              title='WHO ATC Search'
              style={{ height: '80%', width: '100%' }}
            />
            <Box display='flex' style={{ justifyContent: 'space-around' }}>
              <Link href='https://www.whocc.no/atc_ddd_index/' underline='always' target='_blank'>
                WHO
              </Link>
              <Link href='https://mor.nlm.nih.gov/RxNav/' underline='always' target='_blank'>
                RxNav
              </Link>
            </Box>
          </Grid>
        </Grid>
      </div>
      <Dialog
        open={openMultiDialog}
        onClose={() => setOpenMultiDialog(false)}
        fullWidth
        sx={{ backdropFilter: 'blur(5px)', p: 10 }}>
        <MultiApplAtcModal
          handleSubmit={handleMultiApplSubmit}
          closeEdit={() => setOpenMultiDialog(false)}
          activeIngredient={activeIngredient}
          source='ema'
          applicationList={similarApplicationList}
          applicationSelected={multiSelectedAppl.map(elem => elem.product_number)}
        />
      </Dialog>
    </Container>
  );
};

export default memo(EmaAtcModal);
