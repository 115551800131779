/* eslint-disable import/prefer-default-export */
export const HIDDEN_COLUMNS: { [key: string]: any } = {
  accelerated_assessment: false,
  conditional_approval: false,
  orphan_medicine: false,
  exceptional_circumstances: false,
  advanced_therapy: false,
  combination_product: false,
  expedited_review_flag: false,
  orphan_status: false,
  fast_track: false,
  breakthrough_status: false,
  accelerated_approval: false,
  advisory_committee: false,
  biosimilar: false,
  application_boxed_warning_text: false,
  rems_text: false,
  // Clinical Trials
  has_expanded_access: false,
  healthy_volunteers: false,
  outcomes_assessor_masked: false,
  caregiver_masked: false,
  investigator_masked: false,
  subject_masked: false,
  allocation: false,
  study_completion: false,
  sponsors: false,
  primary_completion: false,
  overall_status: false,
  official_title: false,
  last_updated_year: false,
  gender: false,
  first_submitted_year: false,
  // Clinical Trials End
  atc_classification_export: false,
  // PMR Surveillance
  study_type: false,
  study_start_date: false,
  final_report_due_date: false,
  protocol_submission_date: false,
  // PMR Impact
  aa_status: false,
  bt_status: false,
  ft_status: false,
  or_status: false
};
