import UPDATEDGUIDANCE from '../components/GuidanceQcd/GuidanceTableView';
import UPDATEDGUIDANCEDATA from '../components/GuidanceQcd/GuidanceData';

const UPDATE_GUIDANCE_ROUTES = [
  {
    id: 'updateGuidanceView',
    path: '/guidancedocument',
    component: UPDATEDGUIDANCE,
    exact: true
  },
  {
    id: 'updateGuidanceDataView',
    path: '/guidancedocument/:key',
    exact: true,
    component: UPDATEDGUIDANCEDATA
  }
];
export default UPDATE_GUIDANCE_ROUTES;