import React, { ChangeEvent } from 'react';
import TextField from '@mui/material/TextField';

interface StyledTextField {
  id: string;
  label: string;
  value: string;
  multiline: boolean;
  // eslint-disable-next-line react/require-default-props
  disable?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChangeFn: (val: string) => void;
}

const TextFieldData = ({
  id,
  label,
  value,
  multiline,
  onChangeFn,
  disable = false
}: StyledTextField) => {
  return (
    <TextField
      key={id}
      variant='outlined'
      fullWidth
      label={label}
      value={value}
      disabled={disable}
      multiline={multiline}
      minRows={1}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        onChangeFn(e.target.value);
      }}
      InputProps={{
        style: { fontSize: '2vh' }
      }}
      InputLabelProps={{
        style: { fontSize: '2vh', minHeight: 'unset', lineHeight: 1 }
      }}
    />
  );
};

export default React.memo(TextFieldData);
