import { useState, useCallback, useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { getAppQcStatus, updateAppQcStatus } from '../../../api/submission_details';
import Store from '../../../store';

import Actions from '../../../store/actions';

const useQCStatus = (applno: string, submissionType: string, submissionNumber: any) => {
  const [appQCStatus, setAppQcStatus] = useState<string | null>('');
  const [user, setUser] = useState<any>();
  const { dispatch } = useContext<any>(Store);

  useEffect(() => {
    (async () => {
      const authUser = await Auth.currentUserInfo();
      setUser({
        curator_name: authUser.attributes['custom:user'],
        curator_email: authUser.attributes.email
      });
    })();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getQcStatus();
  }, []);

  const alert = useCallback((message: string, type: string) => {
    dispatch({
      type: Actions.SET_ALERT,
      value: {
        status: true,
        message,
        color: type
      }
    });
  }, []);

  const updateQcStatus = useCallback(
    async (qcStatus: string) => {
      const response = await updateAppQcStatus(applno, submissionType, submissionNumber, {
        qc_status: qcStatus,
        ...user
      });
      if (response?.data?.Status === 200) {
        alert('Updated QC Status', 'success');
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        getQcStatus();
      } else {
        // eslint-disable-next-line no-console
        alert('Failed to updated QC Status', 'error');
      }
    },
    [user, applno, submissionNumber, submissionType]
  );

  const getQcStatus = useCallback(async () => {
    const response = await getAppQcStatus(applno, submissionType, submissionNumber);
    if (response?.data?.Status === 200) {
      setAppQcStatus(response?.data?.Success?.qc_status);
    }
  }, [applno, submissionNumber, submissionType]);

  return {
    getQcStatus,
    updateQcStatus,
    appQCStatus
  };
};

export default useQCStatus;
