import React, { useCallback, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Autocomplete from '@mui/material/Autocomplete';
import Divider from '@mui/material/Divider';

import RadioGroup from './RadioGroup';
import SelectOptions from './SelectOptions';
import {
  STUDY_KEY_TITLE,
  ATTRIBUTE_MAPPING,
  ATTRIBUTE_ORDER,
  BOOLEAN_RADIO_GROUP_OPTIONS
} from './constant';

const getAttributename = (attrId: number, attributes: Array<any>) => {
  return attributes.find(attr => attr.attribute_id === attrId)?.attribute_name || attrId;
};

const getAttributeKey = (attrId: number, attributes: Array<any>) => {
  const attributeName = attributes.find(attr => attr.attribute_id === attrId)?.attribute_name;
  return attributeName?.replace(/ /g, '').toLowerCase();
};

const getAltName = (attrName: string) => {
  if (attrName.toLowerCase() === 'primary endpoint') {
    return 'Primary EP';
  }
  if (attrName.toLowerCase() === 'secondary endpoint') {
    return 'Secondary EP';
  }
  return attrName;
};

const StudyComponent = ({
  studyData,
  attributeOptions,
  endpointOptions,
  i,
  formOpen = false,
  indicationSaved = false,
  dataFromPrevSubmission = false,
  onSaveClick,
  onDeleteClick,
  onEditOpen
}: {
  studyData: any;
  attributeOptions: Array<any>;
  endpointOptions: Array<any>;
  i: number;
  formOpen: boolean;
  indicationSaved: boolean;
  dataFromPrevSubmission: boolean;
  onSaveClick: any;
  onDeleteClick: any;
  onEditOpen: any;
}) => {
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [study, setStudyData] = useState<any>({});

  useEffect(() => {
    setStudyData(studyData);
  }, [studyData]);

  useEffect(() => {
    setEditOpen(formOpen);
  }, [formOpen]);

  useEffect(() => {
    ATTRIBUTE_MAPPING.primaryendpoint = {
      ...ATTRIBUTE_MAPPING.primaryendpoint,
      options: endpointOptions
    };
    ATTRIBUTE_MAPPING.secondaryendpoint = {
      ...ATTRIBUTE_MAPPING.secondaryendpoint,
      options: endpointOptions
    };
  }, [endpointOptions]);

  const handleAttributeValueChange = useCallback(
    (index: any, value: string) => {
      const newStudyData = { ...study };
      if (!newStudyData.attributes?.[index]) {
        return;
      }
      newStudyData.attributes[index].attribute_value = value;
      setStudyData(newStudyData);
    },
    [study]
  );

  const handleAttributeDescriptionChange = useCallback(
    (index: any, value: string) => {
      const newStudyData = { ...study };
      if (!newStudyData.attributes?.[index]) {
        return;
      }
      newStudyData.attributes[index].attribute_description = value;
      setStudyData(newStudyData);
    },
    [study]
  );

  const handleRemoveAttribute = useCallback(
    (index: number) => {
      const newStudyData = { ...study };
      newStudyData.attributes.splice(index, 1);
      setStudyData(newStudyData);
    },
    [study]
  );

  const handleAttributeTypeChange = useCallback(
    (index: number, value: string) => {
      const newStudyData = { ...study };
      newStudyData.attributes[index].attribute_id = parseInt(value, 10);
      setStudyData(newStudyData);
    },
    [study]
  );

  const handleAddAttribute = useCallback(
    (index: number) => {
      const newStudyData = { ...study };
      let previousValueIndex = attributeOptions.findIndex(
        (attr: any) => attr.attribute_id === newStudyData.attributes[index]?.attribute_id
      );
      previousValueIndex += 1;
      if (previousValueIndex > attributeOptions.length - 1) {
        previousValueIndex = 0;
      }
      newStudyData.attributes.push({
        attribute_id: attributeOptions[previousValueIndex].attribute_id,
        attribute_value: null
      });
      setStudyData(newStudyData);
    },
    [study]
  );

  const handleSaveClick = useCallback(() => {
    onSaveClick(study);
  }, [study]);
  return (
    <Box sx={{ backgroundColor: 'inherit', position: 'relative' }}>
      <Box display='flex' alignItems='center'>
        <Typography flex={1} key={study.id} fontWeight='bold'>
          Study {i + 1}
        </Typography>
        <Box flex={1}>
          <Button
            disabled={editOpen || dataFromPrevSubmission}
            onClick={() => {
              setEditOpen(prev => !prev);
              onEditOpen(study);
            }}>
            Edit
          </Button>
        </Box>
      </Box>

      <Box p='4px'>
        {Object.keys(study)
          .sort((a: string, b: string) => ATTRIBUTE_ORDER.indexOf(a) - ATTRIBUTE_ORDER.indexOf(b))
          .map((key: string) => {
            if (!STUDY_KEY_TITLE[key]) {
              return null;
            }
            return (
              <Box display='flex' alignItems='center' textAlign='start' p={1} key={key}>
                <Typography flex={1} display='inline-block' fontWeight='600'>
                  {STUDY_KEY_TITLE[key].title}:
                </Typography>
                {/* eslint-disable-next-line */}
                {!editOpen ? (
                  STUDY_KEY_TITLE[key].inputType === 'text' ||
                  STUDY_KEY_TITLE[key].inputType === 'number' ? (
                    <Typography flex={2} display='inline-block' pl={1}>
                      {`${study[key] || '--'}`}
                    </Typography>
                  ) : (
                    <Box flex={2}>
                      <RadioGroup
                        id='radio'
                        disabled
                        value={study[key]}
                        options={STUDY_KEY_TITLE[key].options}
                        onChange={event =>
                          setStudyData((prev: any) => ({
                            ...prev,
                            [key]: event.target.value === 'true'
                          }))
                        }
                      />
                    </Box>
                  )
                ) : // eslint-disable-next-line
                STUDY_KEY_TITLE[key].inputType === 'text' ||
                  STUDY_KEY_TITLE[key].inputType === 'number' ? (
                  <Autocomplete
                    freeSolo
                    disableClearable
                    sx={{ flex: 2 }}
                    options={STUDY_KEY_TITLE[key].options || []}
                    value={study[key]}
                    onInputChange={(event: any, value: any) => {
                      setStudyData((prev: any) => ({ ...prev, [key]: value }));
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        multiline
                        InputProps={{
                          ...params.InputProps,
                          type: STUDY_KEY_TITLE[key].inputType,
                          placeholder: `Enter ${STUDY_KEY_TITLE[key].title}`
                        }}
                      />
                    )}
                  />
                ) : STUDY_KEY_TITLE[key].inputType === 'radio-group' ? (
                  <Box flex={2}>
                    <RadioGroup
                      id='radio'
                      value={study[key]}
                      options={STUDY_KEY_TITLE[key].options}
                      onChange={event =>
                        setStudyData((prev: any) => ({
                          ...prev,
                          [key]: event.target.value === 'true'
                        }))
                      }
                    />
                  </Box>
                ) : null}
              </Box>
            );
          })}
      </Box>
      <Box pl={1}>
        <Box display='flex' alignItems='center'>
          <Typography fontWeight={800}>Other Attributes:</Typography>
          {study?.attributes?.length === 0 && editOpen && (
            <Box pl={1}>
              <Tooltip title='Add new Attribute'>
                <Box display='inline' onClick={() => handleAddAttribute(-1)}>
                  <ControlPointIcon sx={{ color: 'green', fontSize: 34, cursor: 'pointer' }} />
                </Box>
              </Tooltip>
            </Box>
          )}
        </Box>
        {study?.attributes?.length === 0 && (
          <Box display='flex' justifyContent='center'>
            <Typography fontWeight={800}>No Attributes selected</Typography>
          </Box>
        )}

        {!editOpen ? (
          <Box>
            {studyData.attributes
              .sort((a: any, b: any) => a.attribute_id - b.attribute_id)
              .map((attr: any, index: number) => {
                const attributeKey = getAttributeKey(attr.attribute_id, attributeOptions);
                return (
                  <>
                    <Box
                      display='flex'
                      alignItems='center'
                      textAlign='start'
                      p={1}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${attr.attribute_id}-${index}`}>
                      <Box flex={1} fontWeight='bold'>
                        {getAttributename(attr.attribute_id, attributeOptions)}:
                      </Box>
                      <Box flex={2} display='inline-block' pl={1}>
                        <Box>{attr.attribute_value}</Box>
                        {ATTRIBUTE_MAPPING[attributeKey]?.descriptionEnabled && (
                          <Box>
                            <Divider />
                            <Box>
                              <b>Description:</b> {attr.attribute_description}
                            </Box>
                          </Box>
                        )}
                      </Box>
                    </Box>
                    {attributeKey === 'nctid' && (
                      <Box fontWeight='bold' p={1}>
                        Label and Clinical trials posting is different?
                        <Box>
                          <RadioGroup
                            id='radio'
                            disabled
                            value={!!study.is_label_diff_from_clinical_posting as any}
                            options={BOOLEAN_RADIO_GROUP_OPTIONS as any}
                            onChange={event =>
                              setStudyData((prev: any) => ({
                                ...prev,
                                is_label_diff_from_clinical_posting: event.target.value === 'true'
                              }))
                            }
                          />
                        </Box>
                        {study.is_label_diff_from_clinical_posting && (
                          <Box fontWeight='normal'>
                            <b>Description:</b> {study.label_diff_from_clinical_posting_notes}
                          </Box>
                        )}
                      </Box>
                    )}
                  </>
                );
              })}
          </Box>
        ) : (
          <Box>
            {studyData.attributes
              .sort((a: any, b: any) => a.attribute_id - b.attribute_id)
              .map((attr: any, index: number) => {
                const attributeName = getAttributename(attr.attribute_id, attributeOptions);
                const attributeKey = getAttributeKey(attr.attribute_id, attributeOptions);
                return (
                  <>
                    <Box
                      display='flex'
                      alignItems='center'
                      textAlign='start'
                      p={1}
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${attr.attribute_id}-${index}`}>
                      {editOpen ? (
                        <Box flex={1}>
                          <SelectOptions
                            value={attr.attribute_id}
                            options={attributeOptions.map((attrOpt: any) => ({
                              value: attrOpt.attribute_id,
                              label: getAltName(attrOpt.attribute_name)
                            }))}
                            onOptionChange={(event: any) =>
                              handleAttributeTypeChange(index, event?.target.value)
                            }
                          />
                        </Box>
                      ) : (
                        <Box flex={1} fontWeight='bold'>
                          {attributeName}:
                        </Box>
                      )}
                      <Autocomplete
                        freeSolo
                        disableClearable
                        sx={{ flex: 2 }}
                        options={ATTRIBUTE_MAPPING[attributeKey]?.options || []}
                        value={attr.attribute_value}
                        onInputChange={(event: any, value: any) =>
                          handleAttributeValueChange(index, value)
                        }
                        renderInput={(params: any) => (
                          <TextField
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...params}
                            multiline
                            InputProps={{
                              ...params.InputProps,
                              type: ATTRIBUTE_MAPPING[attributeKey]?.type || 'search',
                              placeholder: `Enter ${attributeName}`
                            }}
                          />
                        )}
                      />
                      <Box
                        display='flex'
                        width={80}
                        justifyContent='space-evenly'
                        alignItems='center'
                        pl={1}>
                        <Box flex={1}>
                          {studyData.attributes.length - 1 === index && (
                            <Box display='inline' onClick={() => handleAddAttribute(index)}>
                              <ControlPointIcon
                                sx={{ color: 'green', fontSize: 28, cursor: 'pointer' }}
                              />
                            </Box>
                          )}
                        </Box>
                        <Box flex={1} onClick={() => handleRemoveAttribute(index)}>
                          <RemoveCircleOutlineIcon
                            sx={{ color: 'red', fontSize: 28, cursor: 'pointer' }}
                          />
                        </Box>
                      </Box>
                    </Box>
                    {ATTRIBUTE_MAPPING[attributeKey]?.descriptionEnabled ? (
                      <Box display='flex' alignItems='center' textAlign='start' p={1} mt={-2}>
                        <Box flex={1} display='flex' justifyContent='end'>
                          <span>Desc:</span>
                        </Box>
                        <TextField
                          sx={{ flex: 2 }}
                          value={attr.attribute_description}
                          placeholder={`${attributeName} Description`}
                          multiline
                          onChange={(event: any) =>
                            handleAttributeDescriptionChange(index, event.target.value)
                          }
                        />
                        <Box width={80} />
                      </Box>
                    ) : null}
                    {attributeKey === 'nctid' && (
                      <Box fontWeight='bold' p={1}>
                        Label and Clinical trials posting is different?
                        <Box>
                          <RadioGroup
                            id='radio'
                            value={study.is_label_diff_from_clinical_posting}
                            options={BOOLEAN_RADIO_GROUP_OPTIONS as any}
                            onChange={event =>
                              setStudyData((prev: any) => ({
                                ...prev,
                                is_label_diff_from_clinical_posting: event.target.value === 'true'
                              }))
                            }
                          />
                        </Box>
                        {study.is_label_diff_from_clinical_posting && (
                          <Box display='flex' alignItems='center' textAlign='start'>
                            <Box flex={1} display='flex'>
                              <span>Description:</span>
                            </Box>
                            <TextField
                              sx={{ flex: 2 }}
                              value={study.label_diff_from_clinical_posting_notes}
                              placeholder='Add the mismatch Description'
                              multiline
                              onChange={(event: any) =>
                                setStudyData((prev: any) => ({
                                  ...prev,
                                  label_diff_from_clinical_posting_notes: event.target.value
                                }))
                              }
                            />
                            <Box width={80} />
                          </Box>
                        )}
                      </Box>
                    )}
                  </>
                );
              })}
          </Box>
        )}
      </Box>
      <Box display='flex' justifyContent='end'>
        <Tooltip title={!indicationSaved ? 'Save Indication text before adding studies.' : ''}>
          <Box>
            <Button
              variant='contained'
              disabled={!editOpen || !indicationSaved || dataFromPrevSubmission}
              onClick={handleSaveClick}>
              Save
            </Button>
          </Box>
        </Tooltip>

        <Button
          sx={{ ml: 1 }}
          color='error'
          variant='contained'
          disabled={dataFromPrevSubmission}
          onClick={() => onDeleteClick(study)}>
          Remove
        </Button>
      </Box>
      <hr />
    </Box>
  );
};

export default React.memo(StudyComponent);
