import { useState, useCallback, useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import Store from '../../../store';

import Actions from '../../../store/actions';
import {
  addKeyAttributes,
  getKeyAttributes,
  updateKeyAttributes
} from '../../../api/regulatory_information';

const useKeyAttributesAPIs = (appKey: string) => {
  const { dispatch } = useContext<any>(Store);
  const [attributes, setAttributes] = useState<Array<any>>([]);
  const [user, setUser] = useState<any>();

  useEffect(() => {
    (async () => {
      const authUser = await Auth.currentUserInfo();
      setUser({
        curator_name: authUser.attributes['custom:user'],
        curator_email: authUser.attributes.email
      });
    })();
  }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getKeyAttributes(appKey);
        if (response.data.Status === 200) {
          //eslint-disable-next-line no-shadow,
          const attributes = response.data.Success.map((ind: any) => ({
            ...ind,
            expanded: false
          }));
          //eslint-disable-next-line no-shadow,
          setAttributes(attributes);
          await dispatch({
            type: Actions.SET_KEY_ATTRIBUTES,
            value: attributes
          });
        } else if (response.data.Status === 404) {
          await dispatch({
            type: Actions.SET_KEY_ATTRIBUTES,
            value: []
          });
          await dispatch({
            type: Actions.SET_ALERT,
            value: {
              status: true,
              message: 'Could not find attributes for the given key'
            }
          });
        } else {
          await dispatch({
            type: Actions.SET_ALERT,
            value: {
              status: true,
              message: 'Could not find attributes for the given key'
            }
          });
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getData();
  }, []);

  const alert = useCallback((message: string, type: string) => {
    dispatch({
      type: Actions.SET_ALERT,
      value: {
        status: true,
        message,
        color: type
      }
    });
  }, []);

  const expandAttribute = useCallback(
    (id: Number) => {
      let newAttributes = [...attributes];
      const index = newAttributes.findIndex((ind: any) => ind.id === id);
      const isAttrExpanded = newAttributes[index].expanded;
      newAttributes = newAttributes.map((attr:any) => 
        (attr.id !== id) 
          ? {...attr, expanded:false} 
          : {...attr, expanded:!isAttrExpanded, editOpen:false}
      );
      setAttributes(newAttributes);
    },
    [attributes]
  );

  const editAttribute = useCallback(
    (id: Number) => {
      const newAttributes = [...attributes];
      const index = newAttributes.findIndex((ind: any) => ind.id === id);
      newAttributes[index].editOpen = !newAttributes[index].editOpen;
      setAttributes(newAttributes);
    },
    [attributes]
  );

  const saveAttribute = useCallback(
    async (id: number) => {
      const newAttributes = [...attributes];
      const index = newAttributes.findIndex((ind: any) => ind.id === id);
      if (newAttributes[index].newAttribute) {
        let sequence = newAttributes.filter(item => item.attribute_title.match(newAttributes[index].attribute_title)).map(item => item.attribute_seq);
        let attr_seq = Math.max(...sequence)+1
        try {
          const response = await addKeyAttributes({
            application_id: newAttributes[index].application_id,
            attribute_id: 2,
            attribute_seq: attr_seq,
            attribute_title: newAttributes[index].attribute_title,
            attribute_value: newAttributes[index].attribute_value ? newAttributes[index].attribute_value.toISOString().split('T')[0]:'',
            document_type_id: 2,
            qc_date: '',
            qc_status: newAttributes[index].qc_status,
            source: newAttributes[index].source,
            comments: newAttributes[index].comments,
            curator_name: user.curator_name
          });
          if (response.data.Status === 200) {
            newAttributes[index] = {
              ...newAttributes[index],
              ...response.data.Success.sbas_data,
              newAttribute: false
            };
            newAttributes[index].editOpen = false;
            setAttributes(newAttributes);
            alert('New Attribute added', 'success');
          } else {
            alert('Failed to add new Attribute', 'error');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          alert('Failed to add new Attribute', 'error');
        }
      } else {
        // Handle Edit
        const response = await updateKeyAttributes(
          id,
          {
            attribute_seq: newAttributes[index].attribute_seq,
            attribute_title: newAttributes[index].attribute_title,
            attribute_value: newAttributes[index].attribute_value,
            qc_status: newAttributes[index].qc_status,
            curator_name: user.curator_name,
            comments: newAttributes[index].comments,
            source: newAttributes[index].source
          }
          // eslint-disable-next-line no-console
        ).catch(console.error);
        if (response.data.Status === 200) {
          if (typeof response.data.Success !== 'boolean') {
            newAttributes[index].id = response.data.Success.id;
          }
          newAttributes[index].editOpen = false;
          setAttributes(newAttributes);
          alert('Attribute updated successfully', 'success');
        } else {
          alert('Failed to update Attribute', 'error');
        }
      }
    },
    [attributes, user]
  );

  const updateAttributeFields = useCallback(
    (id: number, value: string, field: string) => {
      const newAttributes = [...attributes];
      const index = newAttributes.findIndex((ind: any) => ind.id === id);
      newAttributes[index][field] = value;
      setAttributes(newAttributes);
    },
    [attributes]
  );

  const addEmptyAttributeFields = useCallback(() => {
    const newAttribute = [...attributes];
    newAttribute.push({
      id: Date.now(),
      attribute_seq: 0,
      attribute_title: '',
      attribute_value: '',
      qc_status: 'To_Qc',
      application_id: attributes[0].application_id,
      comments: '',
      source: '',
      newAttribute: true,
      expanded: true,
      editOpen: true
    });
    setAttributes(newAttribute);
  }, [attributes]);

  return {
    attributes,
    expandAttribute,
    editAttribute,
    updateAttributeFields,
    addEmptyAttributeFields,
    saveAttribute
  };
};

export default useKeyAttributesAPIs;
