import React, { useState, useEffect, SyntheticEvent, useContext } from 'react';

// muiv5
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Auth } from 'aws-amplify';
import { MenuItem } from '@material-ui/core';
import sortLabelInfo from '../CBER/utils';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { submitLabelPageCurationDetails, submitLabelProcessedDetails } from '../../api/Cder';

// styles
import styles from './styles/PageNumberEdit.styles';
import TextFieldCard from '../TextFieldCard';

const labelInfo = [
  {
    id: 'indications_and_usage',
    labelName: 'Indication And Usage'
  },
  {
    id: 'dosage_and_administration',
    labelName: 'Dosage And Administration'
  },
  {
    id: 'dosage_forms_and_strengths',
    labelName: 'Dosage Forms And Strengths'
  },
  {
    id: 'contraindications',
    labelName: 'Contraindications'
  },
  {
    id: 'warning_and_precautions',
    labelName: 'Warnings And Precautions'
  },
  {
    id: 'adverse_reactions',
    labelName: 'Adverse Reactions'
  },
  {
    id: 'drug_interactions',
    labelName: 'Drug Interactions'
  },
  {
    id: 'use_in_specific_populations',
    labelName: 'Use In Specific Populations'
  },
  {
    id: 'drug_abuse_and_dependence',
    labelName: 'Drug Abuse And Dependence'
  },
  {
    id: 'overdosage',
    labelName: 'Overdosage'
  },
  {
    id: 'description',
    labelName: 'Description'
  },
  {
    id: 'clinical_pharmacology',
    labelName: 'Clinical Pharmacology'
  },
  {
    id: 'non_clinical_toxicology',
    labelName: 'Non Clinical Toxicology'
  },
  {
    id: 'clinical_studies',
    labelName: 'Clinical Studies'
  },
  {
    id: 'references',
    labelName: 'References'
  },
  {
    id: 'how_supplied_storage_and_handling',
    labelName: 'How Supplied Storage And Handling'
  },
  {
    id: 'patient_counseling_information',
    labelName: 'Patient Counseling Information'
  }
];
const PLRPage = ({
  format,
  docsId,
  closeEdit,
  labelCachedData,
  processedFormat,
  handleNext
}: any) => {
  type LabelData = {
    dosage_and_administration: number;
    dosage_forms_and_strengths: number;
    contraindications: number;
    warning_and_precautions: number;
    adverse_reactions: number;
    drug_interactions: number;
    use_in_specific_populations: number;
    drug_abuse_and_dependence: number;
    overdosage: number;
    description: number;
    clinical_pharmacology: number;
    non_clinical_toxicology: number;
    clinical_studies: number;
    references: number;
    how_supplied_storage_and_handling: number;
    patient_counseling_information: number;
    indications_and_usage: number;
    qc_status: string;
    disabled_sections: Array<any>;
    [key: string]: number | string | Array<any>;
  };
  const { dispatch } = useContext<any>(Store);
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [labelData, setLabelData] = useState<LabelData>({
    dosage_and_administration: 0,
    dosage_forms_and_strengths: 0,
    contraindications: 0,
    warning_and_precautions: 0,
    adverse_reactions: 0,
    drug_interactions: 0,
    use_in_specific_populations: 0,
    drug_abuse_and_dependence: 0,
    overdosage: 0,
    description: 0,
    clinical_pharmacology: 0,
    non_clinical_toxicology: 0,
    clinical_studies: 0,
    references: 0,
    how_supplied_storage_and_handling: 0,
    patient_counseling_information: 0,
    indications_and_usage: 0,
    disabled_sections: [],
    qc_status: 'qced'
  });

  useEffect(() => {
    setLabelData({
      indications_and_usage: labelCachedData.indications_and_usage ?? 0,
      dosage_and_administration: labelCachedData.dosage_and_administration ?? 0,
      dosage_forms_and_strengths: labelCachedData.dosage_forms_and_strengths ?? 0,
      contraindications: labelCachedData.contraindications ?? 0,
      warning_and_precautions: labelCachedData.warning_and_precautions ?? 0,
      adverse_reactions: labelCachedData.adverse_reactions ?? 0,
      drug_abuse_and_dependence: labelCachedData.drug_abuse_and_dependence ?? 0,
      drug_interactions: labelCachedData.drug_interactions ?? 0,
      use_in_specific_populations: labelCachedData.use_in_specific_populations ?? 0,
      overdosage: labelCachedData.overdosage ?? 0,
      description: labelCachedData.description ?? 0,
      clinical_pharmacology: labelCachedData.clinical_pharmacology ?? 0,
      non_clinical_toxicology: labelCachedData.non_clinical_toxicology ?? 0,
      clinical_studies: labelCachedData.clinical_studies ?? 0,
      references: labelCachedData.references ?? 0,
      how_supplied_storage_and_handling: labelCachedData.how_supplied_storage_and_handling ?? 0,
      patient_counseling_information: labelCachedData.patient_counseling_information ?? 0,
      qc_status: labelCachedData.qc_status?.toLowerCase() ?? 'qced',
      disabled_sections: labelCachedData.disabled_sections ?? []
    });
  }, []);

  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>, isNextSubmit = false) => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        indications_and_usage: labelData.indications_and_usage ?? 0,
        dosage_and_administration: labelData.dosage_and_administration ?? 0,
        dosage_forms_and_strengths: labelData.dosage_forms_and_strengths ?? 0,
        contraindications: labelData.contraindications ?? 0,
        warning_and_precautions: labelData.warning_and_precautions ?? 0,
        adverse_reactions: labelData.adverse_reactions ?? 0,
        drug_abuse_and_dependence: labelData.drug_abuse_and_dependence ?? 0,
        drug_interactions: labelData.drug_interactions ?? 0,
        use_in_specific_populations: labelData.use_in_specific_populations ?? 0,
        overdosage: labelData.overdosage ?? 0,
        description: labelData.description ?? 0,
        clinical_pharmacology: labelData.clinical_pharmacology ?? 0,
        non_clinical_toxicology: labelData.non_clinical_toxicology ?? 0,
        clinical_studies: labelData.clinical_studies ?? 0,
        references: labelData.references ?? 0,
        how_supplied_storage_and_handling: labelData.how_supplied_storage_and_handling ?? 0,
        patient_counseling_information: labelData.patient_counseling_information ?? 0,
        qc_status: labelData.qc_status.toLowerCase() ?? '',
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      };
      let res;
      if (processedFormat) {
        res = await submitLabelProcessedDetails(format.toLowerCase(), docsId, payload);
      } else {
        res = await submitLabelPageCurationDetails(format.toLowerCase(), docsId, payload);
      }
      if (!res?.data || res?.data?.status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Error while Submitting ${docsId}` }
        });
      } else if (res.data.body) {
        if (isNextSubmit) {
          handleNext();
        } else {
          closeEdit();
        }
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: res.data.body, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  const handleNextSubmit = (event: any) => {
    event.preventDefault();
    handleSubmit(event, true);
  };

  return (
    <form
      onSubmit={(event: any) => {
        handleSubmit(event);
      }}
      style={styles.form}>
      <Grid container maxHeight='90vh'>
        <Grid item xs={12} display='flex' direction='column'>
          {sortLabelInfo(labelInfo, labelData).map(arg => {
            return (
              <TextFieldCard
                key={arg.id}
                id={arg.id}
                labelName={arg.labelName}
                data={labelData}
                setData={setLabelData}
                setDisableSubmit={setDisableSubmit}
              />
            );
          })}
          <TextField
            variant='outlined'
            id='status'
            label='QC Status'
            select
            fullWidth
            required
            value={labelData.qc_status}
            onChange={e => setLabelData(prevData => ({ ...prevData, qc_status: e.target.value }))}
            InputProps={{
              style: { fontSize: '1.5vh', minWidth: '250px' }
            }}
            InputLabelProps={{
              style: { fontSize: '1.5vh' }
            }}>
            <MenuItem value='qced'>Qced</MenuItem>
            <MenuItem value='to_qc'>To QC</MenuItem>
            <MenuItem value='escalate'>Escalate</MenuItem>
            <MenuItem value='missing details'>Missing Details</MenuItem>
          </TextField>
        </Grid>
        <Grid item xs={12} display='flex' direction='row' justifyContent='space-around'>
          <Button
            type='submit'
            variant='contained'
            color='primary'
            sx={styles.submit}
            disabled={disableSubmit}
            style={{ color: 'white', width: '45%' }}>
            SUBMIT
          </Button>
          <Button
            type='button'
            variant='contained'
            color='primary'
            sx={styles.submit}
            onClick={e => handleNextSubmit(e)}
            style={{ color: 'white', width: '45%' }}>
            NEXT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PLRPage;
