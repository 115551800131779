import WhatsNew from '../components/WhatsNew';

const WHATS_NEW_ROUTES = [
  {
    id: 'whats_new',
    path: '/whats_new',
    component: WhatsNew,
    exact: true
  }
];

export default WHATS_NEW_ROUTES;
