/* eslint-disable react/prop-types */

import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import styles from './styles/TableView.styles';

const TableView = ({ isOpen, handleClose, title = 'Sample Records', rows, columns }) => {
  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth='xl'>
      <DialogTitle>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Typography component='h2' variant='h6'>
            {title}
          </Typography>
          <IconButton onClick={handleClose} size='large'>
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {rows?.length > 0 ? (
          <TableContainer sx={{ maxHeight: '70vh' }} component={Paper}>
            <Table stickyHeader aria-label='simple table' size='small'>
              <TableHead>
                <TableRow>
                  {columns?.map(columnName => (
                    <TableCell sx={styles.tableHead}>{columnName}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {rows?.map(row => (
                  <TableRow
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0
                      }
                    }}>
                    {row?.map(cell => (
                      <TableCell component='tr' scope='row'>
                        {cell &&
                          cell.toString().slice(0, 50) + (cell.toString().length > 50 ? '...' : '')}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null}
      </DialogContent>
    </Dialog>
  );
};
export default TableView;
