import Accelerated from '../components/Approval/Accelerated';
import Breakthrough from '../components/Approval/Breakthrough';
import Fasttrack from '../components/Approval/Fasttrack';
import Fda from '../components/FDA/Fda';

const APPROVAL_ROUTES = [
  // {
  //   id: 'aaBtFtCurationReview',
  //   path: '/approval/:applNo/:subType/:subNo/:docType',
  //   component: QCDocumentCheckFda,
  //   exact: true
  // },
  {
    id: 'aaBtFtCuration',
    path: '/approval',
    component: Fda,
    exact: true
  },
  {
    id: 'acceleratedCuration',
    path: '/approval/:applNo/:subType/:subNo/accelerated',
    component: Accelerated,
    exact: true
  },
  {
    id: 'breakthroughCuration',
    path: '/approval/:applNo/:subType/:subNo/breakthrough',
    component: Breakthrough,
    exact: true
  },
  {
    id: 'fasttrackCuration',
    path: '/approval/:applNo/:subType/:subNo/fasttrack',
    component: Fasttrack,
    exact: true
  }
];
export default APPROVAL_ROUTES;
