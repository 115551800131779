export const EMA_PROCEDURAL_STEPS_CURATION_QUERY_PARAM = 'procedural_steps';
export const EMA_PRODUCTS_CURATION_QUERY_PARAM = 'products';
export const EMA_INVALID_STATUS = 'invalid';
export const EMA_IN_PROGRESS_STATUS = 'in-progress';
export const EMA_QCD_STATUS = 'Qced';

export const KEY_COLUMNS = [
    {
      field: 'product_number',
      headerName: 'Product Number',
      width: 500,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'sourced_from',
      headerName: 'Sourced From',
      width: 500,
      headerAlign: 'left',
      align: 'left'
    },
    {
      field: 'qc_status',
      headerName: 'Qc Status',
      width: 200,
      headerAlign: 'left',
      align: 'left'
    }
]
