import React from 'react';

const VivproLogo = () => (
  <svg
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    xlink='http://www.w3.org/1999/xlink'
    width='200'
    height='200'
    x='0px'
    y='0px'
    viewBox='0 0 1000 1000'
    style={{ enableBackground: 'new 0 0 1000 1000' }}
    space='preserve'>
    <style type='text/css'>
      {
        '\
        .login_f0{\
          fill:#444444;\
        }\
        .login_f1{\
          fill:url(#SVGID_1_);\
        }\
        .login_f2{\
          fill:fill:#FFFFFF;;\
        }\
      '
      }
    </style>
    <g>
      <g>
        <g>
          <path
            className='login_f0'
            d='M395.5,560.2L359.7,459h31.9l22.1,76.5L436,459h28.8l-35.7,101.2H395.5z'
          />
          <path
            className='login_f0'
            d='M476.5,447.5v-27.9h30.9v27.9H476.5z M476.5,560.2V459h30.9v101.2H476.5z'
          />
          <path
            className='login_f0'
            d='M554.7,560.2L518.8,459h31.7l22.2,76.5L595,459h28.8l-35.5,101.2H554.7z'
          />
          <path
            className='login_f0'
            d='M648.3,459.7h11.6l-4,19.5c5.1-6.3,11.2-11.4,18.3-15.4c7.1-4,14.2-6,21.7-6c5.5,0,10.6,1.2,14.9,3.5
				c4.5,2.3,8.3,5.5,11.2,9.3c3.1,3.8,5.5,8.4,7.1,13.6c1.7,5.1,2.5,10.4,2.5,16c0,7.8-1.5,15.5-4.5,23c-3,7.4-6.9,14.1-12.1,19.8
				s-11.1,10.4-17.9,13.9c-6.8,3.5-13.9,5.1-21.3,5.1c-8.1,0-14.7-2.1-20-6.4c-5.1-4.3-8.9-9.8-11.2-16.4L631.6,601h-13.1
				L648.3,459.7z M674.6,550.4c5.8,0,11.2-1.5,16.5-4.3c5.3-2.8,9.9-6.6,13.7-11.2c4-4.6,7.1-9.8,9.4-15.4
				c2.3-5.8,3.5-11.4,3.5-17.2c0-4.5-0.7-8.8-2-12.7c-1.3-4-3.1-7.4-5.6-10.4c-2.5-3-5.3-5.3-8.6-7.1c-3.3-1.7-7.1-2.6-11.4-2.6
				c-3.1,0-6.4,0.7-10.1,2c-3.6,1.3-7.3,3.1-10.7,5.5c-3.5,2.3-6.6,5-9.4,7.9c-2.8,3-4.8,6.3-6.1,9.6l-6.6,30.9
				c0.7,3.6,1.8,6.9,3.3,10.1c1.7,3.1,3.6,5.8,6,7.9c2.3,2.3,5.1,4,8.1,5.3C667.8,549.9,671.1,550.4,674.6,550.4z'
          />
          <path
            className='login_f0'
            d='M762.3,459.7h12.4l-5.1,24.1c5-7.3,10.7-13.1,17-17.5c6.3-4.3,12.9-6.6,19.3-6.8c1,0,1.8,0,2.6,0.2
				c0.7,0,1.3,0.2,2,0.3l-2.5,11.4c-8.4,0.3-16.2,2.6-23.3,7.3c-7.3,4.6-13.1,10.9-17.4,19l-13.1,62.5h-13.1L762.3,459.7z'
          />
          <path
            className='login_f0'
            d='M843,562c-6,0-11.2-1.2-15.9-3.3c-4.6-2.3-8.6-5.3-11.9-9.1c-3.1-3.8-5.6-8.3-7.4-13.2
				c-1.7-5.1-2.6-10.6-2.6-16.4c0-8.1,1.7-15.9,5-23.3c3.3-7.4,7.6-14.1,13.2-19.8c5.5-5.8,11.9-10.2,19-13.7
				c7.3-3.5,14.7-5.1,22.8-5.1c6,0,11.2,1.2,15.9,3.3c4.6,2.3,8.6,5.3,11.9,9.1s5.8,8.3,7.4,13.4c1.7,5.1,2.6,10.6,2.6,16.4
				c0,7.9-1.7,15.7-5,23.1c-3.3,7.4-7.6,14.1-13.1,19.8c-5.5,5.8-11.9,10.2-19,13.7C858.5,560.4,850.9,562,843,562z M845.5,550.4
				c5.5,0,10.7-1.3,16-4c5.3-2.6,9.9-6.1,14.1-10.6s7.4-9.6,10.1-15.4c2.6-5.8,3.8-12.1,3.8-18.7c0-4.5-0.7-8.8-2-12.7
				c-1.3-4-3.1-7.4-5.5-10.2c-2.3-2.8-5.1-5.1-8.4-6.8s-6.9-2.5-10.9-2.5c-5.5,0-10.7,1.3-15.9,4c-5.1,2.6-9.9,6.3-14.1,10.7
				s-7.6,9.8-10.1,15.5c-2.6,6-3.8,12.1-3.8,18.7c0,4.5,0.7,8.8,2,12.6c1.3,4,3.1,7.3,5.5,10.2c2.3,2.8,5.1,5.1,8.4,6.8
				C837.9,549.8,841.5,550.4,845.5,550.4z'
          />
        </g>
      </g>
      <g>
        <linearGradient
          id='SVGID_1_'
          gradientUnits='userSpaceOnUse'
          x1='95'
          y1='500.0427'
          x2='315.6899'
          y2='500.0427'
          gradientTransform='matrix(1 0 0 -1 0 1000)'>
          <stop offset='1.060230e-06' style={{ stopColor: '#29ABE2' }} />
          <stop offset='1' style={{ stopColor: '#39B54A' }} />
        </linearGradient>
        <path
          className='login_f1'
          d='M268.8,601.2c-2.1,0-4-1.7-5.5-3c-8.1-7.8-16.4-15.4-24.6-23c-1.7-1.7-3.8-2.8-6-3.3
			c-16-4.8-33.7-10.6-44.3-24.3c-3.8-5.3-3.3-8.6,1.3-13.2c10.4-10.4,21-21,31.4-31.4c2.8-2.8,4.6-6,5-10.1
			c0.5-6.1,7.4-12.2,13.4-12.1c6.3,0,13.4,6.4,14.1,12.7c0.5,4.8-2.5,13.2-10.2,14.5c-5.8,1.2-10.2,3.8-14.2,7.9
			c-6.4,6.9-13.2,13.7-19.7,20.7c-3.8,4.1-4,5.1,0.8,7.8c18.3,11.1,43,21.7,62,6c6.6-4.6,17.7-13.2,24.1-27.3
			c10.7-27.3-2.8-60.5-35.7-58.7c-6.4,0.5-10.2-9.6-4.8-12.6c5.1-2.5,3.1-5.8,1.7-8.6c-4.1-8.3-9.4-15.9-17.5-20.8
			c-18.2-10.9-43.8-8.8-58.2,7.3c-8.1,8.1-15.9,22-8.6,33.1c4.6,7.8,0,18.7-8.8,20.3c-11.1,1.8-20.5-10.1-15.7-19
			c2.8-6.1,4.6-4,6.9-17.4c0.5-3.1-1.2-5.1-4.1-4.8c-45,5.3-56.9,66.8-15.5,86.6c3.5,1.5,6.1,0,8.3-2c22.8-22.5,45.5-45.1,67.6-68.3
			c2.1-2.1,2.5-4.8,3-7.3c1.5-8.6,7.3-11.6,13.2-12.4c5.6-0.7,13.2,6.4,14.4,12.4c1.2,6.9-5.1,14.5-13.6,15.7
			c-3,0.5-5.3,1.7-7.3,3.5c-24.1,22.6-47.1,46.4-70.3,70.3c-8.1,8.4-8.8,7.9-19.2,3.3c-20.5-9.1-32.6-25.1-36.4-47.4
			c-6.6-35.9,25-71.2,61.2-69.9c5,0,8.3-1.2,11.6-4.8c8.4-9.3,19.2-16.2,31.2-19.7c9.1-3,16.9-4.5,26.6-2.1
			c24,3.6,41.8,22.1,48.6,44.8c1,3.3,2.5,5.1,5.8,6.3c23.1,6.9,35.9,29.9,34.7,53.1c2,31.2-19.7,54.2-44.1,65.5
			c-3.6,1.5-6.1,2.8-4.1,5.1c4.5,5.3,9.3,10.4,14.1,15.4l4,4c2.6,2.6,0.7,7.1-3,7.1h-13.6V601.2z'
        />
        <path
          className='login_f2'
          d='M228.8,457.5c-3.3-0.2-5.3-2.1-5.1-5.5c0.2-3.1,2.3-5.3,5.5-5c3.1,0.3,4.8,2.3,5,5.3
			C234.2,455.6,231.9,457.7,228.8,457.5z'
        />
        <path
          className='login_f2'
          d='M161.8,464.7c3,0.3,4.3,2.5,4.6,5.5c0.3,3.1-2.1,5.1-5.5,5.1c-3.3-0.2-4.8-2.1-5-5.1
			C155.9,466.8,158.4,464.7,161.8,464.7z'
        />
        <path
          className='login_f2'
          d='M240.4,489.1c3-0.2,5.5,2.3,5.3,5.3c-0.2,3.1-1.8,5-5,5.1c-3.1,0-5-1.5-5.1-4.8
			C235.4,491.4,237.2,489.3,240.4,489.1z'
        />
      </g>
    </g>
  </svg>
);

export default VivproLogo;
