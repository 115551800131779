import * as React from 'react';
import { useContext, useState } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import { Box } from '@mui/material';
import Styles from './styles/GuidanceList.styles';
import AdminStore from '../../store/AdminPage';
import AdminActions from '../../store/AdminPage/actions';
import GuidanceAcceptance from './GuidanceAcceptance';

// eslint-disable-next-line react/prop-types
const CustomTextField = ({ name, value, disabled, onChange }) => {
  // eslint-disable-next-line react/prop-types
  const label = name.split('_').join(' ');
  return (
    <TextField
      fullWidth
      id={name}
      label={label}
      value={value}
      disabled={disabled ?? false}
      variant='outlined'
      onChange={onChange}
    />
  );
};
// eslint-disable-next-line react/prop-types
const GuidanceListDisplay = ({ documents, type, onClose, disableCompare, insertButton }) => {
  const classes = Styles();
  const { adminDispatch } = useContext(AdminStore);

  const [openDetails, setOpenDetails] = useState(false);
  const [disableCompareButton, setDisableCompareButton] = useState(false);
  const {
    // eslint-disable-next-line react/prop-types
    guidance_pk: guidancePk,
    // eslint-disable-next-line react/prop-types
    title
  } = documents;
  const handleAddToCompare = () => {
    const setFunction = async () => {
      await adminDispatch({
        type:
          type === 'qced'
            ? AdminActions.SET_QCED_GUIDANCE_COMPARE
            : AdminActions.SET_NON_QCED_GUIDANCE_COMPARE,
        value: documents
      });
    };
    setFunction();

    setDisableCompareButton(false);
    onClose();
  };

  const labelId = `checkbox-list-secondary-label-${guidancePk}`;
  return (
    <>
      <ListItem
        className={classes.guidanceList}
        secondaryAction={
          // eslint-disable-next-line no-nested-ternary
          disableCompare ? (
            insertButton ? (
              <GuidanceAcceptance
                documents={documents}
                onClick={() => setDisableCompareButton(true)}
                onClose={onClose}
                withoutChecks
              />
            ) : null
          ) : (
            <Button
              variant='contained'
              color='secondary'
              disabled={disableCompareButton}
              onClick={() => {
                setDisableCompareButton(true);
                handleAddToCompare();
              }}>
              Compare
            </Button>
          )
        }
        disablePadding>
        <ListItemButton onClick={() => setOpenDetails(true)}>
          <ListItemText
            id={labelId}
            secondary={
              <Typography className={classes.secondaryText} variant='caption'>
                {guidancePk}
              </Typography>
            }
            primary={title}
            color='white'
          />
        </ListItemButton>
      </ListItem>

      <Dialog onClose={() => setOpenDetails(false)} open={openDetails}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <Box display='flex' flexDirection='column'>
            {Object.entries(documents).map(([key, value]) => (
              <Box key={`${key}${value}`} sx={{ marginTop: '1em' }} width='100%'>
                <CustomTextField name={key} value={value} disabled />
              </Box>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GuidanceListDisplay;
