import makeRequest from './client';

/**
 * Function to get details of Reviews.
 * @param {string} app
 * @param {string} sub
 * @param {string} subno
 * @param {string} doc
 * @param {string} page
 */
export const getReviewData = (app, sub, subno, doc, page = 'non_processed') =>
  makeRequest(`/review/qc/${app}/${sub}/${subno}/${doc}/${page}`, 'GET');

/**
 * Function to get details of Reviews.
 * @param {string} app
 * @param {string} sub
 * @param {string} subno
 * @param {string} doc
 */
export const getLabelInfo = (app, sub, subno, doc) =>
  makeRequest(`/fda/label_section/${app}/${doc.toLowerCase()}/${sub}/${subno}`, 'GET');

/**
 * Function to submit QC Data
 * @param {object} payload
 */
export const submitQcData = payload => makeRequest(`/review/qc`, 'POST', payload);

/**
 * Function to get details of Labels
 * @param {string} app
 * @param {string} sub
 * @param {string} subno
 * @param {string} doctype
 */
export const getLabelData = (app, sub, subno, doctype) =>
  makeRequest(`/review/label_text_qc/${app}/${doctype}/${sub}/${subno}`, 'GET');

/**
 * Function to get FDA data
 * @param {string} app
 * @param {string} sub
 * @param {string} subno
 * @param {string} doctype
 */
export const getFdaData = (app, sub, subno, doctype) =>
  makeRequest(`/approval_status/${app}/${doctype}/${sub}/${subno}`, 'GET');

/**
 * Function to get all FDA data
 * @param {string} doctype
 */
export const getAllFdaData = doctype => makeRequest(`/approval_status/all/${doctype}`, 'GET');

/**
 * Function to get PMR details using Key
 * @param {string} key
 */
export const getPmrByKey = key => makeRequest(`/pmr/review/${key}`, 'GET');

/**
 * Function to get PMR details using ID.
 * @param {string} id
 */
export const getPmrById = id => makeRequest(`/pmr/review/id/${id}`, 'GET');

/**
 * Function to submit New doc change.
 * @param {object} payload
 */
export const submitNewLabelInfo = payload => makeRequest(`/fda/label_section`, 'POST', payload);

/**
 * Function to submit PMR Form.
 * @param {object} payload
 */
export const submitPmrFormById = payload => makeRequest(`/pmr/review`, 'POST', payload);

/**
 * Function to submit PLR details.
 * @param {object} payload
 */
export const submitPLRData = payload => makeRequest(`/review/plr_qc`, 'POST', payload);

/**
 * Function to submit Non PLR Data.
 * @param {object} payload
 */
export const submitNonPLRData = payload => makeRequest(`/review/nonplrqc`, 'POST', payload);

/**
 * Function to submit Processed PLR details.
 * @param {object} payload
 */
export const submitProcessedPLRData = payload =>
  makeRequest(`/review/plr_qc/processed`, 'POST', payload);

/**
 * Function to submit Processed Non PLR Data.
 * @param {object} payload
 */
export const submitProcessedNonPLRData = payload =>
  makeRequest(`/review/nonplrqc/processed`, 'POST', payload);

/**
 * Function to submit Non PLR Label Data.
 * @param {object} payload
 */
export const submitNonPLRLabelData = payload => makeRequest(`/fda/label_text_qc`, 'POST', payload);

/**
 * Function to submit Non PLR FDA details.
 * @param {object} payload
 */
export const submitNonPLRFdaData = payload => makeRequest(`/approval_status`, 'POST', payload);

/**
 * `getRld` is a function that makes a GET request to the `/rld` endpoint
 */
export const getRld = () => makeRequest(`/rld`, 'GET');

/**
 * Function to submit RLD Form.
 * @param {object} payload
 */
export const submitRLDForm = payload => makeRequest(`/rld`, 'POST', payload);

/**
 * It makes a GET request to the server, and if a id is provided, it appends it to the URL
 */
export const getRldById = id => makeRequest(`/rld/${id}`, 'GET');

/**
 * Function to get Delete RLD.
 * @param {string} id
 */
export const deleteRld = id => makeRequest(`/rld/${id}`, 'DELETE');

/**
 * Function to get Update RLD.
 * @param {string} id
 * @param {object} payload
 */
export const updateRld = (id, payload) => makeRequest(`/rld/${id}`, 'POST', payload);

/**
 * Function to get Health data.
 */
export const isHealthy = () => makeRequest(`/health`);

/**
 * Function to get Snapshot Data.
 * @param {string} key
 */
export const getSnapshotData = key => makeRequest(`/snapshot/${key}`, 'GET');

/**
 * Function to Update Snapshot Data.
 * @param {string} update
 * @param {string} tablename
 * @param {string} payload
 */
export const updateSnapshotData = (update, tablename, payload) =>
  makeRequest(`/snapshot?update=${update}&tablename=${tablename}`, 'POST', payload);

/**
 * Function to Insert Snapshot Data
 * @param {string} tablename
 * @param {object} payload
 */
export const insertSnapshotData = (tablename, payload) =>
  makeRequest(`/snapshot?tablename=${tablename}`, 'POST', payload);
/**
 * Function to get Bot Stats
 * @param {string} days
 * @param {string} group
 * @param {boolean} hideVivpro
 */
export const getBotStats = (days, group, hideVivpro) =>
  makeRequest(`/bot/stats/${days}/${group}/${hideVivpro.toString()}`, 'GET');

/**
 * Function to get Bot queries
 * @param {string} startDate
 * @param {string} group
 * @param {boolean} hideVivpro
 */
export const getBotQueries = (startDate, group, hideVivpro) =>
  makeRequest(`/bot/questions/${startDate}/${group}/${hideVivpro.toString()}`, 'GET');

/**
 * Function to submit data for EMA Procedural Steps
 * @param {object} payload
 */
export const submitEMAProceduralStepsData = payload =>
  makeRequest(`/fda/label_text_qc`, 'POST', payload);

/**
 * It makes a GET request to the server, and if a key term is provided, it appends it to the URL
 */
export const getSynonymsByKey = key => makeRequest(`/synonyms/${key}`, 'GET');
/**
 * `getSynonyms` is a function that makes a GET request to the `/synonyms` endpoint
 */
export const getSynonyms = () => makeRequest(`/synonyms`, 'GET');
/**
 * Function to get Delete Synonyms.
 * @param {string} keyTerm
 */
export const deleteSynonyms = keyTerm => makeRequest(`/synonyms/${keyTerm}`, 'DELETE');

/**
 * Function to Create Synonyms details.
 * @param {object} payload
 */
export const createsynonyms = payload => makeRequest(`/synonyms`, 'POST', payload);

/**
 * Function to get Update Synonyms.
 * @param {string} keyTerm
 * @param {object} payload
 */
export const updateSynonyms = (keyTerm, payload) =>
  makeRequest(`/synonyms/${keyTerm}`, 'POST', payload);

/**
 * It makes a GET request to the server and returns the response
 * @param {string} closeDate - The date the application was closed.
 * @param {string} applicationNumber - The application number of the application you want to get the label for.
 * @param {string} documentNumber - The Key  of the application you want to get the label for. */
export const getEMALabelInfo = (closeDate, applicationNumber, documentNumber) =>
  makeRequest(`/ema/${closeDate}/${applicationNumber}/${documentNumber}`, 'GET');

/**
 * It makes a POST request to the server with the curator's name and email address
 * @param {string} closeDate - The date the application was closed.
 * @param {string} applicationNumber - The application number of the application you want to submit.
 * @param {string} curatorEmail - The email address of the curator who will be responsible for the document.
 * @param {string} curatorName - The name of the curator who is submitting the document
 * @param {string} documentNumber - The Key of the submitting document
 * */
export const submitPageSeparatedEMADocument = (
  closeDate,
  applicationNumber,
  curatorEmail,
  curatorName,
  documentNumber,
  downloadFile
) =>
  makeRequest(`/ema/${closeDate}/${applicationNumber}/${documentNumber}`, 'POST', {
    curator_name: curatorName,
    curator_email: curatorEmail,
    download_file: downloadFile
  });
/**
 * It makes a GET request to the server to get the label for the EMA document
 * @param closeDate - The date the application was closed.
 * @param applicationNumber - The application number of the application you want to download the label for.
 * @param documentNumber - The document number of the application you want to download the label for.
 * */
export const getLabeledEMADocument = (closeDate, applicationNumber, documentNumber) =>
  makeRequest(`/ema/${closeDate}/${applicationNumber}/${documentNumber}/label`, 'GET');
/**
 * It makes a POST request to the server with the curator's name, email address and label data
 * @param closeDate - The date of the close.
 * @param applicationNumber - The application number of the application you want to submit a label for.
 * @param payload - {
 * @param documentNumber
 */
export const submitLabeledEMADocument = (closeDate, applicationNumber, payload, documentNumber) =>
  makeRequest(`/ema/${closeDate}/${applicationNumber}/${documentNumber}/label`, 'POST', {
    ...payload
  });

/**
 * `getNonQcedGuidance` is a function that makes a GET request to the `/guidance/` endpoint
 */
export const getNonQcedGuidance = () => makeRequest(`/guidance`, 'GET');

/**
 * It makes a POST request to the server with the searchValue and searchField as the body of the request
 * @param searchValue - The value you want to search for.
 * @param searchField - The field you want to search on.
 */
export const searchQcdGuidance = (searchValue, searchField) =>
  makeRequest(`/guidance/search`, 'POST', {
    search_term: searchValue,
    search_column: searchField
  });

/**
 * It makes a POST request to the `/guidance/` endpoint with the payload as the body of the request
 */
export const insertQcdGuidance = payload =>
  makeRequest(`/guidance`, 'POST', {
    ...payload
  });

/**
 * It makes a POST request to the server with the payload as the body of the request
 */
export const s3toPdf = payload =>
  makeRequest(`/guidance/s3-pdf`, 'POST', {
    qced: payload.qced,
    non_qced: payload.nonQced
  });

/**
 * Function to submit data for comparing Labels.
 * @param {object} pdfdata
 */
export const getLabelComparison = pdfdata => makeRequest(`/comparison/viewer/pdf`, 'POST', pdfdata);

/**
 * It makes a GET request to the `/ema/{closeDate}/{applicationNumber}/{documentId}/products` endpoint
 * @param closeDate - The date the application was closed.
 * @param applicationNumber - The application number of the EMA application.
 */
export const getEMAProducts = (closeDate, applicationNumber) =>
  makeRequest(`/ema/${closeDate}/${applicationNumber}/1/products`, 'GET');
/**
 * Function to insert/update the Qced EUA Documents into indication table.
 * @param {object} payload
 */
export const updateGuidanceQcd = payload =>
  makeRequest(`/guidance/update-qcd-doc`, `POST`, payload);

export const getCderATCList = (
  pageSize,
  pageNumber,
  applicationNumber,
  activeIngredient,
  atcCode,
  qcStatus,
  emptyATC,
  exactSearch
) =>
  makeRequest(
    `/cder/atc?page_size=${pageSize}&page_number=${pageNumber}&application_number=${applicationNumber}&active_ingredient=${activeIngredient}&atc_code=${atcCode}&qc_status=${qcStatus}&empty_atc=${emptyATC}&exact_search=${exactSearch}`,
    'GET'
  );

export const upsertCderAtcData = payload => makeRequest('/cder/atc/qc', 'POST', payload);

export const getEuaATCList = (
  pageSize,
  pageNumber,
  applicationNumber,
  activeIngredient,
  atcCode,
  qcStatus,
  emptyATC,
  exactSearch
) =>
  makeRequest(
    `/eua/atc?page_size=${pageSize}&page_number=${pageNumber}&application_number=${applicationNumber}&active_ingredient=${activeIngredient}&atc_code=${atcCode}&qc_status=${qcStatus}&empty_atc=${emptyATC}&exact_search=${exactSearch}`,
    'GET'
  );

export const upsertEuaAtcData = payload => makeRequest('/eua/atc/qc', 'POST', payload);

export const getEmaATCList = (
  pageSize,
  pageNumber,
  applicationNumber,
  activeIngredient,
  atcCode,
  qcStatus,
  emptyATC,
  exactSearch
) =>
  makeRequest(
    `/ema/atc?page_size=${pageSize}&page_number=${pageNumber}&application_number=${applicationNumber}&active_ingredient=${activeIngredient}&atc_code=${atcCode}&qc_status=${qcStatus}&empty_atc=${emptyATC}&exact_search=${exactSearch}`,
    'GET'
  );

export const upsertEmaAtcData = payload => makeRequest('/ema/atc/qc', 'POST', payload);
