import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { Save } from '@mui/icons-material';
import { GetField } from './PMDAEditDialog';
import Actions from '../../../store/actions';
import { getPMDAProductHTMLData } from '../../../api/pmda';
import Store from '../../../store';
import Box from "@mui/material/Box";

const qcStatus = ['Escalate', 'Non Qced', 'Qced'];

const ProductData = ({ productData, setProductData, handleSubmit }: any) => {
  const { dispatch } = useContext<any>(Store);
  const [HTMLContent, setHTMLContent] = useState('');
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [localProductData, setLocalProductData] = useState<any>(productData);
  const handleHTML = async (link: string, packageInsertNumber: any) => {
    try {
      const res = await getPMDAProductHTMLData(link, packageInsertNumber);
      const resData = res.data;
      if (resData.error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: resData.message }
        });
      } else {
        setHTMLContent(
          resData.body.replaceAll('../imgs/', 'https://www.pmda.go.jp/PmdaSearch/imgs/')
        );
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  useEffect(() => {
    if (productData) {
      if (productData?.package_insert_number) {
        handleHTML(
          `https://www.pmda.go.jp/PmdaSearch/iyakuDetail/${productData?.package_insert_number}`,
          productData?.package_insert_number
        );
      } else {
        setOpenSubmitConfirmation(true);
      }
    }
  }, [productData]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} mb={3} mt={3}>
          <Box p={2} maxHeight='82vh' overflow='auto'>
            {Object.entries(localProductData).map(([key, value]) => (
              <GetField label={key} value={value} setLocalProductData={setLocalProductData} />
            ))}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id='is_sub_product'>Is sub product</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={localProductData?.is_sub_product ? 'yes' : 'no'}
                label='Is sub product'
                onChange={(e: SelectChangeEvent) => {
                  setLocalProductData((prev: any) => ({
                    ...prev,
                    is_sub_product: e.target.value === 'yes'
                  }));
                }}>
                <MenuItem value='yes'>Yes</MenuItem>
                <MenuItem value='no'>No</MenuItem>
              </Select>
            </FormControl>
            {localProductData?.is_sub_product && (
              <TextField
                variant='outlined'
                fullWidth
                id='parent_yj_code'
                label='Parent YJ Code'
                value={localProductData?.parent_yj_code}
                multiline
                sx={{ mb: 2 }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setLocalProductData((prev: any) => ({
                    ...prev,
                    parent_yj_code: e.target.value
                  }));
                }}
                InputProps={{
                  style: { fontSize: '2vh' }
                }}
                InputLabelProps={{
                  style: { fontSize: '2vh' }
                }}
              />
            )}
            <Autocomplete
              id='qc_status'
              fullWidth
              value={localProductData?.qc_status || 'Non Qced'}
              options={qcStatus}
              onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                if (newValue !== null) {
                  setLocalProductData((prev: any) => ({ ...prev, qc_status: newValue }));
                }
              }}
              sx={{ mb: 2 }}
              renderInput={(
                params: any // eslint-disable-next-line react/jsx-props-no-spreading
              ) => <TextField {...params} label='QC Status' variant='outlined' required />}
            />
          </Box>
          <Button
            variant='contained'
            sx={{
              mx: 2,
              width: '95%',
              color: 'white.main'
            }}
            onClick={async () => {
              await setProductData(localProductData);
              setOpenSubmitConfirmation(true);
            }}>
            Submit
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            border: '1px solid #000',
            '& #HeaderPopArea,#SearchHd,#FooterArea01,#FooterArea02,#FooterArea03': {
              display: 'none'
            },
            '& #ResultSet > div:first-child': {
              borderRight: '1px solid #000'
            },
            '& #ResultSet > div:nth-child(2)': {
              px: 2
            }
          }}>
          {!HTMLContent && (
            <Stack width='100%' height='40vh' justifyContent='center' alignItems='center'>
              <CircularProgress />
              <Typography>Loading...</Typography>
            </Stack>
          )}
          <div dangerouslySetInnerHTML={{ __html: HTMLContent }} />
        </Grid>
      </Grid>

      <Dialog
        maxWidth='lg'
        fullWidth
        open={openSubmitConfirmation}
        aria-labelledby='alert-dialog-title'
        disableEscapeKeyDown
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <div>PMDA URL</div>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to submit this data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              setOpenSubmitConfirmation(false);
            }}>
            No
          </Button>

          <Button
            startIcon={<Save />}
            variant='contained'
            onClick={async () => {
              await handleSubmit();
            }}
            color='secondary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default React.memo(ProductData);
