import React, { createContext, useReducer } from 'react';
import reducers from './reducers';

const initialState = {
  submissionSummary: [],
  indicationSummary: [],
  studySummary: [],
  qcedGuidanceCompare: {},
  nonQcedGuidanceCompare: {},
  isSubmitting: false
};

const Store = createContext(initialState);
const { Provider } = Store;

// eslint-disable-next-line react/prop-types
export const AdminStateProvider = ({ children }) => {
  const [adminState, adminDispatch] = useReducer(reducers, initialState);

  return <Provider value={{ adminState, adminDispatch }}>{children}</Provider>;
};

export default Store;
