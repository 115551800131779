import React, { useEffect, useState } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Skeleton from '@material-ui/lab/Skeleton';
import { TextareaAutosize } from '@material-ui/core';

import Styles from '../DataEntry/styles/Review.styles';

const INPUT_TYPE_TEXT = 'text';
const INPUT_TYPE_SELECT = 'select';
const FORM_INPUTS = [
  {
    id: 'pno',
    label: 'EU Number',
    placeholder: 'Enter EU Number',
    type: INPUT_TYPE_TEXT
  },
  {
    id: 'drugname',
    label: 'Invented name',
    placeholder: 'Enter Invented name',
    type: INPUT_TYPE_TEXT
  },
  {
    id: 'strength',
    label: 'Strength',
    placeholder: 'Enter Strength',
    type: INPUT_TYPE_TEXT
  },
  {
    id: 'form',
    label: 'Pharmaceutical Form',
    placeholder: 'Enter Form',
    type: INPUT_TYPE_TEXT
  },
  {
    id: 'route',
    label: 'Route of Administration',
    placeholder: 'Enter Route',
    type: INPUT_TYPE_TEXT
  },
  {
    id: 'packaging',
    label: 'Packaging',
    placeholder: 'Enter Packaging',
    type: INPUT_TYPE_TEXT
  },
  {
    id: 'concentration',
    label: 'Concentration',
    placeholder: 'Enter Concentration',
    type: INPUT_TYPE_TEXT
  },
  {
    id: 'packsize',
    label: 'Packsize',
    placeholder: 'Enter Packsize',
    type: INPUT_TYPE_TEXT
  },
  // {
  //   id: "activeingredient",
  //   label: "Active Ingredient",
  //   placeholder: "Enter Active Ingredient",
  //   type: INPUT_TYPE_TEXT
  // },
  {
    id: 'is_active',
    label: 'Is Active',
    placeholder: 'Select Status',
    type: INPUT_TYPE_SELECT,
    options: [
      {
        label: 'Active',
        value: true
      },
      {
        label: 'Inactive',
        value: false
      }
    ]
  },
  {
    id: 'indication_status',
    label: 'Status',
    placeholder: 'Select Status',
    type: INPUT_TYPE_SELECT,
    options: [
      {
        label: 'Qced',
        value: 'Qced'
      },
      {
        label: 'To Escalate',
        value: 'To Escalate'
      },
      {
        label: 'New Document',
        value: 'New Document'
      }
    ]
  }
];
export const FLOW_TYPE_ADD = 'add';
export const FLOW_TYPE_EDIT = 'edit';
export const EMAProductForm = ({
  data,
  onCancel,
  onSubmit,
  isSubmitDisabled,
  isLoading = false,
  flowType = FLOW_TYPE_ADD
}) => {
  const classes = Styles();
  const [formData, setFormData] = useState({
    pno: '',
    concentration: '',
    drugname: '',
    form: '',
    indication_status: '',
    packaging: '',
    packsize: '',
    strength: '',
    is_active: true
  });

  useEffect(() => {
    if (flowType === FLOW_TYPE_EDIT) {
      setFormData({
        ...data
      });
    }
  }, [flowType, data]);

  const updateFormData = (formKey, value) => {
    setFormData({
      ...formData,
      [formKey]: value
    });
  };
  const handleSubmit = () => {
    onSubmit(formData);
  };
  return (
    <Box padding={1}>
      <Box display='flex' flexDirection='column' justifyContent='space-between'>
        {FORM_INPUTS.map(input => {
          const { id, label, type, options, placeholder } = input;
          return (
            <>
              <Typography align='left' className={classes.label} key={id}>
                {label}
              </Typography>
              {isLoading ? (
                <Skeleton variant='rect' width={500} height={20} />
              ) : type === INPUT_TYPE_TEXT ? (
                <TextareaAutosize
                  padding={1}
                  className={classes.textAreaField}
                  minRows={2}
                  fullWidth
                  value={formData[id]}
                  onChange={event => updateFormData(id, event.target.value)}
                  id={id}
                  placeholder={placeholder}
                  InputProps={{
                    classes: { input: classes.input },
                    disableUnderline: true
                  }}
                />
              ) : type === INPUT_TYPE_SELECT ? (
                <Select
                  id={id}
                  variant='standard'
                  disableUnderline
                  required
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left'
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left'
                    },
                    getContentAnchorEl: null
                  }}
                  value={formData[id]}
                  onChange={event => updateFormData(id, event.target.value)}
                  className={classes.dropdownContainer}
                  inputProps={{
                    classes: {
                      icon: classes.icon
                    }
                  }}>
                  {options.map(option => (
                    <MenuItem value={option.value} className={classes.dropdownText}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              ) : null}
            </>
          );
        })}
      </Box>
      <Box padding={1} display='flex' flexDirection='row'>
        <Button className={classes.button} onClick={onCancel} variant='contained'>
          Cancel
        </Button>
        <Button className={classes.button} onClick={handleSubmit} variant='contained'>
          Submit
        </Button>
      </Box>
    </Box>
  );
};
