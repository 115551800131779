import { makeStyles } from '@material-ui/styles';

export default makeStyles(theme => ({
  root: {
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    padding: '30px'
  },
  typography: {
    padding: 8,
    fontSize: '1.8vh'
  },

  Heading: {
    color: '#3AB09E',
    fontSize: '2.5vh',
    textAlign: 'left',
    fontWeight: '600'
  },

  Button: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    height: '100vh',
    overflow: 'auto',
    paddingTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white
  },
  dialog: {
    background: theme.palette.white
  },
  pdfContainer: {
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      minHeight: '80vh'
    }
  },
  details: {
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    },
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },
  label: {
    fontSize: theme.spacing(2),
    fontWeight: 'bold',
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2.5)
    }
  },
  reviewBox: {
    marginTop: theme.spacing(1),
    width: '100%',
    borderRadius: theme.spacing(1),
    border: '2px black grey'
  },
  dropdownContainer: {
    marginTop: theme.spacing(0.5),
    height: theme.spacing(6),
    // width: '40%',
    display: 'flex',
    justifyContent: 'flex-start',
    // color: theme.palette.white,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    // '& 	.MuiSelect-select': {
    //   color: 'white'
    // }
  }
}));
