import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
// api
import { getOneApp, getOneMeeting } from '../../../api/Adcomm';
// Styles
import Styles from '../AdcommAdminMeetingReview/styles/AdcommAdminMeetingReviewStyles';
// components
import EditApplicationFields from './EditApplicationFields';
import NonEditApplicationFields from './NonEditApplicationFields';
import MeetingAgenda from './MeetingAgenda';

export default function AdCommApplicationReview() {
  const classes = Styles();
  const [adminStatus, setAdminStatus] = useState(false);
  const [application, setApplication] = useState({});
  const [agenda, setAgenda] = useState();
  const [editMode, setEditMode] = useState(false);
  const [status, setStatus] = useState(application.status || '');
  const [webLink, setWebLink] = useState('');
  const [qcComplete, setQcComplete] = useState('');
  const { meetingId, applicationPrimaryKey } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchMeeting = async meetingId => {
      try {
        const user = await Auth.currentUserInfo();
        if (user.attributes['custom:role'] === 'Admin') {
          setAdminStatus(true);
        }
        if (applicationPrimaryKey !== 'newApplication' && applicationPrimaryKey !== 'undefined') {
          // get application details for one meeting
          let applRes = await getOneApp('orig', applicationPrimaryKey);
          // if the application has already been qcd, then get info from qcd table
          if (applRes.data.Success.qc_complete) {
            applRes = await getOneApp('qcd', applicationPrimaryKey);
          }
          // get list of applications for one meeting
          setApplication(applRes.data.Success);
        }
        try {
          let meetingRes = await getOneMeeting(meetingId, 'orig');
          setWebLink(meetingRes.data.Success.meeting_link);

          // if the meeting has already been qcd, then meetingRes data comes from the qcd table
          if (meetingRes.data.Success.qc_complete) {
            meetingRes = await getOneMeeting(meetingId, 'qcd');
          }
          setAgenda(meetingRes.data.Success.agenda);
        } catch (e) {
          console.error(e);
        }
      } catch (e) {
        console.error(e);
      }
    };
    fetchMeeting(meetingId);
  }, [meetingId, qcComplete, adminStatus]);

  const handleGoBack = e => {
    history.push(`/adcomm/${meetingId}`);
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg'>
        <Box display='flex' padding={1} flexDirection='row' justifyContent='flex-start'>
          <Button onClick={handleGoBack} className={classes.button}>
            Back
          </Button>
          {editMode ? (
            <Button
              className={classes.buttonCancel}
              onClick={() => setEditMode(false)}
              variant='contained'>
              Cancel
            </Button>
          ) : application.status && !adminStatus ? (
            <Button className={classes.button} disabled>
              Edit
            </Button>
          ) : (
            <Button
              className={classes.button}
              onClick={() => setEditMode(true)}
              variant='contained'>
              Edit
            </Button>
          )}
          <Link target='_blank' href={webLink} className={classes.link} justifyself='flex-end'>
            Access link for webpage if agenda is not available below
          </Link>
        </Box>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Box display='flex' flexDirection='column'>
              {editMode ? (
                <EditApplicationFields
                  classes={classes}
                  application={application && application}
                  setEditMode={setEditMode}
                  setApplication={setApplication}
                  qcComplete={qcComplete}
                  setQcComplete={setQcComplete}
                  setStatus={setStatus}
                  status={status}
                  adminStatus={adminStatus}
                />
              ) : (
                <NonEditApplicationFields
                  application={application && application}
                  classes={classes}
                  status={status}
                />
              )}
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              className={classes.sticky}
              display='flex'
              justifyContent='flex-start'
              alignItems='flex-start'
              styles={{ border: '2px solid red', margin: '20px', background: 'white' }}>
              <MeetingAgenda
                webLink={webLink && webLink}
                agenda={agenda ? agenda : ''}
                classes={classes}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
