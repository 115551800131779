import { useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Box, FormControlLabel, Switch } from '@mui/material';

const StatsPieChart = ({ data, pieClickCallBack }: any) => {
  const threshold = 0.007;
  const total = data.reduce((acc: any, d: { value: number }) => acc + d.value, 0);
  const sum = data.reduce(
    (acc: any, d: { value: number }) => (d.value / total < threshold ? acc + d.value : acc),
    0
  );
  const [combineOthers, setCombineOthers] = useState(true);

  const groupedData = [
    ...data.filter((d: { value: number }) => d.value / total >= threshold),
    ...(sum > 0 ? [{ id: 'Other (Combined)', value: sum }] : [])
  ];

  return (
    <Box width='100%' height='100%'>
      {sum > 0 && (
        <Box>
          <FormControlLabel
            control={
              <Switch
                checked={combineOthers}
                onChange={event => setCombineOthers(event.target.checked)}
                inputProps={{ 'aria-label': 'Combine insignificant data' }}
              />
            }
            label='Combine insignificant data'
          />
        </Box>
      )}
      <Box width='100%' height='100%'>
        <ResponsivePie
          data={combineOthers ? groupedData : data}
          valueFormat=' >-d'
          margin={{ top: 20, right: 100, bottom: 50, left: 127 }}
          innerRadius={0.7}
          padAngle={0.7}
          cornerRadius={0}
          startAngle={-50}
          colors={['#6868FA', '#EA5252', '#29ABE2', '#0A467C', '#C9D2F6', '#B1BBE4', '#48E0A6']}
          sortByValue
          borderWidth={1}
          borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
          arcLabelsTextColor='#fff'
          onMouseEnter={(_datum, event) => {
            if (pieClickCallBack !== null)
              // eslint-disable-next-line no-param-reassign
              event.currentTarget.style.cursor = 'pointer';
          }}
          theme={{
            axis: {
              ticks: {
                line: {
                  stroke: '#555555'
                },
                text: {
                  fill: '#707070',
                  fontWeight: 'bold',
                  fontSize: 14
                }
              }
            }
          }}
          legends={[
            {
              anchor: 'top-right',
              direction: 'column',
              itemHeight: 20,
              itemWidth: 80,
              toggleSerie: true,
              translateX: 0,
              translateY: 0,
              itemsSpacing: 5,
              symbolSize: 20,
              symbolShape: 'circle',
              itemOpacity: 0.85,
              effects: [
                {
                  on: 'hover',
                  style: {
                    itemOpacity: 1
                  }
                }
              ]
            }
          ]}
          onClick={pieClickedData =>
            pieClickedData.id === 'Other (Combined)'
              ? null
              : pieClickCallBack && pieClickCallBack(pieClickedData)
          }
        />
      </Box>
    </Box>
  );
};

export default StatsPieChart;
