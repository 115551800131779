import UserSurvey from '../components/UserSurvey';

const USER_SURVEY_ROUTES = [
  {
    id: 'user_survey',
    path: '/user_survey',
    component: UserSurvey,
    exact: true
  }
];

export default USER_SURVEY_ROUTES;
