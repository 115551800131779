import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    background: theme.palette.backgrounds.dark,
    height: '100%',
    '& .MuiDataGrid-columnHeaderTitle': {
      color: '#d0d5eb',
      fontSize: '16px',
      fontWeight: 'bold',
      padding: '25px'
    },
    '& .MuiTablePagination-toolbar':{
      color: '#d0d5eb'
    }
  },
  grid: {
    minHeight: '70vh'
  },
  banner: {
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(2),
    background: theme.palette.backgrounds.light
  },
  formHeadings: {
    textAlign: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    color: theme.palette.buttons.purple
  },
  label: {
    padding: theme.spacing(2),
    fontSize: theme.spacing(2),
    color: theme.palette.review.label,
    fontWeight: 'bold'
  },
  textfield: {
    width:'90%',
    marginTop: theme.spacing(0.5),
    height: theme.spacing(6),
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    color: theme.palette.white,
    padding: theme.spacing(1),
    '&::placeholder': {
      color: theme.palette.white,
      padding: theme.spacing(1)
    }
  },
  button: {
    margin: theme.spacing(2),
    background: theme.palette.buttons.purple,
    fontFamily: 'Roboto',
    color: theme.palette.white,
    width: theme.spacing(25),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
  overflowButton: {
    overflow: 'hidden',
    textOverflow:'ellipsis',
    whiteSpace:'nowrap',
    margin: theme.spacing(2),
    background: theme.palette.buttons.purple,
    fontFamily: 'Roboto',
    color: theme.palette.white,
    width: theme.spacing(39),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
  container: {
    width:'100%',
    paddingTop: theme.spacing(10)
  },
  pdfContainer: {
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      minHeight: '80vh'
    }
  },
  pdf:{
    margin: theme.spacing(2),
    height: '80vh',
    width: '50vw',
    background: theme.palette.backgrounds.light,
    borderRadius: theme.spacing(1.5)
  },
  notAvailable: {
    color: theme.palette.white,
    fontSize: theme.spacing(3),
    fontWeight: 'bold'
  },
  paragraphTextfield: {
    width:'90%',
    marginTop: theme.spacing(0.5),
    height: 'auto',
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5)
  },
  dropdownContainer: {
    marginTop: theme.spacing(0.5),
    height: theme.spacing(6),
    background: theme.palette.backgrounds.dark,
    width: '90%',
    color: theme.palette.white,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1.5)
  },
  icon: {
    color: theme.palette.white
  },
  autocomplete: {
    width:'90%',
    height: theme.spacing(6),
    color: `${theme.palette.white} !important`,
    marginTop: theme.spacing(0.5),
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5),
    padding: theme.spacing(1.5),
    '& .MuiAutocomplete-clearIndicator': {
      color: 'white',
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  inputDisabled: {
    '&:disabled': {
      color: `${theme.palette.white} !important`,
      '-webkit-text-fill-color': `##ffffff7d !important`
    }
  },
  filterText: {
    width:'250px',
    height: theme.spacing(6.3),
    color: `${theme.palette.white} !important`,
    marginRight: theme.spacing(2.5),
    marginBottom:theme.spacing(0.7),
    border: '1px solid white', 
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    '& .MuiAutocomplete-clearIndicator': {
      color: 'white',
    },
    '& .MuiSvgIcon-root': {
      color: 'white',
    },
  },
  dropdownPaper: {
    color: 'white',
    background: theme.palette.backgrounds.light
  },
  details: {
    color: '#6f7594',
    padding: theme.spacing(2),
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  label: {
    padding: theme.spacing(2),
    fontSize: theme.spacing(2),
    color: theme.palette.review.label,
    fontWeight: 'bold'
  },
  dialogLabel:{
    background: theme.palette.backgrounds.light,
    marginTop: '5px',
    padding: '12px 10px',
    borderRadius: 8,
  },
  dialog:{
    background: theme.palette.backgrounds.dark,
  },
}));
