import makeRequest from './client';

const baseURL = `/user_notifications/stats`;

const getSummary = (timePeriod: string, timeZone: string, startDate: string, endDate: string) => {
  const finalURL = `${baseURL}/summary?time_period=${timePeriod}&time_zone=${timeZone}&start_date=${startDate}&end_date=${endDate}`;
  return makeRequest(finalURL, 'GET');
};

export default getSummary;
