import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    height: '100%',
    background: theme.palette.backgrounds.dark,
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  root2: {
    minHeight: '100vh',
    height: '100%', // background: theme.palette.backgrounds.dark,
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  leftPart: {
    height: '100vh',
    width: '50%', // background: 'green',
    marginRight: '1vh'
  },
  leftBox: {
    height: '80vh',
    width: '70%'
    // background: 'yellow',
  },
  rightPart: {
    height: '100vh',
    width: '50%', // background: 'red',
    marginLeft: '1vh'
  },
  rightBox: {
    height: '80vh',
    width: '70%'
    // background: 'yellow',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: '25vh'
  },
  dataGridBox: {
    height: '50vh',
    color: 'white',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    },
    '& .MuiDataGrid-root': {
      fontSize: '1.8vh',
      fontWeight: 'normal',
      height: 'inherit',
      color: theme.palette.datagrid.dataText,
      backgroundColor: theme.palette.white,
      border: 'unset',
      '& .MuiDataGrid-columnsContainer': {
        color: theme.palette.primary.lighter,
        backgroundColor: theme.palette.regulatory.lighterGreen
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize: '2.2vh',
        padding: '0 30px'
      },
      '& .MuiDataGrid-columnHeaderTitle': {
        fontWeight: 'bold'
      },
      '& .MuiDataGrid-cell': {
        padding: '0 30px'
      },
      '& .MuiDataGrid-renderingZone': {
        backgroundColor: theme.palette.white
      },
      '& .MuiDataGrid-footer': {
        backgroundColor: theme.palette.regulatory.lighterGreen
      }
    }
  },
  textField: {
    margin: theme.spacing(1)
  },
  container: {
    paddingTop: theme.spacing(10)
  },
  grid: {
    minHeight: '70vh'
  },
  reviewBox: {
    background: theme.palette.backgrounds.light,
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1.5)
  },
  heading: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.white,
    fontSize: theme.spacing(3),
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  heading2: {
    color: theme.palette.white,
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  label: {
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(2),
    color: theme.palette.review.label,
    fontWeight: 'bold',
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2.5)
    }
  },
  jointBox: {
    width: '100%'
  },
  addBtn: {
    marginTop: theme.spacing(2),
    background: theme.palette.review.label,
    height: theme.spacing(4),
    borderRadius: theme.spacing(1.5)
  },
  details: {
    color: '#6f7594',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  textFieldSpacing: {
    padding: '.5em'
  },
  textfield: {
    marginTop: theme.spacing(0.5),
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5),
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    }
  },
  radio: {
    color: `${theme.palette.white} !important`,
    marginTop: theme.spacing(0.5),
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    },
    '& .Mui-disabled': {
      color: '#ffffff7d !important',
      '-webkit-text-fill-color': '#ffffff7d !important'
    },
    '& .MuiRadio-colorPrimary': {
      color: `${theme.palette.white} !important`
    }
  },
  autocomplete: {
    color: `${theme.palette.white} !important`,
    marginTop: theme.spacing(0.5),
    background: theme.palette.backgrounds.dark,
    borderRadius: `${theme.spacing(1.5)} !important`,
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    },
    '& .Mui-disabled': {
      color: '#ffffff7d !important',
      '-webkit-text-fill-color': '#ffffff7d !important'
    }
  },
  inputDisabled: {
    '&:disabled': {
      color: `${theme.palette.white} !important`,
      '-webkit-text-fill-color': `##ffffff7d !important`
    }
  },
  autocompleteChip: {
    border: '1px solid black !important',
    backgroundColor: `${theme.palette.backgrounds.light} !important`,
    color: `${theme.palette.white} !important`
  },
  labelName: {
    color: theme.palette.white,
    textOverflow: 'wrap',
    textTransform: 'capitalize'
  },
  textAreaField: {
    color: theme.palette.white,
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5),
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  '::-webkit-calendar-picker-indicator': {
    filter: 'invert(1)'
  },
  credit: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  crdates: {
    color: theme.palette.white,
    fontSize: theme.spacing(2)
  },
  crLabel: {
    color: theme.palette.review.label,
    fontSize: theme.spacing(1.5)
  },
  textarea: {
    marginTop: theme.spacing(0.5),
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5),
    color: `${theme.palette.white} !important`,
    '& .Mui-disabled': {
      color: '#ffffff7d !important',
      '-webkit-text-fill-color': '#ffffff7d !important'
    },
    '& .MuiInputBase-root': {
      color: `${theme.palette.white} !important`
    }
  },
  inputNew: {
    color: `${theme.palette.white} !important`,
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    '&::placeholder': {
      color: theme.palette.white,
      fontSize: theme.spacing(1)
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  input: {
    color: `${theme.palette.white} !important`,
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    '&::placeholder': {
      color: theme.palette.white,
      fontSize: theme.spacing(1)
    },
    '& .MuiInputBase-root.Mui-disabled': {
      '-webkit-text-fill-color': '#ffffff7d !important'
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  loadingBar: {
    background: theme.palette.buttons.purple,
    color: theme.palette.white
  },
  button: {
    background: theme.palette.buttons.purple,
    backgroundColor: theme.palette.buttons.purple,
    color: theme.palette.white,
    width: theme.spacing(25),
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:disabled': {
      opacity: 0.4,
      background: theme.palette.buttons.purple,
      color: theme.palette.white,
      zIndex: 0
    },
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
  buttonLoading: {
    background: theme.palette.buttons.purple,
    backgroundColor: theme.palette.buttons.purple,
    color: theme.palette.white,
    width: theme.spacing(25),
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:disabled': {
      opacity: 0.4,
      background: theme.palette.buttons.purple,
      color: theme.palette.white,
      zIndex: 0
    },
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
  dialog: {
    background: theme.palette.white
  },
  confirm: {
    background: theme.palette.buttons.purple,
    color: theme.palette.white,
    fontWeight: 'bold',
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
  dropdownContainer: {
    marginTop: theme.spacing(0.5),
    height: theme.spacing(4),
    background: theme.palette.backgrounds.dark,
    width: '100%',
    color: theme.palette.white,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  icon: {
    color: theme.palette.white
  },
  dropdownText: {
    color: theme.palette.adminHome.primaryBlack,
    fontWeight: 'bold'
  },
  formSection: {
    overflowY: 'auto',
    padding: '1em 0'
  },
  pdfContainer: {
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1.5),
    background: theme.palette.backgrounds.light,
    [theme.breakpoints.down('sm')]: {
      minHeight: '80vh'
    }
  },
  pdf: {
    height: '80vh',
    width: '40vw',
    borderRadius: theme.spacing(1.5)
  },
  pdfEma: {
    minHeight: '75vh',
    borderRadius: theme.spacing(1.5)
  },
  driveIframe: {
    width: '100%',
    height: '80vh'
  },
  notAvailable: {
    color: theme.palette.white,
    fontSize: theme.spacing(3),
    fontWeight: 'bold'
  },
  note: {
    color: theme.palette.white,
    width: '80%',
    margin: '0 auto'
  },
  showDropdown: {
    display: 'inline',
    color: theme.palette.white
  },
  hideDropdown: {
    display: 'none'
  },
  dropdownList: {
    color: theme.palette.white,
    backgroundColor: theme.palette.white,
    marginTop: theme.spacing(-1),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(1),
    borderRadius: theme.spacing(2),
    padding: theme.spacing(0)
  },
  dropdownItems: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.primaryGray,
    fontWeight: 'bold'
  }
}));
