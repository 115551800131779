// eslint-disable-next-line import/prefer-default-export
export const PmrcSectionOptions = [
  'ACCELERATED APPROVAL REQUIREMENTS',
  'REQUIRED PEDIATRIC ASSESSMENTS',
  'POSTMARKETING REQUIREMENTS UNDER 505(o)(3)',
  'POSTMARKETING COMMITMENTS SUBJECT TO REPORTING REQUIREMENTS UNDER SECTION 506B',
  'POSTMARKETING COMMITMENTS NOT SUBJECT TO REPORTING REQUIREMENTS UNDER SECTION 506B',
  'SUBPART I APPROVAL REQUIREMENTS',
  'FULFILLMENT OF POSTMARKETING REQUIREMENT/COMMITTMENT',
  'RELEASE FROM POSTMARKETING REQUIREMENT/COMMITTMENT',
  'N/A'
];

export const SourceColumnMapping: any = {
  cder: [
    {
      field: 'applno',
      headerName: 'Application Number',
      width: 300,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'appltype',
      headerName: 'Application Type',
      width: 150,
      headerAlign: 'center',
      align: 'center'
    }
  ],
  cber: [
    {
      field: 'application_number',
      headerName: 'Application Number',
      width: 300,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'application_type',
      headerName: 'Application Type',
      width: 150,
      headerAlign: 'center',
      align: 'center'
    }
  ],
  eua: [
    {
      field: 'appl_no',
      headerName: 'Application Number',
      width: 300,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'appl_type',
      headerName: 'Application Type',
      width: 150,
      headerAlign: 'center',
      align: 'center'
    }
  ],
  ema: [
    {
      field: 'product_number',
      headerName: 'Application Number',
      width: 350,
      headerAlign: 'center',
      align: 'center'
    }
  ]
};
