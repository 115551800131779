import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import { debounce } from 'lodash';

// mui
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, Skeleton, Divider, Dialog, DialogActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// api
import { saveOrSubmitMeetingDetails, getMeetingIdSuggestion } from '../../api/AdcommModule';

// Store
import Actions from '../../store/actions';
import Store from '../../store';

// utils
import PDFViewer from '../../utils/PDFViewer';

// styles
import { styles } from './styles/applicationCuration';

const JointMeetingIdsForm = ({ closeEdit, meetingData }: any) => {
  const [pdfUrl, setpdfUrl] = useState<Array<any>>([]);
  const [openConfimationDialog, setOpenConfimationDialog] = useState<boolean>(false);
  const { dispatch } = useContext<any>(Store);
  const [meetingList, setMeetingList] = useState<Array<string>>([]);
  const [jointMeeting, setJointMeeting] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedS3Link, setSelectedS3Link] = useState<any>({});

  useEffect(() => {
    const pdfLink: Array<any> = [
      {
        document_title: 'agenda',
        label: 'Agenda',
        value: meetingData.agenda
      },
      {
        document_title: 'meeting',
        label: 'Meeting',
        meeting_link: meetingData.meeting_link
      }
    ];
    setJointMeeting(meetingData?.joint_meeting_ids);
    setpdfUrl(pdfLink);
  }, [meetingData]);

  const handleOpenPopup = useCallback((url: string) => {
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow: any = window.open(url, '_blank', option);

    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
      }
    }, 1000);
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: any) => {
    if (newValue !== null) {
      if (JSON.stringify(newValue) !== JSON.stringify(jointMeeting)) {
        setJointMeeting(newValue);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (Object.keys(meetingData).length !== 0) {
        const user = await Auth.currentUserInfo();
        const payload = {
          ...meetingData,
          joint_meeting_ids: jointMeeting,
          curator_email: user.attributes.email,
          curator_name: user.attributes['custom:user']
        };
        await saveOrSubmitMeetingDetails(payload, 'submit');
      }
    } catch (error: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
      setLoading(false);
    } finally {
      closeEdit();
      setLoading(false);
    }
  };

  const debouncedMeetingIdChangeHandler = async (newValue: string) => {
    try {
      if (newValue) {
        const response = await getMeetingIdSuggestion('meeting', 'meeting_id', newValue);
        if (response.data.status === 200) {
          setMeetingList(response.data.body.suggestions);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleMeetingIdFilters = debounce(debouncedMeetingIdChangeHandler, 300);

  return (
    <div style={{ maxHeight: '100vh', overflow: 'hidden' }}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography
          component='h2'
          variant='h5'
          style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
          Add Joint Meeting Id
        </Typography>

        <IconButton
          aria-label='close'
          onClick={() => {
            closeEdit();
          }}
          sx={{
            position: 'absolute',
            right: 5,
            top: 2,
            color: 'gray.light'
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!loading ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='space-around'
              style={{ maxHeight: '75vh', padding: '10px 10px' }}>
              <Box
                style={{
                  width: '50%',
                  overflowY: 'auto',
                  maxHeight: '75vh',
                  padding: '4px'
                }}>
                <Box>
                  <Autocomplete
                    fullWidth
                    value={jointMeeting || []}
                    freeSolo
                    multiple
                    onInputChange={(event, newValue) => {
                      handleMeetingIdFilters(newValue || '');
                    }}
                    options={meetingList || []}
                    onChange={handleChange}
                    sx={{ fontSize: '2vh', mt: '60px' }}
                    //   ChipProps={styles.autocompleteChip}
                    renderInput={(params: any) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label='Meeting Id'
                        variant='outlined'
                        required
                        InputLabelProps={{
                          style: { fontSize: '2vh', minHeight: '25px', lineHeight: 1 }
                        }}
                      />
                    )}
                  />
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Button
                      fullWidth
                      variant='contained'
                      color='primary'
                      onClick={() => {
                        setOpenConfimationDialog(true);
                      }}
                      style={{ color: 'white', marginTop: '25px', width: 'fit-content' }}>
                      SUBMIT
                    </Button>
                  </Box>
                </Box>
              </Box>
              <Divider orientation='vertical' flexItem />
              <Box
                style={{
                  width: '50%',
                  overflowY: 'auto',
                  maxHeight: '75vh',
                  padding: '4px'
                }}>
                <Box display='flex' alignItems='center' justifyContent='center'>
                  <Button
                    variant='contained'
                    sx={styles.Button}
                    disabled={!(selectedS3Link.s3_url || selectedS3Link.meeting_link)}
                    onClick={() => {
                      handleOpenPopup(selectedS3Link.s3_url || selectedS3Link.meeting_link);
                    }}>
                    <Typography>Open As a Pop-Up window</Typography>
                  </Button>
                </Box>
                <PDFViewer pdf={pdfUrl} setSelectedS3Link={setSelectedS3Link} />
              </Box>
            </Box>
          </>
        ) : (
          <Box display='flex' maxHeight='100vh' sx={{ overflow: 'hidden' }}>
            <Skeleton variant='rectangular' height='100vh' width='100%' />
          </Box>
        )}
      </DialogContent>
      <Dialog fullWidth maxWidth='xs' open={openConfimationDialog}>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography style={{ color: '#3AB09E', fontSize: '20px', fontWeight: 'bold' }}>
              Confirm submission
            </Typography>
            <IconButton aria-label='close' onClick={() => setOpenConfimationDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ fontSize: '16px', color: 'GrayText', marginRight: '0' }}>
            Are you sure you have edited the document accurately?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={() => {
              handleSubmit();
              setOpenConfimationDialog(false);
            }}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            Yes
          </Button>
          <Button
            variant='contained'
            onClick={() => setOpenConfimationDialog(false)}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default React.memo(JointMeetingIdsForm);
