export const qcStatusMap = (key: string) => {
  const map: any = {
    qced: 'QCed',
    to_qc: 'To QC',
    escalate: 'Escalate'
  };
  return map[key];
};

export const Fields = [
  {
    id: 'name_of_the_medicinal_product',
    labelName: 'Name of Medicinal Product'
  },
  {
    id: 'qualitative_and_quantitative_composition',
    labelName: 'Qualitative and Quantitative Composition'
  },
  {
    id: 'pharmaceutical_form',
    labelName: 'Pharmaceutical Form'
  },
  {
    id: 'therapeutic_indications',
    labelName: 'Therapeutic Indications'
  },
  {
    id: 'posology_and_method_of_administration',
    labelName: 'Posology and Method of Administration'
  },
  {
    id: 'contraindications',
    labelName: 'Contraindications'
  },
  {
    id: 'special_warnings_and_precautions_for_use',
    labelName: 'Warnings and Precautions'
  },
  {
    id: 'interaction_with_other_medicinal_products_and_other_forms_of_in',
    labelName: 'Interaction with Other Medicinal Products'
  },
  {
    id: 'fertility_pregnancy_and_lactation',
    labelName: 'Fertility, Pregnancy and Lactation'
  },
  {
    id: 'effects_on_ability_to_drive_and_use_machines',
    labelName: 'Effects on Driving and Machine Usage'
  },
  {
    id: 'undesirable_effects',
    labelName: 'Undesirable Effects'
  },
  {
    id: 'overdose',
    labelName: 'Overdosage'
  },
  {
    id: 'pharmacodynamic_properties',
    labelName: 'Pharmacodynamic Properties'
  },
  {
    id: 'pharmacokinetic_properties',
    labelName: 'Pharmacokinectic Properties'
  },
  {
    id: 'preclinical_safety_data',
    labelName: 'Preclinical Safety Data'
  },
  {
    id: 'pharmaceutical_particulars',
    labelName: 'Pharmaceutical Particulars'
  },
  {
    id: 'marketing_authorisation_holder',
    labelName: 'Marketing Authorisation Holder'
  },
  {
    id: 'marketing_authorisation_number',
    labelName: 'Marketing Authorisation Number'
  },
  {
    id: 'date_of_first_authorisation_renewal_of_the_authorisation',
    labelName: 'Date of First Renewal'
  },
  {
    id: 'date_of_revision_of_the_text',
    labelName: 'Date of revision of Text'
  },
  {
    id: 'annex_ii',
    labelName: 'Annexure II'
  },
  {
    id: 'annex_iii',
    labelName: 'Annexure III'
  }
];
