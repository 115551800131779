import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';
import PropTypes from 'prop-types';

// material ui
import { Box, Button, IconButton, Link, Paper, Typography } from '@material-ui/core';

// context
import Store from '../../../store';
import Actions from '../../../store/actions';

// components
import LoginTextfield from '../../../components/StyledComponents/TextFieldLogin';

// styles
import Styles from '../styles/Login.styles';

// assets
import VivproLogo from '../../../assets/svgs/Login/loginLogo';
import User from '../../../assets/svgs/Login/user';
import Code from '../../../assets/svgs/Login/code';

const NewUserPassword = ({ newUser, setNewUser }) => {
  const classes = Styles();
  const { dispatch } = useContext(Store);
  const [username, setUsername] = useState(newUser.challengeParam.userAttributes.email);
  const [newPassword, setNewPassword] = useState();

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await Auth.completeNewPassword(newUser, newPassword);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Password Reset Successfully', color: 'success' }
      });
      await Auth.signOut();
      setNewUser(undefined);
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `${error.message}` }
      });
    }
  };

  return (
    <Box
      className={classes.outline}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'>
      <Box
        className={classes.header}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'>
        <VivproLogo />
        <Typography className={classes.title} variant='h5'>
          Hello New User!
        </Typography>
        <Typography className={classes.description}>
          Enter your Vivpro Email and a new Password to continue
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Box
          className={classes.form}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'>
          <Paper component='form' className={classes.paper} elevation={0}>
            <IconButton className={classes.textfieldIcon} disabled>
              <User />
            </IconButton>
            <LoginTextfield
              InputProps={{ disableUnderline: true }}
              placeholder='Email Address'
              id='email'
              disabled
              value={username}
              name='email'
              onChange={event => setUsername(event.target.value)}
            />
          </Paper>
          <Paper component='form' className={classes.paper} elevation={0}>
            <IconButton className={classes.textfieldIcon} disabled>
              <Code />
            </IconButton>
            <LoginTextfield
              InputProps={{ disableUnderline: true }}
              name='password'
              placeholder='New Password'
              type='password'
              id='password'
              value={newPassword}
              onChange={event => setNewPassword(event.target.value)}
            />
          </Paper>
          <Button className={classes.button} type='submit' variant='contained'>
            Reset Password
          </Button>
        </Box>
      </form>
      <Box
        className={classes.footer}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'>
        <Link href='/' className={classes.link}>
          Go back to Login
        </Link>
      </Box>
    </Box>
  );
};

NewUserPassword.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  newUser: PropTypes.object.isRequired,
  setNewUser: PropTypes.func.isRequired
};

export default NewUserPassword;
