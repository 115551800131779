import React, { useContext, useEffect, useState } from "react";
import themev5 from "../../themev5";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { getPMDAHTMLData, getTranslationList, sendConverterData } from "../../api/pmda";
import Actions from "../../store/actions";
import Store from "../../store";
import Stack from "@mui/material/Stack";
import { CloudUpload, ViewComfyAlt } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { DataGrid } from "@material-ui/data-grid";
import { chunk } from "lodash";
import { Auth } from "aws-amplify";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1
});
const AutoLabelTranslation = () => {
  const { dispatch } = useContext<any>(Store);
  const [errorHistory, setErrorHistory] = useState<any>([]);
  const [notTranslatedData, setNotTranslatedData] = useState<any>(null);
  const [fetchedData, setFetchedData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const [fetching, setFetching] = useState<any>(false);
  const [user, setUser] = useState<any>({});
  const [showGroups, setShowGroups] = useState<any>(false);

  const handleNotTranslatedUpload = async (e: any) => {
    try {
      const file = e.target.files[0];
      // if filename does not end with curators_list.json throw error
      if (!file.name.endsWith("curators_list.json")) {
        throw new Error("Invalid File Type");
      }
      // read file which is .json
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = async () => {
        // parse file to json
        const jsonData = JSON.parse(reader.result as any);
        const newDoneList: any = [];
        jsonData.forEach((item: any) => {
          if (item.package_insert_number !== null) {
            const fetchedDataItem = fetchedData.find((i: any) => i.package_insert_number === item.package_insert_number);
            if (fetchedDataItem) {
              newDoneList.push({
                "id": item.package_insert_number, ...fetchedDataItem, "translated": false, "message": "Not Translated"
              });
            }
            else {
              newDoneList.push({
                "id": item.package_insert_number, ...item, "translated": true, "message": "Already Translated"
              });
            }
          }
        });
        // check if any id is null
        if (newDoneList.some((item: any) => item.id === null)) {
          throw new Error("Invalid Data");
        }
        setNotTranslatedData(newDoneList);

      };
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT, value: { status: true, message: e.message }
      });
    }
  };
  const handleHTML = async (packageInsertNumber: any, activeIngredientId: any, yjCode: any) => {
    try {
      setLoading(true);
      const res = await getPMDAHTMLData(packageInsertNumber, activeIngredientId, yjCode);
      const resData = res.data;
      if (resData.error) {
        if (resData.code === 404) {
          setErrorHistory((prev: any) => [...prev, `${resData.message} - 404`]);
        }
        await dispatch({
          type: Actions.SET_ALERT, value: { status: true, message: resData.message }
        });
        await dispatch({
          type: Actions.SET_ALERT, value: { status: true, message: resData.message }
        });

      } else {
        let htmlString = resData.body.html;
        const link = resData.body.link;
        const resourceId = resData.body.resource_id;
        const fileName = link.split("/").pop()?.replace(".pdf", ".html") || "test.html";
        const pdfS3Path = link;
        // set page language to japanese
        htmlString = htmlString.replace("<html>", "<html lang=\"ja\">");
        htmlString = htmlString.replace("<head>", "<head><meta charset=\"UTF-8\">");
        // add custom css
        htmlString = htmlString.replace("</head>", "<style>#HeaderPopArea,#SearchHd,#FooterArea01,#FooterArea02,#FooterArea03 {display: none};</style> <style>#ResultSet > div:first-child{display: none};</style><style>#ResultSet{width: 21cm; margin:auto}</style></head>");
        htmlString = htmlString.replace("</head>", "<style> @media print {#printPAge {display : none}}</style></head>");
        htmlString = htmlString.replace("</head>", "<style> #ResultSet > div{float: none}</style></head>");
        // add js to remove style from child of an id
        htmlString = htmlString.replace("</body>", "<script>document.getElementById(\"ResultSet\").children[1].removeAttribute(\"style\");</script></body>");

        htmlString = htmlString.replaceAll("src=\"/", "src=\"https://www.pmda.go.jp/");

        // open data in new window
        const option = "height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes";
        const winUrl = URL.createObjectURL(new Blob([htmlString], { type: "text/html" }));
        const newWindow: any = window.open(winUrl, "_blank", option);
        // once content is loaded, scroll to bottom of page smoothly
        setTimeout(() => {
          newWindow.document.getElementById("ResultSet")?.scrollIntoView({
            behavior: "smooth", block: "end", inline: "nearest"
          });
        }, 3000);
        // add file to form data

        new Promise(function(resolve, reject) {
          const timer = setInterval(() => {
            if (newWindow.closed) {
              const formData = new FormData();

              // check if windows HTML has text like "Request failed. You will not be charged for this request. Please make sure your url is correct and try the request again. If this continues to happen, please contact support@scraperapi.com. The domain may be protected, please try adding the premium=true OR ultra_premium=true parameter to your request."
              if (newWindow.document.documentElement.innerHTML.includes("Request failed.")) {
                reject("Request failed. You will not be charged for this request. Please make sure your url is correct and try the request again");
              }
              else {
                const fullPageBlob = new Blob([newWindow.document.documentElement.innerHTML], { type: "text/html" });
                // ObjectURL to file
                const fullPageFile = new File([fullPageBlob], fileName, { type: "text/html" });


                formData.append("translated", fullPageFile);
                formData.append("s3_path", pdfS3Path);
                formData.append("resource_id", resourceId);
                formData.append("package_insert_number", packageInsertNumber);
                formData.append("email", user.email);
                formData.append("name", user.name);

              }

              clearInterval(timer);
              setLoading(true);
              sendFormData(formData, resolve, reject);
            }
          }, 1000);
        }).then(res => {
          setLoading(false);
          setNotTranslatedData((prev: any) => prev?.map((item: any) => {
            if (item.package_insert_number === packageInsertNumber) {
              item.translated = true;
              item.message = res;
              // store packageInsertNumber in local storage
              const prevLocalStorage = JSON.parse(window.localStorage.getItem("doneKeyList") || "[]");
              prevLocalStorage.push(packageInsertNumber);
              window.localStorage.setItem("doneKeyList", JSON.stringify(prevLocalStorage));

            }
            return item;
          }));
        }).catch((e) => {
          setLoading(false);
          dispatch({
            type: Actions.SET_ALERT, value: { status: true, message: e.message }
          });
          setNotTranslatedData((prev: any) => prev?.map((item: any) => {
            if (item.package_insert_number === packageInsertNumber) {
              item.translated = false;
              item.message = e.message;
            }
            return item;
          }));
        });
      }
      setLoading(false);
    } catch (e: any) {
      setLoading(false);
      await dispatch({
        type: Actions.SET_ALERT, value: { status: true, message: e.message }
      });
    }
  };
  const sendFormData = async (formData: any, resolve: any, reject: any) => {
    try {
      const res = await sendConverterData(formData);
      const resData = res.data;
      if (resData.error) {
        reject(resData.message);
      } else {
        resolve(resData.message);
      }
    } catch (e: any) {
      reject(e.message);
    }

  };

  useEffect(() => {
    if (errorHistory.length > 0) {
      window.localStorage.setItem("errorHistory", JSON.stringify(errorHistory));
    }
    setFetching(true)
    getTranslationList().then((res) => {
      const resData = res.data;
      if (resData.error) {
        dispatch({
          type: Actions.SET_ALERT, value: { status: true, message: resData.message }
        });
      } else {
        const newDoneList:any=[];
        Object.entries(resData.body).forEach(([key, value]:any) => {
          newDoneList.push({
            "id": key, ...value, "translated": false, "message": "Not Translated"
          });
        });
        setFetchedData(newDoneList);
        setNotTranslatedData(newDoneList);
      }
      setFetching(false)
    }).catch((e) => {
      dispatch({
        type: Actions.SET_ALERT, value: { status: true, message: e.message }
      });
      setFetching(false)
    })
  }, [errorHistory]);
  useEffect(()=>{
    Auth.currentUserInfo().then((user) => {
      setUser({
        name:user.attributes['custom:user'],
        email:user.attributes.email
      });
    });
  },[])

  if (fetching){
    return <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display="flex" ml={2} mt={10} alignItems="center">
        <h1>AutoLabelTranslation</h1>
      </Box>
      {fetching && <Box p={2}>
        <h1>Fetching...</h1>
      </Box>}
    </ThemeProvider>
  }
  return (<ThemeProvider theme={themev5}>
    <CssBaseline />
    <Box display="flex" ml={2} mt={10} alignItems="center">
      <h1>AutoLabelTranslation</h1>
    </Box>
    {fetching && <Box p={2}>
      <h1>Fetching...</h1>
    </Box>}
    {errorHistory.length > 0 && <Box display="flex" ml={2} mt={10} alignItems="center">
      <h1>Error History</h1>
      <ul>
        {errorHistory.map((item: any, index: number) => <li key={index}>{item}</li>)}
      </ul>
    </Box>

    }
    <Stack flexWrap={"wrap"} direction={"row"}>
      <Button sx={{ m: 5, minWidth: 150 }} component="label" variant="contained" startIcon={<CloudUpload />}>
        Upload ----curators_list.json
        <VisuallyHiddenInput type="file" onChange={handleNotTranslatedUpload} />
      </Button>
      <Button sx={{ m: 5, minWidth: 150 }} component="label" variant="contained" startIcon={<ViewComfyAlt />}>
        See all
      </Button>
      <Button sx={{ m: 5, minWidth: 150 }} component="label" variant="contained" startIcon={<ViewComfyAlt />} onClick={()=>setShowGroups((prev:any)=>!prev)}>
        Bulk translate in groups
      </Button>
    </Stack>
    {loading && <Box p={2}>
      <h1>Uploading...</h1>
    </Box>}
    {
      showGroups && <Stack flexWrap={"wrap"} direction={"row"}>
        {chunk(notTranslatedData, 100).map((item: any, index: number) => <Button key={index} sx={{ m: 5, minWidth: 150 }} component="label" variant="contained" startIcon={<ViewComfyAlt />} onClick={() => {
          item.forEach((i: any) => {
            handleHTML(i.package_insert_number, i.active_ingredient_id, i.yj_code);
          });
        }}>
          Group {index + 1}
        </Button>)}
      </Stack>
    }
    <DataGrid
      style={{ height: "60vh", width: "100%" }}
      rows={notTranslatedData || []}
      columns={[{
        field: "package_insert_number", headerName: "Package Insert Number", width: 500
      }, { field: "translated", headerName: "Translated", width: 300 }, {
        field: "message", headerName: "Message", width: 500
      }, {
        field: "translateButton", headerName: "Translate", width: 200, renderCell: (params) => (//active_ingredient_id,yj_code,package_insert_number
          <Button
            onClick={() => handleHTML(params.row.package_insert_number, params.row.active_ingredient_id, params.row.yj_code)}>Translate</Button>)
      }]}
      pageSize={100}
    />

  </ThemeProvider>);
};
export default React.memo(AutoLabelTranslation);