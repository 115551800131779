import { useState, useEffect, useContext, memo, ChangeEvent } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DataEntryDataGrid from '../../helpers/dataGridCreator';
import Styles from './styles/CategorizationTable.styles';
import themev5 from '../../themev5';
import { ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import LabelPageEditDialog from './LabelPageEditDialog'
import LetterPageEditDialog from './LetterPageEditDialog'
import tabStyle from '../CBER/styles/Tabs.styles'
import utils from '../Datagrid/utils'
// api
import { getLabelPageNumber, getLetterPageNumber } from '../../api/Cber'

// context
import Store from '../../store';
import Actions from '../../store/actions';

import {
    Typography,
    Button,
    Grid,
    Box,
    TextField
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import StyledTabs, { StyledTab } from '../StyledComponents/VivproTabs';
import styles from '../Datagrid/Datagrid.styles';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@mui/material/CssBaseline';

const qc_status = [
    'All Documents',
    'Escalate',
    'Missing Details',
    'Non QCed',
    'QCed'
];

const PageNumberCuration = () => {
    const history = useHistory()
    const { dispatch } = useContext<any>(Store);
    const { format, table_status, search_query } = useParams<any>();
    const classes = Styles();
    const [labelKey, setLabelKey] = useState<Array<any>>([]);
    const [labelPageNo, setLabelPageNo] = useState(0);
    const [labelRowCount, setLabelRowCount] = useState();
    const [offset, setOffset] = useState(0);
    const [loading, setloading] = useState(true)
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [docsId, setDocsId] = useState<Number>()
    const [applicationNumber, setApplicationNumber] = useState('')
    const [selectedTab, setSelectedTab] = useState<string>(utils(format, 'formulations'));
    const [searchText, setSearchText] = useState(search_query || "")
    const [status, setStatus] = useState(table_status || "All Documents")
    const [letterKey, setLetterKey] = useState<Array<any>>([]);
    const [letterPageNo, setLetterPageNo] = useState(0);
    const [letterRowCount, setLetterRowCount] = useState();
    const [letterOffset, setLetterOffset] = useState(0);
    const [showLetterEditDialog, setShowLetterEditDialog] = useState(false);
    const rowsPerPageOptions = [10, 20, 50, 100]
    const [pageSize, setPageSize] = useState(10)
    const [letterPageSize, setLetterPageSize] = useState(10)
    const onTabChange = (e: React.SyntheticEvent, newValue: string) => {
        setSelectedTab(newValue);
        history.push(`/cber/reviews/${newValue.toLowerCase()}`)
        setSearchText("");
        setStatus("All Documents")
        setLetterPageNo(0);
        setLabelPageNo(0);
    };
    useEffect(() => {
        const getNonQcedLabelData = async () => {
            try {
                let result = undefined;
                if (searchText) {
                    result = await getLabelPageNumber(labelPageNo, pageSize, status === "Non QCed" ? "to_qc" : status.toLowerCase(), searchText.trim().toLowerCase())
                }
                else {
                    result = await getLabelPageNumber(labelPageNo, pageSize, status === "Non QCed" ? "to_qc" : status.toLowerCase());
                }
                if (result === undefined) {
                    throw new Error("Result is undefined")
                }
                if (result.data && result.data.body.results) {
                    setloading(false)
                    let resultData = result.data.body.results;
                    setLabelRowCount(result.data.body.total_rows)
                    setLabelKey([...resultData?.map((item: any, index: number) => ({ ...item, id: index }))]);
                }
                else {
                    setloading(false)
                    throw Error('No data');
                }
            } catch (e: any) {
                await dispatch({
                    type: Actions.SET_ALERT,
                    value: { status: true, message: e.message }
                });
            }
        };
        getNonQcedLabelData();
    }, [labelPageNo, searchText, status, pageSize])

    useEffect(() => {
        const getNonQcedLetterData = async () => {
            try {
                let result = undefined;
                if (searchText) {
                    result = await getLetterPageNumber(letterPageNo, letterPageSize, status === "Non QCed" ? "to_qc" : status.toLowerCase(), searchText.trim().toLowerCase())
                }
                else {
                    result = await getLetterPageNumber(letterPageNo, letterPageSize, status === "Non QCed" ? "to_qc" : status.toLowerCase());
                }
                if (result === undefined) {
                    throw new Error("Result is undefined")
                }
                if (result.data && result.data.body.results) {
                    setloading(false)
                    let resultData = result.data.body.results;
                    setLetterRowCount(result.data.body.total_rows)
                    setLetterKey([...resultData?.map((item: any, index: number) => ({ ...item, id: index }))]);
                }
                else {
                    setloading(false)
                    throw Error('No data');
                }
            } catch (e: any) {
                await dispatch({
                    type: Actions.SET_ALERT,
                    value: { status: true, message: e.message }
                });
            }
        };
        getNonQcedLetterData();
    }, [letterPageNo, searchText, status, letterPageSize])

    const openEdit = (data: any) => {
        setShowEditDialog(true);
        setDocsId(data.application_docs_id);
        setApplicationNumber(data.application_number)
    };
    const closeEdit = () => {
        setShowEditDialog(false);
    };
    const letterOpenEdit = (data: any) => {
        setShowLetterEditDialog(true);
        setDocsId(data.application_docs_id);
        setApplicationNumber(data.application_number)
    };
    const letterCloseEdit = () => {
        setShowLetterEditDialog(false);
    };
    const labelColumns = [
        {
            field: 'Edit',
            width: 100,
            headerClassName: "MuiDataGrid-columnHeader",
            cellClassName: "ria-MuiDataGrid-cell",
            headerAlign: 'center',
            renderCell: (params: any) => {
                return (
                    <Button
                        variant='contained'
                        className={classes.Button}
                        onClick={() => {
                            openEdit(params.row);
                        }}>
                        <Typography>Edit</Typography>
                    </Button>
                );
            }
        },
        {
            field: 'application_number',
            headerName: 'Application Number',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'title',
            headerName: 'PDF Title',
            width: 500,
            renderCell: (params: any) => (
                <Grid item xs={8} lg={8}>
                    <Tooltip title={params.row.title} >
                        <Typography style={{ fontSize: '17px' }}>
                            {params.row.title.length > 56 ? params.row.title.substring(0, 52) + "..." : params.row.title.substring(0, 47)}
                        </Typography>
                    </Tooltip>
                </Grid>
            )
        },
        {
            field: 'qc_status',
            headerName: 'QC Status',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) => (
                <Grid item xs={8} lg={8}>
                    {params.row.qc_status === "qced" ? "QCed" : params.row.qc_status === "to_qc" ? "Non QCed" : utils(params.row.qc_status, 'formulations')}
                </Grid>
            )
        },
        {
            field: 'submission_number',
            headerName: 'Submission Number',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'submission_type',
            headerName: 'Submission Type',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
    ];
    const letterColumns = [
        {
            field: 'Edit',
            width: 100,
            headerClassName: "MuiDataGrid-columnHeader",
            cellClassName: "ria-MuiDataGrid-cell",
            headerAlign: 'center',
            renderCell: (params: any) => {
                return (
                    <Button
                        variant='contained'
                        className={classes.Button}
                        onClick={() => {
                            letterOpenEdit(params.row);
                        }}
                    >
                        <Typography>Edit</Typography>
                    </Button>
                );
            }
        },
        {
            field: 'application_number',
            headerName: 'Application Number',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'title',
            headerName: 'PDF Title',
            width: 500,
            renderCell: (params: any) => (
                <Grid item xs={8} lg={8}>
                    <Tooltip title={params.row.title} >
                        <Typography style={{ fontSize: '17px' }}>
                            {params.row.title.length > 56 ? params.row.title.substring(0, 52) + "..." : params.row.title.substring(0, 47)}
                        </Typography>
                    </Tooltip>
                </Grid>
            )
        },
        {
            field: 'qc_status',
            headerName: 'QC Status',
            width: 200,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params: any) => (
                <Grid item xs={8} lg={8}>
                    {params.row.qc_status === "qced" ? "QCed" : params.row.qc_status === "to_qc" ? "Non QCed" : utils(params.row.qc_status, 'formulations')}
                </Grid>
            )
        },
        {
            field: 'submission_number',
            headerName: 'Submission Number',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
        {
            field: 'submission_type',
            headerName: 'Submission Type',
            width: 200,
            headerAlign: 'center',
            align: 'center',
        },
    ];
    return (
        <ThemeProvider theme={themev5}>
            <CssBaseline />
            <Box display='flex' flexDirection='column' ml={2} mt={10} >
                <AppBar position='static' color='transparent' elevation={0} sx={tabStyle.tabContainer}>
                    <StyledTabs
                        value={selectedTab}
                        onChange={(e: React.SyntheticEvent, newValue: string) => {
                            onTabChange(e, newValue)
                        }}
                        aria-label='tabs'
                        sx={{ paddingLeft: 1, marginBottom: '-1px' }}
                        indicatorColor='primary'>
                        <StyledTab
                            value='Label'
                            id='label'
                            label='Label'
                            sx={selectedTab === 'Label' ? tabStyle.activeTab : tabStyle.inactiveTab}
                            activetab={(selectedTab === 'Label').toString()}
                        />
                        <StyledTab
                            value='Letter'
                            id='letter'
                            label='Letter'
                            sx={selectedTab === 'Letter' ? tabStyle.activeTab : tabStyle.inactiveTab}
                            activetab={(selectedTab === 'Letter').toString()}
                        />
                    </StyledTabs>
                </AppBar>
                <Box display='flex' padding={1} alignItems='center' justifyContent='start'>
                    <Autocomplete
                        id='qc_status'
                        value={status}
                        onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                            if (newValue !== null) {
                                setStatus(newValue);
                                setLabelPageNo(0);
                                setLetterPageNo(0);
                            }
                        }}
                        style={{ fontSize: '1.5vh', minWidth: '250px', marginRight: '10px' }}
                        options={qc_status}
                        renderInput={(params: any) => <TextField {...params} placeholder="QC Status" label="QC Status" variant='outlined' />}
                    />
                    <TextField
                        variant='outlined'
                        type='search'
                        id='search_application'
                        label='Search Application Number'
                        value={searchText}
                        InputProps={{
                            style: { fontSize: '1.5vh', minWidth: '250px' }
                        }}
                        InputLabelProps={{
                            style: { fontSize: '1.5vh' }
                        }}
                        onChange={e => {
                            setSearchText(e.target.value);
                            setLabelPageNo(0);
                            setLetterPageNo(0);
                        }}
                    />
                </Box>
                <Box
                    height='70vh'
                    mt={1}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    className={classes.root}>
                    {
                        selectedTab === "Label" ? (
                            <DataGrid
                                sx={styles.datagrid}
                                rowSpacingType='margin'
                                sortingOrder={['desc', 'asc']}
                                checkboxSelection={false}
                                density='standard'
                                rows={labelKey}
                                columns={labelColumns as any}
                                rowCount={labelRowCount}
                                pageSize={pageSize}
                                getRowId={row => row.id}
                                onPageSizeChange={pageSize => {
                                    setPageSize(pageSize);
                                    setLabelPageNo(0);
                                }}
                                onPageChange={setLabelPageNo}
                                rowsPerPageOptions={rowsPerPageOptions}
                                pagination
                                loading={loading}
                                getRowClassName={() => `table-rows`}
                                page={labelPageNo}
                                paginationMode='server'
                                components={{
                                    // Toolbar: DatagridToolbar,
                                    LoadingOverlay: LinearProgress,
                                    Footer: DatagridFooter,
                                    NoRowsOverlay: DatagridNoRowsOverlay
                                }}
                            />
                        ) :
                            <DataGrid
                                sx={styles.datagrid}
                                rowSpacingType='margin'
                                sortingOrder={['desc', 'asc']}
                                checkboxSelection={false}
                                density='standard'
                                rows={letterKey}
                                columns={letterColumns as any}
                                rowCount={letterRowCount}
                                pageSize={letterPageSize}
                                getRowId={row => row.id}
                                onPageSizeChange={pageSize => {
                                    setLetterPageSize(pageSize);
                                    setLetterPageNo(0); // Reset the page number to 0
                                }}
                                onPageChange={setLetterPageNo}
                                rowsPerPageOptions={rowsPerPageOptions}
                                pagination
                                loading={loading}
                                getRowClassName={() => `table-rows`}
                                page={letterPageNo}
                                paginationMode='server'
                                components={{
                                    // Toolbar: DatagridToolbar,
                                    LoadingOverlay: LinearProgress,
                                    Footer: DatagridFooter,
                                    NoRowsOverlay: DatagridNoRowsOverlay
                                }}
                            />
                    }
                    <Dialog
                        open={showEditDialog}
                        onClose={closeEdit}
                        sx={{ backdropFilter: 'blur(5px)' }}
                        maxWidth='lg'
                        fullWidth>
                        <LabelPageEditDialog closeEdit={closeEdit} docsId={docsId} application_number={applicationNumber} searchText={searchText} status={status} />
                    </Dialog>
                    <Dialog
                        open={showLetterEditDialog}
                        onClose={letterCloseEdit}
                        sx={{ backdropFilter: 'blur(5px)' }}
                        maxWidth='lg'
                        fullWidth>
                        <LetterPageEditDialog closeEdit={letterCloseEdit}
                            docsId={docsId} application_number={applicationNumber} searchText={searchText} status={status} />
                    </Dialog>
                </Box>
            </Box >
        </ThemeProvider >
    );
};

export default memo(PageNumberCuration);