import { useState, useContext, useCallback } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Tooltip from '@mui/material/Tooltip';
import Store from '../../store';
import Actions from '../../store/actions';

import { triggerEMADag } from '../../api/ema';

const CurationButton = () => {
  const { dispatch } = useContext<any>(Store);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

  const openEditDialog = useCallback(() => {
    setShowEditDialog(true);
  }, []);

  const handleClick = async () => {
    try {
      const result = await triggerEMADag({
        dag_id: 'EMADailyRefresh',
        task_to_run: 'generate_es_index'
      });

      if (result.data.status === 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: result.data.message, color: 'success' }
        });
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: result.data.message, color: 'error' }
        });
      }
    } catch (exception) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: (exception as Error).message }
      });
      setShowEditDialog(false);
    }
    setShowEditDialog(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'flex-end',
        paddingRight: 1,
        flexDirection: 'column'
      }}>
      <Box display='flex' flexDirection='row' sx={{ color: 'white' }}>
        <InfoIcon fontSize='small' />
        <Typography variant='body1' sx={{ color: 'white' }}>
          Note: Click when EMA Curation is completed
        </Typography>
      </Box>

      <Button
        variant='contained'
        onClick={openEditDialog}
        sx={{
          textTransform: 'capitalize',
          background: '#a593f2'
        }}>
        <Typography variant='body1'>Curation Status</Typography>
      </Button>
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth='xs'
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography style={{ color: '#3AB09E', fontSize: '20px', fontWeight: 'bold' }}>
              Confirm Submit
            </Typography>
            <IconButton aria-label='close' onClick={() => setShowEditDialog(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ fontSize: '16px', color: 'GrayText', marginRight: '0' }}>
            Are you sure EMA document curation completed ?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Tooltip title='Clck here to trigger the dag'>
            <Button
              variant='contained'
              onClick={handleClick}
              sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
              Trigger Now
            </Button>
          </Tooltip>
          <Button
            variant='contained'
            onClick={() => setShowEditDialog(false)}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CurationButton;
