import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    background: theme.palette.backgrounds.dark,
    height: '100vh'
  },
  container: {
    // marginTop:theme.spacing(5),
    paddingTop: theme.spacing(10)
  },
  navbar: {
    background: theme.palette.white
  },
  navText: {
    color: theme.palette.common.primaryGray,
    fontSize: theme.spacing(3),
    fontWeight: 'bold'
  },
  header: {
    // marginTop: theme.spacing(5),
    width: '100%'
  },
  heading: {
    fontSize: theme.spacing(5),
    color: theme.palette.buttons.purple,
    fontWeight: 'bold'
  },
  subheading: {
    marginTop: theme.spacing(1),
    color: theme.palette.review.label
  },
  icon: {
    color: theme.palette.successGreen,
    fontSize: theme.spacing(25)
  },
  errorIcon: {
    color: theme.palette.fatalRed,
    fontSize: theme.spacing(25)
  },
  tableIcon: {
    color: theme.palette.successGreen
  },
  legends: {
    marginRight: theme.spacing(1)
  },
  tableErrorIcon: {
    color: theme.palette.fatalRed
  },
  vivLogo: {
    width: theme.spacing(20),
    height: theme.spacing(9)
  },
  tbBody: {
    background: theme.palette.backgrounds.light
  },
  table: {
    marginTop: theme.spacing(3)
  },
  tableHeader: {
    background: theme.palette.backgrounds.dark
  },
  tableHeading: {
    fontSize: theme.spacing(2),
    color: theme.palette.white
  },
  tbText: {
    color: theme.palette.white
  }
}));
