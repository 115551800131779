import { useState, useContext } from 'react';
import React from 'react';

import { Box, Button, Typography } from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';
import LinearProgress from '@mui/material/LinearProgress';

import Actions from '../../../store/actions';
import Store from '../../../store';
import ConfirmationDialog from '../../Common/ConfirmationDialog';
import { EMA_INVALID_STATUS, EMA_IN_PROGRESS_STATUS, EMA_QCD_STATUS, KEY_COLUMNS } from './constants';
import DatagridFooter from '../../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../../Datagrid/DatagridNoRowsOverlay';
interface propTypes {
  data: any;
  // eslint-disable-next-line no-unused-vars
  handleButtonClick: (rowData: any,qcStatus:string) => any;
  // eslint-disable-next-line no-unused-vars
  handleCurationButtonClick: (product_number: string) => void;
  reload: any;
  setReload: any;
  loading:any;
}

const DisplayEMAKeys = ({
  data,
  handleButtonClick,
  handleCurationButtonClick,
  reload,
  setReload,
  loading
}: propTypes) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [productData, setProductData] = useState({});
  const [qcStatus,setQcStatus] = useState<string>('')
  const { dispatch } = useContext<any>(Store);
  const rowsPerPageOptions = [10, 20, 50, 100];

  const dataGridColumns = [
    ...KEY_COLUMNS,
    {
      field: 'Actions',
      width: 800,
      headerAlign: 'center',
      align: 'left',
      renderCell: (params: any) => {
        return (
          <Box
            style={{
              margin: '3px',
              display: 'flex',
              width: '100%',
              justifyContent: 'space-around'
            }}>
            <Button
              variant='contained'
              onClick={() => {
                handleCurationButtonClick(params.row);
              }}>
              <Typography variant='body1'>Curation</Typography>
            </Button>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => {
                setIsDialogOpen(true);
                setProductData(params.row);
                setQcStatus(EMA_IN_PROGRESS_STATUS)
              }}>
              <Typography variant='body1'>Mark as In-Progress</Typography>
            </Button>
            <Button
              variant='contained'
              color='success'
              onClick={() => {
                setIsDialogOpen(true);
                setProductData(params.row);
                setQcStatus(EMA_QCD_STATUS);
              }}>
              <Typography variant='body1'>Mark as Qced</Typography>
            </Button>
            <Button
              variant='contained'
              color='error'
              onClick={() => {
                setIsDialogOpen(true);
                setProductData(params.row);
                setQcStatus(EMA_INVALID_STATUS);

              }}>
              <Typography variant='body1'>Mark as Invalid</Typography>
            </Button>
          </Box>
        );
      }
    }
  ];

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogSubmit = async () => {
    const success = await handleButtonClick(productData,qcStatus);
    setIsDialogOpen(false);
    if (success) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `Marked as ${qcStatus}` }
      });
      setReload(!reload);
    } else {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `Faild to Mark as ${qcStatus}` }
      });
    }
  };

  return (
    <Box
      p={2}
      mt={1}
      height='65vh'
      width='100%'
      display='flex'
      alignItems='center'
      justifyContent='center'>
      <DataGrid
        rows={data ?? []}
        columns={dataGridColumns as any}
        rowSpacingType='margin'
        density='standard'
        disableColumnMenu
        getRowId={row => row.id}
        getRowHeight={() => 'auto'}
        rowsPerPageOptions={rowsPerPageOptions}
        loading={loading}
        getRowClassName={() => `table-rows`}
        paginationMode='server'
        components={{
          LoadingOverlay: LinearProgress,
          Footer: DatagridFooter,
          NoRowsOverlay: DatagridNoRowsOverlay
        }}
      />
      <ConfirmationDialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        onConfirm={handleDialogSubmit}
        title='Confirmation'
        content={`Are you sure you want to mark the key as ${qcStatus}`}
      />
    </Box>
  );
};
export default React.memo(DisplayEMAKeys);
