import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  label: {
    padding: theme.spacing(2),
    fontSize: theme.spacing(2),
    color: theme.palette.review.label,
    fontWeight: 'bold'
  },
  root: {
    background: '#dbd4fa5c',
    margin: '0 1rem',
    '& .Mui-selected': {
      background: `${theme.palette.buttons.purple} !important`,
      fontFamily: 'Roboto',
      color: `${theme.palette.white} !important`,
      '&:hover': {
        opacity: 0.8,
        background: `${theme.palette.buttons.purple} !important`
      }
    }
  },
  infoIcon: {
    marginBottom: '-5px',
    marginLeft: '5px'
  }
}));
