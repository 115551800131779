import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

// The `withStyles()` higher-order component is injecting a `classes`
// prop that is used by the `TextField` component.
const AdminFormTextField = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(0.5),
    height: theme.spacing(6),
    background: theme.palette.backgrounds.dark,
    width: '50%',
    borderRadius: theme.spacing(1.5)
  },
  label: {
    textTransform: 'capitalize'
  }
}))(TextField);

export default AdminFormTextField;
