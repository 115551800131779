import React from 'react';
import { useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const NonEditApplicationFields = ({ application, classes }) => {
  const { meetingId } = useParams();

  return (
    <Box padding={1} className={classes.reviewBox} styles={{ float: 'left' }}>
      <h1>Application Details</h1>
      <Typography align='left' className={classes.label}>
        Meeting ID
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {meetingId}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Application Number
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.appl_no}
        </Typography>
      </Box>
      <Box>
        <Typography align='left' className={classes.label}>
          Application Type
        </Typography>
        <Typography className={classes.details} align='left'>
          {application.appl_type}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Sponsor
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.sponsor}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Drug Name
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.trade_name}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Active Ingredient
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.active_ingredient}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Formulation
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.formulation}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Route
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.route}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Approval Date
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.approval_date}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Submission
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.submission}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Submission ID
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.submission_id}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Approval Pathway
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.approval_pathway}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Approval Decision
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.approval_decision}
        </Typography>
      </Box>
      <Typography align='left' className={classes.label}>
        Status
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {application.status}
        </Typography>
      </Box>
    </Box>
  );
};

export default NonEditApplicationFields;
