import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from 'lodash';
import { Card, DialogTitle, IconButton, InputLabel, MenuItem, Select } from '@material-ui/core';

// context
import CloseIcon from '@material-ui/icons/Close';
import { Autocomplete } from '@mui/material';
import Store from '../../store';
import Actions from '../../store/actions';

// api
import {
  getCberPMRLetterDetails,
  submitCberPmrRequirement,
  updatePMRCStatus
} from '../../api/Cber';

// helpers
import { handleChange } from '../../helpers/changeHandler';

// styles
import Styles from './styles/CberPmrReviewPage';
import { PmrcSectionOptions } from './constants';

const qcStatusList = ['Escalate', 'Missing Details', 'No Document', 'QCed'];

// eslint-disable-next-line react/prop-types
const CberPmrReviewPage = ({ docsId: applicationDocsId, closeEdit }) => {
  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const [editMode, setEditMode] = useState(false);
  const [initialData, setInitialData] = useState();
  const [cmtNumber, setCmtNumber] = useState();
  const [cmtDesc, setCmtDesc] = useState();
  const [qcStatus, setQcStatus] = useState('Qced');
  const [sectionOptions, setSectionOptions] = useState();
  const [section, setSection] = useState('ACCELERATED APPROVAL REQUIREMENTS');
  const [status, setStatus] = useState('Qced');
  const [formSubmit, setFormSubmit] = useState(false);
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [pdfLink, setPdfLink] = useState();

  const handleClose = () => {
    closeEdit();
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentUserInfo();
        setEmail(user.attributes.email);
        setName(user.attributes['custom:user']);
        // eslint-disable-next-line react/prop-types,react/destructuring-assignment
        const res = await getCberPMRLetterDetails(applicationDocsId);
        if (isEmpty(res.data?.body?.results)) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Enter valid information' }
          });
          handleClose();
        }
        setInitialData(res.data.body.results);
        setQcStatus(res.data.body.results?.qc_status);
        setPdfLink(res.data.body.results.document_link);
        setSectionOptions(PmrcSectionOptions);
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        handleClose();
      }
      setFormSubmit(false);
    }

    fetchData();
    // eslint-disable-next-line react/destructuring-assignment,react/prop-types
  }, [formSubmit, dispatch, history]);

  const handleEdit = prevData => {
    setEditMode(true);
    setCmtNumber(prevData.cmt_number);
    setCmtDesc(prevData.cmt_description);
    setStatus(prevData.qc_status);
    setSection(prevData.section);
  };

  const openAddNew = () => {
    setCmtDesc('');
    setCmtNumber('');
    setStatus('Qced');
    setSection();
    setEditMode(true);
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const payload = {
        section,
        cmt_number: cmtNumber,
        cmt_description: cmtDesc,
        qc_status: status,
        curator_email: email,
        curator_name: name
      };
      await submitCberPmrRequirement(applicationDocsId, payload);
      setEditMode(false);
      setFormSubmit(true);
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error }
      });
    }
  };

  const handleQcStatusUpdate = async e => {
    e.preventDefault();
    try {
      const payload = {
        qc_status: qcStatus,
        curator_email: email,
        curator_name: name
      };
      await updatePMRCStatus(applicationDocsId, payload);
      closeEdit();
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error }
      });
    }
  };
  return (
    <Container className={classes.root}>
      <div>
        <DialogTitle className={{ m: 0, p: 2 }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography component='h2' variant='h5' className={classes.Heading}>
              {initialData?.title}
            </Typography>
            {!editMode ? (
              <Button
                onClick={openAddNew}
                variant='contained'
                className={classes.submit}
                style={{ margin: '0 0 0 30px' }}>
                Add Record
              </Button>
            ) : null}
          </Box>
          <IconButton
            aria-label='close'
            onClick={() => {
              handleClose();
            }}
            style={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid container spacing={2} display='flex'>
          <Grid item xs={12} lg={6}>
            {editMode ? (
              <form onSubmit={handleSubmit} style={{ paddingTop: '17px' }}>
                <Grid container>
                  <Grid item xs={12}>
                    <TextField
                      variant='outlined'
                      fullWidth
                      id='cmt_number'
                      label='CMT Number'
                      value={cmtNumber}
                      required
                      onChange={e => setCmtNumber(handleChange(e))}
                      InputProps={{
                        style: { fontSize: '2vh', marginBottom: '15px' }
                      }}
                      InputLabelProps={{
                        style: { fontSize: '2vh', fontWeight: 'bold' }
                      }}
                    />
                  </Grid>
                  <TextField
                    multiline
                    minRows={3}
                    maxRows={8}
                    variant='outlined'
                    fullWidth
                    id='cmt_description'
                    label='CMT Description'
                    value={cmtDesc}
                    required
                    onChange={e => setCmtDesc(handleChange(e))}
                    InputProps={{
                      style: { fontSize: '2vh', marginBottom: '15px' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '2vh', fontWeight: 'bold' }
                    }}
                  />
                  <Autocomplete
                    id='section'
                    fullWidth
                    value={section}
                    options={sectionOptions}
                    onChange={(_, newValue) => setSection(newValue)}
                    style={{
                      '& .MuiInputBase-input': {
                        fontSize: '1.5vh',
                        minWidth: '250px'
                      },
                      '& .MuiFormLabel-root': {
                        fontSize: '1.5vh'
                      },
                      marginBottom: '15px'
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label='Section'
                        variant='outlined'
                        required
                        InputLabelProps={{
                          style: { fontSize: '2vh', fontWeight: 'bold' }
                        }}
                      />
                    )}
                  />
                  <Autocomplete
                    id='qc_status'
                    fullWidth
                    value={status}
                    options={qcStatusList}
                    onChange={(_, newValue) => setStatus(newValue)}
                    style={{
                      '& .MuiInputBase-input': {
                        fontSize: '1.5vh',
                        minWidth: '250px'
                      },
                      '& .MuiFormLabel-root': {
                        fontSize: '1.5vh'
                      }
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label='QC Status'
                        variant='outlined'
                        required
                        InputLabelProps={{
                          style: { fontSize: '2vh', fontWeight: 'bold' }
                        }}
                      />
                    )}
                  />

                  <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-around'
                    style={{ width: '100%' }}>
                    <Button
                      type='button'
                      variant='contained'
                      color='primary'
                      className={classes.submit}
                      onClick={() => {
                        setEditMode(false);
                      }}>
                      CLOSE
                    </Button>
                    <Button
                      type='submit'
                      variant='contained'
                      color='primary'
                      className={classes.submit}>
                      SUBMIT
                    </Button>
                  </Box>
                </Grid>
              </form>
            ) : (
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-around'
                style={{ padding: '10px 10px' }}>
                <Box
                  style={{
                    overflowY: 'auto',
                    maxHeight: '60vh',
                    padding: '4px',
                    border: '4px solid #d6d6d6',
                    borderRadius: '4px'
                  }}>
                  {initialData?.requirements_data?.length ? (
                    initialData.requirements_data.map(elem => (
                      <Card
                        sx={{ display: 'flex' }}
                        style={{ margin: '10px 0' }}
                        key={`${elem.application_docs_id}-${elem.cmt_number}`}>
                        <Box
                          className={classes.reviewBox}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative'
                          }}>
                          <Button
                            size='small'
                            type='button'
                            variant='contained'
                            onClick={() => {
                              handleEdit(elem);
                            }}
                            color='primary'
                            className={classes.submit}
                            style={{
                              position: 'absolute',
                              right: 15,
                              top: 0,
                              color: 'gray.light'
                            }}>
                            Edit
                          </Button>
                          <Box padding={1}>
                            <Typography align='left' className={classes.label}>
                              CMT Number
                            </Typography>
                            <Typography align='left' className={classes.details}>
                              {elem.cmt_number}
                            </Typography>
                          </Box>
                          <Box padding={1}>
                            <Typography align='left' className={classes.label}>
                              CMT Description
                            </Typography>
                            <Typography align='left' className={classes.details}>
                              {elem.cmt_description}
                            </Typography>
                          </Box>
                          <Box padding={1}>
                            <Typography align='left' className={classes.label}>
                              Section
                            </Typography>
                            <Typography align='left' className={classes.details}>
                              {elem.section}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Card style={{ margin: '10px 0', padding: '10px' }}>
                      <Box>
                        <Typography>No CMT Data found</Typography>
                      </Box>
                    </Card>
                  )}
                </Box>
                <Box
                  style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
                  <Box style={{ width: '45%' }}>
                    <InputLabel id='qc-status-label'>QC Status</InputLabel>
                    <Select
                      id='selectQcStatus'
                      labelId='qc-status-label'
                      variant='outlined'
                      disableUnderline
                      fullWidth
                      placeholder='QC Status'
                      value={qcStatus}
                      onChange={event => setQcStatus(handleChange(event))}
                      className={classes.dropdownContainer}
                      inputProps={{
                        classes: {
                          icon: classes.icon
                        }
                      }}>
                      <MenuItem value='qced' className={classes.dropdownText}>
                        Qced
                      </MenuItem>
                      <MenuItem value='no document' className={classes.dropdownText}>
                        No Document
                      </MenuItem>
                      <MenuItem value='escalate' className={classes.dropdownText}>
                        Escalate
                      </MenuItem>
                      <MenuItem value='delete' className={classes.dropdownText}>
                        Delete
                      </MenuItem>
                      <MenuItem value='no cmt' className={classes.dropdownText}>
                        No Cmt
                      </MenuItem>
                    </Select>
                  </Box>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    className={classes.submit}
                    onClick={handleQcStatusUpdate}>
                    SUBMIT
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            {pdfLink ? (
              <Box display='flex' justifyContent='center'>
                <iframe
                  title='pdf'
                  src={pdfLink}
                  style={{
                    height: '100vh',
                    width: '50vw',
                    marginTop: '20px',
                    position: 'relative'
                  }}
                />
              </Box>
            ) : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{ height: '100%', width: '100%' }}>
                <Typography style={{ fontSize: '20px', fontWeight: 'bold', color: '#33B187' }}>
                  Can&apos;t Open This PDF
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default CberPmrReviewPage;
