import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// mui
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Skeleton from '@mui/material/Skeleton';
import CloseIcon from '@mui/icons-material/Close';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';

// api
import { getMaterial, deleteMaterial, upsertAdcommMaterial } from '../../api/AdcommModule';

// store
import Actions from '../../store/actions';
import Store from '../../store';

// styles
import themev5 from '../../themev5';
import Styles, { styles } from './styles/meetingDashbaord';

// utils
import { MATERIAL_FORM_MAPPING } from './constant';
import getDataFields, { getFieldsFromApiData } from '../CustomDashboard/utils/getDataFields';
import PDFViewer from '../../utils/PDFViewer';
import CurationForm from '../CustomDashboard/CurationForm';

const AdcommMaterial = () => {
  const { dispatch } = useContext<any>(Store);
  const { meetingId } = useParams<any>();
  const history = useHistory();
  const location = useLocation();
  const updatedSearchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const classes = Styles();
  const [loading, setLoading] = useState<boolean>(true);
  const [materialData, setMaterialData] = useState<Array<any>>([]);
  const [data, setData] = useState<any>({});
  const [pdfData, setpdfData] = useState<Array<any>>([]);
  const [viewPdf, setViewPdf] = useState<boolean>(false);
  const [deleteData, setDeleteData] = useState<any>();
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [selectedMaterial, setSelectedMaterial] = useState<any>();
  const openEditDialog = useCallback(() => {
    setShowEditDialog(true);
  }, []);

  const DATAGRID_COLUMN = [
    {
      field: 'document_title',
      headerName: 'Title',
      width: 500,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: '_category',
      headerName: 'Category',
      width: 150,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'original_file_name',
      headerName: 'File Name',
      width: 300,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 100,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Actions',
      width: 500,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Stack p={2} flexDirection='row'>
            <Button
              variant='contained'
              sx={styles.Button2}
              onClick={() => {
                setSelectedMaterial(params.row);
                setData(getFieldsFromApiData(params.row, MATERIAL_FORM_MAPPING));
                openEditDialog();
              }}
              disabled={!params.row.meeting_id}>
              <Typography sx={{ color: 'white.main' }}>Edit</Typography>
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                setDeleteData(params.row);
              }}
              sx={styles.Button2}>
              <Typography sx={{ color: 'white.main' }}>Delete</Typography>
            </Button>
            <Button
              variant='contained'
              onClick={() => {
                window.open(params.row.original_file_url, '_blank');
              }}
              sx={styles.Button2}>
              <Typography sx={{ color: 'white.main' }}>View Web Page</Typography>
            </Button>
          </Stack>
        );
      }
    }
  ];

  const fetchData = async () => {
    try {
      setLoading(true);
      const result = await getMaterial(meetingId);
      if (result.data.status === 200) {
        setMaterialData(result.data.body.material || []);
        const pdfLink = [
          {
            s3_url: result.data.body.meeting_url
          }
        ];
        setpdfData(pdfLink);
      }
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `${error}` }
      });
    } finally {
      setLoading(false);
    }
  };

  const closeEditDialog = useCallback(() => {
    setShowEditDialog(false);
    fetchData();
  }, []);

  useEffect(() => {
    fetchData();
  }, [meetingId]);

  const handleGoBack = useCallback(() => {
    history.push(`/adcomm/?${updatedSearchParams.toString()}`);
  }, [history]);

  const handleOpenPopup = useCallback((url: string) => {
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow: any = window.open(url, '_blank', option);

    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
      }
    }, 1000);
  }, []);

  const handleDeleteMinutes = async (id: string) => {
    try {
      const result = await deleteMaterial(meetingId, id);
      if (result.data.status === 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Deleted Successfully`, color: 'success' }
        });
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Failed to delete` }
        });
      }
    } catch (e) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `Failed to delete` }
      });
    }
    setDeleteData(null);
    fetchData();
  };

  const handleSubmit = async (e: React.SyntheticEvent, value: any) => {
    try {
      if (Object.keys(value).length !== 0) {
        const user = await Auth.currentUserInfo();
        const dataField = getDataFields(value);
        const payload = {
          ...dataField,
          id: selectedMaterial?.id || '',
          page_count: selectedMaterial?.page_count || 0,
          s3_link: selectedMaterial?.s3_link || '',
          meeting_id: meetingId,
          curator_email: user.attributes.email,
          curator_name: user.attributes['custom:user'],
          qc_date: new Date(),
          qc_complete: 'status' in dataField && dataField.status === 'Qced'
        };
        const result = await upsertAdcommMaterial(meetingId, payload);
        if (result && result.data.success) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: {
              status: true,
              message: `Material ${dataField.id === 'add' ? 'added' : 'edited'} successfully`,
              color: 'success'
            }
          });
        } else {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: result.data.error }
          });
        }
      }
    } catch (error: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    } finally {
      closeEditDialog();
    }
  };

  if (loading) {
    return (
      <Box sx={{ minHeight: '100vh' }}>
        <Container maxWidth='lg' sx={{ pt: 10 }}>
          <Box
            sx={{
              width: '100%',
              height: '70vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <LinearProgress
              sx={{ width: '100%', maxWidth: '400px', marginBottom: '1em' }}
              color='secondary'
            />
            <Typography sx={{ fontSize: '25px', color: '#3AB09E' }}>Loading data ....</Typography>
          </Box>
        </Container>
      </Box>
    );
  }

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box sx={{ minHeight: '100vh', pt: 10, pl: 1, pr: 5 }}>
        <Box
          display='flex'
          padding={1}
          flexDirection='row'
          justifyContent='space-between'
          flexWrap='wrap'>
          <Stack flexDirection='row'>
            <IconButton aria-label='go-back' onClick={handleGoBack}>
              <ArrowBackIcon />
            </IconButton>
            <Button
              variant='contained'
              sx={styles.Button}
              onClick={() => {
                setSelectedMaterial({});
                setData(getFieldsFromApiData([], MATERIAL_FORM_MAPPING));
                openEditDialog();
              }}>
              <Typography sx={{ color: 'white.main' }}>Add Material</Typography>
            </Button>
          </Stack>

          <Button
            sx={styles.Button}
            disabled={pdfData?.length === 0}
            onClick={() => setViewPdf(prevState => !prevState)}
            variant='contained'>
            {viewPdf ? 'Hide' : 'View'} PDF
          </Button>
          <Typography variant='h5' sx={styles.title}>
            Total Material - {materialData.length}
          </Typography>
        </Box>
        <Box display='flex' justifyContent='space-between'>
          <Box
            height='70vh'
            p={2}
            mt={1}
            display='flex'
            alignItems='center'
            width={viewPdf ? '60%' : '100%'}
            justifyContent='center'
            className={classes.root}>
            <DataGrid
              sx={styles.datagrid}
              style={{ height: '100%' }}
              rowSpacingType='margin'
              sortingOrder={['desc', 'asc']}
              checkboxSelection={false}
              density='standard'
              rows={materialData}
              columns={DATAGRID_COLUMN as any}
              loading={loading}
              getRowClassName={() => `table-rows`}
              components={{
                LoadingOverlay: LinearProgress,
                Footer: DatagridFooter,
                NoRowsOverlay: DatagridNoRowsOverlay
              }}
            />
          </Box>
          {viewPdf && pdfData && (
            <Box
              height='70vh'
              display='flex'
              flexDirection='column'
              alignItems='stretch'
              width={viewPdf ? '40%' : 'none'}>
              <Box display='flex' alignItems='center' justifyContent='center'>
                <Button
                  variant='contained'
                  sx={styles.Button}
                  onClick={() => {
                    handleOpenPopup(pdfData[0].s3_url);
                  }}>
                  <Typography>Open As a Pop-Up window</Typography>
                </Button>
              </Box>
              <PDFViewer pdf={pdfData} />
            </Box>
          )}
        </Box>
        <Dialog fullWidth maxWidth='xs' open={!!deleteData} onClose={() => setDeleteData(null)}>
          <DialogTitle id='alert-dialog-title'>
            <Box display='flex' justifyContent='space-between' alignItems='center'>
              <Typography style={{ color: '#3AB09E', fontSize: '20px', fontWeight: 'bold' }}>
                Confirm Delete
              </Typography>
              <IconButton aria-label='close' onClick={() => setDeleteData(null)}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent>
            <Typography style={{ fontSize: '16px', color: 'GrayText', marginRight: '0' }}>
              Are you sure you have edited the document accurately?{' '}
              {`${deleteData?.member} - ${deleteData?.role}`}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button
              variant='contained'
              onClick={() => handleDeleteMinutes(deleteData?.id)}
              sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
              Yes
            </Button>
            <Button
              variant='contained'
              onClick={() => setDeleteData(null)}
              sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
              No
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={showEditDialog}
          onClose={closeEditDialog}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          <DialogTitle sx={{ m: 0, p: 2 }}>
            <Typography
              component='h2'
              variant='h5'
              style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
              Material Curation
            </Typography>
            <IconButton
              aria-label='close'
              onClick={() => {
                closeEditDialog();
              }}
              sx={{
                position: 'absolute',
                right: 5,
                top: 2,
                color: 'gray.light'
              }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            {!loading ? (
              <CurationForm fields={data} handleSubmit={handleSubmit} fileUrls={pdfData} />
            ) : (
              <Box display='flex' maxHeight='100vh' sx={{ overflow: 'hidden' }}>
                <Skeleton variant='rectangular' height='100vh' width='100%' />
              </Box>
            )}
          </DialogContent>
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(AdcommMaterial);
