import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { debounce } from 'lodash';

// material ui
import { Box, Typography, TextField, MenuItem } from '@mui/material';

// styles
import styles from './Data/styles/EMAData.styles';

// context
import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';
import CurationStatusToggle from '../Common/CurationStatusToggle';
import { getEMAKeys, updateEMAKeyStatus } from '../../api/ema';
import { EMA_PRODUCTS_CURATION_QUERY_PARAM } from './Curation/constants';
import DisplayEMAKeys from './Curation/DispayKeys';

const EMAProducts = () => {
  const history = useHistory();
  const { dispatch }: any = useContext(GlobalStore);

  const [keys, setKeys] = useState([]);
  const [reload, setReload] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [status, setStatus] = useState('');
  const [loading, setloading] = useState(true);

  const debouncedChangeHandler = () => {
    setReload(prevState => !prevState);
  };

  const handleChange = debounce(debouncedChangeHandler, 300);

  const getEMAKeysData = async () => {
    try {
      setloading(true)
      const params = {
        curationType: EMA_PRODUCTS_CURATION_QUERY_PARAM,
        applicationNumber: searchText.trim().toLowerCase(),
        status: status
      };
      const response = await getEMAKeys(params);
      const keysForDataGrid = response.data.body.map((data: any, index: number) => {
        return {
          ...data,
          id: index
        };
      });
      setKeys(keysForDataGrid);
      setloading(false);

    } catch (e: any) {
      console.error(e);
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: e.message }
      });
      setloading(false);

    }
  };
  const handleCurationButtonClick = async (rowData: any) => {
    // Split key to get application number
    // Eg : key=EMEA-H-C-005331 , applicationNumber = 005331
    const applicationNumber = rowData['product_number'].split('-')[3];

    await dispatch({
      type: GlobalActions.SET_EMA_PRODUCT_NO,
      value: applicationNumber
    });
    history.push(`/ema/products/${applicationNumber}`);
  };

  const handleButtonClick = async (rowData: any, qcStatus: string) => {
    try {
      const user = await Auth.currentUserInfo();
      const payload = {
        sourced_from: rowData['sourced_from'],
        curation_type: EMA_PRODUCTS_CURATION_QUERY_PARAM,
        qc_status: qcStatus,
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      };
      const resp = await updateEMAKeyStatus(rowData['product_number'], payload);
      return resp.data.status === 200;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    getEMAKeysData();
  }, [status, reload]);

  return (
    <Box display='flex' sx={styles.root} flexDirection='column'>
      <Box>
        <Typography
          sx={{ marginTop: '10vh', textAlign: 'center', color: '#3AB09E', fontWeight: 500 }}>
          Products Curation
        </Typography>
      </Box>
      <Box paddingLeft='3vh' display='flex'>
        <CurationStatusToggle source='ema' task='products' />
      </Box>
      <Box display='flex' ml={5} mt={10} alignItems='center'>
        <TextField
          variant='outlined'
          type='search'
          id='search_application'
          label='Search Application Number'
          value={searchText}
          InputProps={{
            style: { fontSize: '1.5vh', minWidth: '250px' }
          }}
          InputLabelProps={{
            style: { fontSize: '1.5vh' }
          }}
          onChange={e => {
            setSearchText(e.target.value);
            handleChange();
          }}
          sx={{ paddingRight: '16px' }}
        />
        <TextField
          variant='outlined'
          id='status'
          label='QC Status'
          select
          value={status}
          onChange={e => setStatus(e.target.value)}
          InputProps={{
            style: { fontSize: '1.5vh', minWidth: '250px' }
          }}
          InputLabelProps={{
            style: { fontSize: '1.5vh' }
          }}>
          <MenuItem value='to_qc'>To QC</MenuItem>
          <MenuItem value='in-progress'>In-progress</MenuItem>
        </TextField>
      </Box>
      <Box paddingTop='2vh' paddingLeft='3vh'>
        <DisplayEMAKeys
          data={keys}
          handleCurationButtonClick={handleCurationButtonClick}
          handleButtonClick={handleButtonClick}
          reload={reload}
          setReload={setReload}
          loading={loading}
        />
      </Box>
    </Box>
  );
};

export default React.memo(EMAProducts);
