import { useState, useContext, useEffect } from 'react';

// muiv5
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { MenuItem } from '@material-ui/core';
import { Select, InputLabel } from '@mui/material';

import { Auth } from 'aws-amplify';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { updateRld } from '../../api/AdminPage';

// styles
import styles from './styles/Form.styles';

import { RldDataProps } from './RldView';

const RldEditForm = ({
  id,
  applType,
  applNum,
  rldApplType,
  rldApplNum,
  submissionType,
  submissionNum,
  status,
  closeEdit
}: RldDataProps) => {
  const [data, setData] = useState({
    id,
    appl_type: applType,
    appl_num: applNum,
    rld_appl_type: rldApplType,
    rld_appl_num: rldApplNum,
    submission_type: submissionType,
    submission_num: submissionNum,
    status,
    closeEdit
  });
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  const { state, dispatch } = useContext(Store);

  const getUserDetails = async () => {
    const user = await Auth.currentUserInfo();
    setEmail(user.attributes.email);
    setName(user.attributes['custom:user']);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault(event);
    try {
      const payload = {
        appl_type: data.appl_type,
        appl_num: data.appl_num,
        rld_appl_type: data.rld_appl_type,
        rld_appl_num: data.rld_appl_num,
        submission_type: data.submission_type,
        submission_num: data.submission_num,
        status: data.status,
        curator_name: name,
        curator_email: email
      };
      const res = await updateRld(id, payload);
      if (closeEdit) closeEdit(res, null);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Operation successful', color: 'success' }
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('=>(EditRLD.tsx:39) error', e);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Enter valid information' }
      });
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Container sx={styles.root}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography component='h1' variant='h5' sx={styles.Heading}>
            Edit RLD data
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              if (closeEdit) closeEdit(null, null);
            }}
            sx={{
              position: 'absolute',
              right: 15,
              top: 15,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit} style={styles.form}>
          <Grid container>
            <Grid item xs={6}>
              <InputLabel id='app_t'>Application Type</InputLabel>
              <Select
                labelId='app_tt'
                name='appl_type'
                id='app_t_select'
                required
                value={data.appl_type}
                renderValue={
                  data.appl_type === 'NDA' || data.appl_type === 'BLA' || data.appl_type === 'EUA'
                    ? undefined
                    : () => <em>Select From Dropdown</em>
                }
                label='Application Type'
                sx={styles.select}
                onChange={e =>
                  setData(prevData => {
                    return { ...prevData, appl_type: e.target.value };
                  })
                }>
                <MenuItem value='NDA'>NDA</MenuItem>
                <MenuItem value='BLA'>BLA</MenuItem>
                <MenuItem value='EUA'>EUA</MenuItem>
              </Select>
              <InputLabel id='rld_t'>RLD Application Type</InputLabel>
              <Select
                labelId='rld_tt'
                name='rld_appl_type'
                id='rld_t_select'
                value={data.rld_appl_type}
                required
                renderValue={
                  data.rld_appl_type === 'NDA' ||
                  data.rld_appl_type === 'BLA' ||
                  data.rld_appl_type === 'EUA' ||
                  data.rld_appl_type === 'NA'
                    ? undefined
                    : () => <em>Select From Dropdown</em>
                }
                label='RLD Application Type'
                sx={styles.select}
                onChange={e =>
                  setData(prevData => {
                    return { ...prevData, rld_appl_type: e.target.value };
                  })
                }>
                <MenuItem value='NDA'>NDA</MenuItem>
                <MenuItem value='BLA'>BLA</MenuItem>
                <MenuItem value='EUA'>EUA</MenuItem>
                <MenuItem value='NA'>NA</MenuItem>
              </Select>
              <InputLabel id='sub_t'>Submission Type</InputLabel>
              <Select
                labelId='sub_tt'
                name='submission_type'
                id='sub_t_select'
                value={data.submission_type}
                required
                renderValue={
                  data.submission_type === 'ORIG' || data.submission_type === 'SUPPL'
                    ? undefined
                    : () => <em>Select From Dropdown</em>
                }
                label='Submission Type'
                sx={styles.select}
                onChange={e =>
                  setData(prevData => {
                    return { ...prevData, submission_type: e.target.value };
                  })
                }>
                <MenuItem value='ORIG'>ORIG</MenuItem>
                <MenuItem value='SUPPL'>SUPPL</MenuItem>
              </Select>
              <InputLabel id='rld_status'>Status</InputLabel>
              <Select
                labelId='rld_status'
                name='status'
                id='status'
                value={data.status}
                label='Status'
                sx={styles.select}
                onChange={e =>
                  setData(prevData => {
                    return { ...prevData, status: e.target.value };
                  })
                }>
                <MenuItem value='ToQced'>ToQced</MenuItem>
                <MenuItem value='Qced'>Qced</MenuItem>
                <MenuItem value='Escalate'>Escalate</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name='appl_num'
                label='Application Number'
                variant='outlined'
                value={data.appl_num}
                sx={styles.text}
                inputProps={{ minLength: 6 }}
                required
                onChange={e =>
                  setData(prevData => {
                    return { ...prevData, appl_num: e.target.value };
                  })
                }
              />
              <TextField
                name='rld_appl_num'
                label='RLD Application Number'
                variant='outlined'
                value={data.rld_appl_num}
                sx={styles.text}
                inputProps={{ minLength: 6 }}
                required
                onChange={e =>
                  setData(prevData => {
                    return { ...prevData, rld_appl_num: e.target.value };
                  })
                }
              />
              <TextField
                name='submission_num'
                label='Submission Number'
                variant='outlined'
                required
                value={data.submission_num}
                sx={styles.text}
                onChange={e =>
                  setData(prevData => {
                    return { ...prevData, submission_num: e.target.value };
                  })
                }
              />
            </Grid>
            <Button type='submit' variant='contained' sx={styles.submit}>
              Update
            </Button>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default RldEditForm;
