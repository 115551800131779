import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField
} from '@material-ui/core';
import * as React from 'react';
import { useContext, useState } from 'react';
import { Save } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { insertQcdGuidance } from '../../api/AdminPage';
import Actions from '../../store/actions';
import AdminActions from '../../store/AdminPage/actions';
import Store from '../../store';
import AdminStore from '../../store/AdminPage';
import Styles from './styles/GuidanceList.styles';

// eslint-disable-next-line react/prop-types
const GuidanceAcceptance = ({ documents, withoutChecks, isReject, onClick, onClose }) => {
  const { dispatch } = useContext(Store);
  const { adminState, adminDispatch } = useContext(AdminStore);
  const history = useHistory();
  const classes = Styles();

  const [confirm, setConfirm] = useState({});

  const acceptGuidance = async message => {
    try {
      const localMessage = { ...message };
      setConfirm({});
      if (onClick) onClick();
      await adminDispatch({ type: AdminActions.SET_SUBMITTING, value: true });
      if (isReject) {
        localMessage.comment = `Compared with ${adminState.qcedGuidanceCompare.guidance_pk} - ${message.comment}`;
      }
      const res = await insertQcdGuidance({
        ...documents,
        ...localMessage
      });
      if (res.data && res.data.Success) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: isReject ? 'Guidance rejected' : 'Guidance accepted' }
        });
        if (onClose) onClose();
        history.go(0);
      } else {
        throw new Error(res.data.Error);
      }
      await adminDispatch({ type: AdminActions.SET_SUBMITTING, value: false });
    } catch (e) {
      await adminDispatch({ type: AdminActions.SET_SUBMITTING, value: false });
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Something went wrong' }
      });
    }
  };

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {isReject ? (
        <Button
          variant='outlined'
          color='secondary'
          className={classes.button}
          onClick={() => {
            setConfirm({
              status: 'Rejected'
            });
          }}>
          Reject
        </Button>
      ) : withoutChecks ? (
        <Button
          variant='outlined'
          color='secondary'
          className={classes.button}
          onClick={() => {
            setConfirm({
              status: 'Qced',
              comment: `Not compared with any document`
            });
          }}>
          Accept without comparing
        </Button>
      ) : (
        <Button
          variant='outlined'
          color='secondary'
          className={classes.button}
          onClick={() => {
            setConfirm({
              status: 'Qced',
              comment: `Compared with ${adminState.qcedGuidanceCompare.guidance_pk}`
            });
          }}>
          Accept
        </Button>
      )}

      <Dialog
        open={!!(confirm && confirm.status)}
        onClose={() => setConfirm({})}
        aria-labelledby='alert-dialog-confirm'
        aria-describedby='alert-dialog-confirm-action'>
        <DialogTitle id='alert-dialog-title'>Confirm action</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to {isReject ? 'reject' : 'accept'} this guidance?
            {isReject && (
              <>
                <br />
                <br />
                Please provide a comment: <br />
                <TextField
                  fullWidth
                  id='comment'
                  label='comment'
                  value={confirm.comment}
                  variant='outlined'
                  onChange={e => {
                    setConfirm(con => ({
                      ...con,
                      comment: e.target.value
                    }));
                  }}
                />
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.button}
            onClick={() => setConfirm({})}>
            Cancel
          </Button>

          <Button
            startIcon={<Save />}
            variant='contained'
            onClick={() => acceptGuidance(confirm)}
            color='secondary'
            className={classes.button}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default GuidanceAcceptance;
