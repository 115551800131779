import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Styles from './styles/Synonyms.styles';

import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';

import { getSynonymsByKey, updateSynonyms } from '../../api/AdminPage';

const UpdateSynonyms = ({ key_term }) => {
  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(GlobalStore);

  const [key_term_value, setKeyTerm] = useState('');
  const [synonyms, setSynonyms] = useState('');
  const [data, setData] = useState([]);

  async function getData(key_term) {
    try {
      console.log(key_term);
      const response = await getSynonymsByKey(key_term);
      console.log(response);
      setKeyTerm(response.data.key_term);
      setSynonyms(response.data.synonyms);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    getData(key_term);
  }, [key_term]);

  async function UpdateSynonyms(keyTerm, data) {
    try {
      const response = await updateSynonyms(keyTerm, data);
      setData(response.data);
      console.log(response.data);
      if (response.status === 200) {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: 'Saved Successfully!', color: 'success' }
        });
        history.go('/synonyms');
      } else if (response.status === 500) {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: { status: true, message: 'Please update Status before you save.' }
        });
      }
    } catch (e) {
      alert(e);
    }
  }

  const handleSubmit = event => {
    event.preventDefault();
    console.log(typeof synonyms);
    if (typeof synonyms === 'string') {
      var updated_synonyms = synonyms.split(',');
    } else {
      var updated_synonyms = synonyms;
    }
    console.log(typeof updated_synonyms);
    const data = {
      key_term: key_term_value,
      synonyms: updated_synonyms
    };
    console.log(data);
    UpdateSynonyms(key_term, data);
  };

  return (
    <Container maxWidth='xs'>
      <div className={classes.paper}>
        <Typography component='h1' variant='h5' className={classes.Heading}>
          Update Synonyms
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='key_term'
                label='Key Term'
                value={key_term_value}
                onChange={e => setKeyTerm(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='synonyms'
                label='Synonyms'
                value={synonyms}
                multiline
                maxRows={Infinity}
                onChange={e => setSynonyms(e.target.value)}
              />
            </Grid>
          </Grid>
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            className={classes.submit}>
            Update
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default UpdateSynonyms;
