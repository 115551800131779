import React, { useState, useEffect, ChangeEvent, SyntheticEvent, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// muiv5
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { Auth } from 'aws-amplify';
import utils from '../Datagrid/utils';
import sortLabelInfo from './utils';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// api
import {
  submitPageNumberDetails,
  getLabelDetails,
  getProcessedLabelInfo,
  submitProcessedLabelInfo
} from '../../api/Cber';

// styles
import styles from './styles/PageNumberEdit.styles';
import TextFieldCard from '../TextFieldCard';

const qc_status = ['Escalate', 'Missing Details', 'No Document', 'QCed'];

const labelInfo = [
  {
    id: 'indications_and_usage',
    labelName: 'Indication And Usage'
  },
  {
    id: 'dosage_and_administration',
    labelName: 'Dosage And Administration'
  },
  {
    id: 'dosage_forms_and_strengths',
    labelName: 'Dosage Forms And Strengths'
  },
  {
    id: 'contraindications',
    labelName: 'Contraindications'
  },
  {
    id: 'warnings_and_precautions',
    labelName: 'Warnings And Precautions'
  },
  {
    id: 'adverse_reactions',
    labelName: 'Adverse Reactions'
  },
  {
    id: 'drug_interactions',
    labelName: 'Drug Interactions'
  },
  {
    id: 'use_in_specific_populations',
    labelName: 'Use In Specific Populations'
  },
  {
    id: 'drug_abuse_and_dependence',
    labelName: 'Drug Abuse And Dependence'
  },
  {
    id: 'overdosage',
    labelName: 'Overdosage'
  },
  {
    id: 'description',
    labelName: 'Description'
  },
  {
    id: 'clinical_pharmacology',
    labelName: 'Clinical Pharmacology'
  },
  {
    id: 'non_clinical_toxicology',
    labelName: 'Non Clinical Toxicology'
  },
  {
    id: 'clinical_studies',
    labelName: 'Clinical Studies'
  },
  {
    id: 'references',
    labelName: 'References'
  },
  {
    id: 'how_supplied_storage_and_handling',
    labelName: 'How Supplied Storage And Handling'
  },
  {
    id: 'patient_counseling_information',
    labelName: 'Patient Counseling Information'
  }
];
const PLRPage = ({ format, docs_id, application_number, closeEdit, searchText, status }: any) => {
  type LabelData = {
    dosage_and_administration: number;
    dosage_forms_and_strengths: number;
    contraindications: number;
    warnings_and_precautions: number;
    adverse_reactions: number;
    drug_interactions: number;
    use_in_specific_populations: number;
    drug_abuse_and_dependence: number;
    overdosage: number;
    description: number;
    clinical_pharmacology: number;
    non_clinical_toxicology: number;
    clinical_studies: number;
    references: number;
    how_supplied_storage_and_handling: number;
    patient_counseling_information: number;
    indications_and_usage: number;
    qc_status: string;
    disabled_sections: Array<any>;
    [key: string]: number | string | Array<any>;
  };
  const { dispatch } = useContext<any>(Store);
  const history = useHistory();
  const location = useLocation();
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [labelData, setLabelData] = useState<LabelData>({
    dosage_and_administration: 0,
    dosage_forms_and_strengths: 0,
    contraindications: 0,
    warnings_and_precautions: 0,
    adverse_reactions: 0,
    drug_interactions: 0,
    use_in_specific_populations: 0,
    drug_abuse_and_dependence: 0,
    overdosage: 0,
    description: 0,
    clinical_pharmacology: 0,
    non_clinical_toxicology: 0,
    clinical_studies: 0,
    references: 0,
    how_supplied_storage_and_handling: 0,
    patient_counseling_information: 0,
    indications_and_usage: 0,
    qc_status: '',
    disabled_sections: []
  });
  useEffect(() => {
    const getLabelData = async () => {
      try {
        let result = null;
        if (location.pathname.includes('label_section')) {
          result = await getProcessedLabelInfo('plr', docs_id);
        } else {
          result = await getLabelDetails(docs_id);
        }
        if (!result?.data || result.status !== 200) {
          throw Error('Result Not Available');
        } else if (result.data) {
          let resultData = null;
          if (location.pathname.includes('label_section')) {
            resultData = result.data.body.results;
          } else {
            resultData = result.data.body.result;
          }
          setLabelData({
            dosage_and_administration: resultData.dosage_and_administration ?? 0,
            dosage_forms_and_strengths: resultData.dosage_forms_and_strengths ?? 0,
            contraindications: resultData.contraindications ?? 0,
            warnings_and_precautions: resultData.warnings_and_precautions ?? 0,
            adverse_reactions: resultData.adverse_reactions ?? 0,
            drug_interactions: resultData.drug_interactions ?? 0,
            use_in_specific_populations: resultData.use_in_specific_populations ?? 0,
            drug_abuse_and_dependence: resultData.drug_abuse_and_dependence ?? 0,
            overdosage: resultData.overdosage ?? 0,
            description: resultData.description ?? 0,
            clinical_pharmacology: resultData.clinical_pharmacology ?? 0,
            non_clinical_toxicology: resultData.non_clinical_toxicology ?? 0,
            clinical_studies: resultData.clinical_studies ?? 0,
            references: resultData.references ?? 0,
            how_supplied_storage_and_handling: resultData.how_supplied_storage_and_handling ?? 0,
            patient_counseling_information: resultData.patient_counseling_information ?? 0,
            indications_and_usage: resultData.indications_and_usage ?? 0,
            disabled_sections: resultData.disabled_sections ?? [],
            qc_status:
              resultData.qc_status ?? resultData.qc_status === 'qced'
                ? 'QCed'
                : utils(resultData.qc_status, 'formulations')
          });
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getLabelData();
  }, []);
  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        indications_and_usage: labelData.indications_and_usage ?? '',
        dosage_and_administration: labelData.dosage_and_administration ?? 0,
        dosage_forms_and_strengths: labelData.dosage_forms_and_strengths ?? 0,
        contraindications: labelData.contraindications ?? 0,
        warnings_and_precautions: labelData.warnings_and_precautions ?? 0,
        adverse_reactions: labelData.adverse_reactions ?? 0,
        drug_abuse_and_dependence: labelData.drug_abuse_and_dependence ?? 0,
        drug_interactions: labelData.drug_interactions ?? 0,
        use_in_specific_populations: labelData.use_in_specific_populations ?? 0,
        overdosage: labelData.overdosage ?? 0,
        description: labelData.description ?? 0,
        clinical_pharmacology: labelData.clinical_pharmacology ?? 0,
        non_clinical_toxicology: labelData.non_clinical_toxicology ?? 0,
        clinical_studies: labelData.clinical_studies ?? 0,
        references: labelData.references ?? 0,
        how_supplied_storage_and_handling: labelData.how_supplied_storage_and_handling ?? 0,
        patient_counseling_information: labelData.patient_counseling_information ?? 0,
        qc_status: labelData.qc_status.toLowerCase() ?? '',
        application_number,
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      };
      const res = await submitPageNumberDetails(format.toLowerCase(), docs_id, payload);
      if (!res?.data || res?.data?.status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Error while Submitting ${docs_id}` }
        });
      } else if (res.data.body) {
        closeEdit();
        history.push(`/cber/reviews/label/${status}/${searchText}`);
        window.location.reload();
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: res.data.body, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  const handleProcessedSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        application_docs_id: docs_id,
        indications_and_usage: labelData.indications_and_usage ?? '',
        dosage_and_administration: labelData.dosage_and_administration ?? 0,
        dosage_forms_and_strengths: labelData.dosage_forms_and_strengths ?? 0,
        contraindications: labelData.contraindications ?? 0,
        warnings_and_precautions: labelData.warnings_and_precautions ?? 0,
        adverse_reactions: labelData.adverse_reactions ?? 0,
        drug_abuse_and_dependence: labelData.drug_abuse_and_dependence ?? 0,
        drug_interactions: labelData.drug_interactions ?? 0,
        use_in_specific_populations: labelData.use_in_specific_populations ?? 0,
        overdosage: labelData.overdosage ?? 0,
        description: labelData.description ?? 0,
        clinical_pharmacology: labelData.clinical_pharmacology ?? 0,
        non_clinical_toxicology: labelData.non_clinical_toxicology ?? 0,
        clinical_studies: labelData.clinical_studies ?? 0,
        references: labelData.references ?? 0,
        how_supplied_storage_and_handling: labelData.how_supplied_storage_and_handling ?? 0,
        patient_counseling_information: labelData.patient_counseling_information ?? 0,
        qc_status: labelData.qc_status.toLowerCase() ?? '',
        application_number,
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      };
      const res = await submitProcessedLabelInfo('plr', payload);
      if (!res?.data || res?.status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Error while Submitting ${docs_id}` }
        });
      } else if (res.data.body) {
        closeEdit();
        const baseURL = `/cber/label_section/plr?qc_status=${status}`;
        const queryParams = [];
        if (searchText !== '') {
          queryParams.push(`application_number=${searchText}`);
        }
        const finalURL = `${baseURL}&${queryParams.join('&')}`;
        history.push(finalURL);
        setTimeout(function () {
          window.location.reload();
        }, 1000);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: res.data.body, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };
  return (
    <form
      onSubmit={(event: any) => {
        if (location.pathname.includes('label_section')) {
          handleProcessedSubmit(event);
        } else {
          handleSubmit(event);
        }
      }}
      style={styles.form}>
      <Grid container sx={{ overflow: 'auto', height: '75vh', padding: 2 }}>
        <Grid item xs={12} display='flex' direction='column'>
          {sortLabelInfo(labelInfo, labelData).map((arg, id) => {
            return (
              <TextFieldCard
                key={arg.id}
                id={arg.id}
                labelName={arg.labelName}
                data={labelData}
                setData={setLabelData}
                setDisableSubmit={setDisableSubmit}
              />
            );
          })}
          <Autocomplete
            id='qc_status'
            fullWidth
            value={labelData.qc_status}
            options={qc_status}
            onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
              if (newValue !== null) {
                setLabelData(prevData => {
                  return { ...prevData, qc_status: newValue };
                });
              }
            }}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '1.5vh',
                minWidth: '250px'
              },
              '& .MuiFormLabel-root': {
                fontSize: '1.5vh'
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label='QC Status'
                variant='outlined'
                required
                InputLabelProps={{
                  style: { fontSize: '2vh', fontWeight: 'bold' }
                }}
              />
            )}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={styles.submit}
            disabled={disableSubmit}>
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PLRPage;
