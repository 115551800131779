import { Tab, Tabs } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography } from '@mui/material';
import Styles from '../Home/styles/Home.styles';

const TabPanel = props => {
  const { children, value, index, pdfUrl, ...other } = props;
  const classes = Styles();

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      style={{ height: '65vh' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}>
      {value === index && (
        <iframe width='100%' height='100%' title='pdf' src={pdfUrl} className={classes.pdfEma} />
      )}
    </div>
  );
};

TabPanel.propTypes = {
  // eslint-disable-next-line react/require-default-props
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  pdfUrl: PropTypes.string.isRequired
};

const PDFViewer = ({ pdf, setSelectedS3Link }) => {
  const classes = Styles();

  const [tab, setTab] = useState(0);

  const handleChange = (event, newValue) => {
    setTab(newValue);
    if (setSelectedS3Link) {
      setSelectedS3Link(pdf[newValue].s3_link);
    }
  };
  useEffect(() => {
    if (pdf && setSelectedS3Link) {
      setSelectedS3Link(pdf[0].s3_link);
    }
  }, [pdf]);
  if (pdf.length !== 0) {
    // eslint-disable-next-line react/prop-types
    if (pdf.length > 1) {
      return (
        <Box>
          <Tabs
            value={tab}
            onChange={handleChange}
            color='primary'
            aria-label='admin tabs'
            scrollButtons
            allowScrollButtonsMobile>
            {/* eslint-disable-next-line no-shadow,react/prop-types */}
            {pdf.map(({ document_title: title }, index) => {
              return (
                <Tab
                  className={classes.tab}
                  key={title}
                  label={title}
                  id={index}
                  color='primary'
                  aria-controls={`tabpanel-${title}`}
                  wrapped
                />
              );
            })}
          </Tabs>

          {/* eslint-disable-next-line no-shadow */}
          {pdf.map(({ s3_url: pdfUrl, id }, index) => {
            return <TabPanel key={id} value={tab} index={index} pdfUrl={pdfUrl} />;
          })}
        </Box>
      );
    }
    return pdf[0] ? (
      <iframe
        width='100%'
        height='100%'
        title='pdf'
        src={pdf[0].s3_url}
        className={classes.pdfEma}
      />
    ) : (
      <Box display='flex' alignItems='center' justifyContent='center' width='100%'>
        <p>No PDF available</p>
      </Box>
    );
  }
  return <Typography className={classes.notAvailable}>No pdf is available</Typography>;
};
PDFViewer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  pdf: PropTypes.array.isRequired,
  // eslint-disable-next-line react/require-default-props
  setSelectedS3Link: PropTypes.func
};
export default PDFViewer;
