function sortLabelInfo(
  labelInfo: { id: string; labelName: string }[],
  labelData: { [key: string]: any }
): { id: string; labelName: string }[] {
  return labelInfo.sort((a, b) => {
    const aValue = labelData[a.id] as number;
    const bValue = labelData[b.id] as number;
    if (aValue === 0) {
      // a value is 0, move it to the end
      return 1;
    }
    if (bValue === 0) {
      // b value is 0, move it to the end
      return -1;
    }
    // sort by non-zero value
    return aValue - bValue;
  });
}
export default sortLabelInfo;
