import React, { useContext, useEffect, useState } from 'react';

// mui
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DatagridFooter from '../../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../../Datagrid/DatagridNoRowsOverlay';

// api
import { getAllNonQcdEuaDoc } from '../../../api/eua';
import Actions from '../../../store/actions';
import Store from '../../../store';

// styles
import themev5 from '../../../themev5';
import Styles, { styles } from './styles/EUAcuration.styles';

// component
import EditApplicationField from './EditApplicationField';
import SubmissionCurationForm from './SubmissionCurationForm';
import CurationButton from './CurationButton';

const EUACuration = () => {
  const classes = Styles();
  const { dispatch } = useContext(Store) as any;
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState() as any;
  const [pageSize, setPageSize] = useState(10);
  const [NonQcedData, setNonQcedData] = useState([]) as any;
  const [selectedData, setSelectedData] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [openSubmission, setOpenSubmission] = useState<boolean>(false);

  const rowsPerPageOptions = [10, 20, 50, 100];

  const fetchData = async () => {
    try {
      setLoading(true);
      const res = await getAllNonQcdEuaDoc(pageSize, page);
      if (res.data.body && res.data.status) {
        if (Array.isArray(res.data.body.results)) {
          setNonQcedData(res.data.body.results);
          setRowCount(res.data.body.total_rows);
        } else {
          setNonQcedData([]);
          setRowCount(0);
        }
      } else if (res.data && res.data.status === 404) {
        setNonQcedData([]);
      } else {
        throw new Error(res.data.error);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setRowCount(0);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Something went wrong' }
      });
    }
  };

  useEffect(() => {
    fetchData();
  }, [page, pageSize]);

  const closeEditDialog = () => {
    setShowEditDialog(false);
    setOpenSubmission(false);
    fetchData();
  };

  const openEditDialog = (data: any) => {
    setSelectedData(data);
    setShowEditDialog(true);
  };

  const openSubmissionDialog = (data: any) => {
    setSelectedData(data);
    setOpenSubmission(true);
    setShowEditDialog(true);
  };

  const DATAGRID_COLUMN = [
    {
      field: 'Edit',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              openEditDialog(params.row);
            }}>
            <Typography sx={{ color: 'white.main' }}>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'title',
      headerName: 'Applications',
      width: 475,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell'
    },
    {
      field: 'Submissions',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            sx={styles.Button2}
            onClick={() => {
              openSubmissionDialog(params.row);
            }}>
            <Typography sx={{ color: 'white.main' }}>Add Submissions</Typography>
          </Button>
        );
      }
    }
  ];

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        <Typography
          component='h2'
          variant='h5'
          style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
          EUA Application Curation
        </Typography>
      </Box>
      <Box mt={2}>
        <CurationButton />
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          style={{ height: '100%' }}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={NonQcedData}
          columns={DATAGRID_COLUMN as any}
          rowCount={rowCount}
          pageSize={pageSize}
          getRowId={row => row.vivpro_appl_no}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPage(0);
          }}
          onPageChange={setPage}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={page}
          paginationMode='server'
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        <Dialog
          open={showEditDialog}
          onClose={closeEditDialog}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          {openSubmission ? (
            <SubmissionCurationForm closeEdit={closeEditDialog} selectedData={selectedData} />
          ) : (
            <EditApplicationField closeEdit={closeEditDialog} selectedData={selectedData} />
          )}
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(EUACuration);
