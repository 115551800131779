import EMALabelFetch from '../components/EMA/EMALabelFetch';
import EMAPageSeparation from '../components/EMA/EMAPageSeparation';
import EMALabelCreation from '../components/EMA/EMALabelCreation';
import ProceduralStepsEMA from '../components/EMA/ProceduralStepsEMA';
import ProceduralStepsEMAReview from '../components/EMA/ProceduralStepsEMAReview';
import EMAProductsPage from '../components/EMA/EMAProductsPage';
import EMAProductsReviewPage from '../components/EMA/EMAProductsReviewPage';
import EMACuration from '../components/EMA/EMACuration';
import EMALabelCurationFetch from '../components/EMA/EMALabelCurationFetch';
import EmaATC from '../components/EMA/EmaATC';
import EMAData from '../components/EMA/Data/EMAData';

const EMA_ROUTES = [
  {
    id: 'emaLabelText',
    path: '/ema/labelEma',
    component: EMALabelCurationFetch,
    exact: true
  },
  {
    id: 'labelIndications',
    path: '/ema/labelEma/:closeDate/:applicationNumber',
    component: EMACuration,
    exact: true
  },
  {
    id: 'emaLabelSeparationSectionForm',
    path: '/ema/label/section/',
    component: EMALabelFetch,
    exact: true
  },
  {
    id: 'emaLabelSeparationSection',
    path: '/ema/label/section/:closeDate/:applicationNumber/:documentNumber',
    component: EMAPageSeparation,
    exact: true
  },
  {
    id: 'emaLabelCreationSection',
    path: '/ema/label/creation/:closeDate/:applicationNumber/:documentNumber',
    component: EMALabelCreation,
    exact: true
  },

  {
    id: 'proceduralSteps',
    path: '/ema/proceduralStepsEma',
    component: ProceduralStepsEMA,
    exact: true
  },
  {
    id: 'proceduralStepsReview',
    path: '/ema/proceduralSteps/:applNo',
    component: ProceduralStepsEMAReview,
    exact: true
  },
  {
    id: 'emaProducts',
    path: '/ema/products',
    component: EMAProductsPage,
    exact: true
  },
  {
    id: 'emaProducts',
    path: '/ema/products/:applNo',
    component: EMAProductsReviewPage,
    exact: true
  },
  {
    id: 'emaATCCuration',
    path: '/ema/atc',
    component: EmaATC,
    exact: true
  },
  {
    id: 'emaData',
    path: '/ema/data',
    component: EMAData,
    exact: true
  },
];

export default EMA_ROUTES;
