import React, { useEffect, useState, useCallback } from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import { Loading } from '../EMA/EMAPageSeparation';
import Select from './Select';

import {
  getSBASAttributeData,
  deleteSBASAttributeData,
  updateSBASAttributeData
} from '../../api/submission_details';
import Styles from './styles/SBASAttributeDataStyle';
import { qcStatus } from '../RegulatoryInformation/constant';

const SBASAttributeData = ({
  applno,
  submission_type,
  submission_number,
  updateTrigger,
  alertFn,
  documentTypeId,
  attributeId,
  setSBASData,
  sbasData,
  getAttributeSequence
}: any) => {
  const [loading, setLoading] = useState(false);
  const [localUpdateTrigger, setLocalUpdateTrigger] = useState(false);
  const [editModeId, setEditModeId] = useState<any>(null);
  const [editAttributeVal, setEditAttributeVal] = useState<any>(null);
  const [editAttributeTextTitle, setEditAttributeTextTitle] = useState<string>('');
  const [editAttributeQCStatus, setEditAttributeQCStatus] = useState<string>('');
  const classes = Styles();

  const setupEditMode = (
    id: number,
    attribute_value: any,
    attribute_title: any,
    qc_status: string
  ) => {
    setEditModeId(id);
    setEditAttributeTextTitle(attribute_title);
    setEditAttributeVal(attribute_value);
    setEditAttributeQCStatus(qc_status);
  };

  const resetEditMode = () => {
    setEditModeId(null);
    setEditAttributeTextTitle('');
    setEditAttributeVal(null);
    setEditAttributeQCStatus(qcStatus[0]);
  };

  useEffect(() => {
    const getSBASAttributeDataHelper = async () => {
      setLoading(true);
      const response = await getSBASAttributeData(
        applno,
        submission_type,
        submission_number,
        documentTypeId,
        attributeId
      ).catch(console.error);
      if (response.data?.Status === 200) {
        setSBASData(response.data.Success);
      } else {
        alertFn('Failed to get SBAS attribute data', 'error');
      }
      setLoading(false);
    };
    getSBASAttributeDataHelper();
  }, [updateTrigger, localUpdateTrigger]);

  const onDelete = async (id: number) => {
    const response = await deleteSBASAttributeData(id).catch(console.error);
    if (response.data?.Status === 200) {
      setLocalUpdateTrigger(prev => !prev);
    } else {
      alertFn('Failed to delete SBAS attribute data', 'error');
    }
  };

  const updateData = useCallback(
    async (id: number, attribute_title: string, attribute_seq: number) => {
      let payload: {
        attribute_title: string;
        attribute_value: number;
        attribute_seq?: number;
        qc_status: string;
      } = {
        attribute_title: editAttributeTextTitle,
        attribute_value: editAttributeVal !== 0 ? editAttributeVal : '',
        qc_status: editAttributeQCStatus,
        attribute_seq
      };

      if (attribute_title !== payload.attribute_title || !attribute_seq) {
        /**
         * If new attribute_title doesn't match the old one,
         * then attribute_seq has to be calculated again.
         */
        payload = {
          ...payload,
          attribute_seq: getAttributeSequence(payload.attribute_title)
        };
      }
      const response = await updateSBASAttributeData(id, payload).catch(console.error);
      if (response.data?.Status === 200) {
        resetEditMode();
        setLocalUpdateTrigger(prev => !prev);
      } else {
        alert('Failed to update SBAS attribute data.');
      }
    },
    [editAttributeTextTitle, editAttributeVal, editAttributeQCStatus]
  );

  if (!sbasData.length) return null;
  return (
    <Box width={'100%'} marginTop='15px' sx={{ '& .MuiTableCell-root': { pl: '4px', pr: '4px' } }}>
      {loading && (
        <Box>
          <Loading dataLoading={loading} dataSubmitting={false} />
        </Box>
      )}

      <TableContainer component={Paper}>
        <Table aria-label='customized table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Seq.</TableCell>
              <TableCell align='center'>Sample Size</TableCell>
              <TableCell align='center'>Description</TableCell>
              <TableCell align='center'>QC Status</TableCell>
              <TableCell align='center'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sbasData.map(
              ({
                id,
                attribute_seq,
                attribute_title,
                attribute_value,
                qc_status
              }: {
                id: number;
                attribute_title: string;
                attribute_seq: number;
                attribute_value: number;
                qc_status: string;
              }) => (
                <TableRow key={`sbas-data-${id}`}>
                  <TableCell align='center'>{attribute_seq}</TableCell>
                  <TableCell component='th' scope='row' align='center'>
                    {editModeId === id ? (
                      <TextField
                        placeholder='Sample Size'
                        className={classes.textField}
                        type={'number'}
                        multiline
                        sx={{ '& .MuiInputBase-root': { p: '0px', width: '100%' } }}
                        onChange={e => {
                          let val = +e.target.value;
                          if (typeof val === 'number') {
                            setEditAttributeVal(val);
                          }
                        }}
                        value={editAttributeVal}
                      />
                    ) : (
                      attribute_value
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    {editModeId === id ? (
                      <TextField
                        multiline
                        sx={{ '& .MuiInputBase-root': { p: '4px' }, width: '100%' }}
                        className={classes.textField}
                        placeholder='Description'
                        onChange={e => setEditAttributeTextTitle(e.target.value)}
                        value={editAttributeTextTitle}
                      />
                    ) : (
                      attribute_title
                    )}
                  </TableCell>
                  <TableCell>
                    {editModeId !== id ? (
                      qc_status
                    ) : (
                      <Select
                        sx={{ minWidth: 75, maxWidth: 75 }}
                        value={editAttributeQCStatus}
                        label='QC Status'
                        onChange={(event: any) => setEditAttributeQCStatus(event.target.value)}
                        options={qcStatus.map(st => ({ label: st, value: st }))}
                      />
                    )}
                  </TableCell>
                  <TableCell align='center'>
                    <Box style={{ flexDirection: 'row' }}>
                      {editModeId !== id ? (
                        <>
                          <Button
                            onClick={() =>
                              setupEditMode(id, attribute_value, attribute_title, qc_status)
                            }>
                            Edit
                          </Button>
                          <Button color='error' onClick={() => onDelete(id)}>
                            Delete
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            disabled={!editAttributeVal && !editAttributeTextTitle}
                            onClick={() => updateData(id, attribute_title, attribute_seq)}>
                            Save
                          </Button>
                          <Button color='error' onClick={resetEditMode}>
                            Cancel
                          </Button>
                        </>
                      )}
                    </Box>
                  </TableCell>
                </TableRow>
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default SBASAttributeData;
