import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// material ui
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AdminFormTextField from '../StyledComponents/TextField';

// api
import { getFdaData, submitNonPLRFdaData } from '../../api/AdminPage';

// helpers
import { handleChange } from '../../helpers/changeHandler';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// styles
import Styles from '../RLD/styles/RLD.styles';
import PNReviewFda from '../FDA/PNReviewFda';

const Breakthrough = () => {
  const { applNo, subType, subNo } = useParams();
  const docType = 'breakthrough';
  const history = useHistory();

  const classes = Styles();
  const { state, dispatch } = useContext(Store);
  const [applType, setApplType] = useState();
  const [appDate, setAppDate] = useState();
  const [indicationsAndUsage, setIndication] = useState();
  const [tradeName, setTradeName] = useState();
  const [activeIngredient, setActiveIngredient] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let res;
      try {
        res = await getFdaData(applNo, subType, subNo, docType);
        if (res.data.Success.fda_data_qc.length > 0) {
          setIsAdd(false);
        } else setIsAdd(true);
      } catch (error) {
        console.error(error.message);
        history.push(`/approval`);
      }
    }

    fetchData();
  }, [dispatch, history]);

  const handleApprovalDateChange = date => {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : String(date.getDate());
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : String(date.getMonth() + 1);
    const year = String(date.getFullYear());
    const formattedDate = `${year}-${month}-${day}`;
    setFromDate(date);
    setAppDate(formattedDate);
  };

  const handleKeyFormSubmit = async e => {
    e.preventDefault();
    const key = `${applNo}-${subType}-${subNo}`;
    try {
      const payload = {
        appl_type: applType,
        appl_num: applNo,
        submission_type: subType,
        submission_num: subNo,
        approval_date: appDate,
        breakthrough_indication: indicationsAndUsage,
        indication_status: 'Qced',
        trade_name: tradeName,
        active_ingredient: activeIngredient,
        key,
        curator_email: state.userStub.email,
        curator_name: state.userStub['custom:user'],
        doctype: 'breakthrough'
      };
      await submitNonPLRFdaData(payload);
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Breakthrough table was successfully updated ',
          color: 'success'
        }
      });

      setApplType('');
      history.push('/approval');
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Something went wrong'
        }
      });

      console.error(error.message);
    }
  };
  if (isAdd) {
    return (
      <Box className={classes.root} display='flex' alignItems='center'>
        <Container maxWidth='lg' className={classes.container}>
          <Paper elevation={1} className={classes.banner}>
            <Typography className={classes.formHeadings}>
              Enter details for Breakthrough data
            </Typography>
            <form onSubmit={handleKeyFormSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                  <Box margin={1} display='flex' flexDirection='column' alignItems='center'>
                    <Typography className={classes.label}>Application Type</Typography>
                    <AdminFormTextField
                      required
                      value={applType}
                      onChange={event => setApplType(handleChange(event))}
                      placeholder='Enter application type Eg: NDA'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                    <Typography className={classes.label}>Approval Date</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        required
                        className={classes.AdvancedSearchDatePicker}
                        value={fromDate}
                        label='Select Date'
                        keyboardIcon={<EventNoteIcon className={classes.calendarIcon} />}
                        InputProps={{
                          classes: {
                            input: classes.resize
                          }
                        }}
                        format='MM/dd/yyyy'
                        onChange={date => handleApprovalDateChange(date)}
                      />
                    </MuiPickersUtilsProvider>
                    <Typography className={classes.label}>Breakthrough Indication</Typography>
                    <AdminFormTextField
                      required
                      value={indicationsAndUsage}
                      onChange={event => setIndication(handleChange(event))}
                      placeholder='Enter Breakthrough Indication'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                    <Typography className={classes.label}>Trade Name</Typography>
                    <AdminFormTextField
                      required
                      value={tradeName}
                      onChange={event => setTradeName(handleChange(event))}
                      placeholder='Enter Trade Name'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                    <Typography className={classes.label}>Active Ingredient</Typography>
                    <AdminFormTextField
                      required
                      value={activeIngredient}
                      onChange={event => setActiveIngredient(handleChange(event))}
                      placeholder='Enter Active Ingredient'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                    <Button type='submit' variant='contained' className={classes.button}>
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </Box>
    );
  }
  return (
    <PNReviewFda applNo={applNo && applNo} subType={subType} subNo={subNo} docType={docType} />
  );
};

export default Breakthrough;
