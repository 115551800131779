import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import TextField from '@mui/material/TextField';

interface TextFormProps {
    selectType: string;
    index: number;
    handleStatsRecordChange: (fieldName: string, value: string) => void
    handleNext: () => void
    handleBack: () => void
}

const TextForm: React.FC<TextFormProps> = ({
    selectType,
    handleStatsRecordChange,
    index,
    handleBack,
    handleNext
}) => {
    const [value, setValue] = useState('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleContinue = () => {
        handleStatsRecordChange(selectType === 'Active Data Source' ? 'active_data_source' : selectType.toLowerCase(), value)
        handleNext()
    }

    return (
        <Box
            component="form"
            sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
            }}
            noValidate
            autoComplete="off"
        >
            <div>
                <TextField
                    id="description"
                    label="Description"
                    multiline
                    rows={3}
                    defaultValue={value}
                    onChange={handleChange}
                />
            </div>

            <Box sx={{ mb: 2 }}>
                <div>
                    <Button
                        variant="contained"
                        onClick={handleContinue}
                        sx={{ mt: 1, mr: 1, color: 'primary' }}
                        disabled={value ? false : true}
                    >
                        {'Continue'}
                    </Button>
                    <Button
                        disabled={index === 0}
                        onClick={handleBack}
                        sx={{ mt: 1, mr: 1 }}
                    >
                        Back
                    </Button>
                </div>
            </Box>

        </Box>
    );
}

export default TextForm;