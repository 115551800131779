import React, { useContext, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Box, Button, IconButton, Link, Paper, Typography } from '@material-ui/core';
import AuthContext from '../../../store/Auth/AuthProvider';
import Store from '../../../store';
import Actions from '../../../store/actions';

import LoginTextfield from '../../../components/StyledComponents/TextFieldLogin';

import Styles from '../styles/Login.styles';

import VivproLogo from '../../../assets/svgs/Login/loginLogo';
import User from '../../../assets/svgs/Login/user';
import Mail from '../../../assets/svgs/Login/password';

import NewUserPassword from './NewUserPassword';

const Login = () => {
  const classes = Styles();
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [user, setUser] = useState(false);
  const [newUser, setNewUser] = useState();

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      const userDetails = await Auth.signIn(username, password);
      if (
        // eslint-disable-next-line no-prototype-builtins
        userDetails.hasOwnProperty('challengeName') &&
        userDetails.challengeName === 'NEW_PASSWORD_REQUIRED'
      ) {
        setNewUser(userDetails);
        return;
      }
      if (userDetails) {
        setCurrentUser(userDetails.attributes);
        setUser(true);
        // used for pendo user analytics
        localStorage.setItem('visitor_id', userDetails.attributes.sub);
        localStorage.setItem('organization', userDetails.attributes['custom:organization']);
      }
      history.push('/');
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    }
  };
  if (user || currentUser) {
    return <Redirect to='/core' />;
  }
  if (newUser) {
    return <NewUserPassword newUser={newUser} setNewUser={setNewUser} />;
  }
  return (
    <Box
      className={classes.outline}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'>
      <Box
        className={classes.header}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'>
        <VivproLogo />
        <Typography className={classes.title}>Sign In</Typography>
        <Typography className={classes.description}>
          Enter your Vivpro Email and Password to continue
        </Typography>
      </Box>
      <Box
        className={classes.form}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'>
        <form onSubmit={handleSubmit}>
          <Paper className={classes.paper} elevation={0}>
            <IconButton className={classes.textfieldIcon} disabled>
              <User />
            </IconButton>
            <LoginTextfield
              InputProps={{ disableUnderline: true }}
              placeholder='Email Address'
              id='email'
              name='email'
              onChange={event => setUsername(event.target.value.trim())}
            />
          </Paper>
          <Paper className={classes.paper} elevation={0}>
            <IconButton className={classes.textfieldIcon} disabled>
              <Mail />
            </IconButton>
            <LoginTextfield
              InputProps={{ disableUnderline: true }}
              name='password'
              placeholder='Password'
              type='password'
              id='password'
              onChange={event => setPassword(event.target.value)}
            />
          </Paper>
          <Button className={classes.button} type='submit' variant='contained'>
            Login to continue
          </Button>
        </form>
      </Box>
      <Box
        className={classes.footer}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'>
        <Link href='/reset' className={classes.link}>
          Change Password / Forgot password?
        </Link>
        {/* <SSOButton/> */}
      </Box>
    </Box>
  );
};

export default Login;
