import { useState, useContext, useEffect, MouseEvent } from 'react';

// material ui
import { Box, Typography } from '@material-ui/core';
import {
  ToggleButtonGroup,
  ToggleButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Tooltip
} from '@mui/material';
import InfoIcon from '@material-ui/icons/Info';

// context
import Store from '../../store';
import Actions from '../../store/actions';

import Styles from './styles/CurationStatusToggle.styles';
import { getCurationStatus, updateCurationStatus } from '../../api/Common';

type CurationStatusToggleProps = {
  source: string;
  task: string;
};

const CurationStatusToggle = ({ source, task }: CurationStatusToggleProps) => {
  const classes = Styles();
  const { dispatch } = useContext<any>(Store);
  const [currentCurationStatus, setCurrentCurationStatus] = useState('');
  const [newCurationStatus, setNewCurationStatus] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const CURATION_STATUS = {
    processing: 'processing',
    done: 'done'
  };

  useEffect(() => {
    const getStatus = async () => {
      const result = await getCurationStatus(source, task);

      if (
        !result?.status ||
        result.status !== 200 ||
        !result.data?.status ||
        result.data.status !== 200
      ) {
        return;
      }

      setCurrentCurationStatus(
        result?.data?.body?.status ? CURATION_STATUS.processing : CURATION_STATUS.done
      );
    };

    getStatus();
  }, []);

  const handleCurationStatusChange = async (_: MouseEvent<HTMLElement>, newStatus: string) => {
    if (!newStatus) {
      return;
    }

    setNewCurationStatus(newStatus);
    setIsDialogOpen(true);
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  const handleDialogSubmit = async () => {
    try {
      const result = await updateCurationStatus(
        source,
        task,
        newCurationStatus === CURATION_STATUS.processing
      );

      if (
        !result?.status ||
        result.status !== 200 ||
        !result.data?.status ||
        result.data.status !== 200
      ) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: result.data.message }
        });

        return;
      }

      setCurrentCurationStatus(newCurationStatus);

      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: `Success: Curation status updated to ${newCurationStatus.toUpperCase()}`,
          color: 'success'
        }
      });
    } catch (exception) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: (exception as Error).message }
      });
    }

    handleDialogClose();
  };

  return (
    <Box textAlign='center'>
      <Typography className={classes.label}>
        Curation Status
        <Tooltip
          title={
            <span>
              <strong>DONE:</strong> Curation process is completed
              <br />
              <strong>PROCESSING:</strong> Curation process currently in-progress
            </span>
          }
          placement='top'
          arrow>
          <InfoIcon fontSize='small' className={classes.infoIcon} />
        </Tooltip>
      </Typography>
      <ToggleButtonGroup
        className={classes.root}
        color='primary'
        value={currentCurationStatus}
        exclusive
        onChange={handleCurationStatusChange}
        aria-label='Curation Status'>
        <ToggleButton value={CURATION_STATUS.processing}>Processing</ToggleButton>
        <ToggleButton value={CURATION_STATUS.done}>Done</ToggleButton>
      </ToggleButtonGroup>
      <Dialog
        open={isDialogOpen}
        onClose={handleDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Confirmation</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to change the curation status{' '}
            {newCurationStatus.length > 0 && `to ${newCurationStatus.toUpperCase()}`}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Disagree</Button>
          <Button onClick={handleDialogSubmit}>Agree</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CurationStatusToggle;
