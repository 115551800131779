import { useState, useContext, useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InfoIcon from '@material-ui/icons/Info';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Close from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import Tooltip from '@mui/material/Tooltip';
import Store from '../../../store';
import Actions from '../../../store/actions';

import { fetchCurationDetails, updateCurationDetails } from '../../../api/eua';

const CurationButton = () => {
  const [loading, setloading] = useState(false);
  const [curationStatus, setCurationStatus] = useState<any>({});
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const { dispatch } = useContext<any>(Store);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);

  const fetchCurationStatus = async () => {
    setloading(true);
    try {
      const result = await fetchCurationDetails();

      if (result === undefined) {
        throw new Error('Result is undefined');
      }
      if (result.data && result.data.body.results) {
        setCurationStatus(result.data.body.results);
        setDisableSubmit(
          Object.values(result.data.body.results)
            .map((obj: any) => obj.status.toLowerCase === 'running')
            .includes(true)
        );
      } else {
        setloading(false);
        throw Error('No data');
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
      setloading(false);
    }
    setloading(false);
    setShowEditDialog(true);
  };

  const openEditDialog = useCallback(() => {
    fetchCurationStatus();
  }, []);

  const handleClick = async () => {
    try {
      const result = await updateCurationDetails({
        dag_id: 'EUARefresh',
        task_to_run: 'after_curation'
      });

      if (result.data.status === 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: result.data.message, color: 'success' }
        });
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: result.data.message, color: 'error' }
        });
      }
      setCurationStatus(false);
    } catch (exception) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: (exception as Error).message }
      });
      setShowEditDialog(false);
      setCurationStatus(false);
    }
    setShowEditDialog(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        alignItems: 'flex-end',
        paddingRight: 1,
        flexDirection: 'column'
      }}>
      <Box display='flex' flexDirection='row'>
        <InfoIcon fontSize='small' />
        <Typography variant='body1'>Note: Click when EUA Cuation is completed</Typography>
      </Box>

      <Button
        variant='contained'
        disabled={loading}
        onClick={openEditDialog}
        sx={{
          textTransform: 'capitalize',
          color: 'white.main'
        }}>
        <Typography variant='body1'>Curation Status</Typography>
      </Button>
      <Dialog
        disableEscapeKeyDown
        fullWidth
        maxWidth='xs'
        open={showEditDialog}
        onClose={() => setShowEditDialog(false)}>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography style={{ color: '#3AB09E', fontSize: '20px', fontWeight: 'bold' }}>
              Confirm Submit
            </Typography>
            <IconButton aria-label='close' onClick={() => setShowEditDialog(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ fontSize: '16px', color: 'GrayText', marginRight: '0' }}>
            {disableSubmit
              ? 'Currently, Dag is running. Try again sometime later '
              : 'Are you sure label and letter both curation completed ?'}
          </Typography>

          {Object.keys(curationStatus).length !== 0 && (
            <Stack mt={2} flex='display' flexDirection='column'>
              {Object.entries(curationStatus).map(([key, value]: any) => (
                <Typography style={{ fontSize: '16px', marginRight: '0' }}>
                  {`${key}`} :{' '}
                  <span
                    style={{
                      color:
                        value &&
                        ['running', 'queued', 'up for retry'].includes(value?.status.toLowerCase())
                          ? 'red'
                          : 'green'
                    }}>
                    {`${value.status}`}{' '}
                  </span>
                  <span>{`${value.last_scheduling_decision}`}</span>
                </Typography>
              ))}
            </Stack>
          )}
        </DialogContent>
        <DialogActions>
          <Tooltip
            title={
              disableSubmit
                ? 'Dag is already running/queued for refresh'
                : 'Clck here to trigger the dag'
            }>
            <Button
              variant='contained'
              disabled={disableSubmit}
              onClick={handleClick}
              sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
              Trigger Now
            </Button>
          </Tooltip>
          <Button
            variant='contained'
            onClick={() => setShowEditDialog(false)}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CurationButton;
