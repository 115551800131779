import React from 'react';

const Password = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'>
    <g data-name='Layer 2' transform='translate(0 .079)'>
      <g data-name='invisible box' transform='translate(0 -.08)'>
        <path fill='none' d='M0 0H24V24H0z' data-name='Rectangle 462' />
      </g>
      <g data-name='icons Q2' transform='translate(3.405 1.96)'>
        <path
          fill='#7a7a7a'
          d='M23.247 18.885l-6.47-6.518a5.254 5.254 0 00.923-3.016 5.351 5.351 0 10-5.349 5.349 5.254 5.254 0 003.016-.924l2.919 2.87-2.238 2.238a.963.963 0 101.362 1.362l2.238-2.238 1.552 1.558-2.234 2.234a.963.963 0 101.362 1.362l2.919-2.919a.924.924 0 000-1.362zM8.946 9.351a3.405 3.405 0 113.405 3.405 3.405 3.405 0 01-3.405-3.405z'
          data-name='Path 519'
          transform='translate(-7 -4)'
        />
      </g>
    </g>
  </svg>
);

export default Password;
