import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { getLabelData } from '../../api/AdminPage';

// styles
import Styles from '../DataEntry/styles/Review.styles';

// other components
import NonPLRPageLabel from './NonPLRPageLabel';

// eslint-disable-next-line react/prop-types
const ReviewPage = ({ applNo, subType, subNo, docType }) => {
  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const [editMode, setEditMode] = useState(false);
  const [response, setResponse] = useState();

  useEffect(() => {
    async function fetchData() {
      let res;
      try {
        // eslint-disable-next-line react/prop-types
        res = await getLabelData(applNo, subType, subNo, docType.toLowerCase());

        if (res === undefined) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Enter valid information' }
          });
          if (docType === 'ema') {
            history.push('/ema/labelEma');
          } else {
            history.push('/fda/label');
          }
        } else {
          if (docType === 'ema') {
            setResponse(res.data.Success.productinfo_data_qc[0]);
          } else {
            setResponse(res.data.Success.label_data_qc[0]);
          }
        }
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        if (docType === 'ema') {
          history.push('/ema/labelEma');
        } else {
          history.push('/fda/label');
        }
      }
    }

    fetchData();
  }, [dispatch, history, applNo, subType, subNo, docType]);

  const handleSwitch = () => {
    setEditMode(!editMode);
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        {editMode ? null : (
          <Box display='flex' padding={1} flexDirection='row' justifyContent='flex-start'>
            <Button
              className={classes.button}
              onClick={() => {
                if (docType === 'ema') {
                  history.push('/ema/labelEma');
                } else {
                  history.push('/fda/label');
                }
              }}
              variant='contained'>
              Go back
            </Button>
            <Button className={classes.button} onClick={handleSwitch} variant='contained'>
              Edit
            </Button>
          </Box>
        )}

        <Grid container spacing={2}>
          {response ? (
            <NonPLRPageLabel
              edit={editMode}
              switch={handleSwitch}
              res={response && response}
              docType={docType}
            />
          ) : null}

          <Grid item xs={12} lg={6}>
            {response && response.pdf_url ? (
              <Box display='flex' className={classes.pdfContainer}>
                <iframe title='pdf' src={response && response.pdf_url} className={classes.pdf} />
              </Box>
            ) : null}
            {response && response.pdf_url ? null : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.pdfContainer}>
                <Typography className={classes.notAvailable}>No pdf is available</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ReviewPage;
