import { makeStyles } from '@material-ui/core';
import { alpha } from '@mui/material/styles';

export default makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    height: '100%',
    background: theme.palette.backgrounds.dark,
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  container: {
    paddingTop: theme.spacing(10)
  },
  guidanceList: {
    color: 'white !important',
    background: theme.palette.backgrounds.light,
    border: '1px solid white',
    borderRadius: '5px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    '& .MuiListItemTex-secondary': {
      color: 'white !important'
    }
  },
  secondaryText: {
    color: 'white !important',
    marginTop: theme.spacing(1)
  },
  buttonText: {
    textTransform: 'initial !important'
  },
  appBar: {
    background: theme.palette.backgrounds.dark
  },
  dialog: {
    '& .MuiDialog-container': {
      '& .MuiDialog-paper': {
        background: `${theme.palette.backgrounds.light} !important`,
        color: 'white'
      }
    },
    color: 'white'
  },
  searchButton: {
    background: theme.palette.buttons.purple,
    fontFamily: 'Roboto',
    color: theme.palette.white,
    width: theme.spacing(25),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    },
    '&:disabled': {
      backgroundColor: alpha(theme.palette.buttons.purple, 0.15),
      color: alpha(theme.palette.white, 0.15)
    }
  },
  selectInputLabel: {
    color: alpha(theme.palette.common.white, 0.8)
  },
  searchRoot: {
    maxHeight: '600px',
    overflow: 'auto'
  },
  whiteText: {
    color: 'white'
  },
  pdfContainer: {
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1.5),
    border: '1px solid white',
    background: theme.palette.backgrounds.light,
    [theme.breakpoints.down('sm')]: {
      minHeight: '80vh'
    }
  },
  formSection: {
    overflowY: 'auto',
    padding: '1em'
  },
  button: {
    background: theme.palette.buttons.purple,
    backgroundColor: theme.palette.buttons.purple,
    color: theme.palette.white,
    minWidth: theme.spacing(25),
    fontWeight: 'bold',
    textTransform: 'initial !important',
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:disabled': {
      opacity: 0.4,
      background: theme.palette.buttons.purple,
      color: theme.palette.white,
      zIndex: 0
    },
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
  iframe: {
    minHeight: '66vh',
    height: '97%',
    width: '100%'
  },
  textFieldDifferent: {
    color: `red !important`
  }
}));
