import React from 'react';

const Code = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='33' viewBox='0 0 16 33'>
    <text
      fill='#7a7a7a'
      data-name='#'
      fontFamily='OpenSans-Extrabold, Open Sans'
      fontSize='24'
      fontWeight='800'>
      <tspan x='0' y='26'>
        #
      </tspan>
    </text>
  </svg>
);

export default Code;
