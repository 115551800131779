import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    background: theme.palette.backgrounds.dark,
    height: '100vh'
  },
  banner: {
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(2),
    background: theme.palette.backgrounds.light
  },
  formHeadings: {
    textAlign: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    color: theme.palette.buttons.purple
  },
  label: {
    marginTop: theme.spacing(2),
    fontSize: theme.spacing(2),
    color: theme.palette.review.label,
    fontWeight: 'bold'
  },
  input: {
    color: theme.palette.white,
    padding: theme.spacing(1),
    '&::placeholder': {
      color: theme.palette.white,
      padding: theme.spacing(1)
    }
  },
  button: {
    marginTop: theme.spacing(2),
    background: theme.palette.buttons.purple,
    fontFamily: 'Roboto',
    color: theme.palette.white,
    width: theme.spacing(25),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  }
}));
