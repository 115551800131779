import React, { useState, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Stack, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import Store from '../../../store';
import Actions from '../../../store/actions';
import { Auth } from 'aws-amplify';
import { createEMAData } from '../../../api/ema';
import styles from './styles/EMANewRecord.styles';
import { DATE_FIELDS, FIELDS_LABEL_MAP, TEXT_FIELDS, WEBLINK } from './constants';

const CustomTextField = ({ name, value, disabled, onChange }: any) => {
  const label = FIELDS_LABEL_MAP[name];
  return (
    <TextField
      fullWidth
      id={name}
      label={label}
      value={value}
      disabled={disabled}
      variant='outlined'
      onChange={onChange}
      InputProps={{
        style: { fontSize: '2vh', marginBottom: '15px' }
      }}
      InputLabelProps={{
        style: { fontSize: '2vh', fontWeight: 'bold' }
      }}
    />
  );
};

const CreateNewRecord = ({ setOpen, loading, setLoading }: any) => {
  const textFields = TEXT_FIELDS;
  const dateFields = DATE_FIELDS;
  const { dispatch } = useContext<any>(Store);
  const [emaData, setEmaData] = useState<any>({
    ...textFields,
    ...dateFields
  });
  const optionsForWindowObject=
    'height=1000,width=1000,right=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
  const handleSubmit = async (event: { preventDefault: () => void }) => {
    try {
      setLoading(true);
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        ...emaData,
        approval_date: emaData.approval_date ? new Date(emaData.approval_date).toISOString() : '',
        date_of_opinion: emaData.date_of_opinion
          ? new Date(emaData.date_of_opinion).toISOString()
          : '',
        date_of_refusal_of_marketing_authorisation:
          emaData.date_of_refusal_of_marketing_authorisation
            ? new Date(emaData.date_of_refusal_of_marketing_authorisation).toISOString()
            : '',
        decision_date: emaData.decision_date ? new Date(emaData.decision_date).toISOString() : '',
        first_published: emaData.first_published
          ? new Date(emaData.first_published).toISOString()
          : '',
        revision_date: emaData.revision_date ? new Date(emaData.revision_date).toISOString() : '',
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user'],
        updated_at: new Date().toLocaleDateString()
      };

      const res = await createEMAData(payload);
      if (!res?.data || res?.status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Error while Updating EMA Data` }
        });
      } else if (res?.status === 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `EMA Record Created Successfully`, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box pl={2}>
      <Stack direction='row' sx={{ overflow: 'hidden', height: '85vh' }}>
        <Box flex={1} overflow='auto'>
          <Box display='flex' padding={1} flexDirection='row'>
            <Button disabled={loading} onClick={() => setOpen(false)} variant='contained'>
              Go back
            </Button>
            <Typography variant='h6' sx={{ marginLeft: 'auto', marginRight: 'auto' }}>
              EMA Create Data
            </Typography>
          </Box>
          <Box display='flex'>
            <Box
              alignItems='center'
              display='flex'
              justifyContent='start'
              position='relative'
              width='70%'>
              <form
                onSubmit={handleSubmit}
                style={{ paddingTop: '17px', width: '95%', alignItems: 'center' }}>
                <Grid container>
                  <Box display='flex' flexDirection='column' width='100%'>
                    {Object.entries(emaData).map(([key, value]) =>
                      key in textFields ? (
                        <CustomTextField
                          key={key} // Using the provided key for emaData
                          name={key}
                          value={value}
                          onChange={(e: { target: { value: any } }) => {
                            setEmaData((prevData: any) => ({
                              ...prevData,
                              [key]: e.target.value
                            }));
                          }}
                        />
                      ) : key in dateFields ? (
                        <LocalizationProvider key={key} dateAdapter={AdapterDateFns}>
                          <DatePicker
                            label={FIELDS_LABEL_MAP[key]}
                            inputFormat='MM/dd/yyyy'
                            openTo='year'
                            views={['year', 'month', 'day']}
                            value={emaData[key]}
                            onChange={newValue => {
                              setEmaData((prevData: any) => ({
                                ...prevData,
                                [key]: newValue
                              }));
                            }}
                            InputProps={{
                              style: { fontSize: '2vh', marginBottom: '15px' }
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                fullWidth
                                error={false}
                                InputLabelProps={{
                                  style: { fontSize: '2vh', fontWeight: 'bold' }
                                }}
                              />
                            )}
                          />
                        </LocalizationProvider>
                      ) : (
                        <></>
                      )
                    )}
                  </Box>
                  <Button
                    type='submit'
                    fullWidth
                    variant='contained'
                    color='primary'
                    disabled={loading}
                    sx={styles.submit}>
                    Create
                  </Button>
                </Grid>
              </form>
            </Box>
            <Box>
              {' '}
              <Button
                type='submit'
                variant='contained'
                color='primary'
                onClick={() => window.open(WEBLINK, '_blank', optionsForWindowObject)}
                fullWidth
                sx={styles.submit}>
                Open Website as a popup window
              </Button>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};

export default CreateNewRecord;
