const styles: any = {
  datagrid: {
    border: 'none',
    borderRadius: '10px',
    fontSize: '16px',

    '& .table-header': {
      width: '100%',
      color: '#3AB09E',
      fontWeight: 'bold',
      bgcolor: 'primary.backgroundDark'
      // borderRadius: '10px',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '20px'
    },
    '& 	.MuiDataGrid-row': {
      display: 'flex',
      bgcolor: 'white'
    },
    '& .MuiDataGrid-cell:hover': {
      color: 'black.main'
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: '16px'
    },
    '& .MuiTablePagination-selectLabel': {
      fontSize: '16px'
    }
  },

  Typography: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#3AB09E'
  },

  Heading: {
    color: '#3AB09E',
    fontSize: '2.5vh',
    textAlign: 'left',
    fontWeight: '600',
    marginTop: '2vh'
  },

  Button: {
    marginTop: '24px',
    marginBottom: '16px',
    '&.MuiButton-text': {
      color: 'white'
    },
    '&.MuiButton-contained': {
      background: '#3AB09E'
    }
  },
  Search: {
    marginTop: '16px',
    marginBottom: '16px',
    marginLeft: '25px',
    bgcolor: 'white'
  }
};

export default styles;
