import React, { useState, useEffect, useContext, useCallback } from 'react';
// mui
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent, Skeleton, Card } from '@mui/material';
import Typography from '@mui/material/Typography';

// api
import { insertSubmission, getSubmissionRecord } from '../../../api/eua';

// Store
import Actions from '../../../store/actions';
import Store from '../../../store';
import CurationForm from '../../CustomDashboard/CurationForm';
import { SUBMISSION_CURATION_FORM } from './constant';

// utils
import getDataFields, { getFieldsFromApiData } from '../../CustomDashboard/utils/getDataFields';
import PDFViewer from '../../../utils/PDFViewer';

// styles
import Styles, { styles } from './styles/EUAcuration.styles';

const SubmissionCurationForm = ({ closeEdit, selectedData }: any) => {
  const classes = Styles();
  const [pdfUrl, setpdfUrl] = useState<Array<any>>([]);
  const { dispatch } = useContext<any>(Store);
  const [submissionData, setSubmissionData] = useState<any>([]);
  const [selectedAppl, setSelectedAppl] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedS3Link, setSelectedS3Link] = useState<any>({});

  const getSubmissionData = async () => {
    try {
      setLoading(true);
      const submission = await getSubmissionRecord(selectedData.vivpro_appl_no);
      if (submission.data.status === 200) {
        setSubmissionData(submission.data.body);
      }
      const pdfLink: Array<any> = [
        {
          document_title: 'confirmation_link',
          label: 'Confirmation Link',
          s3_url: selectedData.confirmation_link
        },
        {
          document_title: 'source_link',
          label: 'Source Link',
          s3_url: selectedData.source_link
        }
      ];
      setpdfUrl(pdfLink);
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
      closeEdit();
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getSubmissionData();
  }, [selectedData]);

  const handleSubmit = async (e: React.SyntheticEvent, data: any) => {
    try {
      if (Object.keys(data).length !== 0) {
        const payload = {
          ...getDataFields(data),
          type: selectedData.type,
          vivpro_appl_no: selectedData.vivpro_appl_no
        };
        const res = await insertSubmission(payload);
        if (res.data.status === 200) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: `Submitted Successfully`, color: 'success' }
          });
        } else {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: `Failed to submit` }
          });
        }
      }
    } catch (error: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    }
  };

  const handleEdit = useCallback((applData: any) => {
    setOpen(true);
    setSelectedAppl(getFieldsFromApiData(applData, SUBMISSION_CURATION_FORM));
  }, []);

  const handleOpenPopup = useCallback((url: string) => {
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow: any = window.open(url, '_blank', option);

    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
      }
    }, 1000);
  }, []);

  return (
    <div style={{ maxHeight: '100vh', overflow: 'hidden' }}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography
          component='h2'
          variant='h5'
          style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
          Submission Curation
        </Typography>

        {open ? (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              setOpen(false);
              getSubmissionData();
            }}>
            <Typography sx={{ color: 'white.main' }}>Back</Typography>
          </Button>
        ) : (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              handleEdit([]);
            }}>
            <Typography sx={{ color: 'white.main' }}>Add New Submission</Typography>
          </Button>
        )}

        <IconButton
          aria-label='close'
          onClick={() => {
            closeEdit();
            setOpen(true);
          }}
          sx={{
            position: 'absolute',
            right: 5,
            top: 2,
            color: 'gray.light'
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {!loading ? (
          // eslint-disable-next-line react/jsx-no-useless-fragment
          <>
            {open ? (
              <CurationForm fields={selectedAppl} handleSubmit={handleSubmit} fileUrls={pdfUrl} />
            ) : (
              <Box
                display='flex'
                flexDirection='row'
                justifyContent='space-around'
                style={{ maxHeight: '75vh', padding: '10px 10px' }}>
                <Box
                  style={{
                    width: '50%',
                    overflowY: 'auto',
                    maxHeight: '75vh',
                    padding: '4px'
                  }}>
                  {submissionData?.length ? (
                    submissionData?.map((ele: any) => (
                      <Card
                        sx={{ display: 'flex' }}
                        style={{ margin: '10px 0' }}
                        key={`${ele.key}`}>
                        <Box
                          className={classes.reviewBox}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            position: 'relative'
                          }}>
                          <Button
                            size='small'
                            type='button'
                            variant='contained'
                            onClick={() => {
                              handleEdit(ele);
                            }}
                            color='primary'
                            sx={styles.Button}
                            style={{
                              position: 'absolute',
                              right: 15,
                              top: 0,
                              color: 'gray.light'
                            }}>
                            Edit
                          </Button>
                          <Box padding={1}>
                            <Typography align='left' sx={styles.label}>
                              Title
                            </Typography>
                            <Typography align='left' sx={styles.details}>
                              {ele.title}
                            </Typography>
                          </Box>
                          <Box padding={1}>
                            <Typography align='left' sx={styles.label}>
                              Approval date
                            </Typography>
                            <Typography align='left' sx={styles.details}>
                              {ele.approval_date}
                            </Typography>
                          </Box>
                        </Box>
                      </Card>
                    ))
                  ) : (
                    <Box sx={{ border: '4px solid #d6d6d6', borderRadius: '4px' }}>
                      <Card style={{ margin: '10px 0', padding: '10px' }}>
                        <Box>
                          <Typography>No Submission found</Typography>
                        </Box>
                      </Card>
                    </Box>
                  )}
                </Box>
                <Box
                  style={{
                    width: '50%',
                    overflowY: 'auto',
                    maxHeight: '75vh',
                    padding: '4px'
                  }}>
                  <Box display='flex' alignItems='center' justifyContent='center'>
                    <Button
                      variant='contained'
                      sx={styles.Button}
                      disabled={!(selectedS3Link.s3_url || selectedS3Link.meeting_link)}
                      onClick={() => {
                        handleOpenPopup(selectedS3Link.s3_url || selectedS3Link.meeting_link);
                      }}>
                      <Typography>Open As a Pop-Up window</Typography>
                    </Button>
                  </Box>
                  <PDFViewer pdf={pdfUrl} setSelectedS3Link={setSelectedS3Link} />
                </Box>
              </Box>
            )}
          </>
        ) : (
          <Box display='flex' maxHeight='100vh' sx={{ overflow: 'hidden' }}>
            <Skeleton variant='rectangular' height='100vh' width='100%' />
          </Box>
        )}
      </DialogContent>
    </div>
  );
};

export default React.memo(SubmissionCurationForm);
