import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// material ui
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import LinearProgress from '@mui/material/LinearProgress';

// context
import { Close, Save } from '@mui/icons-material';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from '@mui/material';
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { getLabelData, getLabelInfo, submitNewLabelInfo } from '../../api/AdminPage';

// helpers
// styles
import Styles from '../DataEntry/styles/Review.styles';

const LabelSectionEditor = () => {
  const { applNo, subType, subNo, docType } = useParams();

  const classes = Styles();
  const history = useHistory();
  const { state, dispatch } = useContext(Store);
  const [allDetails, setAllDetails] = useState();
  const [editMode, setEditMode] = useState(false);
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [showOriginalPdf, setShowOriginalPdf] = useState(false);
  const [loading, setLoading] = useState(true);
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentUserInfo();
        setEmail(user.attributes.email);
        setName(user.attributes['custom:user']);
        window.onbeforeunload = function () {
          return 'Please wait for the data to load...';
        };
        const res = await getLabelInfo(applNo, subType, subNo, docType);
        if (res === undefined) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Enter valid information' }
          });
          history.push('/fda/label/section');
        }
        if (res.data && res.data.Success) {
          setLoading(false);
          setAllDetails(res.data.Success.label_data[0]);
        } else {
          setLoading(false);
          throw Error('No data');
        }
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        history.push('/fda/label/section');
      }
    }

    fetchData();
  }, [applNo, subType, subNo, docType]);
  useEffect(() => {
    if (allDetails && allDetails.onedrive_file_url) {
      handleOpenDocEdit();
    } else if (allDetails && !allDetails.onedrive_file_url) {
      async function setError() {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        history.push('/fda/label/section');
      }

      setError();
    }
  }, [allDetails]);

  useEffect(() => {
    return () => {
      window.onbeforeunload = () => {};
    };
  }, []);
  const handleOpenDocEdit = () => {
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow = window.open(allDetails.onedrive_file_url, '_blank', option);

    const timer = setInterval(function () {
      if (newWindow.closed) {
        clearInterval(timer);
        setOpenSubmitConfirmation(true);
      }
    }, 1000);
  };

  const handleOpenOriginalPDF = async () => {
    try {
      const res = await getLabelData(applNo, subType, subNo, docType.toLowerCase());
      if (res === undefined) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Not a valid PDF${error}` }
        });
      } else {
        const url = res.data.Success.label_data_qc[0].pdf_url;
        const option =
          'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
        const newWindow = window.open(url, '_blank', option);

        const timer = setInterval(function () {
          if (newWindow.closed) {
            clearInterval(timer);
            setOpenSubmitConfirmation(true);
          }
        }, 1000);
      }
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `Not a valid PDF${error}` }
      });
    }
  };

  const handleSubmitChanges = async () => {
    async function handleSubmit() {
      setLoading(true);
      setOpenSubmitConfirmation(false);
      const payload = {
        filename: allDetails.filename ?? allDetails.onedrive_doc_name,
        s3_path: allDetails.label_path ?? allDetails.s3_path,
        fileId: allDetails.drive_file_id ?? allDetails.onedrive_doc_id
      };

      await submitNewLabelInfo(payload);
      history.push(`/fda/label_section/${applNo}/${subType}/${subNo}/${docType}`);
    }

    handleSubmit();
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        {editMode ? null : (
          <Box display='flex' padding={1} flexDirection='row' justifyContent='space-between'>
            <div>
              <Button
                className={classes.button}
                disabled={loading}
                onClick={() => history.push('/reviews')}
                variant='contained'>
                Go back
              </Button>
              {/* <Button
                        className={classes.button}
                        disabled={loading}
                        onClick={() => setShowOriginalPdf(curentState => !curentState)}
                        variant='contained'>
                        {showOriginalPdf ? 'Hide' : 'View'} original
                    </Button> */}
              <Button
                variant='outlined'
                color='secondary'
                className={classes.button}
                disabled={loading}
                onClick={handleOpenOriginalPDF}>
                View Original PDF
              </Button>
            </div>

            {/* <Button */}
            {/*    disabled={loading} */}
            {/*    className={classes.button} */}
            {/*    onClick={handleSubmitChanges} */}
            {/*    variant='contained'> */}
            {/*    Submit */}
            {/* </Button> */}
          </Box>
        )}
        <Grid container spacing={2}>
          {loading && (
            <Box
              sx={{
                width: '100%',
                height: '80vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
              }}>
              <LinearProgress
                sx={{ width: '100%', maxWidth: '400px', marginBottom: '1em' }}
                color='secondary'
              />
              <Typography className={classes.notAvailable}>
                {!allDetails ? 'Loading data ....' : 'Submitting changes ....'}
              </Typography>

              <Typography className={classes.notAvailable}>
                {allDetails && '(this might take couple of minutes to complete)'}
              </Typography>
            </Box>
          )}
          <Grid item xs={12} lg={showOriginalPdf ? 6 : 12}>
            {!loading && allDetails && allDetails.onedrive_file_url && (
              <Box
                display='flex'
                flexDirection='column'
                minHeight='50vh'
                justifyContent='center'
                alignItems='center'
                className={classes.pdfContainer}>
                <Typography variant='h5' component='div' className={classes.notAvailable}>
                  File is open in a new popup window
                </Typography>
                <Button
                  disabled={loading}
                  className={classes.button}
                  onClick={handleOpenDocEdit}
                  variant='contained'>
                  Open file again.
                </Button>
                {/* <iframe */}
                {/*    title='pdf' */}
                {/*    src={`https://docs.google.com/document/d/${allDetails && allDetails.drive_id}/edit`} */}
                {/*    className={classes.driveIframe} */}
                {/* /> */}
              </Box>
            )}
          </Grid>

          {showOriginalPdf && !loading && (
            <Grid item xs={12} lg={6}>
              {allDetails && allDetails.pdf_url ? (
                <Box display='flex' className={classes.pdfContainer}>
                  <iframe
                    title='pdf'
                    src={allDetails && allDetails.pdf_url}
                    className={classes.pdf}
                  />
                </Box>
              ) : null}
              {allDetails && allDetails.pdf_url ? null : (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  className={classes.pdfContainer}>
                  <Typography className={classes.notAvailable}>No pdf is available</Typography>
                </Box>
              )}
            </Grid>
          )}
        </Grid>
      </Container>
      <Dialog
        open={openSubmitConfirmation}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <div>Confirm submission</div>
            <IconButton aria-label='close' onClick={() => setOpenSubmitConfirmation(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you have edited the document accurately?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.button}
            disabled={loading}
            onClick={handleOpenDocEdit}>
            Open file again
          </Button>
          <Button
            startIcon={<Save />}
            variant='contained'
            onClick={handleSubmitChanges}
            disabled={loading}
            color='secondary'
            className={classes.button}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default LabelSectionEditor;
