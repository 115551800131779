import palette from '../../../themev5/palette';
import theme from '../../../theme';

const styles = {
  root: {
    height: '70vh',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& .fda_org_cell': {
        height: '26px',
        '& .fda_org_cell__more-text': {
          display: 'inline !important'
        }
      }
    },
    '& .MuiDataGrid-root': {
      width: '100%',
      '& .MuiDataGrid-columnHeader': {
        color: palette.primary.main,
        fontWeight: 'bold',
        bgcolor: palette.primary.backgroundDark
      },
      '& .MuiDataGrid-columnsContainer': {
        color: palette.primary.lightBluish,
        background: '#F7F7F7'
      }
    }
  },
  Button: {
    background: '#3AB09E',
    color: theme.palette.white
  },
  datagrid: {
    border: 'none',
    borderRadius: '10px',
    fontSize: '16px',

    '& .table-header': {
      width: '100%',
      color: 'primary.main',
      fontWeight: 'bold',
      bgcolor: 'primary.backgroundDark'
      // borderRadius: '10px',
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '16px'
    },
    '& 	.MuiDataGrid-row': {
      bgcolor: 'red'
    },
    '& .MuiDataGrid-cell:hover': {
      color: 'black.main'
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: '16px'
    },
    '& .MuiTablePagination-selectLabel': {
      fontSize: '16px'
    }
  }
};

export default styles;
