import React, { useCallback, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const SelectOptions = ({ options = [], onOptionChange, value }: any) => {
  const [open, setOpen] = useState<boolean>(false);
  const handleOpenSelect = useCallback(() => {
    setOpen(true);
  }, []);
  const handleCloseSelect = useCallback(() => {
    setOpen(false);
  }, []);
  return (
    <Select
      id='blaStats'
      variant='standard'
      disableUnderline
      required
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left'
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'left'
        }
      }}
      sx={{ boder: '1px solid black' }}
      open={open}
      onClose={handleCloseSelect}
      onOpen={handleOpenSelect}
      value={value}
      onChange={onOptionChange}>
      {options.map((opt: any) => (
        <MenuItem value={opt.value} key={opt.value}>
          {opt.label}
        </MenuItem>
      ))}
    </Select>
  );
};

export default React.memo(SelectOptions);
