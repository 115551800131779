import React, { useContext, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// api
import { useHistory } from 'react-router-dom';
import { getOneMeeting, saveOrSubmitMeetingDetails } from '../../../api/Adcomm';
import Styles from './styles/AdcommAdminMeetingReviewStyles';
import Actions from '../../../store/actions';
import Store from '../../../store';
import getDate from "../../../utils/getDate";

const EditMeetingFields = ({
                             meeting,
                             setEditMode,
                             setMeeting,
                             setQcComplete,
                             setMeetingStatus,
                             meetingStatus,
                             editButtons,
                             isNewMeeting,
                             webLink
                           }) => {
  const classes = Styles();
  const { dispatch } = useContext(Store);
  const history = useHistory();
  const [mtgTitle, setMtgTitle] = useState(meeting.meeting_title || '');
  const [mtgStartDate, setMtgStartDate] = useState(meeting.adcom_meeting_start_date || getDate(new Date()));
  const [mtgEndDate, setMtgEndDate] = useState(meeting.adcom_meeting_end_date || getDate(new Date()));
  const [mtgTime, setMtgTime] = useState(meeting.adcom_meeting_time || '');
  const [location, setLocation] = useState(meeting.location || '');
  const [agenda, setAgenda] = useState(meeting.agenda || '');
  const [statusOpen, setStatusOpen] = useState(false);
  const [materialOpen, setMaterialOpen] = useState(false);
  const [minutesOpen, setMinutesOpen] = useState(false);
  const [rosterOpen, setRosterOpen] = useState(false);
  const [materialExists, setMaterialExists] = useState(
    'material_exists' in meeting ? meeting.material_exists : true
  );
  const [minutesExists, setMinutesExists] = useState(
    'minutes_exists' in meeting
      ? meeting.minutes_exists
      : 'material_exists' in meeting
        ? meeting.material_exists
        : true
  );
  const [rosterExists, setRosterExists] = useState(
    'roster_exists' in meeting
      ? meeting.roster_exists
      : 'material_exists' in meeting
        ? meeting.material_exists
        : true
  );

  const handleStartDateChange = date => {
    setMtgStartDate(getDate(date));
  };

  const handleEndDateChange = date => {
    setMtgEndDate(getDate(date));
  };

  const handleSave = async e => {
    e.preventDefault(e);
    try {
      // here we will post the new values
      const payload = {
        acronym: meeting.acronym,
        meeting_id: meeting.meeting_id,
        meeting_title: mtgTitle,
        adcom_meeting_start_date: mtgStartDate,
        adcom_meeting_end_date: mtgEndDate ? mtgEndDate : mtgStartDate,
        adcom_meeting_time: mtgTime,
        location,
        agenda,
        meeting_link: webLink,
        qc_complete: true,
        status: meetingStatus,
        curator_email: meeting.curator_email,
        curator_name: meeting.curator_name,
        material_exists: materialExists,
        minutes_exists: materialExists ? minutesExists : false,
        roster_exists: materialExists ? rosterExists : false
      };
      await saveOrSubmitMeetingDetails(payload, isNewMeeting ? 'new' : 'save');
      if (setEditMode) {
        setEditMode(false);
      }

      // Once new meeting details are saved, set meeting data to qcd table details
      const { data } = await getOneMeeting(meeting.meeting_id, 'qcd');
      if (data) {
        if (isNewMeeting) {
          history.push(`/adcomm/${meeting.meeting_id}/meeting/material`);
        }
        setMeeting(data.Success);
      } else {
        throw new Error('No data returned');
      }

      setQcComplete(true);
    } catch (e) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `${e}` }
      });
    }
  };

  return (
    <Box padding={1} className={classes.reviewBox}>
      <h1>Meeting Details</h1>
      <Typography align='left' className={classes.label}>
        Acronym
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {meeting.acronym}
        </Typography>
      </Box>
      <form onSubmit={handleSave}>
        <Typography align='left' className={classes.label}>
          Meeting ID
        </Typography>
        <Box>
          <Typography className={classes.details} align='left'>
            {meeting.meeting_id}
          </Typography>
        </Box>
        <Typography align='left' className={classes.label}>
          Meeting Title
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={mtgTitle}
          onChange={e => setMtgTitle(e.target.value)}
          placeholder={meeting.meeting_title}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />

        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Typography align='left' className={classes.label}>
            Start Date
          </Typography>
          <KeyboardDatePicker
            format='yyyy-MM-dd'
            margin='normal'
            id='date-picker-inline'
            value={mtgStartDate}
            onChange={handleStartDateChange}
            className={classes.date_picker}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
          <Typography align='left' className={classes.label}>
            End Date
          </Typography>
          <KeyboardDatePicker
            format='yyyy-MM-dd'
            margin='normal'
            id='date-picker-inline'
            className={classes.date_picker}
            value={mtgEndDate ? mtgEndDate : mtgStartDate}
            minDate={mtgStartDate}
            onChange={handleEndDateChange}
            KeyboardButtonProps={{
              'aria-label': 'change date'
            }}
          />
        </MuiPickersUtilsProvider>

        <Typography align='left' className={classes.label}>
          Start Time
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={mtgTime}
          onChange={e => setMtgTime(e.target.value)}
          placeholder={meeting.adcom_meeting_time}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Location
        </Typography>
        <TextareaAutosize
          className={classes.textfield}
          required
          value={location}
          onChange={e => setLocation(e.target.value)}
          inputprops={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Agenda
        </Typography>
        <TextareaAutosize
          className={classes.textfield}
          value={agenda}
          required
          onChange={e => setAgenda(e.target.value)}
          inputprops={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Box padding={1} display='flex' flexDirection='column'>
          <Typography align='left' className={classes.label}>
            Material exists
          </Typography>
          <Select
            id='selectStatus'
            variant='standard'
            disableUnderline
            required
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            open={materialOpen}
            onClose={() => setMaterialOpen(false)}
            onOpen={() => setMaterialOpen(true)}
            value={materialExists}
            onChange={e => setMaterialExists(e.target.value)}
            className={classes.dropdownContainer}
            inputProps={{
              classes: {
                icon: classes.icon
              }
            }}>
            <MenuItem value={true} className={classes.dropdownText}>
              Yes
            </MenuItem>
            <MenuItem value={false} className={classes.dropdownText}>
              No
            </MenuItem>
          </Select>
        </Box>
        {materialExists && (
          <>
            <Box padding={1} display='flex' flexDirection='column'>
              <Typography align='left' className={classes.label}>
                Minutes exists
              </Typography>
              <Select
                id='selectStatus'
                variant='standard'
                disableUnderline
                required
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
                open={minutesOpen}
                onClose={() => setMinutesOpen(false)}
                onOpen={() => setMinutesOpen(true)}
                value={minutesExists}
                onChange={e => setMinutesExists(e.target.value)}
                className={classes.dropdownContainer}
                inputProps={{
                  classes: {
                    icon: classes.icon
                  }
                }}>
                <MenuItem value={true} className={classes.dropdownText}>
                  Yes
                </MenuItem>
                <MenuItem value={false} className={classes.dropdownText}>
                  No
                </MenuItem>
              </Select>
            </Box>
            <Box padding={1} display='flex' flexDirection='column'>
              <Typography align='left' className={classes.label}>
                Roster exists
              </Typography>
              <Select
                id='selectStatus'
                variant='standard'
                disableUnderline
                required
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
                open={rosterOpen}
                onClose={() => setRosterOpen(false)}
                onOpen={() => setRosterOpen(true)}
                value={rosterExists}
                onChange={e => setRosterExists(e.target.value)}
                className={classes.dropdownContainer}
                inputProps={{
                  classes: {
                    icon: classes.icon
                  }
                }}>
                <MenuItem value={true} className={classes.dropdownText}>
                  Yes
                </MenuItem>
                <MenuItem value={false} className={classes.dropdownText}>
                  No
                </MenuItem>
              </Select>
            </Box>
          </>
        )}

        <Box padding={1} display='flex' flexDirection='column'>
          <Typography align='left' className={classes.label}>
            Status
          </Typography>
          <Select
            id='selectStatus'
            variant='standard'
            disableUnderline
            required
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            open={statusOpen}
            onClose={() => setStatusOpen(false)}
            onOpen={() => setStatusOpen(true)}
            value={meetingStatus}
            onChange={e => setMeetingStatus(e.target.value)}
            className={classes.dropdownContainer}
            inputProps={{
              classes: {
                icon: classes.icon
              }
            }}>
            <MenuItem value='Qced' className={classes.dropdownText}>
              Qced
            </MenuItem>
            <MenuItem value='Escalate' className={classes.dropdownText}>
              Escalate
            </MenuItem>
            <MenuItem value='Delete' className={classes.dropdownText}>
              Delete
            </MenuItem>
            <MenuItem value='' className={classes.dropdownText} />
          </Select>
        </Box>
        <Button className={classes.button} disabled={editButtons} type={'submit'}>
          Save
        </Button>
      </form>
    </Box>
  );
};
export default EditMeetingFields;
