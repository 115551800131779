import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  notAvailable: {
    color: theme.palette.white,
    fontSize: theme.spacing(3),
    fontWeight: 'bold'
  },
  pdfEma: {
    borderRadius: theme.spacing(1.5)
  }
}));

export const styles = {
  activeTab: {
    border: '0.5px solid',
    borderColor: 'gray.light',
    borderRadius: '10px 10px 0px 0px',
    fontSize: '16px',
    boxShadow: 'none',
    color: 'gray.main',
    textTransform: 'capitalize',
    background: 'white'
  },
  inactiveTab: {
    borderRadius: '10px 10px 0px 0px',
    textTransform: 'capitalize',
    color: 'white.main',
    fontFamily: 'Nunito Sans',
    fontWeight: '600',
    fontSize: '16px',
    boxShadow: 'none',
    background: 'gradient.main'
  }
};
