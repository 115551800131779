import React, { useCallback } from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextField from '@mui/material/TextField';
import { format } from 'date-fns';

interface DateRangeProps {
  label: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onchange: (val: string | null) => void;
}

const DateRange = ({ value, label, onchange }: DateRangeProps) => {
  const handleChange = useCallback(
    (newValue: string | null) => {
      if (newValue !== null && typeof newValue !== 'undefined') {
        // eslint-disable-next-line no-restricted-globals
        if (!isNaN(new Date(newValue).getTime())) {
          const date = format(new Date(newValue), 'yyyy-MM-dd');
          onchange(date);
        }
      } else {
        onchange('');
      }
    },
    [onchange]
  );
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        label={label}
        inputFormat='yyyy-MM-dd'
        openTo='year'
        views={['year', 'month', 'day']}
        value={value || null}
        onChange={handleChange}
        InputProps={{
          style: { fontSize: '2vh' }
        }}
        renderInput={(params: any) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            variant='outlined'
            InputLabelProps={{
              style: { fontSize: '2vh', fontWeight: 'bold' }
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
};

export default React.memo(DateRange);
