import makeRequest, { makeArrayBufferRequest } from './client';

export const getKeyList = ()=> makeRequest(`/reginfo`, `GET`);

export const getKeyAttributes = (key: string)=> makeRequest(`/reginfo/${key}`, `GET`);

export const addKeyAttributes = (payload: any) =>
  makeRequest(`/reginfo/attribute`, `POST`, payload);

export const updateKeyAttributes = (
  id: number,
  payload: any
) =>
  makeRequest(
    `/reginfo/attribute/${id}`,
    `PUT`,
    payload
  );

export const getPDF = (applno: string, submissionType: string, submissionNumber: number) =>
makeRequest(`/reginfo/${applno}/${submissionType}/${submissionNumber}/all`, `GET`);

export const highlightPDF =  (payload:any)=>
makeArrayBufferRequest('/pdf/highlight',`POST`, payload);

