import UserNotificationStats from '../components/UserNotifications/UserNotificationStats';

const USER_NOTIFICATION_ROUTES = [
  {
    id: 'user_notifications',
    path: '/user_notifications',
    component: UserNotificationStats,
    exact: true
  }
];
export default USER_NOTIFICATION_ROUTES;
