import React from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Styles from '../DataEntry/styles/Review.styles';

export const ViewData = ({
  pno,
  activeingredient,
  concentration,
  drugname,
  form,
  route,
  indication_status,
  packaging,
  packsize,
  strength,
  is_active
}) => {
  const classes = Styles();
  return (
    <Box padding={1} display='flex' justifyContent='space-between' flexDirection='column'>
      <Typography align='left' className={classes.label}>
        EU Number:
        <Typography component={'span'} align='left' display='block' className={classes.details}>
          {pno}
        </Typography>
      </Typography>
      <Typography align='left' className={classes.label}>
        Invented name:
        <Typography component={'span'} align='left' display='block' className={classes.details}>
          {drugname}
        </Typography>
      </Typography>
      <Typography align='left' className={classes.label}>
        Strength:
        <Typography component={'span'} align='left' display='block' className={classes.details}>
          {strength}
        </Typography>
      </Typography>
      <Typography align='left' className={classes.label}>
        Pharmaceutical Form:
        <Typography component={'span'} align='left' display='block' className={classes.details}>
          {form}
        </Typography>
      </Typography>
      <Typography align='left' className={classes.label}>
        Route of Administration:
        <Typography component={'span'} align='left' display='block' className={classes.details}>
          {route}
        </Typography>
      </Typography>
      <Typography align='left' className={classes.label}>
        Packaging:
        <Typography component={'span'} align='left' display='block' className={classes.details}>
          {packaging}
        </Typography>
      </Typography>
      <Typography align='left' className={classes.label}>
        Concentration:
        <Typography component={'span'} align='left' display='block' className={classes.details}>
          {concentration}
        </Typography>
      </Typography>
      <Typography align='left' className={classes.label}>
        Pack Size:
        <Typography component={'span'} align='left' display='block' className={classes.details}>
          {packsize}
        </Typography>
      </Typography>
      <Typography align='left' className={classes.label}>
        Is Active:
        <Typography component={'span'} align='left' display='block' className={classes.details}>
          {
            //if is_active is boolean, display right text. Else display empty string
            is_active === true ? 'Active' : is_active === false ? 'Inactive' : ''
          }
        </Typography>
      </Typography>
      <Typography align='left' className={classes.label}>
        Status:
        <Typography component={'span'} align='left' display='block' className={classes.details}>
          {indication_status}
        </Typography>
      </Typography>
    </Box>
  );
};
