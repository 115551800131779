import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material ui
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// api
// api
import { getFdaData } from '../../api/AdminPage';

// styles
import Styles from '../DataEntry/styles/Review.styles';

// other components
import FdaCurationDisplay from './FdaCurationDisplay';

// eslint-disable-next-line react/prop-types
const ReviewPage = ({ applNo, subType, subNo, docType }) => {
  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const [editMode, setEditMode] = useState(false);
  const [response, setResponse] = useState();

  useEffect(() => {
    async function fetchData() {
      let res;
      try {
        res = await getFdaData(applNo, subType, subNo, docType);
        if (res === undefined) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Enter valid information' }
          });

          history.push('/approval');
        } else {
          setResponse(res.data.Success.fda_data_qc[0]);
        }
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        history.push('/approval');
      }
    }

    fetchData();
  }, [dispatch, history]);

  const handleSwitch = () => {
    setEditMode(!editMode);
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <Box display='flex' padding={1} flexDirection='row' justifyContent='flex-start'>
          <Button
            className={classes.button}
            onClick={() => {
              history.push('/approval');
            }}
            variant='contained'>
            Go back
          </Button>
          <Button className={classes.button} onClick={handleSwitch} variant='contained'>
            {editMode ? 'View mode' : 'Edit'}
          </Button>
        </Box>

        <Grid container spacing={2}>
          {response ? (
            <FdaCurationDisplay
              editMode={editMode}
              switchEdit={handleSwitch}
              res={response}
              docType={docType}
            />
          ) : null}
        </Grid>
      </Container>
    </Box>
  );
};

export default ReviewPage;
