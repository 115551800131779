import { makeStyles } from '@material-ui/core';
import palette from '../../../themev5/palette';

export default makeStyles(theme => ({
  root: {
    '& .MuiDataGrid-columnHeaderTitle': {
      color: palette.primary.main,
      fontSize: '16px',
      fontWeight: 'bold'
    },
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& .fda_org_cell': {
        height: '26px',
        '& .fda_org_cell__more-text': {
          display: 'inline !important'
        }
      }
    },
    '& .MuiTablePagination-toolbar': {
      color: palette.primary.main,
      fontWeight: 'bold'
    },
    '& .MuiDataGrid-root': {
      width: '100%',
      '& .MuiDataGrid-columnHeader': {
        color: palette.primary.main,
        fontWeight: 'bold'
      },
      '& .MuiDataGrid-columnsContainer': {
        color: palette.primary.lightBluish,
        background: '#F7F7F7'
      }
    }
  },
  Button: {
    background: '#3AB09E',
    color: theme.palette.white,
    '&:hover': {
      background: palette.primary.darkBluish,
      color: theme.palette.white
    }
  },
  capitalize: {
    textTransform: 'capitalize'
  }
}));
