/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
import React, { useState, useContext, useEffect } from 'react';

// muiv5
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { MenuItem } from '@material-ui/core';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Auth } from 'aws-amplify';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { submitNonPLRFdaData } from '../../api/AdminPage';

// styles
import styles from './styles/ApprovalEditDialog.styles';

import { ApprovalBaseRecordProps } from './ApprovalTable';
import { DEFAULT_DATE } from '../../const';

const ApprovalEditDialog = ({
  appl_num,
  submission_type,
  submission_num,
  aa_date,
  aa_indication,
  conversion_withdrawal_status,
  full_approval_conversion_withdrawal_date,
  id,
  status,
  rescinded_date,
  designation_date,
  date_source,
  breakthrough_indication,
  ft_indication,
  pepfar_flag,
  rtorfullsubmissiondate,
  rtorsubmissiondate,
  closeEdit,
  docType
}: ApprovalBaseRecordProps) => {
  const [data, setData] = useState({
    appl_num: appl_num,
    appl_title: `${appl_num}-${submission_type}-${submission_num}`,
    submission_type: submission_type,
    submission_num: submission_num,
    rtorsubmissiondate: rtorsubmissiondate,
    rtorfullsubmissiondate: rtorfullsubmissiondate,
    id: id,
    status: status,
    aa_date: aa_date,
    aa_indication: aa_indication,
    conversion_withdrawal_status: conversion_withdrawal_status,
    full_approval_conversion_withdrawal_date: full_approval_conversion_withdrawal_date,
    breakthrough_indication: breakthrough_indication,
    designation_date: designation_date,
    rescinded_date: rescinded_date,
    date_source: date_source,
    ft_indication: ft_indication,
    pepfar_flag: pepfar_flag,
    closeEdit: closeEdit,
    docType: docType,
    email: '',
    name: ''
  });
  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  const { state, dispatch } = useContext(Store);

  const getUserDetails = async () => {
    const user = await Auth.currentUserInfo();
    setData(prevData => {
      return { ...prevData, email: user.attributes.email, name: user.attributes['custom:user'] };
    });
  };

  const formatDate = (date: Date) => {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : String(date.getDate());
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : String(date.getMonth() + 1);
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault(event);
    try {
      let payload = {};
      switch (docType) {
        case 'breakthrough':
          payload = {
            appl_num: data.appl_num,
            submission_type: data.submission_type,
            submission_num: data.submission_num,
            breakthrough_indication: data.breakthrough_indication,
            id: data.id,
            curator_email: data.email,
            curator_name: data.name,
            doctype: docType,
            status: data.status,
            designation_date: formatDate(new Date(data.designation_date || new Date(DEFAULT_DATE))),
            rescinded_date: formatDate(new Date(data.rescinded_date || new Date(DEFAULT_DATE))),
            date_source: data.date_source
          };
          break;
        case 'fasttrack':
          payload = {
            appl_num: data.appl_num,
            submission_type: data.submission_type,
            submission_num: data.submission_num,
            ft_indication: data.ft_indication,
            pepfar_flag: data.pepfar_flag,
            designation_date: formatDate(new Date(data.designation_date || new Date(DEFAULT_DATE))),
            id: data.id,
            curator_email: data.email,
            curator_name: data.name,
            doctype: docType,
            status: data.status,
            date_source: data.date_source
          };
          break;
        case 'rtor':
          payload = {
            id: data.id,
            appl_num: data.appl_num,
            submission_num: data.submission_num,
            submission_type: data.submission_type,
            status: data.status,
            rtorfullsubmissiondate: data.rtorfullsubmissiondate,
            rtorsubmissiondate: data.rtorsubmissiondate,
            curator_email: data.email,
            curator_name: data.name,
            doctype: docType
          };
          break;
        default:
          payload = {
            appl_num: data.appl_num,
            submission_type: data.submission_type,
            submission_num: data.submission_num,
            aa_date: formatDate(new Date(data.aa_date || new Date(DEFAULT_DATE))),
            aa_indication: data.aa_indication,
            conversion_withdrawal_status: data.conversion_withdrawal_status,
            full_approval_conversion_withdrawal_date: formatDate(
              new Date(data.full_approval_conversion_withdrawal_date || new Date(DEFAULT_DATE))
            ),
            id: data.id,
            curator_email: data.email,
            curator_name: data.name,
            doctype: docType,
            status: data.status
          };
          break;
      }

      const res = await submitNonPLRFdaData(payload);
      if (res?.data?.Success) {
        if (closeEdit) await closeEdit(res.data.Success.fda_data_qc, null, data.id);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Operation successful', color: 'success' }
        });
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Operation failed: ${res?.data?.Error}` }
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('=>(AcceleratedEditDialog.jsx:61) error', e);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Enter valid information' }
      });
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Container sx={styles.root}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography component='h2' variant='h5' sx={styles.Heading}>
            {`Edit ${docType[0].toUpperCase() + docType.slice(1)} `}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              if (closeEdit) closeEdit(null, null, data.id);
            }}
            sx={{
              position: 'absolute',
              right: 15,
              top: 15,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit} style={styles.form}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='appl_title'
                label='Application Number'
                value={data.appl_title}
                disabled
                InputProps={{
                  style: { fontSize: '2vh' }
                }}
                InputLabelProps={{
                  style: { fontSize: '2vh' }
                }}
              />
            </Grid>
            {docType === 'accelerated' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='aa_indication'
                  label='Accelerated Indications'
                  value={data.aa_indication}
                  maxRows={Infinity}
                  multiline
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, aa_indication: e.target.value };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
            {docType === 'fasttrack' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='ft_indication'
                  label='Fasttrack Indications'
                  value={data.ft_indication}
                  maxRows={Infinity}
                  multiline
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, ft_indication: e.target.value };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
            {docType === 'breakthrough' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='breakthrough_indication'
                  label='Breakthrough Indications'
                  value={data.breakthrough_indication}
                  maxRows={Infinity}
                  multiline
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, breakthrough_indication: e.target.value };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
            {docType === 'rtor' && (
              <>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Submission Date'
                      inputFormat='yyyy-MM-dd'
                      openTo='year'
                      views={['year', 'month', 'day']}
                      value={data.rtorsubmissiondate}
                      onChange={newValue => {
                        setData(prevData => {
                          return {
                            ...prevData,
                            rtorsubmissiondate: newValue || undefined
                          };
                        });
                      }}
                      InputProps={{
                        style: { fontSize: '2vh' }
                      }}
                      renderInput={params => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          fullWidth
                          InputLabelProps={{
                            style: { fontSize: '2vh' }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Full Submission Date'
                      inputFormat='yyyy-MM-dd'
                      openTo='year'
                      views={['year', 'month', 'day']}
                      value={data.rtorfullsubmissiondate}
                      onChange={newValue => {
                        setData(prevData => {
                          return {
                            ...prevData,
                            rtorfullsubmissiondate: newValue || undefined
                          };
                        });
                      }}
                      InputProps={{
                        style: { fontSize: '2vh' }
                      }}
                      renderInput={params => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          fullWidth
                          InputLabelProps={{
                            style: { fontSize: '2vh' }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}

            {docType === 'accelerated' && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Accelerated Date'
                    inputFormat='yyyy-MM-dd'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={data.aa_date}
                    onChange={newValue => {
                      setData(prevData => {
                        return {
                          ...prevData,
                          aa_date: newValue || null
                        };
                      });
                    }}
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: { fontSize: '2vh' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {docType === 'accelerated' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='conversion_withdrawal_status'
                  label='Conversion Withdrawal Status'
                  value={data.conversion_withdrawal_status}
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, conversion_withdrawal_status: e.target.value };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
            {docType === 'accelerated' && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Full Approval Conversion Withdrawal Date'
                    inputFormat='yyyy-MM-dd'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={data.full_approval_conversion_withdrawal_date}
                    onChange={newValue => {
                      setData(prevData => {
                        return {
                          ...prevData,
                          full_approval_conversion_withdrawal_date: newValue || null
                        };
                      });
                    }}
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: { fontSize: '2vh' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {docType === 'fasttrack' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='pepfar_flag'
                  label='Pepfar Flag'
                  value={data.pepfar_flag}
                  onChange={e => {
                    setData(prevData => {
                      return {
                        ...prevData,
                        pepfar_flag: e.target.value
                      };
                    });
                  }}
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}

            {!['accelerated', 'rtor'].includes(docType) && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Designation Date'
                    inputFormat='yyyy-MM-dd'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={data.designation_date}
                    onChange={newValue => {
                      setData(prevData => {
                        return {
                          ...prevData,
                          designation_date: newValue || null
                        };
                      });
                    }}
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: { fontSize: '2vh' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {docType === 'breakthrough' && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Rescinded Date'
                    inputFormat='yyyy-MM-dd'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={data.rescinded_date}
                    onChange={newValue => {
                      setData(prevData => {
                        return {
                          ...prevData,
                          rescinded_date: newValue || null
                        };
                      });
                    }}
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: { fontSize: '2vh' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {!['accelerated', 'rtor'].includes(docType) && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='date_source'
                  label='Date Source'
                  value={data.date_source}
                  maxRows={Infinity}
                  multiline
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, date_source: e.target.value };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <TextField
                variant='outlined'
                fullWidth
                id='status'
                label='Status'
                select
                value={data.status}
                onChange={e =>
                  setData(prevData => {
                    return {
                      ...prevData,
                      status: e.target.value
                    };
                  })
                }
                InputProps={{
                  style: { fontSize: '2vh' }
                }}
                InputLabelProps={{
                  style: { fontSize: '2vh' }
                }}>
                <MenuItem value='Qced'>Qced</MenuItem>
                <MenuItem value='No Document'>No Document</MenuItem>
                <MenuItem value='Escalate'>Escalate</MenuItem>
                <MenuItem value='' />
              </TextField>
            </Grid>
          </Grid>
          <Button type='submit' fullWidth variant='contained' color='primary' sx={styles.submit}>
            SUBMIT
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default ApprovalEditDialog;
