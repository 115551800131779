import { VALID_LICENSES, VALID_ROLES } from './constants';
import { User } from './type';

export const validateFirstName = (firstName: string) => {
  if (firstName.length < 1) {
    return 'First Name cannot be empty';
  }
  return null;
};

export const validateLastName = (lastName: string) => {
  if (lastName.length < 1) {
    return 'Last Name cannot be empty';
  }
  return null;
};

export const validateEmail = (email: string) => {
  if (email.length < 1) {
    return 'Email cannot be empty';
  }

  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!emailRegex.test(email)) {
    return 'Invalid email';
  }

  return null;
};

export const validateOrganization = (organization: string) => {
  if (organization.length < 1) {
    return 'Organization cannot be empty';
  }
  return null;
};

export const validateStatus = (status: string) => {
  if (status.length < 1) {
    return 'Status cannot be empty';
  }
  return null;
};

export const validateLicense = (license: string) => {
  if (license.length < 1) {
    return 'License cannot be empty';
  }

  const validLicense = VALID_LICENSES;

  if (!validLicense.includes(license)) {
    return `License: '${license}' not valid`;
  }

  return null;
};

export const validateRole = (role: string) => {
  if (role.length < 1) {
    return 'Role cannot be empty';
  }

  const validRoles = VALID_ROLES;
  if (!validRoles.includes(role)) {
    return `Role: '${role}' not valid`;
  }

  return null;
};

// eslint-disable-next-line no-underscore-dangle
export const validateUser_ = (refinedUser: User) => {
  const validators = [
    { validator: validateFirstName, value: refinedUser.first_name, fieldName: 'First Name' },
    { validator: validateLastName, value: refinedUser.last_name, fieldName: 'Last Name' },
    { validator: validateEmail, value: refinedUser.email, fieldName: 'Email' },
    { validator: validateOrganization, value: refinedUser.organization, fieldName: 'Organization' },
    { validator: validateStatus, value: refinedUser.status, fieldName: 'Status' },
    { validator: validateLicense, value: refinedUser.license, fieldName: 'License' },
    { validator: validateRole, value: refinedUser.role, fieldName: 'Role' }
  ];
  // eslint-disable-next-line no-restricted-syntax
  for (const { validator, value } of validators) {
    const error = validator(value);
    if (error) {
      return true;
    }
  }
  return false;
};

export default validateUser_;
