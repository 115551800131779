import React from "react";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputAdornment,
  LinearProgress,
  OutlinedInput,
  Slide,
  Stack,
  TextField,
  Toolbar,
  Typography
} from "@mui/material";
import { isEmpty } from "lodash";
import { Close, Person, SendRounded, Settings, SmartToy } from "@mui/icons-material";
import useChatQuery from "./hooks/useChatQuery";
import { TransitionProps } from "@mui/material/transitions";
import MarkdownRenderer from "./MarkdownBox";
import SettingMenu from "./SeetingsMenu";

interface Props {
  number: string;
  type: string;
}


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChatRIA = ({ number, type }: Props) => {
  const formRef = React.useRef<HTMLFormElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const keyArray = number?.split("-");
  const applicationNumber = keyArray?.[0];
  const {
    handleChangeQuery,
    handleSubmitQuery,
    queryText,
    openSession,
    openWidget,
    closeSession,
    initialised,
    queryLoading,
    chatFlow,
    s3URL,
    sections,
    handleSectionChange,
    currentSection
  } = useChatQuery();
  return (
    <React.Fragment>
      <Button
        variant="contained"
        disabled={isEmpty(applicationNumber) || isEmpty(type) || type.toLowerCase().trim() !== "letter"}
        onClick={() => openSession(applicationNumber)}
      >
        Chat with RIA
      </Button>

      <Dialog
        fullScreen
        open={openWidget}
        onClose={closeSession}
        TransitionComponent={Transition}
      >
        <Backdrop sx={{ color: "#fff", position: "absolute", zIndex: 9999999 }} open={!initialised}>
          <Stack direction={"column"} justifyContent={"center"} alignItems={"center"}>
            <Box>
              <Typography variant="h6" color="inherit">
                Initialising chat...
              </Typography>
            </Box>
            <Box mt={2} width={"100%"}>
              <LinearProgress color="primary" />
            </Box>
          </Stack>
        </Backdrop>

        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <Box>
              <Typography>
                Chat with RIA on {applicationNumber}
              </Typography>
            </Box>

            <Box>
              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <Autocomplete
                  disablePortal
                  id="sections"
                  disabled={queryLoading}
                  options={sections}
                  getOptionLabel={(option) => option.replace(/_/g, " ")}
                  onChange={handleSectionChange}
                  value={currentSection}
                  sx={{
                    width: 300,
                    ml:2,
                    backgroundColor: "white",
                  }}
                  renderInput={(params) => <TextField {...params} label="Section" />}
                />
              </FormControl>
            </Box>

            <Box>
              <SettingMenu />
            </Box>

            <Box ml={"auto"}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={closeSession}
                aria-label="close"
                disabled={queryLoading}
              >
                <Close />
              </IconButton>
            </Box>


          </Toolbar>
        </AppBar>
        <Stack direction={"row"} spacing={1} pl={2} pr={2}>
          <Box sx={{
            width: "60%"
          }}>
            <iframe
              width="100%"
              height="100%"
              title="pdf"
              src={s3URL}
            />

          </Box>

          <Stack direction={"column"} justifyContent={"flex-start"} sx={{
            backgroundColor: "white",
            width: "100%",
            height: "90vh",
            overflow: "hidden",
            "&>div": {
              p: 2
            },
            "&>div:last-child": {
              p: 0
            }

          }}>
            <Box sx={{
              maxHeight: "100%",
              height: "100%",
              flexGrow: 1,
              overflowY: "auto",
              border: "1px solid",
              borderColor: "gray.light"
            }}
                 ref={(el) => {
                   if (el) {
                     // @ts-ignore
                     el.scrollIntoView({ behavior: "smooth" });
                   }
                 }
                 }

            >
              {
                // @ts-ignore
                chatFlow?.map((item, index) => {
                  return (
                    <Stack
                      direction={"row"}
                      key={index}
                      p={1}
                      mb={3}
                      sx={{
                        backgroundColor: item.type === "user" ? "white" : "grey.100",
                        borderRadius: item.type === "user" ? "20px 20px 0 20px" : "20px 20px 20px 0",
                        border: item.type === "user" ? "1px solid" : "none",
                        alignItems: "flex-start"
                      }}
                    >
                      <Box mr={1} mt={3} ml={1}>
                        <Avatar variant={"rounded"}>
                          {item.type === "user" ? <Person /> : <SmartToy />}
                        </Avatar>
                      </Box>
                      <MarkdownRenderer markdown={item.text} />
                    </Stack>
                  );

                })}
              <Box height={10} ref={(el) => {
                if (el) {
                  // @ts-ignore
                  el.scrollIntoView({ behavior: "smooth" });
                }
              }
              } />
              <LinearProgress color="primary" sx={{
                width: "100%",
                visibility: queryLoading ? "visible" : "hidden"
              }} />
            </Box>
            <Box>
              <form onSubmit={async (e) => {
                inputRef.current?.focus();
                await handleSubmitQuery(e);
                inputRef.current?.focus();

              }} ref={formRef}>
                <FormControl variant="filled" fullWidth>
                  <OutlinedInput
                    multiline
                    maxRows={3}
                    id="outlined-adornment-password"
                    type={"text"}
                    value={queryText}
                    disabled={queryLoading}
                    onChange={handleChangeQuery}
                    autoFocus={true}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        e.preventDefault();
                        formRef.current?.requestSubmit();
                      }
                    }
                    }
                    // ref={inputRef}
                    inputRef={inputRef}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disabled={queryLoading}
                          aria-label="toggle password visibility"
                          type={"submit"}
                          edge="end"
                        >
                          <SendRounded />
                        </IconButton>
                      </InputAdornment>
                    }
                    placeholder="Ask your query here"
                  />
                </FormControl>
              </form>
            </Box>
          </Stack>
        </Stack>

      </Dialog>

    </React.Fragment>
  );
};

export default React.memo(ChatRIA);