import palette from '../../../themev5/palette';
import theme from '../../../theme';

const styles = {
  root: {
    height: '70vh',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      '& .fda_org_cell': {
        height: '26px',
        '& .fda_org_cell__more-text': {
          display: 'inline !important'
        }
      }
    },
    '& .MuiDataGrid-root': {
      width: '100%',
      '& .MuiDataGrid-columnHeader': {
        color: palette.primary.main,
        fontWeight: 'bold',
        bgcolor: palette.primary.backgroundDark
      },
      '& .MuiDataGrid-columnsContainer': {
        color: palette.primary.lightBluish,
        background: '#F7F7F7'
      },
      '& 	.MuiDataGrid-row': {
        bgcolor: 'white'
      }
    }
  },
  Typography: {
    fontSize: '24px',
    fontWeight: '700',
    color: '#3AB09E'
  },

  Button: {
    margin: theme.spacing(3, 0, 2),
    background: theme.palette.white,
    color: 'black',
    position: 'absolute',
    float: 'right',
    right: '10px'
  },

  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white
  }
};

export default styles;
