import React, { useContext, useState } from 'react';
import { Auth } from 'aws-amplify';

import Container from '@mui/material/Container';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { Button } from '@mui/material';

import styles from './styles/InvalidFileUrl.styles';

import Store from '../../store';
import Actions from '../../store/actions';

import { updatefileUrl } from '../../api/Cder';

const InvalidPdfUrlDialog = ({ closeEdit, fileData }: any) => {
  const { dispatch } = useContext(Store) as any;
  const [updatedUrl, setUpdatedUrl] = useState<string>(fileData.updated_url ?? '');
  const [reason, setReason] = useState<string>(fileData.reason ?? '');
  const [qcStatus, setQcStatus] = useState<string>(fileData.qc_status ?? '');

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      const user = await Auth.currentUserInfo();
      const payload = {
        ...fileData,
        updated_url: updatedUrl,
        qc_status: qcStatus,
        reason,
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      };
      const res = await updatefileUrl(payload);
      if (res.data.status !== 200 && res.data.status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'something went wrong' }
        });
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Successfully Updated file URL', color: 'success' }
        });
      }
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `${error}` }
      });
    }
    closeEdit();
  };

  const handleChange = () => {
    window.open(
      `https://www.accessdata.fda.gov/scripts/cder/daf/index.cfm?event=overview.process&ApplNo=${fileData.application_number}`,
      '_blank'
    );
  };

  return (
    <Container sx={styles.root}>
      <DialogTitle sx={{ m: 0, p: 2 }}>
        <Typography component='h2' variant='h5' sx={styles.Heading}>
          Edit File Url
        </Typography>
        <IconButton
          aria-label='close'
          onClick={() => {
            closeEdit();
          }}
          sx={{
            position: 'absolute',
            right: 15,
            top: 15,
            color: 'gray.light'
          }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <form onSubmit={handleSubmit} style={styles.form}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                handleChange();
              }}
              variant='contained'
              color='primary'
              sx={{ color: 'white.main' }}>
              Open FDA Page
            </Button>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              fullWidth
              id='document_id'
              label='Id'
              value={fileData.document_id}
              disabled
              InputProps={{
                style: { fontSize: '2vh' }
              }}
              InputLabelProps={{
                style: { fontSize: '2vh' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              fullWidth
              id='current_url'
              label='Current Url'
              value={fileData.current_url}
              maxRows={Infinity}
              disabled
              multiline
              InputProps={{
                style: { fontSize: '2vh' }
              }}
              InputLabelProps={{
                style: { fontSize: '2vh' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              fullWidth
              id='updated_url'
              label='Updated Url'
              value={updatedUrl}
              maxRows={Infinity}
              multiline
              onChange={e => setUpdatedUrl(e.target.value)}
              InputProps={{
                style: { fontSize: '2vh' }
              }}
              InputLabelProps={{
                style: { fontSize: '2vh' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              fullWidth
              id='reason'
              label='Reason'
              value={reason}
              minRows={2}
              maxRows={Infinity}
              multiline
              onChange={e => setReason(e.target.value)}
              InputProps={{
                style: { fontSize: '2vh' }
              }}
              InputLabelProps={{
                style: { fontSize: '2vh' }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant='outlined'
              fullWidth
              id='status'
              label='Status'
              select
              value={qcStatus}
              onChange={e => setQcStatus(e.target.value)}
              InputProps={{
                style: { fontSize: '2vh' }
              }}
              InputLabelProps={{
                style: { fontSize: '2vh' }
              }}>
              <MenuItem value='ToQced'>To Qced</MenuItem>
              <MenuItem value='Qced'>Qced</MenuItem>
              <MenuItem value='Curated'>Curated</MenuItem>
              <MenuItem value='Escalate'>Escalate</MenuItem>
              <MenuItem value='Confirmed Missing'>Confirmed Missing</MenuItem>
            </TextField>

            <Button type='submit' fullWidth variant='contained' color='primary' sx={styles.submit}>
              SUBMIT
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default React.memo(InvalidPdfUrlDialog);
