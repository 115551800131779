export const DB_SCHEMA_VALIDATION_SOURCE_MAPPING: any = {
  hma: 'hma',
  caguidances: 'canada',
  fdaletters: 'fda',
  cber: 'cber',
  cder: 'cder',
  ctgov: 'ct',
  pips: 'ema',
  epar: 'ema'
};

export const ES_INDEX_VALIDATION_PROCESS_MAPPING: any = {
  mapping_validation: 'Mapping',
  field_data_validation: 'Field Data',
  static_file_validation: 'Static File',
  kendra_metadata_validation: 'Kendra Metadata'
};
