import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import DataEntryDataGrid from "../../helpers/dataGridCreator";
// api
import { getNonQcedLabels, getNonQcedLetters } from "../../api/Adcomm";

import {
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Typography
} from "@material-ui/core";

import Store from "../../store";
import Actions from "../../store/actions";

import Styles from "./styles/DataEntry.styles";
import ChatRIA from "../ChatRIA/ChatRIA";

const DataEntry = () => {
  const pageSize = 10;
  const rowsPerPage = 10;
  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const [docType, setDocType] = useState("Letter");
  const [key, setKey] = useState();
  const [letterKey, setLetterKey] = useState([]);
  const [labelKey, setLabelKey] = useState([]);
  const [letterPageNo, setLetterPageNo] = useState(0);
  const [labelPageNo, setLabelPageNo] = useState(0);
  // rowCount is total number of rows (509 --meetings)
  const [letterRowCount, setLetterRowCount] = useState();
  const [labelRowCount, setLabelRowCount] = useState();
  const [offset, setOffset] = useState(0);


  useEffect(() => {
    const getNonQcedLetterData = async () => {
      try {
        const result = await getNonQcedLetters(letterPageNo, pageSize);
        setLetterRowCount(result.data.Success.total_rows);
        const resultData = result.data.Success.data;
        setLetterKey([...resultData?.map((item, index) => ({ ...item, id: index }))]);
      } catch (e) {
        console.error(e);
      }
    };
    getNonQcedLetterData();
  }, [letterPageNo]);

  useEffect(() => {
    const getNonQcedLabelData = async () => {
      try {
        const result = await getNonQcedLabels(labelPageNo, pageSize);
        setLabelRowCount(result.data.Success.total_rows);
        const resultData = result.data.Success.data;
        setLabelKey([...resultData?.map((item, index) => ({ ...item, id: index }))]);
      } catch (e) {
        console.error(e);
      }
    };
    getNonQcedLabelData();
  }, [labelPageNo]);

  const handleChange = event => {
    setDocType(event.target.value);
  };

  const handleKeyChange = event => {
    const text = event.target.value;
    setKey(text);
  };

  const handleKeyFormSubmit = async event => {
    event.preventDefault();
    const validateInput = key.split("-");
    if (validateInput.length !== 3) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: "Enter a valid hiphenated input. Eg: 004782-SUPPL-171"
        }
      });
    } else {
      const applNo = validateInput[0];
      const subType = validateInput[1].toUpperCase();
      const subNo = validateInput[2];
      history.push(`/reviews/${applNo}/${subType}/${subNo}/${docType}`);
    }
  };

  const handleRowData = async (key, value) => {
    const validateInput = key.split("-");
    if (validateInput.length !== 3) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: "Enter a valid hiphenated input. Eg: 004782-SUPPL-171"
        }
      });
    } else {
      const applNo = validateInput[0];
      const subType = validateInput[1].toUpperCase();
      const subNo = validateInput[2];
      history.push(`/reviews/${applNo}/${subType}/${subNo}/${value}`);
    }
  };

  const columns = [
    {
      field: "key",
      headerName: "Letter Keys",
      headerAlign: "center",
      width: 550,
      align: "center",
      headerClassName: "ria-MuiDataGrid-header",
      cellClassName: "ria-MuiDataGrid-cell",
      renderCell: params => (
        <Grid item xs={8} lg={8}>
          <Button
            id={params.row.key}
            value={params.row.key}
            className={classes.button}
            onClick={() => {
              handleRowData(params.row.key, "Letter");
            }}
          >
            {params.row.key}
          </Button>
        </Grid>
      )
    }
  ];

  const labelColumns = [
    {
      field: "key",
      headerName: "Label Keys",
      headerAlign: "center",
      width: 550,
      align: "center",
      headerClassName: "ria-MuiDataGrid-header",
      cellClassName: "ria-MuiDataGrid-cell",
      renderCell: params => (
        <Grid item xs={8} lg={8}>
          <Button
            id={params.row.key}
            value={params.row.key}
            className={classes.button}
            onClick={() => {
              handleRowData(params.row.key, "Label");
            }}
          >
            {params.row.key}
          </Button>
        </Grid>
      )
    }
  ];

  return (
    <Box className={classes.root} display="flex" alignItems="center">
      <Container maxWidth="lg" className={classes.container}>
        <Paper elevation={1} className={classes.banner}>
          <Typography className={classes.formHeadings}>
            Enter the details to review the document
          </Typography>
          <form onSubmit={handleKeyFormSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <Box margin={1} display="flex" flexDirection="column">
                  <Typography align="left" className={classes.label}>
                    Application Number
                  </Typography>
                  <TextField
                    required
                    className={classes.textfield}
                    onChange={handleKeyChange}
                    fullWidth
                    id="standard-basic"
                    placeholder="Enter a key. Eg: 004782-ORIG-1"
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Box margin={1} display="flex" flexDirection="column">
                  <Typography align="left" className={classes.label}>
                    Select a document type
                  </Typography>
                  <Box
                    margin={1}
                    display="flex"
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center">
                    <RadioGroup
                      row
                      aria-label="docType"
                      name="docType"
                      value={docType}
                      onChange={handleChange}>
                      <FormControlLabel
                        value="Letter"
                        className={classes.radioLabel}
                        control={<Radio color="default" className={classes.radio} />}
                        label="Letter"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="Label"
                        className={classes.radioLabel}
                        control={<Radio color="default" className={classes.radio} />}
                        label="Label"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} lg={3}>
                <Box margin={1} flexDirection={'column'} display="flex" justifyContent={'center'} alignItems="center" className={classes.btn}>
                  <Button type="submit" variant="contained" className={classes.button}>
                    Submit
                  </Button>
                  <Box m={1}/>
                  <ChatRIA number={key} type={docType}/>
                </Box>
              </Grid>
            </Grid>
          </form>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-evenly"
            alignItems="center"
            className={classes.dividerRoot}>
            <Divider className={classes.divider} />
            <Typography className={classes.or}>OR</Typography>
            <Divider className={classes.divider} />
          </Box>
          <Box height="69vh" display="flex" alignItems="center" justifyContent="center">
            <DataEntryDataGrid
              rows={letterKey}
              columns={columns}
              pageSize={pageSize}
              rowsPerPage={rowsPerPage}
              rowCount={letterRowCount}
              setPage={setLetterPageNo}
              setOffset={setOffset}
              page={letterPageNo}
            />

            <DataEntryDataGrid
              rows={labelKey}
              columns={labelColumns}
              pageSize={pageSize}
              rowsPerPage={rowsPerPage}
              rowCount={labelRowCount}
              setPage={setLabelPageNo}
              setOffset={setOffset}
              page={labelPageNo}
            />
          </Box>

        </Paper>
      </Container>
    </Box>
  );
};

export default DataEntry;
