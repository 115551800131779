import React from 'react';

import { Grid, Hidden } from '@mui/material';
import Login from './components/Login';

import Styles from './styles/Login.styles';

import loginBackground from '../../assets/images/Login/loginBackground.png';

const LoginPage = () => {
  const classes = Styles();

  return (
    <Grid container spacing={0}>
      <Grid item xs={12} lg={6} xl={6}>
        <Login />
      </Grid>
      <Hidden mdDown>
        <Grid item lg={6} xl={6}>
          <img alt='Login Background' className={classes.outline} src={loginBackground} />
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default LoginPage;
