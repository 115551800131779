import actions from './actions';

const reducers = (state, action) => {
  switch (action.type) {
    case actions.SET_SUBMISSION_INDICATIONS: {
      return {
        ...state,
        indications: action.value
      };
    }
    case actions.SET_INDICATION_SUMMARY: {
      return {
        ...state,
        indicationSummary: action.value
      };
    }
    case actions.SET_STUDY_SUMMARY: {
      return {
        ...state,
        studySummary: action.value
      };
    }
    case actions.SET_NON_QCED_GUIDANCE_COMPARE: {
      return {
        ...state,
        nonQcedGuidanceCompare: action.value
      };
    }
    case actions.SET_QCED_GUIDANCE_COMPARE: {
      return {
        ...state,
        qcedGuidanceCompare: action.value
      };
    }
    case actions.SET_SUBMITTING: {
      return {
        ...state,
        isSubmitting: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
