import Synonyms from '../components/Synonyms/Synonyms';

const SYNONYMS_ROUTES = [
  {
    id: 'synonyms',
    path: '/synonyms',
    component: Synonyms,
    exact: true
  }
];
export default SYNONYMS_ROUTES;
