import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

// mui
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import LinearProgress from '@mui/material/LinearProgress';
import Dialog from '@mui/material/Dialog';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';

// api
import { getAllMeetings, getMeetingIdSuggestion } from '../../api/AdcommModule';

// styles
import themev5 from '../../themev5';
import Styles, { styles } from './styles/meetingDashbaord';

// constant
import { qcStatus, rowsPerPageOptions, qcStatusOption, CENTER_OPTIONS } from './constant';
import MeetingCurationForm from './MeetingCurationForm';
import JointMeetingIdsForm from './JointMeetingIdsForm';
import AdcommNewMeeting from './AdcommNewMeeting';

const AdCommMeetingDashoard = () => {
  const history = useHistory();
  const location = useLocation();
  const classes = Styles();
  const updatedSearchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNo, setPageNo] = useState<number>(0);
  const [curatedStatus, setCuratedStatus] = useState('All');
  const [minutesStatus, setMinutesStatus] = useState('All');
  const [rosterStatus, setRosterStatus] = useState('All');
  const [center, setCenter] = useState('CDER');
  const [rowCount, setRowCount] = useState<number>(0);
  const [meetingData, setMeetingData] = useState<Array<any>>([]);
  const [meetingList, setMeetingList] = useState<Array<string>>([]);
  const [meetingId, setMeetingId] = useState<string>('');
  const [id, setId] = useState<string>('');
  const [reload, setReload] = useState<boolean>(true);
  const [showEditDialog, setShowEditDialog] = useState<boolean>(false);
  const [showJointMeetingEditDialog, setJointMeetingEditDialog] = useState<boolean>(false);
  const [meetingInfo, setMeetingInfo] = useState<string>('');
  const [openUrlPopUp, setOpenUrlPopup] = useState<boolean>(false);

  const debouncedChangeHandler = () => {
    setReload(prevState => !prevState);
  };

  const handleChange = debounce(debouncedChangeHandler, 300);

  const debouncedMeetingIdChangeHandler = async (newValue: string) => {
    try {
      if (newValue) {
        const response = await getMeetingIdSuggestion('meeting', 'meeting_id', newValue);
        if (response.data.status === 200) {
          setMeetingList(response.data.body.suggestions);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const handleMeetingIdFilters = debounce(debouncedMeetingIdChangeHandler, 300);

  const closeEditDialog = () => {
    setShowEditDialog(false);
    setReload(prevState => !prevState);
  };

  const closeJointMeetingEditDialog = () => {
    setJointMeetingEditDialog(false);
    setReload(prevState => !prevState);
  };

  const openEditDialog = (data: string) => {
    setId(data);
    setShowEditDialog(true);
  };

  const openJointMeetingDialog = (data: string) => {
    setMeetingInfo(data);
    setJointMeetingEditDialog(true);
  };

  useEffect(() => {
    const curPage = updatedSearchParams.get('page') || '0';
    if (curPage !== pageNo.toString()) {
      setPageNo(parseInt(curPage, 10));
    }
    const curPageSize = updatedSearchParams.get('pageSize') || '10';
    if (curPageSize !== pageSize.toString()) {
      setPageSize(parseInt(curPageSize, 10));
    }
    updatedSearchParams.set('page', curPage);
    updatedSearchParams.set('pageSize', curPageSize);
    history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
  }, [pageNo, pageSize]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const filters: any = {};
        if (curatedStatus !== 'All') {
          filters.qc_status = curatedStatus === 'Non Qced' ? 'to_qc' : curatedStatus.toLowerCase();
        }
        if (minutesStatus && minutesStatus !== 'All') {
          filters.minute_qc_status =
            minutesStatus === 'Non Qced' ? 'to_qc' : minutesStatus.toLowerCase();
        }
        if (rosterStatus && rosterStatus !== 'All') {
          filters.roster_qc_status =
            rosterStatus === 'Non Qced' ? 'to_qc' : rosterStatus.toLowerCase();
        }
        if (center) {
          filters.center = center.toLowerCase();
        }
        if (meetingId) {
          filters.meeting_id = meetingId;
        }

        const meetings = await getAllMeetings(pageSize, pageNo, { filters });
        if (meetings.data.body.data) {
          setRowCount(meetings.data.body.count);
          setMeetingData(meetings.data.body.data);
          // setMeetingList(meetings.data.body.filter.meeting_ids);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [curatedStatus, pageSize, pageNo, reload]);

  const DATAGRID_COLUMN = [
    {
      field: 'Edit',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              openEditDialog(params.row.meeting_id);
            }}>
            <Typography sx={{ color: 'white.main' }}>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'meeting_id',
      headerName: 'Meeting Id',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'qc_status',
      headerName: 'Qc Status',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'Actions',
      width: 500,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Stack p={2} flexDirection='row'>
            <Button
              variant='contained'
              sx={styles.Button2}
              disabled={!params.row.meeting_id}
              onClick={() =>
                history.push(
                  `/adcomm/${
                    params.row.meeting_id
                  }/meeting/minutes?${updatedSearchParams.toString()}`
                )
              }>
              <Typography sx={{ color: 'white.main' }}>Minutes</Typography>
            </Button>
            <Button
              variant='contained'
              sx={styles.Button2}
              onClick={() =>
                history.push(
                  `/adcomm/${
                    params.row.meeting_id
                  }/meeting/roster?${updatedSearchParams.toString()}`
                )
              }>
              <Typography sx={{ color: 'white.main' }}>Roster</Typography>
            </Button>
            <Button
              variant='contained'
              sx={styles.Button2}
              onClick={() =>
                history.push(
                  `/adcomm/${
                    params.row.meeting_id
                  }/meeting/material?${updatedSearchParams.toString()}`
                )
              }>
              <Typography sx={{ color: 'white.main' }}>Material</Typography>
            </Button>
          </Stack>
        );
      }
    },
    {
      field: 'Joint Meeting Ids',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return params.row.center === 'CDER' ? (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              openJointMeetingDialog(params.row);
            }}>
            <Typography sx={{ color: 'white.main' }}>Add</Typography>
          </Button>
        ) : null;
      }
    }
  ];

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        <Autocomplete
          id='meeting_ids'
          value={meetingId}
          onInputChange={(event, newValue) => {
            setMeetingId(newValue || '');
            handleMeetingIdFilters(newValue || '');
            handleChange();
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
          options={meetingList}
          renderInput={(params: any) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} placeholder='Meeting Id' label='Meeting Id' variant='outlined' />
          )}
        />
        <Autocomplete
          id='qc_status'
          value={curatedStatus}
          onChange={(event: React.ChangeEvent<{}>, newValue: string | null): void => {
            setCuratedStatus(newValue || '');
            setPageNo(0);
            handleChange();
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
          options={qcStatus}
          renderInput={(params: any) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} placeholder='QC Status' label='QC Status' variant='outlined' />
          )}
        />
        <Autocomplete
          id='minutes_qc_status'
          value={minutesStatus}
          onChange={(event: React.ChangeEvent<{}>, newValue: string | null): void => {
            setMinutesStatus(newValue || '');
            setPageNo(0);
            handleChange();
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
          options={qcStatusOption}
          renderInput={(params: any) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder='Minutes QC Status'
              label='Minutes QC Status'
              variant='outlined'
            />
          )}
        />
        <Autocomplete
          id='roster_qc_status'
          value={rosterStatus}
          onChange={(event: React.ChangeEvent<{}>, newValue: string | null): void => {
            setRosterStatus(newValue || '');
            setPageNo(0);
            handleChange();
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
          options={qcStatusOption}
          renderInput={(params: any) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder='Roster QC Status'
              label='Roster QC Status'
              variant='outlined'
            />
          )}
        />
        <Autocomplete
          id='center'
          value={center}
          onChange={(event: React.ChangeEvent<{}>, newValue: string | null): void => {
            setCenter(newValue || '');
            setPageNo(0);
            handleChange();
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
          options={CENTER_OPTIONS}
          renderInput={(params: any) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder='Center'
              label='center'
              variant='outlined'
            />
          )}
        />
        <Button
          variant='contained'
          sx={styles.Button}
          onClick={() => {
            setOpenUrlPopup(true);
          }}>
          <Typography sx={{ color: 'white.main' }}>Add New Meeting</Typography>
        </Button>
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          style={{ height: '100%' }}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={meetingData}
          columns={DATAGRID_COLUMN as any}
          rowCount={rowCount}
          pageSize={pageSize}
          getRowId={row => row.meeting_id}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPageNo(0);
            updatedSearchParams.set('pageSize', newPageSize.toString());
            updatedSearchParams.set('page', '0');
            history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
          }}
          onPageChange={(page: number) => {
            setPageNo(page);
            updatedSearchParams.set('page', page.toString());
            history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
          }}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={pageNo}
          paginationMode='server'
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        {openUrlPopUp && (
          <AdcommNewMeeting openUrlPopUp={openUrlPopUp} setOpenUrlPopup={setOpenUrlPopup} />
        )}
        <Dialog
          disableEscapeKeyDown
          open={showEditDialog}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          <MeetingCurationForm closeEdit={closeEditDialog} meetingId={id} />
        </Dialog>

        <Dialog
          open={showJointMeetingEditDialog}
          disableEscapeKeyDown
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          <JointMeetingIdsForm closeEdit={closeJointMeetingEditDialog} meetingData={meetingInfo} />
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(AdCommMeetingDashoard);
