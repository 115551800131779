import { useState, useEffect, useContext, SyntheticEvent, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// api
import { submitApplicationDetails, getCuratedLabelDetails } from '../../api/Cber'

// context
import Store from '../../store';
import Actions from '../../store/actions';

// material ui
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import utils from '../Datagrid/utils'

const qc_status = [
  'QCed',
  'No Document',
  'Escalate',
  'Missing Details',
];
const NonQcdDoc = ({ closeEdit, docs_id, application_number, searchText, search_status }: any) => {
  const { dispatch } = useContext<any>(Store);
  const history = useHistory();
  const [indication, setindication] = useState('')
  const [boxText, setBoxText] = useState('')
  const [status, setstatus] = useState('')
  const [url, seturl] = useState('')
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  useEffect(() => {
    const getURL = async () => {
      try {
        const user = await Auth.currentUserInfo();
        setEmail(user.attributes.email);
        setName(user.attributes['custom:user']);
        const result = await getCuratedLabelDetails(docs_id);
        if (result === undefined) {
          throw new Error("Result is undefined")
        }
        if (result.data && result.data.body.result) {
          let resultData = result.data.body.result
          seturl(resultData.document_link)
          setBoxText(resultData.box_text)
          setindication(resultData.indication_usage)
          setstatus(resultData.qc_status === "qced" ? "QCed" : utils(resultData.qc_status, 'formulations'))
        }
        else {
          throw Error('Cannot Show PDF');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getURL();
  }, [])

  const handleKeyFormSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = {
      application_number: application_number,
      indication_usage: indication,
      box_text: boxText,
      qc_status: status.toLowerCase(),
      curator_email: email,
      curator_name: name,
    }
    const res = await submitApplicationDetails(docs_id, payload);
    const resData = res.data;
    if (resData.error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: resData.message }
      });
    }
    else {
      closeEdit()
      const queryParams = [];
      const baseURL = `/cber?qc_status=${search_status}`;
      if (searchText) {
        queryParams.push(`application_number=${searchText.trim()}`)
      }
      history.push(`${baseURL}&${queryParams.join('&')}`)
      window.location.reload();
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: resData.body, color: 'success' }
      });
    }
  };
  return (
    <Container >
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography component='h2' variant='h5' style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px', }}>
            Label Curation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <Grid container spacing={2} display='flex'>
          <Grid item xs={12} lg={6}>
            <form
              onSubmit={handleKeyFormSubmit} style={{ marginTop: '20px' }}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='indication_and_usage'
                    label='Indications and Usage'
                    value={indication}
                    minRows={2}
                    maxRows={Infinity}
                    multiline
                    onChange={e =>
                      setindication(e.target.value)
                    }
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '2vh' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='box_text'
                    label='Box Warning Text'
                    value={boxText}
                    minRows={2}
                    maxRows={5}
                    multiline
                    onChange={e =>
                      setBoxText(e.target.value)
                    }
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '2vh' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Autocomplete
                    id="qc_status"
                    fullWidth
                    value={status}
                    options={qc_status}
                    onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                      if (newValue !== null) {
                        setstatus(newValue)
                      }
                    }}
                    sx={{
                      "& .MuiInputBase-input": {
                        fontSize: '1.5vh',
                        minWidth: '250px'
                      },
                      "& .MuiFormLabel-root": {
                        fontSize: '1.5vh'
                      }
                    }}
                    renderInput={(params: any) => <TextField {...params} label="QC Status" variant="outlined" required/>}
                  />
                </Grid>
                <Grid item xs={12} display='flex' direction='column'>
                  <Button type='submit' fullWidth variant='contained' color='primary'
                    style={{ color: 'white' }}
                  >
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
          <Grid item xs={12} lg={6}>
            {url ? null : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{ height: '100%', width: '100%', }}>
                <Typography
                  style={{ fontSize: '20px', fontWeight: 'bold', color: '#33B187' }}
                >Can't Open This PDF </Typography>
              </Box>
            )}
            {url ? (
              <Box display='flex' justifyContent='center'>
                <iframe
                  title='pdf'
                  src={url}
                  style={{ height: '75vh', width: '50vw', marginTop: '20px', position: 'relative' }}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default NonQcdDoc