import { useState, useCallback, useContext, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import {
  addIndication,
  addStudyData,
  copyStaleIndication,
  deleteIndication,
  deleteStudy,
  duplicateIndication,
  getAttributes,
  getEndpointValues,
  updateIndication,
  updateIndicationStale,
  updateStudyData
} from '../../../api/submission_details';
import Store from '../../../store';

import Actions from '../../../store/actions';
import { STUDY_KEY_TITLE } from '../constant';

const useIndicationAPIs = (
  applno: string,
  submissionType: string,
  submissionNumber: any,
  apiIndications: any
) => {
  const { dispatch } = useContext<any>(Store);
  const [indications, setIndications] = useState<Array<any>>([]);
  const [attributes, setAttributes] = useState<Array<any>>([]);
  const [endpointOptions, setEndpointOptions] = useState<Array<any>>([]);

  const [user, setUser] = useState<any>();

  useEffect(() => {
    (async () => {
      const authUser = await Auth.currentUserInfo();
      setUser({
        curator_name: authUser.attributes['custom:user'],
        curator_email: authUser.attributes.email
      });
    })();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getPossibleAttributes();
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    getEndpointOptions();
  }, []);

  useEffect(() => {
    setIndications(
      apiIndications?.map((ind: any) => ({
        ...ind,
        indication: {
          ...ind.indication,
          studies: ind.indication.studies?.map((stud: any) => ({
            ...stud
          }))
        }
      }))
    );
  }, [apiIndications]);

  const alert = useCallback((message: string, type: string) => {
    dispatch({
      type: Actions.SET_ALERT,
      value: {
        status: true,
        message,
        color: type
      }
    });
  }, []);

  const getEndpointOptions = useCallback(async () => {
    // eslint-disable-next-line no-console
    const response = await getEndpointValues().catch(console.error);
    if (response?.data?.Status === 200) {
      setEndpointOptions(response.data.Success || []);
    }
  }, []);

  const getPossibleAttributes = useCallback(async () => {
    // eslint-disable-next-line no-console
    const response = await getAttributes().catch(console.error);
    if (response.data?.Status === 200) {
      setAttributes(response.data.Success);
    } else {
      alert('Failed to get attributes. You will not be able to add studies', 'error');
    }
  }, []);

  const expandIndication = useCallback(
    (id: Number) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === id);
      newIndications[index].expanded = !newIndications[index].expanded;
      setIndications(newIndications);
    },
    [indications]
  );

  const editIndication = useCallback(
    (id: Number) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === id);
      newIndications[index].editOpen = !newIndications[index].editOpen;
      setIndications(newIndications);
    },
    [indications]
  );

  const saveIndication = useCallback(
    async (id: Number) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === id);
      if (newIndications[index].newIndication || newIndications[index].dataFromPrevSubmission) {
        try {
          const response = await addIndication({
            text: newIndications[index].indication.text,
            applno,
            submission_type: submissionType,
            submission_number: submissionNumber,
            ...user
          });
          if (response.data.Status === 200) {
            let { studies } = response.data.Success.app_indication.indication;
            if (studies.length < newIndications[index].indication.studies.length) {
              studies = newIndications[index].indication.studies;
            }
            newIndications[index] = {
              ...newIndications[index],
              ...response.data.Success.app_indication,
              ...{ indication: { ...response.data.Success.app_indication.indication, studies } },
              newIndication: false,
              dataFromPrevSubmission: false
            };
            newIndications[index].editOpen = false;
            setIndications(newIndications);
            alert('New Indication added', 'success');
          } else {
            alert('Failed to add new Indication', 'error');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          alert('Failed to add new Indication', 'error');
        }
      } else {
        // Handle Edit
        const response = await updateIndication(
          applno,
          submissionType,
          submissionNumber,
          newIndications[index].indication.id,
          {
            text: newIndications[index].indication.text,
            ...user
            // eslint-disable-next-line no-console
          }
          // eslint-disable-next-line no-console
        ).catch(console.error);
        if (response.data.Status === 200) {
          if (typeof response.data.Success?.app_indication !== 'boolean') {
            newIndications[index].indication.id = response.data.Success.app_indication.id;
          }
          newIndications[index].editOpen = false;
          setIndications(newIndications);
          alert('Indication updated successfully', 'success');
        } else {
          alert('Failed to update indication', 'error');
        }
      }
    },
    [indications, user, applno, submissionType, submissionNumber]
  );

  const updateIndicationText = useCallback(
    (id: number, value: string) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === id);
      newIndications[index].indication.text = value;
      setIndications(newIndications);
    },
    [indications]
  );

  const addEmptyIndication = useCallback(() => {
    const newIndications = [...indications];
    newIndications.push({
      id: Date.now(),
      newIndication: true,
      indication: {
        id: Date.now(),
        text: '',
        studies: []
      },
      expanded: true,
      editOpen: true
    });
    setIndications(newIndications);
  }, [indications]);

  const addEmptyStudy = useCallback(
    (id: number) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === id);
      // eslint-disable-next-line camelcase
      const attrs = attributes.map(({ attribute_id }: any) => ({
        // eslint-disable-next-line camelcase
        attribute_id,
        attribute_value: null
      }));
      if (attrs.length > 2) {
        attrs.length = 2;
      }
      newIndications[index].indication.studies.push({
        id: Date.now(),
        formOpen: true,
        newStudy: true,
        attributes: attrs,
        ...Object.keys(STUDY_KEY_TITLE).reduce(
          (mergedObj, currentKey) => ({
            ...mergedObj,
            [currentKey]: STUDY_KEY_TITLE[currentKey].default
          }),
          {}
        )
      });
      setIndications(newIndications);
    },
    [indications]
  );

  const editStudy = useCallback(
    (indicationId: number, studyData: any) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === indicationId);

      const studyIndex = newIndications[index].indication.studies.findIndex(
        (st: any) => st.id === studyData.id
      );

      newIndications[index].indication.studies[studyIndex] = {
        ...newIndications[index].indication.studies[studyIndex],
        formOpen: true
      };
      setIndications(newIndications);
    },
    [indications]
  );

  const saveStudyData = useCallback(
    async (indicationId: number, studyData: any) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === indicationId);
      const studyIndex = newIndications[index].indication.studies.findIndex(
        (st: any) => st.id === studyData.id
      );
      if (studyData.newStudy) {
        try {
          const response = await addStudyData(
            applno,
            submissionType,
            submissionNumber,
            indicationId,
            {
              nct_id: studyData.nct_id,
              identifier: studyData.identifier,
              center: studyData.center,
              control: studyData.control,
              randomized: studyData.randomized,
              blinding: studyData.blinding,
              combined_study_count: studyData.combined_study_count,
              has_combined_study_data: studyData.has_combined_study_data,
              is_label_diff_from_clinical_posting: studyData.is_label_diff_from_clinical_posting,
              label_diff_from_clinical_posting_notes:
                studyData.label_diff_from_clinical_posting_notes,
              attributes: studyData.attributes,
              ...user
            }
          );
          if (response.data.Status === 200) {
            if (response.data.Success.indication) {
              newIndications[index].indication.id = response.data.Success.indication.id;
            }
            newIndications[index].indication.studies[studyIndex] = {
              ...response.data.Success,
              newStudy: false,
              formOpen: false
            };
            setIndications(newIndications);
            getEndpointOptions();
            alert('Study data added successfully', 'success');
          } else {
            alert('Failed to add Study Data', 'error');
          }
        } catch (error) {
          // eslint-disable-next-line no-console
          console.log(error);
          alert('Failed to add Study Data', 'error');
        }
      } else {
        const response = await updateStudyData(
          applno,
          submissionType,
          submissionNumber,
          indicationId,
          studyData.id,
          {
            nct_id: studyData.nct_id,
            identifier: studyData.identifier,
            center: studyData.center,
            control: studyData.control,
            randomized: studyData.randomized,
            blinding: studyData.blinding,
            combined_study_count: studyData.combined_study_count,
            has_combined_study_data: studyData.has_combined_study_data,
            is_label_diff_from_clinical_posting: studyData.is_label_diff_from_clinical_posting,
            label_diff_from_clinical_posting_notes:
              studyData.label_diff_from_clinical_posting_notes,
            attributes: studyData.attributes,
            ...user
            // eslint-disable-next-line no-console
          }
          // eslint-disable-next-line no-console
        ).catch(console.error);
        if (response?.data?.Status === 200) {
          if (response?.data?.Success?.indication) {
            newIndications[index].indication.id = response?.data?.Success?.indication.id;
          }
          newIndications[index].indication.studies[studyIndex] = {
            ...response.data.Success,
            newStudy: false,
            formOpen: false
          };
          setIndications(newIndications);
          getEndpointOptions();
          alert('Study data updated successfully', 'success');
        } else {
          alert('Failed to update Study Data', 'error');
        }
      }
    },
    [indications, user, applno, submissionType, submissionNumber]
  );

  const deleteStudyData = useCallback(
    async (indicationId: number, studyData: any) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === indicationId);
      const studyIndex = newIndications[index].indication.studies.findIndex(
        (st: any) => st.id === studyData.id
      );
      if (studyData.newStudy) {
        newIndications[index].indication.studies.splice(studyIndex, 1);
        setIndications(newIndications);
      } else {
        // Call delete API
        // eslint-disable-next-line no-console
        const response = await deleteStudy(
          applno,
          submissionType,
          submissionNumber,
          indicationId,
          studyData.id
          // eslint-disable-next-line no-console
        ).catch(console.error);
        if (response?.data?.Status === 200) {
          if (typeof response.data.Success.deleted !== 'boolean') {
            newIndications[index].indication.id = response.data.Success.deleted.id;
          }
          newIndications[index].indication.studies.splice(studyIndex, 1);
          setIndications(newIndications);
          alert('Study data deleted successfully', 'success');
        } else {
          // eslint-disable-next-line no-console
          console.error('failed to delete', indicationId, studyData);
          alert('Failed to delete Study data', 'error');
        }
      }
    },
    [indications, applno, submissionType, submissionNumber]
  );

  const updateStaleStatus = useCallback(
    async (indicationId: number) => {
      try {
        const response = await updateIndicationStale(
          applno,
          submissionType,
          submissionNumber,
          indicationId,
          {
            stale_status: false
          }
        ).catch(console.error);
        if (response?.data?.Status === 200) {
          const newIndications = [...indications];
          const index = newIndications.findIndex((ind: any) => ind.indication.id === indicationId);
          newIndications[index] = {
            ...newIndications[index],
            is_stale: false
          };
          setIndications(newIndications);
          alert('Updated Stale Status', 'success');
        } else {
          alert('Failed to update Stale Status', 'error');
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        alert('Failed to update Stale Status', 'error');
      }
    },
    [indications, applno, submissionType, submissionNumber]
  );

  const copyStaleIndications = useCallback(
    async (selectedIndicationForComparison: Array<any>) => {
      try {
        const response = await copyStaleIndication(applno, submissionType, submissionNumber, {
          current_indication_id: selectedIndicationForComparison[1].id,
          parent_indication_id: selectedIndicationForComparison[0].id
        }).catch(console.error);
        if (response?.data?.Status === 200) {
          const newIndications = [...indications];
          const index = newIndications.findIndex(
            (ind: any) => ind.indication.id === selectedIndicationForComparison[1].id
          );
          newIndications[index] = {
            ...newIndications[index],
            indication: { ...selectedIndicationForComparison[0] },
            is_stale: false
          };
          setIndications(newIndications);

          alert('Copied indication successfully', 'success');
        } else {
          alert('Failed to copy', 'error');
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        alert('Failed to copy', 'error');
      }
    },
    [indications, applno, submissionType, submissionNumber]
  );

  const rejectIdentical = useCallback(
    (indicationId: number) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === indicationId);
      newIndications[index] = {
        ...newIndications[index],
        indication: {
          ...newIndications[index].indication,
          studies: newIndications[index].indication.studies?.map((stud: any) => ({
            ...stud,
            formOpen: true,
            newStudy: true
          }))
        },
        newIndication: true,
        dataFromPrevSubmission: false,
        editOpen: true
      };

      setIndications(newIndications);
    },
    [indications]
  );

  const copyIndicationFromPrev = useCallback(
    async (indicationId: number) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === indicationId);
      try {
        const response = await duplicateIndication({
          applno,
          indicationId,
          submission_number: submissionNumber,
          submission_type: submissionType,
          ...user
        });
        if (response?.data?.Status === 200) {
          newIndications[index] = {
            ...newIndications[index],
            dataFromPrevSubmission: false
          };
          setIndications(newIndications);
        } else {
          alert('Failed to mark indication identical', 'error');
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        alert('Failed to mark indication identical', 'error');
      }
    },
    [indications, user, applno, submissionType, submissionNumber]
  );

  const deleteIndicationId = useCallback(
    async (indicationId: number) => {
      const newIndications = [...indications];
      const index = newIndications.findIndex((ind: any) => ind.indication.id === indicationId);
      if (newIndications[index].newIndication || newIndications[index].dataFromPrevSubmission) {
        newIndications.splice(index, 1);
        setIndications(newIndications);
      } else {
        const response = await deleteIndication(
          applno,
          submissionType,
          submissionNumber,
          indicationId
          // eslint-disable-next-line no-console
        ).catch(console.error);
        if (response?.data?.Status === 200) {
          newIndications.splice(index, 1);
          setIndications(newIndications);
          alert('Indication deleted successfully', 'success');
        } else {
          // eslint-disable-next-line no-console
          console.error('failed to delete', indicationId);
          alert('Failed to delete Indication', 'error');
        }
      }
    },
    [indications, applno, submissionType, submissionNumber]
  );

  return {
    indications,
    attributes,
    endpointOptions,
    getEndpointOptions,
    getPossibleAttributes,
    expandIndication,
    editIndication,
    saveIndication,
    updateIndicationText,
    addEmptyStudy,
    editStudy,
    deleteStudyData,
    saveStudyData,
    updateStaleStatus,
    copyStaleIndications,
    rejectIdentical,
    copyIndicationFromPrev,
    deleteIndicationId,
    addEmptyIndication
  };
};

export default useIndicationAPIs;
