/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  Stack,
  Typography,
  Autocomplete,
  TextField,
  DialogContentText,
  Tooltip
} from '@mui/material';
import { Close, Add, HelpOutline, AutoAwesome, Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { isEmpty } from 'lodash';
import customNodeStyles from './styles/CustomNode.styles';
import Store from '../../store';
import actions from '../../store/actions';
import { updateRules, getSuggestedRules, getTableData } from '../../api/DataQuality';
import TableView from './TableView';

const RuleView = ({ schema, schemaData, open, handleClose, tableName = null }) => {
  const { dispatch } = useContext(Store);
  const [saveLoading, setSaveLoading] = useState(false);
  const [suggestionsLoading, setSuggestionsLoading] = useState(false);
  const [viewTableLoading, setViewTableLoading] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [changesMade, setChangesMade] = useState(false);
  const [ruleInfoOpen, setRuleInfoOpen] = useState(false);
  const [tableDataOpen, setTableDataOpen] = useState(false);
  const [tableSelect, setTableSelect] = useState(null);
  const [tableRules, setTableRules] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [rowData, setRowData] = useState([]);

  const conditions = [
    'not duplicate',
    'only contain',
    'does not contain',
    'relation',
    'length',
    'date',
    'regex'
  ];

  const chainingAllowed = ['relation'];

  const chainedConditions = ['only contain', 'does not contain'];

  let backupTableRules = [];

  const checkIfRuleExists = ruleObject => {
    if (ruleObject.values && Array.isArray(ruleObject.values))
      return tableRules.some(
        e =>
          e.column === ruleObject.column &&
          e.condition === ruleObject.condition &&
          e.relatedTable === ruleObject.relatedTable &&
          e.relatedColumn === ruleObject.relatedColumn &&
          e.values?.length === ruleObject.values?.length &&
          e.values.every(element => ruleObject.values.includes(element))
      );
    return tableRules.some(
      e =>
        e.column === ruleObject.column &&
        e.condition === ruleObject.condition &&
        e.values === ruleObject.values &&
        e.relatedTable === ruleObject.relatedTable &&
        e.relatedColumn === ruleObject.relatedColumn
    );
  };

  const checkIfRuleEmpty = rule => {
    const { column, condition, values, relatedTable, relatedColumn, chainedRules } = rule;
    if (!column || isEmpty(column)) return true;
    if (!condition || isEmpty(condition)) return true;
    switch (condition) {
      case 'only contain':
      case 'does not contain':
      case 'length':
      case 'regex':
        if (!values || isEmpty(values)) return true;
        break;
      case 'relation':
        if (!relatedTable || !relatedColumn || isEmpty(relatedTable) || isEmpty(relatedColumn))
          return true;
        break;
      default:
        return false;
    }
    if (chainedRules && chainedRules.length > 0) {
      if (chainedRules.every(chainedRule => checkIfRuleEmpty(chainedRule))) return true;
    }
    return false;
  };

  const saveRules = async () => {
    try {
      // Remove suggested key from all objects
      const newTableRules = tableRules.map(({ suggested, ...rest }) => rest);
      const isValidRule = newTableRules.every((rule, index) => {
        if (checkIfRuleEmpty(rule)) {
          dispatch({
            type: actions.SET_ALERT,
            value: {
              status: true,
              message: `Rule #${index + 1} is invalid`
            }
          });
          return false;
        }
        return true;
      });
      if (!isValidRule) return;
      setSaveLoading(true);
      const res = await updateRules({
        schema,
        table: tableSelect,
        rules: newTableRules
      });
      if (res.data.Success) {
        await dispatch({
          type: actions.SET_ALERT,
          value: {
            status: true,
            message: 'Rules updated successfully',
            color: 'success'
          }
        });
        handleClose(schema, tableSelect, newTableRules);
        setSaveLoading(false);
        setChangesMade(false);
      } else {
        await dispatch({
          type: actions.SET_ALERT,
          value: {
            status: true,
            message: res.data.Error ?? 'Could not update rules'
          }
        });
        setSaveLoading(false);
        setChangesMade(true);
      }
    } catch (e) {
      await dispatch({
        type: actions.SET_ALERT,
        value: {
          status: true,
          message: 'Could not update rules'
        }
      });
      setSaveLoading(false);
      setChangesMade(true);
    }
  };

  const deleteRule = (index, chainedIndex = -1) => {
    if (index < 0) return;
    const newRules = [...tableRules];
    if (chainedIndex === -1) newRules.splice(index, 1);
    else {
      newRules[index].chainedRules.splice(chainedIndex, 1);
    }
    setTableRules(newRules);
    setChangesMade(true);
    backupTableRules = newRules;
  };

  const deleteAllRules = () => {
    backupTableRules = [];
    setTableRules([]);
    setChangesMade(true);
  };

  const addNewRule = () => {
    const newRules = [
      {
        column: null,
        condition: null,
        values: null,
        relatedTable: null,
        relatedColumn: null,
        suggested: false,
        chainedRules: []
      },
      ...tableRules
    ];
    backupTableRules = newRules;
    setTableRules(newRules);
    setChangesMade(true);
  };

  const addNewChainedRule = ruleIndex => {
    const newRules = [...tableRules];
    const updatedRule = {
      ...newRules[ruleIndex],
      chainedRules: [
        ...newRules[ruleIndex].chainedRules,
        {
          column: null,
          condition: null,
          values: null,
          relatedTable: null,
          relatedColumn: null
        }
      ]
    };

    newRules[ruleIndex] = updatedRule;
    backupTableRules = newRules;
    setTableRules(newRules);
    setChangesMade(true);
  };

  const handleRuleInfoOpen = () => {
    setRuleInfoOpen(true);
  };

  const handleRuleInfoClose = () => {
    setRuleInfoOpen(false);
  };

  const handleTableDataClose = () => {
    setTableDataOpen(false);
  };

  const fetchTableData = async () => {
    setViewTableLoading(true);
    try {
      const res = await getTableData(schema, tableSelect);
      if (res.data.Success) {
        setColumnData(res.data.Success.columns);
        setRowData(res.data.Success.rows);
        setTableDataOpen(true);
      } else {
        await dispatch({
          type: actions.SET_ALERT,
          value: {
            status: true,
            message: res.data.Error ?? 'Could not get table data'
          }
        });
        setSaveLoading(false);
        setChangesMade(true);
      }
    } catch (e) {
      await dispatch({
        type: actions.SET_ALERT,
        value: {
          status: true,
          message: 'Could not get table data'
        }
      });
    }
    setViewTableLoading(false);
  };

  const handleTableChange = newValue => {
    backupTableRules = schemaData[newValue].rules;
    setTableSelect(newValue);
    setTableRules(
      schemaData[newValue].rules.map(x => {
        return { ...x, suggested: false };
      })
    );
    setSaveLoading(false);
    setSuggestionsLoading(false);
    setAlertOpen(false);
    setChangesMade(false);
    setRuleInfoOpen(false);
    setTableDataOpen(false);
    setColumnData([]);
    setRowData([]);
  };

  const handleChange = async (key, index, newValue) => {
    let newVal = newValue;
    if (Array.isArray(newValue)) {
      newVal = newValue.filter(x => x.trim() !== '');
    }
    const rules = [...tableRules];
    if (key === 'relatedTable')
      rules[index] = { ...rules[index], [key]: newVal, relatedColumn: null };
    else rules[index] = { ...rules[index], [key]: newVal };
    // Clear values if condition changes
    if (key === 'condition')
      rules[index] = { ...rules[index], values: null, relatedColumn: null, relatedTable: null };
    if (checkIfRuleExists(rules[index])) {
      await dispatch({
        type: actions.SET_ALERT,
        value: { status: true, message: 'Rule already exists' }
      });
      const oldrules = [...tableRules];
      oldrules[index] = { ...backupTableRules[index] };
      setTableRules(oldrules);
      return;
    }
    setTableRules(rules);
    setChangesMade(true);
  };

  const handleChainedChange = async (key, index, chainedIndex, newValue) => {
    let newVal = newValue;
    if (Array.isArray(newValue)) {
      newVal = newValue.filter(x => x.trim() !== '');
    }
    const rules = [...tableRules];
    const chainedRules = [...rules[index].chainedRules];
    if (key === 'relatedTable')
      chainedRules[chainedIndex] = {
        ...chainedRules[chainedIndex],
        [key]: newVal,
        relatedColumn: null
      };
    else
      chainedRules[chainedIndex] = {
        ...chainedRules[chainedIndex],
        [key]: newVal
      };
    // Clear values if condition changes
    if (key === 'condition')
      chainedRules[chainedIndex] = {
        ...chainedRules[chainedIndex],
        values: null,
        relatedColumn: null,
        relatedTable: null
      };
    rules[index].chainedRules = chainedRules;
    setTableRules(rules);
    setChangesMade(true);
  };

  const handleRuleClose = () => {
    if (changesMade) setAlertOpen(true);
    else handleClose();
  };

  const getSuggestions = async () => {
    setSuggestionsLoading(true);
    try {
      const res = await getSuggestedRules(schema, tableSelect);
      if (res.data.Success) {
        const newRules = res.data.Success.filter(x => !checkIfRuleExists(x)).map(x => {
          return { ...x, suggested: true };
        });
        if (newRules?.length === 0) {
          await dispatch({
            type: actions.SET_ALERT,
            value: {
              status: true,
              message: 'All the suggestions are already applied'
            }
          });
          setSuggestionsLoading(false);
          return;
        }
        backupTableRules = [...newRules, ...tableRules];
        setTableRules([...newRules, ...tableRules]);
        setChangesMade(true);
      } else
        await dispatch({
          type: actions.SET_ALERT,
          value: {
            status: true,
            message: res.data.Error ?? 'Could not generate suggested rules'
          }
        });
    } catch (e) {
      await dispatch({
        type: actions.SET_ALERT,
        value: {
          status: true,
          message: 'Could not generate suggested rules'
        }
      });
    }
    setSuggestionsLoading(false);
  };

  useEffect(() => {
    if (tableName) handleTableChange(tableName);
    else {
      backupTableRules = [];
      setSaveLoading(false);
      setSuggestionsLoading(false);
      setAlertOpen(false);
      setChangesMade(false);
      setRuleInfoOpen(false);
      setTableDataOpen(false);
      setColumnData([]);
      setRowData([]);
      setTableSelect(null);
      setTableRules([]);
    }
  }, [tableName]);

  const createRuleUI = (
    column,
    condition,
    values,
    relatedTable,
    relatedColumn,
    relatedCondition,
    suggested,
    chainedRules,
    index
  ) => (
    <Box sx={suggested ? customNodeStyles.suggestedRuleBorder : customNodeStyles.ruleBorder}>
      <ListItem disablePadding>
        <Typography
          sx={{
            display: 'inline-block',
            position: 'absolute',
            top: '-22px',
            left: '5px',
            backgroundColor: 'white',
            padding: '0px 5px'
          }}>{`#${index + 1}`}</Typography>
        <Stack direction='column'>
          <Stack direction='row' spacing={2}>
            <Autocomplete
              options={schemaData[tableSelect].columns}
              value={column}
              onChange={(event, newValue) => handleChange('column', index, newValue)}
              sx={{ width: 250 }}
              renderInput={params => (
                <Tooltip
                  title={params.inputProps?.value?.length > 20 ? params.inputProps?.value : ''}>
                  <TextField {...params} label='Column' />
                </Tooltip>
              )}
              renderOption={(props, option) => (
                <Tooltip title={option?.length < 20 ? '' : option}>
                  <Typography {...props}>{option}</Typography>
                </Tooltip>
              )}
            />
            {column ? (
              <Autocomplete
                options={conditions}
                value={condition}
                onChange={(event, newValue) => handleChange('condition', index, newValue)}
                sx={{ minWidth: 250 }}
                renderInput={params => (
                  <Tooltip
                    title={params.inputProps?.value?.length > 20 ? params.inputProps?.value : ''}>
                    <TextField {...params} label='Condition' />
                  </Tooltip>
                )}
                renderOption={(props, option) => (
                  <Tooltip title={option?.length < 20 ? '' : option}>
                    <Typography {...props}>{option}</Typography>
                  </Tooltip>
                )}
              />
            ) : null}
            {condition && (condition === 'only contain' || condition === 'does not contain') ? (
              <Autocomplete
                freeSolo
                multiple
                options={[]}
                value={values ?? []}
                onChange={(event, newValue) => handleChange('values', index, newValue)}
                sx={{ minWidth: 250 }}
                clearOnEscape
                clearOnBlur
                renderInput={params => <TextField {...params} label='Values' />}
                renderOption={(props, option) => (
                  <Tooltip title={option?.length < 20 ? '' : option}>
                    <Typography {...props}>{option}</Typography>
                  </Tooltip>
                )}
              />
            ) : null}
            {condition && (condition === 'length' || condition === 'regex') ? (
              <TextField
                variant='outlined'
                label='Value'
                value={values || ''}
                sx={{ minWidth: 250 }}
                onChange={event => handleChange('values', index, event.target.value)}
              />
            ) : null}
            {condition && condition === 'relation' ? (
              <Autocomplete
                options={Object.keys(schemaData).filter(x => x !== tableSelect)}
                value={relatedTable}
                onChange={(event, value) => handleChange('relatedTable', index, value)}
                sx={{ minWidth: 250 }}
                renderInput={params => (
                  <Tooltip
                    title={params.inputProps?.value?.length > 20 ? params.inputProps?.value : ''}>
                    <TextField {...params} label='Related Table' />
                  </Tooltip>
                )}
                renderOption={(props, option) => (
                  <Tooltip title={option?.length < 20 ? '' : option}>
                    <Typography {...props}>{option}</Typography>
                  </Tooltip>
                )}
              />
            ) : null}
            {condition && condition === 'relation' && relatedTable ? (
              <Autocomplete
                options={schemaData[relatedTable].columns}
                value={relatedColumn}
                onChange={(event, value) => handleChange('relatedColumn', index, value)}
                sx={{ minWidth: 250 }}
                renderInput={params => (
                  <Tooltip
                    title={params.inputProps?.value?.length > 20 ? params.inputProps?.value : ''}>
                    <TextField {...params} label='Related Column' />
                  </Tooltip>
                )}
                renderOption={(props, option) => (
                  <Tooltip title={option?.length < 20 ? '' : option}>
                    <Typography {...props}>{option}</Typography>
                  </Tooltip>
                )}
              />
            ) : null}
            {condition &&
            chainedRules &&
            chainedRules?.length === 0 &&
            chainingAllowed.includes(condition) ? (
              <Button
                disabled={!tableSelect}
                variant='outlined'
                sx={customNodeStyles.outlinedButton}
                onClick={() => addNewChainedRule(index)}
                startIcon={<Add />}>
                Add Chain Rule
              </Button>
            ) : null}
          </Stack>
          <Stack direction='row' spacing={2}>
            {chainedRules && chainedRules?.length > 0
              ? chainedRules.map(
                  (
                    {
                      table: chainedTable,
                      column: chainedColumn,
                      condition: chainedCondition,
                      values: chainedValues,
                      relatedTable: chainedRelatedTable,
                      relatedColumn: chainedRelatedColumn,
                      relatedConditon: chainedRelatedCondition
                    },
                    chainedIndex
                  ) => (
                    <Box sx={customNodeStyles.chainRuleBorder}>
                      <ListItem disablePadding>
                        <Typography
                          sx={{
                            display: 'inline-block',
                            position: 'absolute',
                            top: '-22px',
                            left: '5px',
                            backgroundColor: 'white',
                            padding: '0px 5px'
                          }}>{`#${chainedIndex + 1}`}</Typography>
                        <Stack direction='row' spacing={2}>
                          <Autocomplete
                            options={[tableSelect, relatedTable]}
                            value={chainedTable}
                            onChange={(event, newValue) =>
                              handleChainedChange('table', index, chainedIndex, newValue)
                            }
                            sx={{ width: 250 }}
                            renderInput={params => (
                              <Tooltip
                                title={
                                  params.inputProps?.value?.length > 20
                                    ? params.inputProps?.value
                                    : ''
                                }>
                                <TextField {...params} label='Table' />
                              </Tooltip>
                            )}
                            renderOption={(props, option) => (
                              <Tooltip title={option?.length < 20 ? '' : option}>
                                <Typography {...props}>{option}</Typography>
                              </Tooltip>
                            )}
                          />
                          {chainedTable ? (
                            <Autocomplete
                              options={schemaData[chainedTable].columns}
                              value={chainedColumn}
                              onChange={(event, newValue) =>
                                handleChainedChange('column', index, chainedIndex, newValue)
                              }
                              sx={{ width: 250 }}
                              renderInput={params => (
                                <Tooltip
                                  title={
                                    params.inputProps?.value?.length > 20
                                      ? params.inputProps?.value
                                      : ''
                                  }>
                                  <TextField {...params} label='Column' />
                                </Tooltip>
                              )}
                              renderOption={(props, option) => (
                                <Tooltip title={option?.length < 20 ? '' : option}>
                                  <Typography {...props}>{option}</Typography>
                                </Tooltip>
                              )}
                            />
                          ) : null}

                          {chainedColumn ? (
                            <Autocomplete
                              options={chainedConditions}
                              value={chainedCondition}
                              onChange={(event, newValue) =>
                                handleChainedChange('condition', index, chainedIndex, newValue)
                              }
                              sx={{ minWidth: 250 }}
                              renderInput={params => (
                                <Tooltip
                                  title={
                                    params.inputProps?.value?.length > 20
                                      ? params.inputProps?.value
                                      : ''
                                  }>
                                  <TextField {...params} label='Condition' />
                                </Tooltip>
                              )}
                              renderOption={(props, option) => (
                                <Tooltip title={option?.length < 20 ? '' : option}>
                                  <Typography {...props}>{option}</Typography>
                                </Tooltip>
                              )}
                            />
                          ) : null}
                          {chainedCondition &&
                          (chainedCondition === 'only contain' ||
                            chainedCondition === 'does not contain') ? (
                            <Autocomplete
                              freeSolo
                              multiple
                              options={[]}
                              value={chainedValues ?? []}
                              onChange={(event, newValue) =>
                                handleChainedChange('values', index, chainedIndex, newValue)
                              }
                              sx={{ minWidth: 250 }}
                              clearOnEscape
                              clearOnBlur
                              renderInput={params => <TextField {...params} label='Values' />}
                              renderOption={(props, option) => (
                                <Tooltip title={option?.length < 20 ? '' : option}>
                                  <Typography {...props}>{option}</Typography>
                                </Tooltip>
                              )}
                            />
                          ) : null}
                          {chainedCondition &&
                          (chainedCondition === 'length' || chainedCondition === 'regex') ? (
                            <TextField
                              variant='outlined'
                              label='Value'
                              value={chainedValues || ''}
                              sx={{ minWidth: 250 }}
                              onChange={event => handleChange('values', index, event.target.value)}
                            />
                          ) : null}
                          {chainedCondition && chainedCondition === 'relation' ? (
                            <Autocomplete
                              options={Object.keys(schemaData).filter(x => x !== chainedTable)}
                              value={chainedRelatedTable}
                              onChange={(event, value) =>
                                handleChainedChange('relatedTable', index, chainedIndex, value)
                              }
                              sx={{ minWidth: 250 }}
                              renderInput={params => (
                                <Tooltip
                                  title={
                                    params.inputProps?.value?.length > 20
                                      ? params.inputProps?.value
                                      : ''
                                  }>
                                  <TextField {...params} label='Related Table' />
                                </Tooltip>
                              )}
                              renderOption={(props, option) => (
                                <Tooltip title={option.length < 20 ? '' : option}>
                                  <Typography {...props}>{option}</Typography>
                                </Tooltip>
                              )}
                            />
                          ) : null}
                          {chainedCondition &&
                          chainedCondition === 'relation' &&
                          chainedRelatedTable ? (
                            <Autocomplete
                              options={schemaData[chainedRelatedTable].columns}
                              value={chainedRelatedColumn}
                              onChange={(event, value) =>
                                handleChainedChange('relatedColumn', index, chainedIndex, value)
                              }
                              sx={{ minWidth: 250 }}
                              renderInput={params => (
                                <Tooltip
                                  title={
                                    params.inputProps?.value?.length > 20
                                      ? params.inputProps?.value
                                      : ''
                                  }>
                                  <TextField {...params} label='Related Column' />
                                </Tooltip>
                              )}
                              renderOption={(props, option) => (
                                <Tooltip title={option?.length < 20 ? '' : option}>
                                  <Typography {...props}>{option}</Typography>
                                </Tooltip>
                              )}
                            />
                          ) : null}
                          {chainedCondition &&
                          chainedCondition === 'relation' &&
                          chainedRelatedTable &&
                          chainedRelatedColumn ? (
                            <Autocomplete
                              options={chainedConditions}
                              value={chainedRelatedCondition}
                              onChange={(event, value) =>
                                handleChainedChange('condition', index, chainedIndex, value)
                              }
                              sx={{ minWidth: 250 }}
                              renderInput={params => (
                                <Tooltip
                                  title={
                                    params.inputProps?.value?.length > 20
                                      ? params.inputProps?.value
                                      : ''
                                  }>
                                  <TextField {...params} label='Condition' />
                                </Tooltip>
                              )}
                              renderOption={(props, option) => (
                                <Tooltip title={option?.length < 20 ? '' : option}>
                                  <Typography {...props}>{option}</Typography>
                                </Tooltip>
                              )}
                            />
                          ) : null}
                        </Stack>
                        <Box
                          className='rule-delete-chain'
                          sx={{ position: 'absolute', top: -15, right: -15 }}>
                          <IconButton onClick={() => deleteRule(index, chainedIndex)}>
                            <Delete color='error' />
                          </IconButton>
                        </Box>
                      </ListItem>
                    </Box>
                  )
                )
              : null}
          </Stack>
        </Stack>
        <Box className='rule-delete' sx={{ position: 'absolute', top: -15, right: -15 }}>
          <IconButton onClick={() => deleteRule(index, -1)}>
            <Delete color='error' />
          </IconButton>
        </Box>
        {suggested ? (
          <Tooltip title='Suggested rule'>
            <AutoAwesome color='primary' sx={{ position: 'absolute', bottom: 0, right: 0 }} />
          </Tooltip>
        ) : null}
      </ListItem>
    </Box>
  );

  return (
    <>
      <Dialog open={open} onClose={handleRuleClose} maxWidth={false}>
        <DialogTitle>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Stack direction='row' alignItems='center' spacing={2}>
              <Typography component='h2' variant='h4'>
                Rules
              </Typography>
              <IconButton onClick={handleRuleInfoOpen} size='large'>
                <HelpOutline />
              </IconButton>
            </Stack>
            <IconButton onClick={handleRuleClose} size='large'>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ minHeight: '400px', padding: '10px' }}>
          <Stack direction='row' spacing={3} sx={{ mt: '5px' }} justifyContent='space-between'>
            <Autocomplete
              options={Object.keys(schemaData)}
              value={tableSelect}
              disabled={tableName !== null && tableName !== ''}
              onChange={(event, value) => handleTableChange(value)}
              sx={{ minWidth: 250 }}
              renderInput={params => (
                <Tooltip
                  title={params.inputProps?.value?.length > 20 ? params.inputProps?.value : ''}>
                  <TextField {...params} label='Table' />
                </Tooltip>
              )}
              renderOption={(props, option) => (
                <Tooltip title={option?.length < 23 ? '' : option}>
                  <Typography {...props}>{option}</Typography>
                </Tooltip>
              )}
            />
            <Stack direction='row' spacing={2}>
              <Button
                disabled={!tableSelect}
                variant='outlined'
                sx={customNodeStyles.outlinedButton}
                onClick={addNewRule}
                startIcon={<Add />}>
                Add Rule
              </Button>
              <Button
                disabled={!tableSelect}
                variant='outlined'
                color='error'
                onClick={deleteAllRules}
                startIcon={<Delete />}>
                Delete All Rules
              </Button>
              <LoadingButton
                loading={suggestionsLoading}
                disabled={!tableSelect}
                onClick={getSuggestions}
                loadingPosition='start'
                startIcon={<AutoAwesome />}
                variant='outlined'
                color='primary'>
                <span>Generate Suggestions</span>
              </LoadingButton>
              <LoadingButton
                loading={viewTableLoading}
                disabled={!tableSelect}
                variant='outlined'
                sx={customNodeStyles.outlinedButton}
                onClick={fetchTableData}>
                View Table
              </LoadingButton>
            </Stack>
          </Stack>
          {tableRules && tableRules.length > 0 ? (
            <List>
              {tableRules.map(
                (
                  {
                    column,
                    condition,
                    values,
                    relatedTable,
                    relatedColumn,
                    relatedCondition,
                    suggested,
                    chainedRules
                  },
                  index
                ) =>
                  createRuleUI(
                    column,
                    condition,
                    values,
                    relatedTable,
                    relatedColumn,
                    relatedCondition,
                    suggested,
                    chainedRules,
                    index,
                    false
                  )
              )}
            </List>
          ) : (
            <Box display='flex' height={300} alignItems='center' justifyContent='center'>
              <Typography>No rules for this table</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Button color='error' onClick={handleRuleClose}>
            Cancel
          </Button>
          <LoadingButton
            loading={saveLoading}
            onClick={saveRules}
            disabled={!changesMade}
            color='success'>
            <span>Save</span>
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <Dialog open={ruleInfoOpen} onClose={handleRuleInfoClose}>
        <DialogTitle>
          <Stack direction='row' justifyContent='space-between' alignItems='center'>
            <Typography component='h2' variant='h4'>
              Rules List
            </Typography>
            <IconButton onClick={handleRuleInfoClose} size='large'>
              <Close />
            </IconButton>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: '20px' }}>
          <Stack direction='column' spacing={2}>
            <DialogContentText variant='body1' fontSize={20}>
              <span style={{ fontWeight: 'bold' }}> • Not duplicate:</span> Only Unique values can
              be present
            </DialogContentText>
            <DialogContentText variant='body1' fontSize={20}>
              <span style={{ fontWeight: 'bold' }}> • Only Contain:</span> Can only contain
              specified values (Eg: NDA, BLA, ANDA for Application Type)
            </DialogContentText>
            <DialogContentText variant='body1' fontSize={20}>
              <span style={{ fontWeight: 'bold' }}> • Does not Contain:</span> Does not contain
              specified values
            </DialogContentText>
            <DialogContentText variant='body1' fontSize={20}>
              <span style={{ fontWeight: 'bold' }}> • Relation:</span> (Foreign Key Relation) Checks
              if the specified column value exists in different table column. Needs to have Related
              table and Related Table column filled
            </DialogContentText>
            <DialogContentText variant='body1' fontSize={20}>
              <span style={{ fontWeight: 'bold' }}> • Length:</span> Value can only have a speficed
              length
            </DialogContentText>
            <DialogContentText variant='body1' fontSize={20}>
              <span style={{ fontWeight: 'bold' }}> • Date:</span> YYYY-MM-DD Validation
            </DialogContentText>
            <DialogContentText variant='body1' fontSize={20}>
              <span style={{ fontWeight: 'bold' }}> • Regex:</span> Specify a regex for the the
              column (Needs to be a valid regex)
            </DialogContentText>
            <DialogContentText variant='body1' fontSize={15}>
              * The scanner automatically checks for
              <span style={{ fontWeight: 'bold' }}> missing values</span> of all columns if the
              table is included
            </DialogContentText>
            <DialogContentText variant='body1' fontSize={15}>
              * Auto generated suggestions are only for reference and must be carefully examined
            </DialogContentText>
          </Stack>
        </DialogContent>
      </Dialog>
      <Dialog
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>Discard Changes?</DialogTitle>
        <DialogActions>
          <Button
            onClick={() => {
              setAlertOpen(false);
            }}>
            No
          </Button>
          <Button
            onClick={() => {
              setAlertOpen(false);
              handleClose();
            }}
            autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {tableDataOpen ? (
        <TableView
          isOpen={tableDataOpen}
          handleClose={handleTableDataClose}
          title={tableSelect}
          rows={rowData ?? []}
          columns={columnData}
        />
      ) : null}
    </>
  );
};
export default RuleView;
