import React from 'react';

// muiv5
import { GridFooterContainer, GridFooter } from '@mui/x-data-grid';

const DatagridFooter = () => {
  return (
    <GridFooterContainer>
      <GridFooter
        sx={{
          border: 'none',
          justifyContent: 'center',
          width: '100%'
        }}
      />
    </GridFooterContainer>
  );
};

export default DatagridFooter;
