import Status from '../components/Status';

const STATUS_ROUTE = [
  {
    id: 'adminStatus',
    path: '/status',
    component: Status,
    exact: true
  }
];
export default STATUS_ROUTE;
