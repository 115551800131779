import { useState, useEffect, useContext, memo, useMemo, ChangeEvent } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
// api
import { getDocCategorization } from '../../api/Cber'
// context
import Store from '../../store';
import Actions from '../../store/actions';

import {
  Typography,
  Button,
  Grid,
  Box,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import Styles from './styles/CategorizationTable.styles';
import themev5 from '../../themev5';
import { ThemeProvider } from '@mui/material/styles';
import utils from '../Datagrid/utils'
import DataEntryDataGrid from '../../helpers/dataGridCreator';
import Tooltip from '@mui/material/Tooltip';
import styles from '../Datagrid/Datagrid.styles';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@mui/material/CssBaseline';

const qc_status = [
  'All Documents',
  'Escalate',
  'Missing Details',
  'Non QCed',
  'QCed'
];
const DocumentCategorization = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const table_status = params.get('qc_status');
  const application_number = params.get('application_number');
  const application_title = params.get('application_title');
  const { dispatch } = useContext<any>(Store);
  const history = useHistory();
  const classes = Styles();
  const [labelKey, setLabelKey] = useState<Array<any>>([]);
  const [labelPageNo, setLabelPageNo] = useState(0);
  const [labelRowCount, setLabelRowCount] = useState();
  const [offset, setOffset] = useState(0);
  const [loading, setloading] = useState(true)
  const [status, setStatus] = useState(table_status || "All Documents")
  const [searchText, setSearchText] = useState(application_number || "")
  const [searchTitle, setSearchTitle] = useState(application_title || "")
  const rowsPerPageOptions = [10, 20, 50, 100]
  const [pageSize, setPageSize] = useState(10)

  useEffect(() => {
    const getDocCategory = async () => {
      try {
        let result = await getDocCategorization(labelPageNo, pageSize, status === "Non QCed" ? "to_qc" : status.toLowerCase(), searchText.trim().toLowerCase(), searchTitle.trim().toLowerCase());
        if (result === undefined) {
          throw new Error("Result is undefined")
        }
        if (result.data && result.data.body.results) {
          setloading(false)
          let resultData = result.data.body.results;
          setLabelRowCount(result.data.body.total_rows)
          setLabelKey([...resultData?.map((item: any, index: number) => ({ ...item, id: index }))]);
        }
        else {
          setloading(false)
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getDocCategory();
  }, [labelPageNo, status, searchText, searchTitle, pageSize])
  const handleRowData = async (application_number: any, application_docs_id: any) => {
    const baseURL = `/cber/document_categorization/${application_number}/${application_docs_id}?qc_status=${status}`;
    const queryParams = [];
    if (searchText) {
      queryParams.push(`application_number=${searchText.trim()}`)
    }
    if (searchTitle) {
      queryParams.push(`application_title=${searchTitle.trim()}`)
    }
    const finalURL = `${baseURL}&${queryParams.join('&')}`;
    history.push(finalURL)
  }
  const columns = [
    {
      field: 'Edit',
      width: 100,
      headerClassName: "MuiDataGrid-columnHeader",
      cellClassName: "ria-MuiDataGrid-cell",
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            className={classes.Button}
            onClick={() => {
              handleRowData(params.row.application_number, params.row.application_docs_id);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'application_number',
      headerName: 'Application Number',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'title',
      headerName: 'PDF Title',
      width: 500,
      renderCell: (params: any) => (
        <Grid item xs={8} lg={8}>
          <Tooltip title={params.row.title} >
            <Typography style={{ fontSize: '17px' }}>
              {params.row.title.length > 56 ? params.row.title.substring(0, 52) + "..." : params.row.title.substring(0, 47)}
            </Typography>
          </Tooltip>
        </Grid>
      )
    },
    {
      field: 'qc_status',
      headerName: 'QC Status',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Grid item xs={8} lg={8}>
          {params.row.qc_status === "qced" ? "QCed" : params.row.qc_status === "to_qc" ? "Non QCed" : utils(params.row.qc_status, 'formulations')}
        </Grid>
      )
    },
    {
      field: 'submission_number',
      headerName: 'Submission Number',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'submission_type',
      headerName: 'Submission Type',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'docs_category',
      headerName: 'Document Category',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Grid item xs={8} lg={8}>
          {utils(params.row.docs_category, 'formulations')}
        </Grid>
      )
    },
    {
      field: 'document_approval_date',
      headerName: 'Approval Date',
      width: 200,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'qc_date',
      headerName: 'QC Date',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Grid item xs={8} lg={8}>
          {params.row.qc_date?.substring(0, 17)}
        </Grid>
      )
    },
  ];
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        <Autocomplete
          id='qc_status'
          value={status}
          onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
            if (newValue !== null) {
              setStatus(newValue);
              setLabelPageNo(0);
            }
          }}
          style={{ fontSize: '2vh', minWidth: '250px', marginRight: '10px' }}
          options={qc_status}
          renderInput={(params: any) => <TextField {...params} label="QC Status" variant='outlined' />}
        />
        <TextField
          variant='outlined'
          type='search'
          id='search_application_number'
          label='Search Application Number'
          value={searchText}
          InputProps={{
            style: { fontSize: '2vh', minWidth: '250px', marginRight: '10px' }
          }}
          InputLabelProps={{
            style: { fontSize: '2vh' }
          }}
          onChange={e => {
            setSearchText(e.target.value);
            setLabelPageNo(0);
          }}
        />
        <TextField
          variant='outlined'
          type='search'
          id='search_title'
          label='Search Application Title'
          value={searchTitle}
          InputProps={{
            style: { fontSize: '2vh', minWidth: '250px' }
          }}
          InputLabelProps={{
            style: { fontSize: '2vh' }
          }}
          onChange={e => {
            setSearchTitle(e.target.value);
            setLabelPageNo(0);
          }}
        />
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={labelKey}
          columns={columns as any}
          rowCount={labelRowCount}
          pageSize={pageSize}
          getRowId={row => row.id}
          onPageSizeChange={pageSize => {
            setPageSize(pageSize);
            setLabelPageNo(0);
          }}
          onPageChange={setLabelPageNo}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={labelPageNo}
          paginationMode='server'
          components={{
            // Toolbar: DatagridToolbar,
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
      </Box>
    </ThemeProvider >
  );
};

export default memo(DocumentCategorization);