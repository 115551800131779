export const EUA_APPLICATION_FORM = [
  {
    id: 'approval_date',
    label: 'Approval Date',
    type: 'date_range',
    options: [],
    value: []
  },
  {
    id: 'vivpro_appl_no',
    label: 'Vivpro Appl No',
    type: 'text_field',
    options: [],
    value: []
  },
  {
    id: 'appl_no',
    label: 'Application Number',
    type: 'text_field',
    options: [],
    value: []
  },
  {
    id: 'appl_type',
    label: 'Application Type',
    type: 'text_field',
    options: [],
    value: []
  },
  {
    id: 'sponsor',
    label: 'Sponsor',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'active_ingredient',
    label: 'Active Ingredient',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'title',
    label: 'Brand Name',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'indication',
    label: 'Indications',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'formulation',
    label: 'Formulation',
    type: 'text_field',
    options: [],
    value: []
  },
  {
    id: 'route',
    label: 'Route',
    type: 'text_field',
    options: [],
    value: []
  },
  {
    id: 'last_updated_date',
    label: 'Last Updated Date',
    type: 'date_range',
    options: [],
    value: []
  },
  {
    id: 'approval_pathway',
    label: 'Approval Pathway',
    type: 'text_field',
    options: [],
    value: []
  },
  {
    id: 'attributes',
    label: 'Atrributes',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'authorized_setting',
    label: 'Authorized Setting',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'precode_assigned',
    label: 'Precode Assigned',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'center',
    label: 'Center',
    type: 'dropdown',
    options: [
      {
        label: 'CBER',
        value: 'CBER'
      },
      {
        label: 'CDRH',
        value: 'CDRH'
      },
      {
        label: 'CDER',
        value: 'CDER'
      }
    ],
    value: []
  },
  {
    id: 'module',
    label: 'Module',
    type: 'dropdown',
    options: [
      {
        label: 'US FDA Drugs and Biologics',
        value: 'US FDA Drugs and Biologics'
      },
      {
        label: 'US FDA Devices',
        value: 'US FDA Devices'
      }
    ],
    value: []
  },
  {
    id: 'is_deleted',
    label: 'Is deleted?',
    type: 'dropdown',
    options: [
      {
        label: 'True',
        value: true
      },
      {
        label: 'False',
        value: false
      }
    ],
    value: []
  }
];

export const SUBMISSION_CURATION_FORM = [
  {
    id: 'appl_no',
    label: 'Application Number',
    type: 'text_field',
    options: [],
    value: []
  },
  {
    id: 'title',
    label: 'Title',
    type: 'text_field',
    options: [],
    value: []
  },
  {
    id: 'filename',
    label: 'File Name',
    type: 'text_field',
    options: [],
    value: []
  },
  {
    id: 'approval_date',
    label: 'Approval Date',
    type: 'date_range',
    options: [],
    value: []
  },
  {
    id: 'link',
    label: 'link',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'attributes',
    label: 'Atrributes',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'authorized_setting',
    label: 'Authorized Setting',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'precode_assigned',
    label: 'Precode Assigned',
    type: 'text_area_field',
    options: [],
    value: []
  },
  {
    id: 'page_count',
    label: 'Page Count',
    type: 'text_field',
    options: [],
    value: []
  }
];
