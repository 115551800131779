import makeRequest from './client';

const baseURL = `/whats-new`;

export const getAllWhatsNew = () => {
  const finalURL = `${baseURL}/`;
  return makeRequest(finalURL, 'POST');
};

export const addNewWhatsNew = (payload: any) => {
  const finalURL = `${baseURL}/add`;
  return makeRequest(finalURL, 'POST', payload);
};

export const getWhatsNewById = (id: number) => {
  const finalURL = `${baseURL}/${id}`;
  return makeRequest(finalURL, 'GET');
};

export const updateWhatsNewContent = (payload: any) => {
  const finalURL = `${baseURL}/content`;
  return makeRequest(finalURL, 'PUT', payload);
};

export const updateWhatsNewStatus = (payload: any) => {
  const finalURL = `${baseURL}/status`;
  return makeRequest(finalURL, 'PUT', payload);
};
