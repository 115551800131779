import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
// material ui
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import EventNoteIcon from '@material-ui/icons/EventNote';
import AdminFormTextField from '../StyledComponents/TextField';

// api
import { getFdaData, submitNonPLRFdaData } from '../../api/AdminPage';

// helpers
import { handleChange } from '../../helpers/changeHandler';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// styles
import Styles from '../RLD/styles/RLD.styles';
import PNReviewFda from '../FDA/PNReviewFda';

const Accelerated = () => {
  const { applNo, subType, subNo } = useParams();
  const history = useHistory();
  const classes = Styles();
  const { state, dispatch } = useContext(Store);
  const [applType, setApplType] = useState();
  const [aaDate, setAaDate] = useState();
  const [indicationsAndUsage, setIndication] = useState();
  const [cwStatus, setCwStatus] = useState();
  const [cwDate, setCwDate] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [fullApprovalDate, setFullApprovalDate] = useState(null);
  const [isAdd, setIsAdd] = useState(false);
  useEffect(() => {
    async function fetchData() {
      let res;
      try {
        res = await getFdaData(applNo, subType, subNo, 'accelerated');
        if (res.data.Success.fda_data_qc.length > 0) {
          setIsAdd(false);
        } else {
          setIsAdd(true);
        }
      } catch (error) {
        history.push(`/approval`);
      }
    }

    fetchData();
  }, [dispatch, history]);

  const formatDate = date => {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : String(date.getDate());
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : String(date.getMonth() + 1);
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
  };
  const handleAcceleratedDateChange = date => {
    setFromDate(date);
    setAaDate(formatDate(date));
  };

  const handleFullApprovalDateChange = date => {
    setFullApprovalDate(date);
    setCwDate(formatDate(date));
  };

  const handleKeyFormSubmit = async e => {
    e.preventDefault();
    try {
      const key = `${applNo}-${subType}-${subNo}`;

      const payload = {
        appl_type: applType,
        appl_num: applNo,
        submission_type: subType,
        submission_num: subNo,
        aa_date: aaDate,
        aa_indication: indicationsAndUsage,
        indication_status: 'Qced',
        conversion_withdrawal_status: cwStatus,
        full_approval_conversion_withdrawal_date: cwDate,
        key,
        curator_email: state.userStub.email,
        curator_name: state.userStub['custom:user'],
        doctype: 'accelerated'
      };

      await submitNonPLRFdaData(payload);
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Accelerated table was successfully updated ',
          color: 'success'
        }
      });

      setApplType('');
      history.push('/approval');
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Something went wrong'
        }
      });
    }
  };
  if (isAdd) {
    return (
      <Box className={classes.root} display='flex' alignItems='center'>
        <Container maxWidth='lg' className={classes.container}>
          <Paper elevation={1} className={classes.banner}>
            <Typography className={classes.formHeadings}>
              Enter details for Accelerated data
            </Typography>
            <form onSubmit={handleKeyFormSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                  <Box margin={1} display='flex' flexDirection='column' alignItems='center'>
                    <Typography className={classes.label}>Application Type</Typography>
                    <AdminFormTextField
                      required
                      value={applType}
                      onChange={event => setApplType(handleChange(event))}
                      placeholder='Enter application type Eg: NDA'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                    <Typography className={classes.label}>Accelerated Date</Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        required
                        className={classes.AdvancedSearchDatePicker}
                        value={fromDate}
                        label='Select Date'
                        keyboardIcon={<EventNoteIcon className={classes.calendarIcon} />}
                        InputProps={{
                          classes: {
                            input: classes.resize
                          }
                        }}
                        format='MM/dd/yyyy'
                        onChange={date => handleAcceleratedDateChange(date)}
                      />
                    </MuiPickersUtilsProvider>
                    <Typography className={classes.label}>Accelerated Indication</Typography>
                    <AdminFormTextField
                      required
                      value={indicationsAndUsage}
                      onChange={event => setIndication(handleChange(event))}
                      placeholder='Enter Accelerated Indication'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                    <Typography className={classes.label}>Conversion Withdrawal Status</Typography>
                    <AdminFormTextField
                      required
                      value={cwStatus}
                      onChange={event => setCwStatus(handleChange(event))}
                      placeholder='Conversion Withdrawal Status'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                    <Typography className={classes.label}>
                      Full Approval Conversion Withdrawal Date
                    </Typography>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        className={classes.AdvancedSearchDatePicker}
                        value={fullApprovalDate}
                        label='Select Date'
                        keyboardIcon={<EventNoteIcon className={classes.calendarIcon} />}
                        InputProps={{
                          classes: {
                            input: classes.resize
                          }
                        }}
                        format='MM/dd/yyyy'
                        onChange={date => handleFullApprovalDateChange(date)}
                      />
                    </MuiPickersUtilsProvider>

                    <Button type='submit' variant='contained' className={classes.button}>
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </Box>
    );
  }
  return (
    <PNReviewFda
      applNo={applNo && applNo}
      subType={subType}
      subNo={subNo}
      docType={'accelerated'}
    />
  );
};

export default Accelerated;
