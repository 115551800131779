import { useState, useEffect, useContext, memo, ChangeEvent } from 'react';

import { Typography, Button, Grid, Box, TextField, Dialog } from '@material-ui/core';
import { Autocomplete } from '@mui/material';
import Stack from '@mui/material/Stack';
import { ThemeProvider } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import Styles from './styles/CategorizationTable.styles';
import themev5 from '../../themev5';
import utils from '../Datagrid/utils';

// api
import { getAllCberPMRLetters } from '../../api/Cber';
// context
import Store from '../../store';
import Actions from '../../store/actions';
import CberPmrReviewPage from './CberPmrReviewPage';

const inlineStyles = {
  datagrid: {
    border: 'none',
    borderRadius: '10px',
    fontSize: '16px',

    '& .table-header': {
      width: '100%',
      color: 'primary.main',
      fontWeight: 'bold',
      bgcolor: 'primary.backgroundDark'
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 'bold',
      fontSize: '16px'
    },
    '& 	.MuiDataGrid-row': {
      bgcolor: 'red'
    },
    '& .MuiDataGrid-cell:hover': {
      color: 'black.main'
    },
    '& .MuiTablePagination-displayedRows': {
      fontSize: '16px'
    },
    '& .MuiTablePagination-selectLabel': {
      fontSize: '16px'
    }
  }
};

const qc_status = ['All Documents', 'Escalate', 'Missing Details','No Cmt', 'Non QCed', 'QCed'];
const CberPmrTable = () => {
  const { dispatch } = useContext<any>(Store);
  const [pageSize, setPageSize] = useState(10);
  const rowsPerPage = [10, 20, 50, 100];
  const classes = Styles();
  const [labelKey, setLabelKey] = useState<Array<any>>([]);
  const [labelPageNo, setLabelPageNo] = useState(0);
  const [labelRowCount, setLabelRowCount] = useState();
  const [offset, setOffset] = useState(0);
  const [loading, setloading] = useState(true);
  const [status, setStatus] = useState('All Documents');
  const [searchText, setSearchText] = useState('');
  const [docsId, setDocsId] = useState<Number>();
  const [showDialog, setShowDialog] = useState(false);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    const getPMRLetters = async () => {
      try {
        let result;
        if (searchText) {
          result = await getAllCberPMRLetters(
            labelPageNo,
            pageSize,
            status === 'Non QCed' ? 'to_qc' : status.toLowerCase(),
            searchText.trim().toLowerCase()
          );
        } else {
          result = await getAllCberPMRLetters(
            labelPageNo,
            pageSize,
            status === 'Non QCed' ? 'to_qc' : status.toLowerCase()
          );
        }

        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.results) {
          setloading(false);
          const resultData = result.data.body.results;
          setLabelRowCount(result.data.body.total_rows);
          setLabelKey(resultData?.map((item: any, index: number) => ({ ...item, id: index })));
        } else {
          setloading(false);
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getPMRLetters();
  }, [labelPageNo, status, searchText, reload, pageSize]);
  const handleRowData = (applicationDocsId: Number) => {
    setDocsId(applicationDocsId);
    setShowDialog(true);
  };
  const closeDialog = () => {
    setDocsId(undefined);
    setShowDialog(false);
    setReload(prev => !prev);
  };
  const columns = [
    {
      field: 'Edit',
      width: 120,
      headerClassName: 'MuiDataGrid-columnHeader',
      cellClassName: 'ria-MuiDataGrid-cell',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            className={classes.Button}
            onClick={() => {
              handleRowData(params.row.application_docs_id);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'title',
      headerName: 'PDF Title',
      width: 500,
      renderCell: (params: any) => (
        <Grid item xs={8} lg={8}>
          <Tooltip title={params.row.title}>
            <Typography style={{ fontSize: '17px' }}>
              {params.row.title.length > 56
                ? `${params.row.title.substring(0, 52)}...`
                : params.row.title.substring(0, 47)}
            </Typography>
          </Tooltip>
        </Grid>
      )
    },
    {
      field: 'application_number',
      headerName: 'Application Number',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'qc_status',
      headerName: 'QC Status',
      width: 200,
      renderCell: (params: any) => (
        <Grid item xs={8} lg={8}>
          {params.row.qc_status === 'qced'
            ? 'QCed'
            : params.row.qc_status === 'to_qc'
            ? 'Non QCed'
            : utils(params.row.qc_status, 'formulations')}
        </Grid>
      )
    },
    {
      field: 'submission_number',
      headerName: 'Submission Number',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'submission_type',
      headerName: 'Submission Type',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'qc_date',
      headerName: 'QC Date',
      width: 200,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Grid item xs={8} lg={8}>
          {params.row.qc_date?.substring(0, 17)}
        </Grid>
      )
    }
  ];

  return (
    <ThemeProvider theme={themev5}>
      <Box
        sx={{
          height: '90vh',
          pl: '20px',
          mt: '80px',
          width: `100%`
        }}
        pt={1}>
        <Box className={classes.root} pr={2}>
          <Stack direction='column' alignItems='flex-start' spacing={2}>
            <Stack direction='row' spacing={2} pt={3} width='100%'>
              <Box display='flex' padding={1} alignItems='center' justifyContent='start'>
                <Autocomplete
                  id='qc_status'
                  value={status}
                  options={qc_status}
                  onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                    if (newValue !== null) {
                      setStatus(newValue);
                      setLabelPageNo(0);
                    }
                  }}
                  sx={{
                    width: '250px',
                    marginRight: '10px',
                    '& .MuiInputBase-input': {
                      fontSize: '1.5vh',
                      minWidth: '250px'
                    },
                    '& .MuiFormLabel-root': {
                      fontSize: '1.5vh'
                    }
                  }}
                  renderInput={params => (
                    <TextField {...params} label='QC Status' variant='outlined' />
                  )}
                />
                <TextField
                  variant='outlined'
                  type='search'
                  id='search_application'
                  label='Search Application Number'
                  value={searchText}
                  InputProps={{
                    style: { fontSize: '1.5vh', minWidth: '250px' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '1.5vh' }
                  }}
                  onChange={e => {
                    setSearchText(e.target.value);
                    setLabelPageNo(0);
                  }}
                />
              </Box>
            </Stack>
            <Box height='70vh' width='100%'>
              <DataGrid
                rows={labelKey}
                columns={columns as any}
                pageSize={pageSize}
                onPageSizeChange={newPageSize => {
                  setPageSize(newPageSize);
                  setLabelPageNo(0);
                }}
                rowsPerPageOptions={rowsPerPage}
                rowCount={labelRowCount}
                onPageChange={newPage => {
                  setLabelPageNo(newPage);
                  setOffset(newPage * pageSize);
                }}
                page={labelPageNo}
                loading={loading}
                pagination
                paginationMode='server'
                sx={inlineStyles.datagrid}
              />
            </Box>
          </Stack>
        </Box>
      </Box>
      <Dialog open={showDialog} onClose={closeDialog} maxWidth='lg' fullWidth>
        <CberPmrReviewPage docsId={docsId} closeEdit={closeDialog} />
      </Dialog>
    </ThemeProvider>
  );
};

export default memo(CberPmrTable);
