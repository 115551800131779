import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography
} from '@mui/material';
// import Textarea from '@mui/joy/Textarea';

const ErrorForm = () => {
  const [open, setOpen] = useState(false);
  const [formData, setFormData] = useState({
    error_data_type: '',
    error_message: '',
    error_details: {}
  });
  const [errorList, setErrorList] = useState<Array<any>>([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDetailsChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      error_details: {
        ...formData.error_details,
        [name]: value
      }
    });
  };

  const handleSubmit = () => {
    setErrorList([...errorList, formData]);
    setFormData({
      error_data_type: '',
      error_message: '',
      error_details: {}
    });
    handleClose();
  };

  return (
    <Stack
      direction='row'
      justifyContent={errorList?.length > 0 ? 'space-evenly' : 'flex-end'}
      alignItems='center'>
      {/* Display the list of error reports */}
      <Stack
        direction='row'
        spacing={2}
        alignItems='center'
        sx={{ maxWidth: '70%', overflowX: 'auto' }}>
        {errorList.map((err: any) => {
          return (
            <Stack spacing={0.5} key={err.error_message}>
              <Typography noWrap>Error Data Type: {err.error_data_type}</Typography>
              <Typography noWrap>Error Message: {err.error_message}</Typography>
            </Stack>
          );
        })}
      </Stack>

      <Button
        onClick={handleOpen}
        variant='contained'
        color='error'
        size='small'
        sx={{ textTransform: 'capitalize' }}>
        Report Error
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle sx={{ bgcolor: 'red.main' }}>Error Form</DialogTitle>
        <DialogContent>
          <Stack spacing={2} p={2} sx={{ width: 700, height: 800 }}>
            <FormControl>
              <InputLabel id='error-data-type-label'>Error Data Type</InputLabel>
              <Select
                labelId='error-data-type-label'
                id='error-data-type'
                required
                name='error_data_type'
                sx={{ width: '40%' }}
                variant='outlined'
                value={formData.error_data_type}
                onChange={handleChange}>
                <MenuItem value='Documents'>Documents</MenuItem>
                <MenuItem value='Images'>Products</MenuItem>
                <MenuItem value='Videos'>Application Details</MenuItem>
              </Select>
            </FormControl>
            <TextField
              margin='normal'
              id='error-message'
              required
              name='error_message'
              label='Error Message'
              placeholder='Enter error message'
              sx={{ width: '80%' }}
              minRows={6}
              multiline
              value={formData.error_message}
              onChange={handleChange}
            />
            <FormControl>
              <InputLabel id='error-data-type-label'>Review Status</InputLabel>
              <Select
                labelId='error-data-type-label'
                id='error-data-type'
                name='error_data_type'
                sx={{ width: '40%' }}
                variant='outlined'
                required
                value={formData.error_data_type}
                onChange={handleChange}>
                <MenuItem value='Documents'>Success</MenuItem>
                <MenuItem value='Images'>Missing Data</MenuItem>
              </Select>
            </FormControl>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='secondary'>
            Cancel
          </Button>
          <Button onClick={handleSubmit} color='primary'>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default ErrorForm;
