import palette from '../../../themev5/palette';

const styles = {
  tableHead: {
    color: palette.primary.main,
    fontWeight: 'bold',
    backgroundColor: palette.primary.backgroundDark
  },
  tableRowHighLight: {
    color: palette.primary.main,
    fontWeight: 'bold',
    backgroundColor: palette.primary.backgroundLight
  }
};

export default styles;
