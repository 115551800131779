import theme from '../../../theme';

const styles = {
  root: {
    alignItems: 'center',
    width: '100%',
    position: 'relative',
    padding: '30px',
    overflowY: 'hidden'
  },
  typography: {
    padding: 8,
    fontSize: '1.8vh'
  },

  Heading: {
    color: '#3AB09E',
    fontSize: '2.5vh',
    textAlign: 'left',
    fontWeight: '600'
  },

  Button: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    height: '80vh',
    overflow: 'auto',
    paddingTop: theme.spacing(2)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white
  },
  dialog: {
    background: theme.palette.white
  },
  pdfContainer: {
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      minHeight: '80vh'
    }
  }
};

export default styles;
