/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useContext, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Handle, Position } from 'reactflow';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
  Container,
  Tooltip
} from '@mui/material';
import { Close, ExpandMore, Key, Edit } from '@mui/icons-material';
import styles from './styles/CustomNode.styles';
import Store from '../../store';
import actions from '../../store/actions';
import TableView from './TableView';

const CustomNode = ({ data, isConnectable }) => {
  const { dispatch } = useContext(Store);
  const [open, setOpen] = useState(false);
  const [openRows, setOpenRows] = useState(false);
  const [column, setColumn] = useState({});
  const [rows, setRows] = useState([]);
  const { isPending, columnList = [] } = data;

  const copyTextToClipboard = textToCopy => {
    try {
      navigator.clipboard.writeText(textToCopy);
    } catch (error) {
      // navigator.clipboard.writeText() doesn't work on iOS,
      // so fall back to using the browser "copy" command.
      const textArea = document.createElement('textArea');
      textArea.value = textToCopy;

      document.body.appendChild(textArea);

      const range = document.createRange();
      range.selectNodeContents(textArea);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      textArea.setSelectionRange(0, 999999);

      document.execCommand('copy');

      document.body.removeChild(textArea);
    }
  };

  const copySql = async sql => {
    copyTextToClipboard(sql);
    await dispatch({
      type: actions.SET_ALERT,
      value: { status: true, message: 'SQL copied to clipboard', color: 'success' }
    });
  };

  const handleClickOpen = (columnName, columnData) => {
    setColumn({ columnName, columnData });
    setOpen(true);
  };

  const handleClickRowsOpen = rowData => {
    setRows(rowData);
    setOpenRows(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleRowsClose = () => {
    setOpenRows(false);
  };

  const detailComponent = (detailsList, rowCount, didFail) => {
    return (
      <Box minWidth='500px'>
        <Typography variant='h5' sx={didFail ? styles.failText : styles.passText}>
          {didFail ? 'Failed' : 'Passed'}
        </Typography>
        <List>
          {detailsList.map(detail => (
            <Box sx={styles.ruleBorder}>
              <ListItem disablePadding key={`${detail.rule}-${detail.name}-rule`}>
                <Stack direction='column' spacing={1}>
                  <Stack
                    direction='row'
                    spacing={1}
                    alignItems='center'
                    justifyContent='space-between'>
                    <Typography sx={styles.ruleCheck}>{`${detail.rule.toUpperCase()} ${
                      detail.values ? `: ${detail.values}` : ''
                    }`}</Typography>
                    {didFail ? (
                      <Typography sx={styles.failCount}>{`${detail.count} ${'failed'} (${(
                        (detail.count / rowCount) *
                        100
                      ).toFixed(2)}%) `}</Typography>
                    ) : null}
                  </Stack>
                  <ListItemText primary={detail.name} />
                  {detail.query ? (
                    <Accordion
                      elevation={0}
                      sx={{
                        '&:before': {
                          display: 'none'
                        }
                      }}>
                      <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls='panel1a-content'
                        id='panel1a-header'>
                        Query
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box sx={styles.codeHightlight}>
                          <p>{detail.query}</p>
                          <Button
                            className='code-button'
                            sx={styles.codeButton}
                            variant='outlined'
                            onClick={() => copySql(detail.query)}>
                            Copy
                          </Button>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ) : null}
                  {detail.rows && detail.rows.length > 0 ? (
                    <Button
                      variant='outlined'
                      sx={styles.outlinedButton}
                      onClick={() => handleClickRowsOpen(detail.rows)}>
                      View Sample rows
                    </Button>
                  ) : null}
                </Stack>
              </ListItem>
            </Box>
          ))}
        </List>
      </Box>
    );
  };

  return (
    <Box sx={styles.customNode} key={`${data.table}-${data.table}-customnode`}>
      <Stack direction='column'>
        <Container
          sx={
            isPending
              ? styles.tablePending
              : data.failCount === 0
              ? styles.tableSuccess
              : styles.tableFailure
          }
        />
        <Stack
          direction='row'
          spacing={1}
          sx={styles.tableName}
          alignItems='center'
          justifyContent='space-between'>
          <Stack direction='row' spacing={2} alignItems='baseline' justifyContent='start'>
            <Typography fontWeight='bold' variant='h5'>
              {data.table}
            </Typography>
            {data.isPending ? (
              <Typography variant='h6'>Pending</Typography>
            ) : data.failCount === 0 ? (
              <Typography sx={styles.passText} variant='h6'>
                {`${data.passCount}/${data.totalCount} Passed`}
              </Typography>
            ) : (
              <Typography sx={styles.failText} variant='h6'>
                {`${data.failCount}/${data.totalCount} Failed`}
              </Typography>
            )}
          </Stack>
          <Tooltip title='Edit rule'>
            <IconButton onClick={() => data.ruleEditCallBack(data.table)}>
              <Edit />
            </IconButton>
          </Tooltip>
        </Stack>
        <List disablePadding>
          {Object.entries(data.columns).map(([columnName, columnData], index) => (
            <>
              {columnData.source ? (
                <Handle
                  type='source'
                  id={columnName}
                  position={Position.Right}
                  style={{ top: index * 65 + 32 }}
                  isConnectable={isConnectable}
                />
              ) : null}
              <ListItem disablePadding sx={{ padding: 0 }} key={`${data.table}${columnName}`}>
                <ListItemButton
                  sx={styles.lib}
                  disabled={isPending}
                  onClick={() => handleClickOpen(columnName, columnData)}>
                  <Stack
                    direction='row'
                    spacing={1}
                    padding={1}
                    alignItems='center'
                    justifyContent='space-between'>
                    <ListItemText primary={columnName} />
                    {isPending ? null : columnData.failCount === 0 ? (
                      <ListItemText
                        sx={styles.passText}
                        primary={`${columnData.passCount}/${columnData.totalCount} Passed`}
                      />
                    ) : (
                      <ListItemText
                        sx={styles.failText}
                        primary={`${columnData.failCount}/${columnData.totalCount} Failed`}
                      />
                    )}
                    {columnData.source || columnData.target ? <Key /> : null}
                  </Stack>
                </ListItemButton>
              </ListItem>
              {index < columnList.length - 1 ? <Divider /> : null}
              {columnData.target ? (
                <Handle
                  type='target'
                  id={columnName}
                  position={Position.Left}
                  style={{ top: index * 65 + 32 }}
                  isConnectable={isConnectable}
                />
              ) : null}
            </>
          ))}
        </List>
      </Stack>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography component='h2' variant='h5'>
              {`Column: ${column.columnName}`}
            </Typography>
            <IconButton onClick={handleClose} size='large'>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography>{`Total rows: ${data.rowCount}`}</Typography>
          {column?.columnData?.failCount > 0
            ? detailComponent(column.columnData.fail, data.rowCount, true)
            : null}
          {column?.columnData?.passCount > 0
            ? detailComponent(column.columnData.pass, data.rowCount, false)
            : null}
        </DialogContent>
      </Dialog>
      {openRows ? (
        <TableView
          isOpen={openRows}
          handleClose={handleRowsClose}
          title='Sample Records'
          rows={rows}
          columns={columnList}
        />
      ) : null}
    </Box>
  );
};
export default CustomNode;
