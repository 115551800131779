import * as React from 'react';
import { useCallback } from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Button from '@mui/material/Button';
import {
    makeStyles
} from "@material-ui/core"
import { FormControl } from '@mui/material'
import { SELECT_TYPE_MAPPINGS } from './conts'

interface SelectFieldProps {
    values: string[];
    selectType: string;
    index: number;
    handleStatsRecordChange: (fieldName: string, value: string) => void
    handleNext: () => void
    handleBack: () => void
    statsRecordState: any
}


const SelectField: React.FC<SelectFieldProps> = ({
    values,
    selectType,
    handleStatsRecordChange,
    index,
    handleBack,
    handleNext,
    statsRecordState
}) => {

    const useStyles = makeStyles(() => ({
        root: {
            "&  .MuiOutlinedInput-notchedOutline": { color: '#3ab09e' },
        }
    }));

    const c = useStyles();

    const type_ = SELECT_TYPE_MAPPINGS[selectType]

    const [selectValue, setSelectValue] = React.useState(statsRecordState[type_] ? statsRecordState[type_] : '');

    const handleChange = useCallback((event: SelectChangeEvent) => {
        setSelectValue(event.target.value as string);
    }, []);

    const handleContinue = () => {
        handleStatsRecordChange(SELECT_TYPE_MAPPINGS[selectType], selectValue)
        handleNext()
    }

    return (
        <Box sx={{ minWidth: 120, color: 'primary' }}>
            <FormControl >
                <InputLabel id="demo-simple-select-label">{selectType}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectValue}
                    label={selectType}
                    onChange={handleChange}
                    sx={{ width: 150 }}
                    className={c.root}
                >
                    {values.map((value) => (
                        <MenuItem key={value} value={value}>{value}</MenuItem>
                    ))}

                </Select>
                <Box sx={{ mb: 2 }}>
                    <div>
                        <Button
                            variant="contained"
                            onClick={handleContinue}
                            sx={{ mt: 1, mr: 1, color: 'primary' }}
                            disabled={selectValue ? false : true}
                        >
                            {'Continue'}
                        </Button>
                        <Button
                            disabled={index === 0}
                            onClick={handleBack}
                            sx={{ mt: 1, mr: 1 }}
                        >
                            Back
                        </Button>
                    </div>
                </Box>
            </FormControl>
        </Box>
    );
}

export default SelectField;