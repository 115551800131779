import DataQuality from '../components/DataQuality/DataQuality';

const DATA_QUALITY_ROUTES = [
  {
    id: 'data_quality',
    path: '/data_quality',
    component: DataQuality,
    exact: true
  }
];
export default DATA_QUALITY_ROUTES;
