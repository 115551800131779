import React, { useContext, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import DataEntryDataGrid from '../../helpers/dataGridCreator';

// api
import { getNonQcedLabels } from '../../api/Adcomm';

// material ui
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// styles
import Styles from '../DataEntry/styles/DataEntry.styles';

const Label = () => {
  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const docType = 'Label';
  const [key, setKey] = useState();
  const pageSize = 10;
  const rowsPerPage = 10;
  const [labelKey, setLabelKey] = useState([]); 
  const [labelPageNo, setLabelPageNo] = useState(0);
  const [labelRowCount, setLabelRowCount] = useState(0);
  const [offset, setOffset] = useState(0);
  useEffect(() => {
    const getNonQcedLabelData= async () => {
      try{
        const result = await getNonQcedLabels(labelPageNo, pageSize);
        if (result === undefined) {
          throw new Error("Result is undefined");
        }
        else {
          setLabelRowCount(result.data.Success.total_rows);
          const resultData = result.data.Success.data;
          setLabelKey([...resultData?.map((item,index)=>({...item,id:index}))]);
        }
      } catch(e) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getNonQcedLabelData();
  }, [labelPageNo]);

  const labelColumns = [
    {
      field: 'key',
      headerName: 'Label Keys',
      width: 1100,
      align: 'left',
      headerClassName: 'ria-MuiDataGrid-header',
      cellClassName: 'ria-MuiDataGrid-cell', 
      renderCell: params => (
        <Grid item xs={8} lg={8}>
          <Button
            id={params.row.key}
            value={params.row.key}
            className={classes.button}
            onClick={() => {
              handleSubmit(params.row.key);
            }}
            >
            {params.row.key}
          </Button>
        </Grid>
      )
    }
  ];


  const handleKeyChange = event => {
    const text = event.target.value;
    setKey(text);
  };

  const handleSubmit = (key) => {
    const validate_input = key.split('-');
    const applNo = validate_input[0];
    const subType = validate_input[1].toUpperCase();
    const subNo = validate_input[2];
    history.push(`/fda/label/${applNo}/${subType}/${subNo}/${docType}`);
  };

  const handleKeyFormSubmit = async event => {
    event.preventDefault();
    const validate_input = key.split('-');
    if (validate_input.length !== 3) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Enter a valid hiphenated input. Eg: 004782-SUPPL-171'
        }
      });
    } else {
      const appl_no = key.split('-')[0];
      const sub_type = key.split('-')[1].toUpperCase();
      const sub_no = key.split('-')[2];
      history.push(`/fda/label/${appl_no}/${sub_type}/${sub_no}/${docType}`);
    }
  };

  return (
    <Box className={classes.root} display='flex' alignItems='center'>
      <Container maxWidth='lg' className={classes.container}>
        <Paper elevation={1} className={classes.banner}>
          <Typography className={classes.formHeadings}>
            Enter the details to review the document
          </Typography>
          <form onSubmit={handleKeyFormSubmit}>
            <Grid container spacing={1}>
              <Grid item xs={12} lg={6}>
                <Box margin={1} display='flex' flexDirection='column'>
                  <Typography align='left' className={classes.label}>
                    Application Number
                  </Typography>
                  <TextField
                    required
                    className={classes.textfield}
                    onChange={handleKeyChange}
                    fullWidth
                    id='standard-basic'
                    placeholder='Enter a key. Eg: 004782-ORIG-1'
                    InputProps={{
                      classes: { input: classes.input },
                      disableUnderline: true
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={3} />
              <Grid item xs={12} lg={3}>
                <Box margin={1} display='flex' alignItems='center' className={classes.btn}>
                  <Button type='submit' variant='contained' className={classes.button}>
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='space-evenly'
            alignItems='center'
            className={classes.dividerRoot}>
            <Divider className={classes.divider} />
            <Typography className={classes.or}>OR</Typography>
            <Divider className={classes.divider} />
          </Box>
          <Box height='69vh' display='flex' alignItems='center' justifyContent='center'>
            <DataEntryDataGrid
              rows={labelKey}
              columns={labelColumns}
              pageSize={pageSize}
              rowsPerPage={rowsPerPage}
              rowCount={labelRowCount}
              setPage={setLabelPageNo}
              setOffset={setOffset}
              page={labelPageNo}
            />
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default Label;
