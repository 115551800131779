import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Amplify } from 'aws-amplify';
import * as serviceWorker from './serviceWorker';
import App from './App';

Amplify.configure({
  Auth: {
    manatorySignId: true,
    region: process.env.REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT
  }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
