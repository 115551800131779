import GuidanceListWrapper from '../components/GuidanceCuration/GuidanceList';

const GUIDANCE_ROUTES = [
  {
    id: 'guidanceList',
    path: '/guidance',
    component: GuidanceListWrapper,
    exact: true
  }
];

export default GUIDANCE_ROUTES;
