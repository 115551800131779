import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { Box, Button, IconButton, Link, Paper, Typography } from '@material-ui/core';
import Store from '../../../store';
import Actions from '../../../store/actions';

import LoginTextfield from '../../../components/StyledComponents/TextFieldLogin';

import Styles from '../styles/Login.styles';

import VivproLogo from '../../../assets/svgs/Login/loginLogo';
import User from '../../../assets/svgs/Login/user';
import Mail from '../../../assets/svgs/Login/password';
import Code from '../../../assets/svgs/Login/code';

const ForgotPasswordPage = () => {
  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const [username, setUsername] = useState();
  const [code, setCode] = useState();
  const [newPassword, setNewPassword] = useState();
  const [gotCode, setGotCode] = useState(false);

  const handleSubmit = async event => {
    event.preventDefault();
    try {
      await Auth.forgotPasswordSubmit(username, code, newPassword);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Password Reset Successfully', color: 'success' }
      });
      history.push('/');
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `${error.message}` }
      });
    }
  };

  const gotoCodePage = async event => {
    event.preventDefault();
    setGotCode(false);
  };

  const handleGetCode = async event => {
    event.preventDefault();
    try {
      await Auth.forgotPassword(username);
      setGotCode(true);
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `Error getting security Code - ${error.message}` }
      });
      console.error(error);
    }
  };

  if (gotCode) {
    return (
      <Box
        className={classes.outline}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'>
        <Box
          className={classes.header}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'>
          <VivproLogo />
          <Typography className={classes.title} variant='h5'>
            Reset Password
          </Typography>
          <Typography className={classes.description}>
            Enter your Vivpro Email and Password to continue
          </Typography>
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            className={classes.form}
            display='flex'
            flexDirection='column'
            alignItems='center'
            justifyContent='center'>
            <Paper component='form' className={classes.paper} elevation={0}>
              <IconButton className={classes.textfieldIcon} disabled>
                <User />
              </IconButton>
              <LoginTextfield
                InputProps={{ disableUnderline: true }}
                placeholder='Email Address'
                id='email'
                name='email'
                disabled
                value={username}
              />
            </Paper>
            <Paper component='form' className={classes.paper} elevation={0}>
              <IconButton className={classes.textfieldIcon} disabled>
                <Code />
              </IconButton>
              <LoginTextfield
                InputProps={{ disableUnderline: true }}
                id='code'
                placeholder='Security Code'
                name='code'
                value={code}
                onChange={event => setCode(event.target.value)}
              />
            </Paper>
            <Paper component='form' className={classes.paper} elevation={0}>
              <IconButton className={classes.textfieldIcon} disabled>
                <Mail />
              </IconButton>
              <LoginTextfield
                InputProps={{ disableUnderline: true }}
                name='password'
                placeholder='Password'
                type='password'
                id='password'
                value={newPassword}
                onChange={event => setNewPassword(event.target.value)}
              />
            </Paper>
            <Button className={classes.button} type='submit' variant='contained'>
              Reset Password
            </Button>
            <Button
              className={classes.button}
              variant='contained'
              onClick={event => gotoCodePage(event)}>
              Resend Code
            </Button>
          </Box>
        </form>
        <Box
          className={classes.footer}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'>
          <Link href='/' className={classes.link}>
            Go back to Login
          </Link>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      className={classes.outline}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='center'>
      <Box
        className={classes.header}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'>
        <VivproLogo />
        <Typography className={classes.title} variant='h5'>
          Get Security Code
        </Typography>
        <Typography className={classes.description}>
          Enter your Vivpro Email and Password to continue
        </Typography>
      </Box>
      <form onSubmit={handleGetCode}>
        <Box
          className={classes.form}
          display='flex'
          flexDirection='column'
          alignItems='center'
          justifyContent='center'>
          <Paper component='form' className={classes.paper} elevation={0}>
            <IconButton className={classes.textfieldIcon} disabled>
              <User />
            </IconButton>
            <LoginTextfield
              InputProps={{ disableUnderline: true }}
              placeholder='Email Address'
              id='email'
              name='email'
              value={username}
              onChange={event => setUsername(event.target.value)}
            />
          </Paper>
          <Button className={classes.button} type='submit' variant='contained'>
            Get Code
          </Button>
        </Box>
      </form>
      <Box
        className={classes.footer}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'>
        <Link href='/' className={classes.link}>
          Go back to Login
        </Link>
      </Box>
    </Box>
  );
};

export default ForgotPasswordPage;
