export const qcStatus = ['All', 'Escalate', 'Qced', 'Non Qced'];

export const qcStatusOption = ['All', 'New', 'Escalate', 'Qced', 'Non Qced'];
export const CENTER_OPTIONS = ['All', 'CDER', 'CDRH', 'CBER'];
export const CENTER_OPTIONS_APPLICATON_DASHBOARD = ['CDER', 'CDRH', 'CBER'];

export const rowsPerPageOptions = [10, 20, 50, 100];

export const MEETING_FORM_MAPPING = [
  {
    id: 'acronym',
    label: 'Acronym',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'meeting_id',
    label: 'Meeting ID',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'meeting_title',
    label: 'Meeting Title',
    type: 'text_area_field',
    options: [],
    value: ''
  },
  {
    id: 'adcom_meeting_start_date',
    label: 'Start Date',
    type: 'date_range',
    options: [],
    value: null
  },
  {
    id: 'adcom_meeting_end_date',
    label: 'End Date',
    type: 'date_range',
    options: [],
    value: null
  },
  {
    id: 'adcom_meeting_time',
    label: 'Start Time',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'location',
    label: 'Location',
    type: 'text_area_field',
    options: [],
    value: ''
  },
  {
    id: 'agenda',
    label: 'Agenda',
    type: 'text_area_field',
    options: [],
    value: ''
  },
  {
    id: 'material_exists',
    label: 'Material exists?',
    type: 'dropdown',
    options: [
      {
        label: 'True',
        value: true
      },
      {
        label: 'False',
        value: false
      }
    ],
    value: false
  },
  {
    id: 'minutes_exists',
    label: 'Minutes exists?',
    type: 'dropdown',
    options: [
      {
        label: 'True',
        value: true
      },
      {
        label: 'False',
        value: false
      }
    ],
    value: false
  },
  {
    id: 'roster_exists',
    label: 'Roster exists?',
    type: 'dropdown',
    options: [
      {
        label: 'True',
        value: true
      },
      {
        label: 'False',
        value: false
      }
    ],
    value: false
  },
  {
    id: 'meeting_status',
    label: 'Meeting Status',
    type: 'dropdown',
    options: [
      {
        label: 'Active',
        value: 'active'
      },
      {
        label: 'Cancelled',
        value: 'cancelled'
      },
      {
        label: 'Postponed',
        value: 'postponed'
      },
      {
        label: 'Closed meeting',
        value: 'closed meeting'
      },
      {
        label: 'Delete',
        value: 'delete'
      }
    ],
    value: 'active'
  },
  {
    id: 'qc_status',
    label: 'Qc Status',
    type: 'dropdown',
    options: [
      {
        label: 'Not Qced',
        value: 'to_qc'
      },
      {
        label: 'Qced',
        value: 'qced'
      },
      {
        label: 'Escalate',
        value: 'escalate'
      },
      {
        label: 'Delete',
        value: 'delete'
      }
    ],
    value: 'to_qc'
  }
];

export const MINUTES_FORM_MAPPING = [
  {
    id: 'question_num',
    label: 'Question Number',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'question',
    label: 'Question',
    type: 'text_area_field',
    options: [],
    value: ''
  },
  {
    id: 'summary',
    label: 'Summary',
    type: 'text_area_field',
    options: [],
    value: ''
  },
  {
    id: 'vote_yes',
    label: 'Vote Yes',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'vote_no',
    label: 'Vote No',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'vote_abstain',
    label: 'Vote Abstain',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'pre_acm',
    label: 'Pre Acm',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'post_acm',
    label: 'Post Acm',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'type',
    label: 'Type',
    type: 'dropdown',
    options: [
      {
        label: 'DISCUSSION',
        value: 'DISCUSSION'
      },
      {
        label: 'VOTE',
        value: 'VOTE'
      },
      {
        label: 'Option not available',
        value: 'Option not available'
      }
    ],
    value: ''
  },
  {
    id: 'material_category',
    label: 'Material Type',
    type: 'dropdown',
    freeText: true,
    options: [
      {
        label: 'Minutes Final',
        value: 'final_minutes'
      },
      {
        label: 'Minutes Draft',
        value: 'draft_minutes'
      },
      {
        label: 'Guest',
        value: 'guest'
      },
      {
        label: 'Participant',
        value: 'participant'
      },
      {
        label: 'Committee',
        value: 'committee'
      },
      {
        label: 'Draft Questions',
        value: 'draft_questions'
      },
      {
        label: 'Final Questions',
        value: 'final_questions'
      }
    ],
    value: [],
    validate: true
  }
];

export const ROSTER_FORM_MAPPING = [
  {
    id: 'role',
    label: 'Role',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'voting',
    label: 'Voting',
    type: 'radio_type',
    options: [
      {
        label: 'Yes',
        value: 'Yes'
      },
      {
        label: 'No',
        value: 'No'
      },
      {
        label: 'NA',
        value: 'NA'
      }
    ],
    value: ''
  },
  {
    id: 'member',
    label: 'Member',
    type: 'dropdown',
    freeText: true,
    options: [],
    value: ''
  },
  {
    id: 'degree',
    label: 'Degree',
    type: 'multiple_dropdown',
    options: [],
    value: []
  },
  {
    id: 'affiliation',
    label: 'Affiliation',
    type: 'text_area_field',
    options: [],
    value: ''
  },
  {
    id: 'additional_details',
    label: 'Additional Details',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'material_category',
    label: 'Material Type',
    type: 'dropdown',
    freeText: true,
    options: [
      {
        label: 'Roster Final',
        value: 'roster_final'
      },
      {
        label: 'Roster Draft',
        value: 'roster_draft'
      },
      {
        label: 'Guest',
        value: 'guest'
      },
      {
        label: 'Participant',
        value: 'participant'
      },
      {
        label: 'Committee',
        value: 'committee'
      }
    ],
    value: [],
    validate: true
  }
];

export const MATERIAL_FORM_MAPPING = [
  {
    id: 'document_title',
    label: 'Document Title',
    type: 'text_area_field',
    options: [],
    value: ''
  },
  {
    id: 'original_file_name',
    label: 'File Name',
    type: 'text_area_field',
    options: [],
    value: ''
  },
  {
    id: 'original_file_url',
    label: 'File Url',
    type: 'text_area_field',
    options: [],
    value: ''
  },
  {
    id: '_category',
    label: 'Category',
    type: 'dropdown',
    options: [
      'Other',
      'Backgrounder',
      'Minutes',
      'Transcript',
      'Roster',
      'Slides',
      'Questions',
      'Agenda'
    ],
    value: []
  },
  {
    id: 'status',
    label: 'Status',
    type: 'dropdown',
    options: [
      {
        label: 'Pending',
        value: 'Pending'
      },
      {
        label: 'Qced',
        value: 'Qced'
      }
    ],
    value: 'Pending'
  }
];

export const APPLICATION_FORM_MAPPING = [
  {
    id: 'meeting_id',
    label: 'Meeting Id',
    type: 'text_field',
    options: [],
    value: '',
    disable: true,
    showInApplicationView: false
  },
  {
    id: 'application_number',
    label: 'Application Number',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: true
  },
  {
    id: 'application_type',
    label: 'Application Type',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: false
  },
  {
    id: 'sponsor',
    label: 'Sponsor',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: true
  },
  {
    id: 'trade_name',
    label: 'Drug Name',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: true
  },
  {
    id: 'active_ingredient',
    label: 'Active Ingredient',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: false
  },
  {
    id: 'formulation',
    label: 'Formulation',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: false
  },
  {
    id: 'route',
    label: 'Route',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: false
  },
  {
    id: 'approval_date',
    label: 'Approval Date',
    type: 'date_range',
    options: [],
    value: null,
    showInApplicationView: false
  },
  {
    id: 'submission_number',
    label: 'Submission Number',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: false
  },
  {
    id: 'submission_type',
    label: 'Submission Type',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: false
  },
  {
    id: 'approval_pathway',
    label: 'Approval Pathway',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: false
  },
  {
    id: 'approval_decision',
    label: 'Approval Decision',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: false
  },
  {
    id: 'qc_status',
    label: 'Status',
    type: 'dropdown',
    options: [
      {
        label: 'Qced',
        value: 'qced'
      },
      {
        label: 'Escalate',
        value: 'escalete'
      },
      {
        label: 'Delete',
        value: 'delete'
      }
    ],
    value: '',
    showInApplicationView: false
  }
];
export const APPLICATION_FORM_MAPPING_CBER = [
  {
    id: 'meeting_id',
    label: 'Meeting Id',
    type: 'text_field',
    options: [],
    value: '',
    disable: true,
    showInApplicationView: false
  },
  {
    id: 'application_number',
    label: 'Application Number',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: true
  },
  {
    id: 'application_type',
    label: 'Application Type',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: false
  },
  {
    id: 'sponsor',
    label: 'Sponsor',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: true
  },
  {
    id: 'brand_name',
    label: 'Brand Name',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: true
  },
  {
    id: 'topic',
    label: 'Topic',
    type: 'text_field',
    options: [],
    value: '',
    showInApplicationView: true
  },
  {
    id: 'qc_status',
    label: 'Status',
    type: 'dropdown',
    options: [
      {
        label: 'Qced',
        value: 'qced'
      },
      {
        label: 'Escalate',
        value: 'escalete'
      },
      {
        label: 'Delete',
        value: 'delete'
      }
    ],
    value: '',
    showInApplicationView: false
  }
];

export const DEVICE_FORM_MAPPING = [
  {
    id: 'meeting_id',
    label: 'Meeting Id',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'term',
    label: 'Topic',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'application_number',
    label: 'Application Number',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'brand_name',
    label: 'Brand Name',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'sponsor',
    label: 'Sponsor',
    type: 'text_field',
    options: [],
    value: ''
  },
  {
    id: 'approval_pathway',
    label: 'Approval Pathway',
    type: 'dropdown',
    options: [
      {
        label: 'Pre Market Aprroval',
        value: 'PMA'
      },
      {
        label: 'DeNoVo',
        value: 'DENOVO'
      },
      {
        label: '510(k) | PMN',
        value: 'PMN'
      },
      {
        label: 'Not available',
        value: ''
      }
    ],
    value: ''
  },
  {
    id: 'qc_status',
    label: 'Status',
    type: 'dropdown',
    options: [
      {
        label: 'Non Qced',
        value: 'to_qc'
      },
      {
        label: 'Qced',
        value: 'qced'
      },
      {
        label: 'Escalate',
        value: 'escalete'
      },
      {
        label: 'Delete',
        value: 'delete'
      }
    ],
    value: 'Non Qced'
  }
];

export const DOCUMENT_TYPE = ['Final', 'Draft', 'Transcript'];

export const DEGREE_OPTIONS = [
  'DBA',
  'DDS',
  'Ed.D.',
  'MD',
  'Pharm.D.',
  'Ph.D.',
  'Psy.D.',
  'JD',
  'MBA',
  'M.Ed.',
  'MFA',
  'LL.M.',
  'MPA',
  'MPH',
  'M.Pub.',
  'MS',
  'MSW',
  'BAS',
  'B.Arch.',
  'BA',
  'BBA',
  'BFA',
  'BS'
];

export const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
