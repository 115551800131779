import { useHistory, useParams } from 'react-router-dom';
import {
  Autocomplete,
  Box,
  Container,
  createFilterOptions,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import Button from '@material-ui/core/Button';
import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import { Close, Save } from '@mui/icons-material';
import { Form, Formik } from 'formik';
import { getAddcommRosterById, getMemberList, upsertAdcommRoster } from '../../../api/Adcomm';
import Actions from '../../../store/actions';
import Store from '../../../store';
import Loading from '../../GuidanceCuration/GuidanceLoading';
import Styles from '../../DataEntry/styles/Review.styles';
import PDFViewer from '../PDFViewer';

const insertLabel = [
  'qc_date',
  'qc_complete',
  'status',
  'curator_email',
  'curator_name',
  'role',
  'member_id',
  'member',
  'voting',
  'degree',
  'affiliation',
  'additional_details',
  's3_link'
];
const degreeOptions = [
  'DBA',
  'DDS',
  'Ed.D.',
  'MD',
  'Pharm.D.',
  'Ph.D.',
  'Psy.D.',
  'JD',
  'MBA',
  'M.Ed.',
  'MFA',
  'LL.M.',
  'MPA',
  'MPH',
  'M.Pub.',
  'MS',
  'MSW',
  'BAS',
  'B.Arch.',
  'BA',
  'BBA',
  'BFA',
  'BS'
];

const filter = createFilterOptions();

const UpsertAdcommRoster = () => {
  const classes = Styles();

  const { meetingId, rosterId } = useParams();
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [initialValues, setInitialValues] = useState({
    role: '',
    member: '',
    voting: '',
    degree: '',
    affiliation: '',
    additionalDetails: ''
  });
  const [selectedS3Link, setSelectedS3Link] = useState('');
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(null);
  const [members, setMembers] = useState([]);
  // const [qsNo,setQsNo]=useState([])
  const history = useHistory();
  const { state, dispatch } = useContext(Store);

  const handleGoBack = () => {
    history.push(`/adcomm/${meetingId}/meeting/roster`);
  };
  const handleSubmitChanges = (values, setSubmitting) => {
    const param = { values, setSubmitting };
    setOpenSubmitConfirmation(param);
  };
  const generateMemberId = name => {
    const nameArray = name.trim().split(' ');
    let id = '';
    const lastIndex = nameArray.length - 1;
    nameArray.forEach((nameLocal, index) => {
      let localName = nameLocal.trim();
      if (localName && localName !== '.' && localName !== '-') {
        // eslint-disable-next-line array-callback-return,consistent-return
        localName = localName.split('').map(nam => {
          if (nam && nam !== '.' && nam !== '-') {
            return nam;
          }
        });
        localName = localName.join('');
        if (index === lastIndex) {
          id = `${id}${localName.charAt(0).toUpperCase()}${localName.slice(1)}`;
        } else {
          id = `${id}${localName.charAt(0).toUpperCase()}`;
        }
      }
    });

    return id;
  };
  const handleSubmitFinal = async param => {
    const { values, setSubmitting } = param;
    setOpenSubmitConfirmation(null);
    const user = await Auth.currentUserInfo();

    const payload = {
      qc_date: new Date(),
      qc_complete: true,
      status: 'Qced',
      curator_email: user.attributes.email,
      curator_name: user.attributes['custom:user'],
      role: values.role,
      member_id: generateMemberId(values.member),
      member: values.member,
      voting: values.voting,
      degree: values.degree,
      affiliation: values.affiliation,
      additional_details: values.additionalDetails,
      s3_link: selectedS3Link ?? ' '
    };
    if (rosterId === 'add' && 'id' in payload) {
      delete payload.id;
    } else if (rosterId !== 'add') {
      payload.id = rosterId;
    }
    insertLabel.forEach(label => {
      if (!(label in payload) || !payload[label]) {
        payload[label] = 'NA';
      }
    });
    const { data } = await upsertAdcommRoster(meetingId, payload);
    if (data && data.Success) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: `Roster ${rosterId === 'add' ? 'added' : 'edited'} successfully`
        }
      });
      history.push(`/adcomm/${meetingId}/meeting/roster`);
    } else {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: data.Error }
      });
    }
    setSubmitting(false);
  };

  useEffect(() => {
    async function fetchData() {
      if (!rosterId.match('add')) {
        const { data } = await getAddcommRosterById(rosterId);
        const { Success: success, Error: errors, Status: status } = data;
        if (status === 200) {
          if (success.roster) {
            setInitialValues({
              ...success.roster,
              additionalDetails: success.roster.additional_details
            });
          }
          if (success.pdf) {
            await dispatch({
              type: Actions.SET_ADCOMM_MINUTES_ADD_DETAILS,
              value: {
                pdf: success.pdf
              }
            });
          }
        } else {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: `${errors}` }
          });
        }
      } else {
        setEdit(true);
      }
      const { data: membersData } = await getMemberList();
      const { Success, Error, Status } = membersData;
      if (Status === 200) {
        setMembers([...Success]);
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `${Error}` }
        });
      }

      setLoading(false);
    }

    fetchData();
  }, [rosterId]);
  if (loading) {
    return (
      <Box className={classes.root}>
        <Container maxWidth='lg' className={classes.container}>
          <Loading dataLoading />
        </Container>
      </Box>
    );
  }
  return (
    <>
      <Box className={classes.root}>
        <Container maxWidth='lg' className={classes.container}>
          <Formik
            initialValues={{ ...initialValues }}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleSubmitChanges(values, setSubmitting);
            }}
            enableReinitialize>
            {({
              values,
              errors,
              dirty,
              handleChange,
              handleBlur,
              handleSubmit,
              setFieldValue,
              isValid,
              isSubmitting
            }) => {
              return (
                <>
                  <Box
                    display='flex'
                    padding={1}
                    flexDirection='row'
                    justifyContent='space-between'
                    flexWrap='wrap'>
                    <Stack flexDirection='row'>
                      <Button
                        disabled={loading || isSubmitting}
                        onClick={handleGoBack}
                        className={classes.button}>
                        Back
                      </Button>
                      {!rosterId.match('add') && (
                        <Button
                          disabled={loading || isSubmitting}
                          className={classes.button}
                          onClick={() => setEdit(prevState => !prevState)}
                          variant='contained'>
                          {edit ? 'Cancel' : 'Edit'}
                        </Button>
                      )}
                    </Stack>
                    <Button
                      className={classes.button}
                      disabled={isSubmitting || !(isValid && dirty)}
                      onClick={handleSubmit}
                      variant='contained'>
                      Save
                    </Button>
                    <Typography
                      component='div'
                      variant='h5'
                      sx={{ width: '100%', color: 'white', marginTop: '2em' }}>
                      Meeting id - {meetingId}
                    </Typography>
                  </Box>
                  <Box display='flex' flexDirection='row'>
                    <Box
                      sx={{
                        width: '100%',
                        maxHeight: '80vh',
                        overflow: 'auto',
                        paddingRight: '2em'
                      }}
                      className={`${classes.pdfContainer} ${classes.formSection}`}>
                      <Form>
                        <Box
                          display='flex'
                          width='90%'
                          flex={1}
                          justifyContent='space-evenly'
                          margin='1em auto'
                          alignContent='center'>
                          <Box width='45%' marginRight='2em' height='min-content' margin='auto'>
                            <Typography
                              variant='body1'
                              component='div'
                              className={classes.labelName}>
                              Role
                            </Typography>
                          </Box>
                          <TextField // eslint-disable-next-line react/prop-types
                            error={errors.role}
                            required
                            disabled={!edit}
                            className={classes.textarea}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            onBlur={handleBlur}
                            id='role' // eslint-disable-next-line react/prop-types
                            helperText={errors.role && errors.role}
                            name='role' // eslint-disable-next-line react/prop-types
                            value={values.role}
                            InputProps={{
                              classes: { input: classes.input },
                              disableUnderline: true
                            }}
                          />
                        </Box>
                        <Box
                          display='flex'
                          width='90%'
                          flex={1}
                          justifyContent='space-evenly'
                          margin='1em auto'
                          alignContent='center'>
                          <Box width='45%' marginRight='2em' height='min-content' margin='auto'>
                            <Typography
                              variant='body1'
                              component='div'
                              className={classes.labelName}>
                              Voting
                            </Typography>
                          </Box>
                          <Box width='100%'>
                            <RadioGroup
                              row
                              onBlur={handleBlur}
                              id='voting'
                              // eslint-disable-next-line react/prop-types
                              value={values.voting}
                              onChange={handleChange}
                              aria-labelledby='voting-group-label'
                              className={classes.radio}
                              name='voting'>
                              <FormControlLabel
                                disabled={!edit}
                                value='Yes'
                                control={<Radio />}
                                label='Yes'
                              />
                              <FormControlLabel
                                disabled={!edit}
                                value='No'
                                control={<Radio />}
                                label='No'
                              />
                              <FormControlLabel
                                disabled={!edit}
                                value='NA'
                                control={<Radio />}
                                label='NA'
                              />
                            </RadioGroup>
                          </Box>
                        </Box>
                        <Box
                          display='flex'
                          width='90%'
                          flex={1}
                          justifyContent='space-evenly'
                          margin='1em auto'
                          alignContent='center'>
                          <Box width='45%' marginRight='2em' height='min-content' margin='auto'>
                            <Typography
                              variant='body1'
                              component='div'
                              className={classes.labelName}>
                              Member
                            </Typography>
                          </Box>
                          <Autocomplete
                            fullWidth
                            id='member'
                            name='member'
                            onChange={(e, value) => setFieldValue('member', value)}
                            onBlur={handleBlur} // eslint-disable-next-line react/prop-types
                            value={values.member}
                            classes={{
                              inputRoot: classes.autocomplete,
                              input: classes.inputDisabled
                            }}
                            disabled={!edit}
                            freeSolo
                            selectOnFocus
                            clearOnBlur
                            handleHomeEndKeys
                            options={members} // eslint-disable-next-line react/prop-types
                            error={errors.member} // eslint-disable-next-line react/jsx-props-no-spreading
                            renderInput={params => <TextField {...params} />}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);

                              if (params.inputValue !== '') {
                                filtered.push(`${params.inputValue}`);
                              }

                              return filtered;
                            }}
                          />
                        </Box>
                        <Box
                          display='flex'
                          width='90%'
                          flex={1}
                          justifyContent='space-evenly'
                          margin='1em auto'
                          alignContent='center'>
                          <Box width='45%' marginRight='2em' height='min-content' margin='auto'>
                            <Typography
                              variant='body1'
                              component='div'
                              className={classes.labelName}>
                              Degree
                            </Typography>
                          </Box>
                          <Autocomplete
                            fullWidth
                            id='degree'
                            name='degree'
                            onBlur={handleBlur} // eslint-disable-next-line react/prop-types
                            value={
                              values.degree && values.degree.trim()
                                ? values.degree.trim().split(',')
                                : []
                            }
                            freeSolo
                            multiple
                            disabled={!edit}
                            onChange={(event, value) => {
                              setFieldValue('degree', value.join(','));
                            }}
                            options={degreeOptions}
                            ChipProps={{
                              className: classes.autocompleteChip
                            }}
                            classes={{
                              inputRoot: classes.autocomplete
                            }} // eslint-disable-next-line react/prop-types
                            error={errors.degree} // eslint-disable-next-line react/jsx-props-no-spreading
                            renderInput={params => <TextField {...params} />}
                          />
                        </Box>
                        <Box
                          display='flex'
                          width='90%'
                          flex={1}
                          justifyContent='space-evenly'
                          margin='1em auto'
                          alignContent='center'>
                          <Box width='45%' marginRight='2em' height='min-content' margin='auto'>
                            <Typography
                              variant='body1'
                              component='div'
                              className={classes.labelName}>
                              Affiliation
                            </Typography>
                          </Box>
                          <TextField // eslint-disable-next-line react/prop-types
                            error={errors.affiliation}
                            required
                            disabled={!edit}
                            className={classes.textarea}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            onBlur={handleBlur}
                            id='affiliation' // eslint-disable-next-line react/prop-types
                            helperText={errors.affiliation && errors.affiliation}
                            name='affiliation' // eslint-disable-next-line react/prop-types
                            value={values.affiliation}
                          />
                        </Box>
                        <Box
                          display='flex'
                          width='90%'
                          flex={1}
                          justifyContent='space-evenly'
                          margin='1em auto'
                          alignContent='center'>
                          <Box width='45%' marginRight='2em' height='min-content' margin='auto'>
                            <Typography
                              variant='body1'
                              component='div'
                              className={classes.labelName}>
                              Additional details
                            </Typography>
                          </Box>
                          <TextField // eslint-disable-next-line react/prop-types
                            error={errors.additionalDetails}
                            required
                            disabled={!edit}
                            className={classes.textarea}
                            onChange={handleChange}
                            fullWidth
                            multiline
                            onBlur={handleBlur}
                            id='additionalDetails' // eslint-disable-next-line react/prop-types
                            helperText={errors.additionalDetails && errors.additionalDetails}
                            name='additionalDetails' // eslint-disable-next-line react/prop-types
                            value={values.additionalDetails}
                            InputProps={{
                              classes: { input: classes.input },
                              disableUnderline: true
                            }}
                          />
                        </Box>
                      </Form>
                    </Box>
                    <Box display='flex' className={classes.pdfContainer} sx={{ marginLeft: '2em' }}>
                      <PDFViewer
                        setSelectedS3Link={setSelectedS3Link}
                        pdf={state.adcommMinutesAddDetails && state.adcommMinutesAddDetails.pdf}
                      />
                    </Box>
                  </Box>
                </>
              );
            }}
          </Formik>
        </Container>
      </Box>
      <Dialog
        open={openSubmitConfirmation}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <div>Confirm submission</div>
            <IconButton aria-label='close' onClick={() => setOpenSubmitConfirmation(null)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you have {rosterId === 'add' ? 'added' : 'edited'} data accurately?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='secondary'
            className={classes.button}
            disabled={loading}
            onClick={() => setOpenSubmitConfirmation(null)}>
            Cancel
          </Button>

          <Button
            startIcon={<Save />}
            variant='contained'
            onClick={() => handleSubmitFinal(openSubmitConfirmation)}
            disabled={loading}
            color='secondary'
            className={classes.button}>
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default UpsertAdcommRoster;
