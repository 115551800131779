const getsortLabels = (
  labelInfo: { id: string; labelName: string }[],
  labelData: { [key: string]: any }
) => {
  return labelInfo.sort((a, b) => {
    const aValue = labelData[a.id];
    const bValue = labelData[b.id];
    if (aValue === '0') {
      // a value is 0, move it to the end
      return 1;
    }
    if (bValue === '0') {
      // b value is 0, move it to the end
      return -1;
    }
    // sort by non-zero value
    return aValue - bValue;
  });
};
export default getsortLabels;
