const titleCaseString = (str: string) => {
  if (str) {
    return str.replace(/\w\S*/g, txt => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  return str;
};

export default titleCaseString;
