import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';

import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { getPmrById, getPmrByKey, submitPmrFormById } from '../../api/AdminPage';

// helpers
import { handleChange } from '../../helpers/changeHandler';

// styles
import Styles from './styles/PmrReview.styles';

const LabelReviewPage = props => {
  const classes = Styles();
  const history = useHistory();
  const { state, dispatch } = useContext(Store);
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [statusOpen, setStatusOpen] = useState(false);
  const [initialData, setInitialData] = useState();
  const [searchId, setSearchId] = useState();
  const [searchData, setSearchData] = useState();
  const [cmtNumber, setCmtNumber] = useState();
  const [cmtDesc, setCmtDesc] = useState();
  const [subpartFlag, setSubpartFlag] = useState();
  const [subpartDescOptions, setSubpartDescOption] = useState();
  const [sectionOptions, setSectionOptions] = useState();
  const [sectionSelectOpen, setSectionSelectOpen] = useState();
  const [section, setSection] = useState('ACCELERATED APPROVAL REQUIREMENTS');
  const [status, setStatus] = useState('Qced');
  const [formSubmit, setFormSubmit] = useState(false);
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [pdfLink, setPdfLink] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentUserInfo();
        setEmail(user.attributes.email);
        setName(user.attributes['custom:user']);
        // eslint-disable-next-line react/prop-types,react/destructuring-assignment
        const res = await getPmrByKey(props.match.params.key);
        if (res.data.Success.length < 1) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Enter valid information' }
          });
          history.push('/pmr/label');
        }
        setInitialData(res.data.Success);
        // eslint-disable-next-line camelcase
        const tbd_data = res.data.Success;
        // eslint-disable-next-line camelcase
        tbd_data.forEach(val => {
          if (val.s3_path !== 'No Data') {
            setPdfLink(val.pdf_url);
          }
        });
        setSubpartDescOption({
          'ACCELERATED APPROVAL REQUIREMENTS': 'Accelerated Approval',
          'REQUIRED PEDIATRIC ASSESSMENTS': 'Pediatric Research Equity Act',
          'POSTMARKETING REQUIREMENTS UNDER 505(o)(3)': 'FDAAA Section 505(o)(3)',
          'POSTMARKETING COMMITMENTS SUBJECT TO REPORTING REQUIREMENTS UNDER SECTION 506B':
            'PMC(R)',
          'POSTMARKETING COMMITMENTS NOT SUBJECT TO REPORTING REQUIREMENTS UNDER SECTION 506B':
            'PMC(NR)',
          'SUBPART I APPROVAL REQUIREMENTS': 'Animal Efficacy Rule',
          'FULFILLMENT OF POSTMARKETING REQUIREMENT/COMMITTMENT':
            'FULFILLMENT OF POSTMARKETING REQUIREMENT',
          'RELEASE FROM POSTMARKETING REQUIREMENT/COMMITTMENT':
            'RELEASE FROM POSTMARKETING REQUIREMENT',
          'N/A': 'N/A'
        });
        setSectionOptions([
          'ACCELERATED APPROVAL REQUIREMENTS',
          'REQUIRED PEDIATRIC ASSESSMENTS',
          'POSTMARKETING REQUIREMENTS UNDER 505(o)(3)',
          'POSTMARKETING COMMITMENTS SUBJECT TO REPORTING REQUIREMENTS UNDER SECTION 506B',
          'POSTMARKETING COMMITMENTS NOT SUBJECT TO REPORTING REQUIREMENTS UNDER SECTION 506B',
          'SUBPART I APPROVAL REQUIREMENTS',
          'FULFILLMENT OF POSTMARKETING REQUIREMENT/COMMITTMENT',
          'RELEASE FROM POSTMARKETING REQUIREMENT/COMMITTMENT',
          'N/A'
        ]);
        setSubpartFlag({
          'ACCELERATED APPROVAL REQUIREMENTS': 'H',
          'REQUIRED PEDIATRIC ASSESSMENTS': 'P',
          'POSTMARKETING REQUIREMENTS UNDER 505(o)(3)': 'F',
          'POSTMARKETING COMMITMENTS SUBJECT TO REPORTING REQUIREMENTS UNDER SECTION 506B':
            'PMC(R)',
          'POSTMARKETING COMMITMENTS NOT SUBJECT TO REPORTING REQUIREMENTS UNDER SECTION 506B':
            'PMC(NR)',
          'SUBPART I APPROVAL REQUIREMENTS': 'E',
          'FULFILLMENT OF POSTMARKETING REQUIREMENT/COMMITTMENT': '',
          'RELEASE FROM POSTMARKETING REQUIREMENT/COMMITTMENT': '',
          'N/A': 'N/A'
        });
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        history.push('/pmr/label');
      }
    }

    fetchData();
  }, [formSubmit]);

  const addSearchData = async () => {
    setSearchData({
      id: uuidv4(),
      key: initialData[0].key,
      appl_type: initialData[0].appl_type,
      appl_num: initialData[0].appl_num,
      supplement_type: initialData[0].supplement_type,
      supplement_num: initialData[0].supplement_num,
      s3_path: initialData[0].s3_path,
      category: ''
    });
    setEditMode(false);
    setSearchId(null);
    setCmtNumber(null);
    setCmtDesc(null);
    setSection('ACCELERATED APPROVAL REQUIREMENTS');
  };

  const handleSearch = async () => {
    try {
      const searchResponse = await getPmrById(searchId);
      const res = searchResponse.data.Success[0];
      setSearchData(res);
      setCmtNumber(res.cmt_number);
      setCmtDesc(res.cmt_desc);
      setSection(res.section);
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Enter valid information' }
      });
    }
  };

  const handleSubmit = async () => {
    try {
      const payload = {
        id: searchData.id,
        key: searchData.key,
        appl_type: searchData.appl_type,
        appl_num: searchData.appl_num,
        supplement_type: searchData.supplement_type,
        supplement_num: searchData.supplement_num,
        cmt_number: cmtNumber,
        cmt_desc: cmtDesc,
        subpart_flag: subpartFlag[section],
        subpart_description: subpartDescOptions[section],
        status,
        section,
        s3_path: searchData.s3_path,
        category: searchData.category,
        curator_email: email,
        curator_name: name
      };
      await submitPmrFormById(payload);
      history.push(`/pmr/label/${searchData.key}`);
      window.location.reload();
      setSearchData(null);
      setEditMode(false);
      setSearchId(null);
      setFormSubmit(true);
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error }
      });
    }
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        {editMode ? null : (
          <Box display='flex' padding={1} flexDirection='row' justifyContent='flex-start'>
            <Button
              className={classes.button}
              onClick={() => history.push('/pmr/label')}
              variant='contained'>
              Go back
            </Button>
            <Button
              className={classes.button}
              onClick={() => setEditMode(true)}
              variant='contained'>
              Edit
            </Button>
          </Box>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Box
              className={classes.searchBoxContainer}
              justifyContent='center'
              alignItems='space-evenly'>
              <Box
                display='flex'
                flexDirection='column'
                justifyContent='space-around'
                className={classes.searchBox}>
                <Typography align='left' className={classes.label}>
                  Select the CMT number to edit
                </Typography>
                <Box display='flex' flexDirection='row' padding={1}>
                  <Select
                    id='pmrReviewSearch'
                    variant='standard'
                    disableUnderline
                    required
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                      },
                      getContentAnchorEl: null
                    }}
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    value={searchId}
                    onChange={event => setSearchId(handleChange(event))}
                    className={classes.dropdownContainer}
                    inputProps={{
                      classes: {
                        icon: classes.icon
                      }
                    }}>
                    {initialData &&
                      initialData.map((item, index) => {
                        return (
                          <MenuItem value={item.id} className={classes.dropdownText}>
                            {index + 1}) {item.cmt_number}
                          </MenuItem>
                        );
                      })}
                    <MenuItem value='' className={classes.dropdownText}>
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      ''
                    </MenuItem>
                  </Select>
                  <Button type='submit' className={classes.addBtn} onClick={handleSearch}>
                    Search
                  </Button>
                  <Button className={classes.addBtn} onClick={addSearchData}>
                    Add
                  </Button>
                </Box>
              </Box>
            </Box>

            {searchData && searchData ? (
              <Box className={classes.reviewBox} justifyContent='center' alignItems='space-evenly'>
                <Box padding={1}>
                  <Typography align='left' className={classes.label}>
                    CMT Number
                  </Typography>
                  {editMode ? (
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      value={cmtNumber}
                      onChange={event => setCmtNumber(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter cmt number'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                  ) : null}
                  {editMode ? null : (
                    <Typography align='left' className={classes.details}>
                      {searchData && searchData.cmt_number}
                    </Typography>
                  )}
                </Box>
                <Box padding={1}>
                  <Typography align='left' className={classes.label}>
                    CMT Description
                  </Typography>
                  {editMode ? (
                    <TextField
                      className={classes.textfield}
                      fullWidth
                      multiline
                      rowsMin={3}
                      rowsMax={4}
                      value={cmtDesc}
                      onChange={event => setCmtDesc(handleChange(event))}
                      id='standard-basic'
                      placeholder='Enter cmt description'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                  ) : null}
                  {editMode ? null : (
                    <Typography align='left' className={classes.details}>
                      {searchData && searchData.cmt_desc}
                    </Typography>
                  )}
                </Box>
                <Box padding={1}>
                  <Typography align='left' className={classes.label}>
                    Section
                  </Typography>
                  {editMode ? (
                    <Select
                      id='pmrReviewSearch'
                      variant='standard'
                      disableUnderline
                      required
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }}
                      open={sectionSelectOpen}
                      onClose={() => setSectionSelectOpen(false)}
                      onOpen={() => setSectionSelectOpen(true)}
                      value={section}
                      onChange={event => setSection(handleChange(event))}
                      className={classes.dropdownContainer}
                      inputProps={{
                        classes: {
                          icon: classes.icon
                        }
                      }}>
                      {sectionOptions &&
                        sectionOptions.map(item => {
                          return <MenuItem value={item}>{item}</MenuItem>;
                        })}
                      {/* eslint-disable-next-line react/no-unescaped-entities */}
                      <MenuItem value=''>''</MenuItem>
                    </Select>
                  ) : null}
                  {editMode ? null : (
                    <Typography align='left' className={classes.details}>
                      {searchData && searchData.section}
                    </Typography>
                  )}
                </Box>
                <Box padding={1} display='flex' flexDirection='column'>
                  <Typography align='left' className={classes.label}>
                    Status
                  </Typography>
                  {editMode ? (
                    <Select
                      id='selectStatus'
                      variant='standard'
                      disableUnderline
                      required
                      MenuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left'
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left'
                        },
                        getContentAnchorEl: null
                      }}
                      open={statusOpen}
                      onClose={() => setStatusOpen(false)}
                      onOpen={() => setStatusOpen(true)}
                      value={status}
                      onChange={event => setStatus(handleChange(event))}
                      className={classes.dropdownContainer}
                      inputProps={{
                        classes: {
                          icon: classes.icon
                        }
                      }}>
                      <MenuItem value='Qced' className={classes.dropdownText}>
                        Qced
                      </MenuItem>
                      <MenuItem value='No Document' className={classes.dropdownText}>
                        No Document
                      </MenuItem>
                      <MenuItem value='Escalate' className={classes.dropdownText}>
                        Escalate
                      </MenuItem>
                      <MenuItem value='Delete' className={classes.dropdownText}>
                        Delete
                      </MenuItem>
                      <MenuItem value='' className={classes.dropdownText} />
                    </Select>
                  ) : null}
                  {editMode ? null : (
                    <Typography align='left' className={classes.details}>
                      {searchData && searchData.status}
                    </Typography>
                  )}
                </Box>
                {editMode ? (
                  <Box padding={1} display='flex' flexDirection='row'>
                    <Button
                      className={classes.button}
                      onClick={() => setEditMode(false)}
                      variant='contained'>
                      Cancel
                    </Button>
                    <Button
                      type='submit'
                      className={classes.button}
                      disabled={
                        !!(
                          state.userStub &&
                          state.userStub['custom:role'] !== 'Admin' &&
                          searchData &&
                          searchData.status === 'Qced'
                        )
                      }
                      onClick={handleSubmit}
                      variant='contained'>
                      Submit
                    </Button>
                  </Box>
                ) : null}
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={6}>
            {pdfLink && pdfLink ? (
              <Box display='flex' className={classes.pdfContainer}>
                <iframe title='pdf' src={pdfLink && pdfLink} className={classes.pdf} />
              </Box>
            ) : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.pdfContainer}>
                <Typography className={classes.notAvailable}>No pdf is available</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default LabelReviewPage;
