import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';

import { AccountBoxOutlined, AccountCircle, ExitToApp, VerifiedUser } from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tab,
  Tabs,
  Toolbar,
  Typography
} from '@material-ui/core';
import AuthContext from '../../store/Auth/AuthProvider';
import Store from '../../store';
import Actions from '../../store/actions';

import logoWhite from '../../assets/svgs/logoWhite.svg';

import NavbarStyles from './styles/Navbar.styles';
import { tabList } from '../Home/Home';

const Navbar = () => {
  const classes = NavbarStyles();
  const history = useHistory();
  const location = useLocation();
  const { currentUser, setCurrentUser } = useContext(AuthContext);
  const { state, dispatch } = useContext(Store);
  const [anchorEl, setAnchorEl] = useState(null);
  const [admin, setAdmin] = useState(false);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = async () => {
    try {
      await Auth.signOut();
      await dispatch({ type: Actions.SET_USER, value: null });
      setCurrentUser(false);
      history.push('/');
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    }
  };
  const handleChange = async (event, newValue) => {
    await dispatch({ type: Actions.SET_HOME_TABS, value: newValue });
    if (location.pathname !== '/') {
      history.push('/');
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await Auth.currentUserInfo();
        await dispatch({
          type: Actions.SET_MODULE,
          value: 'admin'
        });
        if (!user) {
          history.push('/login');
        } else {
          if (user.attributes['custom:role'] === 'Admin') {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
          await dispatch({
            type: Actions.SET_USER_STUB,
            value: user.attributes
          });
        }
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: error.message }
        });
      }
    };
    fetchData();
  }, [dispatch, history]);

  if (!currentUser) {
    return <Redirect to='/' />;
  }
  return (
    <AppBar elevation={3} position='fixed' className={classes.root}>
      <Container maxWidth='xl'>
        <Toolbar disableGutters>
          <Box className={classes.responsiveLogo} onClick={() => history.push('/')}>
            <img src={logoWhite} className={classes.vivLogo} alt='logo' />
          </Box>
          <Tabs
            fullWidth
            className={classes.tabs}
            value={state.homeTabs}
            onChange={handleChange}
            color='primary'
            aria-label='admin tabs'
            variant='scrollable'
            scrollButtons='auto'>
            {/* eslint-disable-next-line no-shadow */}
            {tabList.map(({ label, permission }) => {
              if (permission.includes('admin') && admin) {
                return (
                  <Tab
                    className={classes.tab}
                    key={label}
                    label={label}
                    id={label}
                    color='primary'
                    aria-controls={`tabpanel-${label}`}
                    wrapped
                  />
                );
              }
              if (permission.includes('curator') && !admin) {
                return (
                  <Tab
                    className={classes.tab}
                    key={label}
                    label={label}
                    id={label}
                    aria-controls={`tabpanel-${label}`}
                    wrapped
                  />
                );
              }
              return null;
            })}
          </Tabs>
          {state.userStub && state.userStub ? (
            <Box sx={{ flexGrow: 0 }}>
              <IconButton onClick={handleClick}>
                <AccountCircle className={classes.navIcon} />
                <Typography className={classes.name}>
                  {state.userStub && state.userStub['custom:user']}
                </Typography>
              </IconButton>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                keepMounted
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem>
                  <ListItemIcon>
                    <AccountBoxOutlined
                      className={classes.menuIcon}
                      color='primary'
                      fontSize='small'
                    />
                  </ListItemIcon>
                  <Typography className={classes.username}>
                    {state.userStub && state.userStub['custom:user']}
                  </Typography>
                </MenuItem>
                <MenuItem>
                  <ListItemIcon>
                    <VerifiedUser className={classes.menuIcon} color='primary' fontSize='small' />
                  </ListItemIcon>
                  <Typography className={classes.username}>
                    {state.userStub && state.userStub['custom:role']}
                  </Typography>
                </MenuItem>
                <Divider />
                <MenuItem>
                  <Button onClick={handleLogout}>
                    <ListItemIcon>
                      <ExitToApp className={classes.menuIcon} fontSize='large' />
                    </ListItemIcon>
                    <ListItemText className={classes.menuText}>Logout</ListItemText>
                  </Button>
                </MenuItem>
                <MenuItem />
              </Menu>
            </Box>
          ) : null}
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navbar;
