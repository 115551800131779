import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';

// mui
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import { DialogContent, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';

// api
import { getAllMeetings, saveOrSubmitMeetingDetails } from '../../api/AdcommModule';

// Store
import Actions from '../../store/actions';
import Store from '../../store';
import CurationForm from '../CustomDashboard/CurationForm';
import { MEETING_FORM_MAPPING } from './constant';

// utils
import getDataFields, { getFieldsFromApiData } from '../CustomDashboard/utils/getDataFields';

const MeetingCurationForm = ({ closeEdit, meetingId }: any) => {
  const [pdfUrl, setpdfUrl] = useState<Array<any>>([]);
  const { dispatch } = useContext<any>(Store);
  const [meetingData, setMeetingData] = useState<any>([]);
  const [meeting, setMeeting] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const getMeetingData = async () => {
      try {
        setLoading(true);
        const filters: any = {};
        if (meetingId) {
          filters.meeting_id = meetingId;
        }

        const result = await getAllMeetings(10, 0, { filters });
        if (result?.data?.body.data || result.data.body.status === 200) {
          setMeeting(result?.data?.body.data[0]);
          setMeetingData(getFieldsFromApiData(result.data.body.data[0], MEETING_FORM_MAPPING));
          setpdfUrl(result.data.body.data);
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
        closeEdit();
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getMeetingData();
  }, [meetingId]);

  const handleSubmit = async (e: React.SyntheticEvent, data: any) => {
    try {
      if (Object.keys(data).length !== 0) {
        const user = await Auth.currentUserInfo();
        const payload = {
          ...meeting,
          ...getDataFields(data),
          curator_email: user.attributes.email,
          curator_name: user.attributes['custom:user']
        };
        await saveOrSubmitMeetingDetails(payload, 'submit');
      }
    } catch (error: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    } finally {
      closeEdit();
    }
  };

  return (
    <Container sx={{ overflow: 'hidden' }}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Meeting Curation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <CurationForm fields={meetingData} handleSubmit={handleSubmit} fileUrls={pdfUrl} />
          ) : (
            <Box display='flex' maxHeight='100vh' sx={{ overflow: 'hidden' }}>
              <Skeleton variant='rectangular' height='100vh' width='100%' />
            </Box>
          )}
        </DialogContent>
      </div>
    </Container>
  );
};

export default React.memo(MeetingCurationForm);
