import { useState, useCallback, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { getSBASAttributes, getSBASDocumentTypeByProps } from '../../../api/submission_details';
import Store from '../../../store';
import Actions from '../../../store/actions';
const DOCUMENT_TYPE_NAME = 'label';

const useSBASInitialData = () => {
    const [sbasAttributes, setSbasAttributes] = useState<Array<{ attribute_id: Number; attribute_name: String }>>([]);
    const [documentTypeId, setDocumentTypeId] = useState(null);
    const [curatorName, setCuratorName] = useState('');
    const [selectedAttribute, setSelectedAttribute] = useState<any>(1);
    const { dispatch } = useContext<any>(Store);

    const alertFn = useCallback((message: string, type: string) => {
        dispatch({
            type: Actions.SET_ALERT,
            value: {
                status: true,
                message,
                color: type
            }
        });
    }, []);

    const getUserDetails = async () => {
        const user = await Auth.currentUserInfo();
        setCuratorName(user.attributes['custom:user']);
    };

    const getPossibleAttributes = async () => {
        // eslint-disable-next-line no-console
        const response = await getSBASAttributes().catch(console.error);
        if (response.data?.Status === 200) {
            setSbasAttributes(response.data.Success);
            if (Array.isArray(response.data.Success) && response.data.Success.length > 0) {
                let safetyData = response.data.Success.find(({ attribute_name }: any) => attribute_name === 'Safety Data')
                let { attribute_id } = safetyData;
                setSelectedAttribute(attribute_id);
            }
        } else {
            alertFn('Failed to fetch SBAS attributes', 'error');
        }
    };

    const getDocumentType = async () => {
        // eslint-disable-next-line no-console
        const response = await getSBASDocumentTypeByProps(DOCUMENT_TYPE_NAME).catch(console.error);
        if (response.data?.Status === 200) {
            setDocumentTypeId(response.data.Success.id);
        } else {
            alertFn('Failed to fetch document id', 'error');
        }
    };

    return {
        sbasAttributes,
        documentTypeId,
        curatorName,
        selectedAttribute,
        alertFn,
        setSelectedAttribute,
        getDocumentType,
        getPossibleAttributes,
        getUserDetails
    };
};

export default useSBASInitialData;
