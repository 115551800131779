import AdCommMeetingDashoard from '../components/AdCommModule/AdCommMeetingDashbaord';
import AdCommApplicationDashbaord from '../components/AdCommModule/AdCommApplicationDashbaord';
import AdCommApplicationReview from '../components/AdComm/AdCommAdminApplReview';
import AdcommMinutes from '../components/AdCommModule/AdcommMinutes';
import AdcommRoster from '../components/AdCommModule/AdcommRoster';
import UpsertAdcommRoster from '../components/AdComm/AdcommRoster/UpsertAdcommRoster';
import Pre2009 from '../components/AdComm/Pre2009/Pre2009';
import AdcommMaterial from '../components/AdCommModule/AdcommMaterial';

const ADCOMM_ROUTES = [
  {
    id: 'AdcommHome',
    path: '/adcomm',
    component: AdCommMeetingDashoard,
    exact: true
  },
  {
    id: 'adcommApplicationQcReview',
    path: '/adcomm/:meetingId/:applicationPrimaryKey',
    component: AdCommApplicationReview,
    exact: true
  },
  {
    id: 'adcommMinutes',
    path: '/adcomm/:meetingId/meeting/minutes',
    component: AdcommMinutes,
    exact: true
  },
  {
    id: 'adcommRoster',
    path: '/adcomm/:meetingId/meeting/roster',
    component: AdcommRoster,
    exact: true
  },
  {
    id: 'adcommRosterUpsert',
    path: '/adcomm/:meetingId/meeting/roster/:rosterId',
    component: UpsertAdcommRoster,
    exact: true
  },
  {
    id: 'adcommPre2009',
    path: '/adcomm/:meetingId/meeting/new',
    exact: true,
    component: Pre2009
  },
  {
    id: 'adcommMaterial',
    path: '/adcomm/:meetingId/meeting/material',
    exact: true,
    component: AdcommMaterial
  },
  {
    id: 'AdcommApplicationHome',
    path: '/adcomm/application',
    exact: true,
    component: AdCommApplicationDashbaord
  }
];

export default ADCOMM_ROUTES;
