import palette from '../../../themev5/palette';

const styles = {
  customNode: {
    borderRadius: '15px',
    background: 'white',
    minWidth: '200px',
    boxShadow: 3
  },
  lib: {
    margin: 0,
    padding: 1
  },

  tableName: {
    background: palette.blue.alice,
    padding: '10px',
    '& .rule-button': {
      visibility: 'hidden'
    },
    '&:hover .rule-button': {
      visibility: 'visible'
    }
  },
  tableSuccess: {
    height: '15px',
    background: palette.primary.main,
    borderRadius: '15px 15px 0px 0px'
  },
  tableFailure: {
    height: '10px',
    background: palette.red.main,
    borderRadius: '10px 10px 0px 0px'
  },
  tablePending: {
    height: '15px',
    background: palette.orange.main,
    borderRadius: '15px 15px 0px 0px'
  },

  failText: {
    color: palette.red.main,
    fontWeight: 400
  },

  passText: {
    color: palette.primary.main,
    fontWeight: 400
  },
  passCount: {
    backgroundColor: 'rgba(50, 177, 135, 0.2)',
    color: palette.primary.main,
    borderRadius: '15px',
    padding: '10px'
  },
  failCount: {
    backgroundColor: 'rgba(227, 53, 46, 0.2)',
    color: palette.red.main,
    borderRadius: '15px',
    padding: '10px'
  },

  ruleCheck: {
    color: '#6d727e'
  },

  ruleBorder: {
    border: '2px solid #e9ebeb',
    borderRadius: '7px',
    padding: '10px',
    margin: '20px 5px',
    '.rule-delete': {
      visibility: 'hidden'
    },
    '&:hover .rule-delete': {
      visibility: 'visible'
    }
  },
  chainRuleBorder: {
    border: '2px solid #e9ebeb',
    borderRadius: '7px',
    padding: '10px',
    margin: '20px 5px',
    '.rule-delete-chain': {
      visibility: 'hidden'
    },
    '&:hover .rule-delete-chain': {
      visibility: 'visible'
    }
  },

  suggestedRuleBorder: {
    borderWidth: '2px',
    borderColor: palette.primary.main,
    borderStyle: 'solid',
    borderRadius: '7px',
    padding: '10px',
    margin: '20px 5px',
    boxShadow: '0 0 5px #33B187',
    '.rule-delete': {
      visibility: 'hidden'
    },
    '&:hover .rule-delete': {
      visibility: 'visible'
    }
  },

  codeHightlight: {
    backgroundColor: palette.black.darkVariant,
    color: 'white',
    padding: '10px',
    borderRadius: '5px',
    '& .code-button': {
      visibility: 'hidden'
    },
    '&:hover .code-button': {
      visibility: 'visible'
    }
  },
  outlinedButton: {
    color: palette.primary.main,
    borderColor: palette.primary.main,
    '&:hover': {
      borderColor: palette.primary.main,
      backgroundColor: '#33B18733'
    }
  },
  codeButton: {
    marginTop: '5px',
    color: palette.primary.main,
    borderColor: palette.primary.main,
    '&:hover': {
      borderColor: palette.primary.main,
      backgroundColor: '#33B18733'
    }
  }
};

export default styles;
