import makeRequest from './client';

const baseURL = `/user_survey`;

const getSurveyAnswers = (surveyTitle: string) => {
  const finalURL = `${baseURL}/answers/${surveyTitle}`;
  return makeRequest(finalURL, 'GET');
};

export default getSurveyAnswers;
