import { useState } from "react";
import { Box } from "@mui/material";
import DOMPurify from "dompurify";
import { marked } from "marked";

interface Props {
  markdown: string;
}

export default function MarkdownRenderer({ markdown }: Props) {
  const [html, setHtml] = useState("");

  // Use the marked library to convert the markdown to HTML
  const convertedHtml = marked(markdown);

  // Use the DOMPurify library to sanitize the HTML and prevent XSS attacks
  const sanitizedHtml = DOMPurify.sanitize(convertedHtml);

  // Set the sanitized HTML as the component's state
  useState(() => {
    setHtml(sanitizedHtml);
    //@ts-ignore
  }, [markdown]);

  return (
    <Box sx={{ p: 1 }} dangerouslySetInnerHTML={{ __html: html }} />
  );
}
