import React from 'react';

import { Box, Button, Container, Typography } from '@material-ui/core';
import VivproLogo from '../assets/images/Viv321.png';

import ErrorStyles from './ErrorGeneral.styles';

const ErrorGeneral = () => {
  const classes = ErrorStyles();

  return (
    <Container>
      <Box
        className={classes.root}
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'>
        <img src={VivproLogo} className={classes.logo} alt='logo' />
        <Typography variant='subtitle1'>Sorry, Something went wrong!</Typography>
        <Button variant='contained' href='/' className={classes.btn}>
          Go to home
        </Button>
      </Box>
    </Container>
  );
};

export default ErrorGeneral;
