import React, { useState } from 'react';

import {
  Container,
  DialogTitle,
  Box,
  Grid,
  IconButton,
  Typography,
  Stack,
  TextField,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const CHECK_BOX_OPTIONS = ['Newborn', 'Infant', 'Child', 'Adolescent'];

const PediatricIndicationAgeCategory = ({ closeEdit, onFormSubmit, indicationData }: any) => {
  const [status, setstatus] = useState(indicationData.status);
  const [categories, setCategories] = useState(indicationData.pediatrics_categories);

  const handleCheckBoxChange = (opt: string) => {
    if (opt === 'None') {
      setCategories(null);
    } else {
      const foundIndex = (categories || []).findIndex((cat: string) => cat === opt);
      const newCategories = categories ? [...categories] : [];
      if (foundIndex === -1) {
        newCategories.push(opt);
      } else {
        newCategories.splice(foundIndex, 1);
      }
      setCategories(newCategories);
    }
  };

  const handleFormSubmit = (e: any) => {
    e.preventDefault();
    onFormSubmit({
      ...indicationData,
      pediatrics_categories: categories,
      status
    });
  };

  return (
    <Container>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Select Age Categories
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid container spacing={2} display='flex' overflow='hidden' p={1}>
          <Grid item xs={12} lg={6} overflow='auto'>
            <Stack spacing={1}>
              <Stack maxHeight='fit-content' overflow='auto'>
                <Typography>
                  <b>Application Number:</b>{' '}
                  {Array.isArray(indicationData?.appl_num)
                    ? indicationData?.appl_num?.join(', ')
                    : indicationData?.appl_num}
                </Typography>
              </Stack>
              <Stack maxHeight='fit-content' overflow='auto'>
                <Typography>
                  <b>Generic Name:</b>{' '}
                  {Array.isArray(indicationData?.generic_name)
                    ? indicationData?.generic_name?.join(', ')
                    : indicationData?.generic_name}
                </Typography>
              </Stack>
              <Stack maxHeight='fit-content' overflow='auto'>
                <Typography>
                  <b>Product Name:</b>{' '}
                  {Array.isArray(indicationData?.product_name)
                    ? indicationData?.product_name?.join(', ')
                    : indicationData?.product_name}
                </Typography>
              </Stack>
              <Stack maxHeight={400} overflow='auto'>
                <Typography fontWeight='bold'>Indication:</Typography>
                <Typography pt={2} pb={2} pl={1}>
                  {indicationData?.indications}
                </Typography>
              </Stack>

              {indicationData.dosage_and_administration && (
                <Stack maxHeight={400} overflow='auto'>
                  <Typography fontWeight='bold'>Dosage and Administration</Typography>
                  <Typography pt={2} pb={2} pl={1}>
                    {indicationData?.dosage_and_administration}
                  </Typography>
                </Stack>
              )}

              {indicationData.use_in_specific_populations && (
                <Stack maxHeight={400} overflow='auto'>
                  <Typography fontWeight='bold'> Use in specific populations </Typography>
                  <Typography pt={2} pb={2} pl={1}>
                    {indicationData?.use_in_specific_populations}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </Grid>

          <Grid item xs={12} lg={6} overflow='hidden'>
            <form onSubmit={handleFormSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography fontWeight='bold'>Categories:</Typography>
                  <Box>
                    {CHECK_BOX_OPTIONS.map((opt: string) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={categories && !!categories.find((cat: string) => cat === opt)}
                            onChange={() => handleCheckBoxChange(opt)}
                          />
                        }
                        label={opt}
                      />
                    ))}

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!categories || categories.length === 0}
                          onChange={() => handleCheckBoxChange('None')}
                        />
                      }
                      label='None'
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    id='status'
                    label='QC Status'
                    select
                    fullWidth
                    required
                    value={status}
                    onChange={e => setstatus(e.target.value)}
                    InputProps={{
                      style: { fontSize: 16, minWidth: '250px' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: 16 }
                    }}>
                    <MenuItem value='Qced'>Qced</MenuItem>
                    <MenuItem value='Escalate'>Escalate</MenuItem>
                    <MenuItem value='no_change'>No Change</MenuItem>
                  </TextField>
                </Grid>
                <Grid item xs={12} display='flex' direction='row' justifyContent='space-around'>
                  <Button
                    type='submit'
                    variant='contained'
                    color='primary'
                    style={{ color: 'white', width: '45%' }}>
                    SUBMIT
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default React.memo(PediatricIndicationAgeCategory);
