import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material ui
import Box from '@material-ui/core/Box';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';

import useSnapshotAPI from './hooks/useSnapshotAPI';
import styles from '../Datagrid/Datagrid.styles';

import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';
import Chip from './Chip';

import themev5 from '../../themev5';

import tableStyles from './styles/table-root';
import Select from './Select';
import { QC_OPTIONS } from './constant';

const statusMapping: any = {
  not_qced: 'Not QCed',
  qced: 'Qced',
  escalate: 'Escalate',
  no_document: 'No Document'
};
const Snapshot = () => {
  const history = useHistory();
  const [pageNo, setPageNo] = useState(0);
  const [pageSize, setPageSize] = useState(0);
  const [qcStatus, setQCStatus] = useState('all');
  const [searchText, setSearchText] = useState('');
  const ref = useRef<HTMLInputElement>(null);

  const searchParams = new URLSearchParams(history.location.search);

  const { applications, loading, applicationsCount, getApplications } = useSnapshotAPI();

  const columns = [
    {
      field: 'Edit',
      width: 100,
      headerAlign: 'center',
      // eslint-disable-next-line no-unused-vars
      renderCell: ({ row }: any) => {
        return (
          <Button
            variant='contained'
            color='primary'
            onClick={() => {
              history.push(
                `/snapshot/${row.application_number}-${row.submission_type}-${row.submission_number}`
              );
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'application_key',
      headerName: 'Application Key',
      headerAlign: 'center',
      width: 200,
      align: 'center',
      valueGetter: ({ row }: any) =>
        `${row.application_number}-${row.submission_type}-${row.submission_number}`
    },
    {
      field: 'application_number',
      headerName: 'Application Number',
      headerAlign: 'center',
      width: 200,
      align: 'center'
    },
    {
      field: 'submission_type',
      headerName: 'Submission type',
      headerAlign: 'center',
      width: 200,
      align: 'center'
    },
    {
      field: 'submission_number',
      headerName: 'Submission Number',
      headerAlign: 'center',
      width: 200,
      align: 'center'
    },
    {
      field: 'qc_status',
      headerName: 'Status',
      headerAlign: 'center',
      width: 200,
      align: 'center',
      valueGetter: ({ value }: any) => statusMapping[value] || value
    },
    {
      field: 'curator_name',
      headerName: 'Curated By',
      headerAlign: 'center',
      width: 200,
      align: 'center'
    }
  ];

  useEffect(() => {
    if (!pageSize) {
      setSearchText(searchParams.get('searchText') || '');
      setPageNo(parseInt(searchParams.get('pageNo') || '0', 10));
      setQCStatus(searchParams.get('qcStatus') || 'all');
      setPageSize(parseInt(searchParams.get('pageSize') || '20', 10));
    }
  }, []);

  useEffect(() => {
    const query: any = {
      pageNo,
      pageSize,
      qcStatus,
      searchText
    };
    let queryString = '';
    Object.keys(query).forEach((key: string) => {
      if (query[key]) {
        queryString += `${key}=${query[key]}&`;
      }
    });
    window.history.replaceState(null, '', `${window.location.pathname}?${queryString}`);
    if (pageSize) {
      getApplications(pageNo, pageSize, qcStatus, searchText);
    }
  }, [pageSize, pageNo, qcStatus, searchText]);

  useEffect(() => {
    setPageNo(0);
  }, [qcStatus]);

  const handleSearchClick = () => {
    setSearchText(ref.current?.value as string);
  };
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        <Select
          label='QC Status'
          value={qcStatus}
          onChange={(event: any) => setQCStatus(event.target.value)}
          options={[
            { label: 'All', value: 'all' },
            { label: 'Not Qced', value: 'not_qced' },
            ...QC_OPTIONS
          ]}
        />
        <TextField
          variant='outlined'
          type='search'
          id='search_application'
          label='Search Application Number'
          defaultValue={searchText}
          inputRef={ref}
          onKeyPress={(event: any) => event.key === 'Enter' && handleSearchClick()}
          InputProps={{
            style: {
              minWidth: '250px'
            }
          }}
        />
        <Button sx={{ ml: 1 }} variant='contained' color='primary' onClick={handleSearchClick}>
          Search
        </Button>
        <Button
          sx={{ ml: 1 }}
          variant='contained'
          color='primary'
          onClick={() => setSearchText('')}>
          Clear
        </Button>
      </Box>
      <Box display='flex' ml={2}>
        {qcStatus !== 'all' && (
          <Chip title={qcStatus} value={qcStatus} onClose={() => setQCStatus('all')} />
        )}
        {searchText && (
          <Chip title={searchText} value={searchText} onClose={() => setSearchText('')} />
        )}
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={4}
        display='flex'
        alignItems='center'
        justifyContent='center'
        sx={tableStyles.root as any}>
        <DataGrid
          sx={styles.datagrid}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={applications}
          columns={columns as any}
          rowCount={applicationsCount}
          pageSize={pageSize}
          getRowId={row => row.application_number + row.submission_id}
          onPageSizeChange={setPageSize}
          onPageChange={setPageNo}
          rowsPerPageOptions={[20, 40, 60, 80]}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={pageNo}
          paginationMode='server'
          components={{
            // Toolbar: DatagridToolbar,
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
      </Box>
    </ThemeProvider>
  );
};

export default Snapshot;
