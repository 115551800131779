import { useEffect, useState, useContext } from 'react';
import 'reactflow/dist/style.css';
import { isEmpty } from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';

// mui components
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Box,
  Typography,
  Fab,
  CircularProgress
} from '@mui/material';
import { Add } from '@mui/icons-material';

// eslint-disable-next-line import/no-extraneous-dependencies
import ReactFlow, { Background, Controls, Panel, useNodesState, useEdgesState } from 'reactflow';
import { getSchemaList, getJSONResultData } from '../../api/DataQuality';
import CustomNode from './CustomNode';
import styles from './styles/DataQuality.styles';
import palette from '../../themev5/palette';
import RuleView from './RuleView';
import Store from '../../store';
import actions from '../../store/actions';

const edgeOptions = {
  animated: false,
  type: 'smoothstep',
  style: {
    stroke: 'black'
  }
};

const nodeTypes = { custom: CustomNode };

const DataQuality = () => {
  const { dispatch } = useContext(Store);
  const history = useHistory();
  const location = useLocation();
  const [schemasMap, setSchemasMap] = useState({});
  const [schema, setSchema] = useState('');
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [open, setOpen] = useState(false);
  const [ruleTableClicked, setRuleTableClicked] = useState(null);
  const [loading, setLoading] = useState(false);
  const params = new URLSearchParams(location.search);
  const urlSchema = params.get('schema');

  const getSchemas = async (schemaName = '') => {
    setLoading(true);
    const response = await getSchemaList();
    if (response?.status !== 200) {
      await dispatch({
        type: actions.SET_ALERT,
        value: { status: true, message: response?.data?.Error ?? 'Error fetching schemas' }
      });
      return;
    }
    const schemaMap = response?.data?.Success;
    setSchemasMap(schemaMap);
    const schemaNames = Object.keys(schemaMap);
    let finalScehma = schemaName;
    if (isEmpty(schemaName) || !schemaNames.includes(schemaName))
      finalScehma = schemaNames.find(key => schemaMap[key]?.hasResult) ?? schemaNames[0];
    setSchema(finalScehma);
    setLoading(false);
  };

  const getResultData = async () => {
    const response = await getJSONResultData(schema);
    schemasMap[schema].result = response?.data?.Success;
    setSchemasMap(schemasMap);
  };

  const getNodeTableClicked = table => {
    setRuleTableClicked(table);
    setOpen(true);
  };

  const handleChange = event => {
    setSchema(event.target.value);
    history.replace({ pathname: `/data_quality`, search: `?schema=${event.target.value}` });
  };

  const nodeGenerator = () => {
    let newNodes = [];
    const newEdges = [];
    let results = [];
    if ('result' in schemasMap[schema]) results = Object.keys(schemasMap[schema]?.result);
    let tableCount = results.length;
    let maxCol = 0;
    const sourceCols = {};
    const targetCols = {};
    let maxWidth = 420;

    results.forEach(key => {
      const table = schemasMap[schema]?.result[key];
      const tableName = key;
      const columns = Object.keys(table?.columns);
      let allRules = schemasMap[schema]?.tables[tableName]?.rules;
      let pass = 0;
      let fail = 0;
      maxWidth = Math.max(
        maxWidth,
        columns.reduce((p, c) => (p.length > c.length ? p : c).length, 0) * 12
      );
      maxCol = Math.max(maxCol, columns.length * 60);
      const node = {
        id: key,
        type: 'custom',
        data: {
          table: key,
          rowCount: table.rowcount,
          columns: {},
          columnList: schemasMap[schema]?.tables[tableName]?.columns ?? [],
          ruleEditCallBack: getNodeTableClicked,
          isPending: false
        },
        position: {}
      };
      columns.forEach(columnName => {
        const column = table.columns[columnName];
        pass += column.pass.length ?? 0;
        fail += column.fail.length ?? 0;
        column.pass.concat(column?.fail).forEach(colData => {
          const refTable = colData?.reference?.table;
          if (colData?.rule === 'relation') {
            if (
              !newEdges.find(
                edge => edge.id === tableName + refTable || edge.id === refTable + tableName
              )
            ) {
              if (!(tableName in sourceCols)) sourceCols[tableName] = new Set();
              sourceCols[tableName].add(columnName);
              if (!(colData?.reference?.table in targetCols)) targetCols[refTable] = new Set();
              targetCols[refTable].add(colData.reference.column);
              newEdges.push({
                id: tableName + refTable,
                source: tableName,
                sourceHandle: columnName,
                target: refTable,
                targetHandle: colData?.reference?.column
              });
            }
          }
          // Eliminate rules that have results
          allRules = allRules.filter(
            x => !(x.column === columnName && x.condition === colData.rule)
          );
        });
        node.data.columns[columnName] = {
          passCount: column?.pass?.length ?? 0,
          failCount: column?.fail?.length ?? 0,
          totalCount: (column?.pass?.length ?? 0) + (column?.fail?.length ?? 0),
          source: false,
          target: false,
          ...column
        };
      });
      node.data.passCount = pass;
      node.data.failCount = fail;
      node.data.totalCount = pass + fail;
      newNodes.push(node);
    });

    const tablesWithPendingResults = Object.keys(schemasMap[schema]?.tables).filter(
      x => schemasMap[schema]?.tables[x]?.rules?.length !== 0 && !results.includes(x)
    );
    tableCount += tablesWithPendingResults.length;
    tablesWithPendingResults.forEach(table => {
      const { columns } = schemasMap[schema]?.tables[table] ?? [];
      const { rules } = schemasMap[schema]?.tables[table] ?? [];
      maxWidth = Math.max(
        maxWidth,
        columns.reduce((p, c) => (p.length > c.length ? p : c).length, 0) * 12,
        `${table} Pending`.length * 15
      );
      maxCol = Math.max(maxCol, columns.length * 50);

      const newColumns = {};
      columns.forEach(col => {
        newColumns[col] = {
          passCount: 0,
          failCount: 0,
          totalCount: 0,
          source: false,
          target: false
        };
      });
      const node = {
        id: table,
        type: 'custom',
        data: {
          table,
          rowCount: 0,
          isPending: true,
          columns: newColumns,
          ruleEditCallBack: getNodeTableClicked
        },
        position: {}
      };
      rules.forEach(rule => {
        if (rule.condition === 'relation') {
          const relatedTable = rule?.relatedTable ?? '';
          if (
            !newEdges.find(
              edge => edge.id === table + relatedTable || edge.id === relatedTable + table
            )
          ) {
            if (!(table in sourceCols)) sourceCols[table] = new Set();
            sourceCols[table].add(rule.column);
            if (!(relatedTable in targetCols)) targetCols[relatedTable] = new Set();
            targetCols[relatedTable].add(rule?.relatedColumn);
            newEdges.push({
              id: table + relatedTable,
              source: table,
              sourceHandle: rule.column,
              target: rule.relatedTable,
              targetHandle: rule.relatedColumn
            });
          }
        }
      });
      newNodes.push(node);
    });

    newNodes = newNodes.map((nodeVal, index) => {
      const split = newNodes.length < 5 ? false : index > tableCount / 2;
      const position = {
        x: split
          ? 250 + (index - Math.floor(tableCount / 2) - 1) * maxWidth
          : 250 + index * maxWidth,
        y: split ? 200 + maxCol : 25
      };
      if (nodeVal.id in sourceCols) {
        const tempNode = nodeVal;
        sourceCols[nodeVal.id].forEach(col => {
          tempNode.data.columns[col].source = true;
        });
      }
      if (nodeVal.id in targetCols) {
        const tempNode = nodeVal;
        targetCols[nodeVal.id].forEach(col => {
          tempNode.data.columns[col].target = true;
        });
      }
      return { ...nodeVal, position };
    });
    setNodes(newNodes);
    setEdges(newEdges);
  };

  const handleRuleOpen = () => {
    setRuleTableClicked(null);
    setOpen(true);
  };

  const handleRuleClose = (schemaName, tableName, newRules) => {
    if (schemaName && newRules && Array.isArray(newRules)) {
      const newSchemaList = { ...schemasMap };
      newSchemaList[schema].tables[tableName].rules = [
        ...newRules.map(({ suggested, ...item }) => item)
      ];
      setSchemasMap(newSchemaList);
      nodeGenerator();
    }
    setOpen(false);
  };

  useEffect(() => {
    const temp = async () => {
      setLoading(true);
      if (isEmpty(schema) || (!schemasMap[schema]?.hasResult && !schemasMap[schema]?.hasRules)) {
        setNodes([]);
        setEdges([]);
      } else {
        if (schemasMap[schema]?.hasResult) await getResultData();
        nodeGenerator();
      }
      setLoading(false);
    };
    temp();
  }, [schema]);

  useEffect(() => {
    getSchemas(urlSchema);
  }, []);

  return (
    <Box paddingTop='50px' height='100vh'>
      <div style={{ width: '100vw', height: '100%' }}>
        <ReactFlow
          nodes={loading ? [] : nodes}
          edges={loading ? [] : edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          defaultEdgeOptions={edgeOptions}
          nodeTypes={nodeTypes}
          fitView
          style={{
            backgroundColor: '#F1F6F8'
          }}
          nodesDraggable
          nodesConnectable={false}>
          <Panel position='top-left'>
            <Stack direction='column' spacing={1} alignItems='start' sx={{ mt: 2 }}>
              <Stack direction='row' spacing={2} alignItems='center'>
                <Typography variant='h5' color='primary' sx={styles.heading}>
                  Data Quality for
                </Typography>
                <FormControl sx={{ minWidth: 80 }}>
                  <InputLabel id='demo-simple-select-label'>Schema</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    value={schema}
                    label='Scehma'
                    sx={{ backdropFilter: 'blur(5px) saturate(50%)' }}
                    autoWidth
                    onChange={handleChange}>
                    {Object.keys(schemasMap).map(x => (
                      <MenuItem
                        key={`${x}-schema-select`}
                        value={x}
                        sx={{
                          color: schemasMap[x].hasResult ? palette.black.main : palette.gray.light
                        }}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              <Typography variant='h6' color='primary' sx={styles.subtitle}>
                {`Last Checked on: ${
                  !isEmpty(schemasMap[schema]?.date) ? schemasMap[schema]?.date : 'N/A'
                }`}
              </Typography>
            </Stack>
          </Panel>
          <Panel position='bottom-right'>
            <Fab
              color='primary'
              variant='extended'
              sx={styles.addTableFab}
              onClick={handleRuleOpen}>
              <Add sx={{ mr: 1 }} />
              Add Table
            </Fab>
          </Panel>
          {loading ? (
            <Panel position='top-center' style={{ top: '50%' }}>
              <Stack direction='row' spacing={3} alignItems='center'>
                <CircularProgress />
                <Typography variant='h4'>Loading</Typography>
              </Stack>
            </Panel>
          ) : null}
          <Controls />
          <Background />
        </ReactFlow>
      </div>
      {open && (
        <RuleView
          schema={schema}
          schemaData={schema ? schemasMap[schema].tables : {}}
          open={open}
          handleClose={handleRuleClose}
          tableName={ruleTableClicked}
        />
      )}
    </Box>
  );
};

export default DataQuality;
