import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';

const StyledTabs = styled(props => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />)({
  minHeight: '35px',
  minWidth: '160px',
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent'
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: '#635ee7'
  }
});

export const StyledTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme, activetab }) => ({
    borderRadius: '5px 5px 0px 0px',
    textTransform: 'capitalize',
    fontFamily: 'Nunito Sans',
    fontWeight: '600',
    boxShadow: '-5px 0px 6px #00000029',
    // minHeight: '35px',
    minWidth: '198px',
    height: '100%',
    padding: '0px 12px',
    fontSize: theme.typography.body1.fontSize,
    // height: 'max-content',

    background:
      activetab === 'true'
        ? `${theme.palette.primary.background}`
        : 'linear-gradient(102deg, #29ABE2 0%, #06C66C 100%)',
    color: activetab === 'true' ? theme.palette.gray.main : theme.palette.white.main,

    border: activetab === 'true' ? `0.5px solid ${theme.palette.gray.lightBorder}` : 'none',
    borderBottom:
      activetab === 'true'
        ? `1px solid ${theme.palette.primary.background} !important`
        : `0.5px solid ${theme.palette.gray.lightBorder}`,
    boderderTop:
      activetab === 'true' ? `0.5px solid ${theme.palette.gray.lightBorder} !important` : 'none',

    '&.Mui-selected': {
      color: theme.palette.gray.main,
      borderBottom: '0.5px solid',
      borderBottomColor: theme.palette.primary.background
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)'
    }
  })
);

export default StyledTabs;
