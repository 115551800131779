import React, { useContext, useState } from 'react';

// material ui
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

// styles
// context
import { useHistory } from 'react-router-dom';
import Actions from '../../store/actions';
import Styles from '../DataEntry/styles/DataEntry.styles';
import Store from '../../store';

const EMALabelCurationFetch = () => {
  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const [closeDate, setCloseDate] = useState('');
  const [applicationNumber, setApplicationNumber] = useState('');
  const [error, setError] = useState('');

  const handleChange = async e => {
    try {
      const appNumber = e.target.value;
      let [
        applicationNumber1,
        applicationNumber2,
        applicationNumber3,
        applicationNumber4,
        year,
        month,
        date
      ] = appNumber.split('-', 7);
      year = year.trim();
      month = month.trim();
      date = date.trim();
      applicationNumber1 = applicationNumber1.trim();
      applicationNumber2 = applicationNumber2.trim();
      applicationNumber3 = applicationNumber3.trim();
      applicationNumber4 = applicationNumber4.trim();
      if (
        year &&
        month &&
        date &&
        applicationNumber1 &&
        applicationNumber2 &&
        applicationNumber3 &&
        applicationNumber4
      ) {
        setError('');
        setCloseDate(`${year}-${month}-${date}`);
        setApplicationNumber(
          `${applicationNumber1}-${applicationNumber2}-${applicationNumber3}-${applicationNumber4}`
        );
      } else {
        setError('Invalid label');
      }
    } catch (err) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Enter valid information' }
      });
      history.push('/ema/labelEma');
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    history.push(`/ema/labelEma/${closeDate}/${applicationNumber}`);
  };
  return (
    <Box className={classes.root} display='flex' alignItems='center'>
      <Container maxWidth='lg'>
        <Paper elevation={1} className={classes.banner}>
          <form onSubmit={handleSubmit}>
            <Box margin={1} flexDirection='column'>
              <Typography align='left' className={classes.label}>
                Enter Key for EMA Label
              </Typography>
              <TextField
                required
                className={classes.textfield}
                onChange={handleChange}
                fullWidth
                id='ema-label'
                placeholder='Eg EMEA-H-C-001213-2021-12-21'
                InputProps={{
                  classes: { input: classes.input },
                  disableUnderline: true
                }}
              />
              {error && (
                <Typography variant='subtitle1' component='div' color='error'>
                  {error}
                </Typography>
              )}
            </Box>
            <Box padding={2} display='flex' justifyContent='center'>
              <Button
                type='submit'
                variant='contained'
                className={classes.button}
                disabled={!applicationNumber || !closeDate || error !== ''}>
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default EMALabelCurationFetch;
