import React, { useEffect } from 'react';

// muiv5
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';

// styles
import styles from '../styles';

interface DropDownProps {
  id: string;
  label: string;
  value: any;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: React.SyntheticEvent<{}>, newValue: string | null) => void;
  options: Array<{ value: string; label: string } | any>;
  multiple: boolean;
  freeText: boolean;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  validate: boolean;
}

const DropDown = ({
  id,
  label,
  onChange,
  options,
  value,
  multiple,
  freeText,
  setError,
  validate = true
}: DropDownProps) => {
  const [inputError, setInputError] = React.useState('');
  const [showError, setShowError] = React.useState(false);

  const isValid = (name: string): boolean => {
    return /[^\w\s]$/.test(name);
  };

  const getvalue = (text: string) => {
    if (text !== null && !multiple) {
      const selectedOption = options.find(option => option?.value === text);
      if (selectedOption) {
        return selectedOption;
      }
    }
    return text;
  };

  const setErrorAndMessage = (error: boolean, message: string) => {
    setError(error);
    setInputError(message);
  };

  const handleStringValidation = (newInputValue: string) => {
    if (!newInputValue) {
      setErrorAndMessage(true, 'Empty strings are not allowed.');
    } else if (isValid(newInputValue)) {
      setErrorAndMessage(true, 'Special characters are not allowed.');
    } else if (newInputValue.trim() !== newInputValue) {
      setErrorAndMessage(true, 'Trailing spaces are not allowed.');
    } else if (!validate) {
      setShowError(true);
      setErrorAndMessage(false, 'Material ID is missing. Please submit the form.');
    } else {
      setErrorAndMessage(false, '');
    }
  };

  const handleArrayValidation = (arr: string[]) => {
    if (arr.length === 0) {
      setErrorAndMessage(true, 'Value cannot be empty.');
    } else {
      setErrorAndMessage(false, '');
    }
  };

  const checkValidation = (newInputValue: any) => {
    if (typeof newInputValue === 'string') {
      handleStringValidation(newInputValue);
    } else if (Array.isArray(newInputValue)) {
      handleArrayValidation(newInputValue);
    } else {
      setErrorAndMessage(false, '');
    }
  };

  useEffect(() => {
    if (value && freeText) {
      const currentValue = getvalue(value);
      let newValue = getvalue(value);
      if (typeof currentValue === 'object' && !Array.isArray(currentValue)) {
        newValue = currentValue.value;
      } else {
        newValue = currentValue;
      }
      checkValidation(newValue);
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<{}>, newInputValue: any) => {
    checkValidation(newInputValue);
    let newvalue = newInputValue;
    const selectedOption = options.find(option => option.label === newInputValue);
    if (selectedOption) {
      newvalue = selectedOption;
    }
    onChange(event, newvalue);
  };
  return (
    <Autocomplete
      id={id}
      fullWidth
      value={getvalue(value)}
      freeSolo={freeText || multiple}
      multiple={multiple}
      options={options}
      onChange={onChange}
      sx={{ fontSize: '2vh' }}
      ChipProps={
        multiple
          ? {
              sx: styles.autocompleteChip
            }
          : {}
      }
      onInputChange={freeText ? handleInputChange : undefined}
      renderInput={(params: any) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={label}
          variant='outlined'
          error={showError ? false : Boolean(inputError)}
          helperText={inputError}
          sx={{
            '& .MuiFormHelperText-root': {
              color: !validate && inputError ? '#FFA500' : undefined // Change helper text color to warning if error exists
            }
          }}
        />
      )}
    />
  );
};

export default React.memo(DropDown);
