import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// material ui
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// api
// styles
import Styles from './styles/Dashboard.styles';
import { isHealthy } from '../../api/AdminPage';

const Dashboard = () => {
  const classes = Styles();
  const { dispatch } = useContext(Store);
  const history = useHistory();
  const [draftCount, setDraftCount] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = await Auth.currentUserInfo();
        if (user.attributes['custom:role'] !== 'Admin') {
          history.push('/');
        }
        const health = await isHealthy();
        setDraftCount(health.data.draftable_api_count);
        await dispatch({ type: Actions.SET_MODULE, value: 'admin' });
      } catch (error) {
        console.error("=>(Dashboard.jsx:39) error", error);
      }
    };
    fetchData();
  }, [dispatch, history]);

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <Grid container>
          <Grid item xs={12} lg={4}>
            <Box display='flex'>
              <Typography className={classes.heading}>Draftable Count</Typography>
            </Box>
            <Paper elevation={2} className={classes.paper}>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.paperBox}>
                <Typography className={classes.cnt}>{draftCount && draftCount}</Typography>
                <Typography className={classes.cnt}>times</Typography>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Dashboard;
