import React, { useEffect, useState } from 'react';
// MATERIAL UI IMPORTS
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import Link from '@material-ui/core/Link';
// assets
// styles
import StatusStyles from './styles/Status.styles';
// api
import { isHealthy } from '../../api/AdminPage';

const Status = () => {
  const classes = StatusStyles();
  const [health, setHealth] = useState();

  useEffect(() => {
    async function getData() {
      try {
        const response = await isHealthy();
        setHealth(response.data);
      } catch (e) {
        console.log(e);
      }
    }

    getData();
  }, []);

  return (
    <Box className={classes.root}>
      {/* <AppBar position="static" className={classes.navbar}>
        <Container>
          <Toolbar>
            <Link href="/">
              <img src={Viv321} className={classes.vivLogo} alt="logo" />
            </Link>
            <Divider orientation="vertical" />
            <Typography className={classes.navText}>Status</Typography>
          </Toolbar>
        </Container>
      </AppBar> */}
      <Container className={classes.container}>
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          className={classes.header}>
          <Box>
            {health && health.final_status === 'UP' ? (
              <CheckCircleRoundedIcon className={classes.icon} fontSize='small' />
            ) : (
              <RemoveCircleRoundedIcon className={classes.errorIcon} fontSize='small' />
            )}
          </Box>
          {health && health.final_status === 'UP' ? (
            <>
              <Typography className={classes.heading}>Ria is up and running</Typography>
              <Typography className={classes.subheading}>
                Click <Link href='/'>here</Link> to go back
              </Typography>
            </>
          ) : (
            <Typography className={classes.heading}>Ria is facing some issues</Typography>
          )}
        </Box>
        <Box className={classes.table}>
          <TableContainer component={Paper}>
            <Table aria-label='customized table'>
              <TableHead className={classes.tableHeader}>
                <TableRow>
                  <TableCell className={classes.tableHeading}>Current Status by Service</TableCell>
                  <TableCell className={classes.tableHeading} align='right'>
                    <Box
                      display='flex'
                      flexDirection='row'
                      justifyContent='flex-end'
                      alignItems='center'>
                      <CheckCircleRoundedIcon className={classes.tableIcon} fontSize='small' />
                      <Typography className={classes.legends}>No issues</Typography>
                      <RemoveCircleRoundedIcon
                        className={classes.tableErrorIcon}
                        fontSize='small'
                      />
                      <Typography>Outage</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className={classes.tbBody}>
                <TableRow>
                  <TableCell className={classes.tbText}>Backend Server</TableCell>
                  <TableCell align='right'>
                    {health && health.health === 'ok' ? (
                      <CheckCircleRoundedIcon className={classes.tableIcon} fontSize='small' />
                    ) : (
                      <RemoveCircleRoundedIcon
                        className={classes.tableErrorIcon}
                        fontSize='small'
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tbText}>Elastic Search</TableCell>
                  <TableCell align='right'>
                    {health && health.elasticSearch && health.elasticSearch.status === 'UP' ? (
                      <CheckCircleRoundedIcon className={classes.tableIcon} fontSize='small' />
                    ) : (
                      <RemoveCircleRoundedIcon
                        className={classes.tableErrorIcon}
                        fontSize='small'
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tbText}>Database</TableCell>
                  <TableCell align='right'>
                    {health && health.database && health.database.status === 'UP' ? (
                      <CheckCircleRoundedIcon className={classes.tableIcon} fontSize='small' />
                    ) : (
                      <RemoveCircleRoundedIcon
                        className={classes.tableErrorIcon}
                        fontSize='small'
                      />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className={classes.tbText}>Kendra Index</TableCell>
                  <TableCell align='right'>
                    {health && health.kendra && health.kendra.status === 'UP' ? (
                      <CheckCircleRoundedIcon className={classes.tableIcon} fontSize='small' />
                    ) : (
                      <RemoveCircleRoundedIcon
                        className={classes.tableErrorIcon}
                        fontSize='small'
                      />
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Container>
    </Box>
  );
};

export default Status;
