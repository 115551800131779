import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';

import { StateProvider } from './store';
import { AuthProvider } from './store/Auth/AuthProvider';

import ErrorBoundary from './Error/ErrorBoundary';

import Login from './pages/Login';
import ForgotPasswordPage from './pages/Login/components/ForgotPasswordPage';
import AdminLayout from './Layouts/AdminLayout';
import PrivateRoute from './PrivateRoutes';
import ADMIN_ROUTES from './routes/routes';
import AlertMessage from './components/Alerts';

// Staging
const App = () => (
  <div className='App'>
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <AuthProvider>
          <StateProvider>
            <AlertMessage />
            <Router>
              <Switch>
                <Route key='loginPage' exact path='/login' component={Login} />
                <Route key='resetPassword' exact path='/reset' component={ForgotPasswordPage} />
                <Route>
                  <AdminLayout>
                    {ADMIN_ROUTES.map(({ id, exact, path, component }) => (
                      <PrivateRoute key={id} exact={exact} path={path} component={component} />
                    ))}
                  </AdminLayout>
                </Route>
                {/* <Route key='404' exact path='*' component={ErrorPageNotFound} /> */}
              </Switch>
            </Router>
          </StateProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ThemeProvider>
  </div>
);

export default App;
