import { ResponsiveBar } from '@nivo/bar';

const BarTotalsLayer = (props: any) => {
  const labelOffset = 10;
  const labelFontSize = 12;
  const { bars } = props;
  if (!bars || bars?.length === 0) return null;
  // compute totals for each index/bar
  const totals = {} as any;
  const bandwidth = bars[0].width;
  bars.forEach((bar: any) => {
    const { indexValue } = bar.data as any;
    if (!(indexValue in totals)) {
      totals[indexValue] = 0;
    }
    if (!bar.data.hidden) {
      totals[indexValue] += bar.data.value;
    }
  });
  // place text elements above the bars
  const labels = Object.keys(totals).map(indexValue => {
    // eslint-disable-next-line react/destructuring-assignment
    const x = props.xScale(indexValue) + bandwidth / 2;
    // eslint-disable-next-line react/destructuring-assignment
    const y = props.yScale(totals[indexValue]) - labelOffset;
    return (
      <text
        key={`total.${indexValue}`}
        x={x}
        y={y}
        textAnchor='middle'
        fontWeight='bold'
        fontSize={labelFontSize}>
        {totals[indexValue]}
      </text>
    );
  });
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{labels}</>;
};

interface ChatRiaBarChartProps {
  data: any;
  barClickCallBack: any;
  keys: string[];
}

const StatsBarChart = ({ data, barClickCallBack, keys }: ChatRiaBarChartProps) => {
  return (
    <ResponsiveBar
      data={data}
      enableGridY
      //   keys={}
      keys={keys}
      indexBy='formattedDate'
      margin={{ top: 30, right: 200, bottom: 100, left: 50 }}
      colors={['#6868FA', '#EA5252', '#29ABE2', '#0A467C', '#C9D2F6', '#B1BBE4', '#48E0A6']}
      padding={0.4}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      layout='vertical'
      colorBy='id'
      isInteractive
      onMouseEnter={(_datum, event) => {
        if (barClickCallBack !== null)
          // eslint-disable-next-line no-param-reassign
          event.currentTarget.style.cursor = 'pointer';
      }}
      theme={{
        axis: {
          ticks: {
            line: {
              stroke: '#555555'
            },
            text: {
              fill: '#1FBDCA',
              fontWeight: 'bold',
              fontSize: '15px'
            }
          }
        }
      }}
      groupMode='stacked'
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 90,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 0
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 0
      }}
      enableLabel
      labelSkipWidth={12}
      labelSkipHeight={12}
      layers={['grid', 'axes', 'markers', 'bars', 'legends', 'annotations', BarTotalsLayer]}
      labelTextColor='#fff'
      borderColor={{
        from: 'color',
        modifiers: [['darker', 1.6]]
      }}
      legends={[
        {
          anchor: 'top-right',
          dataFrom: 'keys',
          direction: 'column',
          itemHeight: 20,
          itemWidth: 80,
          toggleSerie: true,
          translateX: 100,
          translateY: 0,
          itemsSpacing: 5,
          symbolSize: 20,
          itemOpacity: 0.85,
          symbolShape: 'circle',
          effects: [
            {
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }
          ]
        }
      ]}
      onClick={barClickedData => barClickCallBack && barClickCallBack(barClickedData)}
    />
  );
};

export default StatsBarChart;
