import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Box from '@mui/material/Box';

const SelectAutoWidth = ({ value, label, onChange, options, sx = {} }: any) => {
  return (
    <Box>
      <FormControl sx={{ m: 1, minWidth: 200, ...sx }}>
        <InputLabel id='select-autowidth-label'>{label}</InputLabel>
        <Select
          labelId='select-autowidth-label'
          id='select-autowidth'
          value={value}
          onChange={onChange}
          label={label}>
          {options.map((opt: any) => (
            <MenuItem value={opt.value} key={opt.value}>
              {opt.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default React.memo(SelectAutoWidth);
