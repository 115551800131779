import makeRequest from './client';

export const getCderLabelsList = (
  page: number,
  perPage: number,
  status: string,
  applicationNumber: string,
  curationTask: string,
  date: string,
  submissionType: string,
  exactSearch = ''
) => {
  const baseURL = `/cder/labels`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (status !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  if (curationTask !== '0') {
    queryParams.push(`curation_task=${curationTask}`);
  }
  if (date) {
    queryParams.push(`created_at=${date}`);
  }
  if (submissionType !== 'All') {
    queryParams.push(`submission_type=${submissionType}`);
  }
  if (exactSearch) {
    queryParams.push('exact_search=yes');
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const fetchLabelCurationDetails = (documentId: string) => {
  return makeRequest(`/cder/labels/curation/${documentId}`, 'GET');
};

export const fetchLimitationCurationDetails = (documentId: string) => {
  return makeRequest(`/cder/labels/indication_metadata/${documentId}`, 'GET');
};

export const submitLimitationCurationDetails = (documentId: string, payload: any) => {
  return makeRequest(`/cder/labels/indication_metadata/${documentId}`, 'POST', payload);
};

export const submitLabelCurationDetails = (documentId: string, payload: any) => {
  return makeRequest(`/cder/labels/curation/${documentId}`, 'POST', payload);
};

export const fetchLabelPageCurationDetails = (documentId: string) => {
  return makeRequest(`/cder/labels/page_numbers/${documentId}`, 'GET');
};

export const submitLabelPageCurationDetails = (
  docType: string,
  documentId: string,
  payload: any
) => {
  return makeRequest(`/cder/labels/page_numbers/${docType}/qc/${documentId}`, 'POST', payload);
};

export const getCderLettersList = (
  page: number,
  perPage: number,
  status: string,
  applicationNumber: string,
  curationTask: string,
  createAt: string,
  submissionType: string
) => {
  const baseURL = `/cder/letters`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (status !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  if (curationTask !== '0') {
    queryParams.push(`curation_task=${curationTask}`);
  }
  if (createAt) {
    queryParams.push(`created_at=${createAt}`);
  }
  if (submissionType !== 'All') {
    queryParams.push(`submission_type=${submissionType}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const fetchLetterCurationDetails = (documentId: string) => {
  return makeRequest(`/cder/letters/curation/${documentId}`, 'GET');
};

export const submitLetterCurationDetails = (documentId: string, payload: any) => {
  return makeRequest(`/cder/letters/qc/${documentId}`, 'POST', payload);
};

export const fetchLetterPmrCurationDetails = (documentId: string) => {
  return makeRequest(`/cder/letters/pmrc/requirements/${documentId}`, 'GET');
};

export const submitLetterPmrCurationDetails = (documentId: string, payload: any) => {
  return makeRequest(`/cder/letters/pmrc/${documentId}`, 'POST', payload);
};

export const submitLetterPmrRequirementCurationDetails = (documentId: string, payload: any) => {
  return makeRequest(`/cder/letters/pmrc/requirements/${documentId}`, 'POST', payload);
};

export const deleteLetterPmrRequirementCurationDetails = (documentId: string, payload: any) => {
  return makeRequest(`/cder/letters/pmrc/requirements/${documentId}`, 'DELETE', payload);
};

export const fetchLabelSectionDetails = (documentId: string) => {
  return makeRequest(`/cder/labels/get-label/${documentId}`, 'GET');
};

export const submitNewLabelDetails = (payload: any) =>
  makeRequest('/cder/labels/label_section', 'POST', payload);

export const fetchLabelProcessedDetails = (type: string, documentId: string) => {
  return makeRequest(`/cder/labels/processed/${type}/${documentId}`, 'GET');
};

export const submitLabelProcessedDetails = (type: string, documentId: string, payload: any) => {
  return makeRequest(`/cder/labels/processed/${type}/${documentId}`, 'POST', payload);
};

export const fetchCurationDetails = () => {
  return makeRequest(`/cder/curation`, 'GET');
};

export const updateCurationDetails = (payload: any) => {
  return makeRequest(`/cder/curation`, 'POST', payload);
};

export const getPediatricIndications = (
  page: Number,
  pageSize: Number,
  qcStatus: string,
  indicationText: string,
  latestIndication: boolean
) => {
  const baseURL = `/cder/pediatrics/indications`;
  const queryParams = [`page=${page}`, `page_size=${pageSize}`];

  if (qcStatus) {
    queryParams.push(`qc_status=${qcStatus}`);
  }
  if (indicationText) {
    queryParams.push(`indication=${indicationText}`);
  }
  if (latestIndication) {
    queryParams.push(`latest_indication=${latestIndication}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const submitPediatricIndicationsAgeGroup = (id: number, payload: any) => {
  return makeRequest(`/cder/pediatrics/indications/${id}`, 'PUT', payload);
};

export const getInvalidPdfData = (
  page: Number,
  pageSize: Number,
  qcStatus: string,
  applicationNumber: string
) => {
  const baseURL = `/cder/invalid-files`;
  const queryParams = [`page=${page}`, `page_size=${pageSize}`];

  if (qcStatus) {
    queryParams.push(`qc_status=${qcStatus}`);
  }
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const updatefileUrl = (payload: any) => makeRequest(`/cder/invalid-files`, 'POST', payload);

export const getApprovalStatusList = (
  page: number,
  perPage: number,
  status: string,
  applicationNumber: string,
  center: string,
  approvalStatusType: string
) => {
  const baseURL = `/approval_status/letters/${approvalStatusType}`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (status !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  if (center) {
    queryParams.push(`center=${center}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const getApprovalStatusApplicationDetails = (
  center: string,
  approvalStatusType: string,
  entityId: string
) => {
  const baseURL = `/approval_status/curated/${approvalStatusType}`;
  const queryParams = [];
  if (center) {
    queryParams.push(`center=${center}`);
  }
  if (entityId) {
    queryParams.push(`entity_id=${entityId}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const submitApprovalStatusDetails = (id: string, approvalStatusType: string, payload: any) =>
  makeRequest(`/approval_status/letters/${approvalStatusType}/${id}`, 'POST', payload);
export const getApplicationDetails = (id: string) =>
  makeRequest(`/cder/pediatrics/application-details/${id}`, 'GET');
