import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

// material ui
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import TextField from '@material-ui/core/TextField';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// styles
import Styles from '../DataEntry/styles/DataEntry.styles';

const LabelSection = () => {
  const classes = Styles();
  const history = useHistory();
  const { dispatch } = useContext(Store);
  const docType = 'Label';
  const [applNo, setApplNo] = useState();
  const [subNo, setSubNo] = useState();
  const [subType, setSubType] = useState('SUPPL');
  const [key, setKey] = useState();

  const handleKeyChange = async event => {
    try {
      const text = event.target.value;
      const keyArray = text.split('-');
      setApplNo(keyArray[0]);
      setSubType(keyArray[1].toUpperCase());
      setSubNo(keyArray[2]);
      setKey(text);
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Enter valid information' }
      });
      history.push('/fda/label/section');
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    history.push(`/fda/label/section/${applNo}/${subType}/${subNo}/${docType}`);
  };

  return (
    <Box className={classes.root} display='flex' alignItems='center'>
      <Container maxWidth='lg' className={classes.container}>
        <Paper elevation={1} className={classes.banner}>
          <form onSubmit={handleSubmit}>
            <Box margin={1} flexDirection='column'>
              <Typography align='left' className={classes.label}>
                Enter Key for Label
              </Typography>
              <TextField
                required
                className={classes.textfield}
                onChange={handleKeyChange}
                fullWidth
                id='standard-basic'
                placeholder='Eg: 000000-SUPPL-20'
                InputProps={{
                  classes: { input: classes.input },
                  disableUnderline: true
                }}
              />
            </Box>
            <Box padding={2} display='flex' justifyContent='center'>
              <Button type='submit' variant='contained' className={classes.button}>
                Submit
              </Button>
            </Box>
          </form>
        </Paper>
      </Container>
    </Box>
  );
};

export default LabelSection;
