import React from 'react';
import { useParams } from 'react-router-dom';
import PageNumberReviewPage from './PageNumberReviewPage';
import ReviewPage from './ReviewPage';

const QCDocumentCheck = () => {
  const { applNo, subType, subNo, docType } = useParams();

  switch (docType.toLowerCase().trim()) {
    case 'letter':
      return <ReviewPage applNo={applNo} subType={subType} subNo={subNo} docType={docType} />;
    case 'label':
      return (
        <PageNumberReviewPage applNo={applNo} subType={subType} subNo={subNo} docType={docType} />
      );
    default:
      return null;
  }
};

export default QCDocumentCheck;
