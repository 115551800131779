import makeRequest from './client';

const getIndications = (applno: string, submissionType: string, submissionNumber: string) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/indication`,
    `GET`
  );

export const addIndication = (payload: any) =>
  makeRequest(`/submission_details/snapshot/indication`, `POST`, payload);

export const duplicateIndication = (payload: any) =>
  makeRequest(`/submission_details/snapshot/indication/copy`, `POST`, payload);

export const deleteIndication = (
  applno: string,
  submissionType: string,
  submissionNumber: number,
  indicationId: number
) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/indication/${indicationId}`,
    `DELETE`
  );

export const updateIndication = (
  applno: string,
  submissionType: string,
  submissionNumber: number,
  indicationId: number,
  payload: any
) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/indication/${indicationId}`,
    `PUT`,
    payload
  );

export const updateIndicationStale = (
  applno: string,
  submissionType: string,
  submissionNumber: number,
  indicationId: number,
  payload: any
) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/indication/${indicationId}/stale`,
    `PUT`,
    payload
  );

export const copyStaleIndication = (
  applno: string,
  submissionType: string,
  submissionNumber: number,
  payload: any
) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/indication/stale/copy`,
    `PUT`,
    payload
  );

export const addStudyData = (
  applno: string,
  submissionType: string,
  submissionNumber: number,
  indicationId: number,
  payload: any
) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/indication/${indicationId}/study`,
    `POST`,
    payload
  );

export const deleteStudy = (
  applno: string,
  submissionType: string,
  submissionNumber: number,
  indicationId: number,
  studyId: number
) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/indication/${indicationId}/study/${studyId}`,
    `DELETE`
  );

export const updateStudyData = (
  applno: string,
  submissionType: string,
  submissionNumber: number,
  indicationId: number,
  studyId: number,
  payload: any
) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/indication/${indicationId}/study/${studyId}`,
    `PUT`,
    payload
  );

export const getLabel = (applno: string, submissionType: string, submissionNumber: number) =>
  makeRequest(`/submission_details/${applno}/${submissionType}/${submissionNumber}/label`, `GET`);

export const getAttributes = () =>
  makeRequest(`/submission_details/snapshot/study/attributes`, `GET`);

export const getEndpointValues = () =>
  makeRequest(`/submission_details/snapshot/study/attributevalues/all`, `GET`);

export const updateAppQcStatus = (
  applno: string,
  submissionType: string,
  submissionNumber: number,
  payload: any
) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/qc_status`,
    `POST`,
    payload
  );
export const getAppQcStatus = (applno: string, submissionType: string, submissionNumber: number) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/qc_status`,
    `GET`
  );

export const getSBASAttributes = () =>
  makeRequest(`/submission_details/snapshot/sbas/attributes`, `GET`);
export const getSBASDocumentTypeByProps = (name: string) =>
  makeRequest(`/submission_details/snapshot/sbas_document_type_by_props/${name}`, 'GET');

export const addSBASAttribueData = (payload: any) =>
  makeRequest(`/submission_details/snapshot/sbas/attribute_data`, `POST`, payload);

export const getSBASAttributeData = (
  applno: number,
  submissionType: string,
  submissionNumber: number,
  documentTypeId: number,
  attributeId: number
) =>
  makeRequest(
    `/submission_details/snapshot/${applno}/${submissionType}/${submissionNumber}/sbas?document_type_id=${documentTypeId}&attribute_id=${attributeId}`,
    `GET`
  );

export const deleteSBASAttributeData = (id: number) =>
  makeRequest(`/submission_details/snapshot/sbas/attribute_data/${id}`, `DELETE`);

export const updateSBASAttributeData = (id: number, payload: any) =>
  makeRequest(`/submission_details/snapshot/sbas/attribute_data/${id}`, `PUT`, payload);

export const getSnapshotApplications = async (
  pageNo: number,
  pageSize: number,
  qcStatus: string,
  applicationNumber: string
) => {
  let path = `/submission_details/snapshot/applications?page_size=${pageSize}&page=${pageNo}&qc_status=${qcStatus}`;
  if (applicationNumber) {
    path += `&application_number=${applicationNumber}`;
  }
  return makeRequest(path, `GET`);
};

export default getIndications;
