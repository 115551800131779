export const getSortedLabels = (
  labelInfo: { id: string; labelName: string }[],
  labelData: { [key: string]: any }
) => {
  return labelInfo.sort((a, b) => {
    const aValue = labelData[a.id];
    const bValue = labelData[b.id];
    if (aValue === '0') {
      // a value is 0, move it to the end
      return 1;
    }
    if (bValue === '0') {
      // b value is 0, move it to the end
      return -1;
    }
    // sort by non-zero value
    return aValue - bValue;
  });
};

export const qcStatuses = ['Escalate', 'Non Qced', 'Qced', 'Automated'];

export const DefaultProductResourcePageCurationFields = [
  { id: 'warnings', labelName: 'Warnings' },
  { id: 'contraindications', labelName: 'Contraindications' },
  { id: 'composition_and_product_description', labelName: 'Composition and Product Description' },
  { id: 'indications', labelName: 'Indications' },
  { id: 'precautions_concerning_indications', labelName: 'Precautions Concerning Indications' },
  { id: 'dosage_and_administration', labelName: 'Dosage and Administration' },
  {
    id: 'precautions_concerning_dosage_and_administration',
    labelName: 'Precautions Concerning Dosage and Administration'
  },
  { id: 'important_precautions', labelName: 'Important Precautions' },
  {
    id: 'precautions_concerning_patients_with_specific_backgrounds',
    labelName: 'Precautions Concerning Patients With Specific Backgrounds'
  },
  { id: 'interactions', labelName: 'Interactions' },
  { id: 'adverse_reactions', labelName: 'Adverse Reactions' },
  { id: 'influence_on_laboratory_tests', labelName: 'Influence on Laboratory Tests' },
  { id: 'overdosage', labelName: 'Overdosage' },
  { id: 'precautions_concerning_use', labelName: 'Precautions Concerning Use' },
  { id: 'other_precautions', labelName: 'Other Precautions' },
  { id: 'pharmacokinetics', labelName: 'Pharmacokinetics' },
  { id: 'clinical_studies', labelName: 'Clinical Studies' },
  { id: 'pharmacology', labelName: 'Pharmacology' },
  { id: 'physicomechanical_properties', labelName: 'Physicomechanical Properties' },
  { id: 'precautions_for_handling', labelName: 'Precautions for Handling' },
  { id: 'approval_conditions', labelName: 'Approval Conditions' },
  { id: 'packaging', labelName: 'Packaging' },
  { id: 'references', labelName: 'References' },
  {
    id: 'reference_request_and_contact_information',
    labelName: 'Reference Request and Contact Information'
  },
  {
    id: 'precaution_concerning_health_insurance_benefits',
    labelName: 'Precaution Concerning Health Insurance Benefits'
  },
  { id: 'marketing_authorization_holder_etc', labelName: 'Marketing Authorization Holder, Etc.' }
];
