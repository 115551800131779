import UserUpload from '../components/UserUpload/UserUpload';

const USER_UPLOAD_ROUTES = [
  {
    id: 'user_upload',
    path: '/user_upload',
    component: UserUpload,
    exact: true
  }
];
export default USER_UPLOAD_ROUTES;
