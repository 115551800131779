import ChatRIAStats from '../components/ChatRIA/ChatRIAStats/ChatRIAStats';

const CHAT_RIA_STATS_ROUTES = [
  {
    id: 'chatRIAStats',
    path: '/chatRIAStats/:days/:group',
    component: ChatRIAStats,
    exact: true
  }
];
export default CHAT_RIA_STATS_ROUTES;
