import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import React, { useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Auth } from 'aws-amplify';
import styles from './styles/ApprovalStatusList.styles';
import APPROVAL_STATUS_METADATA from './constant';
import ApprovalEditDialog from './ApprovalEditDialog';
import { submitApprovalStatusDetails } from '../../api/Cder';

const ApprovalStatusList = ({ applData, approvalStatusType, closeEdit }: any) => {
  const [editMode, setEditMode] = useState<boolean>(false);
  const [editRow, setEditRow] = useState<any>({});
  const [qcStatus, setQcStatus] = useState(applData?.qc_status ?? 'qced');

  const handleClose = () => {
    closeEdit();
  };

  const handleEdit = (data: any) => {
    setEditMode(true);
    setEditRow(data);
  };

  const handleQcStatusUpdate = async () => {
    try {
      const user = await Auth.currentUserInfo();
      const payload = {
        qc_status: qcStatus.toLowerCase(),
        curator_email: user.attributes.email,
        center: applData?.center,
        accelerated_approval: qcStatus.toLowerCase() === 'qced',
        curator_name: user.attributes['custom:user']
      };
      await submitApprovalStatusDetails(
        applData?.application_docs_id ?? applData?.document_id,
        approvalStatusType,
        payload
      );
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
    closeEdit();
  };

  const handleCloseEdit = async () => {
    setEditMode(false);
    closeEdit();
  };

  return (
    <Container sx={styles.root}>
      <div>
        <DialogTitle sx={{ m: 0 }}>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography component='h2' variant='h5' sx={styles.Heading}>
              {applData?.title}
            </Typography>
            {!editMode ? (
              <Button
                onClick={() => {
                  handleEdit({
                    appl_title: applData?.title,
                    appl_num: applData?.application_number,
                    submission_type: applData?.submission_type,
                    submission_num: applData?.submission_number ?? applData?.submission_no
                  });
                }}
                variant='contained'
                sx={styles.submit}
                style={{ margin: '0 0 0 -10px' }}>
                Add Record
              </Button>
            ) : null}
            <IconButton
              aria-label='close'
              onClick={() => {
                handleClose();
              }}
              sx={styles.iconButton}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <Grid container spacing={2} display='flex'>
          <Grid item xs={12} lg={6}>
            {applData?.document_link ? (
              <Box display='flex' justifyContent='center'>
                <iframe
                  title='pdf'
                  src={applData?.document_link}
                  style={{
                    height: '60vh',
                    width: '50vw',
                    marginTop: '20px',
                    position: 'relative'
                  }}
                />
              </Box>
            ) : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{ height: '100%', width: '100%' }}>
                <Typography style={{ fontSize: '20px', fontWeight: 'bold', color: '#33B187' }}>
                  Can&apos;t Open This PDF
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='space-around'
              style={{ padding: '10px 10px' }}>
              <Box
                style={{
                  overflowY: 'auto',
                  maxHeight: '60vh',
                  padding: '4px',
                  border: '4px solid #d6d6d6',
                  borderRadius: '4px'
                }}>
                {applData?.approval_records?.fda_data_qc &&
                applData?.approval_records?.fda_data_qc?.length ? (
                  applData?.approval_records?.fda_data_qc.map((elem: any) => (
                    <Card sx={{ display: 'flex' }} style={{ margin: '10px 0' }} key={elem.id}>
                      <Box
                        sx={styles.reviewBox}
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          position: 'relative'
                        }}>
                        <Button
                          size='small'
                          type='button'
                          variant='contained'
                          onClick={() => {
                            handleEdit(elem);
                          }}
                          color='primary'
                          sx={styles.submit}
                          style={{
                            position: 'absolute',
                            right: 15,
                            top: 0,
                            color: 'gray.light'
                          }}>
                          Edit
                        </Button>
                        {APPROVAL_STATUS_METADATA[approvalStatusType].map((metadata: any) => (
                          <Box padding={1}>
                            <Typography align='left' sx={styles.label}>
                              {metadata?.label}
                            </Typography>
                            <Typography align='left' sx={styles.details}>
                              {elem[metadata?.id]}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </Card>
                  ))
                ) : (
                  <Card style={{ margin: '10px 0', padding: '10px' }}>
                    <Box>
                      <Typography>No Data found</Typography>
                    </Box>
                  </Card>
                )}
              </Box>
            </Box>
            <Box style={{ marginTop: '10px', display: 'flex', justifyContent: 'space-between' }}>
              <Box style={{ width: '45%' }}>
                <InputLabel id='qc-status-label'>QC Status</InputLabel>
                <TextField
                  variant='outlined'
                  id='qc_status'
                  label='QC Status'
                  fullWidth
                  select
                  required
                  value={qcStatus}
                  onChange={e => {
                    setQcStatus(e.target.value);
                  }}
                  InputProps={{
                    style: { fontSize: '1.5vh', minWidth: '250px' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '1.5vh' }
                  }}>
                  <MenuItem value='qced'>Qced</MenuItem>
                  <MenuItem value='to_qc'>To QC</MenuItem>
                  <MenuItem value='escalate'>Escalate</MenuItem>
                </TextField>
              </Box>
              <Button
                type='submit'
                variant='contained'
                color='primary'
                sx={styles.submit}
                onClick={handleQcStatusUpdate}>
                SUBMIT
              </Button>
            </Box>
          </Grid>
        </Grid>
        <Dialog
          open={editMode}
          maxWidth='lg'
          onClose={() => handleCloseEdit}
          sx={{ backdropFilter: 'blur(5px)' }}>
          <Grid container spacing={2} display='flex'>
            <Grid item xs={12} lg={6}>
              {applData?.document_link ? null : (
                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  style={{ height: '75vh', width: '50vw' }}>
                  <Typography style={{ fontSize: '20px', fontWeight: 'bold', color: '#33B187' }}>
                    Can&apos;t Open This PDF{' '}
                  </Typography>
                </Box>
              )}
              {applData?.document_link ? (
                <Box display='flex' justifyContent='center'>
                  <iframe
                    title='pdf'
                    src={applData?.document_link}
                    style={{
                      height: '75vh',
                      width: '50vw',
                      marginTop: '20px',
                      position: 'relative'
                    }}
                  />
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={12} lg={6}>
              {approvalStatusType === 'accelerated' && (
                <ApprovalEditDialog
                  id={editRow?.id}
                  appl_title={editRow?.title}
                  appl_num={editRow?.appl_num}
                  submission_type={editRow?.submission_type}
                  submission_num={editRow?.submission_num}
                  aa_date={editRow?.aa_date ?? null}
                  aa_indication={editRow?.aa_indication}
                  conversion_withdrawal_status={editRow?.conversion_withdrawal_status}
                  full_approval_conversion_withdrawal_date={
                    editRow?.full_approval_conversion_withdrawal_date ?? null
                  }
                  status={editRow?.status}
                  closeEdit={handleCloseEdit}
                  docType='accelerated'
                />
              )}
            </Grid>
          </Grid>
        </Dialog>
      </div>
    </Container>
  );
};

export default React.memo(ApprovalStatusList);
