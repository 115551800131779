import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid
} from '@mui/material';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { GetField } from './PMDAEditDialog';

const ActiveIngredientData = ({ productData, setProductData, handleNext }: any) => {
  const [openSubmitConfirmation, setOpenSubmitConfirmation] = useState(false);
  const [localProductData, setLocalProductData] = useState<any>(productData);
  const handleOpenPopup = async () => {
    const text = localProductData?.trade_name_jp;
    navigator?.clipboard?.writeText(`${text}`);
    let highlightedText = `#:~:text=${text}`;
    // url encode the text
    highlightedText = encodeURI(highlightedText);
    const link = `https://www.pmda.go.jp/PmdaSearch/iyakuDetail/GeneralList/${localProductData?.active_ingredient_id}${highlightedText}`;
    setOpenSubmitConfirmation(false);
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    window.open(link, '_blank', option);
  };
  useEffect(() => {
    if (productData) {
      setLocalProductData(productData);
    }
  }, [productData]);
  useEffect(() => {
    if (localProductData) {
      if (!localProductData?.bookmark_of_medicine && localProductData?.active_ingredient_id) {
        handleOpenPopup();
      } else {
        // handleNext();
      }
    }
  }, [localProductData, handleNext]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} mb={3} mt={3}>
          <Box p={2} maxHeight='85vh' overflow='auto'>
            <GetField
              label='bookmark_of_medicine'
              value={localProductData?.bookmark_of_medicine}
              setLocalProductData={setLocalProductData}
            />

            <Stack
              direction='row'
              justifyContent='space-between'
              sx={{
                width: '100%'
              }}>
              {' '}
              <Button
                variant='contained'
                sx={{
                  color: 'white.main'
                }}
                onClick={() => {
                  handleOpenPopup();
                }}>
                Search for bookmark of medicine
              </Button>
              <Button
                variant='contained'
                sx={{
                  color: 'white.main'
                }}
                onClick={() => {
                  setOpenSubmitConfirmation(true);
                }}>
                Next
              </Button>
            </Stack>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        maxWidth='lg'
        fullWidth
        open={openSubmitConfirmation}
        aria-labelledby='alert-dialog-title'
        disableEscapeKeyDown
        aria-describedby='alert-dialog-description'>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <div>PMDA URL</div>
          </Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to submit this data?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='outlined'
            color='secondary'
            onClick={() => {
              handleOpenPopup();
            }}>
            No
          </Button>

          <Button
            variant='contained'
            onClick={async () => {
              await setProductData(localProductData);
              await handleNext();
            }}
            color='secondary'>
            Next
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default React.memo(ActiveIngredientData);
