// mui
import { Stack, Typography, Paper, Button } from '@mui/material';

const DataListContent = ({ data }: { data: any }) => {
  return (
    <Stack
      spacing={2}
      p={1}
      sx={{ width: '100%', height: '100%', minHeight: 460 }}
      justifyContent='flex-start'
      alignItems='flex-start'
      overflow='auto'>
      {data?.documents?.map((doc: any) => (
        <Paper
          square={false}
          elevation={2}
          sx={{ width: '100%', p: 0.5, border: '1ps solid gray' }}>
          <Stack direction='row' spacing={2} overflow='auto' alignItems='center'>
            <Typography variant='subtitle2' noWrap>
              Category: <b>{doc.category}</b>
            </Typography>
            <Typography variant='subtitle2' noWrap>
              Document Added date: <b>{new Date(doc.document_added).toLocaleDateString()}</b>
            </Typography>

            <Button
              fullWidth={false}
              target='_blank'
              href={doc.s3_path}
              sx={{
                fontSize: 14,
                textTransform: 'capitalize',
                width: 'auto',
                textDecoration: 'underline'
              }}
              variant='text'>
              Verify S3 link
            </Button>
          </Stack>
        </Paper>
      ))}
    </Stack>
  );
};

export default DataListContent;
