import makeRequest from './client';

interface sendQueryProps {
  applicationNumber: string;
  section: any;
  payload: {
    question: string;
    temperature: number;
    top_p: number;
    n: number;
  };
}

export const initialiseChat = (applicationNumber: string) =>
  makeRequest(`/chatbot/init/${applicationNumber}`, 'GET');
export const sendQuery = ({ applicationNumber, section, payload }: sendQueryProps) =>
  makeRequest(`/chatbot/conversations/${applicationNumber}/${section}`, 'POST', {
    question: payload.question,
    temperature: payload.temperature,
    top_p: payload.top_p,
    n: payload.n
  });

export const getChatRiaStats = (days: string, group: string, hideVivpro: boolean) =>
  makeRequest(`/chatbot/stats/${days}/${group}/${hideVivpro.toString()}`, 'GET');

export const getChatRiaQuestion = (startDate: string, group: string, hideVivpro: boolean) =>
  makeRequest(`/chatbot/questions/${startDate}/${group}/${hideVivpro.toString()}`, 'GET');
