// eslint-disable-next-line import/prefer-default-export
export const PmrcSectionOptions = [
  'ACCELERATED APPROVAL REQUIREMENTS',
  'REQUIRED PEDIATRIC ASSESSMENTS',
  'POSTMARKETING REQUIREMENTS UNDER 505(o)(3)',
  'POSTMARKETING COMMITMENTS SUBJECT TO REPORTING REQUIREMENTS UNDER SECTION 506B',
  'POSTMARKETING COMMITMENTS NOT SUBJECT TO REPORTING REQUIREMENTS UNDER SECTION 506B',
  'SUBPART I APPROVAL REQUIREMENTS',
  'FULFILLMENT OF POSTMARKETING REQUIREMENT/COMMITTMENT',
  'RELEASE FROM POSTMARKETING REQUIREMENT/COMMITTMENT',
  'N/A'
];
