export const VALID_NUMBER_OF_COLUMNS = 7;
export const EXPECTED_COLUMNS = [
  'First Name',
  'Last Name',
  'Email',
  'Organization',
  'Status',
  'License',
  'Role'
];
export const VALID_LICENSES = [
  'Government',
  'Educational',
  'Enterprise',
  'Individual',
  'Free',
  'Evaluation',
  'Business'
];
export const VALID_ROLES = ['Admin', 'Developer', 'User', 'Data Curator'];
