import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// material ui
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { submitNonPLRLabelData } from '../../api/AdminPage';

// helpers
import { handleChange } from '../../helpers/changeHandler';

// styles
import Styles from '../DataEntry/styles/PageNumberReview.styles';

const ReviewPage = props => {
  const classes = Styles();
  const history = useHistory();
  const { state, dispatch } = useContext(Store);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [formStatus, setFormStatus] = useState('Qced');
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [allDetails, setAllDetails] = useState();
  const [indicationsAndUsage, setIndicationsAndUsage] = useState('');
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [qcStatus] = useState(false);
  const [boxTextStatus, setBoxTextStatus] = useState('default');
  const [boxText, setBoxText] = useState('');
  const handleClickClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentUserInfo();
        setEmail(user.attributes.email);
        setName(user.attributes['custom:user']);
        const { res } = props;
        if (res === undefined) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Enter valid information' }
          });
          if (props.docType === 'ema') {
            history.push('/ema/labelEma');
          } else {
            history.push('/fda/label');
          }
        }
        setEditMode(props.edit);
        setAllDetails(res);
        if (
          'box_text' in res &&
          res.box_text !== null &&
          res.box_text !== undefined &&
          res.box_text !== ''
        ) {
          if (res.box_text === 'NA') {
            setBoxTextStatus('no');
          } else {
            setBoxText(res.box_text);

            setBoxTextStatus('yes');
          }
        }

        if (props.docType === 'ema') {
          setIndicationsAndUsage(res.indications_and_usage || '');
        } else {
          setFormStatus(res.status);
          setIndicationsAndUsage(res.indications_text || '');
        }
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        if (props.docType === 'ema') {
          history.push('/ema/labelEma');
        } else {
          history.push('/fda/label');
        }
      }
    }

    fetchData();
  }, [props.edit, props.switch, dispatch, history, props.res]);

  const checkForQc = async () => {
    setDialogOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleSubmit = async () => {
    if (props.docType === 'ema') {
      const payload = {
        product_number: allDetails.product_number,
        indications_and_usage: indicationsAndUsage,
        indication_status: formStatus,
        key: allDetails.key,
        curator_email: email,
        curator_name: name,
        revision_date: allDetails.revision_date,
        url: allDetails.url,
        doctype: props.docType
      };
      await submitNonPLRLabelData(payload);
    } else {
      const payload = {
        appl_num: allDetails.appl_num,
        status: formStatus,
        indications_text: indicationsAndUsage,
        key: allDetails.key,
        label_path: allDetails.label_path,
        submission_num: allDetails.submission_num,
        submission_type: allDetails.submission_type,
        curator_email: email,
        curator_name: name,
        doctype: props.docType,
        box_text: boxTextStatus === 'no' ? 'NA' : boxText
      };
      await submitNonPLRLabelData(payload);
    }
    if (props.docType === 'ema') {
      history.push('/ema/labelEma');
    } else {
      history.push('/fda/label');
    }
  };
  const handleSwitch = () => {
    setEditMode(false);
    props.switch();
  };

  return (
    <Grid item xs={12} lg={6} className={classes.gridLabel}>
      <Box className={classes.reviewBox} justifyContent='center' alignItems='space-between'>
        {props.docType === 'ema' ? (
          <Typography align='center' className={classes.heading}>
            {allDetails && allDetails.key}
          </Typography>
        ) : (
          <Typography align='center' className={classes.heading}>
            {allDetails && allDetails.appl_num} {allDetails && allDetails.submission_type}-
            {allDetails && allDetails.submission_num}
          </Typography>
        )}
        <Box pr={1} display='flex' flexDirection='column' className={classes.jointBox}>
          <Box
            padding={1}
            display='flex'
            flexDirection='row'
            justifyContent='space-between'
            style={{ minHeight: '65vh' }}>
            <Typography align='left' className={classes.label}>
              Indications and Usage
            </Typography>
            {editMode ? (
              <textarea
                rows='20'
                cols='60'
                className={classes.textarea}
                fullWidth
                value={indicationsAndUsage}
                onChange={event => setIndicationsAndUsage(handleChange(event))}
                id='standard-basic'
                placeholder='Enter Indications & Usage'
                InputProps={{
                  classes: { input: classes.input },
                  disableUnderline: true
                }}
              />
            ) : null}
            {editMode ? null : props.docType === 'ema' ? (
              <Typography align='left' className={classes.detailsLabel}>
                {allDetails && allDetails.indications_and_usage}
              </Typography>
            ) : (
              <Typography align='left' className={classes.detailsLabel}>
                {allDetails && allDetails.indications_text}
              </Typography>
            )}
          </Box>
          {props.docType.toLowerCase() === 'label' && (
            <Box padding={1} display='flex' flexDirection='column'>
              <Typography align='left' className={classes.label}>
                Is BOXED WARNING in the label?
              </Typography>
              <Select
                id='boxText'
                variant='standard'
                disableUnderline
                required
                disabled={!editMode}
                value={boxTextStatus}
                onChange={event => setBoxTextStatus(event.target.value)}
                className={classes.dropdownContainer}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                    disabled: classes.disabled
                  }
                }}>
                <MenuItem disabled value='default'>
                  Does it contain box text?
                </MenuItem>
                <MenuItem value='yes' className={classes.dropdownText}>
                  Yes
                </MenuItem>
                <MenuItem value='no' className={classes.dropdownText}>
                  No
                </MenuItem>
              </Select>
            </Box>
          )}
          {props.docType.toLowerCase() === 'label' && boxTextStatus === 'yes' && (
            <Box padding={1} display='flex' flexDirection='column'>
              <Typography align='left' className={classes.label}>
                Box Warning text
              </Typography>
              <textarea
                rows='4'
                cols='12'
                className={classes.textarea1}
                disabled={!editMode}
                value={boxText}
                onChange={event => setBoxText(handleChange(event))}
                id='standard-basic'
                placeholder='Boxed Warning Text'
                InputProps={{
                  classes: { input: classes.input },
                  disableUnderline: true
                }}
              />
            </Box>
          )}
          <Box padding={1} display='flex' flexDirection='column'>
            <Box padding={1} display='flex' flexDirection='row'>
              <Typography align='left' className={classes.label}>
                Status:
              </Typography>
              <Typography align='right' className={classes.label}>
                {props.docType === 'ema'
                  ? allDetails && allDetails.indication_status
                  : allDetails && allDetails.status}
              </Typography>
            </Box>
            {editMode ? (
              <Select
                id='blaStats'
                variant='standard'
                disableUnderline
                required
                MenuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                value={formStatus}
                onChange={event => setFormStatus(handleChange(event))}
                className={classes.dropdownContainer}
                inputProps={{
                  classes: {
                    icon: classes.icon
                  }
                }}>
                <MenuItem value='Qced' className={classes.dropdownText}>
                  Qced
                </MenuItem>
                <MenuItem value='No Document' className={classes.dropdownText}>
                  No Document
                </MenuItem>
                <MenuItem value='Escalate' className={classes.dropdownText}>
                  Escalate
                </MenuItem>
                <MenuItem value='Carton' className={classes.dropdownText}>
                  Carton
                </MenuItem>
                <MenuItem value='Not label' className={classes.dropdownText}>
                  Not label
                </MenuItem>
                <MenuItem value='' className={classes.dropdownText} />
              </Select>
            ) : null}
            {editMode ? null : (
              <Typography align='left' className={classes.details}>
                {allDetails && allDetails.indications_status}
              </Typography>
            )}
          </Box>

          <Box padding={1} display='flex' justifyContent='center'>
            {editMode ? (
              <Box display='flex' flexDirection='row'>
                <Button className={classes.button} onClick={handleSwitch} variant='contained'>
                  Cancel
                </Button>

                {state.userStub &&
                state.userStub['custom:role'] !== 'Admin' &&
                allDetails &&
                (allDetails.indication_status === 'Qced' ||
                  allDetails.indications_status === 'Qced') ? (
                  <Button className={classes.button} onClick={checkForQc} variant='contained'>
                    Submit
                  </Button>
                ) : (
                  <Button className={classes.button} onClick={checkForQc} variant='contained'>
                    Submit
                  </Button>
                )}
                <Dialog
                  open={dialogOpen}
                  onClose={handleClickClose}
                  aria-labelledby='alert-dialog-title'
                  aria-describedby='alert-dialog-description'>
                  <Box className={classes.dialog}>
                    <DialogTitle id='alert-dialog-title'>Confirm Submission</DialogTitle>
                    <DialogContent>
                      <DialogContentText id='alert-dialog-description'>
                        Are you sure you want to submit the changes?
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClickClose} className={classes.confirm}>
                        No
                      </Button>
                      <Button onClick={handleSubmit} className={classes.confirm} autoFocus>
                        Yes
                      </Button>
                    </DialogActions>
                  </Box>
                </Dialog>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default ReviewPage;
