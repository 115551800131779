export default {
  allowList: [
    'venu@clignosis.ai',
    'venu@vivpro.ai',
    'yogesh@vivpro.ai',
    'pravin@vivpro.ai',
    'vedadnya@vivpro.ai',
    'sid@vivpro.ai',
    'peter@vivpro.ai',
    'ashwath@vivpro.ai',
    'harish@vivpro.ai',
    'tushar@vivpro.ai',
    'rohan@vivpro.ai',
    'bhanu@vivpro.ai',
    'rajan@vivpro.ai',
    'kunal@vivpro.ai',
    'nicky@vivpro.ai',
    'suraj@vivpro.ai',
    'aaditi@vivpro.ai'
  ],
  allowEmailDomains: ['fda.hhs.gov', 'amgen.com', 'rrdintl.com', 'novonordisk.com'],
  allowedLicense: ['enterprise', 'government', 'business'],
  showAdcomm: true
};
