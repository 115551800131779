// mui
import { Stack, TextField, Button } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const SourceValidationLayout = ({
  handleStartChange,
  handleEndChange,
  fromDate,
  toDate,
  getLatestUpdatesList
}: {
  handleStartChange: any;
  handleEndChange: any;
  fromDate: any;
  toDate: any;
  getLatestUpdatesList: any;
}) => {
  return (
    <Stack>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Stack
          mt={2}
          p={2}
          direction='row'
          justifyContent='space-evenly'
          spacing={2}
          sx={{
            borderRadius: 4,
            border: '1px solid gray'
          }}>
          <DatePicker
            label='From'
            value={fromDate}
            onChange={newValue => handleStartChange(newValue)}
            renderInput={params => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant='standard'
                id='start_date'
                helperText={null}
                sx={{ width: '30%' }}
              />
            )}
            components={{
              OpenPickerIcon: ArrowDropDownIcon
            }}
          />
          <DatePicker
            label='To'
            value={toDate}
            onChange={newValue => handleEndChange(newValue)}
            renderInput={params => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant='standard'
                id='end_date'
                helperText={null}
                sx={{ width: '300px' }}
              />
            )}
            components={{
              OpenPickerIcon: ArrowDropDownIcon
            }}
          />
          <Button
            variant='contained'
            onClick={() => getLatestUpdatesList(fromDate, toDate)}
            sx={{
              px: 4,
              borderRadius: '30px',
              textTransform: 'capitalize',
              color: 'white.main',
              fontWeight: 'bold'
            }}>
            submit
          </Button>
        </Stack>
      </LocalizationProvider>
    </Stack>
  );
};

export default SourceValidationLayout;
