import themev5 from '../../../themev5';
import palette from '../../../themev5/palette';

const styles = {
  Button: {
    margin: '10px',
    background: palette.primary.main,
    color: palette.white.main,
    textTransform: 'capitalize',
    padding: 1,
    '&:hover': {
      opacity: 0.8,
      background: palette.primary.main
    }
  },
  Button2: {
    margin: '10px',
    background: '#a593f2',
    color: palette.white.main,
    padding: 1,
    '&:hover': {
      opacity: 0.8,
      background: '#a593f2'
    }
  },
  title: { width: '100%', color: palette.primary.main },
  list: {
    color: palette.black.main,
    background: palette.white.main,
    border: '1px solid black',
    borderRadius: '5px',
    marginBottom: 2,
    marginTop: 2,
    '& .MuiListItemTex-secondary': {
      color: palette.black.main
    }
  },
  secondaryText: {
    color: palette.black.main,
    marginTop: 1
  },
  pdfContainer: {
    height: '75vh',
    width: '100%',
    borderRadius: 1.5,
    marginLeft: '2em',
    background: palette.white.main,
    [themev5.breakpoints.down('sm')]: {
      minHeight: '80vh'
    }
  },
  activeTab: {
    border: '0.5px solid',
    borderColor: palette.gray.light,
    borderRadius: '10px 10px 0px 0px',
    fontSize: '16px',
    boxShadow: 'none',
    color: palette.gray.main,
    textTransform: 'capitalize',
    background: 'white'
  },
  inactiveTab: {
    borderRadius: '10px 10px 0px 0px',
    textTransform: 'capitalize',
    color: 'white.main',
    fontFamily: 'Nunito Sans',
    fontWeight: '600',
    fontSize: '16px',
    boxShadow: 'none',
    background: palette.gradient.main
  }
};

export default styles;
