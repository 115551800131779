import React from 'react';

// context
import { AdminStateProvider } from '../../store/AdminPage/index';

// child components
import SnapshotSummary from './SnapshotSummary';

const SnapshotCuration = props => {
  return (
    <AdminStateProvider>
      <SnapshotSummary {...props} />
    </AdminStateProvider>
  );
};

export default SnapshotCuration;
