import { makeStyles } from '@material-ui/styles';
import palette from '../../../themev5/palette';

export default makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#3AB09E',
    color: theme.palette.white
  },
  reviewBox: {
    marginTop: theme.spacing(1),
    width: '100%',
    borderRadius: theme.spacing(1),
    border: '2px black grey'
  }
}));

export const styles = {
  Button: {
    margin: '10px',
    background: palette.primary.main,
    color: palette.white.main,
    padding: 1,
    '&:hover': {
      opacity: 0.8,
      background: palette.primary.main
    }
  },
  label: {
    fontSize: '18px',
    fontWeight: 'bold'
  },
  details: {
    fontSize: '16px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  }
};
