import React, { useState, useEffect, ChangeEvent, useContext } from 'react';

// muiv5
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Autocomplete } from '@mui/material';
import PLRPage from './PLRPage';
import NonPLRPage from './NonPLRPage';
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { fetchLabelPageCurationDetails, fetchLabelProcessedDetails } from '../../api/Cder';

// styles
import styles from './styles/PageNumberEdit.styles';

const tableFormat = ['PLR', 'NON-PLR'];

const LabelPageCurationModal = ({ closeEdit, docsId, processedFormat, handleNext }: any) => {
  const { dispatch } = useContext<any>(Store);
  const [pdfurl, setpdfurl] = useState('');
  const [format, setFormat] = useState('');
  const [disable, setDisable] = useState(false);
  const [labelData, setLabelData] = useState<any>({});

  useEffect(() => {
    const getLabelData = async () => {
      try {
        let response;
        let formatToSet = 'PLR';
        if (processedFormat) {
          formatToSet = processedFormat;
          setDisable(true);
          response = await fetchLabelProcessedDetails(processedFormat.toLowerCase(), docsId);
        } else {
          response = await fetchLabelPageCurationDetails(docsId);
          if (response.data.body.result.document_id && !response.data.body.result.is_plr) {
            formatToSet = 'NON-PLR';
          }
        }
        if (!response?.data || response.status !== 200) {
          throw Error('Result Not Available');
        } else if (response.data.body) {
          setpdfurl(response.data.body.result.document_link);
          setLabelData(response.data.body.result);
        }
        setFormat(formatToSet);
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getLabelData();
    return () => {
      setLabelData({});
    };
  }, []);

  return (
    <Container sx={styles.root}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography component='h2' variant='h5' sx={styles.Heading}>
              Edit Label Page Number
            </Typography>
            <Autocomplete
              id='table_format'
              disabled={disable}
              value={format}
              options={tableFormat}
              onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                if (newValue !== null) {
                  setFormat(newValue);
                }
              }}
              sx={{
                '& .MuiInputBase-input': {
                  marginRight: '50px',
                  fontSize: '1.5vh',
                  minWidth: '250px'
                },
                '& .MuiFormLabel-root': {
                  fontSize: '1.5vh'
                }
              }}
              renderInput={params => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label='Document Format'
                  variant='outlined'
                  style={{ fontSize: '2.5vh', minWidth: '250px' }}
                  InputLabelProps={{
                    style: { fontSize: '1.8vh', fontWeight: 'bold' }
                  }}
                />
              )}
            />
          </Box>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Grid container spacing={2} display='flex'>
          <Grid item xs={12} lg={6}>
            {format && format === 'PLR' ? (
              <PLRPage
                format={format}
                docsId={docsId}
                closeEdit={closeEdit}
                labelCachedData={labelData}
                processedFormat={processedFormat}
                handleNext={handleNext}
              />
            ) : null}
            {format && format === 'NON-PLR' ? (
              <NonPLRPage
                format={format}
                docsId={docsId}
                closeEdit={closeEdit}
                labelCachedData={labelData}
                processedFormat={processedFormat}
                handleNext={handleNext}
              />
            ) : null}
          </Grid>
          <Grid item xs={12} lg={6}>
            {pdfurl ? null : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                style={{ height: '100%', width: '100%' }}>
                <Typography style={{ fontSize: '20px', fontWeight: 'bold', color: '#33B187' }}>
                  Can&apos;t Open This PDF{' '}
                </Typography>
              </Box>
            )}
            {pdfurl ? (
              <Box display='flex' justifyContent='center'>
                <iframe
                  title='pdf'
                  src={pdfurl}
                  style={{
                    height: '75vh',
                    width: '50vw',
                    marginTop: '20px',
                    position: 'relative'
                  }}
                />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

export default LabelPageCurationModal;
