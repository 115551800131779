/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
// react
import React, { useRef,useState } from 'react';
import { useHistory } from 'react-router-dom';

// material ui imports
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Select from '@mui/material/Select';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';

import useKeyAttributesAPIs from './hooks/useKeyAttributesAPIs';
import Styles from './styles/utils';

// api
import KeyDocuments from './components/KeyDocuments';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { Autocomplete, FormControl, InputLabel, MenuItem } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { qcStatus, meetingOptions } from './constant';
import { doc } from 'prettier';

const ApplicationDetails = ({ match }: any) => {
  const classes = Styles();
  const history = useHistory();
  const ref = useRef<HTMLInputElement>(null);
  const [pdfMetaData,setPdfMetaData] = useState<any>({intialPageNo:0,documentId:'',category:''});
  const [blobUrl,setBlobUrl] = useState<any>('');
  const handleBlobUrl = (fileUrl:string)=>{
    setBlobUrl(fileUrl)
  }
  const handlePdfPageNo = (source:any,meetingComments:any,meetingDate:any)=>{

    const pageNo = Number((source.split('=') || []).pop()) || 0
    const documentMetadata = (source.split('/') || []).pop() || ''
    const endIndex = documentMetadata.indexOf("#")
    const documentId = documentMetadata.slice(0,endIndex)
    let category;
    if (documentMetadata.includes('Letter')) {
      category = 'Letters';
    }
    else if (documentMetadata.includes('Review')) {
      category = 'Reviews';
    }
    else {
      category = 'Others';
    }
    setPdfMetaData({intialPageNo:pageNo,documentId:documentId,category:category,phraseToHighlight:meetingComments,textToHighlight:[meetingDate]});
  }
  const appKey = match?.params?.key;
  const submissionProps = appKey?.split('-');

  const {
    attributes,
    expandAttribute,
    editAttribute,
    updateAttributeFields,
    addEmptyAttributeFields,
    saveAttribute
  } = useKeyAttributesAPIs(appKey);

  return (
    <Box mt={10} pl={2}>
      <Box pb={2}>
        <Stack direction='row'>
          <KeyboardBackspaceIcon sx={{ fontSize: 32 }} />
          <Typography
            onClick={() => history.goBack()}
            variant='h5'
            display='inline-block'
            sx={{ cursor: 'pointer' }}>
            Go Back
          </Typography>
        </Stack>
      </Box>
      <Stack direction='row' sx={{ overflow: 'hidden', height: '85vh' }}>
        <Box flex={1} overflow='auto'>
          <Typography variant='h6' color='black'>
            Regulatory Information for {appKey}
          </Typography>
          <Box>
            <Box alignItems='start' display='flex' justifyContent='start' position='relative'>
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    pl: 1,
                    pt: 1
                  }
                }}>
                {attributes?.map(
                  ({
                    id,
                    attribute_title,
                    attribute_value,
                    qc_status,
                    comments,
                    source,
                    attribute_seq,
                    expanded = false,
                    editOpen = false
                  }: any) => {
                    const color = 'green';
                    return (
                      <TimelineItem key={id}>
                        <TimelineSeparator>
                          <TimelineDot sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                            <Box display='inline' onClick={() => expandAttribute(id) }>
                              {!expanded ? (
                                <ControlPointIcon sx={{ color, fontSize: 34, cursor: 'pointer' }} />
                              ) : (
                                <RemoveCircleOutlineIcon
                                  sx={{ color, fontSize: 34, cursor: 'pointer' }}
                                />
                              )}
                            </Box>
                          </TimelineDot>
                          <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ backgroundColor: 'inherit' }}>
                          <Box display='flex' mt={2} alignItems='center'>
                            {!expanded ? (
                              <Typography>
                                <b>Meeting Type:</b> {attribute_title}
                              </Typography>
                            ) : !editOpen ? (
                              <>
                                <Typography paragraph variant="body1" alignItems={'flex-end'}>
                                  <b>Meeting Type:</b> {attribute_title} <br />
                                  <b>Meeting Date:</b> {attribute_value}<br />
                                  <b>Source:</b> {source} <br />
                                  <b>Meeting Details:</b> {comments} <br />
                                  <b>Status:</b> {qc_status}
                                </Typography>
                                <Box>
                                  <Button onClick={() => {editAttribute(id);handlePdfPageNo(source,comments,attribute_value)}} disabled={editOpen}>
                                    Edit
                                  </Button>
                                  <Button
                                    onClick={() => saveAttribute(id)}
                                    sx={{ color: '#33B187' }}
                                    disabled={!editOpen}>
                                    Save
                                  </Button>
                                </Box>
                              </>
                            ) : (
                              <Box component='form'>
                                <Stack spacing={2}>
                                  <Autocomplete
                                    freeSolo
                                    size='small'
                                    disableClearable
                                    options={meetingOptions}
                                    inputValue={attribute_title}
                                    onInputChange={(event, newInputValue) => {
                                      updateAttributeFields(id, newInputValue, 'attribute_title');
                                    }}
                                    renderInput={(params: any) => (
                                      <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        inputRef ={ref}
                                        required
                                        label='Meeting Type'
                                        placeholder='Add Meeting Type'
                                      />
                                    )}
                                  />
                                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DatePicker
                                      label='Meeting Date'
                                      inputFormat='yyyy-MM-dd'
                                      value={attribute_value}
                                      onChange={newValue =>
                                        updateAttributeFields(id, newValue, 'attribute_value')
                                      }
                                      renderInput={params => (
                                        <TextField size='small' {...params} error={false} />
                                      )}
                                    />
                                  </LocalizationProvider>
                                  <TextField
                                    label='Source'
                                    size='small'
                                    placeholder='Add Source of Meeting Date'
                                    onChange={e =>
                                      updateAttributeFields(id, e.target.value, 'source')
                                    }
                                    value={source}
                                    InputProps={{
                                      classes: {
                                        input: `${classes.input}`
                                      }
                                    }}
                                  />
                                  <TextField
                                    label='Meeting Details'
                                    multiline
                                    size='small'
                                    placeholder='Add Meeting Details'
                                    onChange={e =>
                                      updateAttributeFields(id, e.target.value, 'comments')
                                    }
                                    value={comments}
                                    InputProps={{
                                      classes: {
                                        input: `${classes.input}`
                                      }
                                    }}
                                  />
                                  <FormControl>
                                    <InputLabel id='status'>Status</InputLabel>
                                    <Select
                                      label='Status'
                                      size='small'
                                      labelId='status'
                                      id='qc_status'
                                      value={qc_status}
                                      required
                                      defaultValue='To_Qc'
                                      onChange={(e: { target: { value: string } }) =>
                                        updateAttributeFields(id, e.target.value, 'qc_status')
                                      }>
                                      {qcStatus?.map(option => {
                                        return <MenuItem value={option}>{option}</MenuItem>;
                                      })}
                                    </Select>
                                  </FormControl>
                                  <Box>
                                    <Button onClick={() => editAttribute(id)} disabled={editOpen}>
                                      Edit
                                    </Button>
                                    <Button
                                      onClick={
                                        () => 
                                        ref.current?.reportValidity() ? saveAttribute(id)  : null
                                      }
                                      sx={{ color: '#33B187' }}
                                      disabled={!editOpen}>
                                      Save
                                    </Button>
                                    <Button
                                      color='error'
                                      disabled={!editOpen}
                                      onClick={() => editAttribute(id)}>
                                      Close
                                    </Button>
                                  </Box>
                                </Stack>
                              </Box>
                            )}
                          </Box>
                        </TimelineContent>
                      </TimelineItem>
                    );
                  }
                )}
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                      <ControlPointIcon sx={{ color: 'black', fontSize: 34, cursor: 'pointer' }} />
                    </TimelineDot>
                  </TimelineSeparator>
                  <TimelineContent>
                    <Box display='flex' alignItems='center'>
                      <Box>
                        <Button variant='contained' onClick={addEmptyAttributeFields}>
                          Add Meeting
                        </Button>
                      </Box>
                    </Box>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Box>
          </Box>
        </Box>
        <Box flex={1}>
          <KeyDocuments
            applno={submissionProps[0].trim()}
            submissionType={submissionProps[1].trim()}
            submissionNumber={submissionProps[2].trim()}
            pdfMetaData = {pdfMetaData}
            blobUrl = {blobUrl}
            handleBlobUrl = {handleBlobUrl}
          />
        </Box>
      </Stack>
    </Box>
  );
};

export default ApplicationDetails;
