import * as React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import { DataGrid, GridOverlay } from '@material-ui/data-grid';
import Styles from './styles/DataGrid.styles';

function customNoRowsOverlay(message = null) {
  return (
    <GridOverlay>
      <div>{message || 'Loading...'}</div>
    </GridOverlay>
  );
}

function isOverflown(element) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

function hasMultipleNewlineElements(element) {
  if (!['div', 'span'].includes(element.type)) {
    return false;
  }
  return element.props.children.length > 1;
}

export default function AdcommAdminDataGrid({
  rows,
  columns,
  pageSize,
  rowsPerPageOptions,
  rowCount,
  setPage,
  page,
  loading,
  setOffset
}) {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      pagination
      pageSize={pageSize}
      rowsPerPageOptions={rowsPerPageOptions}
      rowCount={rowCount}
      paginationMode='server'
      onPageChange={newPage => {
        setPage(newPage);
        setOffset(newPage * pageSize);
      }}
      page={page}
      loading={loading}
    />
  );
}

/// Function that creates and exports the basic DataGrid creator.
/// rows: The rows of the element.
/// columns: The columns of the element.
export const basicDataGridCreator = (rows, columns, noRowMessage = null) => {
  return (
    <DataGrid
      components={{ NoRowsOverlay: () => customNoRowsOverlay(noRowMessage) }}
      rows={rows}
      columns={columns}
      height='inherit'
      autoPageSize={false}
      hideFooter
    />
  );
};

/// Function that creates and exports a DataGrid with values that overflow the normal width.
/// rows: The rows of the element.
/// columns: The columns of the element.
/// array: Array of numbers of the columns that the user wants to expand on hover. Leave empty to expand all cells on hover!
export const overflowValuesDataGridCreator = (rows, columns, array, noRowMessage = null) => {
  const useStyles = Styles;

  const GridCellExpand = React.memo(function GridCellExpand(props) {
    const { width, value } = props;
    const wrapper = React.useRef(null);
    const cellDiv = React.useRef(null);
    const cellValue = React.useRef(null);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles();
    const [showFullCell, setShowFullCell] = React.useState(false);
    const [showPopper, setShowPopper] = React.useState(false);

    const handleMouseEnter = () => {
      const isCurrentlyOverflown = isOverflown(cellValue.current);
      setShowPopper(isCurrentlyOverflown);
      setAnchorEl(cellDiv.current);
      setShowFullCell(true);
    };

    const handleMouseLeave = () => {
      setShowFullCell(false);
    };

    React.useEffect(() => {
      if (!showFullCell) {
        return undefined;
      }

      function handleKeyDown(nativeEvent) {
        // IE11, Edge (prior to using Bink?) use 'Esc'
        if (nativeEvent.key === 'Escape' || nativeEvent.key === 'Esc') {
          setShowFullCell(false);
        }
      }

      document.addEventListener('keydown', handleKeyDown);

      return () => {
        document.removeEventListener('keydown', handleKeyDown);
      };
    }, [setShowFullCell, showFullCell]);

    return (
      <div
        ref={wrapper}
        className={classes.root}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <div ref={cellDiv} className={classes.cellDiv} style={{ width }} />

        <div ref={cellValue} className='cellValue'>
          {value}
          {hasMultipleNewlineElements(value) && <div className={classes.moreText}>...More</div>}
        </div>
        {showPopper && (
          <Popper
            open={showFullCell && anchorEl !== null}
            anchorEl={anchorEl}
            style={{ width }}
            className={classes.popper}>
            <Paper elevation={1} style={{ minHeight: wrapper.current.offsetHeight - 3 }}>
              <Typography variant='body2' className={classes.typography}>
                {value}
              </Typography>
            </Paper>
          </Popper>
        )}
      </div>
    );
  });

  GridCellExpand.propTypes = {
    value: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired
  };

  const renderCellExpand = params => {
    return (
      <GridCellExpand
        value={
          params.value
            ? ['div', 'span'].includes(params.value.type)
              ? params.value
              : params.value.toString()
            : ''
        }
        width={params.colDef.width}
      />
    );
  };

  renderCellExpand.propTypes = {
    /**
     * The column of the row that the current cell belongs to.
     */
    colDef: PropTypes.any.isRequired,
    /**
     * The cell value, but if the column has valueGetter, use getValue.
     */
    value: PropTypes.oneOfType([
      PropTypes.instanceOf(Date),
      PropTypes.number,
      PropTypes.object,
      PropTypes.string,
      PropTypes.bool
    ])
  };

  if (Array.isArray(array) && array.length > 0) {
    array.forEach(element => {
      columns[element].renderCell = renderCellExpand;
    });
  } else {
    for (let index = 0; index < columns.length; index++) {
      columns[index].renderCell = renderCellExpand;
    }
  }

  return basicDataGridCreator(rows, columns, noRowMessage);
};

/// Function that creates and exports the select DataGrid creator.
/// rows: The rows of the element.
/// columns: The columns of the element.
/// select: The selected row.
export const selectionDataGridCreator = (rows, columns, select) => {
  return (
    <DataGrid
      rows={rows}
      columns={columns}
      checkboxSelection
      onSelectionModelChange={select}
      height='inherit'
      rowsPerPageOptions={[25, 50, 100, 500]}
    />
  );
};
