const RIA_CUSTOMER_EXPERIENCE_FEEDBACK_QUESTIONS = {
  question_1: 'How often do you use RIA?',
  question_2: 'We recently upgraded our platform, how satisfied are you with our new version?',
  question_3:
    'How would you rate the ease of accomplishing tasks in the new version compared to the previous version?',
  question_4: "Have any comments or suggestions on your mind? We'd love to hear!"
};

const SURVEYS = {
  riaCustomerExperienceFeedback: {
    title: 'RIA CUSTOMER EXPERIENCE FEEDBACK',
    questions: RIA_CUSTOMER_EXPERIENCE_FEEDBACK_QUESTIONS
  }
};

export default SURVEYS;
