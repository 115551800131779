import { SetStateAction, useEffect, useState } from 'react';

// muiv5
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Typography, Dialog, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

// api
import { getRld } from '../../api/AdminPage';

// styles
import styles from './styles/Datagrid.styles';

// components
import DeleteRld from './DeleteRld';
import AddRld from './AddRld';
import EditRLD from './EditRLD';

export interface RldDataProps {
  id: string;
  applType: string;
  applNum: string;
  rldApplType: string;
  rldApplNum: string;
  submissionType: string;
  submissionNum: string;
  status: string;
  closeEdit: any;
  closeAdd: any;
  closeDelete: any;
}

const RldView = () => {
  const [data, setData] = useState<RldDataProps[]>([]);
  const [pageSize, setPageSize] = useState(20);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchData, setSearchData] = useState<RldDataProps[]>([]);
  const [editRow, setEditRow] = useState({
    id: '',
    appl_type: '',
    appl_num: '',
    rld_appl_type: '',
    rld_appl_num: '',
    submission_type: '',
    submission_num: '',
    status: ''
  });
  const [deleteRow, setDeleteRow] = useState('');

  const filterSearch = (text: string, sData: any) => {
    if (text) {
      setSearchData(
        sData.filter((dt: { appl_num: string; rld_appl_num: string }) => {
          return (
            dt.appl_num.toLowerCase().includes(text.trim().toLowerCase()) ||
            dt.rld_appl_num.toLowerCase().includes(text.trim().toLowerCase())
          );
        })
      );
    } else {
      setSearchData(sData);
    }
  };

  const openAdd = async () => {
    try {
      setShowAddDialog(true);
    } catch (e) {
      console.error(e);
    }
  };

  const closeAdd = async (res: any, reason: any) => {
    try {
      if (reason && reason === 'backdropClick') {
        return;
      }
      const addRLd = res?.data?.Success?.body;
      if (addRLd) {
        const response = await getRld();
        if (response.data.Success.length >= 1) {
          setData(response.data.Success);
          setSearchData(response.data.Success);
        } else {
          setData([]);
          setSearchData([]);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setShowAddDialog(false);
  };

  const openDelete = async (rowId: string) => {
    try {
      setDeleteRow(rowId);
      setShowDeleteDialog(true);
    } catch (e) {
      console.error(e);
    }
  };

  const closeDelete = async (res: any, reason: any) => {
    try {
      if (reason && reason === 'backdropClick') {
        return;
      }
      const deletedRLd = res?.data?.Success?.body;
      if (deletedRLd) {
        const response = await getRld();
        if (response.data.Success.length >= 1) {
          setData(response.data.Success);
          setSearchData(response.data.Success);
        } else {
          setData([]);
          setSearchData([]);
        }
      }
    } catch (e) {
      console.error(e);
    }
    setShowDeleteDialog(false);
  };

  const openEdit = async (
    rowData: SetStateAction<{
      id: string;
      appl_type: string;
      appl_num: string;
      rld_appl_type: string;
      rld_appl_num: string;
      submission_type: string;
      submission_num: string;
      status: string;
    }>
  ) => {
    try {
      setShowEditDialog(true);
      setEditRow(rowData);
    } catch (e) {
      console.error(e);
    }
  };

  const closeEdit = (res: any, reason: any) => {
    try {
      if (reason && reason === 'backdropClick') {
        return;
      }
      const editedData = res?.data?.Success?.body;
      if (editedData) {
        const index = data.findIndex(row => row.id === editRow.id);
        const rldIdData = [...data];
        rldIdData[index] = { ...editedData, id: editedData.id };
        setData(rldIdData);
        setSearchData(rldIdData);
      }
    } catch (e) {
      console.error(e);
    }
    setShowEditDialog(false);
  };

  useEffect(() => {
    async function getData() {
      try {
        const response = await getRld();
        if (response.data.Success.length >= 1) {
          setData(response.data.Success);
          setSearchData(response.data.Success);
        } else {
          setData([]);
          setSearchData([]);
        }
      } catch (e) {
        console.error('No record Found');
      }
    }
    getData();
  }, []);

  const columns = [
    {
      field: 'appl_num',
      headerName: 'Application Number',
      headerClassName: 'table-header',
      width: 425,
      cellClassName: 'table-row'
    },
    {
      field: 'rld_appl_num',
      headerName: 'RLD Appl Number',
      headerClassName: 'table-header',
      width: 425,
      cellClassName: 'table-row'
    },
    {
      field: 'status',
      headerName: 'Status',
      headerClassName: 'table-header',
      width: 150,
      cellClassName: 'table-row'
    },
    {
      field: 'Edit',
      headerClassName: 'table-header',
      width: 100,
      renderCell: (cellData: any) => {
        return (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              openEdit(cellData.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'Delete',
      headerClassName: 'table-header',
      width: 140,
      renderCell: (cellData: any) => {
        return (
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              openDelete(cellData.row.id);
            }}>
            <Typography>Delete</Typography>
          </Button>
        );
      }
    }
  ];

  return (
    <Box height='10vh'>
      <Box style={{ height: '50vh', width: '95%', paddingTop: '10vh', paddingLeft: '10vh' }}>
        <Box>
          <Typography sx={styles.Typography}>RLD</Typography>
        </Box>
        <Box>
          <Button
            variant='contained'
            sx={styles.Button}
            onClick={() => {
              openAdd();
            }}>
            Add
          </Button>
          <Dialog open={showAddDialog} onClose={closeAdd} sx={{ backdropFilter: 'blur(5px)' }}>
            <AddRld
              closeAdd={closeAdd}
              id=''
              applType=''
              applNum=''
              rldApplType=''
              rldApplNum=''
              submissionType=''
              submissionNum=''
              status=''
              closeEdit={undefined}
              closeDelete={undefined}
            />
          </Dialog>
          <TextField
            sx={styles.Search}
            variant='outlined'
            type='search'
            id='search_field'
            label='Search here'
            value={searchText}
            InputProps={{
              style: { fontSize: '1.5vh', minWidth: '250px' },
              startAdornment: (
                <InputAdornment position='start'>
                  <SearchIcon />
                </InputAdornment>
              )
            }}
            onChange={e => {
              setSearchText(e.target.value);
              filterSearch(e.target.value, data);
            }}
          />
        </Box>
        <Box justifyContent='center' height='60vh'>
          <DataGrid
            sx={styles.datagrid}
            rowSpacingType='margin'
            sortingOrder={['desc', 'asc']}
            checkboxSelection={false}
            density='standard'
            rows={searchData}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={newPageSize => setPageSize(newPageSize)}
            pagination
            rowsPerPageOptions={[20, 40, 60]}
          />
        </Box>
        <Dialog open={showEditDialog} onClose={closeEdit} sx={{ backdropFilter: 'blur(5px)' }}>
          <EditRLD
            id={editRow.id}
            applType={editRow.appl_type}
            applNum={editRow.appl_num}
            rldApplType={editRow.rld_appl_type}
            rldApplNum={editRow.rld_appl_num}
            submissionType={editRow.submission_type}
            submissionNum={editRow.submission_num}
            status={editRow.status}
            closeEdit={closeEdit}
            closeAdd={undefined}
            closeDelete={undefined}
          />
        </Dialog>
      </Box>
      <Dialog open={showDeleteDialog} onClose={closeDelete} sx={{ backdropFilter: 'blur(5px)' }}>
        <DeleteRld
          id={deleteRow}
          closeDelete={closeDelete}
          closeAdd={undefined}
          applType=''
          applNum=''
          rldApplType=''
          rldApplNum=''
          submissionType=''
          submissionNum=''
          status=''
          closeEdit={undefined}
        />
      </Dialog>
    </Box>
  );
};

export default RldView;
