export const TEXT_FIELDS:any = {
  accelerated_assessment: '',
  active_ingredient: '',
  additional_monitoring: '',
  atc_code: '',
  atcvet_code: '',
  biosimilar: '',
  category: 'Human',
  conditional_approval: '',
  exceptional_circumstances: '',
  generic: '',
  human_pharmacotherapeutic_group: '',
  indications_and_usage: '',
  international_non_proprietary_name: '',
  marketing_status: '',
  orphan_medicine: '',
  patient_safety: '',
  product_number: '',
  qc_status: '',
  sponsor_name: '',
  therapeutic_area: '',
  trade_name: '',
  url: '',
  revision_number:'',
  processed_atc_code:''
};

export const FIELDS_LABEL_MAP: Record<string, string> = {
  accelerated_assessment: 'Accelerated assessment',
  active_ingredient: 'Active substance',
  additional_monitoring: 'Additional monitoring',
  atc_code: 'Anatomical therapeutic chemical (ATC) code',
  atcvet_code: 'Atcvet code',
  biosimilar: 'Biosimilar',
  category: 'Category',
  conditional_approval: 'Conditional approval',
  exceptional_circumstances: 'Exceptional circumstances',
  generic: 'Generic',
  human_pharmacotherapeutic_group: 'Pharmacotherapeutic group',
  indications_and_usage: 'Therapeutic indication',
  international_non_proprietary_name: 'International non-proprietary name',
  marketing_status: 'Marketing status',
  orphan_medicine: 'Orphan medicine',
  patient_safety: 'Patient safety',
  product_number: 'Product number',
  qc_status: 'QC status',
  sponsor_name: 'Marketing authorisation holder',
  therapeutic_area: 'Therapeutic area',
  trade_name: 'Name of medicine',
  url: 'URL',
  revision_number: 'Revision number',
  approval_date: 'Marketing authorisation issued',
  date_of_opinion: 'Opinion adopted',
  date_of_refusal_of_marketing_authorisation: 'Date of refusal of marketing authorisation',
  decision_date: 'Decision date',
  first_published: 'First published',
  revision_date: 'Revision date'
};

export const DATE_FIELDS:any = {
  approval_date: '',
  date_of_opinion: '',
  date_of_refusal_of_marketing_authorisation: '',
  decision_date: '',
  first_published: '',
  revision_date: '',
};

export const EMA_DATA_COLUMNS_MAPPINGS = [
    {
      field: 'id',
      headerName: 'ID',
      width: 0
    },
    {
      field: 'accelerated_assessment',
      headerName: 'Accelerated Assessment',
      width: 200
    },
    {
      field: 'active_ingredient',
      headerName: 'Active Ingredient',
      width: 200
    },
    {
      field: 'additional_monitoring',
      headerName: 'Additional Monitoring',
      width: 200
    },
    {
      field: 'approval_date',
      headerName: 'Approval Date',
      width: 150
    },
    {
      field: 'atc_code',
      headerName: 'ATC Code',
      width: 120
    },
    {
      field: 'atcvet_code',
      headerName: 'ATCVET Code',
      width: 150
    },
    {
      field: 'biosimilar',
      headerName: 'Biosimilar',
      width: 120
    },
    {
      field: 'category',
      headerName: 'Category',
      width: 120
    },
    {
      field: 'conditional_approval',
      headerName: 'Conditional Approval',
      width: 200
    },
    {
      field: 'curator_email',
      headerName: 'Curator Email',
      width: 150
    },
    {
      field: 'curator_name',
      headerName: 'Curator Name',
      width: 150
    },
    {
      field: 'date_of_opinion',
      headerName: 'Date of Opinion',
      width: 150
    },
    {
      field: 'date_of_refusal_of_marketing_authorisation',
      headerName: 'Date of Refusal of Marketing Authorisation',
      width: 300
    },
    {
      field: 'decision_date',
      headerName: 'Decision Date',
      width: 150
    },
    {
      field: 'exceptional_circumstances',
      headerName: 'Exceptional Circumstances',
      width: 200
    },
    {
      field: 'first_published',
      headerName: 'First Published',
      width: 150
    },
    {
      field: 'generic',
      headerName: 'Generic',
      width: 120
    },
    {
      field: 'human_pharmacotherapeutic_group',
      headerName: 'Human Pharmacotherapeutic Group',
      width: 250
    },
    {
      field: 'indications_and_usage',
      headerName: 'Indications and Usage',
      width: 400
    },
    {
      field: 'international_non_proprietary_name',
      headerName: 'International Non-proprietary Name',
      width: 250
    },
    {
      field: 'marketing_status',
      headerName: 'Marketing Status',
      width: 150
    },
    {
      field: 'orphan_medicine',
      headerName: 'Orphan Medicine',
      width: 150
    },
    {
      field: 'patient_safety',
      headerName: 'Patient Safety',
      width: 150
    },
    {
      field: 'processed_atc_code',
      headerName: 'Processed ATC Code',
      width: 200
    },
    {
      field: 'product_number',
      headerName: 'Product Number',
      width: 150
    },
    {
      field: 'qc_status',
      headerName: 'QC Status',
      width: 120
    },
    {
      field: 'revision_date',
      headerName: 'Revision Date',
      width: 150
    },
    {
      field: 'revision_number',
      headerName: 'Revision Number',
      width: 150
    },
    {
      field: 'species',
      headerName: 'Species',
      width: 120
    },
    {
      field: 'sponsor_name',
      headerName: 'Sponsor Name',
      width: 200
    },
    {
      field: 'therapeutic_area',
      headerName: 'Therapeutic Area',
      width: 200
    },
    {
      field: 'trade_name',
      headerName: 'Trade Name',
      width: 150
    },
    {
      field: 'updated_at',
      headerName: 'Updated At',
      width: 150
    },
    {
      field: 'url',
      headerName: 'URL',
      width: 200
    },
    {
      field: 'vet_pharmacotherapeutic_group',
      headerName: 'Vet Pharmacotherapeutic Group',
      width: 250
    }
  ];

  export const WEBLINK = 'https://www.ema.europa.eu/en/search?f%5B0%5D=ema_search_categories%3A83&f%5B1%5D=ema_medicine_bundle%3Aema_medicine&f%5B2%5D=ema_med_status%3A100108&landing_from=73303'