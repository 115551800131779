import React, { useState } from 'react';

// mui v5
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// styles
import themev5 from '../../themev5';
import styles from './styles/Fda.styles';

// components
import StyledTabs, { StyledTab } from '../StyledComponents/VivproTabs';
import ApprovalTable from '../Approval/ApprovalTable';
import AllApprovalStatus from '../Approval/AllApprovalStatus';

const TabPanel = (props: any) => {
  const { children, value, index } = props;

  return (
    <Box role='tabpanel' hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const Label = () => {
  const [selectedTab, setSelectedTab] = useState<string>('approval_status');

  const onTabChange = (e: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box sx={styles.root} pt={1}>
        <AppBar position='static' color='transparent' elevation={0} sx={styles.tabContainer}>
          <StyledTabs
            value={selectedTab}
            onChange={(e: React.SyntheticEvent, newValue: string) => onTabChange(e, newValue)}
            aria-label='tabs'
            sx={styles.tabs}
            indicatorColor='primary'>
            <StyledTab
              value='approval_status'
              id='approval_status'
              label='All Approval Status'
              sx={selectedTab === 'approval_status' ? styles.activeTab : styles.inactiveTab}
              activetab={(selectedTab === 'approval_status').toString()}
            />
            <StyledTab
              value='accelerated'
              id='accelerated'
              label='Accelerated'
              sx={selectedTab === 'accelerated' ? styles.activeTab : styles.inactiveTab}
              activetab={(selectedTab === 'accelerated').toString()}
            />
            <StyledTab
              value='fasttrack'
              id='fasttrack'
              label='Fast Track'
              sx={selectedTab === 'fasttrack' ? styles.activeTab : styles.inactiveTab}
              activetab={(selectedTab === 'fasttrack').toString()}
            />
            <StyledTab
              value='breakthrough'
              id='breakthrough'
              label='Breakthrough'
              sx={selectedTab === 'breakthrough' ? styles.activeTab : styles.inactiveTab}
              activetab={(selectedTab === 'breakthrough').toString()}
            />
            <StyledTab
              value='rtor'
              id='rtor'
              label='RTOR'
              sx={selectedTab === 'rtor' ? styles.activeTab : styles.inactiveTab}
              activetab={(selectedTab === 'rtor').toString()}
            />
          </StyledTabs>
        </AppBar>
        <TabPanel value={selectedTab} index={selectedTab}>
          {selectedTab === 'approval_status' ? (
            <AllApprovalStatus />
          ) : (
            <ApprovalTable docType={selectedTab} />
          )}
        </TabPanel>
      </Box>
    </ThemeProvider>
  );
};

export default Label;
