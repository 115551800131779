import {
  useState,
  useEffect,
  useContext,
  memo,
  SyntheticEvent,
  ChangeEvent,
  useCallback
} from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'aws-amplify';
// api
// context
// material ui
import { Container, Typography, Paper, Button, Box, TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@mui/material';
import Stack from '@mui/material/Stack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import CancelIcon from '@mui/icons-material/Cancel';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Styles from './styles/DataEntry.styles';
import Actions from '../../store/actions';
import Store from '../../store';
import { getPdfUrl, submitDocDetails, getAllDocument } from '../../api/Cber';
import { handleChange } from '../../helpers/changeHandler';
import utils from '../Datagrid/utils';

const document_cateogory_options = [
  'Letter',
  'Label',
  'Rems',
  'Summary Review',
  'Clinical Pharmacology',
  'Pharmacology',
  'Medical',
  'Statistics',
  'Chemistry',
  'Medication Guide',
  'Demographics',
  'CMC',
  'Pharmacovigilance',
  'Pharmacometrics',
  'Mid Cycle',
  'Late Cycle',
  'Patient Information',
  'Information Request',
  'Telephone',
  'Memorandum',
  'Minutes',
  'Filing',
  'Advisory',
  'Officer Employee List',
  'Pediatric',
  'Proprietary Name',
  'Review',
  'Other'
];
const qc_status = ['QCed', 'No Document', 'Escalate', 'Missing Details', 'Carton'];
const submission_type = ['ORIG', 'SUPPL'];
const PdfForm = () => {
  const { application_num, docs_id } = useParams<any>();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const table_status = params.get('qc_status');
  const application_number = params.get('application_number');
  const application_title = params.get('application_title');
  const { dispatch } = useContext<any>(Store);
  const history = useHistory();
  const classes = Styles();
  const [category, setcategory] = useState('');
  const [approval_date, setapproval_date] = useState('');
  const [sub_type, setsub_type] = useState('');
  const [sub_num, setsub_num] = useState('0');
  const [status, setstatus] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [pdfurl, setpdfurl] = useState(null);
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const filter = createFilterOptions();
  const [title, setTitle] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const pageSize = 10;
  const [applLabel, setApplLabel] = useState<Array<any>>([]);
  const [labelPageNo, setLabelPageNo] = useState(0);
  const [PDFData, setPDFData] = useState('');
  const [openPdf, setOpenPdf] = useState(false);
  const [fdaLink, setFdaLink] = useState<string>('');
  useEffect(() => {
    const getURL = async () => {
      try {
        const user = await Auth.currentUserInfo();
        setEmail(user.attributes.email);
        setName(user.attributes['custom:user']);
        const result = await getPdfUrl(docs_id);
        const allApplResult = await getAllDocument(application_num.trim().toLowerCase());
        if (result === undefined) {
          throw new Error('Cannot show URL');
        } else if (result.data && result.data.body.results) {
          const resultData = result.data.body.results;
          setpdfurl(resultData.document_link);
          setTitle(resultData.title);
          resultData.document_approval_date === null
            ? setapproval_date('')
            : setapproval_date(resultData.document_approval_date);
          resultData.docs_category === null
            ? setcategory('')
            : setcategory(utils(resultData.docs_category, 'formulations'));
          setsub_num(resultData.submission_number);
          setsub_type(resultData.submission_type);
          setFdaLink(resultData.trade_name_link);
          if (resultData.qc_status === null) {
            setstatus('');
          } else {
            resultData.qc_status === 'qced'
              ? setstatus('QCed')
              : resultData.qc_status === 'to_qc'
              ? setstatus('')
              : setstatus(utils(resultData.qc_status, 'formulations'));
          }
        }
        if (allApplResult.data && allApplResult.data.body.results) {
          const applResult = allApplResult.data.body.results;
          setApplLabel([
            ...applResult?.map((item: any, index: number) => ({ ...item, id: index }))
          ]);
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getURL();
  }, []);

  const handlePDFOpenClick = async (application_docs_id: number) => {
    try {
      const res = await getPdfUrl(application_docs_id);
      if (!res?.data?.body || res.data.status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Error while showing pdf for ${application_docs_id}` }
        });
      } else if (res.data.body.results) {
        setOpenPdf(true);
        setPDFData(res.data.body.results.document_link);
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };
  const handleClosePDF = useCallback(() => {
    setOpenPdf(false);
    setPDFData('');
  }, []);

  const handleSubmissionTypeTextChange = (value: string) => {
    const selectedValue = submission_type.filter(elem => elem.toLowerCase().includes(value.toLowerCase()));
    if (selectedValue && selectedValue.length === 1) {
      setsub_type(selectedValue[0]);
    }
  };

  const handleKeyFormSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    const payload = {
      application_number: application_num,
      submission_type: sub_type,
      submission_number: sub_num,
      docs_category: category,
      application_docs_date: approval_date,
      qc_status: status,
      curator_email: email,
      curator_name: name
    };
    const res = await submitDocDetails(docs_id, payload);
    const resData = res.data;
    if (resData.error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: resData.message }
      });
    } else {
      const baseURL = `/document_categorization?qc_status=${table_status}`;
      const queryParams = [];
      if (application_number !== null) {
        queryParams.push(`application_number=${application_number}`);
      }
      if (application_title !== null) {
        queryParams.push(`application_title=${application_title}`);
      }
      const finalURL = `${baseURL}&${queryParams.join('&')}`;
      history.push(finalURL);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: resData.body, color: 'success' }
      });
    }
  };
  return (
    <Box className={classes.root} display='flex' alignItems='center'>
      <Container maxWidth='lg' className={classes.container}>
        {editMode ? null : (
          <Box display='flex' padding={1} flexDirection='row' justifyContent='flex-start'>
            <Button
              className={classes.button}
              onClick={() => {
                const baseURL = `/document_categorization?qc_status=${table_status}`;
                const queryParams = [];
                if (application_number !== null) {
                  queryParams.push(`application_number=${application_number}`);
                }
                if (application_title !== null) {
                  queryParams.push(`application_title=${application_title}`);
                }
                const finalURL = `${baseURL}&${queryParams.join('&')}`;
                history.push(finalURL);
              }}
              variant='contained'>
              Go back
            </Button>
            <Button
              className={classes.button}
              onClick={() => setEditMode(true)}
              variant='contained'>
              Edit
            </Button>
            {fdaLink ? (
              <a href={fdaLink} target='_blank' rel='noreferrer'>
                <Button className={classes.button} onClick={() => null} variant='contained'>
                  FDA Link
                </Button>
              </a>
            ) : null}
          </Box>
        )}
        {editMode ? (
          <Box display='flex' padding={1} flexDirection='row' justifyContent='space-between'>
            <Typography className={classes.formHeadings}>{title}</Typography>
            <Box display='flex' height='80px'>
              <Button
                className={classes.button}
                onClick={() => {
                  const baseURL = `/document_categorization?qc_status=${table_status}`;
                  const queryParams = [];
                  if (application_number !== null) {
                    queryParams.push(`application_number=${application_number}`);
                  }
                  if (application_title !== null) {
                    queryParams.push(`application_title=${application_title}`);
                  }
                  const finalURL = `${baseURL}&${queryParams.join('&')}`;
                  history.push(finalURL);
                }}
                variant='contained'>
                Go back
              </Button>
              <Button
                className={classes.button}
                onClick={() => setIsOpen(true)}
                variant='contained'>
                All Documents
              </Button>
            </Box>

            <Dialog open={isOpen} onClose={() => setIsOpen(false)} maxWidth='lg' fullWidth>
              <DialogTitle className={classes.dialog}>
                <Grid
                  container
                  spacing={2}
                  display='flex'
                  justifyContent='space-between'
                  alignItems='flex-start'>
                  <Grid item xs={6} lg={6} className={classes.formHeadings} textAlign='left'>
                    Resources
                  </Grid>
                  <Box display='flex' justifyContent='flex-end'>
                    <IconButton
                      aria-label='delete'
                      onClick={() => setIsOpen(false)}
                      sx={{ fontSize: '30px', color: 'white' }}>
                      <CancelIcon fontSize='inherit' />
                    </IconButton>
                  </Box>
                </Grid>
                <Divider style={{ backgroundColor: 'white' }} />
              </DialogTitle>
              <DialogContent style={{ height: '94%' }} className={classes.dialog}>
                <Stack display='flex' spacing={1} flexDirection='column' sx={{ width: '100%' }}>
                  {applLabel.map((data: any, index: number) => {
                    return (
                      <Stack
                        spacing={1}
                        onClick={() => {
                          handlePDFOpenClick(data.application_docs_id);
                        }}
                        style={{ cursor: 'pointer' }}>
                        <Box display='flex' flexDirection='column' className={classes.dialogLabel}>
                          <Stack direction='row' spacing={1}>
                            <Box>
                              <Box sx={{ padding: '0.2rem', width: '40px', height: '40px' }}>
                                <img
                                  src='https://vivpro-site.s3.amazonaws.com/Help_Page_FAQ/pdf_icon.png'
                                  alt='PDF download'
                                  height='100%'
                                  width='100%'
                                />
                              </Box>
                            </Box>
                            <Typography className={classes.label} align='left'>
                              {data.title}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                    );
                  })}
                </Stack>
              </DialogContent>
            </Dialog>
            <Dialog
              fullWidth
              maxWidth='lg'
              onClose={() => {
                handleClosePDF();
              }}
              aria-labelledby='customized-dialog-title'
              open={openPdf}>
              <DialogTitle className={classes.dialog}>
                <Grid
                  container
                  spacing={2}
                  display='flex'
                  justifyContent='space-between'
                  alignItems='flex-start'>
                  <Grid item xs={6} lg={6} className={classes.formHeadings} textAlign='left'>
                    PDF
                  </Grid>
                  <Box display='flex' justifyContent='flex-end'>
                    <IconButton
                      aria-label='delete'
                      onClick={() => handleClosePDF()}
                      sx={{ fontSize: '30px', color: 'white' }}>
                      <CancelIcon fontSize='inherit' />
                    </IconButton>
                  </Box>
                </Grid>
                <Divider style={{ backgroundColor: 'white' }} />
              </DialogTitle>
              <DialogContent className={classes.dialog}>
                {PDFData ? null : (
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    className={classes.pdfContainer}>
                    <Typography className={classes.notAvailable}>Can't Open This URL</Typography>
                  </Box>
                )}
                {PDFData ? (
                  <Box display='flex' justifyContent='center' className={classes.pdfContainer}>
                    <iframe title='pdf' src={PDFData} className={classes.pdf} />
                  </Box>
                ) : null}
              </DialogContent>
            </Dialog>
          </Box>
        ) : null}
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Paper elevation={1} className={classes.banner}>
              <form onSubmit={handleKeyFormSubmit}>
                <Box margin={1} display='flex' flexDirection='column' justifyContent='center'>
                  <Box padding={1} display='flex'>
                    <Box width='33%' marginRight='2em'>
                      <Typography className={classes.label}>Document Category</Typography>
                    </Box>

                    {editMode ? (
                      <Box display='flex' alignItems='center' justifyContent='end' width='90%'>
                        <Autocomplete
                          fullWidth
                          id='category'
                          value={category}
                          classes={{
                            inputRoot: classes.autocomplete,
                            input: classes.inputDisabled
                          }}
                          onInputChange={(event, newInputValue) => {
                            setcategory(newInputValue);
                          }}
                          options={document_cateogory_options}
                          freeSolo
                          renderInput={params => (
                            <TextField {...params} placeholder='Select Document Category' />
                          )}
                        />
                      </Box>
                    ) : (
                      <Typography align='left' className={classes.details}>
                        {category}
                      </Typography>
                    )}
                  </Box>

                  <Box padding={1} display='flex'>
                    <Typography className={classes.label}>Document Approval Date</Typography>
                    {editMode ? (
                      <Box display='flex' alignItems='center' width='90%'>
                        <TextField
                          id='date'
                          type='date'
                          value={approval_date}
                          onChange={event => setapproval_date(handleChange(event))}
                          className={classes.textfield}
                          fullWidth
                          InputProps={{
                            classes: { input: classes.input },
                            disableUnderline: true
                          }}
                        />
                      </Box>
                    ) : (
                      <Typography align='left' className={classes.details}>
                        {approval_date}
                      </Typography>
                    )}
                  </Box>
                  <Box padding={1} display='flex'>
                    <Box width='33%' marginRight='2em'>
                      <Typography className={classes.label}>Submission Type</Typography>
                    </Box>
                    {editMode ? (
                      <Box display='flex' alignItems='center' justifyContent='end' width='90%'>
                        <Autocomplete
                          fullWidth
                          id='submission_type'
                          value={sub_type}
                          classes={{
                            inputRoot: classes.autocomplete,
                            input: classes.inputDisabled
                          }}
                          onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                            if (newValue !== null) {
                              setsub_type(newValue);
                            }
                          }}
                          options={submission_type}
                          renderInput={params => (
                            <TextField {...params} onChange={event => handleSubmissionTypeTextChange(handleChange(event))} placeholder='Select Submission Type' />
                          )}
                        />
                      </Box>
                    ) : (
                      <Typography align='left' className={classes.details}>
                        {sub_type}
                      </Typography>
                    )}
                  </Box>
                  <Box padding={1} display='flex'>
                    <Box width='33%' marginRight='2em'>
                      <Typography className={classes.label}>Submission Number</Typography>
                    </Box>
                    {editMode ? (
                      <Box display='flex' alignItems='center' width='90%'>
                        <TextField
                          type='number'
                          className={classes.textfield}
                          onChange={event =>
                            event.target.value ? setsub_num(handleChange(event)) : null
                          }
                          value={sub_num}
                          fullWidth
                          placeholder='Enter Submission Number'
                          InputProps={{
                            classes: { input: classes.input },
                            disableUnderline: true
                          }}
                          inputProps={{
                            min: 0
                          }}
                        />
                      </Box>
                    ) : (
                      <Typography align='right' className={classes.details}>
                        {sub_num}
                      </Typography>
                    )}
                  </Box>
                  <Box padding={1} display='flex' flex='1'>
                    <Box width='33%' marginRight='2em'>
                      <Typography className={classes.label}>QC Status</Typography>
                    </Box>
                    {editMode ? (
                      <Box display='flex' alignItems='center' width='90%'>
                        <Autocomplete
                          fullWidth
                          id='qc_status'
                          value={status}
                          classes={{
                            inputRoot: classes.autocomplete,
                            input: classes.inputDisabled
                          }}
                          onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                            if (newValue !== null) {
                              setstatus(newValue);
                            }
                          }}
                          options={qc_status}
                          renderInput={params => (
                            <TextField {...params} placeholder='Select QC status' />
                          )}
                        />
                      </Box>
                    ) : (
                      <Typography align='left' className={classes.details}>
                        {status}
                      </Typography>
                    )}
                  </Box>
                  {editMode ? (
                    <Button type='submit' variant='contained' className={classes.button}>
                      Submit
                    </Button>
                  ) : null}
                </Box>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} lg={6}>
            {pdfurl ? null : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.pdfContainer}>
                <Typography className={classes.notAvailable}>Can&apos;t Open This URL</Typography>
              </Box>
            )}
            {pdfurl ? (
              <Box display='flex' justifyContent='center' className={classes.pdfContainer}>
                <iframe title='pdf' src={pdfurl} className={classes.pdf} />
              </Box>
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default memo(PdfForm);
