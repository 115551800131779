import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    background: theme.palette.backgrounds.dark,
    height: '100vh'
  },
  container: {},
  firstForm: {
    width: '100%',
    height: '100%'
  },
  btn: {
    height: '100%'
  },
  dividerRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  divider: {
    width: '40%',
    background: theme.palette.white,
    opacity: 0.6
  },
  or: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    fontFamily: 'Encode',
    color: theme.palette.white
  },
  banner: {
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(2),
    background: theme.palette.backgrounds.light
  },
  formHeadings: {
    textAlign: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    color: theme.palette.buttons.purple
  },
  label: {
    fontSize: theme.spacing(2),
    color: theme.palette.review.label,
    fontWeight: 'bold'
  },
  radioLabel: {
    fontFamily: 'Roboto',
    color: theme.palette.white
  },
  radio: {
    color: theme.palette.buttons.purple,
    fill: theme.palette.black
  },
  textfield: {
    marginTop: theme.spacing(0.5),
    height: theme.spacing(6),
    background: theme.palette.backgrounds.dark,
    width: theme.spacing(50),
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    color: theme.palette.white,
    padding: theme.spacing(1),
    '&::placeholder': {
      color: theme.palette.white,
      padding: theme.spacing(1)
    }
  },
  dropdownContainer: {
    marginTop: theme.spacing(0.5),
    height: theme.spacing(6),
    background: theme.palette.backgrounds.dark,
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.white,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  icon: {
    color: theme.palette.white
  },
  dropdownText: {
    color: theme.palette.adminHome.primaryBlack,
    fontWeight: 'bold'
  },
  button: {
    background: theme.palette.buttons.purple,
    fontFamily: 'Roboto',
    color: theme.palette.white,
    width: theme.spacing(25),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  }
}));
