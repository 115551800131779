import React from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import Styles from './styles/AdminCardTile.styles';
import { Tooltip } from '@mui/material';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line react/prop-types
const AdminCardTile = ({ link, title, disabled, originalName }) => {
  const history = useHistory();
  const classes = Styles();

  return (
    <Grid item xs={12} lg={4}>
      <Box padding={1} onClick={() => (disabled ? '' : history.push(link))}>
        <Tooltip title={disabled ? 'Under construction' : originalName ?? title}>
          <Button
            elevation={disabled ? 0 : 2}
            className={`${classes.paper} ${disabled ? classes.disabled : ''}`}>

              <Typography variant={"body1"} className={classes.cardText}>{title}</Typography>
          </Button>
        </Tooltip>
      </Box>
    </Grid>
  );
};

export default AdminCardTile;
