/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-props-no-spreading */

// mui
import { Stack, Skeleton } from '@mui/material';

const LatestUpdatesListLoader = () => {
  return (
    <Stack spacing={1}>
      {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i => {
        return <Skeleton key={i} variant='rectangular' height={50} sx={{ borderRadius: 2 }} />;
      })}
    </Stack>
  );
};

export default LatestUpdatesListLoader;
