/* eslint-disable camelcase */
/* eslint-disable object-shorthand */
import React, { useState, useContext, useEffect } from 'react';

// muiv5
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { Auth } from 'aws-amplify';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { submitNonPLRFdaData } from '../../api/AdminPage';

// styles
import styles from './styles/ApprovalEditDialog.styles';

import { ApprovalBaseRecordProps } from './ApprovalTable';
import { DEFAULT_DATE } from '../../const';

interface ApprovalCreateProps {
  docType: string;
  // eslint-disable-next-line no-unused-vars
  closeCreate: (editedData: ApprovalBaseRecordProps | null, reason: any) => void;
}

const ApprovalCreateDialog = ({ closeCreate, docType }: ApprovalCreateProps) => {
  const [data, setData] = useState<ApprovalBaseRecordProps>({
    appl_num: '',
    appl_title: '',
    submission_type: '',
    submission_num: '',
    id: '',
    aa_date: null,
    aa_indication: '',
    rtorfullsubmissiondate: null,
    rtorsubmissiondate: null,
    conversion_withdrawal_status: '',
    full_approval_conversion_withdrawal_date: null,
    breakthrough_indication: '',
    designation_date: null,
    rescinded_date: null,
    date_source: '',
    ft_indication: '',
    pepfar_flag: '',
    email: '',
    name: '',
    status: '',
    docType: docType
  });

  const inputErrorDefault = {
    appl_title: false,
    conversion_withdrawal_status: false
  };

  const [inputError, setInputError] = useState(inputErrorDefault);

  // @ts-ignore
  // eslint-disable-next-line no-unused-vars
  const { state, dispatch } = useContext(Store);

  const getUserDetails = async () => {
    const user = await Auth.currentUserInfo();
    setData(prevData => {
      return { ...prevData, email: user.attributes.email, name: user.attributes['custom:user'] };
    });
  };

  const formatDate = (date: Date) => {
    const day = date.getDate() < 10 ? `0${date.getDate()}` : String(date.getDate());
    const month = date.getMonth() < 9 ? `0${date.getMonth() + 1}` : String(date.getMonth() + 1);
    const year = String(date.getFullYear());
    return `${year}-${month}-${day}`;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault(event);
    setInputError(inputErrorDefault);
    let validateInput: string[] = [];
    if (docType !== 'rtor') {
      validateInput = data.appl_title.split('-');
      let error = false;
      if (validateInput.length !== 3) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: {
            status: true,
            message: 'Enter a valid hiphenated input for Application Numnber. Eg: 004782-SUPPL-171'
          }
        });
        setInputError(prevData => {
          return { ...prevData, appl_title: true };
        });
        return;
      }
      if (docType === 'accelerated') {
        if (!data.conversion_withdrawal_status?.trim()) {
          setInputError(prevData => {
            return { ...prevData, conversion_withdrawal_status: true };
          });
          error = true;
        }
      }
      if (error) return;
    }

    try {
      let payload = {};
      switch (docType) {
        case 'breakthrough':
          payload = {
            appl_num: validateInput[0],
            submission_type: validateInput[1],
            submission_num: validateInput[2],
            breakthrough_indication: data.breakthrough_indication,
            curator_email: data.email,
            curator_name: data.name,
            doctype: docType,
            status: 'Qced',
            designation_date: formatDate(new Date(data.designation_date || new Date(DEFAULT_DATE))),
            rescinded_date: formatDate(new Date(data.rescinded_date || new Date(DEFAULT_DATE))),
            date_source: data.date_source
          };
          break;
        case 'fasttrack':
          payload = {
            appl_num: validateInput[0],
            submission_type: validateInput[1],
            submission_num: validateInput[2],
            ft_indication: data.ft_indication,
            pepfar_flag: data.pepfar_flag,
            designation_date: formatDate(new Date(data.designation_date || new Date(DEFAULT_DATE))),
            curator_email: data.email,
            curator_name: data.name,
            status: 'Qced',
            doctype: docType,
            date_source: data.date_source
          };
          break;
        case 'rtor':
          payload = {
            appl_num: data.appl_num,
            submission_type: data.submission_type,
            submission_num: data.submission_num,
            status: 'Qced',
            doctype: docType,
            curator_email: data.email,
            curator_name: data.name,
            rtorfullsubmissiondate: data.rtorfullsubmissiondate,
            rtorsubmissiondate: data.rtorsubmissiondate
          };
          break;
        default:
          payload = {
            appl_num: validateInput[0],
            submission_type: validateInput[1],
            submission_num: validateInput[2],
            aa_date: formatDate(new Date(data.aa_date || new Date(DEFAULT_DATE))),
            aa_indication: data.aa_indication,
            conversion_withdrawal_status: data.conversion_withdrawal_status,
            full_approval_conversion_withdrawal_date: formatDate(
              new Date(data.full_approval_conversion_withdrawal_date || new Date(DEFAULT_DATE))
            ),
            curator_email: data.email,
            curator_name: data.name,
            status: 'Qced',
            doctype: docType
          };
          break;
      }
      const res = await submitNonPLRFdaData(payload);
      if (res?.data?.Success?.fda_data_qc) {
        if (closeCreate) closeCreate(res.data.Success.fda_data_qc, null);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Operation successful', color: 'success' }
        });
      } else {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Operation failed: ${res?.data?.Error}` }
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('=>(AcceleratedEditDialog.jsx:61) error', e);
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: 'Enter valid information' }
      });
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Container sx={styles.root}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography component='h2' variant='h5' sx={styles.Heading}>
            {`Create ${docType[0].toUpperCase() + docType.slice(1)} `}
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              if (closeCreate) closeCreate(null, null);
            }}
            sx={{
              position: 'absolute',
              right: 15,
              top: 15,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit} style={styles.form}>
          <Grid container spacing={3}>
            {docType !== 'rtor' ? (
              <Grid item xs={12}>
                <TextField
                  error={inputError.appl_title}
                  variant='outlined'
                  fullWidth
                  id='appl_title'
                  label='Application Number'
                  placeholder='Eg: 125327-ORIG-1'
                  value={data.appl_title}
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, appl_title: e.target.value.toUpperCase() };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            ) : (
              <>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='appl_num'
                    label='Application Number'
                    placeholder='Eg: 125327'
                    value={data.appl_num}
                    required
                    onChange={e =>
                      setData(prevData => {
                        return { ...prevData, appl_num: e.target.value.toUpperCase() };
                      })
                    }
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '2vh' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='submission_type'
                    label='Submission Type'
                    placeholder='Eg: ORIG'
                    value={data.submission_type}
                    required
                    onChange={e =>
                      setData(prevData => {
                        return { ...prevData, submission_type: e.target.value.toUpperCase() };
                      })
                    }
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '2vh' }
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant='outlined'
                    fullWidth
                    id='submission_num'
                    label='Submission Number'
                    placeholder='Eg: 121'
                    value={data.submission_num}
                    required
                    onChange={e =>
                      setData(prevData => {
                        return { ...prevData, submission_num: e.target.value.toUpperCase() };
                      })
                    }
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    InputLabelProps={{
                      style: { fontSize: '2vh' }
                    }}
                  />
                </Grid>
              </>
            )}
            {docType === 'accelerated' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='aa_indication'
                  label='Accelerated Indications'
                  value={data.aa_indication}
                  minRows={2}
                  maxRows={Infinity}
                  multiline
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, aa_indication: e.target.value };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
            {docType === 'fasttrack' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='ft_indication'
                  label='Fasttrack Indications'
                  value={data.ft_indication}
                  maxRows={Infinity}
                  minRows={2}
                  multiline
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, ft_indication: e.target.value };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
            {docType === 'breakthrough' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='breakthrough_indication'
                  label='Breakthrough Indications'
                  value={data.breakthrough_indication}
                  maxRows={Infinity}
                  minRows={2}
                  multiline
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, breakthrough_indication: e.target.value };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
            {docType === 'rtor' && (
              <>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Submission Date'
                      inputFormat='yyyy-MM-dd'
                      openTo='year'
                      views={['year', 'month', 'day']}
                      value={data.rtorsubmissiondate}
                      onChange={newValue => {
                        setData(prevData => {
                          return {
                            ...prevData,
                            rtorsubmissiondate: newValue || undefined
                          };
                        });
                      }}
                      InputProps={{
                        style: { fontSize: '2vh' }
                      }}
                      renderInput={params => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          fullWidth
                          InputLabelProps={{
                            style: { fontSize: '2vh' }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label='Full Submission Date'
                      inputFormat='yyyy-MM-dd'
                      openTo='year'
                      views={['year', 'month', 'day']}
                      value={data.rtorfullsubmissiondate}
                      onChange={newValue => {
                        setData(prevData => {
                          return {
                            ...prevData,
                            rtorfullsubmissiondate: newValue || undefined
                          };
                        });
                      }}
                      InputProps={{
                        style: { fontSize: '2vh' }
                      }}
                      renderInput={params => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          fullWidth
                          InputLabelProps={{
                            style: { fontSize: '2vh' }
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </>
            )}
            {docType === 'accelerated' && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Accelerated Date'
                    inputFormat='MM/dd/yyyy'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={data.aa_date}
                    onChange={newValue => {
                      setData(prevData => {
                        return {
                          ...prevData,
                          aa_date: newValue || null
                        };
                      });
                    }}
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: { fontSize: '2vh' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {docType === 'accelerated' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  error={inputError.conversion_withdrawal_status}
                  id='conversion_withdrawal_status'
                  label='Conversion Withdrawal Status'
                  value={data.conversion_withdrawal_status}
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, conversion_withdrawal_status: e.target.value };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
            {docType === 'accelerated' && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Full Approval Conversion Withdrawal Date'
                    inputFormat='MM/dd/yyyy'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={data.full_approval_conversion_withdrawal_date}
                    onChange={newValue => {
                      setData(prevData => {
                        return {
                          ...prevData,
                          full_approval_conversion_withdrawal_date: newValue || null
                        };
                      });
                    }}
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: { fontSize: '2vh' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {docType === 'fasttrack' && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='pepfar_flag'
                  label='Pepfar Flag'
                  value={data.pepfar_flag}
                  onChange={e => {
                    setData(prevData => {
                      return {
                        ...prevData,
                        pepfar_flag: e.target.value
                      };
                    });
                  }}
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
            {!['accelerated', 'rtor'].includes(docType) && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Designation Date'
                    inputFormat='MM/dd/yyyy'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={data.designation_date}
                    onChange={newValue => {
                      setData(prevData => {
                        return {
                          ...prevData,
                          designation_date: newValue || null
                        };
                      });
                    }}
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: { fontSize: '2vh' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {docType === 'breakthrough' && (
              <Grid item xs={12}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    label='Rescinded Date'
                    inputFormat='MM/dd/yyyy'
                    openTo='year'
                    views={['year', 'month', 'day']}
                    value={data.rescinded_date}
                    onChange={newValue => {
                      setData(prevData => {
                        return {
                          ...prevData,
                          rescinded_date: newValue || null
                        };
                      });
                    }}
                    InputProps={{
                      style: { fontSize: '2vh' }
                    }}
                    renderInput={params => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        fullWidth
                        InputLabelProps={{
                          style: { fontSize: '2vh' }
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            )}
            {!['accelerated', 'rtor'].includes(docType) && (
              <Grid item xs={12}>
                <TextField
                  variant='outlined'
                  fullWidth
                  id='date_source'
                  label='Date Source'
                  value={data.date_source}
                  maxRows={Infinity}
                  multiline
                  onChange={e =>
                    setData(prevData => {
                      return { ...prevData, date_source: e.target.value };
                    })
                  }
                  InputProps={{
                    style: { fontSize: '2vh' }
                  }}
                  InputLabelProps={{
                    style: { fontSize: '2vh' }
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Button type='submit' fullWidth variant='contained' color='primary' sx={styles.submit}>
            SUBMIT
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default ApprovalCreateDialog;
