import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    height: '100%',
    background: theme.palette.backgrounds.dark,
    [theme.breakpoints.down('sm')]: {
      height: '100%'
    }
  },
  container: {
    paddingTop: theme.spacing(10)
  },
  grid: {
    maxHeight: '80vh',
    height: '100%',
    overflowY: 'scroll'
  },
  reviewBox: {
    background: theme.palette.backgrounds.light,
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1.5)
  },
  heading: {
    marginTop: theme.spacing(0.5),
    color: theme.palette.white,
    fontSize: theme.spacing(3),
    fontWeight: 'bold',
    fontFamily: 'Roboto'
  },
  label: {
    fontSize: theme.spacing(1.5),
    color: theme.palette.review.label,
    fontWeight: 'bold',
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2.5)
    }
  },
  jointBox: {
    width: '100%'
  },
  addBtn: {
    marginTop: theme.spacing(2),
    background: theme.palette.review.label,
    height: theme.spacing(4),
    borderRadius: theme.spacing(1.5)
  },
  details: {
    color: '#6f7594',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  textfield: {
    width: '50%',
    marginTop: theme.spacing(0.5),
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5),
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    }
  },
  '::-webkit-calendar-picker-indicator': {
    filter: 'invert(1)'
  },
  credit: {
    marginTop: theme.spacing(1),
    width: '100%'
  },
  crdates: {
    color: theme.palette.white,
    fontSize: theme.spacing(2)
  },
  crLabel: {
    color: theme.palette.review.label,
    fontSize: theme.spacing(1.5)
  },
  input: {
    color: theme.palette.white,
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    '&::placeholder': {
      color: theme.palette.white,
      fontSize: theme.spacing(1)
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  input1: {
    minWidth: '100% !important',
    color: theme.palette.white,
    fontSize: theme.spacing(2.5),
    marginLeft: theme.spacing(1),
    '&::placeholder': {
      color: theme.palette.white,
      fontSize: theme.spacing(2)
    }
  },
  button: {
    background: theme.palette.buttons.purple,
    color: theme.palette.white,
    width: theme.spacing(25),
    fontWeight: 'bold',
    marginRight: theme.spacing(0.5),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
  dialog: {
    background: theme.palette.white
  },
  confirm: {
    background: theme.palette.buttons.purple,
    color: theme.palette.white,
    fontWeight: 'bold',
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      background: theme.palette.buttons.purple
    }
  },
  dropdownContainer: {
    marginTop: theme.spacing(0.5),
    height: theme.spacing(4),
    background: theme.palette.backgrounds.dark,
    width: '100%',
    color: theme.palette.white,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  icon: {
    color: theme.palette.white
  },
  dropdownText: {
    color: theme.palette.adminHome.primaryBlack,
    fontWeight: 'bold'
  },
  pdfContainer: {
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1.5),
    background: theme.palette.backgrounds.light,
    [theme.breakpoints.down('sm')]: {
      minHeight: '80vh'
    }
  },
  pdf: {
    height: '100%',
    width: '100%',
    borderRadius: theme.spacing(1.5)
  },
  notAvailable: {
    color: theme.palette.white,
    fontSize: theme.spacing(3),
    fontWeight: 'bold'
  },
  gridLabel: {
    minHeight: '50vh',
    maxHeight: '80vh',
    height: '100%',
    overflowY: 'scroll'
  },
  detailsLabel: {
    color: '#6f7594',
    width: '60%',
    fontSize: theme.spacing(1.5),
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  textarea: {
    color: 'white',
    border: 'unset',
    width: '50%',
    marginTop: theme.spacing(0.5),
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5),
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    },
    focus: {
      border: 'unset'
    }
  },
  textarea1: {
    color: 'white',
    border: 'unset',
    width: '100%',
    marginTop: theme.spacing(0.5),
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5),
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    },
    focus: {
      border: 'unset'
    }
  },
  disabled: {
    color: 'grey'
  }
}));
