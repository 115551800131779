import React from 'react';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

const MeetingAgenda = ({ agenda, classes }) => {
  return (
    <Box
      className={`${classes.reviewBox} ${classes.webPageContainer}`}
      display='flex'
      flexDirection='column'
      alignItems='center'
      justifyContent='flex-start'>
      <h1>Meeting Agenda</h1>
      <Typography>{agenda}</Typography>
    </Box>
  );
};

export default MeetingAgenda;
