/* eslint-disable react/no-danger */

// mui
import { Stack, Typography, Link } from '@mui/material';

const HtmlContent = ({
  content,
  fromDate,
  toDate,
  selectedIdentifier
}: {
  content: string;
  fromDate: string;
  toDate: string;
  selectedIdentifier: any;
}) => {
  return (
    <Stack
      p={2}
      sx={{ border: '1px solid black', width: '100%', height: '90vh', overflow: 'auto' }}>
      <Stack direction='row' justifyContent='space-between'>
        <Typography variant='subtitle1'>
          Date range: {fromDate} - {toDate}
        </Typography>
        <Link href={selectedIdentifier.source_url} target='_blank' rel='noopener'>
          Open in new tab
        </Link>
      </Stack>
      <div dangerouslySetInnerHTML={{ __html: content }} />
    </Stack>
  );
};

export default HtmlContent;
