import DataEntry from '../components/DataEntry/DataEntry';
import QCDocumentCheck from '../components/DataEntry/QCDocumentCheck';

const REVIEW_ROUTES = [
  {
    id: 'labelQc',
    path: '/reviews',
    component: DataEntry,
    exact: true
  },
  {
    id: 'reviewPage',
    path: '/reviews/:applNo/:subType/:subNo/:docType',
    component: QCDocumentCheck,
    exact: true
  }
];
export default REVIEW_ROUTES;
