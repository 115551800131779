import React from "react";

const User = ()=> {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16.745"
      height="17.725"
      viewBox="0 0 16.745 17.725"
    >
      <path
        d="M16.088 14.465a8.41 8.41 0 00-4.456-4.456L11.606 10a5.521 5.521 0 10-6.466 0l-.027.011A8.369 8.369 0 000 17.542a.178.178 0 00.178.183h1.336a.178.178 0 00.178-.174A6.682 6.682 0 0113.1 13a6.639 6.639 0 011.955 4.549.176.176 0 00.178.174h1.336a.178.178 0 00.178-.183 8.3 8.3 0 00-.657-3.077zM8.373 9.352A3.832 3.832 0 1111.08 8.23a3.805 3.805 0 01-2.707 1.122z"
        opacity="0.5"
      ></path>
    </svg>
  );
}

export default User;
