/* eslint-disable no-unused-vars */
/**
 * this object will contain all the diiferent typography variants being used in the application.
 * for the <Typograhy /> component for material-ui
 * each value is px by default
 */
import React from 'react';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    activeIngredient: React.CSSProperties;
    tradeName: React.CSSProperties;
    activityTitle: React.CSSProperties;
    activityDescription: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    activeIngredient: React.CSSProperties;
    tradeName: React.CSSProperties;
    activityTitle: React.CSSProperties;
    activityDescription: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    activeIngredient: true;
    tradeName: true;
    activityTitle: true;
    activityDescription: true;
  }
}

const typography = {
  // fontSize: 16,
  fontFamily: 'Nunito Sans',
  // h1: {
  //   fontSize: 36
  // },
  // h2: {
  //   fontSize: 22
  // },
  // h3: {
  //   fontSize: 20
  // },
  // h4: {
  //   fontSize: 18
  // },
  // subtitle1: {
  //   fontSize: 16
  // },
  // subtitle2: {
  //   fontSize: 14
  // },
  // body1: {
  //   fontSize: 12
  // },
  // body2: {
  //   fontSize: 10
  // },
  activeIngredient: {
    fontSize: 18,
    textTransform: 'capitalize'
  },
  tradeName: {
    fontSize: 20,
    textTransform: 'uppercase'
  },
  activityTitle: {
    fontFamily: 'Nunito Sans',
    weight: `${400} !important`,
    size: 12,
    color: '#4F4F4F !important'
  },
  activityDescription: {
    fontFamily: 'Nunito Sans',
    weight: `${600} !important`,
    size: 16,
    color: '#000000 !important'
  }
};

export default typography;
