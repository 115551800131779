import {
  memo,
  useContext,
  useState,
  useEffect,
  SyntheticEvent,
  Dispatch,
  SetStateAction
} from 'react';
import { Auth } from 'aws-amplify';

// material
import {
  DialogTitle,
  IconButton,
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  FormControlLabel,
  Switch,
  Autocomplete
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContent } from '@material-ui/core';

import { ProductResourcePageCurationKeys, ProductResourcePageEditDialogProps } from './types';
import { qcStatuses, getSortedLabels, DefaultProductResourcePageCurationFields } from './utils';

// api
import { getPmdaProductResource, updatePmdaProductResource } from '../../api/pmda';

// Store
import Actions from '../../store/actions';
import Store from '../../store';

type TextFieldCardProps = {
  id: string;
  labelName: string;
  data: ProductResourcePageCurationKeys;
  setData: Dispatch<SetStateAction<ProductResourcePageCurationKeys>>;
};

const TextFieldCard = ({ id, labelName, data, setData }: TextFieldCardProps) => {
  return (
    <TextField
      variant='outlined'
      fullWidth
      id={id}
      label={labelName}
      placeholder={labelName}
      value={data[id]}
      onChange={e =>
        setData((prevData: ProductResourcePageCurationKeys) => {
          const value = e.target.value.trim();
          if (value === '') {
            return { ...prevData, [id]: 0 };
          }
          const intValue = parseInt(value, 10);
          if (Number.isNaN(intValue)) {
            return prevData;
          }
          return { ...prevData, [id]: intValue };
        })
      }
      InputProps={{
        style: { fontSize: '2vh', marginBottom: '15px' }
      }}
      InputLabelProps={{
        style: { fontSize: '2vh', fontWeight: 'bold' }
      }}
    />
  );
};

const DefaultProductResourcePageCuration: ProductResourcePageCurationKeys = {
  warnings: 0,
  contraindications: 0,
  composition_and_product_description: 0,
  indications: 0,
  precautions_concerning_indications: 0,
  dosage_and_administration: 0,
  precautions_concerning_dosage_and_administration: 0,
  important_precautions: 0,
  precautions_concerning_patients_with_specific_backgrounds: 0,
  interactions: 0,
  adverse_reactions: 0,
  influence_on_laboratory_tests: 0,
  overdosage: 0,
  precautions_concerning_use: 0,
  other_precautions: 0,
  pharmacokinetics: 0,
  clinical_studies: 0,
  pharmacology: 0,
  physicomechanical_properties: 0,
  precautions_for_handling: 0,
  approval_conditions: 0,
  packaging: 0,
  references: 0,
  reference_request_and_contact_information: 0,
  precaution_concerning_health_insurance_benefits: 0,
  marketing_authorization_holder_etc: 0
};

const ProductResourcePageEditDialog = ({
  closeEdit,
  productResource
}: ProductResourcePageEditDialogProps) => {
  const { dispatch } = useContext<any>(Store);
  const [pdfUrl, setPdfUrl] = useState('');
  const [originalPdfUrl, setOriginalPdfUrl] = useState('');
  const [autoSort, setAutoSort] = useState(true);
  const [qcStatus, setQcStatus] = useState('');
  const [productResourcePageCurationFields, setProductResourcePageCurationFields] = useState(
    DefaultProductResourcePageCurationFields
  );
  const [productResourcePageCuration, setProductResourcePageCuration] =
    useState<ProductResourcePageCurationKeys>(DefaultProductResourcePageCuration);

  useEffect(() => {
    const addNewFields = (newSections: string[]) => {
      const defaultSections = productResourcePageCurationFields.map(currentSection => {
        return currentSection.id;
      });

      const unmatchedNewSections = newSections.filter(
        currentSection => !defaultSections.includes(currentSection)
      );

      if (!unmatchedNewSections || unmatchedNewSections.length < 1) {
        return;
      }

      setProductResourcePageCurationFields([...productResourcePageCurationFields]);
    };

    const getProductResource = async () => {
      try {
        const result = await getPmdaProductResource(
          productResource?.resource_id,
          productResource?.package_number
        );

        if (result.status !== 200 || !result?.data?.body || result.data.status !== 200) {
          throw new Error('Error: Product Resource not available');
        }

        setQcStatus(result.data.body.qc_status);
        setPdfUrl(result.data.body.pdf_url);
        setOriginalPdfUrl(result.data.body.s3_path);
        if (!result.data.body.page_number) {
          setProductResourcePageCuration(DefaultProductResourcePageCuration);
          return;
        }

        const previousCuration = result.data.body.page_number;

        const newSections: {
          [k: string]: number;
        } = {};

        const validKeys = Object.fromEntries(
          Object.entries(previousCuration).filter(([key]) => !/undefined/.test(key))
        );

        if (validKeys) {
          Object.keys(validKeys).forEach(key => {
            newSections[key] = previousCuration[key].page_number;
          });

          addNewFields(Object.keys(validKeys));
        }
        // make sure that setProductResourcePageCuration only has valid keys that are in DefaultProductResourcePageCuration
        const validSections: any = Object.fromEntries(
          Object.entries(newSections).filter(([key]) =>
            Object.keys(DefaultProductResourcePageCuration).includes(key)
          )
        );
        setProductResourcePageCuration(validSections);
      } catch (error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: (error as Error).message }
        });
      }
    };

    getProductResource();
  }, []);

  const handleSubmit = async (event: SyntheticEvent) => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();

      const separation: {
        [k: string]: {
          page_number: number;
        };
      } = {};

      Object.keys(productResourcePageCuration).forEach(currentKey => {
        const value = {
          page_number: productResourcePageCuration[currentKey],
          heading: productResourcePageCurationFields.find(field => field.id === currentKey)
            ?.labelName
        };

        separation[currentKey] = value;
      });

      const payload = {
        sections: separation,
        s3_path: originalPdfUrl,
        qc_status: qcStatus.toLowerCase(),
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      };

      const result = await updatePmdaProductResource(
        productResource?.resource_id,
        productResource?.package_number,
        payload
      );

      if (
        result.status !== 200 ||
        !result?.data ||
        result.data.status !== 200 ||
        result.data.error
      ) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: result.data.message }
        });

        return;
      }

      closeEdit();
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: result.data.body.message, color: 'success' }
      });
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: (error as Error).message }
      });
    }
  };

  return (
    <Container sx={{ overflow: 'hidden' }}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='p'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Product Resource Page Number Curation
          </Typography>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={autoSort}
                  onChange={() => {
                    setAutoSort(!autoSort);
                  }}
                />
              }
              label='Auto sorting'
            />
          </Grid>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} display='flex'>
            <Grid item xs={12} lg={6} mb={3} sx={{ overflow: 'auto', height: '70vh', padding: 2 }}>
              <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    {(autoSort
                      ? getSortedLabels(
                          productResourcePageCurationFields,
                          productResourcePageCuration
                        )
                      : productResourcePageCurationFields
                    ).map(currentField => {
                      return (
                        <TextFieldCard
                          key={currentField.id}
                          id={currentField.id}
                          labelName={currentField.labelName}
                          data={productResourcePageCuration}
                          setData={setProductResourcePageCuration}
                        />
                      );
                    })}
                  </Grid>

                  <Grid item xs={12}>
                    <Autocomplete
                      id='qc_status'
                      fullWidth
                      options={qcStatuses}
                      value={qcStatus}
                      onChange={(_, newValue) => {
                        if (newValue) {
                          setQcStatus(newValue);
                        }
                      }}
                      sx={{ fontSize: '2vh' }}
                      renderInput={params => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <TextField {...params} label='QC Status' variant='outlined' required />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} display='flex' direction='column'>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      color='primary'
                      style={{ color: 'white' }}>
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ height: '70vh' }}>
              <iframe
                src={pdfUrl}
                title='Product Resource'
                style={{ height: '100%', width: '100%' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </div>
    </Container>
  );
};

export default memo(ProductResourcePageEditDialog);
