import React, { ChangeEvent, useContext, useState, useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { debounce } from 'lodash';
import CssBaseline from '@mui/material/CssBaseline';
import LinearProgress from '@mui/material/LinearProgress';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import themev5 from '../../themev5';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';

// Styles
import Styles from './styles/curationdashboard.styles';
import styles from '../Datagrid/Datagrid.styles';

// Store
import Store from '../../store';
import Actions from '../../store/actions';

// component
import MonographEditDialog from './MonographEditDialog';

// api
import { getCanadaProductList } from '../../api/canada';
import renderCellExpand from '../Datagrid/DatagridPopover';

export interface Field {
  id: string;
  label: string;
  value: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
}

const qcStatus = ['All Documents', 'Escalate', 'Non Qced', 'Qced', 'Automated'];

const monographCuration = () => {
  const classes = Styles();
  const [pageSize, setPageSize] = useState(10);
  const [productData, setProductData] = useState<Array<any>>([]);
  const [pageNo, setPageNo] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const [loading, setloading] = useState(true);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [vinNumber, setVinNumber] = useState('');
  const [tradeName, setTradeName] = useState('');
  const [submissionNumber, setSubmissionNumber] = useState('');
  const rowsPerPageOptions = [10, 20, 50, 100];
  const [reload, setReload] = useState<boolean>(true);
  const [vinNumberData, setVinNumberData] = useState<object>({});
  const [status, setStatus] = useState('All Documents');
  const { dispatch } = useContext<any>(Store);

  const debouncedChangeHandler = () => {
    setReload(prevState => !prevState);
  };

  const handleChange = debounce(debouncedChangeHandler, 300);

  const handleVinNumber = (value: string) => {
    setVinNumber(value);
    handleChange();
  };

  const handleTradeName = (value: string) => {
    setTradeName(value);
    handleChange();
  };

  const handleSubmissionNumber = (value: string) => {
    setSubmissionNumber(value);
    handleChange();
  };

  const fields: Field[] = [
    { id: 'vin_number', label: 'Vin Number', value: vinNumber, onChange: handleVinNumber },
    { id: 'trade_name', label: 'TradeName', value: tradeName, onChange: handleTradeName },
    {
      id: 'submission_number',
      label: 'Submission Number',
      value: submissionNumber,
      onChange: handleSubmissionNumber
    }
  ];

  const openEditDialog = (data: any) => {
    setVinNumberData(data);
    setShowEditDialog(true);
  };
  const closeEditDialog = () => {
    setShowEditDialog(false);
    setReload(prevState => !prevState);
  };

  useEffect(() => {
    const getData = async () => {
      try {
        setloading(true);
        const result = await getCanadaProductList(
          pageSize,
          pageNo,
          status,
          vinNumber,
          tradeName,
          submissionNumber
        );

        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.results) {
          setloading(false);
          const resultData = result.data.body.results;
          setRowCount(result.data.body.total);
          setProductData(resultData);
        } else {
          setloading(false);
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getData();
  }, [pageNo, pageSize, reload]);

  const DATAGRID_COLUMN = [
    {
      field: 'Edit',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            className={classes.Button}
            onClick={() => {
              openEditDialog(params.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'vin',
      headerName: 'Vin Number',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'din',
      headerName: 'Din Number',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'brand_name',
      headerName: 'Trade Name',
      width: 350,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'qc_status',
      headerName: 'Qced Status',
      width: 350,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'control_number',
      headerName: 'Submission Number',
      width: 250,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'indication',
      headerName: 'Indication',
      width: 350,
      headerAlign: 'center',
      align: 'center',
      renderCell: renderCellExpand
    }
  ];

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        <Autocomplete
          id='qc_status'
          value={status}
          onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
            if (newValue !== null) {
              setStatus(newValue);
              setPageNo(0);
              handleChange();
            }
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }}
          options={qcStatus}
          renderInput={(params: any) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <TextField {...params} placeholder='QC Status' label='QC Status' variant='outlined' />
          )}
        />
        {fields.map(row => (
          <TextField
            key={row.id}
            variant='outlined'
            type='search'
            id={row.id}
            label={row.label}
            value={row.value}
            sx={{ paddingRight: '30px' }}
            InputProps={{
              style: { fontSize: '1.5vh', minWidth: '250px', paddingRight: '20px' }
            }}
            InputLabelProps={{
              style: { fontSize: '1.5vh' }
            }}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              setPageNo(0);
              row.onChange(e.target.value);
            }}
          />
        ))}
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          style={{ height: '100%' }}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={productData}
          columns={DATAGRID_COLUMN as any}
          rowCount={rowCount}
          pageSize={pageSize}
          getRowId={row => row.id}
          onPageSizeChange={newPageSize => {
            setPageSize(newPageSize);
            setPageNo(0);
          }}
          onPageChange={setPageNo}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={pageNo}
          paginationMode='server'
          components={{
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        <Dialog
          open={showEditDialog}
          onClose={closeEditDialog}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          <MonographEditDialog closeEdit={closeEditDialog} productData={vinNumberData} />
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default React.memo(monographCuration);
