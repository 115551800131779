import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

// material ui
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AdminFormTextField from '../StyledComponents/TextField';

// api
import { getFdaData, submitNonPLRFdaData } from '../../api/AdminPage';

// helpers
import { handleChange } from '../../helpers/changeHandler';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// styles
import Styles from '../RLD/styles/RLD.styles';
import PNReviewFda from '../FDA/PNReviewFda';

const Fasttrack = () => {
  const { applNo, subType, subNo } = useParams();
  const history = useHistory();
  const classes = Styles();
  const docType = 'fasttrack';
  const { state, dispatch } = useContext(Store);
  const [applType, setApplType] = useState();
  const [indicationsAndUsage, setIndication] = useState();
  const [pepfarFlag, setPepfarFlag] = useState();
  const [isAdd, setIsAdd] = useState(false);

  useEffect(() => {
    async function fetchData() {
      let res;
      try {
        res = await getFdaData(applNo, subType, subNo, docType);
        if (res.data.Success.fda_data_qc.length > 0) {
          setIsAdd(false);
        } else setIsAdd(true);
      } catch (error) {
        history.push(`/approval`);
      }
    }

    fetchData();
  }, [dispatch, history]);

  const handleKeyFormSubmit = async e => {
    e.preventDefault();
    try {
      const key = `${applNo}-${subType}-${subNo}`;
      const payload = {
        appl_type: applType,
        appl_num: applNo,
        submission_type: subType,
        submission_num: subNo,
        ft_indication: indicationsAndUsage,
        indication_status: 'Qced',
        pepfar_flag: pepfarFlag,
        key,
        curator_email: state.userStub.email,
        curator_name: state.userStub['custom:user'],
        doctype: 'fasttrack'
      };

      await submitNonPLRFdaData(payload);
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Fasttrack table was successfully updated ',
          color: 'success'
        }
      });
      setApplType('');
      history.push('/fda');
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Something went wrong'
        }
      });
    }
  };
  if (isAdd) {
    return (
      <Box className={classes.root} display='flex' alignItems='center'>
        <Container maxWidth='lg' className={classes.container}>
          <Paper elevation={1} className={classes.banner}>
            <Typography className={classes.formHeadings}>
              Enter details for Fasttrack data
            </Typography>
            <form onSubmit={handleKeyFormSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={12} lg={12}>
                  <Box margin={1} display='flex' flexDirection='column' alignItems='center'>
                    <Typography className={classes.label}>Application Type</Typography>
                    <AdminFormTextField
                      required
                      value={applType}
                      onChange={event => setApplType(handleChange(event))}
                      placeholder='Enter application type Eg: BLA'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                    <Typography className={classes.label}>Indication Text</Typography>
                    <AdminFormTextField
                      required
                      value={indicationsAndUsage}
                      onChange={event => setIndication(handleChange(event))}
                      placeholder='Enter indication text '
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                    <Typography className={classes.label}>Pepfar Flag</Typography>
                    <AdminFormTextField
                      required
                      value={pepfarFlag}
                      onChange={event => setPepfarFlag(handleChange(event))}
                      placeholder='Enter Pepfar Flag'
                      InputProps={{
                        classes: { input: classes.input },
                        disableUnderline: true
                      }}
                    />
                    <Button type='submit' variant='contained' className={classes.button}>
                      Submit
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Container>
      </Box>
    );
  }
  return (
    <PNReviewFda applNo={applNo && applNo} subType={subType} subNo={subNo} docType={docType} />
  );
};

export default Fasttrack;
