import { useMemo } from 'react';

const useSearchedTableData = ({ tableData = [], search, ignoreFields = [] }: any) => {
  // eslint-disable-next-line
  const applySearchCondition = (tableData: any, search: any, ignoreFields: any) => {
    if (!search) {
      return tableData;
    }

    return tableData.filter((rowData: any) => {
      // Get each words in the search term
      const searchWords = search.split(' ');

      // Check if each word exists in any of the row data props
      const foundWords = searchWords.filter((word: any) => {
        // eslint-disable-next-line no-param-reassign
        word = word.trim();
        let includeThisRow = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const key in rowData) {
          // Exclude not searchable field
          if (ignoreFields.includes(key)) {
            // eslint-disable-next-line no-continue
            continue;
          }

          // Check if props has the value which includes searched word
          if (rowData[key] instanceof Array) {
            includeThisRow =
              rowData[key]?.findIndex(
                (value: string) => value.toLowerCase().indexOf(word.toLowerCase()) > -1
              ) > -1;
          } else if (rowData[key]) {
            const stringData = String(rowData[key]);
            includeThisRow = stringData.toLowerCase().indexOf(word.toLowerCase()) > -1;
          }
          // If any one column value is matched, then consider it as search result.
          if (includeThisRow) {
            return true;
          }
        }
        return false;
      });

      return foundWords.length === searchWords.length;
    });
  };

  const searchedData = useMemo(
    () => applySearchCondition(tableData, search, ignoreFields),
    [tableData, search, ignoreFields]
  );
  return {
    searchedData
  };
};

export default useSearchedTableData;
