import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  container: {},

  banner: {
    width: '100%',
    height: 'calc(100vh - 170px)',
    borderRadius: theme.spacing(2),
    background: theme.palette.white
  },
  formHeadings: {
    textAlign: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    color: '#3AB09E'
  },
  errorCell: {
    height: '100%',
    width: '100%',
    border: '1px solid red'
  }
}));
