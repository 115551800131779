import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';

// material ui
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Autocomplete } from '@material-ui/lab';

import { getLabelData, submitEMAProceduralStepsData } from '../../api/AdminPage';

import AuthContext from '../../store/Auth/AuthProvider';

// styles
import Styles from '../DataEntry/styles/Review.styles';
import { EMAProductForm, FLOW_TYPE_ADD, FLOW_TYPE_EDIT } from './EMAProductsReviewForm';
// store
import GlobalStore from '../../store';
import GlobalActions from '../../store/actions';
import { ViewData } from './EMAProductsReviewViewData';

const DIALOG_TYPE_CONFIRM_FORM_SUBMIT = 'FORM_SUBMIT';
const DIALOG_TYPE_CANCEL_FORM = 'CANCEL_FORM';
const ConfirmationDialog = ({ dialogOpen, onClose, onYesClick, title, message }) => {
  const classes = Styles();

  return (
    <Dialog
      open={dialogOpen}
      onClose={onClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'>
      <Box className={classes.dialog}>
        <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>{message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} className={classes.confirm}>
            No
          </Button>
          <Button onClick={onYesClick} className={classes.confirm} autoFocus>
            Yes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
};

const SearchBox = ({ options = [], onSubmit }) => {
  const classes = Styles();
  const [inputText, setInputText] = useState('');
  const handleInputText = event => {
    setInputText(event.target.value);
  };
  return (
    <Autocomplete
      id='combo-box-demo'
      fullWidth
      options={options}
      getOptionLabel={option => option.label}
      onChange={(event, newValue) => {
        onSubmit(newValue?.id || '');
      }}
      onInputChange={handleInputText}
      renderOption={(option, i) => {
        return (
          <Box
            padding={1}
            display='flex'
            flex={1}
            fullWidth
            flexDirection='row'
            justifyContent='space-between'>
            <Box flex={3}>
              <Typography padding={1}>{option.label}</Typography>
            </Box>
            <Box flex={1} alignSelf='end'>
              <Typography padding={1}>{option.tag || 'TBD'}</Typography>
            </Box>
          </Box>
        );
      }}
      noOptionsText={'Not found! Please re-check the Application Number or Add a New Application.'}
      renderInput={params => (
        <TextField
          {...params}
          className={classes.textfield}
          fullWidth
          id='ema-product-applno'
          placeholder='Select an Application Number.'
          InputProps={{
            classes: { input: classes.input },
            ...params.InputProps
          }}
        />
      )}
    />
  );
};

const Buttons = ({ onBackClick, onEditClick, isEditEnabled = true, onAddClick, isAddEnabled }) => {
  const classes = Styles();
  return (
    <Box display='flex' padding={1} flexDirection='row' justifyContent='flex-start'>
      <Button className={classes.button} onClick={onBackClick} variant='contained'>
        Go back
      </Button>
      {isEditEnabled ? (
        <Button className={classes.button} onClick={onEditClick} variant='contained'>
          Edit
        </Button>
      ) : null}
      {isAddEnabled && (
        <Button className={classes.button} onClick={onAddClick} variant='contained'>
          Add
        </Button>
      )}
    </Box>
  );
};

Buttons.propTypes = {
  onBackClick: PropTypes.func.isRequired,
  onEditClick: PropTypes.func.isRequired,
  isEditEnabled: PropTypes.bool.isRequired,
  onAddClick: PropTypes.func.isRequired
};

const EMAProductsReviewPage = () => {
  const classes = Styles();
  const history = useHistory();
  const { applNo: prodNo } = useParams();
  // const prodNo = useLocation().pathname.split('/')[4];

  const { currentUser } = useContext(AuthContext);
  const { dispatch } = useContext(GlobalStore);

  const [email, setEmail] = useState(null);
  const [name, setName] = useState(null);
  const [allDetails, setAllDetails] = useState([]);
  const [productUrl, setProductUrl] = useState('');
  const [pdfUrl, setPdfUrl] = useState(null);
  const [isAddFlow, setAddFlow] = useState(false);
  const [isEditFlow, setEditFlow] = useState(false);
  const [dialog, setDialog] = useState({});
  const [formData, setFormData] = useState();
  const [isLoading, setLoading] = useState(false);

  const [selectedApplicationData, setSelectedApplicationData] = useState();
  const [numberOfApps, setNumberOfApps] = useState(0);
  const [numberOfNewApps, setNumberOfNewApps] = useState(0);

  const handleEditClick = () => {
    setEditFlow(true);
    setAddFlow(false);
  };

  const handleAddClick = () => {
    setEditFlow(false);
    setAddFlow(true);
  };

  const handleSearchSubmit = selecteId => {
    const selectedItem = allDetails.find(applcn => applcn.id === selecteId);
    if (selecteId) {
      if (selectedItem) {
        setSelectedApplicationData(selectedItem);
      }
    }
  };

  useEffect(() => {
    async function getData() {
      setLoading(true);
      setEmail(currentUser.email);
      setName(currentUser['custom:user']);
      const response = await getLabelData(prodNo, null, null, 'products');

      if (!response.data.Success.pdf_s3_url) {
        await dispatch({
          type: GlobalActions.SET_ALERT,
          value: {
            status: true,
            message: `No data found for the application number EMEA-H-C-${prodNo}`
          }
        });
      } else {
        setPdfUrl(response.data.Success.pdf_s3_url);
        setAllDetails(response.data.Success.products_qc);
        setNumberOfApps(response.data.Success.products_qc.length);
        setProductUrl(response.data.Success.pdf_s3_path);
      }
      setLoading(false);
    }

    getData();
  }, [currentUser, prodNo, dispatch]);

  const submitForm = async () => {
    setDialog({});
    if (formData.indication_status) {
      const product_number = `EMEA-H-C-${prodNo}`;
      const payload = {
        ...formData,
        doctype: 'products',
        applno: product_number,
        curator_email: email,
        curator_name: name,
        url: productUrl,
        sourced_from: productUrl
      };
      setLoading(true);
      await submitEMAProceduralStepsData(payload);
      const response = await getLabelData(prodNo, null, null, 'products');
      await setAllDetails(response.data.Success.products_qc);

      if (response.data.Success?.products_qc && (payload.id || payload.pno)) {
        const updateData = (response.data.Success?.products_qc || []).find(
          product =>
            product.id === selectedApplicationData?.id ||
            (payload.pno && payload.pno === product.pno)
        );
        await setSelectedApplicationData(updateData);
      }

      if (isAddFlow) {
        setNumberOfNewApps(numberOfNewApps + 1);
      }

      setLoading(false);
      setEditFlow(false);
      setAddFlow(false);

      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Saved Successfully!', color: 'success' }
      });
    } else {
      setLoading(false);
      await dispatch({
        type: GlobalActions.SET_ALERT,
        value: { status: true, message: 'Please update Status before you save.' }
      });
    }
  };

  const confirmSubmission = formData => {
    setDialog({
      type: DIALOG_TYPE_CONFIRM_FORM_SUBMIT,
      message: 'Are you sure you want to submit the changes?',
      title: 'Confirm Submission'
    });
    setFormData(formData);
  };

  const handleFormCancel = () => {
    setDialog({
      type: DIALOG_TYPE_CANCEL_FORM,
      message:
        'You have unsaved changes, are you sure that you want to cancel? All of your changes will be lost.',
      title: 'Confirm Cancel'
    });
  };

  const handleDialogConfirm = () => {
    if (dialog.type === DIALOG_TYPE_CONFIRM_FORM_SUBMIT) {
      submitForm();
    } else if (dialog.type === DIALOG_TYPE_CANCEL_FORM) {
      setEditFlow(false);
      setAddFlow(false);
      setDialog({});
    }
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        <Box display='flex' padding={1} flexDirection='row' justifyContent='space-between'>
          <Buttons
            onBackClick={() => history.push('/ema/products')}
            onEditClick={handleEditClick}
            isEditEnabled={true}
            isAddEnabled={!(isAddFlow || isEditFlow)}
            onAddClick={handleAddClick}
          />
          <Box>
            <Typography className={classes.heading2}>
              Number of Applications in database: {numberOfApps}
            </Typography>
            <Typography className={classes.heading2}>
              Number of New Applications: {numberOfNewApps}
            </Typography>
            <Typography className={classes.heading2}>
              Total Applications: {numberOfApps + numberOfNewApps}
            </Typography>
          </Box>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={12} lg={4} className={classes.gridLabel}>
            <Box
              className={classes.reviewBox}
              justifyContent='center'
              alignItems='space-evenly'
              style={{ minHeight: '75vh' }}>
              <Box padding={1}>
                <Typography className={classes.heading}>
                  Select an Application for EMEA-H-C-{prodNo}.
                </Typography>
              </Box>
              <Box padding={1} display='flex' flexDirection='row' justifyContent='space-between'>
                {
                  /* Search Mode */
                  !(isAddFlow || isEditFlow) && (
                    <SearchBox
                      options={allDetails?.map(opt => ({
                        label: opt.pno || 'Unknown',
                        id: opt.id,
                        tag: opt.indication_status
                      }))}
                      onSubmit={handleSearchSubmit}
                    />
                  )
                }
              </Box>
              {
                /* View Mode */
                !(isAddFlow || isEditFlow) && selectedApplicationData && (
                  <ViewData {...selectedApplicationData} />
                )
              }
              {
                /* Edit or Add Mode */
                (isAddFlow || isEditFlow) && (
                  <EMAProductForm
                    data={selectedApplicationData || {}}
                    flowType={isEditFlow ? FLOW_TYPE_EDIT : FLOW_TYPE_ADD}
                    onSubmit={confirmSubmission}
                    onCancel={handleFormCancel}
                    isLoading={isLoading}
                    isSubmitDisabled={
                      !!(
                        currentUser &&
                        currentUser['custom:role'] !== 'Admin' &&
                        selectedApplicationData &&
                        !isAddFlow &&
                        selectedApplicationData?.indication_status === 'Qced'
                      )
                    }
                  />
                )
              }
            </Box>
          </Grid>
          <Grid item xs={12} lg={8}>
            {pdfUrl ? (
              <Box display='flex' className={classes.pdfContainer}>
                <iframe title='pdf' src={pdfUrl} className={classes.pdfContainer} />
              </Box>
            ) : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.pdfContainer}>
                <Typography className={classes.notAvailable}>No pdf is available</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      <ConfirmationDialog
        dialogOpen={!!(dialog.message && dialog.title)}
        onClose={() => setDialog({})}
        onYesClick={handleDialogConfirm}
        title={dialog.title}
        message={dialog.message}
      />
    </Box>
  );
};

export default EMAProductsReviewPage;
