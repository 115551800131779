import PmrDataEntry from '../components/PMR/PmrDataEntry';
import PmrReviewPage from '../components/PMR/PmrReviewPage';
import LabelReviewPage from '../components/PMR/LabelReviewPage';

const PMR_ROUTES = [
  {
    id: 'pmrQcEntry',
    path: '/pmr/review',
    component: PmrDataEntry,
    exact: true
  },
  {
    id: 'pmrQcReview',
    path: '/pmr/review/:key',
    component: PmrReviewPage,
    exact: true
  },
  {
    id: 'pmrLabel',
    path: '/pmr/label/:key',
    component: LabelReviewPage,
    exact: true
  }
];

export default PMR_ROUTES;
