import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// material ui
import Box from '@material-ui/core/Box';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Container from '@material-ui/core/Container';

// context
import Store from '../../store';
import Actions from '../../store/actions';

// api
import { getLabelData, getReviewData, submitProcessedNonPLRData, submitProcessedPLRData } from '../../api/AdminPage';

// helpers
import { handleChange } from '../../helpers/changeHandler';

// other components
// import PLRPage from './PLRPage';
// import NonPLRPage from './NonPLRPage';
import NonPLRPage from './NonPLRPage';
import PLRPage from './PLRPage';

// styles
import Styles from './styles/Review.styles';

const ReviewPage = props => {
  const classes = Styles();
  const history = useHistory();
  const location = useLocation();
  const { dispatch } = useContext(Store);
  const [editMode, setEditMode] = useState(false);
  const [open, setOpen] = useState(false);
  const [type, setType] = useState(false);
  const [response, setResponse] = useState();
  const [page, setPage] = useState('non_processed');
  const [autoSort, setAutoSort] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        // let page = 'non_processed'
        // eslint-disable-next-line react/prop-types
        const { docType } = props;
        let pagetype = 'non_processed';
        if (location.pathname.includes('label_section')) {
          setPage('processed');
          pagetype = 'processed';
        }
        // eslint-disable-next-line react/destructuring-assignment
        let res = await getReviewData(props.applNo, props.subType, props.subNo, docType, pagetype);
        if (pagetype === 'processed' && res.data.Success === '') {
          res = await getReviewData(
            props.applNo,
            props.subType,
            props.subNo,
            docType,
            'non_processed'
          );
          if (res.data.Success.isPLR !== true) {
            // insert to PLR Processed table
            const allDetails = res.data.Success;
            const payload = {
              key: allDetails.key,
              appl_num: allDetails.key?.split('-')[0],
              submission_num: allDetails.submission_num,
              submission_type: allDetails.submission_type,
              description: null,
              clinical_pharmacology: null,
              indications_and_usage: null,
              contraindications: null,
              warnings: null,
              precautions: null,
              adverse_reactions: null,
              drug_abuse_and_dependence: null,
              overdosage: null,
              dosage_and_administration: null,
              how_supplied: null,
              label_path: allDetails.label_path,
              status: null,
              curator_email: null,
              curator_name: null,
              clinical_studies: null
            };
            await submitProcessedNonPLRData(payload);
          } else {
            // insert to NonPLR Processed table
            const allDetails = res.data.Success;
            const payload = {
              key: allDetails.key,
              appl_num: allDetails.key.split('-')[0],
              submission_num: allDetails.submission_num,
              submission_type: allDetails.submission_type,
              indications_and_usage: null,
              dosage_and_administration: null,
              dosage_forms_and_strengths: null,
              contraindications: null,
              warnings_and_precautions: null,
              adverse_reactions: null,
              drug_abuse_and_dependence: null,
              drug_interactions: null,
              use_in_specific_populations: null,
              overdosage: null,
              description: null,
              clinical_pharmacology: null,
              non_clinical_toxicology: null,
              clinical_studies: null,
              references: null,
              how_supplied_storage_and_handling: null,
              patient_counseling_information: null,
              label_path: allDetails.label_path,
              status: null,
              curator_email: null,
              curator_name: null
            };
            await submitProcessedPLRData(payload);
          }
          // eslint-disable-next-line react/destructuring-assignment
          res = await getReviewData(props.applNo, props.subType, props.subNo, docType, pagetype);
        }
        setResponse(res.data.Success);
        if (res.data.Success.isPLR === true) {
          setType('PLR');
        } else {
          setType('NonPLR');
        }
      } catch (error) {
        console.log(error);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Enter valid information' }
        });
        history.push('/reviews');
      }
    }

    fetchData();
  }, [props, dispatch, history]);

  const handleOpenOriginalPDF = async () => {
    try {
      const res = await getLabelData(
        props.applNo,
        props.subType,
        props.subNo,
        props.docType.toLowerCase()
      );
      if (res === undefined) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Not a valid PDF${error}` }
        });
      } else {
        const url = res.data.Success.label_data_qc[0].pdf_url;
        const option =
          'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
        const newWindow = window.open(url, '_blank', option);

        const timer = setInterval(function() {
          if (newWindow.closed) {
            clearInterval(timer);
            setOpenSubmitConfirmation(true);
          }
        }, 1000);
      }
    } catch (error) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `Not a valid PDF${error}` }
      });
    }
  };

  const handleSwitch = () => {
    setEditMode(!editMode);
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg' className={classes.container}>
        {editMode ? null : (
          <Box display='flex' padding={1} flexDirection='row' justifyContent='flex-start'>
            <Button
              className={classes.button}
              onClick={() => history.push('/reviews')}
              variant='contained'>
              Go back
            </Button>
            <Button className={classes.button} onClick={handleSwitch} variant='contained'>
              Edit
            </Button>
            <Button
              variant='outlined'
              color='secondary'
              className={classes.button}
              onClick={handleOpenOriginalPDF}>
              Original PDF
            </Button>
            <Select
              id='pmrReviewSearch'
              variant='standard'
              disableUnderline
              required
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left'
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left'
                },
                getContentAnchorEl: null
              }}
              open={open}
              onClose={() => setOpen(false)}
              onOpen={() => setOpen(true)}
              value={type}
              onChange={event => setType(handleChange(event))}
              className={classes.dropdownContainer}
              inputProps={{
                classes: {
                  icon: classes.icon
                }
              }}>
              <MenuItem value='PLR' className={classes.dropdownText}>
                PLR Format
              </MenuItem>
              <MenuItem value='NonPLR' className={classes.dropdownText}>
                Non PLR Format
              </MenuItem>
              <MenuItem value='' className={classes.dropdownText}>
                {/* eslint-disable-next-line react/no-unescaped-entities */}
                ''
              </MenuItem>
            </Select>
          </Box>
        )}

        <Grid container spacing={2}>
          {type && type === 'PLR' ? (
            <PLRPage autoSort={autoSort} setAutoSort={setAutoSort} edit={editMode} switch={handleSwitch} res={response && response} page={page} />
          ) : null}
          {type && type === 'NonPLR' ? (
            <NonPLRPage
              autoSort={autoSort}
              setAutoSort={setAutoSort}
              edit={editMode}
              handleSwitch={handleSwitch}
              res={response && response}
              page={page}
            />
          ) : null}
          <Grid item xs={12} lg={6}>
            {response && response.pdf_url ? (
              <Box display='flex' className={classes.pdfContainer}>
                <iframe title='pdf' src={response && response.pdf_url} className={classes.pdf} />
              </Box>
            ) : null}
            {response && response.pdf_url ? null : (
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.pdfContainer}>
                <Typography className={classes.notAvailable}>No pdf is available</Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default ReviewPage;
