/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useContext, useRef } from 'react';
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Paper,
  SelectChangeEvent,
  ThemeProvider,
  Skeleton,
  Typography,
  Stack,
  Switch,
  FormControlLabel,
  ToggleButtonGroup,
  ToggleButton,
  AppBar
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import DateFnsAdapter from '@date-io/date-fns';
import { daysList, groupList } from './constants';
import themev5 from '../../../themev5';
import { getChatRiaStats, getChatRiaQuestion } from '../../../api/ChatRIA';
import styles from './styles/ChatRIAStats.styles';
import Store from '../../../store';
import actions from '../../../store/actions';
import DetailView from './DetailView';
import StatsBarChart from '../../../helpers/StatsBarChart';
import StatsPieChart from '../../../helpers/StatsPieChart';
import StyledTabs, { StyledTab } from '../../StyledComponents/VivproTabs';
import { chatRiaGraphType, graphDataType, chatRiaQuestionType } from './ChatRIAStatsInterface';

const TabPanel = (props: any) => {
  const { children, value, index } = props;

  return (
    <Box role='tabpanel' hidden={value !== index}>
      {value === index && <Box>{children}</Box>}
    </Box>
  );
};

const ChatRIAStats = () => {
  const cache = useRef<any>({});
  // @ts-ignore
  const { dispatch } = useContext(Store);
  const history = useHistory();
  const dateFns = new DateFnsAdapter();
  const { days, group } = useParams<{ days: string; group: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [barDataLoading, setBarDataLoading] = useState<boolean>(false);
  const [selectedDay, setSelectedDay] = useState<string>(days);
  const [selectedGroup, setSelectedGroup] = useState<string>(group);
  const [selectedGraph, setSelectedGraph] = useState<string>('users');
  const [selectedOrg, setSelectedOrg] = useState<string>('All');
  const [organizations, setOrganizations] = useState<string[]>([]);
  const [organizationQuestions, setOrganizationQuestions] = useState<chatRiaQuestionType>({
    startDate: '',
    endDate: '',
    orgData: []
  });
  const [overallStats, setOverallStats] = useState<{ id: string; count: string }[]>([]);
  const [clickedOrganization, setClickedOrganization] = useState<string>('');
  const [detailOpen, setDetailOpen] = useState<boolean>(false);
  const [hideVivproToggle, setHideVivproToggle] = useState<boolean>(true);
  const [selectedTab, setSelectedTab] = useState<string>('pie');

  const [graphData, setGraphData] = useState<graphDataType>({
    uniqueUsers: [],
    uniqueUsersPie: [],
    requestCount: [],
    requestCountPie: [],
    riaMode: [],
    riaModePie: []
  });

  // Validate the parameter against the list of allowed values
  if (!daysList.some(e => e.key === days)) {
    history.replace(`/chatRIAStats/30/${group}`);
  } else if (!groupList.some(e => e.key === group)) {
    history.replace(`/chatRIAStats/${days}/day`);
  }

  const onTabChange = (_e: React.SyntheticEvent, newValue: string) => {
    setSelectedTab(newValue);
  };

  const filterOrg = (data: chatRiaGraphType[]) =>
    selectedOrg === 'All' ? data : data.filter(x => Object.keys(x).includes(selectedOrg));

  const getPieDataBasedOnType = (currentGraph: string) => {
    switch (currentGraph) {
      case 'requests':
        return graphData.requestCountPie;
      case 'ria_mode':
        return graphData.riaModePie;
      case 'users':
        return graphData.uniqueUsersPie;
      default:
        return graphData.uniqueUsersPie;
    }
  };

  const getBarDataBasedonType = (currentGraph: string) => {
    switch (currentGraph) {
      case 'requests':
        return filterOrg(graphData.requestCount);
      case 'ria_mode':
        return filterOrg(graphData.riaMode);
      case 'users':
        return filterOrg(graphData.uniqueUsers);
      default:
        return filterOrg(graphData.uniqueUsers);
    }
  };

  const handleTimeChange = (event: SelectChangeEvent<string>) => {
    setSelectedDay(event.target.value);
    setSelectedOrg('All');
    const groupVal = groupList.find(x => x.key === selectedGroup);
    if (!groupVal?.allowed.includes(event.target.value)) {
      const newVal = groupList.find(x => x.allowed.includes(event.target.value));
      setSelectedGroup(newVal?.key || 'day');
    }
  };

  const handleOrganizationChange = (event: SelectChangeEvent<string>) => {
    setSelectedOrg(event.target.value);
  };

  const handleGraphTypeChange = (event: SelectChangeEvent<string>) => {
    setSelectedGraph(event.target.value);
  };

  const handleDetailClose = () => {
    setDetailOpen(false);
  };

  const fetchData = async () => {
    setLoading(true);
    const cacheKey = `data-${selectedDay}-${selectedGroup}-${hideVivproToggle}`;
    try {
      history.replace(`/chatRIAStats/${selectedDay}/${selectedGroup}`);
      let data = cache.current[cacheKey];
      let res = null;
      let error = null;
      if (!data) {
        res = await getChatRiaStats(selectedDay, selectedGroup, hideVivproToggle);
        data = res?.data?.Success;
        error = res?.data?.Error;
        if (res?.data?.Success) cache.current[cacheKey] = res?.data?.Success;
      }
      if (data) {
        const reqCountTotal =
          Object.entries(data?.request_count_overview ?? {}).map(([id, value]) => ({
            id,
            value: value as number
          })) ?? [];
        setOverallStats([
          {
            id: 'Unique Users',
            count: data?.unique_user_count ?? '0'
          },
          {
            id: 'Request Count',
            count: data?.request_count ?? '0'
          },
          {
            id: 'Ria Mode = True',
            count: `${data?.ria_mode_percent?.toFixed(2)}%` ?? '0%'
          },
          {
            id: 'Top Organization',
            count:
              (reqCountTotal.length > 0 &&
                reqCountTotal.reduce(
                  (max: { id: string; value: number }, item: { id: string; value: number }) =>
                    max.value > item.value ? max : item
                ).id) ||
              ''
          },
          {
            id: 'Top Section',
            count: `${data?.top_section}` ?? ''
          }
        ]);
        setGraphData({
          uniqueUsers: data?.unique_user_detailed || [],
          uniqueUsersPie:
            Object.entries(data?.unique_user_overview ?? {}).map(([id, value]) => ({
              id,
              value: value as number
            })) ?? [],
          requestCount: data?.request_count_detailed || [],
          requestCountPie: reqCountTotal,
          riaMode: data?.ria_mode_detailed || [],
          riaModePie:
            Object.entries(data?.ria_mode_overview ?? {}).map(([id, value]) => ({
              id,
              value: value as number
            })) ?? []
        });
        setOrganizations(data?.organizations ?? []);
      } else {
        await dispatch({
          type: actions.SET_ALERT,
          value: {
            status: true,
            message: error ?? 'Error'
          }
        });
      }
    } catch (error) {
      await dispatch({
        type: actions.SET_ALERT,
        value: {
          status: true,
          message: error
        }
      });
    }
    setLoading(false);
  };

  const fetchQueries = async (startDate: string, groupVal: string) => {
    setBarDataLoading(true);
    const cacheKey = `question-${startDate}-${groupVal}-${hideVivproToggle}`;
    try {
      let data = cache.current[cacheKey];
      let res = null;
      let error = null;
      if (!data) {
        res = await getChatRiaQuestion(startDate, groupVal, hideVivproToggle);
        data = res?.data?.Success;
        error = res?.data?.Error;
        if (res?.data?.Success) cache.current[cacheKey] = res?.data?.Success;
      }
      if (data) {
        setOrganizationQuestions({
          startDate: data.start_date ?? '',
          endDate: data.end_date ?? '',
          orgData: data.organization_questions
        });
      } else {
        await dispatch({
          type: actions.SET_ALERT,
          value: {
            status: true,
            message: error ?? 'Error'
          }
        });
      }
    } catch (error) {
      await dispatch({
        type: actions.SET_ALERT,
        value: {
          status: true,
          message: error
        }
      });
    }
    setBarDataLoading(false);
  };

  const barOnClick = (barClickedData: any) => {
    let date = dateFns.parse(barClickedData?.data?.date, 'MM/dd/yyyy');
    if (!dateFns.isValid(date)) date = new Date();
    setClickedOrganization(barClickedData?.id);
    setDetailOpen(true);
    fetchQueries(date.toISOString(), selectedGroup);
  };

  const pieOnClick = (pieClickedData: any) => {
    setClickedOrganization(pieClickedData?.id);
    setDetailOpen(true);
    fetchQueries(new Date().toISOString(), selectedDay);
  };

  useEffect(() => {
    setSelectedDay(days);
    setSelectedGroup(group);
  }, [days, group]);

  useEffect(() => {
    fetchData();
  }, [selectedDay, selectedGroup, hideVivproToggle]);

  return (
    <ThemeProvider theme={themev5}>
      <Box sx={styles.root}>
        <Stack direction='row' width='100%' height='80vh' spacing={4} alignItems='stretch'>
          <Stack
            spacing={4}
            width='15vw'
            height='100%'
            direction='column'
            justifyContent='space-between'>
            <Typography variant='h4'>ChatRIA Stats</Typography>
            {overallStats?.map(stat =>
              !loading ? (
                <Paper
                  component={Stack}
                  height='100%'
                  justifyContent='center'
                  elevation={2}
                  sx={styles.paper}>
                  <Stack
                    direction='column'
                    justifyContent='space-evenly'
                    alignItems='center'
                    textAlign='center'>
                    <Typography variant='h5' sx={styles.countText}>
                      {stat.count}
                    </Typography>
                    <Typography variant='h6' color='green.emerald'>
                      {stat.id}
                    </Typography>
                  </Stack>
                </Paper>
              ) : (
                <Skeleton variant='rectangular' width='100%' height='100%' />
              )
            )}
          </Stack>
          <Box width='80vw'>
            <Paper elevation={2} sx={styles.paper}>
              <Stack>
                <Stack direction='row' justifyContent='space-between' sx={{ margin: '20px' }}>
                  <Stack direction='row' spacing={2} alignItems='center'>
                    <FormControl sx={{ minWidth: 200 }}>
                      <InputLabel id='group-label'>Type</InputLabel>
                      <Select
                        labelId='group-label'
                        id='group-select'
                        value={selectedGraph}
                        label='Type'
                        onChange={handleGraphTypeChange}>
                        <MenuItem key='unique-user-count-menuitem' value='users'>
                          Unique user count
                        </MenuItem>
                        <MenuItem key='request-count-menuitem' value='requests'>
                          Request count
                        </MenuItem>
                        <MenuItem key='ria-mode-rate-menuitem' value='ria_mode'>
                          Ria Mode = True %
                        </MenuItem>
                      </Select>
                    </FormControl>
                    <Box>
                      <FormControl sx={{ minWidth: 100 }}>
                        <InputLabel id='time-range-label'>Time Range</InputLabel>
                        <Select
                          labelId='time-range-label'
                          id='time-range-select'
                          value={selectedDay}
                          label='Time Range'
                          onChange={handleTimeChange}>
                          {daysList.map(({ key, value }) => (
                            <MenuItem key={`${key}-days`} value={key}>
                              {value}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    {selectedTab === 'bar' && (
                      <Box>
                        <FormControl sx={{ minWidth: 200 }}>
                          <InputLabel id='group-label'>Organization</InputLabel>
                          <Select
                            labelId='group-label'
                            id='group-select'
                            value={selectedOrg}
                            label='Organization'
                            onChange={handleOrganizationChange}>
                            {['All', ...organizations].map(org => (
                              <MenuItem key={`organization-${org}-menuitem`} value={org}>
                                {org}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    )}
                    <Box>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={hideVivproToggle}
                            onChange={event => setHideVivproToggle(event.target.checked)}
                            inputProps={{ 'aria-label': 'Hide Vivpro' }}
                          />
                        }
                        label='Hide Vivpro'
                      />
                    </Box>
                  </Stack>
                  {selectedTab === 'bar' && (
                    <ToggleButtonGroup
                      color='primary'
                      value={selectedGroup}
                      exclusive
                      onChange={(_event, newValue) => {
                        if (newValue !== null) setSelectedGroup(newValue);
                      }}
                      aria-label='Time Period'>
                      {groupList.map(({ key, value, allowed }) =>
                        allowed.includes(selectedDay) ? (
                          <ToggleButton key={`${key}-group`} value={key}>
                            {value}
                          </ToggleButton>
                        ) : null
                      )}
                    </ToggleButtonGroup>
                  )}
                </Stack>
                <Box>
                  <AppBar
                    position='static'
                    color='transparent'
                    elevation={0}
                    sx={styles.tabContainer}>
                    <StyledTabs
                      value={selectedTab}
                      onChange={(e: React.SyntheticEvent, newValue: string) =>
                        onTabChange(e, newValue)
                      }
                      aria-label='tabs'
                      sx={styles.tabs}
                      indicatorColor='primary'>
                      <StyledTab
                        value='pie'
                        id='pie-tab'
                        label='Overview'
                        sx={selectedTab === 'pie' ? styles.activeTab : styles.inactiveTab}
                        activetab={(selectedTab === 'pie').toString()}
                      />
                      <StyledTab
                        value='bar'
                        id='bar-tab'
                        label='Detailed View'
                        sx={selectedTab === 'bar' ? styles.activeTab : styles.inactiveTab}
                        activetab={(selectedTab === 'bar').toString()}
                      />
                    </StyledTabs>
                  </AppBar>
                  <TabPanel value={selectedTab} index='bar'>
                    {loading ? (
                      <Skeleton variant='rectangular' height='70vh' width='100%' />
                    ) : (
                      <Box height='70vh' width='100%' padding='10px'>
                        <StatsBarChart
                          data={getBarDataBasedonType(selectedGraph)}
                          barClickCallBack={barOnClick}
                          key={`chart-${selectedDay}-${selectedGroup}-${hideVivproToggle}`}
                          keys={selectedOrg === 'All' ? organizations : [selectedOrg]}
                        />
                      </Box>
                    )}
                  </TabPanel>
                  <TabPanel value={selectedTab} index='pie'>
                    {loading ? (
                      <Skeleton variant='rectangular' height='70vh' width='100%' />
                    ) : (
                      <Box height='70vh' width='100%' padding='40px'>
                        <StatsPieChart
                          data={getPieDataBasedOnType(selectedGraph)}
                          pieClickCallBack={pieOnClick}
                        />
                      </Box>
                    )}
                  </TabPanel>
                </Box>
              </Stack>
            </Paper>
          </Box>
        </Stack>
      </Box>
      <DetailView
        isOpen={detailOpen}
        loading={barDataLoading}
        handleClose={handleDetailClose}
        organization={clickedOrganization}
        data={organizationQuestions}
      />
    </ThemeProvider>
  );
};

export default ChatRIAStats;
