const actions = {
  SET_LOADING: 'SET_LOADING',
  SET_GRAPH_TYPE: 'SET_GRAPH_TYPE',
  SET_CART_ITEMS: 'SET_CART_ITEMS',
  SET_CARD_DETAILS: 'SET_CARD_DETAILS',
  SET_USER_STUB: 'SET_USER_STUB',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  SET_PATENT_EXPANSION: 'SET_PATENT_EXPANSION',
  SET_APPLICATION_CONTENT: 'SET_APPLICATION_CONTENT',
  SET_MODULE: 'SET_MODULE',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_REPORT: 'SET_REPORT',
  SET_PDF_MESSAGE: 'SET_PDF_MESSAGE',
  SET_ADVANCE_SEARCH: 'SET_ADVANCE_SEARCH',
  SET_ALERT: 'SET_ALERT',
  SET_DB: 'SET_DB',
  SET_TIMELINE: 'SET_TIMELINE',
  SET_EXTENDED_CARD: 'SET_EXTENDED_CARD',
  SET_BOT: 'SET_BOT',
  SET_ADCOMMCOMMITTEE: 'SET_ADCOMMCOMMITTEE',
  SET_TREE_DATA: 'SET_TREE_DATA',
  SET_SEARCH_DETAILS: 'SET_SEARCH_DETAILS',
  SET_PROCEDURAL_STEPS_PROD_NO: 'SET_PROCEDURAL_STEPS_PROD_NO',
  SET_EMA_PRODUCT_NO: 'SET_EMA_PRODUCT_NO',
  SET_SELECTED_CARD: 'SET_SELECTED_CARD', // selected application (card) for a project.
  SET_PROJECT_DIALOG: 'SET_PROJECT_DIALOG', // open.close projects list dialog box.
  SET_ITEM_TYPE: 'SET_ITEM_TYPE', // item type selected for a project (i.e application, query or report)
  SET_SUBMIT_PROJECT_NAME: 'SET_SUBMIT_PROJECT_NAME', // project name
  SET_ADVANCE_SEARCH_PAYLOAD: 'SET_ADVANCE_SEARCH_PAYLOAD',
  SET_ADVANCE_SEARCH_CNF_QUERY: 'SET_ADVANCE_SEARCH_CNF_QUERY',
  SET_ADVANCE_SEARCH_CONDITIONS: 'SET_ADVANCE_SEARCH_CONDITIONS',
  SET_ADCOMM_MINUTES_ADD_DETAILS: 'SET_ADCOMM_MINUTES_ADD_DETAILS',
  SET_HOME_TABS: 'SET_HOME_TABS',
  SET_KEY_ATTRIBUTES: 'SET_KEY_ATTRIBUTES'
};

export default actions;
