import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  webPageContainer: {
    height: '100%',
    width: '100%',
    padding: '0px 32px',
    borderRadius: theme.spacing(1.5)
  },
  webPage: {
    height: '80vh',
    width: '100%',
    borderRadius: theme.spacing(1.5)
  },
  notAvailable: {
    color: theme.palette.white,
    fontSize: theme.spacing(3),
    fontWeight: 'bold'
  },
  label: {
    fontSize: theme.spacing(2),
    padding: '10px 0 3px 0',
    color: theme.palette.review.label,
    fontWeight: 'bold',
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2.5)
    }
  },
  textfield: {
    marginTop: theme.spacing(0.5),
    width: '100%',
    color: 'white',
    background: theme.palette.backgrounds.dark,
    borderRadius: theme.spacing(1.5),
    '::-webkit-calendar-picker-indicator': {
      filter: 'invert(1)'
    }
  },
  input: {
    color: theme.palette.white,
    fontSize: theme.spacing(1.5),
    marginLeft: theme.spacing(1),
    '&::placeholder': {
      color: theme.palette.white,
      fontSize: theme.spacing(1)
    },
    [theme.breakpoints.down('xl')]: {
      fontSize: theme.spacing(2)
    }
  },
  button: {
    background: theme.palette.buttons.purple,
    fontFamily: 'Roboto',
    display: 'flex',
    // @ts-ignore
    color: theme.palette.white,
    width: theme.spacing(35),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      opacity: 0.8,
      // @ts-ignore
      background: theme.palette.buttons.purple
    }
  }
}));
