import DataQualityStatistics from '../components/DataQualityStatistics';

const DATA_QUALITY_STATISTICS_BASE_ROUTE = '/data_quality_statistics';

const DATA_QUALITY_STATISTICS_ROUTES = [
  {
    id: 'dataQualityStatistics',
    path: DATA_QUALITY_STATISTICS_BASE_ROUTE,
    component: DataQualityStatistics,
    exact: true
  }
];

export default DATA_QUALITY_STATISTICS_ROUTES;
