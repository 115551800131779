import React, { useEffect, useCallback, useState, ChangeEvent, SyntheticEvent } from 'react';
import { cloneDeep, isArray } from 'lodash';

// mui
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { Close } from '@mui/icons-material';

// component
// import TextFieldData from './TextFiel
import TextFieldData from '../CustomDashboard/TextField';
import RadioButton from '../CustomDashboard/RadioButton';
import DropDown from '../CustomDashboard/DropDown';
import DateRange from '../CustomDashboard/DateRange';
import { DataField } from '../CustomDashboard/utils/getDataFields';

// styles
import styles from '../CustomDashboard/styles';
import PDFViewer from '../../utils/PDFViewer';

interface curationFormProps {
  fields: Array<DataField>;
  // eslint-disable-next-line no-unused-vars
  handleSubmit: (e: SyntheticEvent | any, dataField: Array<DataField> | any) => void;
  fileUrls: Array<any>;
  // eslint-disable-next-line no-unused-vars, react/require-default-props
  setSelectedPdfUrl?: React.Dispatch<React.SetStateAction<any>>;
  center: string;
}

const TopicOrDeviceForm = ({
  fields,
  handleSubmit,
  fileUrls,
  setSelectedPdfUrl,
  center
}: curationFormProps) => {
  const getMode = (): string => {
    let mode = 'device';
    fields.forEach(data => {
      if ((data.id === 'term' || data.id === 'topic') && data.value) {
        mode = 'topic';
      }
    });
    return mode;
  };
  const [mode, setMode] = React.useState(getMode());
  const [dataField, setDataField] = useState<Array<DataField>>([]);
  const [error, setError] = useState<boolean>(false);
  const [openConfimationDialog, setOpenConfimationDialog] = useState<boolean>(false);
  const [selectedS3Link, setSelectedS3Link] = useState<any>({});

  useEffect(() => {
    setDataField(cloneDeep(fields));
  }, [fields]);

  useEffect(() => {
    if (setSelectedPdfUrl) {
      setSelectedPdfUrl(selectedS3Link);
    }
  }, [selectedS3Link]);

  const handleToggle = (event: React.MouseEvent<HTMLElement>, selectedMode: string) => {
    setDataField(cloneDeep(fields));
    setMode(selectedMode);
  };

  const handleDatasubmit = async (e: React.SyntheticEvent) => {
    const cleanedData = dataField.map(item => {
      if (
        mode === 'topic' &&
        ['application_number', 'application_type', 'brand_name', 'sponsor'].includes(item.id)
      ) {
        return { ...item, value: '' };
      }
      if (mode === 'device' && ['term', 'topic'].includes(item.id)) {
        return { ...item, value: '' };
      }
      return { ...item };
    });
    handleSubmit(e, cleanedData);
  };

  const handleChange = useCallback(
    (id: string, val: string | any) => {
      const newFields = [...dataField];
      const updatedFields = newFields.find((fltr: any) => fltr.id === id);
      if (updatedFields) {
        if (typeof val === 'string') {
          updatedFields.value = val;
        } else {
          updatedFields.value = val.value;
        }
      }
      setDataField(newFields);
    },
    [dataField]
  );

  const handleMultipleDropDownChange = useCallback(
    (id: string, val: string | any) => {
      const newFields = [...dataField];
      const updatedFields = newFields.find((fltr: any) => fltr.id === id);
      if (updatedFields) {
        updatedFields.value = val.join(',');
      }
      setDataField(newFields);
    },
    [dataField]
  );

  const handleDateSelectionChange = useCallback(
    (id: string, date: any) => {
      const newFields = [...dataField];
      const updatedFields = newFields.find((fltr: any) => fltr.id === id);
      if (updatedFields) {
        updatedFields.value = date;
      }

      setDataField(newFields);
    },
    [dataField]
  );

  const handleOpenPopup = (url: string) => {
    const option =
      'height=1000,width=1000,left=100,top=100,resizable=yes,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=yes';
    const newWindow: any = window.open(url, '_blank', option);

    const timer = setInterval(() => {
      if (newWindow.closed) {
        clearInterval(timer);
      }
    }, 1000);
  };

  const onSubmit = () => {
    setOpenConfimationDialog(true);
  };

  const showField = (field: DataField) => {
    if (mode === 'topic') {
      const result = ['meeting_id', 'term', 'topic', 'qc_status'].includes(field.id);
      return result;
    }
    const result = [
      'meeting_id',
      'application_number',
      'application_type',
      'brand_name',
      'sponsor',
      'qc_status',
      'approval_pathway'
    ].includes(field.id);
    return result;
  };

  return (
    <Grid container display='flex' maxHeight='100vh' sx={{ overflow: 'hidden' }}>
      <Grid item xs={12} lg={6} height='70vh' sx={{ overflow: 'auto' }}>
        <form style={{ marginTop: '20px' }}>
          <Grid container spacing={3} p={2}>
            <Grid item xs={12}>
              <ToggleButtonGroup
                color='primary'
                value={mode}
                exclusive
                onChange={handleToggle}
                aria-label='TopicOrDevice'>
                <ToggleButton value='device'>
                  {center === 'CDRH' ? 'Device' : 'Application'}
                </ToggleButton>
                <ToggleButton value='topic'>Topic</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            {dataField.map((row: any) => {
              if (!showField(row)) {
                return null;
              }
              if (row.type === 'text_area_field') {
                return (
                  <Grid item xs={12} key={row.id}>
                    <TextFieldData
                      key={row.id}
                      id={row.id}
                      label={row.label}
                      value={row.value}
                      multiline
                      onChangeFn={newValue => {
                        handleChange(row.id, newValue);
                      }}
                    />
                  </Grid>
                );
              }
              if (row.type === 'date_range') {
                return (
                  <Grid item xs={12} key={row.id}>
                    <DateRange
                      label={row.label}
                      value={row.value || null}
                      onchange={(newValue: string | null) => {
                        if (newValue !== null) {
                          handleDateSelectionChange(row.id, newValue);
                        }
                      }}
                    />
                  </Grid>
                );
              }
              if (row.type === 'dropdown') {
                return (
                  <Grid item xs={12} key={row.id}>
                    <DropDown
                      id={row.id}
                      label={row.label}
                      value={row.value}
                      options={row.options}
                      multiple={false}
                      freeText={row?.freeText || false}
                      setError={setError}
                      validate={row?.validate}
                      onChange={(
                        event: React.SyntheticEvent<{}>,
                        newValue: string | null
                      ): void => {
                        if (newValue !== null) {
                          handleChange(row.id, newValue);
                        }
                      }}
                    />
                  </Grid>
                );
              }
              if (row.type === 'multiple_dropdown') {
                return (
                  <Grid item xs={12} key={row.id}>
                    <DropDown
                      id={row.id}
                      label={row.label}
                      value={isArray(row.value) ? row.value : row.value.trim().split(',')}
                      options={row.options}
                      multiple
                      freeText={false}
                      setError={setError}
                      validate={row?.validate}
                      onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                        if (newValue !== null) {
                          handleMultipleDropDownChange(row.id, newValue);
                        }
                      }}
                    />
                  </Grid>
                );
              }
              if (row.type === 'radio_type') {
                return (
                  <Grid item xs={12} key={row.id}>
                    <RadioButton
                      id={row.id}
                      label={row.label}
                      value={row.value}
                      options={row.options}
                      onChange={e => {
                        handleChange(row.id, e.target.value);
                      }}
                    />
                  </Grid>
                );
              }
              return (
                <Grid item xs={12} key={row.id}>
                  <TextFieldData
                    key={row.id}
                    id={row.id}
                    label={row.label}
                    value={row.value}
                    multiline={false}
                    onChangeFn={newValue => {
                      handleChange(row.id, newValue);
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
          <Grid item xs={12} display='flex' direction='column'>
            <Button
              fullWidth
              variant='contained'
              color='primary'
              onClick={() => {
                onSubmit();
              }}
              disabled={error}
              style={{ color: 'white' }}>
              SUBMIT
            </Button>
          </Grid>
        </form>
      </Grid>
      <Dialog fullWidth maxWidth='xs' open={openConfimationDialog}>
        <DialogTitle id='alert-dialog-title'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography style={{ color: '#3AB09E', fontSize: '20px', fontWeight: 'bold' }}>
              Confirm submission
            </Typography>
            <IconButton aria-label='close' onClick={() => setOpenConfimationDialog(false)}>
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Typography style={{ fontSize: '16px', color: 'GrayText', marginRight: '0' }}>
            Are you sure you have edited the document accurately?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant='contained'
            onClick={(e: SyntheticEvent) => {
              handleDatasubmit(e);
              setOpenConfimationDialog(false);
            }}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            Yes
          </Button>
          <Button
            variant='contained'
            onClick={() => setOpenConfimationDialog(false)}
            sx={{ background: '#3AB09E', marginRight: '8px', color: 'whitesmoke' }}>
            No
          </Button>
        </DialogActions>
      </Dialog>
      <Grid
        item
        xs={12}
        lg={6}
        display='flex'
        flexDirection='column'
        height='70vh'
        sx={{ overflow: 'auto' }}
        alignItems='stretch'>
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Button
            variant='contained'
            sx={styles.Button}
            disabled={!(selectedS3Link.s3_url || selectedS3Link.meeting_link)}
            onClick={() => {
              handleOpenPopup(selectedS3Link.s3_url || selectedS3Link.meeting_link);
            }}>
            <Typography>Open As a Pop-Up window</Typography>
          </Button>
        </Box>
        <PDFViewer pdf={fileUrls} setSelectedS3Link={setSelectedS3Link} />
      </Grid>
    </Grid>
  );
};

export default React.memo(TopicOrDeviceForm);
