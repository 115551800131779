import React, { useContext, useEffect, useState } from 'react';
import { Auth } from 'aws-amplify';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
// api
import { getOneApp, submitApplicationDetails } from '../../../api/Adcomm';
import { useHistory, useParams } from 'react-router-dom';
import Store from '../../../store';
import actions from '../../../store/actions';

export default function EditApplicationFields({
                                                classes,
                                                application,
                                                setEditMode,
                                                setApplication,
                                                setQcComplete,
                                                qcComplete,
                                                setStatus,
                                                status,
                                                adminStatus
                                              }) {
  const { dispatch } = useContext(Store);
  const [applPK, setApplPK] = useState(application.application_primary_key);
  const [applNo, setApplNo] = useState(application.appl_no);
  const [applType, setApplType] = useState(application.appl_type || '');
  const [activeIngredient, setActiveIngredient] = useState(application.active_ingredient);
  const [sponsor, setSponsor] = useState(application.sponsor || '');
  const [approvalDecision, setApprovalDecision] = useState(application.approval_decision || '');
  const [submission, setSubmission] = useState(application.submission || '');
  const [submissionId, setSubmissionId] = useState(application.submission_id || '');
  const [approvalDate, setApprovalDate] = useState(application.approval_date || null);
  const [approvalPathway, setApprovalPathway] = useState(application.approval_pathway || '');
  const [route, setRoute] = useState(application.route || '');
  const [formulation, setFormulation] = useState(application.formulation || '');
  const [tradeName, setTradeName] = useState(application.trade_name || '');
  const [email, setEmail] = useState();
  const [name, setName] = useState();
  const [statusOpen, setStatusOpen] = useState(false);
  const { meetingId, applicationPrimaryKey } = useParams();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      try {
        const user = await Auth.currentUserInfo();
        setEmail(user.attributes.email);
        setName(user.attributes['custom:user']);
      } catch (e) {
        console.error(e);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (applicationPrimaryKey === 'newApplication' && applicationPrimaryKey !== 'undefined') {
      setApplPK(
        `${applNo}-${applType}-${activeIngredient}-${sponsor}-${route}-${formulation}-${tradeName}-${meetingId}`
      );
    }
  }, [applNo, applType, activeIngredient, sponsor, route, formulation, tradeName]);

  const handleSubmit = async e => {
    e.preventDefault(e);
    try {
      // here we will post the new values
      const payload = {
        application_primary_key: applPK,
        appl_no: applNo,
        meeting_id: meetingId,
        appl_type: applType,
        active_ingredient: activeIngredient,
        sponsor,
        approval_decision: approvalDecision,
        submission,
        submission_id: submissionId,
        approval_date: approvalDate,
        approval_pathway: approvalPathway,
        route,
        formulation,
        trade_name: tradeName,
        qc_complete: true,
        status,
        curator_email: email,
        curator_name: name
      };

      if (applNo.length !== 6) {
        await dispatch({
          type: actions.SET_ALERT,
          value: { status: true, message: 'Application number must be 6 digits.' }
        });
        return;
      }

      history.push(`/adcomm/${meetingId}`);
      await submitApplicationDetails(payload);
      setEditMode(false);
      // Once new meeting details are saved, set meeting data to qcd table details
      const newApplication = await getOneApp('qcd', applPK);
      setApplication(newApplication.data.Success);
      setQcComplete(true);
    } catch (e) {
      await dispatch({ type: actions.SET_ALERT, value: { status: true, message: e.message } });
      console.log(e);
    }
  };

  const handleApprovalDate = date => {
    setApprovalDate(date);
  };

  return (
    <Box padding={1} className={classes.reviewBox}>
      <h1>Application Details</h1>
      <Typography align='left' className={classes.label}>
        Meeting ID
      </Typography>
      <Box>
        <Typography className={classes.details} align='left'>
          {meetingId}
        </Typography>
      </Box>
      <form onSubmit={handleSubmit}>
        <Typography align='left' className={classes.label}>
          Application Number
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={applNo}
          onChange={e => setApplNo(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Application Type
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={applType}
          onChange={e => setApplType(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Sponsor
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={sponsor}
          onChange={e => setSponsor(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Drug Name
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={tradeName}
          onChange={e => setTradeName(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Active Ingredient
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={activeIngredient}
          onChange={e => setActiveIngredient(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Formulation
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={formulation}
          onChange={e => setFormulation(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Route
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={route}
          onChange={e => setRoute(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Approval Date
        </Typography>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container>
            <KeyboardDatePicker
              variant='inline'
              format='yyyy-MM-dd'
              margin='normal'
              id='date-picker-inline'
              label='Date picker'
              value={approvalDate}
              onChange={handleApprovalDate}
              className={classes.date_picker}
              KeyboardButtonProps={{
                'aria-label': 'change date'
              }}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Typography align='left' className={classes.label}>
          Submission
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={submission}
          onChange={e => setSubmission(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Submission ID
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={submissionId}
          onChange={e => setSubmissionId(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Approval Pathway
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={approvalPathway}
          onChange={e => setApprovalPathway(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Typography align='left' className={classes.label}>
          Approval Decision
        </Typography>
        <TextField
          className={classes.textfield}
          required
          value={approvalDecision}
          onChange={e => setApprovalDecision(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
            disableUnderline: true
          }}
        />
        <Box padding={1} display='flex' flexDirection='column'>
          <Typography align='left' className={classes.label}>
            Status
          </Typography>
          <Select
            id='selectStatus'
            variant='standard'
            disableUnderline
            required
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
            open={statusOpen}
            onClose={() => setStatusOpen(false)}
            onOpen={() => setStatusOpen(true)}
            value={status}
            onChange={e => setStatus(e.target.value)}
            className={classes.dropdownContainer}
            inputProps={{
              classes: {
                icon: classes.icon
              }
            }}>
            <MenuItem value='Qced' className={classes.dropdownText}>
              Qced
            </MenuItem>
            <MenuItem value='Escalate' className={classes.dropdownText}>
              Escalate
            </MenuItem>
            <MenuItem value='Delete' className={classes.dropdownText}>
              Delete
            </MenuItem>
            <MenuItem value='' className={classes.dropdownText}></MenuItem>
          </Select>
        </Box>
        <Box>
          {(!qcComplete && status) || adminStatus ? (
            <Button type='submit' className={classes.button}>
              Submit
            </Button>
          ) : (
            <Button className={classes.button} disabled>
              Submit
            </Button>
          )}
        </Box>
      </form>
    </Box>
  );
}
