import React, { useState, useEffect, useContext, SyntheticEvent, ChangeEvent } from 'react';
import { Auth } from 'aws-amplify';

// material
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Autocomplete from '@mui/material/Autocomplete';
import { FormControlLabel, Switch } from '@mui/material';
// Store
import { DialogContent } from '@material-ui/core';
import Actions from '../../store/actions';
import Store from '../../store';

// api
import { updateMonographPageNoDetails, getMonographPageNoDetails } from '../../api/canada';

// constant
import { qcStatus, Fields } from './constants';

// utils
import getsortLabels from './getSortLabels';
import TextFieldCard from '../TextFieldCard';

const MonographPageEditDialog = ({ closeEdit, productData, original }: any) => {
  type MonographData = {
    adverse_reactions: number | string;
    clinical_pharmacology: number | string;
    clinical_trials: number | string;
    contraindications: number | string;
    dosage_and_administration: number | string;
    dosage_forms_strengths_composition_and_packaging: number | string;
    drug_interactions: number | string;
    indications: number | string;
    microbiology: number | string;
    nonclinical_toxicology: number | string;
    overdosage: number | string;
    pharmaceutical_information: number | string;
    serious_warnings_and_precautions_box: number | string;
    special_handling_instructions: number | string;
    storage_stability_and_disposal: number | string;
    supporting_product_monographs: number | string;
    warnings_and_precautions: number | string;
    detailed_pharmacology: number | string;
    references: number | string;
    patient_medication_information: number | string;
    qc_status: string;
    disabled_sections: Array<any>;
    [key: string]: number | string | Array<any>;
  };
  const { dispatch } = useContext<any>(Store);
  const [pdfUrl, setpdfUrl] = useState('');
  const [autoStart, setAutoStart] = useState(true);
  const [monographData, setMonographData] = useState<MonographData>({
    adverse_reactions: 0,
    clinical_pharmacology: 0,
    clinical_trials: 0,
    contraindications: 0,
    dosage_and_administration: 0,
    dosage_forms_strengths_composition_and_packaging: 0,
    drug_interactions: 0,
    indications: 0,
    microbiology: 0,
    nonclinical_toxicology: 0,
    overdosage: 0,
    pharmaceutical_information: 0,
    serious_warnings_and_precautions_box: 0,
    special_handling_instructions: 0,
    storage_stability_and_disposal: 0,
    supporting_product_monographs: 0,
    warnings_and_precautions: 0,
    detailed_pharmacology: 0,
    references: 0,
    patient_medication_information: 0,
    qc_status: '',
    disabled_sections: []
  });
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        adverse_reactions: monographData.adverse_reactions ?? 0,
        clinical_pharmacology: monographData.clinical_pharmacology ?? 0,
        clinical_trials: monographData.clinical_trials ?? 0,
        contraindications: monographData.contraindications ?? 0,
        dosage_and_administration: monographData.dosage_and_administration ?? 0,
        dosage_forms_strengths_composition_and_packaging:
          monographData.dosage_forms_strengths_composition_and_packaging ?? 0,
        drug_interactions: monographData.drug_interactions ?? 0,
        indications: monographData.indications ?? 0,
        microbiology: monographData.microbiology ?? 0,
        nonclinical_toxicology: monographData.nonclinical_toxicology ?? 0,
        overdosage: monographData.overdosage ?? 0,
        pharmaceutical_information: monographData.pharmaceutical_information ?? 0,
        serious_warnings_and_precautions_box:
          monographData.serious_warnings_and_precautions_box ?? 0,
        special_handling_instructions: monographData.special_handling_instructions ?? 0,
        storage_stability_and_disposal: monographData.storage_stability_and_disposal ?? 0,
        supporting_product_monographs: monographData.supporting_product_monographs ?? 0,
        warnings_and_precautions: monographData.warnings_and_precautions ?? 0,
        patient_medication_information: monographData.patient_medication_information ?? 0,
        detailed_pharmacology: monographData.detailed_pharmacology ?? 0,
        references: monographData.references ?? 0,
        qc_status: monographData.qc_status ?? 'Non Qced',
        key: productData.id,
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      };
      const res = await updateMonographPageNoDetails(productData.key, payload, original);
      const resData = res.data;
      if (resData.error) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: resData.message }
        });
      } else {
        closeEdit();
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: resData.body, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };

  useEffect(() => {
    const getProductData = async () => {
      try {
        const result = await getMonographPageNoDetails(productData.key, original);
        if (!result?.data?.body || result.status !== 200) {
          throw Error('Result Not Available');
        } else if (result.data.body) {
          const resultData = result.data.body;
          setMonographData({
            adverse_reactions: resultData.adverse_reactions ?? 0,
            clinical_pharmacology: resultData.clinical_pharmacology ?? 0,
            clinical_trials: resultData.clinical_trials ?? 0,
            contraindications: resultData.contraindications ?? 0,
            dosage_and_administration: resultData.dosage_and_administration ?? 0,
            dosage_forms_strengths_composition_and_packaging:
              resultData.dosage_forms_strengths_composition_and_packaging ?? 0,
            drug_interactions: resultData.drug_interactions ?? 0,
            indications: resultData.indications ?? 0,
            microbiology: resultData.microbiology ?? 0,
            nonclinical_toxicology: resultData.nonclinical_toxicology ?? 0,
            overdosage: resultData.overdosage ?? 0,
            pharmaceutical_information: resultData.pharmaceutical_information ?? 0,
            serious_warnings_and_precautions_box:
              resultData.serious_warnings_and_precautions_box ?? 0,
            special_handling_instructions: resultData.special_handling_instructions ?? 0,
            storage_stability_and_disposal: resultData.storage_stability_and_disposal ?? 0,
            supporting_product_monographs: resultData.supporting_product_monographs ?? 0,
            warnings_and_precautions: resultData.warnings_and_precautions ?? 0,
            references: resultData.references ?? 0,
            patient_medication_information: resultData.patient_medication_information ?? 0,
            detailed_pharmacology: resultData.detailed_pharmacology ?? 0,
            qc_status: resultData.qc_status ?? 'Non Qced',
            disabled_sections: resultData.disabled_sections ?? []
          });
          setpdfUrl(resultData.document_link);
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getProductData();
  }, []);

  return (
    <Container sx={{ overflow: 'hidden' }}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Product Monograph Page Number Curation
          </Typography>
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  checked={autoStart}
                  onChange={() => {
                    setAutoStart(!autoStart);
                  }}
                />
              }
              label='Auto sorting'
            />
          </Grid>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} display='flex'>
            <Grid item xs={12} lg={6} mb={3} sx={{ overflow: 'auto', height: '70vh', padding: 2 }}>
              <form onSubmit={handleSubmit} style={{ marginTop: '20px' }}>
                <Grid container spacing={3}>
                  {autoStart ? (
                    <Grid item xs={12}>
                      {getsortLabels(Fields, monographData).map((arg: any) => {
                        return (
                          <TextFieldCard
                            key={arg.id}
                            id={arg.id}
                            labelName={arg.labelName}
                            data={monographData}
                            setData={setMonographData}
                            setDisableSubmit={setDisableSubmit}
                          />
                        );
                      })}
                    </Grid>
                  ) : (
                    <Grid item xs={12}>
                      {Fields.map((arg: any) => {
                        return (
                          <TextFieldCard
                            key={arg.id}
                            id={arg.id}
                            labelName={arg.labelName}
                            data={monographData}
                            setData={setMonographData}
                            setDisableSubmit={setDisableSubmit}
                          />
                        );
                      })}
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Autocomplete
                      id='qc_status'
                      fullWidth
                      value={monographData.qc_status}
                      options={qcStatus}
                      onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
                        if (newValue !== null) {
                          setMonographData(prevData => {
                            return { ...prevData, qc_status: newValue };
                          });
                        }
                      }}
                      sx={{ fontSize: '2vh' }}
                      renderInput={(params: any) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <TextField {...params} label='QC Status' variant='outlined' required />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} display='flex' direction='column'>
                    <Button
                      type='submit'
                      fullWidth
                      variant='contained'
                      disabled={disableSubmit}
                      color='primary'
                      style={{ color: 'white' }}>
                      SUBMIT
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Grid>
            <Grid item xs={12} lg={6} sx={{ height: '70vh' }}>
              <iframe
                src={pdfUrl}
                title='Product Monograph'
                style={{ height: '100%', width: '100%' }}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </div>
    </Container>
  );
};

export default React.memo(MonographPageEditDialog);
