import { makeStyles } from '@material-ui/core';

export default makeStyles(theme => ({
  root: {
    height:"100vh",
    background: theme.palette.backgrounds.dark
  },
  container: {
    paddingTop: theme.spacing(10)
  },
  subHeading: {
    color: theme.palette.adminHome.grayText,
    fontSize: theme.spacing(2),
    fontWeight: 'light'
  },
  heading: {
    fontSize: theme.spacing(4),
    color: theme.palette.white,
    fontWeight: 'light'
  },
  name: {
    marginLeft: theme.spacing(1),
    color: theme.palette.white,
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: theme.spacing(4)
  },
  divider: {
    marginTop: theme.spacing(2)
  },
  grids: {
    marginTop: theme.spacing(2)
  },
  paper: {
    width: '80%',
    height: theme.spacing(15),
    borderRadius: theme.spacing(1.5),
    background: 'linear-gradient(to right, #96deda, #50c9c3)',
    '&:hover': {
      opacity: '.8'
    }
  },
  paperBox: {
    height: '100%'
  },
  cardText: {
    color: theme.palette.primaryBlack,
    fontWeight: 'bold',
    textDecoration: 'none'
  },
  tab: {
    borderRadius: theme.spacing(1.5),
    fontWeight: 'bolder',
    background: 'linear-gradient(to right, #96deda, #50c9c3) !important',
    margin: theme.spacing(1.2),
    '&:hover': {
      opacity: '.8'
    }
  },
  notAvailable: {
    color: theme.palette.white,
    fontSize: theme.spacing(3),
    fontWeight: 'bold'
  },
  pdfEma: {
    borderRadius: theme.spacing(1.5)
  }
}));
