import makeRequest from './client';

/**
 * Function to get All Meetings details
 * @param {number} pageSize
 * @param {number} page
 * @param {object} payload
 */
export const getAllMeetings = (pageSize: number, page: number, payload: any) =>
  makeRequest(`/adcomm/meetings?page_size=${pageSize}&page=${page}`, 'POST', payload);

/**
 * Function to get All Meetings details
 * @param {number} pageSize
 * @param {number} page
 * @param {object} payload
 */
export const getAllApplications = (pageSize: number, page: number, payload: any) =>
  makeRequest(`/adcomm/meetings/application?page_size=${pageSize}&page=${page}`, 'POST', payload);

/**
 * Function to get All Meetings details
 * @param {string} meetingId
 */
export const getSelectedApplication = (meetingId: string, center: string) =>
  makeRequest(`/adcomm/meeting/${meetingId}/application?center=${center}`, 'GET');

/**
 * Function to get All device details of a meeting ID
 * @param {string} meetingId
 */
export const getSelectedDevice = (meetingId: string) =>
  makeRequest(`/adcomm/meeting/${meetingId}/device`, 'GET');

/**
 * Function to get All Meetings detailse
 * @param {string} meetingId
 * @param {object} payload
 */
export const submitDevice = (meetingId: string, payload: any) =>
  makeRequest(`/adcomm/meeting/${meetingId}/device`, 'POST', payload);
/**
 * Function to get All Meetings detailse
 * @param {string} meetingId
 * @param {object} payload
 */
export const submitApplication = (meetingId: string, payload: any) =>
  makeRequest(`/adcomm/meeting/${meetingId}/application`, 'POST', payload);

/**
 * Function to get a single Meeting details
 * @param {string} table
 * @param {string} meetingId
 */
export const getOneMeeting = (meetingId: string, table: string) =>
  makeRequest(`/adcomm/singleMeeting?table=${table}&meetingId=${meetingId}`, 'GET');

/**
 * Function to get Applications of a Meeting.
 * @param {string} meetingId
 * @param {string} table
 */
export const getAppsOneMeeting = (meetingId: string, table: string) =>
  makeRequest(`/adcomm/singleMeetingApps?table=${table}&meetingId=${meetingId}`, 'GET');

/**
 * Function to Save or Submit details about a meeting
 * @param {object} payload
 * @param {string} action
 */
export const saveOrSubmitMeetingDetails = (payload: any, action: string) =>
  makeRequest(`/adcomm/meeting?action=${action}`, 'POST', payload);

/**
 * Function to get details of One Application.
 * @param {string} table
 * @param {string} applPrimaryKey
 */
export const getOneApp = (table: string, applPrimaryKey: string) =>
  makeRequest(`/adcomm?table=${table}&key=${applPrimaryKey}`, 'GET');

/**
 * Function to Submit Application Details.
 * @param {object} payload
 */
export const submitApplicationDetails = (payload: any) =>
  makeRequest(`/adcomm/application/submit`, 'POST', payload);

/**
 * Function to Submit Application Qc Status.
 * @param {object} payload
 * @param {string} meetingId
 */
export const submitApplicationQcStatus = (meetingId: string, payload: any) =>
  makeRequest(`/adcomm/application/${meetingId}`, 'POST', payload);

/**
 * Function to Submit Application Qc Status.
 * @param {object} payload
 * @param {string} meetingId
 */
export const submitOverallQcStatus = (meetingId: string, resource: string, payload: any) =>
  makeRequest(`/adcomm/meeting/${meetingId}/${resource}/qc`, 'POST', payload);

/**
 * It makes a GET request to the `/adcomm/:meetingId/minutes` endpoint
 * @param meetingId - The ID of the meeting you want to get the minutes for.
 */
export const getAdcommMinutes = (meetingId: string) =>
  makeRequest(`/adcomm/meeting/${meetingId}/minutes`, 'GET');

/**
 * It makes a GET request to the `/adcomm/minutes/:minutesId` endpoint
 */
export const getAddcommMinutesById = (minutesId: string) =>
  makeRequest(`/adcomm/meeting/minutes/${minutesId}`, 'GET');

/**
 * It makes a POST request to the `/adcomm/:meetingId/minutes` endpoint with the payload
 * @param meetingId - The ID of the meeting you want to update
 * @param payload - the data to be sent to the server
 */
export const upsertAdcommMinutes = (meetingId: string, payload: any) =>
  makeRequest(`/adcomm/meeting/${meetingId}/minutes`, 'POST', payload);

/**
 * It makes a GET request to the `/adcomm/:minutesId/roster` endpoint
 */
export const getAdcommRoster = (minutesId: string) =>
  makeRequest(`/adcomm/meeting/${minutesId}/roster`, 'GET');

/**
 * It makes a GET request to the `/adcomm/rosterId/:rosterId` endpoint and returns the response
 */
export const getAddcommRosterById = (rosterId: string) =>
  makeRequest(`/adcomm/meeting/rosters/${rosterId}`, 'GET');

/**
 * This function makes a POST request to the `/adcomm/:meetingId/roster` endpoint with the given payload.
 * @param meetingId - The ID of the meeting you want to update
 * @param payload - {
 */
export const upsertAdcommRoster = (meetingId: string, payload: any) =>
  makeRequest(`/adcomm/meeting/${meetingId}/roster`, 'POST', payload);

/**
 * This function makes a POST request to the `/adcomm/:meetingId/roster` endpoint with the given payload.
 * @param meetingId - The ID of the meeting you want to update
 * @param payload - {
 */
export const getMeetingIdSuggestion = (category: string, field: string, searchTerm: string) =>
  makeRequest(`/adcomm/suggestion/${category}/${field}/${searchTerm}`, 'GET');

/**
 * It makes a GET request to the /adcomm/members endpoint and returns the response
 */
export const getMemberList = () => makeRequest('/adcomm/members', 'GET');

export const getCommittee = () => makeRequest('/adcomm/committee', 'GET');

export const getMaterial = (meetingId: string) =>
  makeRequest(`/adcomm/meeting/${meetingId}/material`, 'GET');

export const upsertAdcommMaterial = (meetingId: string, payload: any) =>
  makeRequest(`/adcomm/meeting/${meetingId}/material`, 'POST', payload);

export const getNonQcedLetters = (page: number, perPage: number) =>
  makeRequest(`/cder/non-qcdletters?page=${page}&per_page=${perPage}`, 'GET');

export const getNonQcedLabels = (page: number, perPage: number) =>
  makeRequest(`/cder/non-qcdlabel?page=${page}&per_page=${perPage}`, 'GET');

export const deleteMaterial = (meetingId: string, materialId: string) =>
  makeRequest(`/adcomm/meeting/${meetingId}/material/${materialId}`, 'DELETE');

export const deleteMinute = (minutesId: string) =>
  makeRequest(`/adcomm/minutes/${minutesId}`, 'DELETE');

export const deleteRoster = (rosterId: string) =>
  makeRequest(`/adcomm/roster/${rosterId}`, 'DELETE');
