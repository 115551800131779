import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Auth } from 'aws-amplify';

// mui
import Box from '@mui/material/Box';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Container from '@mui/material/Container';
import { DialogContent, Skeleton } from '@mui/material';
import Typography from '@mui/material/Typography';

// api
import { getAddcommMinutesById, upsertAdcommMinutes } from '../../api/AdcommModule';

// Store
import Actions from '../../store/actions';
import Store from '../../store';
import CurationForm from '../CustomDashboard/CurationForm';
import { MINUTES_FORM_MAPPING } from './constant';

// utils
import getDataFields, { getFieldsFromApiData } from '../CustomDashboard/utils/getDataFields';

const MinutesCurationForm = ({ closeEdit, id, pdf }: any) => {
  const { meetingId } = useParams<any>();
  const { dispatch } = useContext<any>(Store);
  const [pdfUrl, setpdfUrl] = useState<any>({});
  const [meetingData, setMeetingData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [documentId, setdoucmentId] = useState<string>('');

  useEffect(() => {
    if (pdfUrl) {
      const minutes: Array<any> = [...meetingData];
      minutes.forEach((ele: any) => {
        if (ele.id === 'material_category') {
          // eslint-disable-next-line no-param-reassign
          ele.value = pdfUrl?.material_category || '';
          // eslint-disable-next-line no-param-reassign
          ele.validate = Boolean(documentId);
        }
      });
      setMeetingData(minutes);
    }
  }, [pdfUrl]);

  useEffect(() => {
    const getMeetingData = async () => {
      try {
        setLoading(true);
        if (id) {
          const result = await getAddcommMinutesById(id);
          if (result.data.status === 200) {
            const minutesList: Array<any> = getFieldsFromApiData(
              result.data.body.minutes,
              MINUTES_FORM_MAPPING
            );
            setMeetingData(minutesList);
            setdoucmentId(result.data.body.minutes?.document_id || '');
          }
        } else {
          setMeetingData(getFieldsFromApiData([], MINUTES_FORM_MAPPING));
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
        closeEdit();
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };
    getMeetingData();
  }, [id]);

  const handleSubmit = async (e: React.SyntheticEvent, data: any) => {
    try {
      if (Object.keys(data).length !== 0) {
        const user = await Auth.currentUserInfo();
        const payload = {
          ...getDataFields(data),
          curator_email: user.attributes.email,
          curator_name: user.attributes['custom:user'],
          qc_date: new Date(),
          qc_complete: true,
          document_id: pdfUrl?.id,
          id,
          status: 'Qced'
        };
        const result = await upsertAdcommMinutes(meetingId, payload);
        if (result && result.data.status === 200) {
          await dispatch({
            type: Actions.SET_ALERT,
            value: {
              status: true,
              message: `Minutes ${id === 'add' ? 'added' : 'edited'} successfully`,
              color: 'success'
            }
          });
        } else {
          await dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: result.data.error }
          });
        }
      }
    } catch (error: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: error.message }
      });
    } finally {
      closeEdit();
    }
  };
  return (
    <Container sx={{ overflow: 'hidden' }}>
      <div>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography
            component='h2'
            variant='h5'
            style={{ color: '#3AB09E', fontWeight: 'bold', fontSize: '28px' }}>
            Minutes Curation
          </Typography>
          <IconButton
            aria-label='close'
            onClick={() => {
              closeEdit();
            }}
            sx={{
              position: 'absolute',
              right: 5,
              top: 2,
              color: 'gray.light'
            }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {!loading ? (
            <CurationForm
              fields={meetingData}
              handleSubmit={handleSubmit}
              fileUrls={pdf}
              setSelectedPdfUrl={setpdfUrl}
            />
          ) : (
            <Box display='flex' maxHeight='100vh' sx={{ overflow: 'hidden' }}>
              <Skeleton variant='rectangular' height='100vh' width='100%' />
            </Box>
          )}
        </DialogContent>
      </div>
    </Container>
  );
};

export default React.memo(MinutesCurationForm);
