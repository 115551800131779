import makeRequest from './client';

export const getEMAData = () => makeRequest(`/ema/ema_records`, 'GET');

export const createEMAData = (payload: any) => makeRequest(`/ema/add_record`, 'POST', payload);

export const updateEMAData = (id: number, payload: any) =>
  makeRequest(`/ema/ema_records/${id}`, 'PUT', payload);

export const deleteEMAData = (id: number) => makeRequest(`/ema/ema_records/${id}`, 'DELETE');

export const updateEMAKeyStatus = (key: string, payload: any) =>
  makeRequest(`/ema/keys/${key}`, 'POST', payload);

interface EMAKeysParams {
  curationType:string,
  status?: string;
  applicationNumber?: string;
}

export const getEMAKeys = (params: EMAKeysParams) => {
  const {curationType, status, applicationNumber } = params;
  
  const baseURL = '/ema/keys';
  const queryParams = [

    `curation_type=${curationType}`,
    status && `qc_status=${status}`,
    applicationNumber && `application_number=${applicationNumber}`,
  ].filter(Boolean); // Filter out undefined or falsey values

  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const triggerEMADag = (payload: any) => {
  return makeRequest(`/ema/trigger`, 'POST', payload);
};
