import palette from '../../../themev5/palette';

const styles = {
  heading: {
    color: palette.primary.main,
    textAlign: 'left',
    fontWeight: '600',
    backdropFilter: 'blur(5px) saturate(50%)',
    filter: 'blur(0)'
  },
  subtitle: {
    color: palette.primary.main,
    textAlign: 'left',
    fontWeight: '600',
    backdropFilter: 'blur(5px) saturate(50%)',
    filter: 'blur(0)'
  },
  addTableFab: {
    mr: 5,
    mb: 5,
    backgroundColor: palette.primary.main,
    '&:hover': {
      backgroundColor: palette.primary.dark
    }
  }
};

export default styles;
