import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
// api
// Styles
import { Auth } from 'aws-amplify';
import Styles from '../AdcommAdminMeetingReview/styles/AdcommAdminMeetingReviewStyles';
// components
import EditMeetingFields from '../AdcommAdminMeetingReview/EditMeetingFields';
import AdCommWebPageiFrame from '../AdcommAdminMeetingReview/AdCommWebPageiFrame';

const Pre2009 = () => {
  const classes = Styles();
  const [meeting, setMeeting] = useState({});
  const [webLink, setWebLink] = useState('');
  const [qcComplete, setQcComplete] = useState();
  const [meetingStatus, setMeetingStatus] = useState();
  const { meetingId } = useParams();
  const history = useHistory();
  useEffect(() => {
    if (meetingId) {
      const fetchMeeting = async () => {
        const user = await Auth.currentUserInfo();
        setMeeting({
          meeting_id: meetingId,
          acronym: meetingId.split('_')[0],
          curator_email: user.attributes.email,
          curator_name: user.attributes['custom:user']
        });
      };
      fetchMeeting();
    }
  }, [meetingId]);
  const handleGoBack = () => {
    history.push('/adcomm');
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth='lg'>
        <Box display='flex' padding={1} flexDirection='row' justifyContent='flex-start'>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Button onClick={handleGoBack} className={classes.button}>
              Back
            </Button>
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={12} lg={6}>
            <Box display='flex' flexDirection='column'>
              <EditMeetingFields
                classes={classes}
                meeting={meeting}
                setMeeting={setMeeting}
                qcComplete={qcComplete}
                setQcComplete={setQcComplete}
                meetingStatus={meetingStatus}
                setMeetingStatus={setMeetingStatus}
                editButtons={false}
                adminStatus={false}
                isNewMeeting
                webLink={webLink}
              />
            </Box>
          </Grid>
          <Grid item xs={12} lg={6}>
            <Box
              className={classes.sticky}
              display='flex'
              justifyContent='flex-start'
              alignItems='flex-start'
              styles={{ border: '2px solid red', margin: '20px', background: 'white' }}>
              <AdCommWebPageiFrame webLink={webLink && webLink} isPre2009 setWebLink={setWebLink} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
export default Pre2009;
