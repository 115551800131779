export const WHATS_NEW_TYPES = {
  'Enhancement Updates': 'Enhancement Updates',
  'Major Updates': 'Major Updates'
};

export const WHATS_NEW_STATUSES = {
  Draft: 'Draft',
  'Design Review': 'Design Review',
  'Final Review': 'Final Review',
  Approved: 'Approved',
  Published: 'Published'
};

export const WHATS_NEW_VISUAL_TYPES = {
  image: 'image',
  video: 'video'
};

export const USERS_ALLOWED_TO_EDIT = {
  [WHATS_NEW_STATUSES['Design Review']]: [
    'ankit@vivpro.ai',
    'sid@vivpro.ai',
    'nirva@vivpro.ai',
    'siva@vivpro.ai',
    'unnati@vivpro.ai',
    'ashwathsringeri@gmail.com'
  ]
};

export const USERS_ALLOWED_TO_UPDATE_STATUS = {
  [WHATS_NEW_STATUSES['Design Review']]: [
    'ankit@vivpro.ai',
    'sid@vivpro.ai',
    'nirva@vivpro.ai',
    'siva@vivpro.ai',
    'unnati@vivpro.ai',
    'ashwathsringeri@gmail.com'
  ],
  [WHATS_NEW_STATUSES['Final Review']]: [
    'ankit@vivpro.ai',
    'sid@vivpro.ai',
    'pravin@vivpro.ai',
    'siva@vivpro.ai',
    'unnati@vivpro.ai',
    'ashwathsringeri@gmail.com'
  ],
  [WHATS_NEW_STATUSES.Approved]: [
    'ankit@vivpro.ai',
    'sid@vivpro.ai',
    'pravin@vivpro.ai',
    'siva@vivpro.ai',
    'unnati@vivpro.ai',
    'ashwathsringeri@gmail.com'
  ]
};

export const LIMITS = {
  mainTitle: 1000,
  description: 10000,
  title: 10000,
  content: 10000
};
