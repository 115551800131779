import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// components variants
import components from './components';

// color palette
import palette from './palette';

// typography
import typography from './typography';

let themev5 = createTheme({
  // spacing is used for margins and paddings
  spacing: 8,
  palette,
  typography,
  components,
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  }
});

themev5 = responsiveFontSizes(themev5);

export default themev5;
