import Box from '@mui/material/Box';
import React, { useState, useMemo, useCallback, useEffect } from 'react';

import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';

import includes from 'lodash/includes';

import StudyComponent from './StudyForm';
import ConfirmationPop from './ConfirmationPopup';
import IndicationComparion from './IndicationComparion';

import Styles from './styles/utils';

import useIndicationAPIs from './hooks/useIndicationAPIs';
import WarningPopup from './WarningPopup';
import Select from './Select';
import useQCStatus from './hooks/useQCStatus';
import { QC_OPTIONS } from './constant';

const validateStudyData = (studyData: any, attributes: Array<any>) => {
  const errors: Array<string> = [];
  const warnings: Array<string> = [];
  let valid = true;

  const durationId = attributes?.find(
    (attr: any) => attr.attribute_name.toLowerCase() === 'duration'
  )?.attribute_id;
  const durationUnitId = attributes?.find(
    (attr: any) => attr.attribute_name.toLowerCase() === 'duration unit'
  )?.attribute_id;

  const duration = studyData.attributes?.find(
    (attr: any) => attr.attribute_id === durationId
  )?.attribute_value;
  const durationUnit = studyData.attributes?.find(
    (attr: any) => attr.attribute_id === durationUnitId
  )?.attribute_value;

  if (duration && !durationUnit) {
    errors.push('Duration is added, but Duration unit is missing.');
    valid = false;
  }
  if (!duration && durationUnit) {
    errors.push('Duration is missing but duration is selected');
    valid = false;
  }
  const studyAttributes = studyData.attributes
    ?.filter((attr: any) => !!attr.attribute_value)
    ?.map((attr: any) => attr.attribute_id);

  attributes.forEach((attr: any) => {
    if (!includes(studyAttributes, attr.attribute_id)) {
      warnings.push(`${attr.attribute_name} is missing.`);
    }
  });

  // Find the attribute ID for 'Randomization Ratio' from the attributes array
  const ratioId = attributes.find(
    (attr: any) => attr.attribute_name === 'Randomization Ratio'
  )?.attribute_id;

  // Find the attribute ID for 'Number of Arms' from the attributes array
  const numberArmsId = attributes.find(
    (attr: any) => attr.attribute_name === 'Number of Arms'
  )?.attribute_id;

  // Find the attribute value for 'Randomization Ratio' using the ratioId
  const ratioValue = ratioId
    ? studyData.attributes.find((attr: any) => attr.attribute_id === ratioId)?.attribute_value
    : null;

  // Find the attribute value for 'Number of Arms' using the numberArmsId
  const numberArmsValue = numberArmsId
    ? studyData.attributes.find((attr: any) => attr.attribute_id === numberArmsId)?.attribute_value
    : null;

  // Check if both ratioValue and numberArmsValue are found (i.e., not null or undefined)
  if (ratioValue && numberArmsValue) {
    // Split the ratioValue by ':' and get the length of the resulting array
    // If ratioValue is null or undefined, use 0 as the default value
    const ratioCount = ratioValue?.split(':').length ?? 0;

    // Compare the number of arms (parsed as an integer) with the ratioCount
    // If they do not match, add an error message to the errors array and set valid to false
    if (parseInt(numberArmsValue, 10) !== ratioCount) {
      errors.push('Number of Arms and Randomization Ratios are not matching');
      valid = false;
    }
  }

  return { valid, warnings, errors };
};

const IndicationForm = ({
  applno,
  // eslint-disable-next-line camelcase
  submission_type,
  // eslint-disable-next-line camelcase
  submission_number,
  apiIndications = []
}: any) => {
  const classes = Styles();

  const [indicationToDelete, setIndicationToDelete] = useState<any>(null);
  const [studyDataToDelete, setStudyDataToDelete] = useState<any>(null);
  const [errorsWarning, setErrors] = useState<any>(null);
  const [dataToSave, setDataTobeSaved] = useState<any>(null);

  const [selectedIndicationForComparison, selectIndicationForComparison] = useState<any>(null);

  const { updateQcStatus, appQCStatus, getQcStatus } = useQCStatus(
    applno,
    submission_type,
    submission_number
  );

  const [qcStatus, setQcStatus] = useState<string | null>(appQCStatus || QC_OPTIONS[0].value);

  const {
    indications,
    attributes,
    endpointOptions,
    expandIndication,
    editIndication,
    saveIndication,
    updateIndicationText,
    addEmptyStudy,
    editStudy,
    deleteStudyData,
    saveStudyData,
    updateStaleStatus,
    copyStaleIndications,
    rejectIdentical,
    copyIndicationFromPrev,
    deleteIndicationId,
    addEmptyIndication
  } = useIndicationAPIs(applno, submission_type, submission_number, apiIndications);

  useEffect(() => {
    getQcStatus();
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    appQCStatus && setQcStatus(appQCStatus);
  }, [appQCStatus]);

  const handleSaveStudyData = async (indicationId: number, studyData: any) => {
    const { errors = [], warnings = [] } = validateStudyData(studyData, attributes);
    if (errors.length > 0 || warnings.length > 0) {
      setErrors({
        errors,
        warnings
      });
      setDataTobeSaved({
        indicationId,
        studyData
      });
    } else {
      saveStudyData(indicationId, studyData);
    }
  };

  const continueStudySave = useCallback(() => {
    saveStudyData(dataToSave.indicationId, dataToSave.studyData);
    setDataTobeSaved(null);
    setErrors(null);
  }, [dataToSave]);

  const handleDeleteStudyData = async (indicationId: number, studyData: any) => {
    setStudyDataToDelete({
      indicationId,
      studyData
    });
  };

  const handleDeleteStudyDataConfirm = async () => {
    const { indicationId, studyData } = studyDataToDelete;
    deleteStudyData(indicationId, studyData);
    setStudyDataToDelete(null);
  };

  const handleDeleteIndication = async (indicationId: number) => {
    const index = indications.findIndex((ind: any) => ind.indication.id === indicationId);
    setIndicationToDelete(indications[index]);
  };

  const handleDeleteIndicationConfirm = async () => {
    const indicationId = indicationToDelete.indication.id;
    await deleteIndicationId(indicationId);
    setIndicationToDelete(null);
  };

  const handleIdenticalClick = async (indicationId: number) => {
    await copyIndicationFromPrev(indicationId);
  };

  const handleNotIdenticalClick = async (indicationId: number) => {
    await rejectIdentical(indicationId);
  };

  const handleIndicationCopy = async () => {
    await copyStaleIndications(selectedIndicationForComparison);
    selectIndicationForComparison(null);
  };

  const handleStaleUpdate = async () => {
    const indicationId = selectedIndicationForComparison[1].id;

    await updateStaleStatus(indicationId);
    selectIndicationForComparison(null);
  };
  const handleQCStatusChange = useCallback((event: any) => setQcStatus(event.target.value), []);

  const copiedFromPreviousSubmission = useMemo(() => {
    return indications?.findIndex((ind: any) => ind.dataFromPrevSubmission) > -1;
  }, [indications]);

  return (
    <Box
      alignItems='start'
      display='flex'
      justifyContent='start'
      position='relative'
      flexDirection='column'
      sx={{
        '& ul': {
          width: '97%',
          pl: 0
        }
      }}>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            p: 0,
            pl: 0,
            pt: 1
          }
        }}>
        {indications?.map(
          ({
            indication,
            stale_parent: staleParent = null,
            expanded = false,
            editOpen = false,
            newIndication = false,
            dataFromPrevSubmission = false,
            // eslint-disable-next-line camelcase
            is_stale = false
          }: any) => {
            const color =
              dataFromPrevSubmission ||
              newIndication ||
              indication.studies?.findIndex((stud: any) => stud.newStudy) > -1
                ? 'red'
                : 'green';
            return (
              <TimelineItem key={indication.id}>
                {/* <TimelineOppositeContent>Indication {index + 1}</TimelineOppositeContent> */}
                <TimelineSeparator>
                  <TimelineDot sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
                    <Box display='inline' onClick={() => expandIndication(indication.id)}>
                      {!expanded ? (
                        <ControlPointIcon sx={{ color, fontSize: 34, cursor: 'pointer' }} />
                      ) : (
                        <RemoveCircleOutlineIcon sx={{ color, fontSize: 34, cursor: 'pointer' }} />
                      )}
                    </Box>
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent
                  // eslint-disable-next-line camelcase
                  sx={{ backgroundColor: is_stale ? '#fcf40333' : 'inherit' }}>
                  <Box display='flex' mt={2} alignItems='center'>
                    {!editOpen ? (
                      <Typography>{indication.text}</Typography>
                    ) : (
                      <TextField
                        placeholder='Add Indication Text'
                        required
                        sx={{ width: '50%' }}
                        onChange={e => updateIndicationText(indication.id, e.target.value)}
                        value={indication.text}
                        InputProps={{
                          classes: {
                            input: `${classes.input}`
                          }
                        }}
                      />
                    )}
                    <Box display='flex' alignItems='center'>
                      <Tooltip title='Edit indication title'>
                        <Box display='inline'>
                          <Button
                            onClick={() => editIndication(indication.id)}
                            disabled={editOpen || dataFromPrevSubmission}>
                            Edit
                          </Button>
                        </Box>
                      </Tooltip>
                      <Tooltip title='Save indication title'>
                        <Box display='inline'>
                          <Button
                            onClick={() => saveIndication(indication.id)}
                            sx={{ color: '#33B187' }}
                            disabled={!editOpen || dataFromPrevSubmission}>
                            Save
                          </Button>
                        </Box>
                      </Tooltip>
                      <Tooltip title='Delete this Indication'>
                        <Box display='inline' onClick={() => handleDeleteIndication(indication.id)}>
                          <DeleteForeverIcon
                            sx={{ color: 'red', cursor: 'pointer', ml: 0.5, mr: 0.5 }}
                          />
                        </Box>
                      </Tooltip>
                      <Tooltip title='View Studies'>
                        <Box display='inline' onClick={() => expandIndication(indication.id)}>
                          <ExpandCircleDownIcon
                            sx={{ color: 'primary', cursor: 'pointer', ml: 0.5, mr: 0.5 }}
                          />
                        </Box>
                      </Tooltip>

                      {/* eslint-disable-next-line camelcase */}
                      {is_stale && (
                        <Button
                          sx={{ ml: 3 }}
                          onClick={() => selectIndicationForComparison([staleParent, indication])}>
                          Compare Stale
                        </Button>
                      )}
                    </Box>
                  </Box>
                  {expanded && (
                    <Box p={1} sx={{ maxWidth: '620px' }}>
                      {indication.studies?.map((study: any, i: number) => (
                        <StudyComponent
                          attributeOptions={attributes}
                          endpointOptions={endpointOptions}
                          // eslint-disable-next-line react/no-array-index-key
                          key={`${study.id}-${i}`}
                          studyData={study}
                          i={i}
                          formOpen={study.formOpen}
                          indicationSaved={!(newIndication || dataFromPrevSubmission)}
                          dataFromPrevSubmission={dataFromPrevSubmission}
                          onEditOpen={(studyData: any) => editStudy(indication.id, studyData)}
                          onSaveClick={(studyData: any) =>
                            handleSaveStudyData(indication.id, studyData)
                          }
                          onDeleteClick={(studyData: any) =>
                            handleDeleteStudyData(indication.id, studyData)
                          }
                        />
                      ))}
                      <Box display='flex' width={300}>
                        {!dataFromPrevSubmission ? (
                          <Tooltip
                            title={
                              newIndication || dataFromPrevSubmission
                                ? 'Save Indication text changes before adding studies'
                                : ''
                            }>
                            <Box display='flex'>
                              <Button
                                variant='contained'
                                disabled={
                                  newIndication || dataFromPrevSubmission || attributes.length === 0
                                }
                                sx={{ m: 'auto', mt: 2 }}
                                onClick={() => addEmptyStudy(indication.id)}>
                                Add New Study Data
                              </Button>
                            </Box>
                          </Tooltip>
                        ) : (
                          <>
                            <Button
                              variant='contained'
                              sx={{ m: 'auto', mt: 2 }}
                              onClick={() => handleIdenticalClick(indication.id)}>
                              Indentical
                            </Button>
                            <Button
                              variant='contained'
                              color='error'
                              sx={{ m: 'auto', mt: 2 }}
                              onClick={() => handleNotIdenticalClick(indication.id)}>
                              Not Identical
                            </Button>
                          </>
                        )}
                      </Box>
                    </Box>
                  )}
                </TimelineContent>
              </TimelineItem>
            );
          }
        )}
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot
              sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}
              onClick={addEmptyIndication}>
              <ControlPointIcon sx={{ color: 'black', fontSize: 34, cursor: 'pointer' }} />
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Box display='flex' alignItems='center'>
              <Box>
                <Button variant='contained' onClick={addEmptyIndication}>
                  Add New Indication
                </Button>
              </Box>
            </Box>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
      <Box ml='auto'>
        <Box display='flex' alignItems='center'>
          <Typography>Indications QC Status: </Typography>
          <Select
            label='QC Status'
            value={qcStatus}
            onChange={handleQCStatusChange}
            options={QC_OPTIONS}
          />
          <Button
            variant='contained'
            onClick={() => qcStatus && updateQcStatus(qcStatus as string)}
            sx={{ height: 48 }}>
            Update QC Status
          </Button>
        </Box>
      </Box>

      <Snackbar
        open={copiedFromPreviousSubmission}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        autoHideDuration={6000}
        message={
          <Typography>
            Previous Submission Data is popuplated. You will have to save each indication and its
            studies.
          </Typography>
        }
        sx={{ bottom: { xs: 25 } }}
      />
      {indicationToDelete && (
        <ConfirmationPop
          title='Delete Indication'
          message={`Are you sure want delete indication "${indicationToDelete?.indication?.text}" ?`}
          onClose={() => setIndicationToDelete(null)}
          onConfirm={handleDeleteIndicationConfirm}
        />
      )}
      {studyDataToDelete && (
        <ConfirmationPop
          title='Delete Study Data'
          message='Are you sure want delete the study Data?'
          onClose={() => setStudyDataToDelete(null)}
          onConfirm={handleDeleteStudyDataConfirm}
        />
      )}
      {selectedIndicationForComparison && selectedIndicationForComparison.length === 2 && (
        <Box
          position='absolute'
          top='10px'
          left='10px'
          height='80vh'
          sx={{ bgcolor: 'white' }}
          width='calc(100% - 10px)'>
          <IndicationComparion
            title='Comparing with Previous Submission Data'
            parentIndication={selectedIndicationForComparison[0]}
            currentIndication={selectedIndicationForComparison[1]}
            onClose={() => selectIndicationForComparison(null)}
            onAcceptCurrent={handleStaleUpdate}
            onCopy={handleIndicationCopy}
          />
        </Box>
      )}
      {errorsWarning && (
        <WarningPopup
          errors={errorsWarning.errors}
          warnings={errorsWarning.warnings}
          onContinue={continueStudySave}
          onClose={() => {
            setDataTobeSaved(null);
            setErrors(null);
          }}
        />
      )}
    </Box>
  );
};

export default React.memo(IndicationForm);
