// react
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// material ui imports
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import CircularProgress from '@mui/material/CircularProgress';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

import IndicationForm from './IndicationForm';
import SBASForm from './SBASForm';

// Context
import GlobalStore from '../../store';
import Actions from '../../store/actions';
import AdminStore from '../../store/AdminPage';
import AdminActions from '../../store/AdminPage/actions';

// api
import getIndications from '../../api/submission_details';

import useLabel from './hooks/useLabel';

const SnapshotSummary = ({ match }: any) => {
  const history = useHistory();

  const { adminDispatch, adminState } = useContext<any>(AdminStore);
  const { dispatch } = useContext<any>(GlobalStore);
  const appKey = match?.params?.key;
  const submissionProps = appKey?.split('-');
  const [previousSubmission, setPreviousSubmission] = useState<any>(null);
  const [usePrevSubmissionData, setUsePrevSubmissionData] = useState<boolean>(false);

  const { pdfUrl, loading, errMessage, getLabelPdf } = useLabel();

  useEffect(() => {
    const getData = async () => {
      try {
        const response = await getIndications(
          submissionProps[0].trim(),
          submissionProps[1].trim(),
          submissionProps[2].trim()
        );
        if (response.data.Status === 200) {
          let {
            indications,
            // eslint-disable-next-line prefer-const
            previous_submission: dataFromPrevSubmission,
            // eslint-disable-next-line prefer-const
            previous_application: previousApplication = null
          } = response.data.Success;

          indications = indications.map((ind: any) => ({
            ...ind,
            dataFromPrevSubmission,
            expanded: !!dataFromPrevSubmission
          }));

          adminDispatch({
            type: AdminActions.SET_SUBMISSION_INDICATIONS,
            value: indications
          });

          if (dataFromPrevSubmission) {
            setPreviousSubmission(
              `${previousApplication.applno}-${previousApplication.submission_type}-${previousApplication.submission_number}`
            );
            setUsePrevSubmissionData(true);
          } else {
            setPreviousSubmission(null);
            setUsePrevSubmissionData(false);
          }
        } else if (response.data.Status === 404) {
          adminDispatch({
            type: AdminActions.SET_SUBMISSION_INDICATIONS,
            value: []
          });
        } else {
          await dispatch({
            type: Actions.SET_ALERT,
            value: {
              status: true,
              message: 'Could not find indications for the given submission details'
            }
          });
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getData();
    getLabelPdf(submissionProps[0].trim(), submissionProps[1].trim(), submissionProps[2].trim());
  }, []);

  return (
    <Box mt={10} pl={2}>
      <Box pb={2}>
        <Stack direction='row'>
          <KeyboardBackspaceIcon sx={{ fontSize: 32 }} />
          <Typography
            onClick={() => history.goBack()}
            variant='h5'
            display='inline-block'
            sx={{ cursor: 'pointer' }}>
            Go Back
          </Typography>
        </Stack>
      </Box>
      <Stack direction='row' sx={{ overflow: 'hidden', height: '85vh' }}>
        <Box flex={1} overflow='auto'>
          <SBASForm
            applno={submissionProps[0].trim()}
            submission_type={submissionProps[1].trim()}
            submission_number={submissionProps[2].trim()}
          />
          <Box
            sx={{
              borderRadius: '4px',
              bgcolor: '#fff',
              mt: 4,
              p: 2,
              boxShadow:
                '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)'
            }}>
            <Typography variant='h6' color='black'>
              Indications for {appKey}
            </Typography>

            <Box>
              {previousSubmission && (
                <Box pt={1}>
                  <Typography variant='h6' color='black'>
                    We found indications for previous submission - {previousSubmission}
                  </Typography>
                  <Box display='flex' alignItems='center'>
                    <Typography>Use Previous Submission Data as default value</Typography>
                    <Switch
                      checked={usePrevSubmissionData}
                      onChange={() => setUsePrevSubmissionData(prev => !prev)}
                    />
                  </Box>
                </Box>
              )}
            </Box>
            <IndicationForm
              apiIndications={
                // eslint-disable-next-line no-nested-ternary
                usePrevSubmissionData
                  ? adminState.indications
                  : previousSubmission
                  ? []
                  : adminState.indications
              }
              applno={submissionProps[0].trim()}
              submission_type={submissionProps[1].trim()}
              submission_number={submissionProps[2].trim()}
            />
          </Box>
        </Box>
        <Box flex={1}>
          {(loading || errMessage) && (
            <Box height='100%' position='relative'>
              <Box
                position='absolute'
                top='50%'
                left='50%'
                sx={{ transform: 'translate(-50%, -50%)' }}>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Typography variant='h5'>{errMessage}</Typography>
                )}
              </Box>
            </Box>
          )}
          <iframe src={pdfUrl} frameBorder='0' title='Label' width='100%' height='100%' />
        </Box>
      </Stack>
    </Box>
  );
};

export default SnapshotSummary;
