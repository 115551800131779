import makeRequest from './client';
/**
 * It makes a GET request to the `/cber/label_orig` endpoint
 */
export const getLabelOrig = (
  page,
  perPage,
  status = 'all documents',
  limitationStatus = 'all documents',
  applicationNumber = ''
) => {
  const baseURL = `/cber/labels`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (status !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  if (limitationStatus !== 'all documents') {
    queryParams.push(`limitations_qc_status=${limitationStatus}`);
  }
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const getCuratedLabelDetails = docsId =>
  makeRequest(`/cber/curated-labels/${docsId}`, 'GET');

export const submitURL = applicationDocsId =>
  makeRequest(`/cber/categorization-docs?application_docs_id=${applicationDocsId}`, 'GET');
/**
 * It makes a POST request to the `/cber/update_label_orig` endpoint
 */
export const submitApplicationDetails = (applicationDocsId, payload) =>
  makeRequest(`/cber/labels/${applicationDocsId}`, 'POST', payload);

export const fetchLimitationCurationDetails = applicationDocsId => {
  return makeRequest(`/cber/labels/indication_metadata/${applicationDocsId}`, 'GET');
};

export const submitLimitationCurationDetails = (applicationDocsId, payload) => {
  return makeRequest(`/cber/labels/indication_metadata/${applicationDocsId}`, 'POST', payload);
};

export const getDocCategorization = (
  page,
  perPage,
  status = 'all documents',
  applicationNumber = '',
  applicationTitle = ''
) => {
  const baseURL = `/cber/categorization-docs`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (status !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  if (applicationTitle) {
    queryParams.push(`application_title=${applicationTitle}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const getAllDocument = applicationNumber =>
  makeRequest(`/cber/categorization-docs?application_number=${applicationNumber}`, 'GET');

export const getPdfUrl = docsId =>
  makeRequest(`/cber/categorization-docs?application_docs_id=${docsId}`, 'GET');

export const submitDocDetails = (docsId, payload) =>
  makeRequest(`/cber/categorization-docs/${docsId}`, 'POST', payload);

export const getLabelPlrData = (
  page,
  perPage,
  status = 'all documents',
  applicationNumber = ''
) => {
  const baseURL = `/cber/label/plr`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (status !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const getLabelNonPlrData = (
  page,
  perPage,
  status = 'all documents',
  applicationNumber = ''
) => {
  const baseURL = `/cber/label/non-plr`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (status !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const getLabelPageNumber = (
  page,
  perPage,
  status = 'all documents',
  applicationNumber = ''
) => {
  const baseURL = `/cber/non-qcdlabel`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  if (status !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const submitPageNumberDetails = (labelType, docsId, payload) =>
  makeRequest(`/cber/labels/${labelType}/qc/${docsId}`, 'POST', payload);

export const getLetterPageNumber = (
  page,
  perPage,
  status = 'all documents',
  applicationNumber = ''
) => {
  const baseURL = `/cber/non-qcdletters`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  if (status !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const getLabelData = applicationDocsId =>
  makeRequest(`/cber/get-label/${applicationDocsId}`, 'GET');

export const submitLetterCurationDetails = (docsId, payload) =>
  makeRequest(`/cber/letters/qc/${docsId}`, 'POST', payload);

export const getCberPMRLetterDetails = (applicationNumber = '') => {
  const finalURL = `/cber/pmrc/letters/requirements/${applicationNumber}`;
  return makeRequest(finalURL, 'GET');
};

export const getAllCberPMRLetters = (
  page,
  perPage,
  status = 'all documents',
  applicationNumber = ''
) => {
  const baseURL = `/cber/pmrc/letters`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (applicationNumber) {
    queryParams.push(`application_number=${applicationNumber}`);
  }
  if (status !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const submitCberPmrRequirement = (applicationDocsId, payload) =>
  makeRequest(`/cber/pmrc/letters/requirements/${applicationDocsId}`, 'POST', payload);

export const getLabelDetails = docsId => makeRequest(`/cber/label/${docsId}`, 'GET');

export const getLetterDetails = docsId => makeRequest(`/cber/letter/${docsId}`, 'GET');

export const updatePMRCStatus = (applicationDocsId, payload) =>
  makeRequest(`/cber/pmrc/letters/${applicationDocsId}`, 'POST', payload);

export const submitNewLabelInfo = payload => makeRequest(`/cber/label_section`, 'POST', payload);

export const getProcessedLabelInfo = (docType, docsId) =>
  makeRequest(`/cber/processed/label/${docType}/${docsId}`, 'GET');

export const submitProcessedLabelInfo = (docType, payload) =>
  makeRequest(`/cber/processed/label/${docType}`, 'POST', payload);

export const getCberATCList = (
  pageSize,
  pageNumber,
  applicationNumber,
  activeIngredient,
  atcCode,
  qcStatus,
  emptyATC,
  exactSearch
) =>
  makeRequest(
    `/cber/atc?page_size=${pageSize}&page_number=${pageNumber}&application_number=${applicationNumber}&active_ingredient=${activeIngredient}&atc_code=${atcCode}&qc_status=${qcStatus}&empty_atc=${emptyATC}&exact_search=${exactSearch}`,
    'GET'
  );

export const upsertCberAtcData = payload => makeRequest('/cber/atc/qc', 'POST', payload);

export const fetchCurationDetails = () => {
  return makeRequest(`/cber/cber-dag`, 'GET');
};

export const updateCurationDetails = payload => {
  return makeRequest(`/cber/cber-dag`, 'POST', payload);
};
