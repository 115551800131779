import { useState, useEffect, useContext, ChangeEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// api
import { Button, Grid, Box, TextField } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import LinearProgress from '@material-ui/core/LinearProgress';
import CssBaseline from '@mui/material/CssBaseline';
import { Autocomplete } from '@mui/material';
import { getLabelOrig } from '../../api/Cber';

// context
import Store from '../../store';
import Actions from '../../store/actions';

import themev5 from '../../themev5';
import Styles from './styles/CategorizationTable.styles';
import NonQcdDoc from './NonQcdDoc';
import styles from '../Datagrid/Datagrid.styles';
import DatagridFooter from '../Datagrid/DatagridFooter';
import DatagridNoRowsOverlay from '../Datagrid/DatagridNoRowsOverlay';
import utils from '../Datagrid/utils';
import LimitationCuration from './LimitationCuration';
import CurationButton from './CurationButton';

const qc_status = ['All Documents', 'Escalate', 'Missing Details', 'Non QCed', 'QCed'];
const Cber = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const table_status = params.get('qc_status');
  const application_number = params.get('application_number');
  const { dispatch } = useContext<any>(Store);
  const classes = Styles();
  const [pageSize, setPageSize] = useState(10);
  const [labelKey, setLabelKey] = useState<Array<any>>([]);
  const [labelPageNo, setLabelPageNo] = useState(0);
  const [labelRowCount, setLabelRowCount] = useState();
  const [offset, setOffset] = useState(0);
  const [loading, setloading] = useState(true);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [showLimitationEditDialog, setShowLimitationEditDialog] = useState(false);
  const [applicationNumber, setApplicationNumber] = useState('');
  const [docsId, setDocsId] = useState<Number>();
  const [searchText, setSearchText] = useState(application_number || '');
  const [status, setStatus] = useState(table_status || 'All Documents');
  const [limitationStatus, setLimitationStatus] = useState('All Documents');
  const rowsPerPageOptions = [10, 20, 50, 100];

  useEffect(() => {
    const getNonQcdData = async () => {
      try {
        const result = await getLabelOrig(
          labelPageNo,
          pageSize,
          status === 'Non QCed' ? 'to_qc' : status.toLowerCase(),
          limitationStatus === 'Non QCed' ? 'to_qc' : limitationStatus.toLowerCase(),
          searchText.trim().toLowerCase()
        );

        if (result === undefined) {
          throw new Error('Result is undefined');
        }
        if (result.data && result.data.body.results) {
          setloading(false);
          const resultData = result.data.body.results;
          setLabelRowCount(result.data.body.total_rows);
          setLabelKey([...resultData?.map((item: any, index: any) => ({ ...item, id: index }))]);
        } else {
          setloading(false);
          throw Error('No data');
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getNonQcdData();
  }, [labelPageNo, searchText, status, limitationStatus, pageSize]);

  const openEdit = (data: any) => {
    setShowEditDialog(true);
    setDocsId(data.application_docs_id);
    setApplicationNumber(data.application_number);
  };
  const openLimitationEdit = (data: any) => {
    setShowLimitationEditDialog(true);
    setDocsId(data.application_docs_id);
    setApplicationNumber(data.application_number);
  };

  const closeEdit = () => {
    setShowEditDialog(false);
    setShowLimitationEditDialog(false);
  };
  const columns = [
    {
      field: 'Edit',
      width: 100,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            className={classes.Button}
            onClick={() => {
              openEdit(params.row);
            }}>
            <Typography>Edit</Typography>
          </Button>
        );
      }
    },
    {
      field: 'Limitation Curation',
      width: 250,
      headerClassName: 'MuiDataGrid-columnHeader',
      cellClassName: 'ria-MuiDataGrid-cell',
      headerAlign: 'center',
      renderCell: (params: any) => {
        return (
          <Button
            variant='contained'
            className={classes.Button}
            onClick={() => {
              openLimitationEdit(params.row);
            }}>
            <Typography>Limitation Curation</Typography>
          </Button>
        );
      }
    },
    {
      field: 'application_number',
      headerName: 'Application Number',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'title',
      headerName: 'PDF Title',
      width: 500,
      renderCell: (params: any) => (
        <Grid item xs={8} lg={8}>
          <Tooltip title={params.row.title}>
            <Typography style={{ fontSize: '17px' }}>
              {params.row.title.length > 56
                ? `${params.row.title.substring(0, 52)}...`
                : params.row.title.substring(0, 47)}
            </Typography>
          </Tooltip>
        </Grid>
      )
    },
    {
      field: 'qc_status',
      headerName: 'QC Status',
      width: 150,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => (
        <Grid item xs={8} lg={8}>
          {params.row.qc_status === 'qced'
            ? 'QCed'
            : params.row.qc_status === 'to_qc'
            ? 'Non QCed'
            : utils(params.row.qc_status, 'formulations')}
        </Grid>
      )
    },
    {
      field: 'submission_number',
      headerName: 'Submission Number',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    },
    {
      field: 'submission_type',
      headerName: 'Submission Type',
      width: 200,
      headerAlign: 'center',
      align: 'center'
    }
  ];
  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <Box display='flex' ml={2} mt={10} alignItems='center'>
        <Autocomplete
          id='qc_status'
          value={status}
          onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
            if (newValue !== null) {
              setStatus(newValue);
              setLabelPageNo(0);
            }
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', marginRight: '10px' }}
          options={qc_status}
          renderInput={(params: any) => (
            <TextField {...params} placeholder='QC Status' label='QC Status' variant='outlined' />
          )}
        />
        <Autocomplete
          id='limitation_qc_status'
          value={limitationStatus}
          onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
            if (newValue !== null) {
              setLimitationStatus(newValue);
              setLabelPageNo(0);
            }
          }}
          style={{ fontSize: '1.5vh', minWidth: '250px', marginRight: '10px' }}
          options={qc_status}
          renderInput={(params: any) => (
            <TextField
              {...params}
              placeholder='Limitation QC Status'
              label='Limitation QC Status'
              variant='outlined'
            />
          )}
        />
        <TextField
          variant='outlined'
          type='search'
          id='search_application'
          label='Search Application Number'
          value={searchText}
          InputProps={{
            style: { fontSize: '1.5vh', minWidth: '250px' }
          }}
          InputLabelProps={{
            style: { fontSize: '1.5vh' }
          }}
          onChange={e => {
            setSearchText(e.target.value);
            setLabelPageNo(0);
          }}
        />
      </Box>
      <Box mt={2}>
        <CurationButton />
      </Box>
      <Box
        height='80vh'
        p={2}
        mt={1}
        display='flex'
        alignItems='center'
        justifyContent='center'
        className={classes.root}>
        <DataGrid
          sx={styles.datagrid}
          rowSpacingType='margin'
          sortingOrder={['desc', 'asc']}
          checkboxSelection={false}
          density='standard'
          rows={labelKey}
          columns={columns as any}
          rowCount={labelRowCount}
          pageSize={pageSize}
          getRowId={row => row.id}
          onPageSizeChange={pageSize => {
            setPageSize(pageSize);
            setLabelPageNo(0);
          }}
          onPageChange={setLabelPageNo}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          loading={loading}
          getRowClassName={() => `table-rows`}
          page={labelPageNo}
          paginationMode='server'
          components={{
            // Toolbar: DatagridToolbar,
            LoadingOverlay: LinearProgress,
            Footer: DatagridFooter,
            NoRowsOverlay: DatagridNoRowsOverlay
          }}
        />
        <Dialog
          open={showEditDialog}
          onClose={closeEdit}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          <NonQcdDoc
            closeEdit={closeEdit}
            docs_id={docsId}
            application_number={applicationNumber}
            searchText={searchText}
            search_status={status}
          />
        </Dialog>
        <Dialog
          open={showLimitationEditDialog}
          onClose={closeEdit}
          sx={{ backdropFilter: 'blur(5px)' }}
          maxWidth='lg'
          fullWidth>
          <LimitationCuration
            closeEdit={closeEdit}
            docsId={docsId}
            applicationNumber={applicationNumber}
          />
        </Dialog>
      </Box>
    </ThemeProvider>
  );
};

export default Cber;
