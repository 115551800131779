import makeRequest from './client';

export const getHPRALabelList = (
  page: number,
  perPage: number,
  status: string,
  applicationNumber: string,
  original = false
) => {
  const baseURL = `/hpra/`;
  const queryParams = [`page=${page}`, `page_size=${perPage}`];
  if (status.toLowerCase() !== 'all documents') {
    queryParams.push(`qc_status=${status}`);
  }
  if (applicationNumber) {
    queryParams.push(`product_number=${applicationNumber}`);
  }
  if (original) {
    queryParams.push(`is_original=${1}`);
  }
  const finalURL = `${baseURL}?${queryParams.join('&')}`;
  return makeRequest(finalURL, 'GET');
};

export const fetchLabelDocs = (documentId: string) => {
  return makeRequest(`/hpra/get-label/${documentId}`, 'GET');
};

export const submitLabelCurationDetails = (payload: any) => {
  return makeRequest('/hpra/label_section', 'POST', payload);
};

export const fetchLabelPageCurationDetails = (documentId: any, original: any) => {
  let baseURL = `/hpra/processed/label/${documentId}`;
  if ([0, 1].includes(original)) {
    baseURL += `?is_original=${original}`;
  }
  return makeRequest(baseURL, 'GET');
};

export const submitLabelPageCuration = (payload: any) => {
  return makeRequest('/hpra/update_processed_hpra', 'POST', payload);
};
