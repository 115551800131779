import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Stack from '@mui/material/Stack';

import Styles from './UserUpload.style';
import ExcelUploadComponent from './ExcelUploadComponent';

const UserUpload = () => {
  const classes: any = Styles();

  return (
    <Box className={classes.root} display='flex' alignItems='center'>
      <Container maxWidth='lg' className={classes.container}>
        <Paper elevation={1} className={classes.banner}>
          <Typography className={classes.formHeadings}>User Excel Upload</Typography>
          <Stack>
            <ExcelUploadComponent />
          </Stack>
        </Paper>
      </Container>
    </Box>
  );
};

export default UserUpload;
