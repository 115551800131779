import makeRequest from './client';

/**
 * Function to get List of all Non Qced EUA Documents.
 * @param {number} pageSize
 * @param {number} page
 */
export const getAllNonQcdEuaDoc = (pageSize: number, page: number) =>
  makeRequest(`/eua/applicationorig?page_size=${pageSize}&page=${page}`, `GET`);

/**
 * Function to get a specific Non Qced EUA Documents.
 * @param {object} vivproApplNo
 */
export const getNonQcdEuaDoc = (vivproApplNo: any) =>
  makeRequest(`/eua/applicationorig`, `POST`, vivproApplNo);

/**
 * Function to insert/update  the Qced EUA Documents into application table.
 * @param {object} payload
 */
export const insertQcdEuaAppliation = (payload: any) =>
  makeRequest(`/eua/applicationqcd`, `POST`, payload);

/**
 * Function to insert/update the Qced EUA Documents into indication table.
 * @param {object} payload
 */
export const insertQcdEuaIndication = (payload: any) =>
  makeRequest(`/eua/indicationqcd`, `POST`, payload);

/**
 * Function to get List of all Submission
 * @param {string} vivproApplNo
 */
export const getSubmissionRecord = (vivproApplNo: string) =>
  makeRequest(`/eua/submissions/${vivproApplNo}`, `GET`);

/**
 * Function to insert/update the Qced EUA Documents into submission table
 * @param {object} payload
 */
export const insertSubmission = (payload: any) => makeRequest(`/eua/submissions`, `POST`, payload);

/**
 * Function to get eua dag status
 * @param {object} payload
 */
export const fetchCurationDetails = () => {
  return makeRequest(`/eua/curation`, 'GET');
};

/**
 * Function to get trigger eua dag after curation
 * @param {object} payload
 */
export const updateCurationDetails = (payload: any) => {
  return makeRequest(`/eua/curation`, 'POST', payload);
};
