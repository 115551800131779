/* eslint-disable camelcase */
import React, { useState, useEffect, ChangeEvent, SyntheticEvent, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

// muiv5
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { Auth } from 'aws-amplify';
import utils from '../Datagrid/utils';
import sortLabelInfo from './utils';

// store
import Store from '../../store';
import Actions from '../../store/actions';

// api
import {
  submitPageNumberDetails,
  getLabelDetails,
  getProcessedLabelInfo,
  submitProcessedLabelInfo
} from '../../api/Cber';

// styles
import styles from './styles/PageNumberEdit.styles';
import TextFieldCard from '../TextFieldCard';

const qc_status = ['Escalate', 'Missing Details', 'No Document', 'QCed'];

const labelInfo = [
  {
    id: 'indications_and_usage',
    labelName: 'Indications And Usage'
  },
  {
    id: 'dosage_and_administration',
    labelName: 'Dosage And Administration'
  },
  {
    id: 'contraindications',
    labelName: 'Contraindications'
  },
  {
    id: 'warnings',
    labelName: 'Warnings'
  },
  {
    id: 'precautions',
    labelName: 'Precautions'
  },
  {
    id: 'adverse_reactions',
    labelName: 'Adverse Reactions'
  },
  {
    id: 'drug_abuse_and_dependence',
    labelName: 'Drug Abuse And Dependence'
  },
  {
    id: 'overdosage',
    labelName: 'Overdosage'
  },
  {
    id: 'description',
    labelName: 'Description'
  },
  {
    id: 'clinical_pharmacology',
    labelName: 'Clinical Pharmacology'
  },
  {
    id: 'how_supplied',
    labelName: 'How Supplied'
  }
];
const NonPLRPage = ({
  format,
  docs_id,
  application_number,
  sub_type,
  sub_num,
  closeEdit,
  searchText,
  status
}: any) => {
  type LabelData = {
    dosage_and_administration: number;
    contraindications: number;
    warnings: number;
    precautions: number;
    adverse_reactions: number;
    drug_abuse_and_dependence: number;
    overdosage: number;
    description: number;
    clinical_pharmacology: number;
    how_supplied: number;
    indications_and_usage: number;
    qc_status: string;
    disabled_sections: Array<any>;
    [key: string]: number | string | Array<any>;
  };
  const { dispatch } = useContext<any>(Store);
  const history = useHistory();
  const location = useLocation();
  const [disableSubmit, setDisableSubmit] = useState<boolean>(false);
  const [labelData, setLabelData] = useState<LabelData>({
    dosage_and_administration: 0,
    contraindications: 0,
    warnings: 0,
    precautions: 0,
    adverse_reactions: 0,
    drug_abuse_and_dependence: 0,
    overdosage: 0,
    description: 0,
    clinical_pharmacology: 0,
    how_supplied: 0,
    indications_and_usage: 0,
    qc_status: '',
    disabled_sections: []
  });
  useEffect(() => {
    const getLabelData = async () => {
      try {
        let result = null;
        if (location.pathname.includes('label_section')) {
          result = await getProcessedLabelInfo('non-plr', docs_id);
        } else {
          result = await getLabelDetails(docs_id);
        }
        if (!result?.data || result.status !== 200) {
          throw Error('Result Not Available');
        } else if (result.data) {
          let resultData = null;
          if (location.pathname.includes('label_section')) {
            resultData = result.data.body.results;
          } else {
            resultData = result.data.body.result;
          }
          setLabelData({
            dosage_and_administration: resultData.dosage_and_administration ?? 0,
            contraindications: resultData.contraindications ?? 0,
            warnings: resultData.warnings ?? 0,
            precautions: resultData.precautions ?? 0,
            adverse_reactions: resultData.adverse_reactions ?? 0,
            drug_abuse_and_dependence: resultData.drug_abuse_and_dependence ?? 0,
            overdosage: resultData.overdosage ?? 0,
            description: resultData.description ?? 0,
            clinical_pharmacology: resultData.clinical_pharmacology ?? 0,
            how_supplied: resultData.how_supplied ?? 0,
            indications_and_usage: resultData.indications_and_usage ?? 0,
            disabled_sections: resultData.disabled_sections ?? [],
            qc_status:
              resultData.qc_status ?? resultData.qc_status === 'qced'
                ? 'QCed'
                : utils(resultData.qc_status, 'formulations')
          });
        }
      } catch (e: any) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: e.message }
        });
      }
    };
    getLabelData();
  }, []);
  const handleSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        description: labelData.description ?? 0,
        clinical_pharmacology: labelData.clinical_pharmacology ?? 0,
        indications_and_usage: labelData.indications_and_usage ?? 0,
        contraindications: labelData.contraindications ?? 0,
        warnings: labelData.warnings ?? 0,
        precautions: labelData.precautions ?? 0,
        adverse_reactions: labelData.adverse_reactions ?? 0,
        drug_abuse_and_dependence: labelData.drug_abuse_and_dependence ?? 0,
        overdosage: labelData.overdosage ?? 0,
        dosage_and_administration: labelData.dosage_and_administration ?? 0,
        how_supplied: labelData.how_supplied ?? 0,
        qc_status: labelData.qc_status.toLowerCase(),
        application_number,
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      };
      const res = await submitPageNumberDetails(format.toLowerCase(), docs_id, payload);
      if (!res?.data || res.status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Error while Submitting ${docs_id}` }
        });
      } else if (res.data.body) {
        closeEdit();
        history.push(`/cber/reviews/label/${status}/${searchText}`);
        window.location.reload();
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: res.data.body, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };
  const handleProcessedSubmit = async (event: SyntheticEvent<HTMLFormElement>) => {
    try {
      event.preventDefault();
      const user = await Auth.currentUserInfo();
      const payload = {
        application_docs_id: docs_id,
        description: labelData.description ?? 0,
        clinical_pharmacology: labelData.clinical_pharmacology ?? 0,
        indications_and_usage: labelData.indications_and_usage ?? 0,
        contraindications: labelData.contraindications ?? 0,
        warnings: labelData.warnings ?? 0,
        precautions: labelData.precautions ?? 0,
        adverse_reactions: labelData.adverse_reactions ?? 0,
        drug_abuse_and_dependence: labelData.drug_abuse_and_dependence ?? 0,
        overdosage: labelData.overdosage ?? 0,
        dosage_and_administration: labelData.dosage_and_administration ?? 0,
        how_supplied: labelData.how_supplied ?? 0,
        qc_status: labelData.qc_status.toLowerCase(),
        application_number,
        curator_email: user.attributes.email,
        curator_name: user.attributes['custom:user']
      };

      const res = await submitProcessedLabelInfo('non-plr', payload);
      if (!res?.data || res?.status !== 200) {
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: `Error while Submitting ${docs_id}` }
        });
      } else if (res.data.body) {
        closeEdit();
        const baseURL = `/cber/label_section/non-plr?qc_status=${status}`;
        const queryParams = [];
        if (searchText !== '') {
          queryParams.push(`application_number=${searchText}`);
        }
        const finalURL = `${baseURL}&${queryParams.join('&')}`;
        history.push(finalURL);
        setTimeout(function () {
          window.location.reload();
        }, 1000);
        await dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: res.data.body, color: 'success' }
        });
      }
    } catch (e: any) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: e.message }
      });
    }
  };
  return (
    <form
      onSubmit={(event: any) => {
        if (location.pathname.includes('label_section')) {
          handleProcessedSubmit(event);
        } else {
          handleSubmit(event);
        }
      }}
      style={styles.form}>
      <Grid container sx={{ overflow: 'auto', height: '75vh', padding: 2 }}>
        <Grid item xs={12} display='flex' direction='column'>
          {sortLabelInfo(labelInfo, labelData).map((arg, id) => {
            return (
              <TextFieldCard
                key={arg.id}
                id={arg.id}
                labelName={arg.labelName}
                data={labelData}
                setData={setLabelData}
                setDisableSubmit={setDisableSubmit}
              />
            );
          })}
          <Autocomplete
            id='qc_status'
            fullWidth
            value={labelData.qc_status}
            options={qc_status}
            onChange={(event: ChangeEvent<{}>, newValue: string | null): void => {
              if (newValue !== null) {
                setLabelData(prevData => {
                  return { ...prevData, qc_status: newValue };
                });
              }
            }}
            sx={{
              '& .MuiInputBase-input': {
                fontSize: '1.5vh',
                minWidth: '250px'
              },
              '& .MuiFormLabel-root': {
                fontSize: '1.5vh'
              }
            }}
            renderInput={params => (
              <TextField
                {...params}
                label='QC Status'
                variant='outlined'
                required
                InputLabelProps={{
                  style: { fontSize: '2vh', fontWeight: 'bold' }
                }}
              />
            )}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            sx={styles.submit}
            disabled={disableSubmit}>
            SUBMIT
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default NonPLRPage;
