import { makeStyles } from '@material-ui/core';
import palette from '../../themev5/palette';

export default makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  container: {},
  firstForm: {
    width: '100%',
    height: '100%'
  },
  btn: {
    height: '100%'
  },
  dividerRoot: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  divider: {
    width: '40%',
    background: theme.palette.white,
    opacity: 0.6
  },
  or: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    fontFamily: 'Encode',
    color: theme.palette.white
  },
  banner: {
    width: '100%',
    height: '100%',
    borderRadius: theme.spacing(2),
    background: theme.palette.white
  },
  formHeadings: {
    textAlign: 'center',
    padding: theme.spacing(2),
    borderRadius: theme.spacing(2),
    fontWeight: 'bold',
    fontSize: theme.spacing(3),
    color: '#3AB09E'
  },
  label: {
    fontSize: theme.spacing(2),
    color: '#6C7A89',
    fontWeight: 'bold'
  },
  radioLabel: {
    fontFamily: 'Roboto',
    color: theme.palette.white
  },
  radio: {
    color: theme.palette.buttons.purple,
    fill: theme.palette.black
  },
  textfield: {
    marginTop: theme.spacing(0.5),
    height: theme.spacing(6),
    background: '#ECF0F1',
    width: theme.spacing(50),
    borderRadius: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    color: theme.palette.grey,
    padding: theme.spacing(1),
    '&::placeholder': {
      color: theme.palette.grey,
      padding: theme.spacing(1)
    }
  },
  dropdownContainer: {
    marginTop: theme.spacing(0.5),
    height: theme.spacing(6),
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    color: theme.palette.white,
    padding: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  icon: {
    color: theme.palette.white
  },
  dropdownText: {
    fontWeight: 'bold'
  },
  button: {
    background: '#3AB09E',
    color: theme.palette.white,
    width: theme.spacing(25),
    padding: theme.spacing(1),
    borderRadius: theme.spacing(6),
    '&:hover': {
      background: palette.primary.darkBluish,
      color: theme.palette.white
    }
  }
}));
